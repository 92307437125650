import {useEffect, useState} from "react";
import {TPermission} from "types/settings/permissions";
import {Loader} from "@atoms/loader";
import {CRMAPIManager} from "@classes/crmApiManager";
import {store as pStore} from "@molecules/paginationControls/pageSizeStore";
import {PermissionListResponse} from "@api/responseModels/permissions/permissionsResponse";
import {SettingsManager} from "@classes/settingsManager";
import {Empty, Pagination} from "antd";
import {TMetadata} from "types/metadata";
import {useParams} from "react-router-dom";

const PermissionSettings = () => {
    const [permissionsList, setPermissionsList] = useState<Array<TPermission>>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentMeta, setCurrentMeta] = useState<TMetadata>();
    const creds = SettingsManager.getConnectionCredentials();
    const urlParams = useParams();

    const loadPermissions = async (page: number = currentPage) => {
        setIsLoading(true);
        try {
            const permissions = await CRMAPIManager.request<PermissionListResponse>(async (api) => {
                return await api.getPermissionList({
                    crm_id: creds?.crmID,
                    page: page,
                    per_page: pStore.pS,
                    filters: {
                        worker_profile_id: urlParams?.workerID
                    }
                });
            });
            setPermissionsList([...permissions.data.data]);
            setCurrentMeta({...permissions.data.meta});
        } catch (e) {}
        setIsLoading(false);
    };

    const handlePageChange = (newPage: number) => {
       loadPermissions(newPage);
       setCurrentPage(newPage);
    }

    useEffect(() => {
        loadPermissions(currentPage);
    }, []);

    return isLoading ? <Loader /> : !!permissionsList?.length ? (
        <>
            {permissionsList.map((item: TPermission) => {
                return (
                    <>
                        <div style={{display: "flex"}}>
                            <p>{item.id}</p>
                            <p>{item.name}</p>
                        </div>
                    </>
                )
            })}
            <Pagination
                current={currentPage}
                defaultCurrent={1}
                pageSize={pStore.pS}
                total={currentMeta?.total ?? 1}
                onChange={handlePageChange}
            />
        </>
    ) : (
        <Empty/>
    );
};

export { PermissionSettings };