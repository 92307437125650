import { combineReducers } from '@reduxjs/toolkit';

import { employeesReducer } from '../components/commentEmployeesPicker/redux/commentEmployeesPickerSlice';
import { topicsReducer } from '../components/commentTopicsPicker/common/redux/commentTopicsPickerSlice';
import commentsDataReducer from './commentSlice';

const commentsReducer = combineReducers({
    commentsData: commentsDataReducer,
    topics: topicsReducer,
    employees: employeesReducer,
});

export default commentsReducer;
