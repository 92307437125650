import moment from 'moment';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: {},
};

const errorSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        pushError: (state, action: PayloadAction<{ errorId: string; err: string[] }>) => {
            const { errorId, err } = action.payload;
            state.data[errorId] = {
                data: err,
                at: moment(),
            };
        },

        removeError: (state, { payload }) => {
            state.data[payload] = undefined;
        },

        clearErrors: (state) => {
            state = initialState;
        },
    },
});

export const { clearErrors, pushError, removeError } = errorSlice.actions;
export default errorSlice.reducer;
