import './style.scss';
import { Col, List, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HeaderListBreadcrumbs } from '@molecules/breadcrumbs/listsBreadcrumbs/HeaderListBreadcrumbs';
import { columnViewStore as vStore } from '@molecules/listViewChanger/listViewStore';
import { OptionsMenu } from '@molecules/OptionsMenu/OptionsMenu';
import { observer } from 'mobx-react';

const Settings = observer(() => {
    const navigate = useNavigate();

    const data = [
        {
            name: 'Заказы',
            path: 'orders'
        },
        {
            name: 'Другое',
            path: 'other'
        },
    ]

    return (
        <>
            <div id="app-settings-list">
                <HeaderListBreadcrumbs
                    dataTotal={null}
                    title={'Настройки'}
                    dataTitle={'Настройки'}
                    dataIcon={null}
                    dataPrice={null}
                    flag={false}
                    isProduct={false}
                    categoryPath={null}
                    openCategoryCard={null}
                    showSearchInput={false}
                    
                >
                    <>
                        <OptionsMenu />
                    </>
                </HeaderListBreadcrumbs>
                <List
                    className="setting-card-list"
                    dataSource={data}
                    itemLayout="horizontal"
                    grid={{ column: vStore.col }}
                    renderItem={(item) => (
                        <List.Item className="setting-card">
                            <Row
                                gutter={[0, 16]}
                                onClick={() => navigate(item.path)}
                            >
                                <Col span={24} className="card-body">
                                    <p>{item.name}</p>
                                    <p>Перейти</p>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </div>
        </>
    );
});

export { Settings };
