import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Space, Tabs } from 'antd';
import { observer } from 'mobx-react';
import { TMetadata } from 'types/metadata';
import { TUser } from 'types/user/user';

import { CustomerProfileResp } from '@api/responseModels/customer/customerProfileResponse';
import { Loader } from '@atoms/loader';
import { Common } from '@classes/commonMethods';
import { CRMAPIManager } from '@classes/crmApiManager';
import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import { GlobalConstants } from '@constants/global';
import { InvitationStatuses } from '@enums/invitationStatuses';
import {
    faPlus,
    faTrashCan,
    faTrashCanArrowUp,
    faUser,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetchDataList } from '@hooks/useFetchDataList';
import { OptionsMenu } from '@molecules/OptionsMenu';
import { HeaderListBreadcrumbs } from '@molecules/breadcrumbs/listsBreadcrumbs/HeaderListBreadcrumbs';
import { FunctionalMenu } from '@molecules/functionalMenu';
import { columnViewStore as vStore } from '@molecules/listViewChanger/listViewStore';
import { store as pStore } from '@molecules/paginationControls/pageSizeStore';
import { RenderUserList } from '@molecules/renderList/userList';
import { CustomerFilter } from '@organisms/customersFilter/customersFilter';
import { FilterStore } from '@organisms/customersFilter/filterStore';

import { debounce } from '../../../utils/functions';
import { CreateWorkerDialog } from '../dialogs/createWorkerDialog';

const filterStore = new FilterStore();
const filter = filterStore.filter;

const CustomerList = observer((): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [workerList, setWorkerList] = useState<Array<TUser>>([]);
    const [currentMeta, setCurrentMeta] = useState<TMetadata>(null);
    const [selectList, setSelectList] = useState<
        Array<{ id: number; name: string; profileId: number }>
    >([]);
    const [inviteWorkerOpen, setInviteWorkerOpen] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const navigate = useNavigate();
    const creds = SettingsManager.getConnectionCredentials();
    const tabNumber = useRef<string>('');

    useEffect(() => {
        filterStore.changeDeleted('null');
    }, []);

    function onChangeTab(key: string) {
        if (key == '3') filterStore.changeDeleted('only');
        else filterStore.changeDeleted('null');
        setSelectList([]);
        tabNumber.current = key;
    }

    const { getDataList, isLoading: isCustomerListLoading } = useFetchDataList({
        filter,
        profile: 'customer',
        setCurrentMeta,
        setDataList: setWorkerList,
        getFnKey: 'getUserList',
    });

    async function handleChangePage() {
        getDataList(currentPage + 1).then(() => {
            setCurrentPage((prev) => prev + 1);
        });
    }

    async function deleteSelected() {
        setIsLoading(true);
        const creds = SettingsManager.getConnectionCredentials();
        while (selectList.length != 0) {
            const target = selectList.pop();
            try {
                const del = await CRMAPIManager.request<CustomerProfileResp>(async (api) => {
                    return await api.removeCustomerProfile(target.profileId, creds.crmID);
                });
                if (del.errorMessages) throw del.errorMessages;
            } catch (err) {
                messageService.sendError(target.name + ': ' + err.message);
            }
            setSelectList((oldList) => oldList.filter((sli) => sli != target));
        }
        await getDataList(1);
        setCurrentPage(1);
        setIsLoading(false);
    }

    async function restoreSelected() {
        setIsLoading(true);
        const creds = SettingsManager.getConnectionCredentials();
        while (selectList.length != 0) {
            const target = selectList.pop();
            try {
                const restored = await CRMAPIManager.request<CustomerProfileResp>(async (api) => {
                    return await api.restoreCustomerProfile(target.profileId, creds.crmID);
                });
                if (restored.errorMessages) throw restored.errorMessages;
            } catch (errors) {
                messageService.sendErrorList(errors);
            }
            setSelectList((oldList) => oldList.filter((sli) => sli != target));
        }
        await getDataList(1);
        setCurrentPage(1);
        setIsLoading(false);
    }

    function beforeMountCustomerList() {
        Common.checkUserBelongToCrm(GlobalConstants.CrmOrderinUrl).then((res) => {
            if (!res) navigate(`/lk/worker/crm/${creds.crmID}`);
        });
        if (localStorage.getItem('invitationIsSent')) {
            localStorage.removeItem('invitationIsSent');
            messageService.sendSuccess('Клиент добавлен');
        }
        getDataList(1);
        setCurrentPage(1);
    }

    const handleSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        filterStore.changeQuery(e.target.value);
    }, GlobalConstants.SearchTimeout);

    useEffect(() => {
        beforeMountCustomerList();
    }, [
        filter.sortBy,
        filter.sortDirection,
        filter.updatedDates,
        filter.createdDates,
        filter.query,
        filter.deleted,
    ]);

    useEffect(() => {
        if (currentMeta?.total == null || currentMeta?.total == 0) return;
        let newPage = Math.ceil(currentMeta.from / pStore.pS);
        getDataList(newPage).then(() => {
            setCurrentPage(newPage);
        });
    }, [pStore.pS]);

    return (
        <div id="app-worker-list">
            {(isLoading || isCustomerListLoading) && <Loader />}
            <CreateWorkerDialog
                isModalOpen={inviteWorkerOpen}
                setIsModalOpen={setInviteWorkerOpen}
                isCustomer={true}
                getCustomerList={getDataList}
            />
            <FunctionalMenu
                items={
                    selectList.length > 0
                        ? [
                              {
                                  key: 'createElement',
                                  label: 'Создать клиента',
                                  icon: <FontAwesomeIcon icon={faPlus} />,
                                  onClick: () => {
                                      setInviteWorkerOpen(true);
                                  },
                              },
                              {
                                  key: 'abort',
                                  label: 'Отмена',
                                  icon: <FontAwesomeIcon icon={faXmark} />,
                                  onClick: () => {
                                      setSelectList([]);
                                  },
                              },
                          ]
                        : [
                              {
                                  key: 'createElement',
                                  label: 'Создать клиента',
                                  icon: <FontAwesomeIcon icon={faPlus} />,
                                  onClick: () => {
                                      setInviteWorkerOpen(true);
                                  },
                              },
                          ]
                }
                dropdownItems={
                    selectList.length > 0
                        ? [
                              {
                                  key: tabNumber.current == '3' ? 'restore' : 'delete',
                                  label: tabNumber.current == '3' ? 'Восстановить' : 'Удалить',
                                  icon:
                                      tabNumber.current == '3' ? (
                                          <FontAwesomeIcon icon={faTrashCanArrowUp} />
                                      ) : (
                                          <FontAwesomeIcon icon={faTrashCan} />
                                      ),
                                  onClick:
                                      tabNumber.current == '3' ? restoreSelected : deleteSelected,
                              },
                          ]
                        : []
                }
                selected={selectList.length}
            />
            <HeaderListBreadcrumbs
                dataTotal={currentMeta?.total}
                title={'Клиенты'}
                dataTitle={'Клиенты'}
                dataIcon={faUser}
                dataPrice={null}
                flag={false}
                isProduct={false}
                categoryPath={null}
                openCategoryCard={null}
                onSearch={handleSearchChange}
                searchPlaceHolder="По клиентам"
            >
                <Space>
                    <CustomerFilter store={filterStore} />
                    <OptionsMenu />
                </Space>
            </HeaderListBreadcrumbs>
            <Tabs
                items={[
                    {
                        label: 'Клиенты',
                        key: '1',
                        children: (
                            <RenderUserList
                                inviteStatus={InvitationStatuses.accepted}
                                userList={workerList}
                                selectList={selectList}
                                setSelectList={setSelectList}
                                isWorker={false}
                                col={vStore.col}
                                handleChangePage={handleChangePage}
                                currentMetaTotal={currentMeta?.total}
                            />
                        ),
                    },
                    {
                        label: 'Корзина',
                        key: '3',
                        children: (
                            <RenderUserList
                                inviteStatus={null}
                                userList={workerList}
                                selectList={selectList}
                                setSelectList={setSelectList}
                                isWorker={false}
                                col={vStore.col}
                                handleChangePage={handleChangePage}
                                currentMetaTotal={currentMeta?.total}
                            />
                        ),
                    },
                ]}
                onChange={(key) => {
                    onChangeTab(key);
                }}
            />

            {/* {currentMeta && currentPage < currentMeta.last_page && (
                <ShowMoreButton onClick={handleShowMore} text="Показать ещё" />
            )}
            <Pagination
                current={currentPage}
                defaultCurrent={1}
                onChange={handleChangePage}
                pageSize={pStore.pS}
                showSizeChanger={false}
                total={currentMeta?.total ?? 1}
            /> */}
        </div>
    );
});

export { CustomerList };
