import { WorkerParams } from 'types/getParams';
import { TUser } from 'types/user/user';
import { CRMAPIBase } from '../crmApiBase';
import { TUserSearch } from '../models/userSearch';
import { InvitationListResp } from '../responseModels/invitation/invitationListResponse';
import { RequestResult } from '../responseModels/requestResult';
import { RequisiteListResp } from '../responseModels/requisite/requisiteListResponse';
import { UserListResp } from '../responseModels/user/userListResponse';
import { UserResp } from '../responseModels/user/userResponse';
import { WorkerProfileResp } from '../responseModels/worker/workerProfileResponse';
import { CurrentUserResponse } from '@api/responseModels/user/currentUserResponse';

class UserGroup extends CRMAPIBase {
    /**
     * Обновление пользователя
     * @param crmId ID CRM
     * @param user Пользователь
     * @returns Результат запроса
     */
    async updateUser(crmId: number, user: TUser): Promise<RequestResult<UserResp>> {
        const result = await this.put<UserResp>(`/users/${user.id}`, {
            crm_id: crmId,
            ...user,
        });
        return result;
    }

    /**
     * Получение пользователя
     * @param id ID пользователя
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getUser(id: number, crmId: number): Promise<RequestResult<UserResp>> {
        const result = await this.get<UserResp>(`/users/${id}`, { crm_id: crmId });
        return result;
    }

    /**
     * Получение текущего пользователя
     * @returns Результат запроса
     */
    async currentUser(): Promise<RequestResult<CurrentUserResponse>> {
        const result = await this.get<CurrentUserResponse>('/users/current');
        return result;
    }

    /**
     * Получение списка пользователей
     * @param params Параметры запроса пользователей
     * @param page Страница
     * @param perPage На страницу
     * @returns Результат запроса
     */
    async getUserList(params: WorkerParams): Promise<RequestResult<UserListResp>> {
        const result = await this.get<UserListResp>('/users', { ...params });
        return result;
    }

    /**
     * Удаление пользователя
     * @param id ID пользователя
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeUser(id: string, crmId: number): Promise<RequestResult<UserResp>> {
        const result = await this.delete<UserResp>(`/users/${id}`, { crm_id: crmId });
        return result;
    }

    /**
     * Получение реквизитов текущего пользователя
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getUserRequisites(crmId: number): Promise<RequestResult<RequisiteListResp>> {
        const result = await this.get<RequisiteListResp>('/users/requisites', { crm_id: crmId });
        return result;
    }

    /**
     * Получение профиля сотрудника текущего пользователя
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getUserWorkerProfile(crmId: number): Promise<RequestResult<WorkerProfileResp>> {
        const result = await this.get<WorkerProfileResp>('/users/worker-profile', {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Получение отправленных текущим пользователем приглашений
     * @returns Результат запроса
     */
    async getSendInvitations(): Promise<RequestResult<InvitationListResp>> {
        const result = await this.get<InvitationListResp>('/users/sent-invitations');
        return result;
    }

    /**
     * Получение принятых текущим пользователем приглашений
     * @returns Результат запроса
     */
    async getRecieveInvitations(): Promise<RequestResult<InvitationListResp>> {
        const result = await this.get<InvitationListResp>('/users/received-invitations');
        return result;
    }

    /**
     * Поиск пользователей
     * @param params WorkerParams,
     * @param userSearchModel Объект параметров поиска
     * @returns Результат запросов
     */
    async searchUser(
        params: WorkerParams,
        userSearchModel: TUserSearch
    ): Promise<RequestResult<UserListResp>> {
        let query = '';
        Object.keys(userSearchModel).forEach((key) => {
            query += key + '=' + (userSearchModel[key] ? userSearchModel[key] : '') + '&';
        });
        query = query.slice(0, -1);
        const result = await this.get<UserListResp>('/users/search', {
            ...params,
            query: userSearchModel.name,
        });
        return result;
    }

    /**
     * Восстановление пользователя
     * @param id ID пользователя
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreUser(id: string, crmId: number): Promise<RequestResult<UserResp>> {
        const result = await this.post<UserResp>(`/users/${id}/restore`, { crm_id: crmId });
        return result;
    }
}

export { UserGroup };
