import React, { memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCreation } from 'ahooks';
import { Col, Row, Table } from 'antd';
import { isEmpty } from 'lodash';
import { TOrderService } from 'types/Orders/services';

import { handleFormData } from '../../common/helpers';
import {
    selectAllServices,
    selectService,
    setOrderProperty,
    setServices,
} from '../../common/redux/orderSlice';
import { getOrder, getSelectedItemsWithoutExecutor, getSelectedServices, getTotalServices } from '../../common/redux/selectors';
import { clearSelectedComments } from '../orderComment/common/redux/commentSlice';
import { getComments } from '../orderComment/common/redux/selectors';
import { getColumnsService } from './common/data';
import { TableServiceProps } from './common/types';

import '../style.scss';

export const ServiceTable: React.FC<TableServiceProps> = memo(({ nameCellWidth }) => {
    const dispatch = useDispatch();

    const { services = [] } = useSelector(getOrder) || {};
    const selectedServices = useSelector(getSelectedServices);
    const totalServices = useSelector(getTotalServices);
    const { selectedComments } = useSelector(getComments);
    const selectedItemsWithoutExecutor = useSelector(getSelectedItemsWithoutExecutor);
    const inputRefs = useRef([]);

    const setInputRef = (index, el) => {
        inputRefs.current[index] = el;
    };

    const handleSearchInput = (item: TOrderService, _, rowIndex: number) => {
        const data = handleFormData(item, rowIndex, services);
        dispatch(setServices(data));
        dispatch(setOrderProperty({ key: 'updatedRowIndex', value: rowIndex }));
    };

    const handleChangeQuantity = (rowIndex: number, newQuantity: number) => {
        const serviceCopy = [...services];
        serviceCopy[rowIndex] = {
            ...services[rowIndex],
            quantity: newQuantity,
            total: newQuantity * services[rowIndex].price,
        };

        dispatch(setServices(serviceCopy));
    };

    const handleChangePrice = (rowIndex: number, newPrice: number) => {
        const serviceCopy = [...services];
        serviceCopy[rowIndex] = {
            ...services[rowIndex],
            price: newPrice,
            total: newPrice * services[rowIndex].quantity,
        };

        dispatch(setServices(serviceCopy));
    };

    const handleSelectService = (id: number) => {
        dispatch(clearSelectedComments());
        dispatch(selectService(id));
    };

    const handleSelectAllItems = () => {
        dispatch(selectAllServices());
    };

    const columnsService = useCreation(() => {
        return getColumnsService({
            handleSearchInput,
            handleChangeQuantity,
            handleChangePrice,
            handleSelectItem: handleSelectService,
            selectedItems: selectedServices,
            handleSelectAllItems,
            itemsCount: services.length - 2,
            nameCellWidth,
            isCommentSelected: !isEmpty(selectedComments) || !isEmpty(selectedItemsWithoutExecutor),
            inputRefs,
            setInputRef,
        });
    }, [selectedServices.length, nameCellWidth, !isEmpty(selectedComments), services, selectedItemsWithoutExecutor]);

    return (
        <Row className="table-service">
            <Table
                columns={columnsService}
                bordered
                dataSource={services}
                pagination={false}
                className="table-size"
                size={'small'}
                rowKey={(record) => record.id}
                footer={() => {
                    return (
                        <Row className="t-footer" justify={'space-between'} align="middle">
                            <Col span={4}>
                                <h3>Итого</h3>
                            </Col>
                            <Col span={6}>
                                <h3>{totalServices.toLocaleString('ru-RU')} ₽</h3>
                            </Col>
                        </Row>
                    );
                }}
                onHeaderRow={(columns, _index) => {
                    return {
                        onClick: () => {
                            columns[1].className = 'td-service-cell enlarged';
                        },
                    };
                }}
            />
        </Row>
    );
});
