import { memo } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row, Space } from 'antd';

import { GlobalConstants } from '@constants/global';
import { FILTER_KEYS } from '@organisms/orders/profile/components/orderComment/common/constants';
import { setFilterArrayItem } from '@organisms/orders/profile/components/orderComment/common/redux/commentSlice';
import { getComments } from '@organisms/orders/profile/components/orderComment/common/redux/selectors';

import './avatar.scss';

interface Props {
    comment: any;
}

export const Avatar: React.FC<Props> = memo(({ comment }) => {
    const dispatch = useDispatch();
    const {
        filterData: { searchText },
    } = useSelector(getComments);

    const author = ` ${comment.author.name} ${comment.author.surname}`;

    const handleActivateFilter = (member) => {
        dispatch(
            setFilterArrayItem({
                item: FILTER_KEYS.MEMBER,
                value: member,
            })
        );
    };

    return (
        <div className="comment-avatar-wrapper">
            <img
                className="comment-avatar-image"
                src={GlobalConstants.BaseUrlForImg + comment.author.picture}
                alt=""
            />

            <Space direction="vertical" align="center">
                <Row onClick={() => handleActivateFilter(comment.author)}>
                    <Col className="comment-items-container pointer">
                        <div style={{ textAlign: 'center' }}>
                            <Highlighter
                                searchWords={searchText.split(' ')}
                                autoEscape={true}
                                textToHighlight={'Менеджер'}
                            />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Highlighter
                                searchWords={searchText.split(' ')}
                                autoEscape={true}
                                textToHighlight={author}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="comment-items-container">
                        <p>{comment.time}</p>
                        <p>{comment.date}</p>
                    </Col>
                </Row>
            </Space>
        </div>
    );
});
