import brigadeReducer from '@organisms/workers/components/brigadeList/common/redux/brigadeListSlice';
import workplaceReducer from '@organisms/workers/workplaceList/common/redux/workplaceListSlice';
import { combineReducers } from '@reduxjs/toolkit';

const workersRootReducer = combineReducers({
    brigade: brigadeReducer,
    workplace: workplaceReducer,
});

export default workersRootReducer;
