import { faHistory, faTableList } from '@fortawesome/free-solid-svg-icons';

export const sectionsData = {
    orders: {
        name: 'Заказы',
        icon: faTableList,
    },
    other: {
        name: 'Другое',
        icon: faHistory,
    },
};

export const FIELD_NAME = 'fieldName';

export const FIELD_PIVOT = 'fieldPivot';
