import { useEffect, useRef, useState } from 'react';

import { Button, Form, Input, InputRef, Modal } from 'antd';

export const TransactionModal = ({ transactionType, open, setIsOpen, prepareTransaction }) => {
    const [transactionComment, setTransactionComment] = useState<string>('');
    const [transactionAmount, setTransactionAmount] = useState<number>(0);

    const transactionAmountInput = useRef<InputRef>(null);

    const handleConfirm = () => {
        setIsOpen(false);
        prepareTransaction({ transactionAmount, transactionType });
        setTransactionAmount(0);
        setTransactionComment('');
    };

    useEffect(() => {
        if (open) {
            transactionAmountInput.current!.focus();
        }
    }, [open]);

    return (
        <Modal
            className="transaction-dialog"
            title={transactionType}
            open={open}
            onCancel={() => setIsOpen(false)}
            wrapClassName="sticky-modal-no-stretch"
            footer={[
                <Button className="round-button" key="back" onClick={() => setIsOpen(false)}>
                    Отмена
                </Button>,
                <Button
                    className="round-button"
                    key="submit"
                    type="primary"
                    disabled={transactionAmount == null || transactionAmount <= 0}
                    onClick={handleConfirm}
                >
                    Подтвердить
                </Button>,
            ]}
        >
            <Form>
                <Form.Item label="Сумма">
                    <Input
                        ref={transactionAmountInput}
                        autoFocus={true}
                        type="number"
                        inputMode="numeric"
                        min={0}
                        max={9999999}
                        step={0.01}
                        onChange={(e) => setTransactionAmount(Number.parseFloat(e.target.value))}
                        style={{ width: '180px' }}
                        value={transactionAmount}
                        required
                    />
                </Form.Item>
                <Form.Item label="Комментарий">
                    <Input.TextArea
                        rows={3}
                        onChange={(e) => setTransactionComment(e.target.value)}
                        style={{ width: '100%' }}
                        value={transactionComment}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
