import { useRef } from 'react';
import { TUser } from 'types/user/user';
import { Button, Typography } from 'antd';
import cn from 'classnames';
import styles from './userCard.module.scss';
import { GlobalConstants } from '@constants/global';
import { columnViewStore } from '@molecules/listViewChanger/listViewStore';
import { faCheck, faRubleSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoResizeText from '../autoResizeText/autoResizeText';
import useGetCardWidth from '../hooks/useGetCardWidth';
import { Common } from '@classes/commonMethods';

const { Paragraph } = Typography;

type Props = {
    handleUserClick: (
        select: boolean,
        userId: number,
        userFirstName: string,
        userLastName: string,
        profileId: number
    ) => void;
    selectList: {
        id: number;
        name: string;
    }[];
    user: TUser;
    preventOpenCard?: boolean;
};

const UserCard = ({ user, handleUserClick, selectList, preventOpenCard }: Props) => {
    const profileId = user.customer_profile ? user.customer_profile.id : user.worker_profile.id;
    const cardRef = useRef(null);
    const cardWidth = useGetCardWidth({ cardRef });

    return (
        <div
            ref={cardRef}
            className={cn(styles.card, {
                [styles['card_vertical']]: columnViewStore.col > 1,
            })}
            onClick={() => handleUserClick((!!preventOpenCard), user.id, user.name, user.surname, profileId)}
        >
            <div className={styles['card__image']}>
                <img src={`${GlobalConstants.BaseUrlForImg}${user?.picture as string}`} />
            </div>
            <div className={styles['card__info']}>
                <Paragraph className={styles['card__smaller-margin']} ellipsis={{ rows: 1 }}>{`${user.name} ${user.surname}`}</Paragraph>
                <Paragraph className={styles['card__smaller-margin']} ellipsis={{ rows: 1 }}>
                    {user.profile?.name ?? 'Тестовая группа/Тестовый профиль'}
                </Paragraph>
                <div className={styles['card__price-container']}>
                    <AutoResizeText maxWidth={cardWidth}>
                        {Common.formatCurrency(user.worker_profile?.balance ?? user.customer_profile?.balance ?? 0)}&nbsp;
                        <FontAwesomeIcon color="black" icon={faRubleSign} />
                    </AutoResizeText>
                </div>
                <div className={styles['card__select-btn']}>
                    <Button
                        icon={<FontAwesomeIcon icon={faCheck} />}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleUserClick(true, user.id, user.name, user.surname, profileId);
                        }}
                        shape="circle"
                        type={selectList.find((sli) => sli.id == user.id) ? 'primary' : 'default'}
                    />
                </div>
            </div>
        </div>
    );
};

export { UserCard };
