import { useState } from 'react';

import { Col, Input, Row, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import { ImgUploader } from '@organisms/imgUploader/imgUploader';

import { Brigade } from '../brigadeList/common/types';

import './createBrigadeModal.scss';

export const CreateBrigadeModal = ({ open, onCancel, onOk }) => {
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);

    const handleOk = () => {
        const brigade: Brigade = {
            id: (Math.floor(Math.random() * 9991) + 10).toString(),
            name,
            workplaces: [],
            image: '',
        };

        if (!name || name.trim().length === 0) {
            message.error('Введите название');
            return;
        }

        onOk(brigade);
        message.success(`Бригада ${name} создана`);
        setName('');
        setImage(null);
    };

    const handleCancel = () => {
        setName('');
        setImage(null);
        onCancel();
    };

    const handleImageDelete = () => {
        setImage(null);
    };

    const handleImageSelect = (file) => {
        setImage(file);
    };

    return (
        <Modal
            title="Создание бригады"
            open={open}
            onCancel={handleCancel}
            onOk={handleOk}
            className="create-brigade-modal"
        >
            <Row className="">
                <Col span={11} className="brigade-modal-col">
                    <ImgUploader
                        photoSrc={image}
                        onImageSelect={handleImageSelect}
                        onImageDelete={handleImageDelete}
                        isEdit={true}
                    />
                </Col>
                <Col span={11} className="brigade-modal-col modal-col-vertical">
                    <Row>
                        <Col className="brigade-modal-text">
                            <p>Название</p>
                        </Col>
                    </Row>
                    <Row className="brigade-modal-input">
                        <Col>
                            <Input value={name} onChange={(e) => setName(e.target.value)} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};
