import { memo } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Row } from 'antd';
import { includes, isEmpty, map } from 'lodash';

import RenderIf from '@common/RenderIf';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    getSelectedProducts,
    getSelectedServices,
} from '@organisms/orders/profile/common/redux/selectors';
import {
    FILTER_KEYS,
    TOPIC,
} from '@organisms/orders/profile/components/orderComment/common/constants';
import {
    selectComment,
    setFilterArrayItem,
} from '@organisms/orders/profile/components/orderComment/common/redux/commentSlice';
import { getComments } from '@organisms/orders/profile/components/orderComment/common/redux/selectors';

import './headerInfo.scss';

interface Props {
    comment: any;
}

export const HeaderInfo: React.FC<Props> = memo(({ comment }) => {
    const dispatch = useDispatch();

    const selectedProducts = useSelector(getSelectedProducts);
    const selectedServices = useSelector(getSelectedServices);
    const {
        selectedComments,
        filterData: { searchText },
    } = useSelector(getComments);

    const recipient = map(comment.repliedTo, (member) => `${member.name} ${member.surname}`).join(
        ', '
    );
    const topic = `${TOPIC[comment.topic?.category]} ${comment.topic?.name}`;

    const isProdOrServSelected = !isEmpty(selectedProducts) || !isEmpty(selectedServices);

    const handleActivateFilter = (topic) => {
        dispatch(
            setFilterArrayItem({
                item: FILTER_KEYS.TOPIC,
                value: topic,
            })
        );
    };

    return (
        <>
            <Row className="comment-header-info-wrapper" justify={'space-between'}>
                <Col span={20}>
                    {/* Кому */}
                    <p className="comment-topic">
                        <span style={{ color: '#1890ff' }}>Кому: </span>
                        <RenderIf condition={!isEmpty(comment.repliedTo)}>
                            <Highlighter
                                searchWords={searchText.split(' ')}
                                autoEscape={true}
                                textToHighlight={recipient}
                            />
                        </RenderIf>
                        <RenderIf condition={isEmpty(comment.repliedTo)}>
                            <Highlighter
                                searchWords={searchText.split(' ')}
                                autoEscape={true}
                                textToHighlight={'Общий чат'}
                            />
                        </RenderIf>
                    </p>

                    {/* Тема */}
                    <p className="comment-topic">
                        <span style={{ color: '#1890ff' }}>Тема: </span>
                        <RenderIf condition={comment.topic}>
                            <span
                                className="pointer"
                                onClick={() => handleActivateFilter(comment.topic)}
                            >
                                <Highlighter
                                    searchWords={searchText.split(' ')}
                                    autoEscape={true}
                                    textToHighlight={topic}
                                />
                            </span>
                        </RenderIf>
                        <RenderIf condition={!comment.topic}>
                            <Highlighter
                                searchWords={searchText.split(' ')}
                                autoEscape={true}
                                textToHighlight={'Без темы'}
                            />
                        </RenderIf>
                    </p>

                    {/* В ответ на */}
                    <RenderIf condition={comment.repliedComment}>
                        <p className="comment-topic">
                            <span style={{ color: '#1890ff' }}>В ответ на: </span>
                            <span>{comment.repliedComment}</span>
                        </p>
                    </RenderIf>
                </Col>

                <RenderIf condition={!isProdOrServSelected}>
                    <Col>
                        <Button
                            icon={<FontAwesomeIcon icon={faCheck} />}
                            onClick={() => dispatch(selectComment(comment.id))}
                            shape="circle"
                            type={includes(selectedComments, comment.id) ? 'primary' : 'default'}
                        />
                    </Col>
                </RenderIf>
            </Row>
        </>
    );
});
