import { CRMListResp } from '../api/responseModels/crm/crmListResponse';
import { SecurityStore } from '../ui/organisms/security/securityStore';
import { CRMAPIManager } from './crmApiManager';
import { messageService } from './messageService';
import { SettingsManager } from './settingsManager';

class Common {
    /**
     * Проверка на пустую переменную
     * @param value Переменная
     *
     * @returns Результат
     */
    private static isNullOrUndefined(value?: unknown) {
        return value == null || value == undefined;
    }

    /**
     * Добавление ведущих нулей к строке, выражающей число
     * @param value Число
     * @param length Общая длина
     * @returns Форматированная строка
     */
    private static pad(value: number, length: number): string {
        var string = value.toString(),
            sLength = string.length;
        for (var i = 0; i < length - sLength; i++) {
            string = '0' + string;
        }
        return string;
    }
    /**
     * Конвертация формата 00:00 в минуты
     * @param timeParts Массив часов и минут
     * @returns Конвертированное количество минут
     */
    public static convertH2M(timeInHour: string): number {
        const timeParts = timeInHour.split(':');
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }
    /**
     * Конвертация формата минут в формат 00:00
     * hours число часов
     * minutes число минут
     * @returns Конвертированную строку формата 00:00
     */
    public static convertM2H(totalMinutes: number): string {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes - hours * 60);
        const timeAmount =
            hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
        return timeAmount;
    }
    /**
     * Конвертация формата минут в формат даты
     * months число месяцев
     * days число дней
     * hours число часов
     * minutes число минут
     * @returns Конвертированную строку формата 00:00
     */
    public static convertMinutes2Date(minutes: number) {
        // Calculate the number of months
        const months = Math.floor(minutes / 43200);

        // Calculate the number of days
        const days = Math.floor((minutes % 43200) / 1440);

        // Calculate the number of hours
        const hours = Math.floor((minutes % 1440) / 60);

        // Calculate the number of minutes
        const remainingMinutes = minutes % 60;

        return `${months != 0 ? months + 'м. ' : ''}${days != 0 ? days + 'д. ' : ''}${
            hours != 0 ? hours + 'ч. ' : ''
        }${remainingMinutes != 0 ? remainingMinutes + 'мин. ' : ''}`;
    }

    /**
     * Форматирование даты к 04-08-2022 | 16:16
     * @param date Дата
     * @returns Форматированная строка
     */
    public static formatDate(date?: string): string {
        if (this.isNullOrUndefined(date)) return '';
        const d = new Date(date);
        return (
            this.pad(d.getDate(), 2) +
            '-' +
            this.pad(d.getMonth() + 1, 2) +
            '-' +
            d.getFullYear() +
            ' | ' +
            this.pad(d.getHours(), 2) +
            ':' +
            this.pad(d.getMinutes(), 2)
        );
    }

    /**
     * Преобразование продолжительности
     * @param dur Миллисекунд
     * @returns Форматированная строка
     */
    public static formatDuration(dur?: number): string {
        if (this.isNullOrUndefined(dur)) return '';
        if (dur < 60 * 1000) {
            return Math.floor(dur / 1000) + ' сек.';
        } else if (dur < 60 * 60 * 1000) {
            return Math.floor(dur / 60 / 1000) + ' мин.';
        } else return Math.floor(dur / 60 / 60 / 1000) + ' ч.';
    }

    public static getIdfromUrl(): number {
        const urlArr = window.location.pathname.split('/');
        return Number(urlArr[urlArr.length - 1]);
    }

    /**
     * Проверка принадлежности пользователя к CRM
     * @param orderNum Положение ID CRM
     * @returns Результат
     */
    public static async checkUserBelongToCrm(orderNum: number): Promise<boolean> {
        try {
            const urlArr = window.location.pathname.split('/');
            const currentID = Number(urlArr[orderNum]);
            let CRMList = [];
            const crmList = await CRMAPIManager.request<CRMListResp>(async (api) => {
                return await api.getCRMList(SecurityStore.loginAs === 'employee');
            });
            CRMList = crmList.data.data;
            return CRMList?.find((el) => el.id == currentID) != undefined;
        } catch (errors) {
            messageService.sendErrorList(errors);
            return false;
        }
    }

    public static checkConnection() {
        return SettingsManager.getConnectionCredentials()?.token != null;
    }

    public static formatCurrency(value: number, decimals: boolean = false): string {
        let tVal = decimals ? value : Math.floor(value);
        return tVal.toLocaleString('ru-RU', { useGrouping: true });
    }

    public static numberToWords(num): string {
        const th = [
            ['', 'тысяча', 'миллион', 'миллиард', 'триллион'],
            ['', 'тысячи', 'миллиона', 'миллиарда', 'триллиона'],
            ['', 'тысяч', 'миллионов', 'миллиардов', 'триллионов'],
        ];
        const dg = [
            'ноль',
            'один',
            'два',
            'три',
            'четыре',
            'пять',
            'шесть',
            'семь',
            'восемь',
            'девять',
        ];
        const tn = [
            'десять',
            'одиннадцать',
            'двенадцать',
            'тринадцать',
            'четырнадцать',
            'пятнадцать',
            'шестнадцать',
            'семнадцать',
            'восемнадцать',
            'девятнадцать',
        ];
        const tw = [
            'двадцать',
            'тридцать',
            'сорок',
            'пятьдесят',
            'шестьдесят',
            'семьдесят',
            'восемьдесят',
            'девяносто',
        ];
        const hu = [
            '',
            'сто',
            'двести',
            'триста',
            'четыреста',
            'пятьсот',
            'шестьсот',
            'семьсот',
            'восемьсот',
            'девятьсот',
        ];

        num = parseFloat(num).toFixed(2);
        num = num.toString();
        num = num.replace(/[\, ]/g, '');
        if (num != parseFloat(num)) return 'не число';
        let x = num.indexOf('.');
        if (x == -1) x = num.length;
        if (x > 15) return 'слишком большое число';
        const n = num.split('');
        let str = '';
        let sk = 0;
        for (let i = 0; i < x; i++) {
            if ((x - i) % 3 == 2) {
                if (n[i] == '1') {
                    str += `${tn[Number(n[i + 1])]} `;
                    i++;
                    sk = 1;
                } else if (n[i] != 0) {
                    str += `${tw[n[i] - 2]} `;
                    sk = 1;
                }
            } else if (n[i] != 0) {
                if ((x - i) % 3 == 0) str += `${hu[n[i]]} `;
                else str += `${dg[n[i]]} `;
                sk = 1;
            }
            if ((x - i) % 3 == 1) {
                if (sk) {
                    let index = n[i - 1] == '1' || n[i] > 4 ? 2 : n[i] == '1' ? 0 : 1;
                    str += `${th[index][(x - i - 1) / 3]} `;
                }
                sk = 0;
            }
        }
        let ruble = 'рублей';
        if (n[x - 1] == '1' && n[x - 2] != '1') ruble = 'рубль';
        else if (n[x - 1] > 1 && n[x - 1] < 5 && (n[x - 2] != '1' || x == 1)) ruble = 'рубля';
        str += `${ruble}`;
        if (x != num.length) {
            let kopekNumber = parseInt(num.slice(x + 1));
            if (kopekNumber > 0) {
                str += ', ';
                let kopek = 'копеек';
                let kopekWords = '';
                if (kopekNumber < 20) {
                    kopekWords = `${tn[kopekNumber]} `;
                } else {
                    kopekWords = `${tw[Math.floor(kopekNumber / 10) - 2]} `;
                    if (kopekNumber % 10 > 0) {
                        kopekWords += `${dg[kopekNumber % 10]} `;
                    }
                }
                if (kopekNumber == 1) kopek = 'копейка';
                else if (kopekNumber > 1 && kopekNumber < 5) kopek = 'копейки';
                str += `${kopekWords}${kopek}`;
            }
        }
        str.replace(/\s+/g, ' ');
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}

export { Common };
