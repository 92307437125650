import { BodyTable } from './blocks/bodyTable';
import { Footer } from './blocks/footer';
import { Header } from './blocks/header';
import { HeaderTable } from './blocks/headerTable';

export const Check = ({ data }) => {
    return (
        <>
            <HeaderTable />
            <Header data={data} />
            <BodyTable data={data} />
            <Footer data={data} />
        </>
    );
};
