import { TUser } from 'types/user/user';
import { CRMAPIBase } from '../crmApiBase';
import { InvitationResp } from '../responseModels/invitation/invitationResponse';
import { RequestResult } from '../responseModels/requestResult';
import { InvitationListResp } from '../responseModels/invitation/invitationListResponse';
import { InvitationRequestParams } from 'types/invitation';

class InvitationGroup extends CRMAPIBase {
    /**
     * Создание приглашения (зарегистрирован)
     * @param crmId ID CRM
     * @param phone Номер телефона
     * @returns Результат запроса
     */
    async inviteRegisterUser(
        crmId: number,
        phone: string,
        profile_type: string
    ): Promise<RequestResult<InvitationResp>> {
        const result = await this.post<InvitationResp>('/invitations/invite/registered', {
            crm_id: crmId,
            phone: phone,
            profile_type,
        });
        return result;
    }

    /**
     * Создание приглашения (не зарегистрирован)
     * @param crmId ID CRM
     * @param user Пользователь
     * @returns Результат запроса
     */
    async inviteNotRegisterUser(
        crmId: number,
        user: TUser,
        profile_type: 'worker' | 'customer'
    ): Promise<RequestResult<InvitationResp>> {
        const result = await this.post<InvitationResp>('/invitations/invite/not-registered', {
            crm_id: crmId,
            surname: user.surname,
            name: user.name,
            patronymic: user.patronymic,
            phone: user.phone,
            hex: user.hex,
            picture: user.picture,
            country: user.country,
            address: user.address,
            email: user.email,
            worker_profile_id: user.profile?.id,
            profile_type,
        });
        return result;
    }

    /**
     * Обновление приглашения
     * @param crmId ID CRM
     * @param id ID приглашения
     * @param invitationStatus Статус приглашения (0 - не обработан, 1 - отклонён, 2 - принят)
     * @returns Результат запроса
     */
    async updateInvite(
        crmId: number,
        id: number,
        invitationStatus: 0 | 1 | 2
    ): Promise<RequestResult<InvitationResp>> {
        const result = await this.put<InvitationResp>(`/invitations/${id}`, {
            crm_id: crmId,
            invitation_status: invitationStatus,
        });
        return result;
    }

    /**
     * Получение всех приглашений определенной CRM
     * @returns Результат запроса
     * @param params
     */
    async getInvitationList(
        params: InvitationRequestParams
    ): Promise<RequestResult<InvitationListResp>> {
        const result = await this.get<InvitationListResp>('/invitations', { ...params });
        return result;
    }
}

export { InvitationGroup };
