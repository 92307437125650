import { TOrderField, TPivot, TWorksheetFields } from 'types/worksheets/worksheetFields';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
    worksheetFields: TWorksheetFields;
    pivotsImmutableValue: TPivot[] | null;
}

const initialState: InitialState = {
    worksheetFields: null,
    pivotsImmutableValue: null,
};

interface SetFieldNamePayload {
    index: number;
    value: string;
}
interface SetFieldPivotValuePayload {
    pivotProperty: string;
    fieldIndex: number;
    value: number;
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setWorksheetFields: (state, action: PayloadAction<TWorksheetFields>) => {
            state.worksheetFields = action.payload;
            state.pivotsImmutableValue = action.payload.order_fields.map((field) => field.pivot);
        },

        setFieldName: (state, action: PayloadAction<SetFieldNamePayload>) => {
            const { index, value } = action.payload;
            state.worksheetFields.order_fields[index].name = value;
        },

        setFieldPivotValue: (state, action: PayloadAction<SetFieldPivotValuePayload>) => {
            const { pivotProperty, value, fieldIndex } = action.payload;
            state.worksheetFields.order_fields[fieldIndex].pivot[pivotProperty] = value;

            if (pivotProperty === 'is_used') {
                state.worksheetFields.order_fields[fieldIndex].pivot.is_shown = value;
                if (value === 0) {
                    state.worksheetFields.order_fields[fieldIndex].pivot.is_required = 0;
                    state.worksheetFields.order_fields[fieldIndex].pivot.is_saved = 0;
                }
            }
        },

        setOrderFields: (state, action: PayloadAction<TOrderField[]>) => {
            state.worksheetFields.order_fields = action.payload;
            state.pivotsImmutableValue = action.payload.map((field) => field.pivot);
        },
    },
});

export const { setWorksheetFields, setFieldName, setFieldPivotValue, setOrderFields } =
    settingsSlice.actions;
export default settingsSlice.reducer;
