import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Button, Col, Input, List, Modal, Row, message } from 'antd';
import { includes, map, uniqBy } from 'lodash';

import { GlobalConstants } from '@constants/global';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FILTER_KEYS } from '@organisms/orders/profile/components/orderComment/common/constants';
import {
    resetFilterArrayItem,
    setFilterArrayItem,
} from '@organisms/orders/profile/components/orderComment/common/redux/commentSlice';
import { getComments } from '@organisms/orders/profile/components/orderComment/common/redux/selectors';

const { Search } = Input;

export const Members = ({ isOpen, toggleVisibility, toggleDropdown }) => {
    const dispatch = useDispatch();

    const { data: comments, filterData } = useSelector(getComments);

    const members = useMemo(() => {
        const memberList = map(comments, (com) => com.author);

        return uniqBy(memberList, 'id');
    }, [comments]);

    const isMemberSelected = (id) =>
        includes(
            map(filterData.members, (member) => member.id),
            id
        );

    const handleCancel = () => {
        dispatch(resetFilterArrayItem(FILTER_KEYS.MEMBER));
        toggleVisibility();
        toggleDropdown();
    };

    const handleOk = () => {
        toggleVisibility();
        toggleDropdown();
    };

    const handleSelect = (member) => {
        dispatch(
            setFilterArrayItem({
                item: FILTER_KEYS.MEMBER,
                value: member,
            })
        );

        if (!isMemberSelected(member.id))
            message.success(`${member.name} ${member.surname} | ${member.position} выбран`);
    };

    return (
        <Modal
            title={'Выберите участника'}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelText="Отмена"
            centered
        >
            <Row>
                <Col span={24}>
                    <Search allowClear onChange={() => {}} />
                </Col>
            </Row>
            <List
                dataSource={members}
                itemLayout="horizontal"
                renderItem={(member) => (
                    <List.Item key={member.id} onClick={() => {}}>
                        <Row style={{ width: '100%' }}>
                            <Col span={3}>
                                <Avatar
                                    className="avatar"
                                    src={`${GlobalConstants.BaseUrlForImg}${member.picture}`}
                                />
                            </Col>
                            <Col span={18}>
                                <Row>
                                    <Col>
                                        <h3>{member.name + ' ' + member.surname}</h3>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={3}>
                                <Button
                                    icon={<FontAwesomeIcon icon={faCheck} />}
                                    shape="circle"
                                    type={isMemberSelected(member.id) ? 'primary' : 'default'}
                                    onClick={() => handleSelect(member)}
                                />
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
        </Modal>
    );
};
