import { SyntheticEvent } from 'react';

import { Button } from 'antd';
import { find } from 'lodash';
import { TService } from 'types/service';
import { TServiceCategory } from 'types/serviceCategory';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from './style.module.scss';

type Props = {
    data: TServiceCategory & { products: Array<TService>; categories: Array<TServiceCategory> };
    selectedCatList: Array<{ id: number; name: string; parentId: number | null }>;
    openCategoryCard: (id: number, back: boolean) => void | null;
    onSelect: (category: any) => void | null;
};

function CategoryCard({ data, openCategoryCard, onSelect, selectedCatList }: Props) {
    return (
        <div onClick={() => openCategoryCard(data.id, false)} className={style.folder}>
            <p className={style.count}>{data?.products_count ?? data.services_count}</p>
            <p className={style.text}>{data.name}</p>
            <Button
                icon={<FontAwesomeIcon icon={faCheck} />}
                shape="circle"
                className={style.select_btn}
                type={find(selectedCatList, (cat) => cat.id === data.id) ? 'primary' : 'default'}
                onClick={(e) => {
                    e.stopPropagation();
                    onSelect(data);
                }}
            />
        </div>
    );
}

export { CategoryCard };
