import { createSelector } from '@reduxjs/toolkit';

import { LOADING_IDS } from './loadingIds';

const getLoadings = (state) => state.loadings.data;

export const getOrderLoading = createSelector(
    getLoadings,
    (loadings) => loadings[LOADING_IDS.ORDER]
);

export const getWorksheetsLoading = createSelector(
    getLoadings,
    (loadings) => loadings[LOADING_IDS.WORKSHEETS]
);

export const getOrderObjectsLoading = createSelector(
    getLoadings,
    (loadings) => loadings[LOADING_IDS.ORDER_OBJECTS]
);
