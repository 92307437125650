import { faBarcode, faPlusCircle, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

export const TOPIC_CATEGORIES = {
    PRODUCT: 'product',
    SERVICE: 'service',
    CUSTOM: 'custom',
};

export const TOPIC_ICONS = {
    [TOPIC_CATEGORIES.PRODUCT]: faBarcode,
    [TOPIC_CATEGORIES.SERVICE]: faScrewdriverWrench,
    [TOPIC_CATEGORIES.CUSTOM]: faPlusCircle,
};
