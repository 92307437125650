import { useDispatch, useSelector } from 'react-redux';

import { useReactive } from 'ahooks';
import { Row, Table } from 'antd';
import { TOrderFieldExtended, TWorksheetFields } from 'types/worksheets/worksheetFields';

import RenderIf from '@common/RenderIf';

import {
    setMutableOrderFieldValue,
    setMutableRequiredOrderFieldValue,
} from '../../common/redux/orderSlice';
import {
    getMutableOrderFields,
    getMutableRequiredOrderFields,
    getSelectedWorksheet,
} from '../../common/redux/selectors';
import { getColumns, getColumnsReq } from './common/data';

export const FieldsTable = ({ isRequiredFields, hideOrderObject = false }) => {
    const dispatch = useDispatch();
    const state = useReactive({
        focusedInputIndex: null,
    });

    const selectedWorksheet: TWorksheetFields = useSelector(getSelectedWorksheet);
    const mutableOrderFields: TOrderFieldExtended[] = useSelector(getMutableOrderFields);
    const mutableRequiredOrderFields: TOrderFieldExtended[] = useSelector(
        getMutableRequiredOrderFields
    );

    const handleChangeFieldValue = (value: string, index: number) => {
        dispatch(setMutableOrderFieldValue({ value, index }));
    };

    const handleChangeRequiredFieldValue = (value: string, index: number) => {
        dispatch(setMutableRequiredOrderFieldValue({ value, index }));
    };

    const handleInputFocus = (index) => {
        state.focusedInputIndex = index;
    };

    const handleTextAreaBlur = () => {
        state.focusedInputIndex = null;
    };

    const columns = getColumns({
        worksheetName: selectedWorksheet?.name,
        onChange: handleChangeFieldValue,
        focusedInputIndex: state.focusedInputIndex,
        onInputFocus: handleInputFocus,
        onTextareaBlur: handleTextAreaBlur,
    });
    const columnsReq = getColumnsReq({
        onChange: handleChangeRequiredFieldValue,
        hideOrderObject,
        focusedInputIndex: state.focusedInputIndex,
        onInputFocus: handleInputFocus,
        onTextareaBlur: handleTextAreaBlur,
    });

    return (
        <>
            <RenderIf condition={!isRequiredFields}>
                <Row className={'order-details'}>
                    <Table
                        columns={columns}
                        bordered
                        dataSource={mutableOrderFields}
                        pagination={false}
                        size={'small'}
                    />
                </Row>
            </RenderIf>
            <RenderIf condition={isRequiredFields}>
                <Row className={'order-details'}>
                    <Table
                        columns={columnsReq}
                        bordered
                        dataSource={mutableRequiredOrderFields}
                        pagination={false}
                        size={'small'}
                    />
                </Row>
            </RenderIf>
        </>
    );
};
