import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { Divider, List } from 'antd';
import { TUser } from 'types/user/user';

import { UserCard } from '@atoms/listsCard/userCard';
import { SettingsManager } from '@classes/settingsManager';
import { InvitationStatuses } from '@enums/invitationStatuses';
import { LastIdStore } from '@pages/lastIdStore';

type TProps = {
    noNavigation?: boolean;
    inviteStatus?: InvitationStatuses;
    userList: Array<TUser>;
    selectList: Array<{ id: number; name: string; profileId: number }>;
    setSelectList: (value: Array<{ id: number; name: string; profileId: number }>) => void;
    isWorker: boolean;
    col: number;
    currentMetaTotal?: number;
    handleChangePage: () => void;
    preventOpenCard?: boolean;
    infinityScrollHeight?: string;
};

function filterUserListByInviteStatus(
    userList: TUser[],
    inviteStatus: InvitationStatuses,
    isWorker: boolean
): TUser[] {
    if (isWorker) {
        switch (inviteStatus) {
            case InvitationStatuses.accepted:
                return userList.filter(
                    (user) =>
                        (user?.worker_profile && user.invitation_status_text === '') ||
                        (user.invitation_status_text === 'Принято' && user?.worker_profile)
                );
            case InvitationStatuses.declined:
                return userList.filter(
                    (user) => !user?.worker_profile && user.invitation_status_text === 'Отклонено'
                );
            case InvitationStatuses.pending:
                return userList.filter(
                    (user) =>
                        !user?.worker_profile && user.invitation_status_text === 'Не обработано'
                );
            default:
                return userList;
        }
    } else {
        switch (inviteStatus) {
            case InvitationStatuses.accepted:
                return userList.filter((user) => user?.customer_profile);
            // case InvitationStatuses.declined:
            //     return userList.filter((user) => user?.customer_profile != null && user.invitation_status_text === 'Отклонено');
            case InvitationStatuses.pending:
                return userList.filter(
                    (user) =>
                        !user?.customer_profile && user.invitation_status_text === 'Не обработано'
                );
            default:
                return userList;
        }
    }
}

function RenderUserList({
    noNavigation = false,
    inviteStatus,
    userList,
    selectList,
    setSelectList,
    col,
    isWorker,
    currentMetaTotal,
    handleChangePage,
    preventOpenCard,
    infinityScrollHeight = '',
}: TProps): JSX.Element {
    const navigate = useNavigate();
    const creds = SettingsManager.getConnectionCredentials();
    const filteredByInviteStatusUserList = filterUserListByInviteStatus(
        userList,
        inviteStatus,
        isWorker
    );

    function openCard(id: number) {
        LastIdStore.setLastUserId(id);
        isWorker
            ? navigate(`/lk/worker/crm/${creds.crmID}/workers/${id}`)
            : navigate(`/lk/worker/crm/${creds?.crmID}/customers/${id}`);
    }

    function handleUserClick(
        select: boolean,
        userId: number,
        userFirstName: string,
        userLastName: string,
        profileId: number
    ) {
        if (select || noNavigation) {
            !selectList.find((sli) => sli.id == userId)
                ? setSelectList([
                      ...selectList,
                      {
                          id: userId,
                          name: userFirstName + ' ' + userLastName,
                          profileId: profileId,
                      },
                  ])
                : setSelectList(selectList.filter((sli) => sli.id != userId));
        } else {
            openCard(userId);
        }
    }

    return (
        <InfiniteScroll
            dataLength={userList.length}
            next={handleChangePage}
            hasMore={userList.length < currentMetaTotal}
            loader={<Divider plain>Загрузка...</Divider>}
            endMessage={<Divider plain>Все клиенты загружены :^)</Divider>}
            height={infinityScrollHeight}
        >
            <List
                className="worker-card-list"
                dataSource={filteredByInviteStatusUserList}
                itemLayout="horizontal"
                grid={{ column: col }}
                renderItem={(item) => (
                    <List.Item>
                        <UserCard
                            user={item}
                            handleUserClick={handleUserClick}
                            selectList={selectList}
                            preventOpenCard={preventOpenCard}
                        />
                    </List.Item>
                )}
            />
        </InfiniteScroll>
    );
}

export { RenderUserList };
