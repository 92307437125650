import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useReactive, useToggle, useUpdateEffect } from 'ahooks';
import { Col, Row, message } from 'antd';
import { filter, find, includes, isEmpty, map } from 'lodash';

import RenderIf from '@common/RenderIf';
import { ContentShareModal } from '@components/common/contentShareModal';
import { FunctionalMenuWrapper } from '@components/common/functionalMenuWrapper';
import { getSelectedMessagesString } from '@helpers/getSelectedMessagesString';
import { FunctionalMenu } from '@molecules/functionalMenu';

import { CommentTextArea } from './common/components';
import { CommentEmployeesPicker } from './common/components/commentEmployeesPicker';
import { getInitialMenuItemsForComments } from './common/data';
import { getFilteredDataForComments } from './common/helpers/getFilteredDataForComments';
import {
    clearSelectedComments,
    copyToClipboard,
    deleteSelectedComments,
    saveEditedComment,
    selectRecipients,
    toggleEdit,
    toggleReply,
} from './common/redux/commentSlice';
import { getComments } from './common/redux/selectors';
import { CommentCreating, CommentItem, CommentsFilter } from './partials';

import './orderComment.scss';

export const OrderComment: React.FC = () => {
    const state = useReactive({
        editedCommentValue: null,
        isEmployeesModalVisible: false,
        repliedComment: null,
    });

    const [initialMenuItemsData, setInitialMenuItemsData] = useState([]);

    const {
        data: comments,
        selectedComments,
        selectedRecipients,
        isEditMode,
        isReplyMode,
    } = useSelector(getComments);

    const dispatch = useDispatch();

    const [isEmployeesModalVisible, { toggle: toggleEmployeesModal }] = useToggle(false);
    const [isContentShareModalVisible, { toggle: toggleContentShareModal }] = useToggle(false);

    const onEditedCommentValueChange = (value: string) => {
        state.editedCommentValue = value;
    };

    const handleDeleteSelectedItems = () => {
        dispatch(deleteSelectedComments());
    };

    const handleDeselectAllItems = () => {
        dispatch(clearSelectedComments());
    };

    const handleCopyComment = () => {
        dispatch(copyToClipboard());
        message.success('Скопировано');
    };

    const handleSaveEditedComment = () => {
        dispatch(saveEditedComment({ id: selectedComments[0], value: state.editedCommentValue }));
        message.success('Сохранено');
    };

    const handleCancelEditedComment = () => {
        dispatch(toggleEdit(false));
        state.editedCommentValue = null;
        message.info('Отменено');
    };

    const handleToggleReplyMode = () => {
        dispatch(toggleReply(true));
    };

    const toggleEditMode = () => {
        dispatch(toggleEdit(true));
    };

    useEffect(() => {
        const allDataComments = getInitialMenuItemsForComments({
            handleDeselectAllItems,
            handleDeleteSelectedItems,
            handleCopyComment,
            toggleEmployeesModal,
            toggleContentShareModal,
            toggleEditMode,
            handleSaveEditedComment,
            handleCancelEditedComment,
            handleToggleReplyMode,
        });

        const selectedCommentsData = filter(comments, (com) => includes(selectedComments, com.id));
        const menuDataComments = getFilteredDataForComments(
            selectedCommentsData,
            allDataComments,
            isEditMode
        );

        setInitialMenuItemsData(menuDataComments);
    }, [selectedComments, isEditMode, isReplyMode]);

    useUpdateEffect(() => {
        if (!isEditMode) return;

        const {
            body: { text },
        } = find(comments, (com) => com.id === selectedComments[0]);

        state.editedCommentValue = text;
    }, [isEditMode]);

    return (
        <>
            <Row className="order-comment">
                <CommentsFilter />
                <Col span={24} className="last-comments">
                    {map(comments, (com, id) => {
                        return <CommentItem key={id} com={com} />;
                    })}

                    <CommentCreating visible={!isEditMode} />

                    <RenderIf condition={isEditMode}>
                        <CommentTextArea
                            commentValue={state.editedCommentValue}
                            onCommentValueChange={onEditedCommentValueChange}
                        />
                    </RenderIf>
                </Col>
            </Row>

            <RenderIf condition={isEmployeesModalVisible}>
                <CommentEmployeesPicker
                    isModalOpen={isEmployeesModalVisible}
                    setIsModalOpen={toggleEmployeesModal}
                    currentItems={selectedRecipients}
                    setCurrentItems={(data) => dispatch(selectRecipients(data))}
                />
            </RenderIf>
            <RenderIf condition={isContentShareModalVisible}>
                <ContentShareModal
                    visible={isContentShareModalVisible}
                    handleCancel={toggleContentShareModal}
                    message={getSelectedMessagesString(comments, selectedComments)}
                />
            </RenderIf>
            <RenderIf condition={!isEmpty(selectedComments)}>
                <FunctionalMenuWrapper>
                    <FunctionalMenu
                        items={initialMenuItemsData.filter((mi) => mi.key !== 'delete')}
                        dropdownItems={initialMenuItemsData.filter((mi) => mi.key === 'delete')}
                        selected={selectedComments.length}
                    />
                </FunctionalMenuWrapper>
            </RenderIf>
        </>
    );
};
