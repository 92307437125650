import { SettingsManager } from "@classes/settingsManager";
import { TTask, TTaskCategory } from "../common/types";
import { TaskUseCases } from "../profile/taskProfile";
import { useNavigate, useParams } from "react-router-dom";
import { useReactive } from "ahooks";
import { messageService } from "@classes/messageService";
import { CRMAPIManager } from "@classes/crmApiManager";
import { TaskCategoryResp } from "@api/responseModels/task/taskCategoryResponse";
import { makeEmptyTaskCategory } from "../common/data";
import { useEffect } from "react";
import { Loader } from "@atoms/loader";
import { FunctionalMenu } from "@molecules/functionalMenu";
import { faCheck, faXmark, faEdit, faPersonSnowboarding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import { ImgUploader } from "@organisms/imgUploader/imgUploader";
import TextArea from "antd/lib/input/TextArea";
import { HeaderBreadcrumbProfiles } from "@molecules/breadcrumbs/profilesBreadcrumbs/HeaderProfilesBreadcrumb";
import "../profile/style.scss";
import { TaskCategoryPicker } from "@organisms/pickers/taskCategoryPicker";

type TProps = {
    useCase?: TaskUseCases 
};

type TState = {
    isLoading: boolean;
    isTaskCategoryPickerVisible: boolean;
    case: TaskUseCases;
    category: TTaskCategory & { tasks: Array<TTask>; categories: Array<TTaskCategory> };
}

const TaskCategoryProfile = ({ useCase }: TProps): JSX.Element => {
    const state = useReactive<TState>({
        isLoading: true,
        isTaskCategoryPickerVisible: false,
        case: useCase ?? TaskUseCases.Profile,
        category: null,
    });
    let { taskCategoryID } = useParams();
    const navigate = useNavigate();
    const creds = SettingsManager.getConnectionCredentials();
    
    async function loadExistingTaskCategory() {
        state.isLoading = true;
        try {
            const cat = await CRMAPIManager.request<TaskCategoryResp>(async (api) => {
                return await api.getTaskCategory(creds?.crmID, taskCategoryID == 'null' ? null : Number(taskCategoryID));
            });
            if (cat.errorMessages) throw cat.errorMessages;
            state.category = cat.data.data;
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    function handleEdit() {
        state.case = TaskUseCases.Edit;
        navigate(`/lk/worker/crm/${creds.crmID}/task-categories/${taskCategoryID}/edit`);
    }

    function validate(): boolean {
        if (state.category.name == '' || state.category.name === null) {
            messageService.sendError('Имя задачи не может быть пустым');
            return false;
        }
        return true;
    }

    async function handleSave() {
        if (!validate()) return;
        state.isLoading = true;
        try {
            const resp = await CRMAPIManager.request<TaskCategoryResp>(async (api) => {
                if (state.case == TaskUseCases.Create) {
                    return await api.saveTaskCategory(state.category.name, creds?.crmID, state.category.parent_task_category_id, state.category.picture as File);
                }
                else {
                    return await api.updateTaskCategory(state.category.name, creds?.crmID, state.category.id, state.category.parent_task_category_id, state.category.picture as File);
                }
            });
            if (resp.errorMessages) throw resp.errorMessages;
            state.category = resp.data.data;
            state.case = TaskUseCases.Profile;
            navigate(`/lk/worker/crm/${creds.crmID}/task-categories/${resp.data.data.id}`);
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    async function handleCancel() {
        if (state.case == TaskUseCases.Create) navigate(`/lk/worker/crm/${creds.crmID}/task-categories`);
        else {
            state.case = TaskUseCases.Profile;
            navigate(`/lk/worker/crm/${creds.crmID}/task-categories/${taskCategoryID}`);
            await loadExistingTaskCategory();
        }
    }

    function setEmptyTaskCategory() {
        state.category = {...makeEmptyTaskCategory(creds.crmID), tasks: [], categories: []};
    }

    function handleImageSelect(file: File) {
        state.category.picture = file;
        state.category.picture["src"] = URL.createObjectURL(state.category.picture);
    }

    function handleImageDelete() {
        state.category.picture = '/images/no_image.png';
    }

    async function initializeTaskCategory() {
        if (state.case == TaskUseCases.Create) setEmptyTaskCategory();
        else await loadExistingTaskCategory();
    }

    useEffect(() => {
        initializeTaskCategory();
    }, []);

    return (
        <div className="profile-template task-category-profile">
            {state.isLoading && <Loader />}
            {state.isTaskCategoryPickerVisible && (
                <TaskCategoryPicker
                    isModalOpen={state.isTaskCategoryPickerVisible}
                    setIsModalOpen={(isOpen) => state.isTaskCategoryPickerVisible = isOpen}
                    multiple={false}
                    selectedByDefault={state.category?.parent_task_category_id == null ? [] : [{ id: state.category.parent_task_category_id, name: null, parentId: null }]}
                    setSelected={(selected) => {
                        if (selected.length > 0) state.category.parent_task_category_id = selected[0].id;
                        else state.category.parent_task_category_id = null;
                    }}
                    exception={state.category.id}
                />
            )}
            <FunctionalMenu
                items={state.case != TaskUseCases.Profile
                    ? [
                        {
                            key: 'save',
                            label: state.case == TaskUseCases.Create ? 'Создать' : 'Сохранить',
                            icon: <FontAwesomeIcon icon={faCheck} />,
                            onClick: handleSave,
                        },
                        {
                            key: 'abort',
                            label: 'Отменить',
                            icon: <FontAwesomeIcon icon={faXmark} />,
                            onClick: handleCancel,
                        },
                    ] : [
                        {
                            key: 'edit',
                            label: 'Редактировать',
                            icon: <FontAwesomeIcon icon={faEdit} />,
                            onClick: handleEdit,
                        },
                    ]}
                dropdownItems={[]}
            />
            <HeaderBreadcrumbProfiles
                dataIcon={faPersonSnowboarding}
                dataId={state.category?.id}
                dataTitle={'Категория задач'}
                title={'Категория задач'}
                route={`/lk/worker/crm/${creds.crmID}/task-categories`}
                isForInvitation={false}
                dataName={'Новая категория'}
                isSpecialty={false}
            />
            <div className="profile-inner">
                <Row className="profile-row">
                    <Col className="avatar outer-box" flex={1}>
                        <ImgUploader
                            photoSrc={typeof state.category?.picture == 'string' ? state.category?.picture as string : state.category?.picture["src"]}
                            onImageSelect={handleImageSelect}
                            isEdit={state.case != TaskUseCases.Profile}
                            onImageDelete={handleImageDelete}
                        />
                    </Col>
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Название</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                {state.case != TaskUseCases.Profile ? (
                                    <TextArea
                                        autoSize={{ minRows: 2, maxRows: 5 }}
                                        value={state.category?.name}
                                        onChange={(e) => {
                                            state.category.name = e.target.value;
                                        }}
                                    />
                                ) : (
                                    <p>{state.category?.name}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="outer-box">
                        <Row className="label-row">
                            <Col>
                                <p>ID родителя</p>
                            </Col>
                        </Row>
                        <Row
                            className="value-row"
                            onClick={() => {
                                if (state.case != TaskUseCases.Profile) state.isTaskCategoryPickerVisible = true;
                            }}
                        >
                            <Col>
                                {state.case != TaskUseCases.Profile ? (
                                    <a>
                                        {state.category?.parent_task_category_id ?? 'Не выбрана'}
                                    </a>
                                ) : (
                                    <p>{state.category?.parent_task_category_id ?? 'Не выбрана'}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {state.case != TaskUseCases.Create && (
                    <Row className="profile-row two-blocks">
                        <Col className="outer-box">
                            <Row className="label-row">
                                <Col>
                                    <p>Задачи</p>
                                </Col>
                            </Row>
                            <Row className="value-row">
                                <Col>
                                    <p>{state.category?.tasks.length ?? '-'}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="outer-box">
                            <Row className="label-row">
                                <Col>
                                    <p>Подкатегории</p>
                                </Col>
                            </Row>
                            <Row className="value-row">
                                <Col>
                                    <p>{state.category?.categories.length ?? '-'}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export { TaskCategoryProfile };