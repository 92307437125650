import { useLocation, useNavigate } from 'react-router-dom';

import { MenuProps, Tooltip } from 'antd';
import { observer } from 'mobx-react';

import { Permissions } from '@constants/permissions';
import {
    faArrowRightFromBracket,
    faBarcode,
    faBookOpen,
    faBriefcase,
    faDollar,
    faGear,
    faHouse,
    faPersonRunning,
    faPlug,
    faScrewdriverWrench,
    faTableList,
    faUserTie,
    faUsers,
    faUsersGear,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppFooter from '@molecules/appFooter/appFooter';
import { TAB_ROUTES } from '@organisms/workers/common/constants';
import { LastIdStore } from '@pages/lastIdStore';

import { SettingsManager } from '../../../classes/settingsManager';

const ApelsinFooter = observer((): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const creds = SettingsManager.getConnectionCredentials();
    const loginAs = localStorage.getItem('loginAs');

    const _unconnectedItems: MenuProps['items'] = [
        {
            icon: (
                <Tooltip placement="topRight" title="Подключение">
                    <FontAwesomeIcon icon={faPlug} />
                </Tooltip>
            ),
            key: 'login',
            title: 'Подключение',
            onClick: () => navigate('/login'),
        },
        {
            disabled: !creds?.token,
            icon: <FontAwesomeIcon icon={faBriefcase} />,
            key: 'lk',
            onClick: () => navigate('/lk'),
            title: 'Выбор CRM',
        },
        {
            disabled: !creds?.token,
            icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
            key: 'logout',
            onClick: () => navigate('/logout'),
            title: 'Закрыть сессию',
        },
    ];

    const _navLinks = [
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.CrmTotal),
            icon: <FontAwesomeIcon icon={faHouse} />,
            key: 'crm',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}`),
            title: 'Профиль CRM',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.WorkerProfileList),
            icon: <FontAwesomeIcon icon={faUsersGear} />,
            key: 'workers',
            onClick: () =>
                navigate(`/lk/worker/crm/${creds.crmID}/workers/tabs/${TAB_ROUTES.WORKING_NOW}`),
            title: 'Сотрудники',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.CustomerProfileList),
            icon: <FontAwesomeIcon icon={faUsers} />,
            key: 'customers',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/customers`),
            title: 'Клиенты',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.OrderList),
            icon: <FontAwesomeIcon icon={faTableList} />,
            key: 'orders',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/orders`),
            title: 'Заказы',
        },
        {
            disabled: !creds?.crmID,
            icon: <FontAwesomeIcon icon={faPersonRunning} />,
            key: 'tasks',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/tasks`),
            title: 'Задачи',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.ProductList),
            icon: <FontAwesomeIcon icon={faBarcode} />,
            key: 'products',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/products`),
            title: 'Товары',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.ServiceList),
            icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
            key: 'services',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/services`),
            title: 'Услуги',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.TransactionList),
            icon: <FontAwesomeIcon icon={faDollar} />,
            key: 'transactions',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/transactions`),
            title: 'Транзакции',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.LogList),
            icon: <FontAwesomeIcon icon={faBookOpen} />,
            key: 'logs',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/logs`),
            title: 'Журнал',
        },
        {
            disabled: creds?.token == null,
            icon: <FontAwesomeIcon icon={faUserTie} />,
            key: 'profile',
            onClick: () => {
                LastIdStore.setLastUserId(null);
                if (location.pathname.includes('crm')) {
                    navigate(`/lk/worker/crm/${creds.crmID}/workers/${creds.userId}`);
                } else {
                    navigate(`/lk/worker/profile`);
                }
            },
            title: 'Профиль',
        },
        {
            disabled: !creds?.token || Permissions.checkPermission(Permissions.CrmList),
            icon: <FontAwesomeIcon icon={faGear} />,
            key: 'settings',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/settings`),
            title: 'Настройки',
        },
        {
            disabled: !creds?.token || Permissions.checkPermission(Permissions.CrmList),
            icon: <FontAwesomeIcon icon={faBriefcase} />,
            key: 'lk',
            onClick: () => navigate('/lk'),
            title: 'Выбор CRM',
        },
        {
            icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
            key: 'logout',
            onClick: () => navigate('/logout'),
            title: 'Выйти',
        },
    ];

    const _adminNavLinks = [
        {
            disabled: !creds?.token || Permissions.checkPermission(Permissions.CrmList),
            icon: <FontAwesomeIcon icon={faBriefcase} />,
            key: 'lk',
            onClick: () => navigate('/lk'),
            title: 'Выбор CRM',
        },
        {
            icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
            key: 'logout',
            onClick: () => navigate('/logout'),
            title: 'Выйти',
        },
    ];

    return (
        <AppFooter
            items={
                creds?.token
                    ? loginAs === 'admin'
                        ? _adminNavLinks
                        : _navLinks
                    : _unconnectedItems
            }
        />
    );
});

export { ApelsinFooter };
