import * as process from "process";

export class GlobalConstants {
    /// Точки входа
    public static MainRoot = 'apelsin-root';

    /**
     * URL бэкенда
     */
    public static BaseUrl =  process.env.API_URL + '/api/v1';
    /**
     * URL бэкенда для storage
     */
    public static BaseUrlForImg = process.env.API_URL;
     /**
     * Иконка пустой аватарки на случай если URL отсутствует в данных клиента
     */
     public static NoImageUrl = '/images/no_image.png';
    /**
     * Стандартный таймаут запросов
     */
    public static RequestTimeout = 30000;
    /**
     * Перерыв между запросами кода авторизации
     */
    public static GetCodeCooldown = 50000;
    /**
     * Таймаут для поисковых строк
     */
    public static SearchTimeout = 600;

    /// Хранимые свойства
    /**
     * Хранит данные сессии, TCredentials
     */
    public static ConnectionCredentialsProperty = 'Apelsin.Properties.ConnectionCredentials';

    public static UploadImg = {
        maxWidth: 5120,
        maxHeight: 5120,
        minWidth: 200,
        minHeight: 200,
        maxSize: 10485760
    }
    
    public static CrmOrderinUrl = 4;

    public static notificationDuration = 3;

    public static ElementsPerPage = 20;
}
