import { Brigade } from './types';

export const initialBrigadeList: Brigade[] = [
    {
        id: '1',
        name: 'ул. Пушкина',
        workplaces: [],
        image: null,
    },
    {
        id: '2',
        name: 'ул. Есенина',
        workplaces: [],
        image: null,
    },
    {
        id: '3',
        name: 'ул. Горького',
        workplaces: [],
        image: null,
    },
    {
        id: '4',
        name: 'ул. Достоевского',
        workplaces: [],
        image: null,
    },
];
