import { Button, Divider, Form, Row, Col, InputRef, message } from "antd";
import { useNavigate } from 'react-router-dom';
import { MaskedInput } from "antd-mask-input";
import * as React from "react";
import { SyntheticEvent, useEffect, useRef } from "react";
import { CRMAPI } from "../../../api/crmApi";
import { AfterCodeActions } from "../../../api/enums/afterCodeActions";
import { RequestResult } from "../../../api/responseModels/requestResult";
import { ConnectionResp } from "../../../api/responseModels/security/connectionResponse";
import { SettingsManager } from "../../../classes/settingsManager";
import { GlobalConstants } from "../../../constants/global";
import { Loader } from "../../atoms/loader";
import {rootStore} from "@store/rootStore/instanse";
import { useReactive } from "ahooks";

type TState = {
    afterCodeAction: AfterCodeActions;
    cooldown: number;
    codeValid: boolean;
    codeValue: string;
    isLoading: boolean;
    lastCodeSend: number;
    numberLocked: boolean;
    numberValid: boolean;
    numberValue: string;
}

const ConnectionForm = (): JSX.Element => {
    const state = useReactive<TState>({
        afterCodeAction: null,
        cooldown: 0,
        codeValid: false,
        codeValue: null,
        isLoading: true,
        lastCodeSend: null,
        numberLocked: false,
        numberValid: false,
        numberValue: ""
    });
    const codeRef = useRef<InputRef>(null);
    const codeMask = /^[0-9]{4}$/;
    const phoneMask = /^[0-9]{10}$/;
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    function calculateCooldown(): number {
        if(state.lastCodeSend) {
            const left = GlobalConstants.GetCodeCooldown - Math.floor(Date.now() - state.lastCodeSend);
            if(left <= 0){
                state.cooldown = 0;
                return 0;
            }
            return Math.floor(left/1000);
        }
        return 0;
    }

    function onCodeChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const newValue = e.target.value;
        state.codeValid = newValue && codeMask.test(newValue.toString());
        state.codeValue = newValue;
        
    }

    function onNumberChange(e: SyntheticEvent & { maskedValue: string; unmaskedValue: string }) {
        const newValue = e.unmaskedValue;
        state.codeValid = false;
        state.numberLocked = false;
        state.numberValid = newValue && phoneMask.test(newValue);
        state.numberValue = newValue;
    }

    async function onSendCode() {
        if(state.cooldown != 0) return;
        const api = new CRMAPI();
        let result: RequestResult<ConnectionResp>;
        try {
            result = await api.connection(state.numberValue);
            if (result.errorMessages) throw result.errorMessages;
            state.afterCodeAction = result.data.require_method;
            //result = await api.sendCode(numberValue);
            //if (result.errorMessage) throw new Error(result.errorMessage);
            state.lastCodeSend = Date.now();
            state.numberLocked = true;
            messageApi.info("Код отправлен.");
        }
        catch (errors) {
            messageApi.error(errors);
        }
    }

    useEffect(() => {
        if (state.lastCodeSend != null && state.numberLocked)
            codeRef.current!.focus({ cursor: "start" });
    }, [state.lastCodeSend, state.numberLocked])
    

    async function onSubmitCode() {
        const api = new CRMAPI();
        try {
            const result =
                state.afterCodeAction == AfterCodeActions.login
                    ? await api.login(state.numberValue, state.codeValue)
                    : await api.register(state.numberValue, state.codeValue);
            if (result.errorMessages) throw result.errorMessages;
            SettingsManager.setConnectionCredentials({token: result.data?.token, crmID: null});
            const user = await api.currentUser();
            rootStore.currentUserStore.setUser(user.data.data);
            SettingsManager.updateConnectionCredentials({userId: user.data.data.id, workerProfileId: user.data.data?.worker_profile?.id});
            navigate("/lk");
            messageApi.success('Удачный логин.');
        }
        catch (errors) {
            messageApi.error(errors);
        }
    }

    function unlockNumber() {
        state.codeValid = false;
        state.codeValue = null;
        state.numberLocked = false;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            state.cooldown = calculateCooldown();
        }, 1000);
        state.isLoading = false;
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <div className="app-connection-authorization">
            {state.isLoading && <Loader />}
            {contextHolder}
            <Divider>CRM <i className="text-logo"><span className="text-logo" style={{fontSize: 30}}>А</span>пельсин</i></Divider>
            <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="vertical"
                style={{
                    paddingTop: 20
                }}
            >
                <Form.Item hidden={state.numberLocked}>
                    <h3 className="text-center">Введите номер телефона <br/> для входа или регистрации</h3>
                </Form.Item>

                <Row justify="center" gutter={16}>
                    <Col span={!state.numberLocked ? 13 : 12}>
                        <Form.Item label={state.lastCodeSend == null || !state.numberLocked ? '' : "Номер телефона: "}>
                            <MaskedInput
                                autoFocus={true}
                                disabled={state.numberLocked}
                                mask="(000)-000-00-00"
                                //maxLength={10}
                                onChange={onNumberChange}
                                placeholder="(___)-___-__-__"
                                prefix="+7"
                                pattern="[0-9]*"
                                inputMode='numeric'
                                onPressEnter={async () => {
                                    if(state.numberValid && state.cooldown == 0) {
                                        await onSendCode();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12} hidden={state.lastCodeSend == null || !state.numberLocked}>
                        <Form.Item
                            label="Код авторизации: "
                        >
                            <MaskedInput
                                ref={codeRef}
                                disabled={!state.numberValid}
                                mask="0000"
                                onChange={onCodeChange}
                                placeholder="____"
                                pattern="[0-9]*"
                                inputMode='numeric'
                                onPressEnter={() => {
                                    if(state.codeValid) {
                                        onSubmitCode();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>


            </Form>
            <Form wrapperCol={{ span: 16 }}>
                <Row justify="center" gutter={16}>
                    <Col span={12}>
                        <Form.Item>
                            <Button
                                danger
                                disabled={!state.numberLocked}
                                onClick={unlockNumber}
                                type="default"
                            >
                                Отмена
                            </Button>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item hidden={state.lastCodeSend == null || !state.numberLocked}>
                            <Button
                                disabled={!state.codeValid}
                                onClick={onSubmitCode}
                                type="primary"
                            >
                                Подтвердить
                            </Button>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                disabled={!state.numberValid || state.cooldown > 0}
                                onClick={onSendCode}
                                type="default"
                            >
                                {"Отправить код" + (state.cooldown > 0 ? " заново (" + state.cooldown + ")" : "")}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export { ConnectionForm };
