import { useState } from 'react';

import { Button, Col, Row, Tooltip } from 'antd';
import { isNil } from 'lodash';

import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import { faMessage, faMinus, faPhone, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TransactionModal } from './components/transactionModal';

export const ProfileBalance = ({ balance, userWorkerProfileId, prepareTransaction }) => {
    const [transactionType, setTransactionType] = useState<'Пополнение' | 'Списание'>('Пополнение');
    const [transactionOpen, setTransactionOpen] = useState<boolean>(false);

    const creds = SettingsManager.getConnectionCredentials();

    function topUpBalance() {
        if (creds?.workerProfileId == userWorkerProfileId) {
            messageService.sendInfo('Нельзя пополнить свой баланс.');
            return;
        } else if (creds?.workerProfileId == null) {
            messageService.sendError('У вас отсутствует профиль сотрудника.');
            return;
        }
        if (isNil(userWorkerProfileId)) {
            messageService.sendError('У получателя отсутствует профиль сотрудника');
            return;
        }
        setTransactionType('Пополнение');
        setTransactionOpen(true);
    }

    function chargeBalance() {
        if (creds?.workerProfileId == userWorkerProfileId) {
            messageService.sendInfo('Нельзя списать со своего баланса.');
            return;
        } else if (creds?.workerProfileId == null) {
            messageService.sendError('У вас отсутствует профиль сотрудника.');
            return;
        }
        if (isNil(userWorkerProfileId)) {
            messageService.sendError('У получателя отсутствует профиль сотрудника');
            return;
        }
        setTransactionType('Списание');
        setTransactionOpen(true);
    }

    return (
        <>
            <TransactionModal
                transactionType={transactionType}
                open={transactionOpen}
                setIsOpen={setTransactionOpen}
                prepareTransaction={prepareTransaction}
            />
            <Col className="balance-contact outer-box">
                <Row className="main-row">
                    <Col flex={1}>
                        <Tooltip title="Написать">
                            <Button
                                className="round-button"
                                icon={<FontAwesomeIcon icon={faMessage} />}
                                shape="circle"
                            />
                        </Tooltip>
                    </Col>
                    <Col flex={2}>
                        <Row className="label-row">
                            <Col>
                                <p>Баланс:</p>
                            </Col>
                        </Row>
                        <Row className="balance-value-row">
                            <Col>
                                <p>{balance}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col flex={1}>
                        <Tooltip title="Позвонить">
                            <Button
                                className="round-button"
                                icon={<FontAwesomeIcon icon={faPhone} />}
                                shape="circle"
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <Row className="balance-actions">
                    <Col>
                        <Button
                            disabled={creds.crmID == null}
                            className="balance-add round-button"
                            icon={<FontAwesomeIcon icon={faPlus} />}
                            onClick={topUpBalance}
                        >
                            Пополнить
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            disabled={creds.crmID == null}
                            className="balance-subtract round-button"
                            icon={<FontAwesomeIcon icon={faMinus} />}
                            onClick={chargeBalance}
                        >
                            Списать
                        </Button>
                    </Col>
                </Row>
            </Col>
        </>
    );
};
