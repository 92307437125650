import { faEdit, faSave, faTrash, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput } from '@molecules/tables/inputs/searchInput';
import { Button, Checkbox, Input, InputNumber, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TOrderDetails } from 'types/Orders/details';
import { TOrderProduct } from 'types/Orders/products';
import { TOrderService } from 'types/Orders/services';

type HandleSearchInput = (
    item: TOrderProduct | TOrderService,
    type: 'product' | 'service',
    rowIndex: number
) => void;

type HandleChangeQuantity = (
    rowIndex: number,
    newQuantity: number,
    type: 'product' | 'service'
) => void;

type HandleChangePrice = (rowIndex: number, newPrice: number, type: 'product' | 'service') => void;

type HandleDeleteClick = (index: number, type: 'product' | 'service') => void;

type OnCheckboxChange = (check: boolean, index: number) => void;

type HandleChangeDetails = (
    index: number,
    type: 'name' | 'value'
) => (event: React.ChangeEvent<HTMLInputElement>) => void;

type SaveDetailsOrder = (detailParam: TOrderDetails) => Promise<void>;

type HandleDeleteDetailParam = (index: number) => void;

type UpdateDetailsOrder = (detailParam: TOrderDetails) => Promise<void>;

type RemoveDetailsOrder = (detailParam: TOrderDetails) => Promise<void>;

interface GetColumnsOrderDetailsProps {
    onCheckboxChange: OnCheckboxChange;
    handleChangeDetails: HandleChangeDetails;
    saveDetailsOrder: SaveDetailsOrder;
    handleDeleteDetailParam: HandleDeleteDetailParam;
    updateDetailsOrder: UpdateDetailsOrder;
    removeDetailsOrder: RemoveDetailsOrder;
}

interface GetColumnsProps {
    handleSearchInput: HandleSearchInput;
    handleChangeQuantity: HandleChangeQuantity;
    handleDeleteClick: HandleDeleteClick;
    handleChangePrice: HandleChangePrice;
}

export const getColumnsService = ({
    handleSearchInput,
    handleChangeQuantity,
    handleDeleteClick,
    handleChangePrice,
}: GetColumnsProps): ColumnsType<TOrderService> => {
    return [
        {
            title: '№',
            dataIndex: 'id',
            width: 20,
            key: 'id',
            render: (_text, _record, index) => {
                return (
                    <div className="d-flex justify-content-center align-content-center">
                        <span>{index + 1}</span>
                    </div>
                );
            },
        },
        {
            title: 'Услуги',
            dataIndex: 'name',
            width: 120,
            key: 'name',
            render: (_text, record, index) => {
                return (
                    <>
                        <SearchInput
                            placeholder="Введите наименование услуги"
                            style={{ minWidth: '193px', maxWidth: '274px', width: '100%' }}
                            fetchType="service"
                            setDataOrder={handleSearchInput}
                            rowIndex={index}
                            defaultValue={record.name}
                        />
                    </>
                );
            },
        },
        {
            title: 'Кол-во',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 55,
            render: (__, record, index) => {
                return (
                    <>
                        <InputNumber
                            min={1}
                            max={1000}
                            value={record.quantity}
                            style={{ width: '100%' }}
                            controls={false}
                            onChange={(value) => handleChangeQuantity(index, value, 'service')}
                        />
                    </>
                );
            },
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            width: 60,
            key: 'price',
            render: (__, record, index) => {
                return (
                    <>
                        <InputNumber
                            min={1}
                            value={record.price}
                            style={{ width: '100%' }}
                            controls={false}
                            onChange={(value) => handleChangePrice(index, value, 'service')}
                        />
                    </>
                );
            },
        },
        {
            title: 'Сумма',
            dataIndex: 'total',
            key: 'total',
            width: 100,
        },
        {
            title: 'Удалить',
            fixed: 'right',
            width: '10%',
            render: (_, _record, index) => {
                return (
                    <div
                        style={{ textAlign: 'center' }}
                        onClick={() => handleDeleteClick(index, 'service')}
                    >
                        <FontAwesomeIcon icon={faXmark} color="white" className="btn-delete" />
                    </div>
                );
            },
        },
    ];
};

export const getColumnsProduct = ({
    handleSearchInput,
    handleChangeQuantity,
    handleChangePrice,
    handleDeleteClick,
}: GetColumnsProps): ColumnsType<TOrderProduct> => {
    return [
        {
            title: '№',
            dataIndex: 'id',
            width: 20,
            key: 'id',
            render: (_text, _record, index) => {
                return (
                    <div className="d-flex justify-content-center align-content-center">
                        <span>{index + 1}</span>
                    </div>
                );
            },
        },
        {
            title: 'Товары',
            dataIndex: 'name',
            width: 120,
            key: 'name',
            render: (_text, record, index) => {
                return (
                    <>
                        <SearchInput
                            placeholder="Введите наименование товара"
                            style={{ minWidth: '193px', maxWidth: '274px' }}
                            fetchType="product"
                            setDataOrder={handleSearchInput}
                            rowIndex={index}
                            defaultValue={record.name}
                        />
                    </>
                );
            },
        },
        {
            title: 'Кол-во',
            dataIndex: 'quantity',
            width: 55,
            key: 'quantity',
            render: (__, record, index) => {
                return (
                    <>
                        <InputNumber
                            min={1}
                            max={1000}
                            style={{ width: '100%' }}
                            value={record.quantity}
                            controls={false}
                            onChange={(value) => handleChangeQuantity(index, value, 'product')}
                        />
                    </>
                );
            },
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            width: 60,
            key: 'price',
            render: (__, record, index) => {
                return (
                    <>
                        <InputNumber
                            min={1}
                            value={record.price}
                            style={{ width: '100%' }}
                            controls={false}
                            onChange={(value) => handleChangePrice(index, value, 'product')}
                        />
                    </>
                );
            },
        },
        {
            title: 'Сумма',
            dataIndex: 'total',
            width: 100,
            key: 'total',
        },
        {
            title: 'Удалить',
            fixed: 'right',
            width: '10%',
            render: (_, _record, index) => {
                return (
                    <div
                        style={{ textAlign: 'center' }}
                        onClick={() => handleDeleteClick(index, 'product')}
                    >
                        <FontAwesomeIcon icon={faXmark} color="white" className="btn-delete" />
                    </div>
                );
            },
        },
    ];
};

export const getColumnsOrderDetails = ({
    onCheckboxChange,
    handleChangeDetails,
    saveDetailsOrder,
    handleDeleteDetailParam,
    updateDetailsOrder,
    removeDetailsOrder,
}: GetColumnsOrderDetailsProps): ColumnsType<TOrderDetails> => {
    return [
        {
            title: '№',
            dataIndex: 'id',
            width: '10%',
            key: 'key',
            render: (_text, record, index) => {
                return (
                    <>
                        <Checkbox
                            checked={record.checked}
                            disabled={
                                record.id == null ||
                                record.name.trim() == '' ||
                                record.value.trim() == ''
                            }
                            onChange={(e) => onCheckboxChange(e.target.checked, index)}
                        >
                            {index + 1}
                        </Checkbox>
                    </>
                );
            },
        },
        {
            title: 'Поля документа',
            dataIndex: 'name',
            key: 'name',
            render: (_text, record, index) => {
                return (
                    <>
                        <Input
                            type="text"
                            value={record.name}
                            key={`name-${index}`}
                            onChange={handleChangeDetails(index, 'name')}
                        />
                    </>
                );
            },
        },
        {
            title: 'Описание полей',
            dataIndex: 'value',
            key: 'value',
            render: (_text, record, index) => {
                return (
                    <>
                        <Input
                            type="text"
                            value={record.value}
                            key={`name-${index}`}
                            onChange={handleChangeDetails(index, 'value')}
                        />
                    </>
                );
            },
        },
        {
            title: 'Действие',
            fixed: 'right',
            width: '15%',
            render: (_text, record, index) => {
                return (
                    <Row>
                        <Button
                            disabled={
                                record.name.replace(/ /g, '') == '' ||
                                record.value.replace(/ /g, '') == ''
                            }
                            onClick={() => saveDetailsOrder(record)}
                            hidden={record.id != null}
                        >
                            <FontAwesomeIcon icon={faSave} color="green" />
                        </Button>
                        <Button
                            hidden={record.id != null}
                            onClick={() => handleDeleteDetailParam(index)}
                            disabled={
                                record.name.replace(/ /g, '') == '' ||
                                record.value.replace(/ /g, '') == ''
                            }
                        >
                            <FontAwesomeIcon icon={faTrash} color="red" />
                        </Button>
                        <Button
                            disabled={
                                record.name.replace(/ /g, '') == '' ||
                                record.value.replace(/ /g, '') == ''
                            }
                            onClick={() => updateDetailsOrder(record)}
                            hidden={record.id == null}
                        >
                            <FontAwesomeIcon icon={faEdit} color="blue" />
                        </Button>
                        <Button
                            hidden={record.id == null}
                            onClick={() => removeDetailsOrder(record)}
                        >
                            <FontAwesomeIcon icon={faTrashCan} color="red" />
                        </Button>
                    </Row>
                );
            },
        },
    ];
};
