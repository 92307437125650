import { makeAutoObservable } from "mobx";
import { TaskFilter } from "types/filters";

class TaskFilterStore {
    private _filter: TaskFilter = {
        query: '',
        sortBy: 'name',
        sortDirection: 'asc',
        createdDates: ['', ''],
        updatedDates: ['', ''],
        deleted: 'null',
        profile: ''
    };

    constructor() {
        makeAutoObservable(this);
    }

    get filter() {
        return this._filter;
    }

    changeBy = (sort: TaskFilter['sortBy']) => {
        this._filter.sortBy = sort;
    }

    changeDir = (dir: TaskFilter['sortDirection']) => {
        this._filter.sortDirection = dir;
    }

    changeCreatedDates = (start: string, end: string) => {
        this._filter.createdDates = [start, end];
    };

    changeUpdatedDates = (start: string, end: string) => {
        this._filter.updatedDates = [start, end];
    };

    changeDeleted = (value: TaskFilter['deleted']) => {
        this._filter.deleted = value;
    };

    changeQuery = (value: string) => {
        this._filter.query = value;
    };
}

export { TaskFilterStore };