import { AdminFilter } from './partials/adminFilter/adminFilter';
import { Results } from './partials/results/results';

export const Administrator = () => {
    return (
        <>
            <AdminFilter />
            <Results />
        </>
    );
};
