import {
    PermissionRequestParams,
    UpdateWorkerPermissionRequestParams,
} from 'types/settings/permissions';

import { CRMAPIBase } from '@api/crmApiBase';
import {
    PermissionCategoryListResponse,
    PermissionListResponse,
} from '@api/responseModels/permissions/permissionsResponse';

import { RequestResult } from '../responseModels/requestResult';

class PermissionGroup extends CRMAPIBase {
    async getPermissionList(
        params: PermissionRequestParams
    ): Promise<RequestResult<PermissionListResponse>> {
        const result = await this.get<PermissionListResponse>('/permissions', { ...params });
        return result;
    }

    async getCategorizedPermissionList(
        crmId
    ): Promise<RequestResult<PermissionCategoryListResponse>> {
        const result = await this.get<PermissionCategoryListResponse>('/permissions/categorized', {
            crm_id: crmId,
        });
        return result;
    }

    async updateWorkerPermissions(
        params: UpdateWorkerPermissionRequestParams
    ): Promise<RequestResult<PermissionCategoryListResponse>> {
        const result = await this.post<PermissionCategoryListResponse>(
            '/permissions/change',
            params
        );
        return result;
    }
}

export { PermissionGroup };
