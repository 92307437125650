import { Table as PDFTable, TD, TR } from '@ag-media/react-pdf-table';
import { Text, View } from '@react-pdf/renderer';

import { styles } from '../../../common/styles';

export const HeaderTable = () => {
    return (
        <>
            <PDFTable style={[styles.mt_10, styles.mb_10]}>
                <TR>
                    <TD
                        weighting={6}
                        style={[
                            { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
                        ]}
                    >
                        <Text> АО "ТИНЬКОФФ БАНК"</Text>
                        <Text style={[styles.mt_5]}> Банк получателя</Text>
                    </TD>
                    <TD
                        weighting={2}
                        style={[
                            { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
                        ]}
                    >
                        <View
                            style={{
                                borderBottomWidth: 1,
                                borderBottomColor: 'black',
                                width: '100%',
                            }}
                        >
                            <Text style={[styles.tAlignLeft]}>БИК</Text>
                        </View>
                        <Text style={[styles.tAlignLeft]}>Сч. №</Text>
                    </TD>
                    <TD
                        weighting={3}
                        style={[
                            { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
                        ]}
                    >
                        <Text>044525974</Text>
                        <Text>044525974044525974</Text>
                    </TD>
                </TR>

                <TR>
                    <TD
                        weighting={6}
                        style={[
                            { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
                        ]}
                    >
                        <View
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text
                                style={[
                                    {
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderBottomColor: 'black',
                                        borderRightColor: 'black',
                                        width: '50%',
                                    },
                                ]}
                            >
                                ИНН: 04452597
                            </Text>
                            <Text
                                style={[
                                    {
                                        borderBottomWidth: 1,
                                        borderBottomColor: 'black',
                                        width: '50%',
                                    },
                                ]}
                            ></Text>
                        </View>
                        <Text style={[styles.mt_5]}>Получатель:</Text>
                    </TD>
                    <TD
                        weighting={2}
                        style={[
                            { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
                        ]}
                    >
                        <Text style={[styles.tAlignLeft]}>Сч. №</Text>
                    </TD>
                    <TD weighting={3} style={[{ alignItems: 'flex-start' }]}>
                        <Text>044525974044525974</Text>
                    </TD>
                </TR>
            </PDFTable>
        </>
    );
};
