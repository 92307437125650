import { isEqual, xorWith } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedFiles: [],
    isFullScreenMode: false,
};

const filesViewerSlice = createSlice({
    name: 'filesViewer',
    initialState,
    reducers: {
        setSelectedFiles: (state, { payload }) => {
            state.selectedFiles = xorWith(state.selectedFiles, [payload], isEqual);
        },

        setFullScreen: (state, { payload }) => {
            state.isFullScreenMode = payload;
        },
    },
});

export const { setSelectedFiles, setFullScreen } = filesViewerSlice.actions;
export default filesViewerSlice.reducer;
