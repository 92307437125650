import { OrderFieldsCreateResp } from '@api/responseModels/orderFields/orderFieldsCreateResponse';
import { OrderFieldsResp } from '@api/responseModels/orderFields/orderFieldsResponse';
import { RequestResult } from '@api/responseModels/requestResult';

import { CRMAPIBase } from '../crmApiBase';

class OrderFieldsGroup extends CRMAPIBase {
    async updateOrderFieldName(
        fieldId,
        crm_id: number,
        name: string
    ): Promise<RequestResult<OrderFieldsResp>> {
        const result = await this.put<OrderFieldsResp>(`order-fields/${fieldId}`, {
            crm_id,
            name,
            id: fieldId,
        });
        return result;
    }

    async getOrderFields(
        crm_id: number,
        worksheetId: number,
        query: string
    ): Promise<RequestResult<OrderFieldsResp>> {
        const result = await this.get<OrderFieldsResp>(`order-fields`, {
            'filters[worksheets][0]': worksheetId,
            crm_id,
            query,
        });
        return result;
    }

    async createOrderField(
        crm_id: number,
        name: string,
        is_photo: number
    ): Promise<RequestResult<OrderFieldsCreateResp>> {
        const result = await this.post<OrderFieldsCreateResp>(`order-fields`, {
            crm_id,
            name,
            is_photo,
        });
        return result;
    }

    async getOrderField(
        crm_id: number,
        field_id: number
    ): Promise<RequestResult<OrderFieldsCreateResp>> {
        const result = await this.get<OrderFieldsCreateResp>(`order-fields/${field_id}`, {
            crm_id,
        });
        return result;
    }

    async deleteOrderField(
        crm_id: number,
        field_id: number
    ): Promise<RequestResult<OrderFieldsCreateResp>> {
        const result = await this.delete<OrderFieldsCreateResp>(`order-fields/${field_id}`, {
            crm_id,
        });
        return result;
    }
}

export { OrderFieldsGroup };
