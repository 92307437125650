import { TPivot } from 'types/worksheets/worksheetFields';

interface Props {
    worksheet_id: number;
    order_field_id: number;
}

export const getDefaultPivot = ({order_field_id, worksheet_id}: Props): TPivot => ({
    order_field_id,
    worksheet_id,
    is_required: 0,
    is_saved: 0,
    is_shown: 0,
    is_used: 0,
});
