import { AdminData } from '../types';

export const mockData: AdminData[] = [
    {
        id: 1,
        artnumber: '87361',
        barcode: '61952',
        quantity: 63,
        price: 47360,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1 для Москва, Авто, Сервис, Альфа',
    },
    {
        id: 2,
        artnumber: '30624',
        barcode: '49654',
        quantity: 359,
        price: 30762,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2 для Москва, Авто, Сервис, Альфа',
    },
    {
        id: 3,
        artnumber: '13365',
        barcode: '46178',
        quantity: 495,
        price: 44012,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3 для Москва, Авто, Сервис, Альфа',
    },
    {
        id: 4,
        artnumber: '44323',
        barcode: '21783',
        quantity: 268,
        price: 41271,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4 для Москва, Авто, Сервис, Альфа',
    },
    {
        id: 5,
        artnumber: '68584',
        barcode: '93470',
        quantity: 294,
        price: 20763,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5 для Москва, Авто, Сервис, Альфа',
    },
    {
        id: 6,
        artnumber: '36428',
        barcode: '25863',
        quantity: 20,
        price: 41012,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 6 для Москва, Авто, Сервис, Альфа',
    },
    {
        id: 7,
        artnumber: '78207',
        barcode: '43366',
        quantity: 136,
        price: 32729,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 7 для Москва, Авто, Сервис, Альфа',
    },
    {
        id: 8,
        artnumber: '72160',
        barcode: '72455',
        quantity: 296,
        price: 21786,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 8 для Москва, Авто, Сервис, Строй',
    },
    {
        id: 9,
        artnumber: '47435',
        barcode: '93247',
        quantity: 208,
        price: 43554,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 9 для Москва, Авто, Сервис, Строй',
    },
    {
        id: 10,
        artnumber: '21397',
        barcode: '62673',
        quantity: 249,
        price: 8467,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 10 для Москва, Авто, Сервис, Строй',
    },
    {
        id: 11,
        artnumber: '28499',
        barcode: '49403',
        quantity: 76,
        price: 19167,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 11 для Москва, Авто, Сервис, Строй',
    },
    {
        id: 12,
        artnumber: '26058',
        barcode: '34824',
        quantity: 37,
        price: 23360,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 12 для Москва, Авто, Сервис, Строй',
    },
    {
        id: 13,
        artnumber: '35247',
        barcode: '77931',
        quantity: 309,
        price: 24318,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 13 для Москва, Авто, Сервис, Строй',
    },
    {
        id: 14,
        artnumber: '19580',
        barcode: '47133',
        quantity: 266,
        price: 38372,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 14 для Москва, Авто, Сервис, Строй',
    },
    {
        id: 15,
        artnumber: '27726',
        barcode: '66138',
        quantity: 404,
        price: 9510,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 15 для Москва, Авто, Сервис, Бета',
    },
    {
        id: 16,
        artnumber: '23654',
        barcode: '92329',
        quantity: 420,
        price: 30427,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 16 для Москва, Авто, Сервис, Бета',
    },
    {
        id: 17,
        artnumber: '35427',
        barcode: '38231',
        quantity: 77,
        price: 11786,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 17 для Москва, Авто, Сервис, Бета',
    },
    {
        id: 18,
        artnumber: '66374',
        barcode: '52399',
        quantity: 109,
        price: 21249,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 18 для Москва, Авто, Сервис, Бета',
    },
    {
        id: 19,
        artnumber: '95796',
        barcode: '37292',
        quantity: 368,
        price: 26581,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 19 для Москва, Авто, Сервис, Бета',
    },
    {
        id: 20,
        artnumber: '12031',
        barcode: '75762',
        quantity: 228,
        price: 48069,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 20 для Москва, Авто, Сервис, Бета',
    },
    {
        id: 21,
        artnumber: '95301',
        barcode: '32048',
        quantity: 395,
        price: 23708,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 21 для Москва, Авто, Сервис, Бета',
    },
    {
        id: 22,
        artnumber: '62353',
        barcode: '67058',
        quantity: 109,
        price: 10920,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 22 для Москва, Авто, Сервис, Гамма',
    },
    {
        id: 23,
        artnumber: '43975',
        barcode: '53930',
        quantity: 102,
        price: 9703,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 23 для Москва, Авто, Сервис, Гамма',
    },
    {
        id: 24,
        artnumber: '66313',
        barcode: '75420',
        quantity: 57,
        price: 49811,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 24 для Москва, Авто, Сервис, Гамма',
    },
    {
        id: 25,
        artnumber: '72202',
        barcode: '15533',
        quantity: 341,
        price: 30131,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 25 для Москва, Авто, Сервис, Гамма',
    },
    {
        id: 26,
        artnumber: '71067',
        barcode: '85669',
        quantity: 316,
        price: 30162,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 26 для Москва, Авто, Сервис, Гамма',
    },
    {
        id: 27,
        artnumber: '62426',
        barcode: '66043',
        quantity: 244,
        price: 18928,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 27 для Москва, Авто, Сервис, Гамма',
    },
    {
        id: 28,
        artnumber: '71499',
        barcode: '46647',
        quantity: 302,
        price: 18760,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 28 для Москва, Авто, Сервис, Гамма',
    },
    {
        id: 29,
        artnumber: '63920',
        barcode: '70554',
        quantity: 266,
        price: 39849,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 29 для Москва, Авто, Сервис, Трио',
    },
    {
        id: 30,
        artnumber: '35824',
        barcode: '19059',
        quantity: 117,
        price: 41431,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 30 для Москва, Авто, Сервис, Трио',
    },
    {
        id: 31,
        artnumber: '28288',
        barcode: '15084',
        quantity: 245,
        price: 30908,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 31 для Москва, Авто, Сервис, Трио',
    },
    {
        id: 32,
        artnumber: '19106',
        barcode: '93009',
        quantity: 473,
        price: 46554,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 32 для Москва, Авто, Сервис, Трио',
    },
    {
        id: 33,
        artnumber: '44257',
        barcode: '75391',
        quantity: 302,
        price: 13206,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 33 для Москва, Авто, Сервис, Трио',
    },
    {
        id: 34,
        artnumber: '57680',
        barcode: '27108',
        quantity: 82,
        price: 26714,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 34 для Москва, Авто, Сервис, Трио',
    },
    {
        id: 35,
        artnumber: '87648',
        barcode: '42112',
        quantity: 4,
        price: 17660,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 35 для Москва, Авто, Сервис, Трио',
    },
    {
        id: 36,
        artnumber: '63744',
        barcode: '14447',
        quantity: 3,
        price: 6429,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 36 для Москва, Авто, Запчасти, Альфа',
    },
    {
        id: 37,
        artnumber: '43836',
        barcode: '63222',
        quantity: 458,
        price: 48965,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 37 для Москва, Авто, Запчасти, Альфа',
    },
    {
        id: 38,
        artnumber: '25360',
        barcode: '56534',
        quantity: 479,
        price: 14407,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 38 для Москва, Авто, Запчасти, Альфа',
    },
    {
        id: 39,
        artnumber: '42149',
        barcode: '50424',
        quantity: 495,
        price: 2159,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 39 для Москва, Авто, Запчасти, Альфа',
    },
    {
        id: 40,
        artnumber: '91817',
        barcode: '70131',
        quantity: 3,
        price: 18375,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 40 для Москва, Авто, Запчасти, Альфа',
    },
    {
        id: 41,
        artnumber: '92767',
        barcode: '25718',
        quantity: 236,
        price: 41662,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 41 для Москва, Авто, Запчасти, Альфа',
    },
    {
        id: 42,
        artnumber: '98904',
        barcode: '63892',
        quantity: 268,
        price: 41398,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 42 для Москва, Авто, Запчасти, Альфа',
    },
    {
        id: 43,
        artnumber: '29817',
        barcode: '18758',
        quantity: 376,
        price: 4700,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 43 для Москва, Авто, Запчасти, Строй',
    },
    {
        id: 44,
        artnumber: '15473',
        barcode: '79678',
        quantity: 152,
        price: 22891,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 44 для Москва, Авто, Запчасти, Строй',
    },
    {
        id: 45,
        artnumber: '36199',
        barcode: '70846',
        quantity: 145,
        price: 27944,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 45 для Москва, Авто, Запчасти, Строй',
    },
    {
        id: 46,
        artnumber: '18183',
        barcode: '11514',
        quantity: 470,
        price: 49694,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 46 для Москва, Авто, Запчасти, Строй',
    },
    {
        id: 47,
        artnumber: '41519',
        barcode: '93232',
        quantity: 233,
        price: 38849,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 47 для Москва, Авто, Запчасти, Строй',
    },
    {
        id: 48,
        artnumber: '85249',
        barcode: '25118',
        quantity: 176,
        price: 6577,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 48 для Москва, Авто, Запчасти, Строй',
    },
    {
        id: 49,
        artnumber: '46914',
        barcode: '42448',
        quantity: 149,
        price: 6620,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 49 для Москва, Авто, Запчасти, Строй',
    },
    {
        id: 50,
        artnumber: '95043',
        barcode: '32212',
        quantity: 284,
        price: 8992,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 50 для Москва, Авто, Запчасти, Бета',
    },
    {
        id: 51,
        artnumber: '79210',
        barcode: '37084',
        quantity: 224,
        price: 4872,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 51 для Москва, Авто, Запчасти, Бета',
    },
    {
        id: 52,
        artnumber: '70741',
        barcode: '40957',
        quantity: 416,
        price: 18223,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 52 для Москва, Авто, Запчасти, Бета',
    },
    {
        id: 53,
        artnumber: '72068',
        barcode: '95228',
        quantity: 215,
        price: 37081,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 53 для Москва, Авто, Запчасти, Бета',
    },
    {
        id: 54,
        artnumber: '55664',
        barcode: '37869',
        quantity: 323,
        price: 11243,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 54 для Москва, Авто, Запчасти, Бета',
    },
    {
        id: 55,
        artnumber: '88449',
        barcode: '86260',
        quantity: 460,
        price: 35490,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 55 для Москва, Авто, Запчасти, Бета',
    },
    {
        id: 56,
        artnumber: '72728',
        barcode: '84236',
        quantity: 71,
        price: 9214,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 56 для Москва, Авто, Запчасти, Бета',
    },
    {
        id: 57,
        artnumber: '10110',
        barcode: '14199',
        quantity: 492,
        price: 42247,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 57 для Москва, Авто, Запчасти, Гамма',
    },
    {
        id: 58,
        artnumber: '33755',
        barcode: '74091',
        quantity: 178,
        price: 4987,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 58 для Москва, Авто, Запчасти, Гамма',
    },
    {
        id: 59,
        artnumber: '42431',
        barcode: '65555',
        quantity: 403,
        price: 33478,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 59 для Москва, Авто, Запчасти, Гамма',
    },
    {
        id: 60,
        artnumber: '84452',
        barcode: '24261',
        quantity: 412,
        price: 37834,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 60 для Москва, Авто, Запчасти, Гамма',
    },
    {
        id: 61,
        artnumber: '28166',
        barcode: '58762',
        quantity: 235,
        price: 12279,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 61 для Москва, Авто, Запчасти, Гамма',
    },
    {
        id: 62,
        artnumber: '85891',
        barcode: '85527',
        quantity: 261,
        price: 49510,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 62 для Москва, Авто, Запчасти, Гамма',
    },
    {
        id: 63,
        artnumber: '83525',
        barcode: '15810',
        quantity: 175,
        price: 10012,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 63 для Москва, Авто, Запчасти, Гамма',
    },
    {
        id: 64,
        artnumber: '33662',
        barcode: '99515',
        quantity: 441,
        price: 40935,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 64 для Москва, Авто, Запчасти, Трио',
    },
    {
        id: 65,
        artnumber: '91855',
        barcode: '92158',
        quantity: 288,
        price: 48596,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 65 для Москва, Авто, Запчасти, Трио',
    },
    {
        id: 66,
        artnumber: '72312',
        barcode: '70312',
        quantity: 397,
        price: 42664,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 66 для Москва, Авто, Запчасти, Трио',
    },
    {
        id: 67,
        artnumber: '49361',
        barcode: '52687',
        quantity: 334,
        price: 5389,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 67 для Москва, Авто, Запчасти, Трио',
    },
    {
        id: 68,
        artnumber: '32171',
        barcode: '59450',
        quantity: 172,
        price: 39604,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 68 для Москва, Авто, Запчасти, Трио',
    },
    {
        id: 69,
        artnumber: '88791',
        barcode: '22931',
        quantity: 395,
        price: 43550,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 69 для Москва, Авто, Запчасти, Трио',
    },
    {
        id: 70,
        artnumber: '87179',
        barcode: '95764',
        quantity: 485,
        price: 31617,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 70 для Москва, Авто, Запчасти, Трио',
    },
    {
        id: 71,
        artnumber: '81741',
        barcode: '20031',
        quantity: 325,
        price: 36023,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 71 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 72,
        artnumber: '18024',
        barcode: '28392',
        quantity: 433,
        price: 35358,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 72 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 73,
        artnumber: '90147',
        barcode: '13394',
        quantity: 436,
        price: 20045,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 73 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 74,
        artnumber: '20714',
        barcode: '25277',
        quantity: 24,
        price: 43048,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 74 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 75,
        artnumber: '87170',
        barcode: '36853',
        quantity: 461,
        price: 37722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 75 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 76,
        artnumber: '69246',
        barcode: '63934',
        quantity: 428,
        price: 18527,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 76 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 77,
        artnumber: '79058',
        barcode: '32222',
        quantity: 189,
        price: 8772,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 77 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 78,
        artnumber: '90007',
        barcode: '77506',
        quantity: 108,
        price: 47491,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 78 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 79,
        artnumber: '59946',
        barcode: '20531',
        quantity: 221,
        price: 18308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 79 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 80,
        artnumber: '73143',
        barcode: '93611',
        quantity: 288,
        price: 26685,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 80 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 81,
        artnumber: '32014',
        barcode: '78020',
        quantity: 432,
        price: 13573,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 81 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 82,
        artnumber: '39875',
        barcode: '68870',
        quantity: 253,
        price: 8564,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 82 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 83,
        artnumber: '60202',
        barcode: '32989',
        quantity: 54,
        price: 12291,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 83 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 84,
        artnumber: '73286',
        barcode: '34878',
        quantity: 26,
        price: 40400,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 84 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 85,
        artnumber: '34525',
        barcode: '11626',
        quantity: 75,
        price: 23347,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 85 для Москва, Авто, Доставка, Альфа',
    },
    {
        id: 86,
        artnumber: '68887',
        barcode: '20698',
        quantity: 31,
        price: 28712,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 86 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 87,
        artnumber: '94693',
        barcode: '19185',
        quantity: 321,
        price: 37748,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 87 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 88,
        artnumber: '65707',
        barcode: '48943',
        quantity: 199,
        price: 27685,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 88 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 89,
        artnumber: '92653',
        barcode: '83582',
        quantity: 301,
        price: 2818,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 89 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 90,
        artnumber: '51145',
        barcode: '75538',
        quantity: 204,
        price: 14192,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 90 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 91,
        artnumber: '52376',
        barcode: '12863',
        quantity: 287,
        price: 21338,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 91 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 92,
        artnumber: '79205',
        barcode: '54544',
        quantity: 206,
        price: 47071,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 92 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 93,
        artnumber: '85909',
        barcode: '27376',
        quantity: 238,
        price: 8990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 93 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 94,
        artnumber: '87285',
        barcode: '29758',
        quantity: 296,
        price: 7212,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 94 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 95,
        artnumber: '20425',
        barcode: '34533',
        quantity: 370,
        price: 19805,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 95 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 96,
        artnumber: '60363',
        barcode: '35045',
        quantity: 193,
        price: 40642,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 96 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 97,
        artnumber: '99193',
        barcode: '51680',
        quantity: 99,
        price: 15576,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 97 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 98,
        artnumber: '67591',
        barcode: '50670',
        quantity: 130,
        price: 3520,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 98 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 99,
        artnumber: '72348',
        barcode: '28047',
        quantity: 126,
        price: 14308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 99 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 100,
        artnumber: '11755',
        barcode: '53462',
        quantity: 52,
        price: 2113,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 100 для Москва, Авто, Доставка, Строй',
    },
    {
        id: 101,
        artnumber: '41828',
        barcode: '75590',
        quantity: 438,
        price: 3194,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 101 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 102,
        artnumber: '93409',
        barcode: '23051',
        quantity: 445,
        price: 17408,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 102 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 103,
        artnumber: '86971',
        barcode: '92965',
        quantity: 12,
        price: 27871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 103 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 104,
        artnumber: '30984',
        barcode: '86818',
        quantity: 95,
        price: 11205,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 104 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 105,
        artnumber: '75189',
        barcode: '38633',
        quantity: 57,
        price: 19523,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 105 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 106,
        artnumber: '84194',
        barcode: '32258',
        quantity: 51,
        price: 10964,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 106 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 107,
        artnumber: '69090',
        barcode: '85201',
        quantity: 322,
        price: 18401,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 107 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 108,
        artnumber: '61770',
        barcode: '51237',
        quantity: 317,
        price: 36636,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 108 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 109,
        artnumber: '98892',
        barcode: '34051',
        quantity: 466,
        price: 40985,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 109 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 110,
        artnumber: '26585',
        barcode: '19502',
        quantity: 472,
        price: 49866,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 110 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 111,
        artnumber: '52513',
        barcode: '42712',
        quantity: 281,
        price: 7102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 111 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 112,
        artnumber: '77676',
        barcode: '31044',
        quantity: 462,
        price: 41842,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 112 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 113,
        artnumber: '49283',
        barcode: '96167',
        quantity: 37,
        price: 40670,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 113 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 114,
        artnumber: '86056',
        barcode: '91113',
        quantity: 353,
        price: 25165,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 114 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 115,
        artnumber: '13239',
        barcode: '20832',
        quantity: 458,
        price: 34349,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 115 для Москва, Авто, Доставка, Бета',
    },
    {
        id: 116,
        artnumber: '79435',
        barcode: '16610',
        quantity: 274,
        price: 42902,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 116 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 117,
        artnumber: '96460',
        barcode: '54639',
        quantity: 359,
        price: 30215,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 117 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 118,
        artnumber: '61943',
        barcode: '44854',
        quantity: 70,
        price: 19966,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 118 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 119,
        artnumber: '83264',
        barcode: '24758',
        quantity: 400,
        price: 33962,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 119 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 120,
        artnumber: '93977',
        barcode: '44830',
        quantity: 132,
        price: 3502,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 120 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 121,
        artnumber: '96342',
        barcode: '99147',
        quantity: 340,
        price: 29877,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 121 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 122,
        artnumber: '42617',
        barcode: '18802',
        quantity: 448,
        price: 47660,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 122 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 123,
        artnumber: '26273',
        barcode: '34010',
        quantity: 98,
        price: 49621,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 123 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 124,
        artnumber: '66178',
        barcode: '98732',
        quantity: 270,
        price: 21242,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 124 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 125,
        artnumber: '99458',
        barcode: '13034',
        quantity: 261,
        price: 38031,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 125 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 126,
        artnumber: '53868',
        barcode: '58704',
        quantity: 176,
        price: 25544,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 126 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 127,
        artnumber: '32670',
        barcode: '33452',
        quantity: 138,
        price: 41962,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 127 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 128,
        artnumber: '60023',
        barcode: '58291',
        quantity: 245,
        price: 2844,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 128 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 129,
        artnumber: '91993',
        barcode: '75169',
        quantity: 201,
        price: 23222,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 129 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 130,
        artnumber: '67303',
        barcode: '10256',
        quantity: 6,
        price: 42065,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 130 для Москва, Авто, Доставка, Гамма',
    },
    {
        id: 131,
        artnumber: '84086',
        barcode: '37517',
        quantity: 451,
        price: 35572,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 131 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 132,
        artnumber: '83278',
        barcode: '16838',
        quantity: 363,
        price: 19676,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 132 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 133,
        artnumber: '54684',
        barcode: '29654',
        quantity: 75,
        price: 5013,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 133 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 134,
        artnumber: '68621',
        barcode: '77206',
        quantity: 19,
        price: 38745,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 134 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 135,
        artnumber: '11094',
        barcode: '76288',
        quantity: 121,
        price: 20940,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 135 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 136,
        artnumber: '78642',
        barcode: '37659',
        quantity: 35,
        price: 31311,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 136 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 137,
        artnumber: '99988',
        barcode: '83228',
        quantity: 192,
        price: 22614,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 137 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 138,
        artnumber: '14116',
        barcode: '13014',
        quantity: 378,
        price: 39954,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 138 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 139,
        artnumber: '30149',
        barcode: '44896',
        quantity: 264,
        price: 34630,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 139 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 140,
        artnumber: '32553',
        barcode: '29784',
        quantity: 273,
        price: 32022,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 140 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 141,
        artnumber: '85052',
        barcode: '64597',
        quantity: 39,
        price: 26332,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 141 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 142,
        artnumber: '55119',
        barcode: '39528',
        quantity: 32,
        price: 29252,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 142 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 143,
        artnumber: '84609',
        barcode: '76193',
        quantity: 497,
        price: 7803,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 143 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 144,
        artnumber: '14534',
        barcode: '80495',
        quantity: 224,
        price: 4043,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 144 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 145,
        artnumber: '84659',
        barcode: '54293',
        quantity: 93,
        price: 29889,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 145 для Москва, Авто, Доставка, Трио',
    },
    {
        id: 146,
        artnumber: '61873',
        barcode: '77057',
        quantity: 36,
        price: 42470,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 146 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 147,
        artnumber: '23709',
        barcode: '45252',
        quantity: 241,
        price: 28369,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 147 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 148,
        artnumber: '25980',
        barcode: '39477',
        quantity: 349,
        price: 6421,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 148 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 149,
        artnumber: '17814',
        barcode: '14059',
        quantity: 358,
        price: 35168,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 149 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 150,
        artnumber: '94403',
        barcode: '93858',
        quantity: 1,
        price: 7696,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 150 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 151,
        artnumber: '85587',
        barcode: '95846',
        quantity: 292,
        price: 44086,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 151 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 152,
        artnumber: '95920',
        barcode: '68520',
        quantity: 100,
        price: 17075,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 152 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 153,
        artnumber: '92743',
        barcode: '11975',
        quantity: 325,
        price: 40033,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 153 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 154,
        artnumber: '58446',
        barcode: '11575',
        quantity: 115,
        price: 16734,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 154 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 155,
        artnumber: '32002',
        barcode: '85522',
        quantity: 394,
        price: 292,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 155 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 156,
        artnumber: '31866',
        barcode: '96485',
        quantity: 360,
        price: 30377,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 156 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 157,
        artnumber: '76406',
        barcode: '73033',
        quantity: 32,
        price: 27399,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 157 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 158,
        artnumber: '84546',
        barcode: '21416',
        quantity: 151,
        price: 28396,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 158 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 159,
        artnumber: '67012',
        barcode: '30239',
        quantity: 52,
        price: 32072,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 159 для Москва, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 160,
        artnumber: '83135',
        barcode: '20155',
        quantity: 238,
        price: 9379,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 160 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 161,
        artnumber: '83114',
        barcode: '23475',
        quantity: 410,
        price: 45981,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 161 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 162,
        artnumber: '96199',
        barcode: '70809',
        quantity: 58,
        price: 29481,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 162 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 163,
        artnumber: '93217',
        barcode: '25443',
        quantity: 32,
        price: 25794,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 163 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 164,
        artnumber: '23552',
        barcode: '17490',
        quantity: 312,
        price: 43072,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 164 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 165,
        artnumber: '33188',
        barcode: '36906',
        quantity: 66,
        price: 47356,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 165 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 166,
        artnumber: '87481',
        barcode: '56095',
        quantity: 427,
        price: 37138,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 166 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 167,
        artnumber: '28560',
        barcode: '55284',
        quantity: 98,
        price: 34539,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 167 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 168,
        artnumber: '53393',
        barcode: '94253',
        quantity: 267,
        price: 17220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 168 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 169,
        artnumber: '75478',
        barcode: '97071',
        quantity: 362,
        price: 9650,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 169 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 170,
        artnumber: '46142',
        barcode: '52410',
        quantity: 97,
        price: 10152,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 170 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 171,
        artnumber: '85704',
        barcode: '29564',
        quantity: 371,
        price: 45659,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 171 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 172,
        artnumber: '72856',
        barcode: '98392',
        quantity: 289,
        price: 21467,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 172 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 173,
        artnumber: '21032',
        barcode: '69699',
        quantity: 361,
        price: 6498,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 173 для Москва, Авто, Шиномонтаж, Строй',
    },
    {
        id: 174,
        artnumber: '12614',
        barcode: '82492',
        quantity: 374,
        price: 49102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 174 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 175,
        artnumber: '40184',
        barcode: '80444',
        quantity: 255,
        price: 13110,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 175 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 176,
        artnumber: '33172',
        barcode: '72170',
        quantity: 26,
        price: 5008,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 176 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 177,
        artnumber: '83223',
        barcode: '73424',
        quantity: 267,
        price: 173,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 177 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 178,
        artnumber: '92473',
        barcode: '79812',
        quantity: 141,
        price: 32002,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 178 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 179,
        artnumber: '77816',
        barcode: '82152',
        quantity: 56,
        price: 42487,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 179 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 180,
        artnumber: '83372',
        barcode: '70406',
        quantity: 157,
        price: 36212,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 180 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 181,
        artnumber: '42467',
        barcode: '88101',
        quantity: 484,
        price: 28154,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 181 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 182,
        artnumber: '15966',
        barcode: '35915',
        quantity: 376,
        price: 25559,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 182 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 183,
        artnumber: '47227',
        barcode: '85175',
        quantity: 190,
        price: 31793,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 183 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 184,
        artnumber: '43944',
        barcode: '15487',
        quantity: 266,
        price: 36920,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 184 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 185,
        artnumber: '44463',
        barcode: '92269',
        quantity: 161,
        price: 39936,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 185 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 186,
        artnumber: '41214',
        barcode: '38115',
        quantity: 163,
        price: 9543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 186 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 187,
        artnumber: '56610',
        barcode: '29888',
        quantity: 89,
        price: 44087,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 187 для Москва, Авто, Шиномонтаж, Бета',
    },
    {
        id: 188,
        artnumber: '57514',
        barcode: '59269',
        quantity: 442,
        price: 7859,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 188 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 189,
        artnumber: '14876',
        barcode: '93525',
        quantity: 446,
        price: 24277,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 189 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 190,
        artnumber: '77909',
        barcode: '58132',
        quantity: 43,
        price: 19479,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 190 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 191,
        artnumber: '21117',
        barcode: '47203',
        quantity: 331,
        price: 38282,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 191 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 192,
        artnumber: '76228',
        barcode: '30991',
        quantity: 97,
        price: 47626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 192 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 193,
        artnumber: '51530',
        barcode: '52895',
        quantity: 405,
        price: 3194,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 193 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 194,
        artnumber: '56824',
        barcode: '16947',
        quantity: 292,
        price: 45639,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 194 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 195,
        artnumber: '64931',
        barcode: '54750',
        quantity: 491,
        price: 45884,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 195 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 196,
        artnumber: '60547',
        barcode: '36160',
        quantity: 316,
        price: 11849,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 196 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 197,
        artnumber: '25511',
        barcode: '25134',
        quantity: 365,
        price: 38615,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 197 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 198,
        artnumber: '27722',
        barcode: '53506',
        quantity: 336,
        price: 25464,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 198 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 199,
        artnumber: '36614',
        barcode: '97301',
        quantity: 375,
        price: 29871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 199 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 200,
        artnumber: '17617',
        barcode: '42914',
        quantity: 21,
        price: 20687,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 200 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 201,
        artnumber: '46927',
        barcode: '46647',
        quantity: 171,
        price: 5054,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 201 для Москва, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 202,
        artnumber: '87568',
        barcode: '73829',
        quantity: 293,
        price: 18593,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 202 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 203,
        artnumber: '51032',
        barcode: '76177',
        quantity: 106,
        price: 5203,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 203 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 204,
        artnumber: '62536',
        barcode: '40394',
        quantity: 333,
        price: 20044,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 204 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 205,
        artnumber: '78704',
        barcode: '55893',
        quantity: 281,
        price: 30559,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 205 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 206,
        artnumber: '79645',
        barcode: '13489',
        quantity: 210,
        price: 4027,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 206 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 207,
        artnumber: '13551',
        barcode: '22758',
        quantity: 396,
        price: 20120,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 207 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 208,
        artnumber: '52691',
        barcode: '18838',
        quantity: 323,
        price: 8816,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 208 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 209,
        artnumber: '26124',
        barcode: '32121',
        quantity: 471,
        price: 29896,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 209 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 210,
        artnumber: '23059',
        barcode: '38614',
        quantity: 44,
        price: 803,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 210 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 211,
        artnumber: '78184',
        barcode: '68836',
        quantity: 64,
        price: 13362,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 211 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 212,
        artnumber: '95117',
        barcode: '50861',
        quantity: 1,
        price: 4196,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 212 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 213,
        artnumber: '78249',
        barcode: '31012',
        quantity: 469,
        price: 40062,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 213 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 214,
        artnumber: '24582',
        barcode: '67142',
        quantity: 305,
        price: 44317,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 214 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 215,
        artnumber: '70905',
        barcode: '39911',
        quantity: 9,
        price: 5331,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 215 для Москва, Авто, Шиномонтаж, Трио',
    },
    {
        id: 216,
        artnumber: '17924',
        barcode: '73146',
        quantity: 19,
        price: 18589,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 216 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 217,
        artnumber: '57969',
        barcode: '50267',
        quantity: 414,
        price: 33456,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 217 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 218,
        artnumber: '95663',
        barcode: '29755',
        quantity: 21,
        price: 19169,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 218 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 219,
        artnumber: '95412',
        barcode: '24826',
        quantity: 334,
        price: 16315,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 219 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 220,
        artnumber: '72528',
        barcode: '52051',
        quantity: 32,
        price: 9401,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 220 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 221,
        artnumber: '88537',
        barcode: '88095',
        quantity: 138,
        price: 6064,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 221 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 222,
        artnumber: '68713',
        barcode: '22053',
        quantity: 425,
        price: 1810,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 222 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 223,
        artnumber: '11166',
        barcode: '69794',
        quantity: 413,
        price: 47250,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 223 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 224,
        artnumber: '67168',
        barcode: '67205',
        quantity: 185,
        price: 28751,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 224 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 225,
        artnumber: '24174',
        barcode: '76669',
        quantity: 90,
        price: 19245,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 225 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 226,
        artnumber: '85963',
        barcode: '96060',
        quantity: 114,
        price: 32732,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 226 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 227,
        artnumber: '56320',
        barcode: '88085',
        quantity: 290,
        price: 29095,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 227 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 228,
        artnumber: '83071',
        barcode: '23341',
        quantity: 109,
        price: 25765,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 228 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 229,
        artnumber: '32427',
        barcode: '90471',
        quantity: 495,
        price: 2213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 229 для Москва, Авто, Замена масла, Альфа',
    },
    {
        id: 230,
        artnumber: '21052',
        barcode: '18831',
        quantity: 462,
        price: 20756,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 230 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 231,
        artnumber: '30956',
        barcode: '48378',
        quantity: 404,
        price: 9765,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 231 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 232,
        artnumber: '55147',
        barcode: '76736',
        quantity: 204,
        price: 17790,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 232 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 233,
        artnumber: '37721',
        barcode: '79629',
        quantity: 157,
        price: 15926,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 233 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 234,
        artnumber: '69610',
        barcode: '10680',
        quantity: 373,
        price: 1337,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 234 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 235,
        artnumber: '36060',
        barcode: '36663',
        quantity: 354,
        price: 12725,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 235 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 236,
        artnumber: '96895',
        barcode: '85545',
        quantity: 196,
        price: 14105,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 236 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 237,
        artnumber: '32083',
        barcode: '66615',
        quantity: 86,
        price: 31901,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 237 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 238,
        artnumber: '66005',
        barcode: '64139',
        quantity: 279,
        price: 36892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 238 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 239,
        artnumber: '77900',
        barcode: '66216',
        quantity: 456,
        price: 24375,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 239 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 240,
        artnumber: '58579',
        barcode: '13537',
        quantity: 467,
        price: 4506,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 240 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 241,
        artnumber: '29469',
        barcode: '77591',
        quantity: 238,
        price: 42643,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 241 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 242,
        artnumber: '45333',
        barcode: '37619',
        quantity: 225,
        price: 22403,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 242 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 243,
        artnumber: '16938',
        barcode: '38207',
        quantity: 198,
        price: 44089,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 243 для Москва, Авто, Замена масла, Строй',
    },
    {
        id: 244,
        artnumber: '52314',
        barcode: '68079',
        quantity: 51,
        price: 1795,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 244 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 245,
        artnumber: '44101',
        barcode: '67914',
        quantity: 382,
        price: 1649,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 245 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 246,
        artnumber: '45975',
        barcode: '68400',
        quantity: 476,
        price: 30184,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 246 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 247,
        artnumber: '68826',
        barcode: '32835',
        quantity: 198,
        price: 40071,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 247 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 248,
        artnumber: '96776',
        barcode: '68392',
        quantity: 18,
        price: 34516,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 248 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 249,
        artnumber: '82527',
        barcode: '64391',
        quantity: 7,
        price: 5708,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 249 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 250,
        artnumber: '72698',
        barcode: '63144',
        quantity: 341,
        price: 45864,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 250 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 251,
        artnumber: '40125',
        barcode: '19850',
        quantity: 428,
        price: 43449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 251 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 252,
        artnumber: '52562',
        barcode: '26126',
        quantity: 425,
        price: 20267,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 252 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 253,
        artnumber: '20022',
        barcode: '92425',
        quantity: 257,
        price: 20438,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 253 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 254,
        artnumber: '68055',
        barcode: '37715',
        quantity: 329,
        price: 5914,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 254 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 255,
        artnumber: '11476',
        barcode: '88997',
        quantity: 415,
        price: 47739,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 255 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 256,
        artnumber: '42550',
        barcode: '23278',
        quantity: 467,
        price: 30253,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 256 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 257,
        artnumber: '76533',
        barcode: '65998',
        quantity: 4,
        price: 29113,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 257 для Москва, Авто, Замена масла, Бета',
    },
    {
        id: 258,
        artnumber: '67496',
        barcode: '48171',
        quantity: 387,
        price: 11583,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 258 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 259,
        artnumber: '79662',
        barcode: '98425',
        quantity: 166,
        price: 25985,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 259 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 260,
        artnumber: '95599',
        barcode: '85733',
        quantity: 303,
        price: 44661,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 260 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 261,
        artnumber: '88323',
        barcode: '55795',
        quantity: 271,
        price: 31696,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 261 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 262,
        artnumber: '88547',
        barcode: '46002',
        quantity: 259,
        price: 27838,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 262 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 263,
        artnumber: '79613',
        barcode: '98823',
        quantity: 317,
        price: 24865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 263 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 264,
        artnumber: '86013',
        barcode: '60160',
        quantity: 364,
        price: 41611,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 264 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 265,
        artnumber: '13034',
        barcode: '51377',
        quantity: 64,
        price: 3860,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 265 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 266,
        artnumber: '12539',
        barcode: '96419',
        quantity: 423,
        price: 40096,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 266 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 267,
        artnumber: '66425',
        barcode: '49194',
        quantity: 148,
        price: 29680,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 267 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 268,
        artnumber: '15219',
        barcode: '76848',
        quantity: 497,
        price: 24782,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 268 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 269,
        artnumber: '54116',
        barcode: '42411',
        quantity: 222,
        price: 32265,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 269 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 270,
        artnumber: '88776',
        barcode: '56259',
        quantity: 258,
        price: 49384,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 270 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 271,
        artnumber: '40831',
        barcode: '60074',
        quantity: 275,
        price: 9747,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 271 для Москва, Авто, Замена масла, Гамма',
    },
    {
        id: 272,
        artnumber: '66112',
        barcode: '81761',
        quantity: 468,
        price: 25845,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 272 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 273,
        artnumber: '40864',
        barcode: '52356',
        quantity: 239,
        price: 48845,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 273 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 274,
        artnumber: '55987',
        barcode: '17475',
        quantity: 73,
        price: 18564,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 274 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 275,
        artnumber: '19235',
        barcode: '56756',
        quantity: 87,
        price: 27277,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 275 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 276,
        artnumber: '72497',
        barcode: '91936',
        quantity: 239,
        price: 33781,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 276 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 277,
        artnumber: '93007',
        barcode: '88755',
        quantity: 299,
        price: 28764,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 277 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 278,
        artnumber: '35271',
        barcode: '97286',
        quantity: 233,
        price: 49951,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 278 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 279,
        artnumber: '92448',
        barcode: '18970',
        quantity: 500,
        price: 12156,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 279 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 280,
        artnumber: '95666',
        barcode: '79840',
        quantity: 48,
        price: 41303,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 280 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 281,
        artnumber: '95521',
        barcode: '15474',
        quantity: 351,
        price: 13659,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 281 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 282,
        artnumber: '99298',
        barcode: '69805',
        quantity: 405,
        price: 45594,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 282 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 283,
        artnumber: '13697',
        barcode: '53049',
        quantity: 78,
        price: 8085,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 283 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 284,
        artnumber: '11020',
        barcode: '84949',
        quantity: 202,
        price: 14365,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 284 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 285,
        artnumber: '35076',
        barcode: '63934',
        quantity: 484,
        price: 37991,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 285 для Москва, Авто, Замена масла, Трио',
    },
    {
        id: 286,
        artnumber: '21252',
        barcode: '15286',
        quantity: 345,
        price: 36274,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 286 для Москва, Недвижимость, Сервис, Альфа',
    },
    {
        id: 287,
        artnumber: '42224',
        barcode: '61384',
        quantity: 451,
        price: 9736,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 287 для Москва, Недвижимость, Сервис, Альфа',
    },
    {
        id: 288,
        artnumber: '40186',
        barcode: '83948',
        quantity: 196,
        price: 14172,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 288 для Москва, Недвижимость, Сервис, Альфа',
    },
    {
        id: 289,
        artnumber: '70078',
        barcode: '33660',
        quantity: 293,
        price: 43223,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 289 для Москва, Недвижимость, Сервис, Альфа',
    },
    {
        id: 290,
        artnumber: '77558',
        barcode: '56271',
        quantity: 272,
        price: 20177,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 290 для Москва, Недвижимость, Сервис, Строй',
    },
    {
        id: 291,
        artnumber: '51089',
        barcode: '28031',
        quantity: 95,
        price: 9239,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 291 для Москва, Недвижимость, Сервис, Строй',
    },
    {
        id: 292,
        artnumber: '50674',
        barcode: '16445',
        quantity: 447,
        price: 20601,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 292 для Москва, Недвижимость, Сервис, Строй',
    },
    {
        id: 293,
        artnumber: '53811',
        barcode: '17337',
        quantity: 377,
        price: 5569,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 293 для Москва, Недвижимость, Сервис, Строй',
    },
    {
        id: 294,
        artnumber: '79558',
        barcode: '62734',
        quantity: 9,
        price: 31897,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 294 для Москва, Недвижимость, Сервис, Бета',
    },
    {
        id: 295,
        artnumber: '28082',
        barcode: '63367',
        quantity: 354,
        price: 19145,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 295 для Москва, Недвижимость, Сервис, Бета',
    },
    {
        id: 296,
        artnumber: '99191',
        barcode: '87432',
        quantity: 126,
        price: 29436,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 296 для Москва, Недвижимость, Сервис, Бета',
    },
    {
        id: 297,
        artnumber: '11965',
        barcode: '99742',
        quantity: 52,
        price: 47844,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 297 для Москва, Недвижимость, Сервис, Бета',
    },
    {
        id: 298,
        artnumber: '41661',
        barcode: '70320',
        quantity: 118,
        price: 38316,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 298 для Москва, Недвижимость, Сервис, Гамма',
    },
    {
        id: 299,
        artnumber: '87970',
        barcode: '66781',
        quantity: 460,
        price: 9237,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 299 для Москва, Недвижимость, Сервис, Гамма',
    },
    {
        id: 300,
        artnumber: '30686',
        barcode: '10167',
        quantity: 98,
        price: 46616,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 300 для Москва, Недвижимость, Сервис, Гамма',
    },
    {
        id: 301,
        artnumber: '32694',
        barcode: '61073',
        quantity: 411,
        price: 9105,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 301 для Москва, Недвижимость, Сервис, Гамма',
    },
    {
        id: 302,
        artnumber: '65089',
        barcode: '30702',
        quantity: 175,
        price: 38073,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 302 для Москва, Недвижимость, Сервис, Трио',
    },
    {
        id: 303,
        artnumber: '75312',
        barcode: '16603',
        quantity: 229,
        price: 18932,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 303 для Москва, Недвижимость, Сервис, Трио',
    },
    {
        id: 304,
        artnumber: '72579',
        barcode: '13245',
        quantity: 381,
        price: 35710,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 304 для Москва, Недвижимость, Сервис, Трио',
    },
    {
        id: 305,
        artnumber: '54233',
        barcode: '48289',
        quantity: 104,
        price: 40773,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 305 для Москва, Недвижимость, Сервис, Трио',
    },
    {
        id: 306,
        artnumber: '54682',
        barcode: '90960',
        quantity: 472,
        price: 45122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 306 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 307,
        artnumber: '31951',
        barcode: '81576',
        quantity: 462,
        price: 6957,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 307 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 308,
        artnumber: '64614',
        barcode: '24036',
        quantity: 366,
        price: 19271,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 308 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 309,
        artnumber: '61044',
        barcode: '54317',
        quantity: 15,
        price: 35876,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 309 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 310,
        artnumber: '95450',
        barcode: '96923',
        quantity: 248,
        price: 45883,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 310 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 311,
        artnumber: '43641',
        barcode: '42627',
        quantity: 13,
        price: 43712,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 311 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 312,
        artnumber: '28612',
        barcode: '40182',
        quantity: 138,
        price: 38765,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 312 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 313,
        artnumber: '49014',
        barcode: '54501',
        quantity: 128,
        price: 40381,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 313 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 314,
        artnumber: '61175',
        barcode: '87820',
        quantity: 369,
        price: 38169,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 314 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 315,
        artnumber: '40200',
        barcode: '25825',
        quantity: 5,
        price: 14121,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 315 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 316,
        artnumber: '73589',
        barcode: '41372',
        quantity: 248,
        price: 35756,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 316 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 317,
        artnumber: '48816',
        barcode: '82693',
        quantity: 126,
        price: 28204,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 317 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 318,
        artnumber: '17114',
        barcode: '25141',
        quantity: 106,
        price: 16187,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 318 для Москва, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 319,
        artnumber: '70100',
        barcode: '84347',
        quantity: 419,
        price: 21166,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 319 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 320,
        artnumber: '35594',
        barcode: '19637',
        quantity: 140,
        price: 23689,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 320 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 321,
        artnumber: '54470',
        barcode: '16666',
        quantity: 276,
        price: 6288,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 321 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 322,
        artnumber: '86753',
        barcode: '90743',
        quantity: 432,
        price: 28148,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 322 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 323,
        artnumber: '20491',
        barcode: '68466',
        quantity: 13,
        price: 12126,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 323 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 324,
        artnumber: '69647',
        barcode: '68369',
        quantity: 40,
        price: 30084,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 324 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 325,
        artnumber: '21228',
        barcode: '42336',
        quantity: 413,
        price: 26860,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 325 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 326,
        artnumber: '70090',
        barcode: '28572',
        quantity: 458,
        price: 6023,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 326 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 327,
        artnumber: '81929',
        barcode: '75897',
        quantity: 401,
        price: 47179,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 327 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 328,
        artnumber: '41402',
        barcode: '97642',
        quantity: 235,
        price: 13539,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 328 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 329,
        artnumber: '17712',
        barcode: '20638',
        quantity: 267,
        price: 31623,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 329 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 330,
        artnumber: '30958',
        barcode: '85685',
        quantity: 467,
        price: 36055,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 330 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 331,
        artnumber: '27677',
        barcode: '54385',
        quantity: 232,
        price: 42945,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 331 для Москва, Недвижимость, Запчасти, Строй',
    },
    {
        id: 332,
        artnumber: '67038',
        barcode: '35827',
        quantity: 401,
        price: 21682,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 332 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 333,
        artnumber: '80707',
        barcode: '90344',
        quantity: 49,
        price: 15385,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 333 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 334,
        artnumber: '41075',
        barcode: '31001',
        quantity: 239,
        price: 6195,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 334 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 335,
        artnumber: '79931',
        barcode: '90235',
        quantity: 417,
        price: 6634,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 335 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 336,
        artnumber: '98566',
        barcode: '28771',
        quantity: 81,
        price: 35911,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 336 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 337,
        artnumber: '87953',
        barcode: '49205',
        quantity: 238,
        price: 39292,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 337 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 338,
        artnumber: '79485',
        barcode: '79767',
        quantity: 40,
        price: 26603,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 338 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 339,
        artnumber: '68404',
        barcode: '91416',
        quantity: 317,
        price: 5782,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 339 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 340,
        artnumber: '20906',
        barcode: '99825',
        quantity: 484,
        price: 1857,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 340 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 341,
        artnumber: '69907',
        barcode: '40250',
        quantity: 86,
        price: 4543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 341 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 342,
        artnumber: '32799',
        barcode: '69047',
        quantity: 412,
        price: 18515,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 342 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 343,
        artnumber: '31026',
        barcode: '98196',
        quantity: 306,
        price: 20955,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 343 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 344,
        artnumber: '53386',
        barcode: '45916',
        quantity: 155,
        price: 9488,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 344 для Москва, Недвижимость, Запчасти, Бета',
    },
    {
        id: 345,
        artnumber: '48622',
        barcode: '58540',
        quantity: 136,
        price: 12102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 345 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 346,
        artnumber: '97162',
        barcode: '77471',
        quantity: 1,
        price: 5877,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 346 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 347,
        artnumber: '10379',
        barcode: '23631',
        quantity: 469,
        price: 33686,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 347 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 348,
        artnumber: '18305',
        barcode: '18247',
        quantity: 152,
        price: 39170,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 348 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 349,
        artnumber: '67787',
        barcode: '96707',
        quantity: 157,
        price: 4690,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 349 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 350,
        artnumber: '55345',
        barcode: '19209',
        quantity: 219,
        price: 47110,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 350 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 351,
        artnumber: '87586',
        barcode: '70497',
        quantity: 149,
        price: 36462,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 351 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 352,
        artnumber: '94506',
        barcode: '25273',
        quantity: 63,
        price: 49339,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 352 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 353,
        artnumber: '77235',
        barcode: '43823',
        quantity: 273,
        price: 18246,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 353 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 354,
        artnumber: '19942',
        barcode: '56065',
        quantity: 115,
        price: 29601,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 354 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 355,
        artnumber: '80473',
        barcode: '54386',
        quantity: 487,
        price: 47763,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 355 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 356,
        artnumber: '30357',
        barcode: '31759',
        quantity: 468,
        price: 47345,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 356 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 357,
        artnumber: '84151',
        barcode: '42420',
        quantity: 105,
        price: 450,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 357 для Москва, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 358,
        artnumber: '78774',
        barcode: '95976',
        quantity: 418,
        price: 23221,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 358 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 359,
        artnumber: '86022',
        barcode: '93235',
        quantity: 119,
        price: 23756,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 359 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 360,
        artnumber: '44382',
        barcode: '44576',
        quantity: 473,
        price: 28818,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 360 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 361,
        artnumber: '84159',
        barcode: '75993',
        quantity: 175,
        price: 15375,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 361 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 362,
        artnumber: '74347',
        barcode: '15199',
        quantity: 472,
        price: 43120,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 362 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 363,
        artnumber: '44480',
        barcode: '73497',
        quantity: 55,
        price: 15607,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 363 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 364,
        artnumber: '28304',
        barcode: '98927',
        quantity: 114,
        price: 24255,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 364 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 365,
        artnumber: '72902',
        barcode: '58594',
        quantity: 331,
        price: 2525,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 365 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 366,
        artnumber: '54782',
        barcode: '56260',
        quantity: 153,
        price: 32299,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 366 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 367,
        artnumber: '61607',
        barcode: '88662',
        quantity: 337,
        price: 36313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 367 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 368,
        artnumber: '17391',
        barcode: '84233',
        quantity: 217,
        price: 41272,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 368 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 369,
        artnumber: '84439',
        barcode: '17635',
        quantity: 494,
        price: 36253,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 369 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 370,
        artnumber: '57226',
        barcode: '96409',
        quantity: 116,
        price: 15437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 370 для Москва, Недвижимость, Запчасти, Трио',
    },
    {
        id: 371,
        artnumber: '80941',
        barcode: '79066',
        quantity: 446,
        price: 34567,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 371 для Москва, Недвижимость, Доставка, Альфа',
    },
    {
        id: 372,
        artnumber: '26052',
        barcode: '24107',
        quantity: 371,
        price: 45532,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 372 для Москва, Недвижимость, Доставка, Альфа',
    },
    {
        id: 373,
        artnumber: '73183',
        barcode: '87432',
        quantity: 273,
        price: 33738,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 373 для Москва, Недвижимость, Доставка, Альфа',
    },
    {
        id: 374,
        artnumber: '30312',
        barcode: '66894',
        quantity: 1,
        price: 16576,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 374 для Москва, Недвижимость, Доставка, Альфа',
    },
    {
        id: 375,
        artnumber: '11123',
        barcode: '49616',
        quantity: 57,
        price: 31496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 375 для Москва, Недвижимость, Доставка, Альфа',
    },
    {
        id: 376,
        artnumber: '86729',
        barcode: '25061',
        quantity: 350,
        price: 315,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 376 для Москва, Недвижимость, Доставка, Альфа',
    },
    {
        id: 377,
        artnumber: '58633',
        barcode: '43744',
        quantity: 16,
        price: 30831,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 377 для Москва, Недвижимость, Доставка, Альфа',
    },
    {
        id: 378,
        artnumber: '33901',
        barcode: '26846',
        quantity: 312,
        price: 17372,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 378 для Москва, Недвижимость, Доставка, Строй',
    },
    {
        id: 379,
        artnumber: '20895',
        barcode: '55580',
        quantity: 372,
        price: 38893,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 379 для Москва, Недвижимость, Доставка, Строй',
    },
    {
        id: 380,
        artnumber: '79871',
        barcode: '27422',
        quantity: 308,
        price: 23382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 380 для Москва, Недвижимость, Доставка, Строй',
    },
    {
        id: 381,
        artnumber: '20934',
        barcode: '75007',
        quantity: 471,
        price: 25172,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 381 для Москва, Недвижимость, Доставка, Строй',
    },
    {
        id: 382,
        artnumber: '51860',
        barcode: '18508',
        quantity: 144,
        price: 36306,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 382 для Москва, Недвижимость, Доставка, Строй',
    },
    {
        id: 383,
        artnumber: '77803',
        barcode: '92317',
        quantity: 236,
        price: 10633,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 383 для Москва, Недвижимость, Доставка, Строй',
    },
    {
        id: 384,
        artnumber: '64836',
        barcode: '66058',
        quantity: 339,
        price: 32476,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 384 для Москва, Недвижимость, Доставка, Строй',
    },
    {
        id: 385,
        artnumber: '98164',
        barcode: '18519',
        quantity: 74,
        price: 30845,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 385 для Москва, Недвижимость, Доставка, Бета',
    },
    {
        id: 386,
        artnumber: '34043',
        barcode: '55790',
        quantity: 91,
        price: 3693,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 386 для Москва, Недвижимость, Доставка, Бета',
    },
    {
        id: 387,
        artnumber: '69732',
        barcode: '13571',
        quantity: 30,
        price: 24696,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 387 для Москва, Недвижимость, Доставка, Бета',
    },
    {
        id: 388,
        artnumber: '65037',
        barcode: '98547',
        quantity: 248,
        price: 39295,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 388 для Москва, Недвижимость, Доставка, Бета',
    },
    {
        id: 389,
        artnumber: '10399',
        barcode: '65485',
        quantity: 256,
        price: 26799,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 389 для Москва, Недвижимость, Доставка, Бета',
    },
    {
        id: 390,
        artnumber: '76577',
        barcode: '25217',
        quantity: 237,
        price: 3754,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 390 для Москва, Недвижимость, Доставка, Бета',
    },
    {
        id: 391,
        artnumber: '16351',
        barcode: '57404',
        quantity: 483,
        price: 36568,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 391 для Москва, Недвижимость, Доставка, Бета',
    },
    {
        id: 392,
        artnumber: '66644',
        barcode: '98045',
        quantity: 395,
        price: 38511,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 392 для Москва, Недвижимость, Доставка, Гамма',
    },
    {
        id: 393,
        artnumber: '13603',
        barcode: '20027',
        quantity: 157,
        price: 27443,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 393 для Москва, Недвижимость, Доставка, Гамма',
    },
    {
        id: 394,
        artnumber: '74044',
        barcode: '68670',
        quantity: 218,
        price: 35377,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 394 для Москва, Недвижимость, Доставка, Гамма',
    },
    {
        id: 395,
        artnumber: '69011',
        barcode: '76193',
        quantity: 150,
        price: 48645,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 395 для Москва, Недвижимость, Доставка, Гамма',
    },
    {
        id: 396,
        artnumber: '85697',
        barcode: '89739',
        quantity: 465,
        price: 32386,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 396 для Москва, Недвижимость, Доставка, Гамма',
    },
    {
        id: 397,
        artnumber: '63942',
        barcode: '36619',
        quantity: 273,
        price: 4521,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 397 для Москва, Недвижимость, Доставка, Гамма',
    },
    {
        id: 398,
        artnumber: '21645',
        barcode: '52725',
        quantity: 210,
        price: 10635,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 398 для Москва, Недвижимость, Доставка, Гамма',
    },
    {
        id: 399,
        artnumber: '17231',
        barcode: '96693',
        quantity: 162,
        price: 5429,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 399 для Москва, Недвижимость, Доставка, Трио',
    },
    {
        id: 400,
        artnumber: '88119',
        barcode: '20771',
        quantity: 354,
        price: 47550,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 400 для Москва, Недвижимость, Доставка, Трио',
    },
    {
        id: 401,
        artnumber: '31087',
        barcode: '92197',
        quantity: 197,
        price: 32296,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 401 для Москва, Недвижимость, Доставка, Трио',
    },
    {
        id: 402,
        artnumber: '37109',
        barcode: '82711',
        quantity: 156,
        price: 24718,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 402 для Москва, Недвижимость, Доставка, Трио',
    },
    {
        id: 403,
        artnumber: '70297',
        barcode: '59762',
        quantity: 219,
        price: 14275,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 403 для Москва, Недвижимость, Доставка, Трио',
    },
    {
        id: 404,
        artnumber: '15958',
        barcode: '39408',
        quantity: 308,
        price: 36123,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 404 для Москва, Недвижимость, Доставка, Трио',
    },
    {
        id: 405,
        artnumber: '40933',
        barcode: '93004',
        quantity: 23,
        price: 45333,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 405 для Москва, Недвижимость, Доставка, Трио',
    },
    {
        id: 406,
        artnumber: '24381',
        barcode: '68957',
        quantity: 159,
        price: 39734,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 406 для Москва, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 407,
        artnumber: '69554',
        barcode: '67729',
        quantity: 252,
        price: 36123,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 407 для Москва, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 408,
        artnumber: '92391',
        barcode: '55321',
        quantity: 398,
        price: 14797,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 408 для Москва, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 409,
        artnumber: '30342',
        barcode: '11056',
        quantity: 344,
        price: 2484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 409 для Москва, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 410,
        artnumber: '12228',
        barcode: '75678',
        quantity: 454,
        price: 19704,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 410 для Москва, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 411,
        artnumber: '22347',
        barcode: '33478',
        quantity: 118,
        price: 30851,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 411 для Москва, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 412,
        artnumber: '34555',
        barcode: '82930',
        quantity: 130,
        price: 26847,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 412 для Москва, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 413,
        artnumber: '77722',
        barcode: '95286',
        quantity: 390,
        price: 45889,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 413 для Москва, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 414,
        artnumber: '22910',
        barcode: '23181',
        quantity: 151,
        price: 8032,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 414 для Москва, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 415,
        artnumber: '79626',
        barcode: '57358',
        quantity: 377,
        price: 12127,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 415 для Москва, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 416,
        artnumber: '82442',
        barcode: '68725',
        quantity: 319,
        price: 17001,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 416 для Москва, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 417,
        artnumber: '59591',
        barcode: '51582',
        quantity: 498,
        price: 12458,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 417 для Москва, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 418,
        artnumber: '88493',
        barcode: '23320',
        quantity: 97,
        price: 27120,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 418 для Москва, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 419,
        artnumber: '51624',
        barcode: '76446',
        quantity: 15,
        price: 23838,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 419 для Москва, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 420,
        artnumber: '38926',
        barcode: '60765',
        quantity: 467,
        price: 19871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 420 для Москва, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 421,
        artnumber: '11767',
        barcode: '89029',
        quantity: 307,
        price: 47552,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 421 для Москва, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 422,
        artnumber: '47481',
        barcode: '29796',
        quantity: 143,
        price: 29627,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 422 для Москва, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 423,
        artnumber: '97180',
        barcode: '14858',
        quantity: 428,
        price: 38289,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 423 для Москва, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 424,
        artnumber: '86367',
        barcode: '24261',
        quantity: 402,
        price: 2298,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 424 для Москва, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 425,
        artnumber: '40021',
        barcode: '86414',
        quantity: 25,
        price: 1975,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 425 для Москва, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 426,
        artnumber: '98047',
        barcode: '38217',
        quantity: 339,
        price: 48864,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 426 для Москва, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 427,
        artnumber: '82521',
        barcode: '37631',
        quantity: 196,
        price: 3636,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 427 для Москва, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 428,
        artnumber: '21547',
        barcode: '56986',
        quantity: 219,
        price: 42877,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 428 для Москва, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 429,
        artnumber: '62671',
        barcode: '90024',
        quantity: 349,
        price: 49179,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 429 для Москва, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 430,
        artnumber: '18924',
        barcode: '87101',
        quantity: 53,
        price: 4799,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 430 для Москва, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 431,
        artnumber: '12521',
        barcode: '33078',
        quantity: 361,
        price: 29726,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 431 для Москва, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 432,
        artnumber: '10114',
        barcode: '25472',
        quantity: 92,
        price: 27628,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 432 для Москва, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 433,
        artnumber: '64640',
        barcode: '96042',
        quantity: 190,
        price: 18863,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 433 для Москва, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 434,
        artnumber: '57185',
        barcode: '79807',
        quantity: 262,
        price: 11222,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 434 для Москва, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 435,
        artnumber: '81727',
        barcode: '76557',
        quantity: 369,
        price: 25717,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 435 для Москва, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 436,
        artnumber: '96848',
        barcode: '47075',
        quantity: 281,
        price: 34265,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 436 для Москва, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 437,
        artnumber: '47349',
        barcode: '68772',
        quantity: 473,
        price: 2627,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 437 для Москва, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 438,
        artnumber: '71373',
        barcode: '86453',
        quantity: 72,
        price: 20067,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 438 для Москва, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 439,
        artnumber: '32911',
        barcode: '16489',
        quantity: 353,
        price: 11754,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 439 для Москва, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 440,
        artnumber: '29530',
        barcode: '66123',
        quantity: 166,
        price: 3584,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 440 для Москва, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 441,
        artnumber: '33872',
        barcode: '17635',
        quantity: 282,
        price: 183,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 441 для Москва, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 442,
        artnumber: '25102',
        barcode: '68595',
        quantity: 247,
        price: 47148,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 442 для Москва, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 443,
        artnumber: '58778',
        barcode: '97497',
        quantity: 192,
        price: 22197,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 443 для Москва, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 444,
        artnumber: '74763',
        barcode: '20120',
        quantity: 463,
        price: 44469,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 444 для Москва, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 445,
        artnumber: '75533',
        barcode: '60783',
        quantity: 195,
        price: 7803,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 445 для Москва, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 446,
        artnumber: '14903',
        barcode: '93670',
        quantity: 19,
        price: 43636,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 446 для Москва, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 447,
        artnumber: '51674',
        barcode: '91293',
        quantity: 126,
        price: 24277,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 447 для Москва, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 448,
        artnumber: '59931',
        barcode: '49983',
        quantity: 216,
        price: 23816,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 448 для Москва, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 449,
        artnumber: '14721',
        barcode: '17121',
        quantity: 260,
        price: 17635,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 449 для Москва, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 450,
        artnumber: '21982',
        barcode: '53128',
        quantity: 392,
        price: 47360,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 450 для Москва, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 451,
        artnumber: '79807',
        barcode: '19416',
        quantity: 9,
        price: 22553,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 451 для Москва, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 452,
        artnumber: '24496',
        barcode: '79812',
        quantity: 180,
        price: 41556,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 452 для Москва, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 453,
        artnumber: '94148',
        barcode: '97022',
        quantity: 151,
        price: 44903,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 453 для Москва, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 454,
        artnumber: '23413',
        barcode: '61839',
        quantity: 3,
        price: 34051,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 454 для Москва, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 455,
        artnumber: '67469',
        barcode: '44246',
        quantity: 92,
        price: 31594,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 455 для Москва, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 456,
        artnumber: '26442',
        barcode: '97358',
        quantity: 30,
        price: 46976,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 456 для Москва, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 457,
        artnumber: '33930',
        barcode: '74944',
        quantity: 296,
        price: 24597,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 457 для Москва, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 458,
        artnumber: '78379',
        barcode: '88223',
        quantity: 362,
        price: 10569,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 458 для Москва, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 459,
        artnumber: '24789',
        barcode: '64081',
        quantity: 360,
        price: 36097,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 459 для Москва, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 460,
        artnumber: '56086',
        barcode: '15818',
        quantity: 46,
        price: 5797,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 460 для Москва, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 461,
        artnumber: '49327',
        barcode: '75574',
        quantity: 243,
        price: 49402,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 461 для Москва, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 462,
        artnumber: '63422',
        barcode: '63148',
        quantity: 240,
        price: 17169,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 462 для Москва, Недвижимость, Замена масла, Строй',
    },
    {
        id: 463,
        artnumber: '24117',
        barcode: '82629',
        quantity: 322,
        price: 10260,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 463 для Москва, Недвижимость, Замена масла, Строй',
    },
    {
        id: 464,
        artnumber: '74925',
        barcode: '86132',
        quantity: 63,
        price: 48812,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 464 для Москва, Недвижимость, Замена масла, Строй',
    },
    {
        id: 465,
        artnumber: '26322',
        barcode: '58177',
        quantity: 96,
        price: 7222,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 465 для Москва, Недвижимость, Замена масла, Строй',
    },
    {
        id: 466,
        artnumber: '21201',
        barcode: '10234',
        quantity: 69,
        price: 36580,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 466 для Москва, Недвижимость, Замена масла, Строй',
    },
    {
        id: 467,
        artnumber: '37202',
        barcode: '47822',
        quantity: 160,
        price: 14456,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 467 для Москва, Недвижимость, Замена масла, Строй',
    },
    {
        id: 468,
        artnumber: '74649',
        barcode: '14883',
        quantity: 311,
        price: 21425,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 468 для Москва, Недвижимость, Замена масла, Бета',
    },
    {
        id: 469,
        artnumber: '87981',
        barcode: '12396',
        quantity: 452,
        price: 45971,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 469 для Москва, Недвижимость, Замена масла, Бета',
    },
    {
        id: 470,
        artnumber: '78173',
        barcode: '36963',
        quantity: 72,
        price: 17247,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 470 для Москва, Недвижимость, Замена масла, Бета',
    },
    {
        id: 471,
        artnumber: '88949',
        barcode: '10161',
        quantity: 170,
        price: 26175,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 471 для Москва, Недвижимость, Замена масла, Бета',
    },
    {
        id: 472,
        artnumber: '23023',
        barcode: '74725',
        quantity: 252,
        price: 300,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 472 для Москва, Недвижимость, Замена масла, Бета',
    },
    {
        id: 473,
        artnumber: '63696',
        barcode: '41579',
        quantity: 73,
        price: 151,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 473 для Москва, Недвижимость, Замена масла, Бета',
    },
    {
        id: 474,
        artnumber: '76439',
        barcode: '11241',
        quantity: 300,
        price: 49258,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 474 для Москва, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 475,
        artnumber: '83180',
        barcode: '18126',
        quantity: 346,
        price: 26230,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 475 для Москва, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 476,
        artnumber: '89238',
        barcode: '82524',
        quantity: 472,
        price: 34907,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 476 для Москва, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 477,
        artnumber: '64399',
        barcode: '66399',
        quantity: 496,
        price: 3152,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 477 для Москва, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 478,
        artnumber: '68492',
        barcode: '76405',
        quantity: 408,
        price: 47825,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 478 для Москва, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 479,
        artnumber: '73345',
        barcode: '69103',
        quantity: 331,
        price: 39869,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 479 для Москва, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 480,
        artnumber: '67333',
        barcode: '98628',
        quantity: 83,
        price: 28342,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 480 для Москва, Недвижимость, Замена масла, Трио',
    },
    {
        id: 481,
        artnumber: '68877',
        barcode: '31468',
        quantity: 393,
        price: 46513,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 481 для Москва, Недвижимость, Замена масла, Трио',
    },
    {
        id: 482,
        artnumber: '18848',
        barcode: '73216',
        quantity: 228,
        price: 42309,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 482 для Москва, Недвижимость, Замена масла, Трио',
    },
    {
        id: 483,
        artnumber: '90259',
        barcode: '49557',
        quantity: 388,
        price: 45671,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 483 для Москва, Недвижимость, Замена масла, Трио',
    },
    {
        id: 484,
        artnumber: '16701',
        barcode: '20812',
        quantity: 263,
        price: 12211,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 484 для Москва, Недвижимость, Замена масла, Трио',
    },
    {
        id: 485,
        artnumber: '63199',
        barcode: '27368',
        quantity: 350,
        price: 12385,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 485 для Москва, Недвижимость, Замена масла, Трио',
    },
    {
        id: 486,
        artnumber: '79435',
        barcode: '70876',
        quantity: 128,
        price: 39639,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 486 для Москва, Красота, Сервис, Альфа',
    },
    {
        id: 487,
        artnumber: '81572',
        barcode: '88819',
        quantity: 174,
        price: 36016,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 487 для Москва, Красота, Сервис, Альфа',
    },
    {
        id: 488,
        artnumber: '39587',
        barcode: '82103',
        quantity: 122,
        price: 7546,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 488 для Москва, Красота, Сервис, Альфа',
    },
    {
        id: 489,
        artnumber: '46718',
        barcode: '99644',
        quantity: 85,
        price: 38145,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 489 для Москва, Красота, Сервис, Альфа',
    },
    {
        id: 490,
        artnumber: '92679',
        barcode: '24765',
        quantity: 44,
        price: 47767,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 490 для Москва, Красота, Сервис, Альфа',
    },
    {
        id: 491,
        artnumber: '16255',
        barcode: '77822',
        quantity: 420,
        price: 35931,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 491 для Москва, Красота, Сервис, Альфа',
    },
    {
        id: 492,
        artnumber: '39784',
        barcode: '80001',
        quantity: 363,
        price: 12694,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 492 для Москва, Красота, Сервис, Альфа',
    },
    {
        id: 493,
        artnumber: '37663',
        barcode: '58262',
        quantity: 400,
        price: 10904,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 493 для Москва, Красота, Сервис, Альфа',
    },
    {
        id: 494,
        artnumber: '42014',
        barcode: '52995',
        quantity: 496,
        price: 36298,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 494 для Москва, Красота, Сервис, Строй',
    },
    {
        id: 495,
        artnumber: '35106',
        barcode: '16710',
        quantity: 64,
        price: 41369,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 495 для Москва, Красота, Сервис, Строй',
    },
    {
        id: 496,
        artnumber: '76463',
        barcode: '19090',
        quantity: 396,
        price: 25676,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 496 для Москва, Красота, Сервис, Строй',
    },
    {
        id: 497,
        artnumber: '80971',
        barcode: '81264',
        quantity: 156,
        price: 360,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 497 для Москва, Красота, Сервис, Строй',
    },
    {
        id: 498,
        artnumber: '38856',
        barcode: '15010',
        quantity: 212,
        price: 11346,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 498 для Москва, Красота, Сервис, Строй',
    },
    {
        id: 499,
        artnumber: '47485',
        barcode: '13772',
        quantity: 109,
        price: 4159,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 499 для Москва, Красота, Сервис, Строй',
    },
    {
        id: 500,
        artnumber: '64089',
        barcode: '86992',
        quantity: 499,
        price: 28208,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 500 для Москва, Красота, Сервис, Строй',
    },
    {
        id: 501,
        artnumber: '12308',
        barcode: '59697',
        quantity: 249,
        price: 44498,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 501 для Москва, Красота, Сервис, Строй',
    },
    {
        id: 502,
        artnumber: '25810',
        barcode: '22973',
        quantity: 213,
        price: 11063,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 502 для Москва, Красота, Сервис, Бета',
    },
    {
        id: 503,
        artnumber: '59388',
        barcode: '39084',
        quantity: 296,
        price: 19391,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 503 для Москва, Красота, Сервис, Бета',
    },
    {
        id: 504,
        artnumber: '36399',
        barcode: '20095',
        quantity: 318,
        price: 16322,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 504 для Москва, Красота, Сервис, Бета',
    },
    {
        id: 505,
        artnumber: '64101',
        barcode: '62117',
        quantity: 366,
        price: 6842,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 505 для Москва, Красота, Сервис, Бета',
    },
    {
        id: 506,
        artnumber: '42309',
        barcode: '71230',
        quantity: 190,
        price: 13903,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 506 для Москва, Красота, Сервис, Бета',
    },
    {
        id: 507,
        artnumber: '27783',
        barcode: '40517',
        quantity: 440,
        price: 39946,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 507 для Москва, Красота, Сервис, Бета',
    },
    {
        id: 508,
        artnumber: '15705',
        barcode: '64585',
        quantity: 338,
        price: 15893,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 508 для Москва, Красота, Сервис, Бета',
    },
    {
        id: 509,
        artnumber: '69609',
        barcode: '31629',
        quantity: 175,
        price: 12163,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 509 для Москва, Красота, Сервис, Бета',
    },
    {
        id: 510,
        artnumber: '13357',
        barcode: '81291',
        quantity: 431,
        price: 39460,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 510 для Москва, Красота, Сервис, Гамма',
    },
    {
        id: 511,
        artnumber: '98370',
        barcode: '49279',
        quantity: 226,
        price: 40286,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 511 для Москва, Красота, Сервис, Гамма',
    },
    {
        id: 512,
        artnumber: '68778',
        barcode: '68661',
        quantity: 48,
        price: 43181,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 512 для Москва, Красота, Сервис, Гамма',
    },
    {
        id: 513,
        artnumber: '76764',
        barcode: '95964',
        quantity: 43,
        price: 34054,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 513 для Москва, Красота, Сервис, Гамма',
    },
    {
        id: 514,
        artnumber: '68729',
        barcode: '51340',
        quantity: 113,
        price: 13223,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 514 для Москва, Красота, Сервис, Гамма',
    },
    {
        id: 515,
        artnumber: '82088',
        barcode: '44874',
        quantity: 460,
        price: 18185,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 515 для Москва, Красота, Сервис, Гамма',
    },
    {
        id: 516,
        artnumber: '42176',
        barcode: '75205',
        quantity: 363,
        price: 38976,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 516 для Москва, Красота, Сервис, Гамма',
    },
    {
        id: 517,
        artnumber: '61456',
        barcode: '35785',
        quantity: 70,
        price: 7343,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 517 для Москва, Красота, Сервис, Гамма',
    },
    {
        id: 518,
        artnumber: '40372',
        barcode: '37805',
        quantity: 192,
        price: 8634,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 518 для Москва, Красота, Сервис, Трио',
    },
    {
        id: 519,
        artnumber: '52182',
        barcode: '75503',
        quantity: 243,
        price: 26705,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 519 для Москва, Красота, Сервис, Трио',
    },
    {
        id: 520,
        artnumber: '37503',
        barcode: '62529',
        quantity: 339,
        price: 38934,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 520 для Москва, Красота, Сервис, Трио',
    },
    {
        id: 521,
        artnumber: '13462',
        barcode: '62049',
        quantity: 129,
        price: 44782,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 521 для Москва, Красота, Сервис, Трио',
    },
    {
        id: 522,
        artnumber: '97278',
        barcode: '59377',
        quantity: 410,
        price: 14313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 522 для Москва, Красота, Сервис, Трио',
    },
    {
        id: 523,
        artnumber: '19119',
        barcode: '19203',
        quantity: 135,
        price: 25573,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 523 для Москва, Красота, Сервис, Трио',
    },
    {
        id: 524,
        artnumber: '73303',
        barcode: '72590',
        quantity: 119,
        price: 6334,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 524 для Москва, Красота, Сервис, Трио',
    },
    {
        id: 525,
        artnumber: '56968',
        barcode: '24085',
        quantity: 273,
        price: 25545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 525 для Москва, Красота, Сервис, Трио',
    },
    {
        id: 526,
        artnumber: '91068',
        barcode: '94021',
        quantity: 424,
        price: 21592,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 526 для Москва, Красота, Запчасти, Альфа',
    },
    {
        id: 527,
        artnumber: '26492',
        barcode: '32074',
        quantity: 315,
        price: 2064,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 527 для Москва, Красота, Запчасти, Альфа',
    },
    {
        id: 528,
        artnumber: '85237',
        barcode: '20160',
        quantity: 291,
        price: 33246,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 528 для Москва, Красота, Запчасти, Альфа',
    },
    {
        id: 529,
        artnumber: '78706',
        barcode: '49184',
        quantity: 170,
        price: 39485,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 529 для Москва, Красота, Запчасти, Альфа',
    },
    {
        id: 530,
        artnumber: '58459',
        barcode: '64408',
        quantity: 441,
        price: 8150,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 530 для Москва, Красота, Запчасти, Альфа',
    },
    {
        id: 531,
        artnumber: '37474',
        barcode: '48151',
        quantity: 419,
        price: 27764,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 531 для Москва, Красота, Запчасти, Строй',
    },
    {
        id: 532,
        artnumber: '10503',
        barcode: '96919',
        quantity: 146,
        price: 20727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 532 для Москва, Красота, Запчасти, Строй',
    },
    {
        id: 533,
        artnumber: '45548',
        barcode: '55948',
        quantity: 454,
        price: 28626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 533 для Москва, Красота, Запчасти, Строй',
    },
    {
        id: 534,
        artnumber: '82809',
        barcode: '75862',
        quantity: 350,
        price: 24555,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 534 для Москва, Красота, Запчасти, Строй',
    },
    {
        id: 535,
        artnumber: '11333',
        barcode: '76687',
        quantity: 104,
        price: 4373,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 535 для Москва, Красота, Запчасти, Строй',
    },
    {
        id: 536,
        artnumber: '99977',
        barcode: '33178',
        quantity: 203,
        price: 27304,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 536 для Москва, Красота, Запчасти, Бета',
    },
    {
        id: 537,
        artnumber: '99026',
        barcode: '51238',
        quantity: 220,
        price: 56,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 537 для Москва, Красота, Запчасти, Бета',
    },
    {
        id: 538,
        artnumber: '87956',
        barcode: '90618',
        quantity: 241,
        price: 47021,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 538 для Москва, Красота, Запчасти, Бета',
    },
    {
        id: 539,
        artnumber: '90916',
        barcode: '62486',
        quantity: 410,
        price: 40871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 539 для Москва, Красота, Запчасти, Бета',
    },
    {
        id: 540,
        artnumber: '93088',
        barcode: '93085',
        quantity: 30,
        price: 7322,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 540 для Москва, Красота, Запчасти, Бета',
    },
    {
        id: 541,
        artnumber: '11671',
        barcode: '52059',
        quantity: 481,
        price: 28991,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 541 для Москва, Красота, Запчасти, Гамма',
    },
    {
        id: 542,
        artnumber: '61369',
        barcode: '30025',
        quantity: 35,
        price: 25724,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 542 для Москва, Красота, Запчасти, Гамма',
    },
    {
        id: 543,
        artnumber: '15540',
        barcode: '62855',
        quantity: 413,
        price: 48964,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 543 для Москва, Красота, Запчасти, Гамма',
    },
    {
        id: 544,
        artnumber: '87950',
        barcode: '32732',
        quantity: 115,
        price: 21340,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 544 для Москва, Красота, Запчасти, Гамма',
    },
    {
        id: 545,
        artnumber: '99272',
        barcode: '98264',
        quantity: 88,
        price: 43436,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 545 для Москва, Красота, Запчасти, Гамма',
    },
    {
        id: 546,
        artnumber: '28894',
        barcode: '37480',
        quantity: 465,
        price: 43199,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 546 для Москва, Красота, Запчасти, Трио',
    },
    {
        id: 547,
        artnumber: '74213',
        barcode: '17039',
        quantity: 399,
        price: 21498,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 547 для Москва, Красота, Запчасти, Трио',
    },
    {
        id: 548,
        artnumber: '14350',
        barcode: '47970',
        quantity: 380,
        price: 49865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 548 для Москва, Красота, Запчасти, Трио',
    },
    {
        id: 549,
        artnumber: '33619',
        barcode: '62097',
        quantity: 244,
        price: 37282,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 549 для Москва, Красота, Запчасти, Трио',
    },
    {
        id: 550,
        artnumber: '88408',
        barcode: '83639',
        quantity: 389,
        price: 47008,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 550 для Москва, Красота, Запчасти, Трио',
    },
    {
        id: 551,
        artnumber: '54438',
        barcode: '30970',
        quantity: 122,
        price: 13377,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 551 для Москва, Красота, Доставка, Альфа',
    },
    {
        id: 552,
        artnumber: '18707',
        barcode: '94921',
        quantity: 125,
        price: 26523,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 552 для Москва, Красота, Доставка, Альфа',
    },
    {
        id: 553,
        artnumber: '69251',
        barcode: '94926',
        quantity: 357,
        price: 27525,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 553 для Москва, Красота, Доставка, Альфа',
    },
    {
        id: 554,
        artnumber: '64126',
        barcode: '36096',
        quantity: 74,
        price: 27156,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 554 для Москва, Красота, Доставка, Альфа',
    },
    {
        id: 555,
        artnumber: '60470',
        barcode: '42862',
        quantity: 247,
        price: 40064,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 555 для Москва, Красота, Доставка, Альфа',
    },
    {
        id: 556,
        artnumber: '99150',
        barcode: '11951',
        quantity: 230,
        price: 29623,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 556 для Москва, Красота, Доставка, Альфа',
    },
    {
        id: 557,
        artnumber: '30885',
        barcode: '42894',
        quantity: 33,
        price: 17196,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 557 для Москва, Красота, Доставка, Альфа',
    },
    {
        id: 558,
        artnumber: '90127',
        barcode: '95204',
        quantity: 261,
        price: 34001,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 558 для Москва, Красота, Доставка, Строй',
    },
    {
        id: 559,
        artnumber: '44847',
        barcode: '65108',
        quantity: 365,
        price: 16881,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 559 для Москва, Красота, Доставка, Строй',
    },
    {
        id: 560,
        artnumber: '51279',
        barcode: '19267',
        quantity: 327,
        price: 29749,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 560 для Москва, Красота, Доставка, Строй',
    },
    {
        id: 561,
        artnumber: '76403',
        barcode: '14937',
        quantity: 33,
        price: 29928,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 561 для Москва, Красота, Доставка, Строй',
    },
    {
        id: 562,
        artnumber: '76192',
        barcode: '45102',
        quantity: 279,
        price: 32712,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 562 для Москва, Красота, Доставка, Строй',
    },
    {
        id: 563,
        artnumber: '36234',
        barcode: '10694',
        quantity: 454,
        price: 19323,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 563 для Москва, Красота, Доставка, Строй',
    },
    {
        id: 564,
        artnumber: '43427',
        barcode: '26387',
        quantity: 406,
        price: 46132,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 564 для Москва, Красота, Доставка, Строй',
    },
    {
        id: 565,
        artnumber: '91702',
        barcode: '54844',
        quantity: 416,
        price: 1629,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 565 для Москва, Красота, Доставка, Бета',
    },
    {
        id: 566,
        artnumber: '91947',
        barcode: '96403',
        quantity: 349,
        price: 3870,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 566 для Москва, Красота, Доставка, Бета',
    },
    {
        id: 567,
        artnumber: '67461',
        barcode: '23300',
        quantity: 196,
        price: 23057,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 567 для Москва, Красота, Доставка, Бета',
    },
    {
        id: 568,
        artnumber: '76130',
        barcode: '29805',
        quantity: 237,
        price: 22524,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 568 для Москва, Красота, Доставка, Бета',
    },
    {
        id: 569,
        artnumber: '54095',
        barcode: '11238',
        quantity: 323,
        price: 15974,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 569 для Москва, Красота, Доставка, Бета',
    },
    {
        id: 570,
        artnumber: '58538',
        barcode: '99846',
        quantity: 323,
        price: 43713,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 570 для Москва, Красота, Доставка, Бета',
    },
    {
        id: 571,
        artnumber: '78900',
        barcode: '24643',
        quantity: 128,
        price: 20105,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 571 для Москва, Красота, Доставка, Бета',
    },
    {
        id: 572,
        artnumber: '49031',
        barcode: '94680',
        quantity: 474,
        price: 15367,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 572 для Москва, Красота, Доставка, Гамма',
    },
    {
        id: 573,
        artnumber: '83314',
        barcode: '29807',
        quantity: 255,
        price: 5644,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 573 для Москва, Красота, Доставка, Гамма',
    },
    {
        id: 574,
        artnumber: '43735',
        barcode: '40833',
        quantity: 7,
        price: 24449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 574 для Москва, Красота, Доставка, Гамма',
    },
    {
        id: 575,
        artnumber: '42893',
        barcode: '21058',
        quantity: 231,
        price: 16530,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 575 для Москва, Красота, Доставка, Гамма',
    },
    {
        id: 576,
        artnumber: '98422',
        barcode: '34819',
        quantity: 31,
        price: 19474,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 576 для Москва, Красота, Доставка, Гамма',
    },
    {
        id: 577,
        artnumber: '36268',
        barcode: '30317',
        quantity: 95,
        price: 39741,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 577 для Москва, Красота, Доставка, Гамма',
    },
    {
        id: 578,
        artnumber: '14076',
        barcode: '38885',
        quantity: 129,
        price: 12964,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 578 для Москва, Красота, Доставка, Гамма',
    },
    {
        id: 579,
        artnumber: '75276',
        barcode: '71847',
        quantity: 491,
        price: 45276,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 579 для Москва, Красота, Доставка, Трио',
    },
    {
        id: 580,
        artnumber: '59726',
        barcode: '65755',
        quantity: 493,
        price: 23164,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 580 для Москва, Красота, Доставка, Трио',
    },
    {
        id: 581,
        artnumber: '76259',
        barcode: '88304',
        quantity: 479,
        price: 31827,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 581 для Москва, Красота, Доставка, Трио',
    },
    {
        id: 582,
        artnumber: '55708',
        barcode: '87975',
        quantity: 87,
        price: 30837,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 582 для Москва, Красота, Доставка, Трио',
    },
    {
        id: 583,
        artnumber: '21949',
        barcode: '67499',
        quantity: 247,
        price: 33310,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 583 для Москва, Красота, Доставка, Трио',
    },
    {
        id: 584,
        artnumber: '95068',
        barcode: '31651',
        quantity: 364,
        price: 3199,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 584 для Москва, Красота, Доставка, Трио',
    },
    {
        id: 585,
        artnumber: '24665',
        barcode: '96385',
        quantity: 119,
        price: 21134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 585 для Москва, Красота, Доставка, Трио',
    },
    {
        id: 586,
        artnumber: '44134',
        barcode: '19320',
        quantity: 375,
        price: 19085,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 586 для Москва, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 587,
        artnumber: '55058',
        barcode: '51900',
        quantity: 162,
        price: 12419,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 587 для Москва, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 588,
        artnumber: '91839',
        barcode: '10856',
        quantity: 402,
        price: 4709,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 588 для Москва, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 589,
        artnumber: '54701',
        barcode: '82459',
        quantity: 162,
        price: 16138,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 589 для Москва, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 590,
        artnumber: '11635',
        barcode: '79947',
        quantity: 31,
        price: 31484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 590 для Москва, Красота, Шиномонтаж, Строй',
    },
    {
        id: 591,
        artnumber: '60385',
        barcode: '32091',
        quantity: 334,
        price: 38480,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 591 для Москва, Красота, Шиномонтаж, Строй',
    },
    {
        id: 592,
        artnumber: '24700',
        barcode: '50754',
        quantity: 491,
        price: 28004,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 592 для Москва, Красота, Шиномонтаж, Строй',
    },
    {
        id: 593,
        artnumber: '46772',
        barcode: '37623',
        quantity: 457,
        price: 18685,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 593 для Москва, Красота, Шиномонтаж, Строй',
    },
    {
        id: 594,
        artnumber: '77193',
        barcode: '30979',
        quantity: 386,
        price: 14498,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 594 для Москва, Красота, Шиномонтаж, Бета',
    },
    {
        id: 595,
        artnumber: '24089',
        barcode: '20418',
        quantity: 325,
        price: 15528,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 595 для Москва, Красота, Шиномонтаж, Бета',
    },
    {
        id: 596,
        artnumber: '80031',
        barcode: '45054',
        quantity: 211,
        price: 31404,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 596 для Москва, Красота, Шиномонтаж, Бета',
    },
    {
        id: 597,
        artnumber: '63814',
        barcode: '61048',
        quantity: 406,
        price: 33306,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 597 для Москва, Красота, Шиномонтаж, Бета',
    },
    {
        id: 598,
        artnumber: '23611',
        barcode: '16321',
        quantity: 499,
        price: 37676,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 598 для Москва, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 599,
        artnumber: '88290',
        barcode: '98555',
        quantity: 434,
        price: 33727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 599 для Москва, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 600,
        artnumber: '69908',
        barcode: '85248',
        quantity: 25,
        price: 48292,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 600 для Москва, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 601,
        artnumber: '25342',
        barcode: '15314',
        quantity: 382,
        price: 10360,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 601 для Москва, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 602,
        artnumber: '66574',
        barcode: '21040',
        quantity: 362,
        price: 24969,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 602 для Москва, Красота, Шиномонтаж, Трио',
    },
    {
        id: 603,
        artnumber: '58859',
        barcode: '81645',
        quantity: 500,
        price: 15052,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 603 для Москва, Красота, Шиномонтаж, Трио',
    },
    {
        id: 604,
        artnumber: '97986',
        barcode: '83203',
        quantity: 195,
        price: 4285,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 604 для Москва, Красота, Шиномонтаж, Трио',
    },
    {
        id: 605,
        artnumber: '29034',
        barcode: '77459',
        quantity: 195,
        price: 7788,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 605 для Москва, Красота, Шиномонтаж, Трио',
    },
    {
        id: 606,
        artnumber: '25163',
        barcode: '78089',
        quantity: 184,
        price: 37721,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 606 для Москва, Красота, Замена масла, Альфа',
    },
    {
        id: 607,
        artnumber: '89476',
        barcode: '34540',
        quantity: 302,
        price: 15862,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 607 для Москва, Красота, Замена масла, Альфа',
    },
    {
        id: 608,
        artnumber: '41588',
        barcode: '72244',
        quantity: 340,
        price: 11347,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 608 для Москва, Красота, Замена масла, Альфа',
    },
    {
        id: 609,
        artnumber: '19671',
        barcode: '95872',
        quantity: 88,
        price: 9282,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 609 для Москва, Красота, Замена масла, Альфа',
    },
    {
        id: 610,
        artnumber: '84615',
        barcode: '44250',
        quantity: 135,
        price: 29874,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 610 для Москва, Красота, Замена масла, Альфа',
    },
    {
        id: 611,
        artnumber: '21543',
        barcode: '43090',
        quantity: 14,
        price: 35027,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 611 для Москва, Красота, Замена масла, Альфа',
    },
    {
        id: 612,
        artnumber: '57491',
        barcode: '23952',
        quantity: 224,
        price: 22271,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 612 для Москва, Красота, Замена масла, Строй',
    },
    {
        id: 613,
        artnumber: '25956',
        barcode: '95836',
        quantity: 77,
        price: 1804,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 613 для Москва, Красота, Замена масла, Строй',
    },
    {
        id: 614,
        artnumber: '47367',
        barcode: '98832',
        quantity: 171,
        price: 11658,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 614 для Москва, Красота, Замена масла, Строй',
    },
    {
        id: 615,
        artnumber: '23530',
        barcode: '12823',
        quantity: 313,
        price: 27409,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 615 для Москва, Красота, Замена масла, Строй',
    },
    {
        id: 616,
        artnumber: '25679',
        barcode: '33508',
        quantity: 447,
        price: 28150,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 616 для Москва, Красота, Замена масла, Строй',
    },
    {
        id: 617,
        artnumber: '29248',
        barcode: '44043',
        quantity: 177,
        price: 45825,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 617 для Москва, Красота, Замена масла, Строй',
    },
    {
        id: 618,
        artnumber: '94592',
        barcode: '95603',
        quantity: 120,
        price: 28893,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 618 для Москва, Красота, Замена масла, Бета',
    },
    {
        id: 619,
        artnumber: '87358',
        barcode: '57120',
        quantity: 41,
        price: 27400,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 619 для Москва, Красота, Замена масла, Бета',
    },
    {
        id: 620,
        artnumber: '52681',
        barcode: '51851',
        quantity: 13,
        price: 17293,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 620 для Москва, Красота, Замена масла, Бета',
    },
    {
        id: 621,
        artnumber: '83237',
        barcode: '81756',
        quantity: 323,
        price: 28385,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 621 для Москва, Красота, Замена масла, Бета',
    },
    {
        id: 622,
        artnumber: '96449',
        barcode: '14585',
        quantity: 488,
        price: 38928,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 622 для Москва, Красота, Замена масла, Бета',
    },
    {
        id: 623,
        artnumber: '61567',
        barcode: '86955',
        quantity: 314,
        price: 21725,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 623 для Москва, Красота, Замена масла, Бета',
    },
    {
        id: 624,
        artnumber: '57294',
        barcode: '59714',
        quantity: 149,
        price: 39597,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 624 для Москва, Красота, Замена масла, Гамма',
    },
    {
        id: 625,
        artnumber: '64675',
        barcode: '29309',
        quantity: 221,
        price: 40097,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 625 для Москва, Красота, Замена масла, Гамма',
    },
    {
        id: 626,
        artnumber: '15610',
        barcode: '54643',
        quantity: 387,
        price: 43276,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 626 для Москва, Красота, Замена масла, Гамма',
    },
    {
        id: 627,
        artnumber: '61799',
        barcode: '72760',
        quantity: 174,
        price: 36892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 627 для Москва, Красота, Замена масла, Гамма',
    },
    {
        id: 628,
        artnumber: '83778',
        barcode: '27591',
        quantity: 361,
        price: 21534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 628 для Москва, Красота, Замена масла, Гамма',
    },
    {
        id: 629,
        artnumber: '76934',
        barcode: '33728',
        quantity: 416,
        price: 41492,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 629 для Москва, Красота, Замена масла, Гамма',
    },
    {
        id: 630,
        artnumber: '21679',
        barcode: '79696',
        quantity: 354,
        price: 9528,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 630 для Москва, Красота, Замена масла, Трио',
    },
    {
        id: 631,
        artnumber: '76855',
        barcode: '32420',
        quantity: 98,
        price: 14471,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 631 для Москва, Красота, Замена масла, Трио',
    },
    {
        id: 632,
        artnumber: '56818',
        barcode: '83815',
        quantity: 194,
        price: 17075,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 632 для Москва, Красота, Замена масла, Трио',
    },
    {
        id: 633,
        artnumber: '37293',
        barcode: '31021',
        quantity: 474,
        price: 24412,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 633 для Москва, Красота, Замена масла, Трио',
    },
    {
        id: 634,
        artnumber: '48194',
        barcode: '78076',
        quantity: 166,
        price: 9318,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 634 для Москва, Красота, Замена масла, Трио',
    },
    {
        id: 635,
        artnumber: '76983',
        barcode: '30203',
        quantity: 89,
        price: 13917,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 635 для Москва, Красота, Замена масла, Трио',
    },
    {
        id: 636,
        artnumber: '65802',
        barcode: '33551',
        quantity: 308,
        price: 29012,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 636 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 637,
        artnumber: '11059',
        barcode: '96918',
        quantity: 80,
        price: 34418,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 637 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 638,
        artnumber: '26073',
        barcode: '76027',
        quantity: 234,
        price: 30990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 638 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 639,
        artnumber: '31555',
        barcode: '39905',
        quantity: 22,
        price: 9556,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 639 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 640,
        artnumber: '64944',
        barcode: '61490',
        quantity: 284,
        price: 20764,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 640 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 641,
        artnumber: '19487',
        barcode: '82351',
        quantity: 401,
        price: 45304,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 641 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 642,
        artnumber: '64528',
        barcode: '94087',
        quantity: 220,
        price: 15093,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 642 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 643,
        artnumber: '48723',
        barcode: '70358',
        quantity: 410,
        price: 30091,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 643 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 644,
        artnumber: '27545',
        barcode: '23429',
        quantity: 45,
        price: 6537,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 644 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 645,
        artnumber: '81607',
        barcode: '96410',
        quantity: 164,
        price: 40392,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 645 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 646,
        artnumber: '99480',
        barcode: '50612',
        quantity: 113,
        price: 18409,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 646 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 647,
        artnumber: '35572',
        barcode: '54333',
        quantity: 410,
        price: 48303,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 647 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 648,
        artnumber: '57994',
        barcode: '85956',
        quantity: 114,
        price: 23226,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 648 для Москва, Здоровье, Сервис, Альфа',
    },
    {
        id: 649,
        artnumber: '65472',
        barcode: '14676',
        quantity: 68,
        price: 10784,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 649 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 650,
        artnumber: '66296',
        barcode: '66433',
        quantity: 290,
        price: 21344,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 650 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 651,
        artnumber: '95139',
        barcode: '28075',
        quantity: 471,
        price: 33139,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 651 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 652,
        artnumber: '32328',
        barcode: '28276',
        quantity: 97,
        price: 46279,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 652 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 653,
        artnumber: '51170',
        barcode: '55505',
        quantity: 91,
        price: 1917,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 653 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 654,
        artnumber: '29080',
        barcode: '84921',
        quantity: 42,
        price: 9261,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 654 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 655,
        artnumber: '84896',
        barcode: '60913',
        quantity: 205,
        price: 19982,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 655 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 656,
        artnumber: '41151',
        barcode: '11014',
        quantity: 52,
        price: 34638,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 656 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 657,
        artnumber: '34798',
        barcode: '26380',
        quantity: 493,
        price: 34173,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 657 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 658,
        artnumber: '83545',
        barcode: '42239',
        quantity: 265,
        price: 14613,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 658 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 659,
        artnumber: '86327',
        barcode: '17101',
        quantity: 335,
        price: 7818,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 659 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 660,
        artnumber: '96347',
        barcode: '16005',
        quantity: 108,
        price: 28199,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 660 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 661,
        artnumber: '51384',
        barcode: '54972',
        quantity: 18,
        price: 25905,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 661 для Москва, Здоровье, Сервис, Строй',
    },
    {
        id: 662,
        artnumber: '29842',
        barcode: '85963',
        quantity: 362,
        price: 23346,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 662 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 663,
        artnumber: '94882',
        barcode: '87322',
        quantity: 246,
        price: 48545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 663 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 664,
        artnumber: '54955',
        barcode: '71855',
        quantity: 440,
        price: 5243,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 664 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 665,
        artnumber: '27658',
        barcode: '89187',
        quantity: 205,
        price: 40749,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 665 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 666,
        artnumber: '73614',
        barcode: '31405',
        quantity: 223,
        price: 26129,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 666 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 667,
        artnumber: '69200',
        barcode: '37511',
        quantity: 150,
        price: 12265,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 667 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 668,
        artnumber: '75506',
        barcode: '52860',
        quantity: 245,
        price: 38798,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 668 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 669,
        artnumber: '23213',
        barcode: '72826',
        quantity: 462,
        price: 23107,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 669 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 670,
        artnumber: '46506',
        barcode: '46631',
        quantity: 468,
        price: 29824,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 670 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 671,
        artnumber: '26098',
        barcode: '91401',
        quantity: 294,
        price: 18608,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 671 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 672,
        artnumber: '47973',
        barcode: '78409',
        quantity: 43,
        price: 7041,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 672 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 673,
        artnumber: '11291',
        barcode: '81754',
        quantity: 226,
        price: 7472,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 673 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 674,
        artnumber: '86692',
        barcode: '38098',
        quantity: 106,
        price: 5594,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 674 для Москва, Здоровье, Сервис, Бета',
    },
    {
        id: 675,
        artnumber: '93419',
        barcode: '16714',
        quantity: 175,
        price: 33359,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 675 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 676,
        artnumber: '75429',
        barcode: '15492',
        quantity: 462,
        price: 34277,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 676 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 677,
        artnumber: '64648',
        barcode: '69999',
        quantity: 341,
        price: 39236,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 677 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 678,
        artnumber: '82441',
        barcode: '35891',
        quantity: 421,
        price: 2765,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 678 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 679,
        artnumber: '99158',
        barcode: '89279',
        quantity: 430,
        price: 45965,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 679 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 680,
        artnumber: '25492',
        barcode: '49909',
        quantity: 45,
        price: 48303,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 680 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 681,
        artnumber: '40831',
        barcode: '87754',
        quantity: 86,
        price: 34306,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 681 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 682,
        artnumber: '60961',
        barcode: '91661',
        quantity: 350,
        price: 40317,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 682 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 683,
        artnumber: '28190',
        barcode: '46066',
        quantity: 434,
        price: 7579,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 683 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 684,
        artnumber: '63928',
        barcode: '34207',
        quantity: 390,
        price: 5660,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 684 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 685,
        artnumber: '78523',
        barcode: '38938',
        quantity: 33,
        price: 44156,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 685 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 686,
        artnumber: '32753',
        barcode: '60635',
        quantity: 173,
        price: 6141,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 686 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 687,
        artnumber: '36291',
        barcode: '37263',
        quantity: 334,
        price: 36310,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 687 для Москва, Здоровье, Сервис, Гамма',
    },
    {
        id: 688,
        artnumber: '46577',
        barcode: '85222',
        quantity: 329,
        price: 44935,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 688 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 689,
        artnumber: '78162',
        barcode: '24772',
        quantity: 5,
        price: 327,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 689 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 690,
        artnumber: '81364',
        barcode: '43098',
        quantity: 342,
        price: 13241,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 690 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 691,
        artnumber: '67167',
        barcode: '10846',
        quantity: 25,
        price: 30152,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 691 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 692,
        artnumber: '10414',
        barcode: '82910',
        quantity: 436,
        price: 13969,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 692 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 693,
        artnumber: '62256',
        barcode: '10526',
        quantity: 197,
        price: 28182,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 693 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 694,
        artnumber: '79818',
        barcode: '28906',
        quantity: 173,
        price: 36340,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 694 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 695,
        artnumber: '15414',
        barcode: '18385',
        quantity: 479,
        price: 8790,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 695 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 696,
        artnumber: '25461',
        barcode: '86983',
        quantity: 25,
        price: 14577,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 696 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 697,
        artnumber: '41619',
        barcode: '35790',
        quantity: 476,
        price: 131,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 697 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 698,
        artnumber: '32688',
        barcode: '92243',
        quantity: 59,
        price: 17313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 698 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 699,
        artnumber: '84993',
        barcode: '63784',
        quantity: 463,
        price: 37502,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 699 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 700,
        artnumber: '95776',
        barcode: '68492',
        quantity: 344,
        price: 38358,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 700 для Москва, Здоровье, Сервис, Трио',
    },
    {
        id: 701,
        artnumber: '98173',
        barcode: '74917',
        quantity: 292,
        price: 29552,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 701 для Москва, Здоровье, Запчасти, Альфа',
    },
    {
        id: 702,
        artnumber: '20049',
        barcode: '33234',
        quantity: 167,
        price: 41856,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 702 для Москва, Здоровье, Запчасти, Альфа',
    },
    {
        id: 703,
        artnumber: '15703',
        barcode: '96794',
        quantity: 485,
        price: 37593,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 703 для Москва, Здоровье, Запчасти, Альфа',
    },
    {
        id: 704,
        artnumber: '91350',
        barcode: '34909',
        quantity: 205,
        price: 8652,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 704 для Москва, Здоровье, Запчасти, Альфа',
    },
    {
        id: 705,
        artnumber: '44572',
        barcode: '33346',
        quantity: 213,
        price: 47030,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 705 для Москва, Здоровье, Запчасти, Альфа',
    },
    {
        id: 706,
        artnumber: '91301',
        barcode: '48459',
        quantity: 316,
        price: 49983,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 706 для Москва, Здоровье, Запчасти, Альфа',
    },
    {
        id: 707,
        artnumber: '87714',
        barcode: '92255',
        quantity: 147,
        price: 42453,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 707 для Москва, Здоровье, Запчасти, Альфа',
    },
    {
        id: 708,
        artnumber: '22990',
        barcode: '14873',
        quantity: 195,
        price: 18524,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 708 для Москва, Здоровье, Запчасти, Альфа',
    },
    {
        id: 709,
        artnumber: '34968',
        barcode: '88741',
        quantity: 75,
        price: 41972,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 709 для Москва, Здоровье, Запчасти, Альфа',
    },
    {
        id: 710,
        artnumber: '82326',
        barcode: '32400',
        quantity: 386,
        price: 26065,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 710 для Москва, Здоровье, Запчасти, Альфа',
    },
    {
        id: 711,
        artnumber: '67650',
        barcode: '94266',
        quantity: 273,
        price: 37621,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 711 для Москва, Здоровье, Запчасти, Строй',
    },
    {
        id: 712,
        artnumber: '19466',
        barcode: '24326',
        quantity: 356,
        price: 41918,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 712 для Москва, Здоровье, Запчасти, Строй',
    },
    {
        id: 713,
        artnumber: '63035',
        barcode: '83701',
        quantity: 463,
        price: 16900,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 713 для Москва, Здоровье, Запчасти, Строй',
    },
    {
        id: 714,
        artnumber: '91584',
        barcode: '27622',
        quantity: 307,
        price: 24123,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 714 для Москва, Здоровье, Запчасти, Строй',
    },
    {
        id: 715,
        artnumber: '36219',
        barcode: '78021',
        quantity: 279,
        price: 2872,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 715 для Москва, Здоровье, Запчасти, Строй',
    },
    {
        id: 716,
        artnumber: '96467',
        barcode: '54549',
        quantity: 350,
        price: 39554,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 716 для Москва, Здоровье, Запчасти, Строй',
    },
    {
        id: 717,
        artnumber: '69622',
        barcode: '77909',
        quantity: 196,
        price: 30627,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 717 для Москва, Здоровье, Запчасти, Строй',
    },
    {
        id: 718,
        artnumber: '95751',
        barcode: '48570',
        quantity: 377,
        price: 49997,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 718 для Москва, Здоровье, Запчасти, Строй',
    },
    {
        id: 719,
        artnumber: '96948',
        barcode: '69843',
        quantity: 207,
        price: 10194,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 719 для Москва, Здоровье, Запчасти, Строй',
    },
    {
        id: 720,
        artnumber: '13086',
        barcode: '74640',
        quantity: 191,
        price: 35097,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 720 для Москва, Здоровье, Запчасти, Строй',
    },
    {
        id: 721,
        artnumber: '62462',
        barcode: '67737',
        quantity: 336,
        price: 1895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 721 для Москва, Здоровье, Запчасти, Бета',
    },
    {
        id: 722,
        artnumber: '17122',
        barcode: '67340',
        quantity: 131,
        price: 20124,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 722 для Москва, Здоровье, Запчасти, Бета',
    },
    {
        id: 723,
        artnumber: '55508',
        barcode: '36744',
        quantity: 46,
        price: 46443,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 723 для Москва, Здоровье, Запчасти, Бета',
    },
    {
        id: 724,
        artnumber: '25506',
        barcode: '27511',
        quantity: 85,
        price: 9379,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 724 для Москва, Здоровье, Запчасти, Бета',
    },
    {
        id: 725,
        artnumber: '25149',
        barcode: '94854',
        quantity: 451,
        price: 6162,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 725 для Москва, Здоровье, Запчасти, Бета',
    },
    {
        id: 726,
        artnumber: '50141',
        barcode: '83193',
        quantity: 23,
        price: 40720,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 726 для Москва, Здоровье, Запчасти, Бета',
    },
    {
        id: 727,
        artnumber: '83684',
        barcode: '97973',
        quantity: 208,
        price: 46488,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 727 для Москва, Здоровье, Запчасти, Бета',
    },
    {
        id: 728,
        artnumber: '28909',
        barcode: '59536',
        quantity: 428,
        price: 28022,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 728 для Москва, Здоровье, Запчасти, Бета',
    },
    {
        id: 729,
        artnumber: '23757',
        barcode: '57211',
        quantity: 374,
        price: 48849,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 729 для Москва, Здоровье, Запчасти, Бета',
    },
    {
        id: 730,
        artnumber: '89520',
        barcode: '27149',
        quantity: 263,
        price: 2307,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 730 для Москва, Здоровье, Запчасти, Бета',
    },
    {
        id: 731,
        artnumber: '63384',
        barcode: '20451',
        quantity: 373,
        price: 36973,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 731 для Москва, Здоровье, Запчасти, Гамма',
    },
    {
        id: 732,
        artnumber: '45587',
        barcode: '37883',
        quantity: 107,
        price: 46833,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 732 для Москва, Здоровье, Запчасти, Гамма',
    },
    {
        id: 733,
        artnumber: '52229',
        barcode: '70397',
        quantity: 455,
        price: 333,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 733 для Москва, Здоровье, Запчасти, Гамма',
    },
    {
        id: 734,
        artnumber: '18040',
        barcode: '79675',
        quantity: 334,
        price: 48139,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 734 для Москва, Здоровье, Запчасти, Гамма',
    },
    {
        id: 735,
        artnumber: '97408',
        barcode: '32211',
        quantity: 314,
        price: 43632,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 735 для Москва, Здоровье, Запчасти, Гамма',
    },
    {
        id: 736,
        artnumber: '41032',
        barcode: '64199',
        quantity: 6,
        price: 33103,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 736 для Москва, Здоровье, Запчасти, Гамма',
    },
    {
        id: 737,
        artnumber: '79619',
        barcode: '20272',
        quantity: 464,
        price: 39411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 737 для Москва, Здоровье, Запчасти, Гамма',
    },
    {
        id: 738,
        artnumber: '55125',
        barcode: '32557',
        quantity: 343,
        price: 23014,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 738 для Москва, Здоровье, Запчасти, Гамма',
    },
    {
        id: 739,
        artnumber: '14905',
        barcode: '40230',
        quantity: 442,
        price: 22935,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 739 для Москва, Здоровье, Запчасти, Гамма',
    },
    {
        id: 740,
        artnumber: '34829',
        barcode: '50101',
        quantity: 258,
        price: 32211,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 740 для Москва, Здоровье, Запчасти, Гамма',
    },
    {
        id: 741,
        artnumber: '19280',
        barcode: '56635',
        quantity: 122,
        price: 5121,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 741 для Москва, Здоровье, Запчасти, Трио',
    },
    {
        id: 742,
        artnumber: '89450',
        barcode: '59078',
        quantity: 205,
        price: 15868,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 742 для Москва, Здоровье, Запчасти, Трио',
    },
    {
        id: 743,
        artnumber: '77262',
        barcode: '21444',
        quantity: 118,
        price: 43994,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 743 для Москва, Здоровье, Запчасти, Трио',
    },
    {
        id: 744,
        artnumber: '11170',
        barcode: '94414',
        quantity: 93,
        price: 43653,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 744 для Москва, Здоровье, Запчасти, Трио',
    },
    {
        id: 745,
        artnumber: '90304',
        barcode: '51377',
        quantity: 401,
        price: 12287,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 745 для Москва, Здоровье, Запчасти, Трио',
    },
    {
        id: 746,
        artnumber: '80503',
        barcode: '24450',
        quantity: 68,
        price: 17253,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 746 для Москва, Здоровье, Запчасти, Трио',
    },
    {
        id: 747,
        artnumber: '18975',
        barcode: '47341',
        quantity: 461,
        price: 30251,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 747 для Москва, Здоровье, Запчасти, Трио',
    },
    {
        id: 748,
        artnumber: '23604',
        barcode: '45036',
        quantity: 60,
        price: 28453,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 748 для Москва, Здоровье, Запчасти, Трио',
    },
    {
        id: 749,
        artnumber: '76607',
        barcode: '64042',
        quantity: 446,
        price: 8370,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 749 для Москва, Здоровье, Запчасти, Трио',
    },
    {
        id: 750,
        artnumber: '15973',
        barcode: '51423',
        quantity: 323,
        price: 28668,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 750 для Москва, Здоровье, Запчасти, Трио',
    },
    {
        id: 751,
        artnumber: '92516',
        barcode: '96655',
        quantity: 125,
        price: 38939,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 751 для Москва, Здоровье, Доставка, Альфа',
    },
    {
        id: 752,
        artnumber: '28073',
        barcode: '48932',
        quantity: 480,
        price: 29022,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 752 для Москва, Здоровье, Доставка, Альфа',
    },
    {
        id: 753,
        artnumber: '21521',
        barcode: '94694',
        quantity: 243,
        price: 40937,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 753 для Москва, Здоровье, Доставка, Альфа',
    },
    {
        id: 754,
        artnumber: '39482',
        barcode: '19954',
        quantity: 43,
        price: 44083,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 754 для Москва, Здоровье, Доставка, Альфа',
    },
    {
        id: 755,
        artnumber: '58210',
        barcode: '23789',
        quantity: 235,
        price: 20728,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 755 для Москва, Здоровье, Доставка, Альфа',
    },
    {
        id: 756,
        artnumber: '12317',
        barcode: '45932',
        quantity: 4,
        price: 19052,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 756 для Москва, Здоровье, Доставка, Альфа',
    },
    {
        id: 757,
        artnumber: '19162',
        barcode: '68661',
        quantity: 40,
        price: 37403,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 757 для Москва, Здоровье, Доставка, Альфа',
    },
    {
        id: 758,
        artnumber: '81677',
        barcode: '29946',
        quantity: 213,
        price: 36957,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 758 для Москва, Здоровье, Доставка, Строй',
    },
    {
        id: 759,
        artnumber: '70415',
        barcode: '93684',
        quantity: 130,
        price: 10122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 759 для Москва, Здоровье, Доставка, Строй',
    },
    {
        id: 760,
        artnumber: '92359',
        barcode: '79132',
        quantity: 449,
        price: 43643,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 760 для Москва, Здоровье, Доставка, Строй',
    },
    {
        id: 761,
        artnumber: '76922',
        barcode: '56463',
        quantity: 321,
        price: 20215,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 761 для Москва, Здоровье, Доставка, Строй',
    },
    {
        id: 762,
        artnumber: '19364',
        barcode: '96016',
        quantity: 424,
        price: 16631,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 762 для Москва, Здоровье, Доставка, Строй',
    },
    {
        id: 763,
        artnumber: '78938',
        barcode: '43848',
        quantity: 9,
        price: 20805,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 763 для Москва, Здоровье, Доставка, Строй',
    },
    {
        id: 764,
        artnumber: '62642',
        barcode: '69861',
        quantity: 79,
        price: 49096,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 764 для Москва, Здоровье, Доставка, Строй',
    },
    {
        id: 765,
        artnumber: '60719',
        barcode: '67195',
        quantity: 53,
        price: 28730,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 765 для Москва, Здоровье, Доставка, Бета',
    },
    {
        id: 766,
        artnumber: '53178',
        barcode: '91899',
        quantity: 479,
        price: 34184,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 766 для Москва, Здоровье, Доставка, Бета',
    },
    {
        id: 767,
        artnumber: '78536',
        barcode: '67936',
        quantity: 228,
        price: 49168,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 767 для Москва, Здоровье, Доставка, Бета',
    },
    {
        id: 768,
        artnumber: '21591',
        barcode: '50637',
        quantity: 383,
        price: 36287,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 768 для Москва, Здоровье, Доставка, Бета',
    },
    {
        id: 769,
        artnumber: '13266',
        barcode: '61452',
        quantity: 91,
        price: 33231,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 769 для Москва, Здоровье, Доставка, Бета',
    },
    {
        id: 770,
        artnumber: '87220',
        barcode: '56551',
        quantity: 54,
        price: 37075,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 770 для Москва, Здоровье, Доставка, Бета',
    },
    {
        id: 771,
        artnumber: '90265',
        barcode: '56781',
        quantity: 16,
        price: 43892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 771 для Москва, Здоровье, Доставка, Бета',
    },
    {
        id: 772,
        artnumber: '17416',
        barcode: '91265',
        quantity: 29,
        price: 5321,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 772 для Москва, Здоровье, Доставка, Гамма',
    },
    {
        id: 773,
        artnumber: '11839',
        barcode: '99617',
        quantity: 118,
        price: 3076,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 773 для Москва, Здоровье, Доставка, Гамма',
    },
    {
        id: 774,
        artnumber: '95375',
        barcode: '25509',
        quantity: 12,
        price: 26512,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 774 для Москва, Здоровье, Доставка, Гамма',
    },
    {
        id: 775,
        artnumber: '26856',
        barcode: '31305',
        quantity: 307,
        price: 33220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 775 для Москва, Здоровье, Доставка, Гамма',
    },
    {
        id: 776,
        artnumber: '25610',
        barcode: '74033',
        quantity: 489,
        price: 8678,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 776 для Москва, Здоровье, Доставка, Гамма',
    },
    {
        id: 777,
        artnumber: '56224',
        barcode: '85586',
        quantity: 193,
        price: 10158,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 777 для Москва, Здоровье, Доставка, Гамма',
    },
    {
        id: 778,
        artnumber: '41530',
        barcode: '24149',
        quantity: 244,
        price: 10747,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 778 для Москва, Здоровье, Доставка, Гамма',
    },
    {
        id: 779,
        artnumber: '36088',
        barcode: '13081',
        quantity: 140,
        price: 25746,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 779 для Москва, Здоровье, Доставка, Трио',
    },
    {
        id: 780,
        artnumber: '89310',
        barcode: '40957',
        quantity: 360,
        price: 3535,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 780 для Москва, Здоровье, Доставка, Трио',
    },
    {
        id: 781,
        artnumber: '35694',
        barcode: '31847',
        quantity: 36,
        price: 38254,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 781 для Москва, Здоровье, Доставка, Трио',
    },
    {
        id: 782,
        artnumber: '65803',
        barcode: '60179',
        quantity: 215,
        price: 1137,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 782 для Москва, Здоровье, Доставка, Трио',
    },
    {
        id: 783,
        artnumber: '86752',
        barcode: '39286',
        quantity: 211,
        price: 46218,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 783 для Москва, Здоровье, Доставка, Трио',
    },
    {
        id: 784,
        artnumber: '71018',
        barcode: '96706',
        quantity: 339,
        price: 49507,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 784 для Москва, Здоровье, Доставка, Трио',
    },
    {
        id: 785,
        artnumber: '46316',
        barcode: '51530',
        quantity: 314,
        price: 31015,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 785 для Москва, Здоровье, Доставка, Трио',
    },
    {
        id: 786,
        artnumber: '54612',
        barcode: '63957',
        quantity: 265,
        price: 3998,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 786 для Москва, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 787,
        artnumber: '94469',
        barcode: '20763',
        quantity: 270,
        price: 33881,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 787 для Москва, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 788,
        artnumber: '55342',
        barcode: '40275',
        quantity: 34,
        price: 43717,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 788 для Москва, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 789,
        artnumber: '27481',
        barcode: '30455',
        quantity: 223,
        price: 30163,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 789 для Москва, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 790,
        artnumber: '14055',
        barcode: '73971',
        quantity: 361,
        price: 21180,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 790 для Москва, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 791,
        artnumber: '13882',
        barcode: '60289',
        quantity: 253,
        price: 31550,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 791 для Москва, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 792,
        artnumber: '26957',
        barcode: '23849',
        quantity: 172,
        price: 41361,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 792 для Москва, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 793,
        artnumber: '22429',
        barcode: '90218',
        quantity: 250,
        price: 25534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 793 для Москва, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 794,
        artnumber: '70511',
        barcode: '42326',
        quantity: 229,
        price: 14343,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 794 для Москва, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 795,
        artnumber: '91165',
        barcode: '33264',
        quantity: 192,
        price: 22972,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 795 для Москва, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 796,
        artnumber: '69666',
        barcode: '55329',
        quantity: 475,
        price: 26193,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 796 для Москва, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 797,
        artnumber: '13191',
        barcode: '29971',
        quantity: 487,
        price: 22172,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 797 для Москва, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 798,
        artnumber: '98371',
        barcode: '77279',
        quantity: 314,
        price: 7585,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 798 для Москва, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 799,
        artnumber: '40131',
        barcode: '37502',
        quantity: 171,
        price: 45224,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 799 для Москва, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 800,
        artnumber: '36879',
        barcode: '93409',
        quantity: 146,
        price: 26891,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 800 для Москва, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 801,
        artnumber: '22082',
        barcode: '82514',
        quantity: 405,
        price: 48931,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 801 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 802,
        artnumber: '85622',
        barcode: '79308',
        quantity: 102,
        price: 49953,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 802 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 803,
        artnumber: '72562',
        barcode: '74327',
        quantity: 90,
        price: 23234,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 803 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 804,
        artnumber: '10759',
        barcode: '94966',
        quantity: 193,
        price: 33138,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 804 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 805,
        artnumber: '36922',
        barcode: '79598',
        quantity: 145,
        price: 6257,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 805 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 806,
        artnumber: '27521',
        barcode: '51194',
        quantity: 376,
        price: 37738,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 806 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 807,
        artnumber: '40206',
        barcode: '39333',
        quantity: 490,
        price: 35934,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 807 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 808,
        artnumber: '58503',
        barcode: '89817',
        quantity: 437,
        price: 22000,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 808 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 809,
        artnumber: '37551',
        barcode: '68630',
        quantity: 252,
        price: 7110,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 809 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 810,
        artnumber: '82304',
        barcode: '85019',
        quantity: 82,
        price: 24314,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 810 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 811,
        artnumber: '77870',
        barcode: '35726',
        quantity: 474,
        price: 44816,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 811 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 812,
        artnumber: '56274',
        barcode: '72146',
        quantity: 464,
        price: 42320,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 812 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 813,
        artnumber: '57807',
        barcode: '21136',
        quantity: 172,
        price: 7383,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 813 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 814,
        artnumber: '91008',
        barcode: '26262',
        quantity: 99,
        price: 22806,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 814 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 815,
        artnumber: '79428',
        barcode: '88564',
        quantity: 299,
        price: 13265,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 815 для Москва, Здоровье, Замена масла, Альфа',
    },
    {
        id: 816,
        artnumber: '76793',
        barcode: '62225',
        quantity: 188,
        price: 40216,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 816 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 817,
        artnumber: '97848',
        barcode: '42203',
        quantity: 289,
        price: 36020,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 817 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 818,
        artnumber: '13344',
        barcode: '77485',
        quantity: 291,
        price: 11760,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 818 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 819,
        artnumber: '54868',
        barcode: '60706',
        quantity: 494,
        price: 37102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 819 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 820,
        artnumber: '60625',
        barcode: '45248',
        quantity: 239,
        price: 36367,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 820 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 821,
        artnumber: '95130',
        barcode: '17033',
        quantity: 392,
        price: 19768,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 821 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 822,
        artnumber: '48553',
        barcode: '83901',
        quantity: 168,
        price: 15328,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 822 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 823,
        artnumber: '89895',
        barcode: '11424',
        quantity: 400,
        price: 21040,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 823 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 824,
        artnumber: '55503',
        barcode: '12970',
        quantity: 411,
        price: 4123,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 824 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 825,
        artnumber: '64610',
        barcode: '81365',
        quantity: 481,
        price: 14300,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 825 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 826,
        artnumber: '34926',
        barcode: '86960',
        quantity: 12,
        price: 27071,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 826 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 827,
        artnumber: '99623',
        barcode: '26655',
        quantity: 24,
        price: 30287,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 827 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 828,
        artnumber: '91020',
        barcode: '53800',
        quantity: 333,
        price: 23767,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 828 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 829,
        artnumber: '21278',
        barcode: '28562',
        quantity: 283,
        price: 19222,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 829 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 830,
        artnumber: '78590',
        barcode: '15409',
        quantity: 465,
        price: 5544,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 830 для Москва, Здоровье, Замена масла, Строй',
    },
    {
        id: 831,
        artnumber: '49167',
        barcode: '49302',
        quantity: 392,
        price: 10233,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 831 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 832,
        artnumber: '19735',
        barcode: '34691',
        quantity: 249,
        price: 15673,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 832 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 833,
        artnumber: '25574',
        barcode: '15893',
        quantity: 399,
        price: 8822,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 833 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 834,
        artnumber: '85190',
        barcode: '98961',
        quantity: 443,
        price: 4300,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 834 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 835,
        artnumber: '80904',
        barcode: '16196',
        quantity: 425,
        price: 24041,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 835 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 836,
        artnumber: '83683',
        barcode: '43936',
        quantity: 221,
        price: 36134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 836 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 837,
        artnumber: '67171',
        barcode: '47382',
        quantity: 468,
        price: 38293,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 837 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 838,
        artnumber: '13207',
        barcode: '31628',
        quantity: 364,
        price: 33835,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 838 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 839,
        artnumber: '88985',
        barcode: '89639',
        quantity: 499,
        price: 36027,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 839 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 840,
        artnumber: '87300',
        barcode: '60936',
        quantity: 79,
        price: 16465,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 840 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 841,
        artnumber: '93636',
        barcode: '12754',
        quantity: 384,
        price: 42441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 841 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 842,
        artnumber: '94575',
        barcode: '92005',
        quantity: 415,
        price: 20327,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 842 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 843,
        artnumber: '10308',
        barcode: '60511',
        quantity: 378,
        price: 42879,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 843 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 844,
        artnumber: '12531',
        barcode: '22591',
        quantity: 75,
        price: 46017,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 844 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 845,
        artnumber: '31284',
        barcode: '11404',
        quantity: 491,
        price: 42058,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 845 для Москва, Здоровье, Замена масла, Бета',
    },
    {
        id: 846,
        artnumber: '60575',
        barcode: '39480',
        quantity: 75,
        price: 48974,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 846 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 847,
        artnumber: '34539',
        barcode: '20536',
        quantity: 473,
        price: 26175,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 847 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 848,
        artnumber: '99085',
        barcode: '22245',
        quantity: 113,
        price: 24811,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 848 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 849,
        artnumber: '32306',
        barcode: '82875',
        quantity: 243,
        price: 45042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 849 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 850,
        artnumber: '77664',
        barcode: '43958',
        quantity: 326,
        price: 21425,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 850 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 851,
        artnumber: '46652',
        barcode: '61489',
        quantity: 274,
        price: 1183,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 851 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 852,
        artnumber: '10296',
        barcode: '42624',
        quantity: 419,
        price: 16221,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 852 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 853,
        artnumber: '47014',
        barcode: '43647',
        quantity: 282,
        price: 30357,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 853 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 854,
        artnumber: '27529',
        barcode: '77249',
        quantity: 193,
        price: 22147,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 854 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 855,
        artnumber: '48601',
        barcode: '74583',
        quantity: 135,
        price: 17442,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 855 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 856,
        artnumber: '64080',
        barcode: '29238',
        quantity: 42,
        price: 13537,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 856 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 857,
        artnumber: '19096',
        barcode: '22562',
        quantity: 314,
        price: 18893,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 857 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 858,
        artnumber: '61986',
        barcode: '68903',
        quantity: 109,
        price: 19505,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 858 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 859,
        artnumber: '93333',
        barcode: '96054',
        quantity: 129,
        price: 27608,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 859 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 860,
        artnumber: '79382',
        barcode: '56976',
        quantity: 111,
        price: 38006,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 860 для Москва, Здоровье, Замена масла, Гамма',
    },
    {
        id: 861,
        artnumber: '10479',
        barcode: '65271',
        quantity: 277,
        price: 7407,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 861 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 862,
        artnumber: '63173',
        barcode: '12491',
        quantity: 23,
        price: 1427,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 862 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 863,
        artnumber: '66250',
        barcode: '30992',
        quantity: 190,
        price: 36176,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 863 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 864,
        artnumber: '10683',
        barcode: '90519',
        quantity: 499,
        price: 27784,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 864 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 865,
        artnumber: '20988',
        barcode: '90114',
        quantity: 87,
        price: 33869,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 865 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 866,
        artnumber: '56297',
        barcode: '41600',
        quantity: 303,
        price: 44255,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 866 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 867,
        artnumber: '69406',
        barcode: '46302',
        quantity: 370,
        price: 34455,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 867 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 868,
        artnumber: '56443',
        barcode: '77956',
        quantity: 306,
        price: 4037,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 868 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 869,
        artnumber: '95767',
        barcode: '51281',
        quantity: 494,
        price: 24163,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 869 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 870,
        artnumber: '58129',
        barcode: '25887',
        quantity: 258,
        price: 15028,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 870 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 871,
        artnumber: '24515',
        barcode: '20459',
        quantity: 112,
        price: 8806,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 871 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 872,
        artnumber: '27116',
        barcode: '74132',
        quantity: 272,
        price: 14425,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 872 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 873,
        artnumber: '34035',
        barcode: '16635',
        quantity: 36,
        price: 47389,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 873 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 874,
        artnumber: '38998',
        barcode: '63233',
        quantity: 381,
        price: 26170,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 874 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 875,
        artnumber: '59213',
        barcode: '40103',
        quantity: 361,
        price: 35975,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 875 для Москва, Здоровье, Замена масла, Трио',
    },
    {
        id: 876,
        artnumber: '43588',
        barcode: '52306',
        quantity: 274,
        price: 23659,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 876 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 877,
        artnumber: '36007',
        barcode: '52922',
        quantity: 332,
        price: 40406,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 877 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 878,
        artnumber: '87511',
        barcode: '44644',
        quantity: 281,
        price: 31807,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 878 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 879,
        artnumber: '89759',
        barcode: '18914',
        quantity: 303,
        price: 19483,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 879 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 880,
        artnumber: '23524',
        barcode: '53177',
        quantity: 111,
        price: 23475,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 880 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 881,
        artnumber: '94053',
        barcode: '63393',
        quantity: 272,
        price: 42307,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 881 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 882,
        artnumber: '90593',
        barcode: '14575',
        quantity: 356,
        price: 38463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 882 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 883,
        artnumber: '64898',
        barcode: '25078',
        quantity: 57,
        price: 7827,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 883 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 884,
        artnumber: '54331',
        barcode: '54609',
        quantity: 468,
        price: 41712,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 884 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 885,
        artnumber: '44626',
        barcode: '32510',
        quantity: 201,
        price: 7781,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 885 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 886,
        artnumber: '60852',
        barcode: '84078',
        quantity: 321,
        price: 19619,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 886 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 887,
        artnumber: '37289',
        barcode: '78423',
        quantity: 151,
        price: 20390,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 887 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 888,
        artnumber: '27732',
        barcode: '84965',
        quantity: 343,
        price: 44267,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 888 для Москва, Техника, Сервис, Альфа',
    },
    {
        id: 889,
        artnumber: '73511',
        barcode: '58025',
        quantity: 427,
        price: 34472,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 889 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 890,
        artnumber: '47995',
        barcode: '15027',
        quantity: 312,
        price: 33063,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 890 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 891,
        artnumber: '25602',
        barcode: '49137',
        quantity: 393,
        price: 42501,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 891 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 892,
        artnumber: '78771',
        barcode: '56898',
        quantity: 98,
        price: 44350,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 892 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 893,
        artnumber: '28591',
        barcode: '85344',
        quantity: 63,
        price: 23820,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 893 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 894,
        artnumber: '66372',
        barcode: '43805',
        quantity: 445,
        price: 21779,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 894 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 895,
        artnumber: '96743',
        barcode: '85631',
        quantity: 142,
        price: 26956,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 895 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 896,
        artnumber: '85234',
        barcode: '85382',
        quantity: 350,
        price: 37970,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 896 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 897,
        artnumber: '63250',
        barcode: '75400',
        quantity: 491,
        price: 41074,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 897 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 898,
        artnumber: '56007',
        barcode: '16198',
        quantity: 482,
        price: 19463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 898 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 899,
        artnumber: '93036',
        barcode: '70573',
        quantity: 387,
        price: 11025,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 899 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 900,
        artnumber: '32529',
        barcode: '92584',
        quantity: 319,
        price: 23071,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 900 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 901,
        artnumber: '53039',
        barcode: '39219',
        quantity: 470,
        price: 44659,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 901 для Москва, Техника, Сервис, Строй',
    },
    {
        id: 902,
        artnumber: '51603',
        barcode: '75738',
        quantity: 333,
        price: 46029,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 902 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 903,
        artnumber: '22835',
        barcode: '37209',
        quantity: 444,
        price: 36735,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 903 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 904,
        artnumber: '52586',
        barcode: '37009',
        quantity: 166,
        price: 44318,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 904 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 905,
        artnumber: '89884',
        barcode: '12006',
        quantity: 428,
        price: 27925,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 905 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 906,
        artnumber: '89964',
        barcode: '41452',
        quantity: 104,
        price: 33131,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 906 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 907,
        artnumber: '50890',
        barcode: '50681',
        quantity: 11,
        price: 25361,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 907 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 908,
        artnumber: '94464',
        barcode: '65389',
        quantity: 365,
        price: 18826,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 908 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 909,
        artnumber: '98323',
        barcode: '87806',
        quantity: 446,
        price: 25527,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 909 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 910,
        artnumber: '14399',
        barcode: '43963',
        quantity: 348,
        price: 35303,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 910 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 911,
        artnumber: '15400',
        barcode: '62172',
        quantity: 398,
        price: 10321,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 911 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 912,
        artnumber: '92898',
        barcode: '42269',
        quantity: 202,
        price: 43437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 912 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 913,
        artnumber: '21146',
        barcode: '57772',
        quantity: 316,
        price: 24244,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 913 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 914,
        artnumber: '75358',
        barcode: '53583',
        quantity: 351,
        price: 15422,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 914 для Москва, Техника, Сервис, Бета',
    },
    {
        id: 915,
        artnumber: '25311',
        barcode: '74021',
        quantity: 408,
        price: 48361,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 915 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 916,
        artnumber: '50699',
        barcode: '36878',
        quantity: 144,
        price: 34261,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 916 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 917,
        artnumber: '12547',
        barcode: '66674',
        quantity: 89,
        price: 48724,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 917 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 918,
        artnumber: '66240',
        barcode: '64575',
        quantity: 202,
        price: 3541,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 918 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 919,
        artnumber: '26850',
        barcode: '80888',
        quantity: 56,
        price: 36818,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 919 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 920,
        artnumber: '77312',
        barcode: '13781',
        quantity: 397,
        price: 3258,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 920 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 921,
        artnumber: '30475',
        barcode: '97871',
        quantity: 355,
        price: 31856,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 921 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 922,
        artnumber: '71906',
        barcode: '68835',
        quantity: 36,
        price: 44684,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 922 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 923,
        artnumber: '10094',
        barcode: '40418',
        quantity: 378,
        price: 45552,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 923 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 924,
        artnumber: '11099',
        barcode: '30175',
        quantity: 298,
        price: 37550,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 924 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 925,
        artnumber: '64541',
        barcode: '89133',
        quantity: 177,
        price: 11247,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 925 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 926,
        artnumber: '61031',
        barcode: '35822',
        quantity: 231,
        price: 40628,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 926 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 927,
        artnumber: '56121',
        barcode: '29927',
        quantity: 10,
        price: 33024,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 927 для Москва, Техника, Сервис, Гамма',
    },
    {
        id: 928,
        artnumber: '68168',
        barcode: '18192',
        quantity: 460,
        price: 25860,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 928 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 929,
        artnumber: '39296',
        barcode: '81952',
        quantity: 476,
        price: 6109,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 929 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 930,
        artnumber: '60846',
        barcode: '74605',
        quantity: 48,
        price: 20367,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 930 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 931,
        artnumber: '12135',
        barcode: '77607',
        quantity: 489,
        price: 48537,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 931 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 932,
        artnumber: '90520',
        barcode: '93497',
        quantity: 445,
        price: 40724,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 932 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 933,
        artnumber: '56287',
        barcode: '54965',
        quantity: 413,
        price: 30825,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 933 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 934,
        artnumber: '66327',
        barcode: '32873',
        quantity: 338,
        price: 32153,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 934 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 935,
        artnumber: '10941',
        barcode: '21005',
        quantity: 62,
        price: 15387,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 935 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 936,
        artnumber: '59132',
        barcode: '21387',
        quantity: 184,
        price: 12056,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 936 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 937,
        artnumber: '48337',
        barcode: '69742',
        quantity: 370,
        price: 17838,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 937 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 938,
        artnumber: '24254',
        barcode: '76865',
        quantity: 303,
        price: 38328,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 938 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 939,
        artnumber: '89261',
        barcode: '98388',
        quantity: 239,
        price: 20571,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 939 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 940,
        artnumber: '14306',
        barcode: '70418',
        quantity: 159,
        price: 26023,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 940 для Москва, Техника, Сервис, Трио',
    },
    {
        id: 941,
        artnumber: '17856',
        barcode: '10095',
        quantity: 500,
        price: 25493,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 941 для Москва, Техника, Запчасти, Альфа',
    },
    {
        id: 942,
        artnumber: '95221',
        barcode: '99981',
        quantity: 38,
        price: 22926,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 942 для Москва, Техника, Запчасти, Альфа',
    },
    {
        id: 943,
        artnumber: '58653',
        barcode: '91418',
        quantity: 294,
        price: 31755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 943 для Москва, Техника, Запчасти, Альфа',
    },
    {
        id: 944,
        artnumber: '40054',
        barcode: '88676',
        quantity: 328,
        price: 5088,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 944 для Москва, Техника, Запчасти, Альфа',
    },
    {
        id: 945,
        artnumber: '48953',
        barcode: '35786',
        quantity: 359,
        price: 37762,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 945 для Москва, Техника, Запчасти, Альфа',
    },
    {
        id: 946,
        artnumber: '63506',
        barcode: '92487',
        quantity: 307,
        price: 25646,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 946 для Москва, Техника, Запчасти, Строй',
    },
    {
        id: 947,
        artnumber: '43092',
        barcode: '67242',
        quantity: 408,
        price: 22218,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 947 для Москва, Техника, Запчасти, Строй',
    },
    {
        id: 948,
        artnumber: '44184',
        barcode: '76542',
        quantity: 470,
        price: 19936,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 948 для Москва, Техника, Запчасти, Строй',
    },
    {
        id: 949,
        artnumber: '20179',
        barcode: '28779',
        quantity: 293,
        price: 40938,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 949 для Москва, Техника, Запчасти, Строй',
    },
    {
        id: 950,
        artnumber: '67553',
        barcode: '49327',
        quantity: 254,
        price: 12339,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 950 для Москва, Техника, Запчасти, Строй',
    },
    {
        id: 951,
        artnumber: '13371',
        barcode: '97263',
        quantity: 485,
        price: 49831,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 951 для Москва, Техника, Запчасти, Бета',
    },
    {
        id: 952,
        artnumber: '90547',
        barcode: '50518',
        quantity: 368,
        price: 2522,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 952 для Москва, Техника, Запчасти, Бета',
    },
    {
        id: 953,
        artnumber: '28762',
        barcode: '31019',
        quantity: 417,
        price: 28790,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 953 для Москва, Техника, Запчасти, Бета',
    },
    {
        id: 954,
        artnumber: '94394',
        barcode: '21306',
        quantity: 78,
        price: 13509,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 954 для Москва, Техника, Запчасти, Бета',
    },
    {
        id: 955,
        artnumber: '19408',
        barcode: '82532',
        quantity: 68,
        price: 722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 955 для Москва, Техника, Запчасти, Бета',
    },
    {
        id: 956,
        artnumber: '55093',
        barcode: '92707',
        quantity: 113,
        price: 24610,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 956 для Москва, Техника, Запчасти, Гамма',
    },
    {
        id: 957,
        artnumber: '41847',
        barcode: '31831',
        quantity: 303,
        price: 6536,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 957 для Москва, Техника, Запчасти, Гамма',
    },
    {
        id: 958,
        artnumber: '88189',
        barcode: '87687',
        quantity: 292,
        price: 14630,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 958 для Москва, Техника, Запчасти, Гамма',
    },
    {
        id: 959,
        artnumber: '60021',
        barcode: '15110',
        quantity: 8,
        price: 32292,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 959 для Москва, Техника, Запчасти, Гамма',
    },
    {
        id: 960,
        artnumber: '52436',
        barcode: '41531',
        quantity: 361,
        price: 16670,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 960 для Москва, Техника, Запчасти, Гамма',
    },
    {
        id: 961,
        artnumber: '65284',
        barcode: '29374',
        quantity: 157,
        price: 39997,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 961 для Москва, Техника, Запчасти, Трио',
    },
    {
        id: 962,
        artnumber: '22511',
        barcode: '69021',
        quantity: 23,
        price: 17895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 962 для Москва, Техника, Запчасти, Трио',
    },
    {
        id: 963,
        artnumber: '33690',
        barcode: '57796',
        quantity: 293,
        price: 42167,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 963 для Москва, Техника, Запчасти, Трио',
    },
    {
        id: 964,
        artnumber: '50551',
        barcode: '92728',
        quantity: 487,
        price: 49506,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 964 для Москва, Техника, Запчасти, Трио',
    },
    {
        id: 965,
        artnumber: '59804',
        barcode: '95986',
        quantity: 259,
        price: 11834,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 965 для Москва, Техника, Запчасти, Трио',
    },
    {
        id: 966,
        artnumber: '45823',
        barcode: '79996',
        quantity: 443,
        price: 41577,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 966 для Москва, Техника, Доставка, Альфа',
    },
    {
        id: 967,
        artnumber: '32550',
        barcode: '73383',
        quantity: 94,
        price: 32612,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 967 для Москва, Техника, Доставка, Альфа',
    },
    {
        id: 968,
        artnumber: '19355',
        barcode: '67695',
        quantity: 36,
        price: 6058,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 968 для Москва, Техника, Доставка, Альфа',
    },
    {
        id: 969,
        artnumber: '78872',
        barcode: '99444',
        quantity: 478,
        price: 46029,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 969 для Москва, Техника, Доставка, Альфа',
    },
    {
        id: 970,
        artnumber: '36652',
        barcode: '35052',
        quantity: 477,
        price: 15643,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 970 для Москва, Техника, Доставка, Строй',
    },
    {
        id: 971,
        artnumber: '48751',
        barcode: '23620',
        quantity: 263,
        price: 34243,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 971 для Москва, Техника, Доставка, Строй',
    },
    {
        id: 972,
        artnumber: '29292',
        barcode: '97077',
        quantity: 78,
        price: 4461,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 972 для Москва, Техника, Доставка, Строй',
    },
    {
        id: 973,
        artnumber: '20296',
        barcode: '29385',
        quantity: 10,
        price: 47300,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 973 для Москва, Техника, Доставка, Строй',
    },
    {
        id: 974,
        artnumber: '78800',
        barcode: '85610',
        quantity: 8,
        price: 23906,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 974 для Москва, Техника, Доставка, Бета',
    },
    {
        id: 975,
        artnumber: '26269',
        barcode: '13083',
        quantity: 476,
        price: 13389,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 975 для Москва, Техника, Доставка, Бета',
    },
    {
        id: 976,
        artnumber: '45744',
        barcode: '13130',
        quantity: 256,
        price: 33291,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 976 для Москва, Техника, Доставка, Бета',
    },
    {
        id: 977,
        artnumber: '21151',
        barcode: '99930',
        quantity: 358,
        price: 43692,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 977 для Москва, Техника, Доставка, Бета',
    },
    {
        id: 978,
        artnumber: '19615',
        barcode: '70157',
        quantity: 183,
        price: 9488,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 978 для Москва, Техника, Доставка, Гамма',
    },
    {
        id: 979,
        artnumber: '55174',
        barcode: '18313',
        quantity: 426,
        price: 47202,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 979 для Москва, Техника, Доставка, Гамма',
    },
    {
        id: 980,
        artnumber: '11130',
        barcode: '88642',
        quantity: 65,
        price: 40877,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 980 для Москва, Техника, Доставка, Гамма',
    },
    {
        id: 981,
        artnumber: '30462',
        barcode: '91565',
        quantity: 370,
        price: 471,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 981 для Москва, Техника, Доставка, Гамма',
    },
    {
        id: 982,
        artnumber: '72091',
        barcode: '13911',
        quantity: 405,
        price: 27654,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 982 для Москва, Техника, Доставка, Трио',
    },
    {
        id: 983,
        artnumber: '14097',
        barcode: '30610',
        quantity: 374,
        price: 45433,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 983 для Москва, Техника, Доставка, Трио',
    },
    {
        id: 984,
        artnumber: '36388',
        barcode: '50490',
        quantity: 38,
        price: 15647,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 984 для Москва, Техника, Доставка, Трио',
    },
    {
        id: 985,
        artnumber: '17367',
        barcode: '64983',
        quantity: 25,
        price: 13520,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 985 для Москва, Техника, Доставка, Трио',
    },
    {
        id: 986,
        artnumber: '64529',
        barcode: '39728',
        quantity: 400,
        price: 7956,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 986 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 987,
        artnumber: '74174',
        barcode: '47120',
        quantity: 380,
        price: 22248,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 987 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 988,
        artnumber: '69666',
        barcode: '58886',
        quantity: 66,
        price: 12389,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 988 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 989,
        artnumber: '15624',
        barcode: '81246',
        quantity: 497,
        price: 25174,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 989 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 990,
        artnumber: '35514',
        barcode: '89551',
        quantity: 271,
        price: 17309,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 990 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 991,
        artnumber: '92340',
        barcode: '69181',
        quantity: 56,
        price: 6851,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 991 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 992,
        artnumber: '16510',
        barcode: '50801',
        quantity: 382,
        price: 14632,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 992 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 993,
        artnumber: '53090',
        barcode: '54342',
        quantity: 239,
        price: 2138,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 993 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 994,
        artnumber: '85097',
        barcode: '35662',
        quantity: 365,
        price: 41857,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 994 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 995,
        artnumber: '92572',
        barcode: '56466',
        quantity: 137,
        price: 2551,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 995 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 996,
        artnumber: '99711',
        barcode: '47984',
        quantity: 332,
        price: 41087,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 996 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 997,
        artnumber: '29433',
        barcode: '41524',
        quantity: 42,
        price: 26684,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 997 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 998,
        artnumber: '63757',
        barcode: '99405',
        quantity: 198,
        price: 5988,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 998 для Москва, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 999,
        artnumber: '12365',
        barcode: '83055',
        quantity: 206,
        price: 2515,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 999 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1000,
        artnumber: '79074',
        barcode: '44303',
        quantity: 252,
        price: 23673,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1000 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1001,
        artnumber: '70671',
        barcode: '81257',
        quantity: 452,
        price: 12005,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1001 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1002,
        artnumber: '24416',
        barcode: '92113',
        quantity: 108,
        price: 25661,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1002 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1003,
        artnumber: '23100',
        barcode: '79476',
        quantity: 306,
        price: 48693,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1003 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1004,
        artnumber: '69237',
        barcode: '99098',
        quantity: 336,
        price: 7100,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1004 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1005,
        artnumber: '90502',
        barcode: '37779',
        quantity: 81,
        price: 39683,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1005 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1006,
        artnumber: '40350',
        barcode: '90890',
        quantity: 411,
        price: 31739,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1006 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1007,
        artnumber: '75148',
        barcode: '31810',
        quantity: 126,
        price: 21850,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1007 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1008,
        artnumber: '69475',
        barcode: '65801',
        quantity: 301,
        price: 33756,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1008 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1009,
        artnumber: '54047',
        barcode: '39693',
        quantity: 489,
        price: 20955,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1009 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1010,
        artnumber: '89204',
        barcode: '36972',
        quantity: 209,
        price: 29061,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1010 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1011,
        artnumber: '18555',
        barcode: '71245',
        quantity: 310,
        price: 20160,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1011 для Москва, Техника, Шиномонтаж, Строй',
    },
    {
        id: 1012,
        artnumber: '31520',
        barcode: '43158',
        quantity: 403,
        price: 39924,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1012 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1013,
        artnumber: '84046',
        barcode: '62749',
        quantity: 431,
        price: 38313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1013 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1014,
        artnumber: '92687',
        barcode: '60984',
        quantity: 188,
        price: 21310,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1014 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1015,
        artnumber: '10855',
        barcode: '24070',
        quantity: 79,
        price: 37758,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1015 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1016,
        artnumber: '43442',
        barcode: '75852',
        quantity: 80,
        price: 19038,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1016 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1017,
        artnumber: '28386',
        barcode: '80752',
        quantity: 77,
        price: 5761,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1017 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1018,
        artnumber: '81414',
        barcode: '99625',
        quantity: 10,
        price: 21782,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1018 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1019,
        artnumber: '88997',
        barcode: '24279',
        quantity: 142,
        price: 22225,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1019 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1020,
        artnumber: '71589',
        barcode: '28093',
        quantity: 157,
        price: 48190,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1020 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1021,
        artnumber: '12101',
        barcode: '44192',
        quantity: 286,
        price: 12204,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1021 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1022,
        artnumber: '56931',
        barcode: '40104',
        quantity: 174,
        price: 20934,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1022 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1023,
        artnumber: '12784',
        barcode: '47794',
        quantity: 402,
        price: 8308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1023 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1024,
        artnumber: '61159',
        barcode: '86213',
        quantity: 147,
        price: 41751,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1024 для Москва, Техника, Шиномонтаж, Бета',
    },
    {
        id: 1025,
        artnumber: '49904',
        barcode: '27268',
        quantity: 378,
        price: 2271,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1025 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1026,
        artnumber: '97995',
        barcode: '96348',
        quantity: 263,
        price: 29128,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1026 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1027,
        artnumber: '18048',
        barcode: '39838',
        quantity: 485,
        price: 15034,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1027 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1028,
        artnumber: '66323',
        barcode: '71613',
        quantity: 91,
        price: 44539,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1028 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1029,
        artnumber: '97759',
        barcode: '22230',
        quantity: 479,
        price: 32127,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1029 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1030,
        artnumber: '40356',
        barcode: '31358',
        quantity: 320,
        price: 10457,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1030 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1031,
        artnumber: '69787',
        barcode: '34640',
        quantity: 274,
        price: 11402,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1031 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1032,
        artnumber: '62901',
        barcode: '96210',
        quantity: 164,
        price: 17171,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1032 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1033,
        artnumber: '44417',
        barcode: '61527',
        quantity: 102,
        price: 40682,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1033 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1034,
        artnumber: '17460',
        barcode: '74460',
        quantity: 54,
        price: 22499,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1034 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1035,
        artnumber: '96750',
        barcode: '32442',
        quantity: 496,
        price: 17527,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1035 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1036,
        artnumber: '81624',
        barcode: '88326',
        quantity: 454,
        price: 22617,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1036 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1037,
        artnumber: '78141',
        barcode: '36372',
        quantity: 265,
        price: 5569,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1037 для Москва, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 1038,
        artnumber: '85805',
        barcode: '86925',
        quantity: 174,
        price: 29743,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1038 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1039,
        artnumber: '57001',
        barcode: '21149',
        quantity: 473,
        price: 37857,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1039 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1040,
        artnumber: '72001',
        barcode: '67863',
        quantity: 97,
        price: 32835,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1040 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1041,
        artnumber: '25121',
        barcode: '42176',
        quantity: 287,
        price: 38755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1041 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1042,
        artnumber: '14596',
        barcode: '13112',
        quantity: 85,
        price: 46140,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1042 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1043,
        artnumber: '76340',
        barcode: '94500',
        quantity: 244,
        price: 11889,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1043 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1044,
        artnumber: '35098',
        barcode: '79688',
        quantity: 130,
        price: 45315,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1044 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1045,
        artnumber: '64488',
        barcode: '42625',
        quantity: 321,
        price: 36062,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1045 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1046,
        artnumber: '49611',
        barcode: '55218',
        quantity: 405,
        price: 33,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1046 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1047,
        artnumber: '71003',
        barcode: '46646',
        quantity: 451,
        price: 29728,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1047 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1048,
        artnumber: '14221',
        barcode: '22937',
        quantity: 276,
        price: 9615,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1048 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1049,
        artnumber: '83718',
        barcode: '82555',
        quantity: 466,
        price: 35046,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1049 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1050,
        artnumber: '79647',
        barcode: '40225',
        quantity: 490,
        price: 39097,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1050 для Москва, Техника, Шиномонтаж, Трио',
    },
    {
        id: 1051,
        artnumber: '89931',
        barcode: '87223',
        quantity: 277,
        price: 34722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1051 для Москва, Техника, Замена масла, Альфа',
    },
    {
        id: 1052,
        artnumber: '64623',
        barcode: '78510',
        quantity: 387,
        price: 21118,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1052 для Москва, Техника, Замена масла, Альфа',
    },
    {
        id: 1053,
        artnumber: '16135',
        barcode: '64930',
        quantity: 367,
        price: 9302,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1053 для Москва, Техника, Замена масла, Альфа',
    },
    {
        id: 1054,
        artnumber: '81902',
        barcode: '50781',
        quantity: 296,
        price: 49179,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1054 для Москва, Техника, Замена масла, Альфа',
    },
    {
        id: 1055,
        artnumber: '42869',
        barcode: '28763',
        quantity: 390,
        price: 9533,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1055 для Москва, Техника, Замена масла, Альфа',
    },
    {
        id: 1056,
        artnumber: '63886',
        barcode: '69992',
        quantity: 302,
        price: 30903,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1056 для Москва, Техника, Замена масла, Строй',
    },
    {
        id: 1057,
        artnumber: '64161',
        barcode: '52491',
        quantity: 235,
        price: 33135,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1057 для Москва, Техника, Замена масла, Строй',
    },
    {
        id: 1058,
        artnumber: '82125',
        barcode: '61648',
        quantity: 258,
        price: 32057,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1058 для Москва, Техника, Замена масла, Строй',
    },
    {
        id: 1059,
        artnumber: '83865',
        barcode: '98181',
        quantity: 423,
        price: 13593,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1059 для Москва, Техника, Замена масла, Строй',
    },
    {
        id: 1060,
        artnumber: '80908',
        barcode: '88127',
        quantity: 458,
        price: 41767,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1060 для Москва, Техника, Замена масла, Строй',
    },
    {
        id: 1061,
        artnumber: '26019',
        barcode: '48366',
        quantity: 103,
        price: 16679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1061 для Москва, Техника, Замена масла, Бета',
    },
    {
        id: 1062,
        artnumber: '68133',
        barcode: '84238',
        quantity: 279,
        price: 20434,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1062 для Москва, Техника, Замена масла, Бета',
    },
    {
        id: 1063,
        artnumber: '44708',
        barcode: '69425',
        quantity: 257,
        price: 10229,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1063 для Москва, Техника, Замена масла, Бета',
    },
    {
        id: 1064,
        artnumber: '60612',
        barcode: '78246',
        quantity: 31,
        price: 35692,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1064 для Москва, Техника, Замена масла, Бета',
    },
    {
        id: 1065,
        artnumber: '76069',
        barcode: '57311',
        quantity: 261,
        price: 4540,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1065 для Москва, Техника, Замена масла, Бета',
    },
    {
        id: 1066,
        artnumber: '17457',
        barcode: '24674',
        quantity: 117,
        price: 24701,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1066 для Москва, Техника, Замена масла, Гамма',
    },
    {
        id: 1067,
        artnumber: '91600',
        barcode: '53434',
        quantity: 169,
        price: 36434,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1067 для Москва, Техника, Замена масла, Гамма',
    },
    {
        id: 1068,
        artnumber: '73803',
        barcode: '42097',
        quantity: 442,
        price: 43157,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1068 для Москва, Техника, Замена масла, Гамма',
    },
    {
        id: 1069,
        artnumber: '20622',
        barcode: '98651',
        quantity: 127,
        price: 31134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1069 для Москва, Техника, Замена масла, Гамма',
    },
    {
        id: 1070,
        artnumber: '76385',
        barcode: '35138',
        quantity: 475,
        price: 29628,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1070 для Москва, Техника, Замена масла, Гамма',
    },
    {
        id: 1071,
        artnumber: '79495',
        barcode: '85511',
        quantity: 381,
        price: 36025,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1071 для Москва, Техника, Замена масла, Трио',
    },
    {
        id: 1072,
        artnumber: '84477',
        barcode: '10186',
        quantity: 408,
        price: 33699,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1072 для Москва, Техника, Замена масла, Трио',
    },
    {
        id: 1073,
        artnumber: '52242',
        barcode: '76764',
        quantity: 224,
        price: 28683,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1073 для Москва, Техника, Замена масла, Трио',
    },
    {
        id: 1074,
        artnumber: '77599',
        barcode: '12532',
        quantity: 425,
        price: 31808,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1074 для Москва, Техника, Замена масла, Трио',
    },
    {
        id: 1075,
        artnumber: '25444',
        barcode: '34066',
        quantity: 498,
        price: 10841,
        picture: '/images/products/no_image.svg',
        region: {
            id: 1,
            name: 'Москва',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1075 для Москва, Техника, Замена масла, Трио',
    },
    {
        id: 1076,
        artnumber: '56023',
        barcode: '38114',
        quantity: 8,
        price: 9543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1076 для Подольск, Авто, Сервис, Альфа',
    },
    {
        id: 1077,
        artnumber: '10022',
        barcode: '60927',
        quantity: 2,
        price: 34839,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1077 для Подольск, Авто, Сервис, Альфа',
    },
    {
        id: 1078,
        artnumber: '45584',
        barcode: '59645',
        quantity: 374,
        price: 59,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1078 для Подольск, Авто, Сервис, Альфа',
    },
    {
        id: 1079,
        artnumber: '24928',
        barcode: '50093',
        quantity: 394,
        price: 28487,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1079 для Подольск, Авто, Сервис, Альфа',
    },
    {
        id: 1080,
        artnumber: '84606',
        barcode: '50248',
        quantity: 125,
        price: 21798,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1080 для Подольск, Авто, Сервис, Альфа',
    },
    {
        id: 1081,
        artnumber: '70639',
        barcode: '47533',
        quantity: 408,
        price: 14496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1081 для Подольск, Авто, Сервис, Альфа',
    },
    {
        id: 1082,
        artnumber: '78722',
        barcode: '16726',
        quantity: 39,
        price: 21648,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1082 для Подольск, Авто, Сервис, Альфа',
    },
    {
        id: 1083,
        artnumber: '59821',
        barcode: '31844',
        quantity: 168,
        price: 6628,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1083 для Подольск, Авто, Сервис, Строй',
    },
    {
        id: 1084,
        artnumber: '48569',
        barcode: '89074',
        quantity: 445,
        price: 30553,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1084 для Подольск, Авто, Сервис, Строй',
    },
    {
        id: 1085,
        artnumber: '16805',
        barcode: '74905',
        quantity: 186,
        price: 48840,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1085 для Подольск, Авто, Сервис, Строй',
    },
    {
        id: 1086,
        artnumber: '61821',
        barcode: '43297',
        quantity: 286,
        price: 13593,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1086 для Подольск, Авто, Сервис, Строй',
    },
    {
        id: 1087,
        artnumber: '34931',
        barcode: '40639',
        quantity: 39,
        price: 45657,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1087 для Подольск, Авто, Сервис, Строй',
    },
    {
        id: 1088,
        artnumber: '32289',
        barcode: '20812',
        quantity: 73,
        price: 12082,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1088 для Подольск, Авто, Сервис, Строй',
    },
    {
        id: 1089,
        artnumber: '29704',
        barcode: '10507',
        quantity: 167,
        price: 37508,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1089 для Подольск, Авто, Сервис, Строй',
    },
    {
        id: 1090,
        artnumber: '79314',
        barcode: '22497',
        quantity: 147,
        price: 34184,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1090 для Подольск, Авто, Сервис, Бета',
    },
    {
        id: 1091,
        artnumber: '89927',
        barcode: '70678',
        quantity: 392,
        price: 44774,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1091 для Подольск, Авто, Сервис, Бета',
    },
    {
        id: 1092,
        artnumber: '75959',
        barcode: '28603',
        quantity: 142,
        price: 5849,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1092 для Подольск, Авто, Сервис, Бета',
    },
    {
        id: 1093,
        artnumber: '86519',
        barcode: '89532',
        quantity: 45,
        price: 34412,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1093 для Подольск, Авто, Сервис, Бета',
    },
    {
        id: 1094,
        artnumber: '16222',
        barcode: '80344',
        quantity: 421,
        price: 13111,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1094 для Подольск, Авто, Сервис, Бета',
    },
    {
        id: 1095,
        artnumber: '84764',
        barcode: '60606',
        quantity: 393,
        price: 14202,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1095 для Подольск, Авто, Сервис, Бета',
    },
    {
        id: 1096,
        artnumber: '26829',
        barcode: '71394',
        quantity: 226,
        price: 16543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1096 для Подольск, Авто, Сервис, Бета',
    },
    {
        id: 1097,
        artnumber: '35280',
        barcode: '70835',
        quantity: 191,
        price: 8537,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1097 для Подольск, Авто, Сервис, Гамма',
    },
    {
        id: 1098,
        artnumber: '57977',
        barcode: '83886',
        quantity: 15,
        price: 21308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1098 для Подольск, Авто, Сервис, Гамма',
    },
    {
        id: 1099,
        artnumber: '17736',
        barcode: '87970',
        quantity: 109,
        price: 46915,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1099 для Подольск, Авто, Сервис, Гамма',
    },
    {
        id: 1100,
        artnumber: '61251',
        barcode: '98454',
        quantity: 112,
        price: 24475,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1100 для Подольск, Авто, Сервис, Гамма',
    },
    {
        id: 1101,
        artnumber: '14332',
        barcode: '36387',
        quantity: 363,
        price: 8493,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1101 для Подольск, Авто, Сервис, Гамма',
    },
    {
        id: 1102,
        artnumber: '92617',
        barcode: '60091',
        quantity: 87,
        price: 10909,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1102 для Подольск, Авто, Сервис, Гамма',
    },
    {
        id: 1103,
        artnumber: '42411',
        barcode: '35243',
        quantity: 409,
        price: 18466,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1103 для Подольск, Авто, Сервис, Гамма',
    },
    {
        id: 1104,
        artnumber: '88483',
        barcode: '54493',
        quantity: 371,
        price: 8204,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1104 для Подольск, Авто, Сервис, Трио',
    },
    {
        id: 1105,
        artnumber: '81509',
        barcode: '87636',
        quantity: 226,
        price: 49865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1105 для Подольск, Авто, Сервис, Трио',
    },
    {
        id: 1106,
        artnumber: '57401',
        barcode: '99692',
        quantity: 15,
        price: 23496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1106 для Подольск, Авто, Сервис, Трио',
    },
    {
        id: 1107,
        artnumber: '12240',
        barcode: '64372',
        quantity: 135,
        price: 42877,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1107 для Подольск, Авто, Сервис, Трио',
    },
    {
        id: 1108,
        artnumber: '28020',
        barcode: '72172',
        quantity: 196,
        price: 26431,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1108 для Подольск, Авто, Сервис, Трио',
    },
    {
        id: 1109,
        artnumber: '53557',
        barcode: '70316',
        quantity: 474,
        price: 6471,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1109 для Подольск, Авто, Сервис, Трио',
    },
    {
        id: 1110,
        artnumber: '80029',
        barcode: '26032',
        quantity: 198,
        price: 7432,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1110 для Подольск, Авто, Сервис, Трио',
    },
    {
        id: 1111,
        artnumber: '68190',
        barcode: '39202',
        quantity: 79,
        price: 44378,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1111 для Подольск, Авто, Запчасти, Альфа',
    },
    {
        id: 1112,
        artnumber: '91178',
        barcode: '23253',
        quantity: 85,
        price: 26930,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1112 для Подольск, Авто, Запчасти, Альфа',
    },
    {
        id: 1113,
        artnumber: '11910',
        barcode: '28873',
        quantity: 55,
        price: 26261,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1113 для Подольск, Авто, Запчасти, Альфа',
    },
    {
        id: 1114,
        artnumber: '15639',
        barcode: '88904',
        quantity: 256,
        price: 37043,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1114 для Подольск, Авто, Запчасти, Альфа',
    },
    {
        id: 1115,
        artnumber: '18401',
        barcode: '35843',
        quantity: 194,
        price: 35081,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1115 для Подольск, Авто, Запчасти, Строй',
    },
    {
        id: 1116,
        artnumber: '14778',
        barcode: '57232',
        quantity: 57,
        price: 33102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1116 для Подольск, Авто, Запчасти, Строй',
    },
    {
        id: 1117,
        artnumber: '62967',
        barcode: '82228',
        quantity: 73,
        price: 7001,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1117 для Подольск, Авто, Запчасти, Строй',
    },
    {
        id: 1118,
        artnumber: '56181',
        barcode: '82921',
        quantity: 406,
        price: 15213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1118 для Подольск, Авто, Запчасти, Строй',
    },
    {
        id: 1119,
        artnumber: '39084',
        barcode: '35561',
        quantity: 90,
        price: 26441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1119 для Подольск, Авто, Запчасти, Бета',
    },
    {
        id: 1120,
        artnumber: '19264',
        barcode: '78858',
        quantity: 392,
        price: 36189,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1120 для Подольск, Авто, Запчасти, Бета',
    },
    {
        id: 1121,
        artnumber: '46909',
        barcode: '15821',
        quantity: 159,
        price: 13398,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1121 для Подольск, Авто, Запчасти, Бета',
    },
    {
        id: 1122,
        artnumber: '28657',
        barcode: '69993',
        quantity: 154,
        price: 31731,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1122 для Подольск, Авто, Запчасти, Бета',
    },
    {
        id: 1123,
        artnumber: '82128',
        barcode: '52317',
        quantity: 122,
        price: 2055,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1123 для Подольск, Авто, Запчасти, Гамма',
    },
    {
        id: 1124,
        artnumber: '10930',
        barcode: '80637',
        quantity: 298,
        price: 34165,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1124 для Подольск, Авто, Запчасти, Гамма',
    },
    {
        id: 1125,
        artnumber: '20147',
        barcode: '67773',
        quantity: 190,
        price: 45101,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1125 для Подольск, Авто, Запчасти, Гамма',
    },
    {
        id: 1126,
        artnumber: '56881',
        barcode: '57165',
        quantity: 44,
        price: 48876,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1126 для Подольск, Авто, Запчасти, Гамма',
    },
    {
        id: 1127,
        artnumber: '62659',
        barcode: '29322',
        quantity: 348,
        price: 41483,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1127 для Подольск, Авто, Запчасти, Трио',
    },
    {
        id: 1128,
        artnumber: '95669',
        barcode: '18738',
        quantity: 110,
        price: 2270,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1128 для Подольск, Авто, Запчасти, Трио',
    },
    {
        id: 1129,
        artnumber: '67436',
        barcode: '64012',
        quantity: 475,
        price: 13793,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1129 для Подольск, Авто, Запчасти, Трио',
    },
    {
        id: 1130,
        artnumber: '16558',
        barcode: '43822',
        quantity: 417,
        price: 2871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1130 для Подольск, Авто, Запчасти, Трио',
    },
    {
        id: 1131,
        artnumber: '84897',
        barcode: '94966',
        quantity: 224,
        price: 46951,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1131 для Подольск, Авто, Доставка, Альфа',
    },
    {
        id: 1132,
        artnumber: '16366',
        barcode: '62139',
        quantity: 362,
        price: 34642,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1132 для Подольск, Авто, Доставка, Альфа',
    },
    {
        id: 1133,
        artnumber: '80722',
        barcode: '40543',
        quantity: 36,
        price: 37327,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1133 для Подольск, Авто, Доставка, Альфа',
    },
    {
        id: 1134,
        artnumber: '99670',
        barcode: '74715',
        quantity: 435,
        price: 44724,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1134 для Подольск, Авто, Доставка, Строй',
    },
    {
        id: 1135,
        artnumber: '33385',
        barcode: '51639',
        quantity: 226,
        price: 25537,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1135 для Подольск, Авто, Доставка, Строй',
    },
    {
        id: 1136,
        artnumber: '38546',
        barcode: '51864',
        quantity: 373,
        price: 30137,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1136 для Подольск, Авто, Доставка, Строй',
    },
    {
        id: 1137,
        artnumber: '35342',
        barcode: '82931',
        quantity: 48,
        price: 27463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1137 для Подольск, Авто, Доставка, Бета',
    },
    {
        id: 1138,
        artnumber: '90581',
        barcode: '56550',
        quantity: 51,
        price: 12966,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1138 для Подольск, Авто, Доставка, Бета',
    },
    {
        id: 1139,
        artnumber: '62859',
        barcode: '35485',
        quantity: 362,
        price: 34253,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1139 для Подольск, Авто, Доставка, Бета',
    },
    {
        id: 1140,
        artnumber: '23988',
        barcode: '66542',
        quantity: 451,
        price: 48785,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1140 для Подольск, Авто, Доставка, Гамма',
    },
    {
        id: 1141,
        artnumber: '53464',
        barcode: '74381',
        quantity: 129,
        price: 28354,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1141 для Подольск, Авто, Доставка, Гамма',
    },
    {
        id: 1142,
        artnumber: '80009',
        barcode: '82840',
        quantity: 463,
        price: 48918,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1142 для Подольск, Авто, Доставка, Гамма',
    },
    {
        id: 1143,
        artnumber: '13075',
        barcode: '25763',
        quantity: 51,
        price: 42881,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1143 для Подольск, Авто, Доставка, Трио',
    },
    {
        id: 1144,
        artnumber: '24591',
        barcode: '28203',
        quantity: 3,
        price: 36143,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1144 для Подольск, Авто, Доставка, Трио',
    },
    {
        id: 1145,
        artnumber: '17908',
        barcode: '99580',
        quantity: 346,
        price: 32148,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1145 для Подольск, Авто, Доставка, Трио',
    },
    {
        id: 1146,
        artnumber: '38250',
        barcode: '82511',
        quantity: 241,
        price: 28881,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1146 для Подольск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 1147,
        artnumber: '15715',
        barcode: '48652',
        quantity: 169,
        price: 24067,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1147 для Подольск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 1148,
        artnumber: '90312',
        barcode: '18883',
        quantity: 262,
        price: 47602,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1148 для Подольск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 1149,
        artnumber: '35559',
        barcode: '40047',
        quantity: 157,
        price: 14744,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1149 для Подольск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 1150,
        artnumber: '81542',
        barcode: '10119',
        quantity: 465,
        price: 41837,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1150 для Подольск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 1151,
        artnumber: '45738',
        barcode: '90836',
        quantity: 4,
        price: 18643,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1151 для Подольск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 1152,
        artnumber: '39222',
        barcode: '48703',
        quantity: 312,
        price: 23023,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1152 для Подольск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 1153,
        artnumber: '75559',
        barcode: '56785',
        quantity: 97,
        price: 43381,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1153 для Подольск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 1154,
        artnumber: '83090',
        barcode: '27019',
        quantity: 481,
        price: 43675,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1154 для Подольск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 1155,
        artnumber: '24450',
        barcode: '56541',
        quantity: 335,
        price: 48250,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1155 для Подольск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 1156,
        artnumber: '73975',
        barcode: '12510',
        quantity: 349,
        price: 11961,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1156 для Подольск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 1157,
        artnumber: '46007',
        barcode: '73442',
        quantity: 236,
        price: 19103,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1157 для Подольск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 1158,
        artnumber: '87808',
        barcode: '58122',
        quantity: 40,
        price: 18820,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1158 для Подольск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 1159,
        artnumber: '84366',
        barcode: '46135',
        quantity: 282,
        price: 38077,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1159 для Подольск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 1160,
        artnumber: '27303',
        barcode: '59610',
        quantity: 164,
        price: 2992,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1160 для Подольск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 1161,
        artnumber: '33338',
        barcode: '18658',
        quantity: 264,
        price: 25221,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1161 для Подольск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 1162,
        artnumber: '85858',
        barcode: '19270',
        quantity: 481,
        price: 16911,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1162 для Подольск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 1163,
        artnumber: '24235',
        barcode: '11989',
        quantity: 223,
        price: 10995,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1163 для Подольск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 1164,
        artnumber: '62100',
        barcode: '73330',
        quantity: 205,
        price: 41894,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1164 для Подольск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 1165,
        artnumber: '66337',
        barcode: '26639',
        quantity: 405,
        price: 42348,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1165 для Подольск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 1166,
        artnumber: '29088',
        barcode: '63048',
        quantity: 191,
        price: 49448,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1166 для Подольск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 1167,
        artnumber: '75455',
        barcode: '58476',
        quantity: 463,
        price: 1738,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1167 для Подольск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 1168,
        artnumber: '31883',
        barcode: '33085',
        quantity: 382,
        price: 3713,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1168 для Подольск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 1169,
        artnumber: '76530',
        barcode: '47960',
        quantity: 5,
        price: 36895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1169 для Подольск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 1170,
        artnumber: '34242',
        barcode: '32427',
        quantity: 305,
        price: 8072,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1170 для Подольск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 1171,
        artnumber: '26196',
        barcode: '33274',
        quantity: 169,
        price: 17269,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1171 для Подольск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 1172,
        artnumber: '15525',
        barcode: '97999',
        quantity: 166,
        price: 25108,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1172 для Подольск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 1173,
        artnumber: '83053',
        barcode: '95814',
        quantity: 479,
        price: 2149,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1173 для Подольск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 1174,
        artnumber: '13207',
        barcode: '12897',
        quantity: 5,
        price: 29332,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1174 для Подольск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 1175,
        artnumber: '52275',
        barcode: '89360',
        quantity: 424,
        price: 34514,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1175 для Подольск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 1176,
        artnumber: '31965',
        barcode: '39166',
        quantity: 131,
        price: 44086,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1176 для Подольск, Авто, Замена масла, Альфа',
    },
    {
        id: 1177,
        artnumber: '33421',
        barcode: '98008',
        quantity: 337,
        price: 43042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1177 для Подольск, Авто, Замена масла, Альфа',
    },
    {
        id: 1178,
        artnumber: '95730',
        barcode: '13025',
        quantity: 431,
        price: 42837,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1178 для Подольск, Авто, Замена масла, Альфа',
    },
    {
        id: 1179,
        artnumber: '69194',
        barcode: '16594',
        quantity: 372,
        price: 21573,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1179 для Подольск, Авто, Замена масла, Альфа',
    },
    {
        id: 1180,
        artnumber: '49431',
        barcode: '16032',
        quantity: 226,
        price: 38885,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1180 для Подольск, Авто, Замена масла, Альфа',
    },
    {
        id: 1181,
        artnumber: '58722',
        barcode: '70942',
        quantity: 98,
        price: 38824,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1181 для Подольск, Авто, Замена масла, Альфа',
    },
    {
        id: 1182,
        artnumber: '18402',
        barcode: '47428',
        quantity: 454,
        price: 21218,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1182 для Подольск, Авто, Замена масла, Альфа',
    },
    {
        id: 1183,
        artnumber: '57042',
        barcode: '62931',
        quantity: 60,
        price: 26341,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1183 для Подольск, Авто, Замена масла, Альфа',
    },
    {
        id: 1184,
        artnumber: '83083',
        barcode: '87286',
        quantity: 33,
        price: 8065,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1184 для Подольск, Авто, Замена масла, Альфа',
    },
    {
        id: 1185,
        artnumber: '43639',
        barcode: '56781',
        quantity: 405,
        price: 20727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1185 для Подольск, Авто, Замена масла, Альфа',
    },
    {
        id: 1186,
        artnumber: '13231',
        barcode: '68616',
        quantity: 63,
        price: 42256,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1186 для Подольск, Авто, Замена масла, Строй',
    },
    {
        id: 1187,
        artnumber: '36417',
        barcode: '44920',
        quantity: 493,
        price: 39200,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1187 для Подольск, Авто, Замена масла, Строй',
    },
    {
        id: 1188,
        artnumber: '97876',
        barcode: '57974',
        quantity: 242,
        price: 18194,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1188 для Подольск, Авто, Замена масла, Строй',
    },
    {
        id: 1189,
        artnumber: '36476',
        barcode: '40247',
        quantity: 285,
        price: 43513,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1189 для Подольск, Авто, Замена масла, Строй',
    },
    {
        id: 1190,
        artnumber: '39490',
        barcode: '77769',
        quantity: 100,
        price: 49987,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1190 для Подольск, Авто, Замена масла, Строй',
    },
    {
        id: 1191,
        artnumber: '86959',
        barcode: '65717',
        quantity: 37,
        price: 24449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1191 для Подольск, Авто, Замена масла, Строй',
    },
    {
        id: 1192,
        artnumber: '44587',
        barcode: '25010',
        quantity: 273,
        price: 8177,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1192 для Подольск, Авто, Замена масла, Строй',
    },
    {
        id: 1193,
        artnumber: '23937',
        barcode: '84025',
        quantity: 128,
        price: 5582,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1193 для Подольск, Авто, Замена масла, Строй',
    },
    {
        id: 1194,
        artnumber: '94550',
        barcode: '21611',
        quantity: 314,
        price: 6640,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1194 для Подольск, Авто, Замена масла, Строй',
    },
    {
        id: 1195,
        artnumber: '54639',
        barcode: '68537',
        quantity: 415,
        price: 14423,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1195 для Подольск, Авто, Замена масла, Строй',
    },
    {
        id: 1196,
        artnumber: '42511',
        barcode: '26679',
        quantity: 167,
        price: 2262,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1196 для Подольск, Авто, Замена масла, Бета',
    },
    {
        id: 1197,
        artnumber: '54796',
        barcode: '27922',
        quantity: 343,
        price: 34014,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1197 для Подольск, Авто, Замена масла, Бета',
    },
    {
        id: 1198,
        artnumber: '58455',
        barcode: '40494',
        quantity: 29,
        price: 29990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1198 для Подольск, Авто, Замена масла, Бета',
    },
    {
        id: 1199,
        artnumber: '17168',
        barcode: '18500',
        quantity: 484,
        price: 37802,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1199 для Подольск, Авто, Замена масла, Бета',
    },
    {
        id: 1200,
        artnumber: '94220',
        barcode: '13986',
        quantity: 350,
        price: 34290,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1200 для Подольск, Авто, Замена масла, Бета',
    },
    {
        id: 1201,
        artnumber: '92565',
        barcode: '80579',
        quantity: 212,
        price: 28363,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1201 для Подольск, Авто, Замена масла, Бета',
    },
    {
        id: 1202,
        artnumber: '97376',
        barcode: '10374',
        quantity: 380,
        price: 20990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1202 для Подольск, Авто, Замена масла, Бета',
    },
    {
        id: 1203,
        artnumber: '17832',
        barcode: '62380',
        quantity: 17,
        price: 39200,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1203 для Подольск, Авто, Замена масла, Бета',
    },
    {
        id: 1204,
        artnumber: '19948',
        barcode: '55374',
        quantity: 421,
        price: 6971,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1204 для Подольск, Авто, Замена масла, Бета',
    },
    {
        id: 1205,
        artnumber: '60966',
        barcode: '44580',
        quantity: 16,
        price: 47974,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1205 для Подольск, Авто, Замена масла, Бета',
    },
    {
        id: 1206,
        artnumber: '14900',
        barcode: '23255',
        quantity: 307,
        price: 4226,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1206 для Подольск, Авто, Замена масла, Гамма',
    },
    {
        id: 1207,
        artnumber: '38946',
        barcode: '65815',
        quantity: 417,
        price: 6619,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1207 для Подольск, Авто, Замена масла, Гамма',
    },
    {
        id: 1208,
        artnumber: '49973',
        barcode: '14851',
        quantity: 483,
        price: 45983,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1208 для Подольск, Авто, Замена масла, Гамма',
    },
    {
        id: 1209,
        artnumber: '57431',
        barcode: '68389',
        quantity: 193,
        price: 32387,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1209 для Подольск, Авто, Замена масла, Гамма',
    },
    {
        id: 1210,
        artnumber: '70466',
        barcode: '86979',
        quantity: 35,
        price: 28322,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1210 для Подольск, Авто, Замена масла, Гамма',
    },
    {
        id: 1211,
        artnumber: '73210',
        barcode: '19548',
        quantity: 426,
        price: 48597,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1211 для Подольск, Авто, Замена масла, Гамма',
    },
    {
        id: 1212,
        artnumber: '57938',
        barcode: '87289',
        quantity: 433,
        price: 25078,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1212 для Подольск, Авто, Замена масла, Гамма',
    },
    {
        id: 1213,
        artnumber: '14640',
        barcode: '57686',
        quantity: 396,
        price: 26718,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1213 для Подольск, Авто, Замена масла, Гамма',
    },
    {
        id: 1214,
        artnumber: '38027',
        barcode: '85616',
        quantity: 405,
        price: 34871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1214 для Подольск, Авто, Замена масла, Гамма',
    },
    {
        id: 1215,
        artnumber: '38315',
        barcode: '81797',
        quantity: 33,
        price: 35989,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1215 для Подольск, Авто, Замена масла, Гамма',
    },
    {
        id: 1216,
        artnumber: '92395',
        barcode: '16497',
        quantity: 405,
        price: 37989,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1216 для Подольск, Авто, Замена масла, Трио',
    },
    {
        id: 1217,
        artnumber: '67168',
        barcode: '89759',
        quantity: 135,
        price: 8699,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1217 для Подольск, Авто, Замена масла, Трио',
    },
    {
        id: 1218,
        artnumber: '91635',
        barcode: '36859',
        quantity: 343,
        price: 7655,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1218 для Подольск, Авто, Замена масла, Трио',
    },
    {
        id: 1219,
        artnumber: '79179',
        barcode: '68584',
        quantity: 393,
        price: 5785,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1219 для Подольск, Авто, Замена масла, Трио',
    },
    {
        id: 1220,
        artnumber: '12428',
        barcode: '54080',
        quantity: 118,
        price: 33801,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1220 для Подольск, Авто, Замена масла, Трио',
    },
    {
        id: 1221,
        artnumber: '56198',
        barcode: '93366',
        quantity: 208,
        price: 39746,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1221 для Подольск, Авто, Замена масла, Трио',
    },
    {
        id: 1222,
        artnumber: '62099',
        barcode: '24803',
        quantity: 157,
        price: 30901,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1222 для Подольск, Авто, Замена масла, Трио',
    },
    {
        id: 1223,
        artnumber: '84224',
        barcode: '87489',
        quantity: 183,
        price: 34034,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1223 для Подольск, Авто, Замена масла, Трио',
    },
    {
        id: 1224,
        artnumber: '10444',
        barcode: '51311',
        quantity: 233,
        price: 18144,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1224 для Подольск, Авто, Замена масла, Трио',
    },
    {
        id: 1225,
        artnumber: '21184',
        barcode: '95731',
        quantity: 303,
        price: 22238,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1225 для Подольск, Авто, Замена масла, Трио',
    },
    {
        id: 1226,
        artnumber: '46912',
        barcode: '41842',
        quantity: 251,
        price: 40688,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1226 для Подольск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 1227,
        artnumber: '12971',
        barcode: '83704',
        quantity: 472,
        price: 18346,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1227 для Подольск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 1228,
        artnumber: '31407',
        barcode: '54872',
        quantity: 111,
        price: 10578,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1228 для Подольск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 1229,
        artnumber: '91462',
        barcode: '45091',
        quantity: 386,
        price: 32605,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1229 для Подольск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 1230,
        artnumber: '56816',
        barcode: '81196',
        quantity: 35,
        price: 13338,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1230 для Подольск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 1231,
        artnumber: '75206',
        barcode: '82144',
        quantity: 357,
        price: 38602,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1231 для Подольск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 1232,
        artnumber: '74338',
        barcode: '53046',
        quantity: 249,
        price: 16485,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1232 для Подольск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 1233,
        artnumber: '13574',
        barcode: '51383',
        quantity: 23,
        price: 2433,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1233 для Подольск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 1234,
        artnumber: '54636',
        barcode: '97937',
        quantity: 143,
        price: 47801,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1234 для Подольск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 1235,
        artnumber: '83103',
        barcode: '40864',
        quantity: 77,
        price: 40768,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1235 для Подольск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 1236,
        artnumber: '53932',
        barcode: '76667',
        quantity: 264,
        price: 6308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1236 для Подольск, Недвижимость, Сервис, Строй',
    },
    {
        id: 1237,
        artnumber: '94148',
        barcode: '93748',
        quantity: 304,
        price: 18156,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1237 для Подольск, Недвижимость, Сервис, Строй',
    },
    {
        id: 1238,
        artnumber: '94118',
        barcode: '30630',
        quantity: 390,
        price: 6802,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1238 для Подольск, Недвижимость, Сервис, Строй',
    },
    {
        id: 1239,
        artnumber: '33099',
        barcode: '92081',
        quantity: 451,
        price: 13249,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1239 для Подольск, Недвижимость, Сервис, Строй',
    },
    {
        id: 1240,
        artnumber: '34161',
        barcode: '50016',
        quantity: 405,
        price: 31781,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1240 для Подольск, Недвижимость, Сервис, Строй',
    },
    {
        id: 1241,
        artnumber: '96457',
        barcode: '22388',
        quantity: 479,
        price: 5449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1241 для Подольск, Недвижимость, Сервис, Строй',
    },
    {
        id: 1242,
        artnumber: '74851',
        barcode: '95462',
        quantity: 61,
        price: 23531,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1242 для Подольск, Недвижимость, Сервис, Строй',
    },
    {
        id: 1243,
        artnumber: '75931',
        barcode: '66951',
        quantity: 266,
        price: 16734,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1243 для Подольск, Недвижимость, Сервис, Строй',
    },
    {
        id: 1244,
        artnumber: '98248',
        barcode: '50712',
        quantity: 470,
        price: 16465,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1244 для Подольск, Недвижимость, Сервис, Строй',
    },
    {
        id: 1245,
        artnumber: '84681',
        barcode: '88161',
        quantity: 248,
        price: 42973,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1245 для Подольск, Недвижимость, Сервис, Строй',
    },
    {
        id: 1246,
        artnumber: '15264',
        barcode: '95933',
        quantity: 381,
        price: 42006,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1246 для Подольск, Недвижимость, Сервис, Бета',
    },
    {
        id: 1247,
        artnumber: '31825',
        barcode: '86851',
        quantity: 44,
        price: 37333,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1247 для Подольск, Недвижимость, Сервис, Бета',
    },
    {
        id: 1248,
        artnumber: '72436',
        barcode: '27526',
        quantity: 369,
        price: 20113,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1248 для Подольск, Недвижимость, Сервис, Бета',
    },
    {
        id: 1249,
        artnumber: '92823',
        barcode: '39673',
        quantity: 216,
        price: 30078,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1249 для Подольск, Недвижимость, Сервис, Бета',
    },
    {
        id: 1250,
        artnumber: '61624',
        barcode: '14150',
        quantity: 226,
        price: 16092,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1250 для Подольск, Недвижимость, Сервис, Бета',
    },
    {
        id: 1251,
        artnumber: '26504',
        barcode: '18192',
        quantity: 289,
        price: 47166,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1251 для Подольск, Недвижимость, Сервис, Бета',
    },
    {
        id: 1252,
        artnumber: '57359',
        barcode: '23905',
        quantity: 386,
        price: 29977,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1252 для Подольск, Недвижимость, Сервис, Бета',
    },
    {
        id: 1253,
        artnumber: '57115',
        barcode: '34060',
        quantity: 344,
        price: 49190,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1253 для Подольск, Недвижимость, Сервис, Бета',
    },
    {
        id: 1254,
        artnumber: '78951',
        barcode: '65947',
        quantity: 252,
        price: 29742,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1254 для Подольск, Недвижимость, Сервис, Бета',
    },
    {
        id: 1255,
        artnumber: '52296',
        barcode: '59137',
        quantity: 295,
        price: 6077,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1255 для Подольск, Недвижимость, Сервис, Бета',
    },
    {
        id: 1256,
        artnumber: '56748',
        barcode: '97241',
        quantity: 262,
        price: 18797,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1256 для Подольск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 1257,
        artnumber: '51534',
        barcode: '32946',
        quantity: 247,
        price: 7926,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1257 для Подольск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 1258,
        artnumber: '75983',
        barcode: '20781',
        quantity: 303,
        price: 41448,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1258 для Подольск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 1259,
        artnumber: '76431',
        barcode: '13273',
        quantity: 25,
        price: 21055,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1259 для Подольск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 1260,
        artnumber: '46227',
        barcode: '97519',
        quantity: 231,
        price: 14949,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1260 для Подольск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 1261,
        artnumber: '31604',
        barcode: '41417',
        quantity: 296,
        price: 30239,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1261 для Подольск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 1262,
        artnumber: '80900',
        barcode: '66656',
        quantity: 477,
        price: 27357,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1262 для Подольск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 1263,
        artnumber: '82947',
        barcode: '76451',
        quantity: 213,
        price: 22989,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1263 для Подольск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 1264,
        artnumber: '59473',
        barcode: '11340',
        quantity: 324,
        price: 11943,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1264 для Подольск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 1265,
        artnumber: '88530',
        barcode: '38687',
        quantity: 226,
        price: 33065,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1265 для Подольск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 1266,
        artnumber: '68743',
        barcode: '49632',
        quantity: 167,
        price: 9976,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1266 для Подольск, Недвижимость, Сервис, Трио',
    },
    {
        id: 1267,
        artnumber: '63950',
        barcode: '79884',
        quantity: 322,
        price: 49555,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1267 для Подольск, Недвижимость, Сервис, Трио',
    },
    {
        id: 1268,
        artnumber: '27335',
        barcode: '25256',
        quantity: 43,
        price: 39107,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1268 для Подольск, Недвижимость, Сервис, Трио',
    },
    {
        id: 1269,
        artnumber: '20861',
        barcode: '13209',
        quantity: 278,
        price: 24532,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1269 для Подольск, Недвижимость, Сервис, Трио',
    },
    {
        id: 1270,
        artnumber: '13758',
        barcode: '52111',
        quantity: 250,
        price: 45326,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1270 для Подольск, Недвижимость, Сервис, Трио',
    },
    {
        id: 1271,
        artnumber: '25462',
        barcode: '73691',
        quantity: 143,
        price: 29806,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1271 для Подольск, Недвижимость, Сервис, Трио',
    },
    {
        id: 1272,
        artnumber: '85244',
        barcode: '19879',
        quantity: 487,
        price: 12649,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1272 для Подольск, Недвижимость, Сервис, Трио',
    },
    {
        id: 1273,
        artnumber: '50732',
        barcode: '12689',
        quantity: 144,
        price: 28838,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1273 для Подольск, Недвижимость, Сервис, Трио',
    },
    {
        id: 1274,
        artnumber: '46167',
        barcode: '48384',
        quantity: 360,
        price: 23522,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1274 для Подольск, Недвижимость, Сервис, Трио',
    },
    {
        id: 1275,
        artnumber: '38912',
        barcode: '64571',
        quantity: 157,
        price: 3611,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1275 для Подольск, Недвижимость, Сервис, Трио',
    },
    {
        id: 1276,
        artnumber: '22850',
        barcode: '99234',
        quantity: 288,
        price: 36946,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1276 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1277,
        artnumber: '14611',
        barcode: '40280',
        quantity: 417,
        price: 43326,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1277 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1278,
        artnumber: '17903',
        barcode: '97364',
        quantity: 450,
        price: 37624,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1278 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1279,
        artnumber: '75199',
        barcode: '80148',
        quantity: 145,
        price: 26441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1279 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1280,
        artnumber: '86442',
        barcode: '20240',
        quantity: 439,
        price: 24819,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1280 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1281,
        artnumber: '23692',
        barcode: '98337',
        quantity: 123,
        price: 21819,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1281 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1282,
        artnumber: '32756',
        barcode: '83733',
        quantity: 363,
        price: 49514,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1282 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1283,
        artnumber: '58250',
        barcode: '97342',
        quantity: 118,
        price: 34253,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1283 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1284,
        artnumber: '82433',
        barcode: '15976',
        quantity: 228,
        price: 9920,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1284 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1285,
        artnumber: '11125',
        barcode: '68714',
        quantity: 239,
        price: 4845,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1285 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1286,
        artnumber: '77842',
        barcode: '57080',
        quantity: 469,
        price: 48389,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1286 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1287,
        artnumber: '96740',
        barcode: '53080',
        quantity: 160,
        price: 2125,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1287 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1288,
        artnumber: '28258',
        barcode: '26700',
        quantity: 418,
        price: 35526,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1288 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1289,
        artnumber: '26288',
        barcode: '95640',
        quantity: 287,
        price: 2089,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1289 для Подольск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 1290,
        artnumber: '78277',
        barcode: '64135',
        quantity: 182,
        price: 38577,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1290 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1291,
        artnumber: '27461',
        barcode: '85577',
        quantity: 368,
        price: 41957,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1291 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1292,
        artnumber: '60941',
        barcode: '34353',
        quantity: 456,
        price: 30057,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1292 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1293,
        artnumber: '11039',
        barcode: '40380',
        quantity: 331,
        price: 9513,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1293 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1294,
        artnumber: '19708',
        barcode: '33866',
        quantity: 206,
        price: 45510,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1294 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1295,
        artnumber: '76995',
        barcode: '65821',
        quantity: 180,
        price: 48886,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1295 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1296,
        artnumber: '87894',
        barcode: '13314',
        quantity: 88,
        price: 23546,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1296 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1297,
        artnumber: '75187',
        barcode: '34418',
        quantity: 314,
        price: 36687,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1297 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1298,
        artnumber: '78945',
        barcode: '59200',
        quantity: 221,
        price: 33623,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1298 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1299,
        artnumber: '45557',
        barcode: '97124',
        quantity: 133,
        price: 33060,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1299 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1300,
        artnumber: '24294',
        barcode: '20110',
        quantity: 127,
        price: 31891,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1300 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1301,
        artnumber: '72874',
        barcode: '42082',
        quantity: 341,
        price: 22594,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1301 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1302,
        artnumber: '55346',
        barcode: '65623',
        quantity: 436,
        price: 26510,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1302 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1303,
        artnumber: '96033',
        barcode: '10491',
        quantity: 489,
        price: 36145,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1303 для Подольск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 1304,
        artnumber: '42545',
        barcode: '35902',
        quantity: 45,
        price: 18535,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1304 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1305,
        artnumber: '95474',
        barcode: '42755',
        quantity: 442,
        price: 55,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1305 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1306,
        artnumber: '86365',
        barcode: '81127',
        quantity: 362,
        price: 2871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1306 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1307,
        artnumber: '70988',
        barcode: '56860',
        quantity: 230,
        price: 38626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1307 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1308,
        artnumber: '45818',
        barcode: '25441',
        quantity: 425,
        price: 42516,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1308 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1309,
        artnumber: '52828',
        barcode: '70451',
        quantity: 32,
        price: 31478,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1309 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1310,
        artnumber: '33331',
        barcode: '87093',
        quantity: 282,
        price: 1553,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1310 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1311,
        artnumber: '55496',
        barcode: '33621',
        quantity: 224,
        price: 1940,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1311 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1312,
        artnumber: '77615',
        barcode: '11792',
        quantity: 163,
        price: 46705,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1312 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1313,
        artnumber: '35431',
        barcode: '97712',
        quantity: 48,
        price: 12550,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1313 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1314,
        artnumber: '62059',
        barcode: '79749',
        quantity: 427,
        price: 41148,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1314 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1315,
        artnumber: '66434',
        barcode: '40859',
        quantity: 407,
        price: 21159,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1315 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1316,
        artnumber: '79420',
        barcode: '46475',
        quantity: 246,
        price: 4200,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1316 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1317,
        artnumber: '54015',
        barcode: '64920',
        quantity: 154,
        price: 32057,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1317 для Подольск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 1318,
        artnumber: '43179',
        barcode: '46496',
        quantity: 367,
        price: 39213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1318 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1319,
        artnumber: '58633',
        barcode: '21407',
        quantity: 25,
        price: 31151,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1319 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1320,
        artnumber: '85084',
        barcode: '99214',
        quantity: 447,
        price: 36959,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1320 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1321,
        artnumber: '78627',
        barcode: '57042',
        quantity: 365,
        price: 32933,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1321 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1322,
        artnumber: '55031',
        barcode: '25731',
        quantity: 174,
        price: 43671,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1322 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1323,
        artnumber: '58716',
        barcode: '59684',
        quantity: 290,
        price: 49926,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1323 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1324,
        artnumber: '68541',
        barcode: '66112',
        quantity: 292,
        price: 5221,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1324 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1325,
        artnumber: '76723',
        barcode: '69458',
        quantity: 200,
        price: 37618,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1325 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1326,
        artnumber: '44750',
        barcode: '95048',
        quantity: 180,
        price: 38426,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1326 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1327,
        artnumber: '47833',
        barcode: '30234',
        quantity: 46,
        price: 27969,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1327 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1328,
        artnumber: '85254',
        barcode: '53016',
        quantity: 405,
        price: 12428,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1328 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1329,
        artnumber: '72404',
        barcode: '73841',
        quantity: 98,
        price: 6302,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1329 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1330,
        artnumber: '71112',
        barcode: '90631',
        quantity: 123,
        price: 26457,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1330 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1331,
        artnumber: '11027',
        barcode: '27733',
        quantity: 121,
        price: 39807,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1331 для Подольск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 1332,
        artnumber: '82547',
        barcode: '30708',
        quantity: 304,
        price: 23788,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1332 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1333,
        artnumber: '50561',
        barcode: '82681',
        quantity: 36,
        price: 23355,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1333 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1334,
        artnumber: '19955',
        barcode: '54319',
        quantity: 465,
        price: 2790,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1334 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1335,
        artnumber: '37712',
        barcode: '50714',
        quantity: 59,
        price: 35668,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1335 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1336,
        artnumber: '41425',
        barcode: '94783',
        quantity: 393,
        price: 41551,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1336 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1337,
        artnumber: '78577',
        barcode: '97411',
        quantity: 137,
        price: 6974,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1337 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1338,
        artnumber: '58681',
        barcode: '36051',
        quantity: 314,
        price: 30865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1338 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1339,
        artnumber: '14705',
        barcode: '88531',
        quantity: 260,
        price: 15717,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1339 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1340,
        artnumber: '52041',
        barcode: '81307',
        quantity: 44,
        price: 36652,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1340 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1341,
        artnumber: '50034',
        barcode: '55601',
        quantity: 437,
        price: 19518,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1341 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1342,
        artnumber: '16323',
        barcode: '69280',
        quantity: 478,
        price: 40112,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1342 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1343,
        artnumber: '91354',
        barcode: '96686',
        quantity: 170,
        price: 47788,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1343 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1344,
        artnumber: '83925',
        barcode: '13081',
        quantity: 141,
        price: 9911,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1344 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1345,
        artnumber: '53631',
        barcode: '33050',
        quantity: 81,
        price: 34324,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1345 для Подольск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 1346,
        artnumber: '73853',
        barcode: '54384',
        quantity: 334,
        price: 23146,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1346 для Подольск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 1347,
        artnumber: '50282',
        barcode: '20881',
        quantity: 375,
        price: 38146,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1347 для Подольск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 1348,
        artnumber: '49198',
        barcode: '62851',
        quantity: 375,
        price: 23384,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1348 для Подольск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 1349,
        artnumber: '76270',
        barcode: '92919',
        quantity: 118,
        price: 43949,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1349 для Подольск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 1350,
        artnumber: '71734',
        barcode: '37166',
        quantity: 480,
        price: 29559,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1350 для Подольск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 1351,
        artnumber: '34495',
        barcode: '69527',
        quantity: 400,
        price: 48044,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1351 для Подольск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 1352,
        artnumber: '96441',
        barcode: '85747',
        quantity: 239,
        price: 20282,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1352 для Подольск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 1353,
        artnumber: '84193',
        barcode: '89142',
        quantity: 320,
        price: 280,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1353 для Подольск, Недвижимость, Доставка, Строй',
    },
    {
        id: 1354,
        artnumber: '24128',
        barcode: '51880',
        quantity: 139,
        price: 2120,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1354 для Подольск, Недвижимость, Доставка, Строй',
    },
    {
        id: 1355,
        artnumber: '91495',
        barcode: '13978',
        quantity: 453,
        price: 19996,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1355 для Подольск, Недвижимость, Доставка, Строй',
    },
    {
        id: 1356,
        artnumber: '73615',
        barcode: '20690',
        quantity: 381,
        price: 39171,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1356 для Подольск, Недвижимость, Доставка, Строй',
    },
    {
        id: 1357,
        artnumber: '83071',
        barcode: '29486',
        quantity: 209,
        price: 41825,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1357 для Подольск, Недвижимость, Доставка, Строй',
    },
    {
        id: 1358,
        artnumber: '62105',
        barcode: '72621',
        quantity: 323,
        price: 24794,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1358 для Подольск, Недвижимость, Доставка, Строй',
    },
    {
        id: 1359,
        artnumber: '10893',
        barcode: '86447',
        quantity: 177,
        price: 18708,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1359 для Подольск, Недвижимость, Доставка, Строй',
    },
    {
        id: 1360,
        artnumber: '61470',
        barcode: '79739',
        quantity: 424,
        price: 32066,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1360 для Подольск, Недвижимость, Доставка, Бета',
    },
    {
        id: 1361,
        artnumber: '29709',
        barcode: '63206',
        quantity: 128,
        price: 19081,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1361 для Подольск, Недвижимость, Доставка, Бета',
    },
    {
        id: 1362,
        artnumber: '75661',
        barcode: '90260',
        quantity: 147,
        price: 34739,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1362 для Подольск, Недвижимость, Доставка, Бета',
    },
    {
        id: 1363,
        artnumber: '22860',
        barcode: '97651',
        quantity: 467,
        price: 1172,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1363 для Подольск, Недвижимость, Доставка, Бета',
    },
    {
        id: 1364,
        artnumber: '97065',
        barcode: '12470',
        quantity: 422,
        price: 25346,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1364 для Подольск, Недвижимость, Доставка, Бета',
    },
    {
        id: 1365,
        artnumber: '54529',
        barcode: '44839',
        quantity: 174,
        price: 35277,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1365 для Подольск, Недвижимость, Доставка, Бета',
    },
    {
        id: 1366,
        artnumber: '30047',
        barcode: '82912',
        quantity: 256,
        price: 21842,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1366 для Подольск, Недвижимость, Доставка, Бета',
    },
    {
        id: 1367,
        artnumber: '87413',
        barcode: '89730',
        quantity: 248,
        price: 40706,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1367 для Подольск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 1368,
        artnumber: '80960',
        barcode: '49195',
        quantity: 170,
        price: 31529,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1368 для Подольск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 1369,
        artnumber: '53310',
        barcode: '57256',
        quantity: 91,
        price: 49123,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1369 для Подольск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 1370,
        artnumber: '71064',
        barcode: '21697',
        quantity: 331,
        price: 3126,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1370 для Подольск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 1371,
        artnumber: '86716',
        barcode: '76419',
        quantity: 101,
        price: 10075,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1371 для Подольск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 1372,
        artnumber: '72341',
        barcode: '47765',
        quantity: 478,
        price: 2007,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1372 для Подольск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 1373,
        artnumber: '63368',
        barcode: '22562',
        quantity: 270,
        price: 19612,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1373 для Подольск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 1374,
        artnumber: '92596',
        barcode: '49815',
        quantity: 113,
        price: 36736,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1374 для Подольск, Недвижимость, Доставка, Трио',
    },
    {
        id: 1375,
        artnumber: '19933',
        barcode: '92283',
        quantity: 396,
        price: 31330,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1375 для Подольск, Недвижимость, Доставка, Трио',
    },
    {
        id: 1376,
        artnumber: '84572',
        barcode: '55238',
        quantity: 380,
        price: 2738,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1376 для Подольск, Недвижимость, Доставка, Трио',
    },
    {
        id: 1377,
        artnumber: '98989',
        barcode: '45999',
        quantity: 52,
        price: 6744,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1377 для Подольск, Недвижимость, Доставка, Трио',
    },
    {
        id: 1378,
        artnumber: '44466',
        barcode: '64183',
        quantity: 494,
        price: 10931,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1378 для Подольск, Недвижимость, Доставка, Трио',
    },
    {
        id: 1379,
        artnumber: '60478',
        barcode: '60551',
        quantity: 35,
        price: 44460,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1379 для Подольск, Недвижимость, Доставка, Трио',
    },
    {
        id: 1380,
        artnumber: '62437',
        barcode: '44827',
        quantity: 416,
        price: 31810,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1380 для Подольск, Недвижимость, Доставка, Трио',
    },
    {
        id: 1381,
        artnumber: '24773',
        barcode: '52001',
        quantity: 396,
        price: 14175,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1381 для Подольск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 1382,
        artnumber: '71093',
        barcode: '79033',
        quantity: 222,
        price: 49478,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1382 для Подольск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 1383,
        artnumber: '76612',
        barcode: '96690',
        quantity: 184,
        price: 22471,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1383 для Подольск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 1384,
        artnumber: '33912',
        barcode: '53667',
        quantity: 282,
        price: 43625,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1384 для Подольск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 1385,
        artnumber: '50339',
        barcode: '97703',
        quantity: 108,
        price: 31313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1385 для Подольск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 1386,
        artnumber: '42928',
        barcode: '83720',
        quantity: 389,
        price: 25843,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1386 для Подольск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 1387,
        artnumber: '54079',
        barcode: '36126',
        quantity: 350,
        price: 21847,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1387 для Подольск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 1388,
        artnumber: '87893',
        barcode: '73014',
        quantity: 96,
        price: 28018,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1388 для Подольск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 1389,
        artnumber: '48161',
        barcode: '40000',
        quantity: 200,
        price: 31420,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1389 для Подольск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 1390,
        artnumber: '40138',
        barcode: '42951',
        quantity: 416,
        price: 46929,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1390 для Подольск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 1391,
        artnumber: '65442',
        barcode: '95432',
        quantity: 405,
        price: 46642,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1391 для Подольск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 1392,
        artnumber: '32215',
        barcode: '72592',
        quantity: 208,
        price: 8637,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1392 для Подольск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 1393,
        artnumber: '55107',
        barcode: '57900',
        quantity: 142,
        price: 30926,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1393 для Подольск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 1394,
        artnumber: '12918',
        barcode: '10304',
        quantity: 263,
        price: 11113,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1394 для Подольск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 1395,
        artnumber: '39889',
        barcode: '12362',
        quantity: 22,
        price: 41794,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1395 для Подольск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 1396,
        artnumber: '43384',
        barcode: '51262',
        quantity: 250,
        price: 13901,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1396 для Подольск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 1397,
        artnumber: '80053',
        barcode: '80781',
        quantity: 498,
        price: 43315,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1397 для Подольск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 1398,
        artnumber: '85517',
        barcode: '62650',
        quantity: 444,
        price: 17865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1398 для Подольск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 1399,
        artnumber: '60257',
        barcode: '98448',
        quantity: 161,
        price: 23909,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1399 для Подольск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 1400,
        artnumber: '45586',
        barcode: '27631',
        quantity: 191,
        price: 26605,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1400 для Подольск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 1401,
        artnumber: '37149',
        barcode: '54691',
        quantity: 36,
        price: 15456,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1401 для Подольск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 1402,
        artnumber: '48533',
        barcode: '52549',
        quantity: 166,
        price: 31957,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1402 для Подольск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 1403,
        artnumber: '67481',
        barcode: '37391',
        quantity: 369,
        price: 21339,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1403 для Подольск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 1404,
        artnumber: '48511',
        barcode: '78525',
        quantity: 359,
        price: 33810,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1404 для Подольск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 1405,
        artnumber: '88134',
        barcode: '31508',
        quantity: 190,
        price: 21423,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1405 для Подольск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 1406,
        artnumber: '37296',
        barcode: '45031',
        quantity: 440,
        price: 13873,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1406 для Подольск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 1407,
        artnumber: '65599',
        barcode: '52317',
        quantity: 427,
        price: 47664,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1407 для Подольск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 1408,
        artnumber: '58719',
        barcode: '29612',
        quantity: 494,
        price: 31680,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1408 для Подольск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 1409,
        artnumber: '93567',
        barcode: '62885',
        quantity: 230,
        price: 677,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1409 для Подольск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 1410,
        artnumber: '53144',
        barcode: '15713',
        quantity: 247,
        price: 26387,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1410 для Подольск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 1411,
        artnumber: '56976',
        barcode: '23734',
        quantity: 249,
        price: 11752,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1411 для Подольск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 1412,
        artnumber: '74709',
        barcode: '19555',
        quantity: 472,
        price: 42573,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1412 для Подольск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 1413,
        artnumber: '32145',
        barcode: '76521',
        quantity: 376,
        price: 24649,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1413 для Подольск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 1414,
        artnumber: '57515',
        barcode: '79177',
        quantity: 198,
        price: 41760,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1414 для Подольск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 1415,
        artnumber: '58709',
        barcode: '43454',
        quantity: 265,
        price: 40340,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1415 для Подольск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 1416,
        artnumber: '59688',
        barcode: '92534',
        quantity: 112,
        price: 36421,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1416 для Подольск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 1417,
        artnumber: '77729',
        barcode: '42192',
        quantity: 418,
        price: 39534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1417 для Подольск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 1418,
        artnumber: '78383',
        barcode: '22415',
        quantity: 101,
        price: 42728,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1418 для Подольск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 1419,
        artnumber: '48274',
        barcode: '51162',
        quantity: 444,
        price: 21180,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1419 для Подольск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 1420,
        artnumber: '85874',
        barcode: '25928',
        quantity: 424,
        price: 6875,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1420 для Подольск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 1421,
        artnumber: '10225',
        barcode: '30019',
        quantity: 357,
        price: 24892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1421 для Подольск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 1422,
        artnumber: '79812',
        barcode: '87386',
        quantity: 131,
        price: 49964,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1422 для Подольск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 1423,
        artnumber: '64921',
        barcode: '47052',
        quantity: 273,
        price: 23511,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1423 для Подольск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 1424,
        artnumber: '39106',
        barcode: '20814',
        quantity: 97,
        price: 17170,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1424 для Подольск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 1425,
        artnumber: '66395',
        barcode: '43587',
        quantity: 132,
        price: 22280,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1425 для Подольск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 1426,
        artnumber: '77139',
        barcode: '43146',
        quantity: 312,
        price: 9743,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1426 для Подольск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 1427,
        artnumber: '35511',
        barcode: '56866',
        quantity: 310,
        price: 25415,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1427 для Подольск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 1428,
        artnumber: '39248',
        barcode: '61206',
        quantity: 46,
        price: 12993,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1428 для Подольск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 1429,
        artnumber: '21941',
        barcode: '44866',
        quantity: 299,
        price: 29624,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1429 для Подольск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 1430,
        artnumber: '57094',
        barcode: '46271',
        quantity: 6,
        price: 33119,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1430 для Подольск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 1431,
        artnumber: '67697',
        barcode: '45741',
        quantity: 405,
        price: 4698,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1431 для Подольск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 1432,
        artnumber: '65892',
        barcode: '29752',
        quantity: 360,
        price: 24658,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1432 для Подольск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 1433,
        artnumber: '68546',
        barcode: '67558',
        quantity: 367,
        price: 28286,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1433 для Подольск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 1434,
        artnumber: '44935',
        barcode: '25202',
        quantity: 91,
        price: 21733,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1434 для Подольск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 1435,
        artnumber: '47887',
        barcode: '87247',
        quantity: 94,
        price: 28715,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1435 для Подольск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 1436,
        artnumber: '36205',
        barcode: '88083',
        quantity: 373,
        price: 953,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1436 для Подольск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 1437,
        artnumber: '81429',
        barcode: '55719',
        quantity: 158,
        price: 7627,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1437 для Подольск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 1438,
        artnumber: '58471',
        barcode: '79357',
        quantity: 406,
        price: 22599,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1438 для Подольск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 1439,
        artnumber: '26175',
        barcode: '13181',
        quantity: 246,
        price: 15345,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1439 для Подольск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 1440,
        artnumber: '11733',
        barcode: '42878',
        quantity: 475,
        price: 11281,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1440 для Подольск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 1441,
        artnumber: '54846',
        barcode: '23019',
        quantity: 322,
        price: 47938,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1441 для Подольск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 1442,
        artnumber: '56289',
        barcode: '17888',
        quantity: 242,
        price: 18589,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1442 для Подольск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 1443,
        artnumber: '43494',
        barcode: '63051',
        quantity: 91,
        price: 33240,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1443 для Подольск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 1444,
        artnumber: '93675',
        barcode: '35917',
        quantity: 17,
        price: 30421,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1444 для Подольск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 1445,
        artnumber: '82119',
        barcode: '56207',
        quantity: 170,
        price: 39951,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1445 для Подольск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 1446,
        artnumber: '79857',
        barcode: '87530',
        quantity: 272,
        price: 17369,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1446 для Подольск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 1447,
        artnumber: '97447',
        barcode: '87115',
        quantity: 87,
        price: 32220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1447 для Подольск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 1448,
        artnumber: '56632',
        barcode: '57258',
        quantity: 445,
        price: 22644,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1448 для Подольск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 1449,
        artnumber: '39168',
        barcode: '11083',
        quantity: 454,
        price: 24970,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1449 для Подольск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 1450,
        artnumber: '96714',
        barcode: '36927',
        quantity: 279,
        price: 39467,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1450 для Подольск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 1451,
        artnumber: '74329',
        barcode: '65268',
        quantity: 326,
        price: 5462,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1451 для Подольск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 1452,
        artnumber: '29566',
        barcode: '94343',
        quantity: 212,
        price: 15627,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1452 для Подольск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 1453,
        artnumber: '36134',
        barcode: '58237',
        quantity: 381,
        price: 19534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1453 для Подольск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 1454,
        artnumber: '90285',
        barcode: '57520',
        quantity: 278,
        price: 32927,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1454 для Подольск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 1455,
        artnumber: '24361',
        barcode: '24876',
        quantity: 399,
        price: 18525,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1455 для Подольск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 1456,
        artnumber: '24242',
        barcode: '57758',
        quantity: 454,
        price: 11235,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1456 для Подольск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 1457,
        artnumber: '78396',
        barcode: '38070',
        quantity: 141,
        price: 46520,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1457 для Подольск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 1458,
        artnumber: '39955',
        barcode: '56320',
        quantity: 39,
        price: 26410,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1458 для Подольск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 1459,
        artnumber: '12611',
        barcode: '15810',
        quantity: 485,
        price: 12669,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1459 для Подольск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 1460,
        artnumber: '44980',
        barcode: '91821',
        quantity: 317,
        price: 40182,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1460 для Подольск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 1461,
        artnumber: '69308',
        barcode: '92909',
        quantity: 321,
        price: 38966,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1461 для Подольск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 1462,
        artnumber: '82920',
        barcode: '97573',
        quantity: 378,
        price: 34499,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1462 для Подольск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 1463,
        artnumber: '23503',
        barcode: '71961',
        quantity: 377,
        price: 15963,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1463 для Подольск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 1464,
        artnumber: '82257',
        barcode: '99585',
        quantity: 145,
        price: 46237,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1464 для Подольск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 1465,
        artnumber: '68172',
        barcode: '16543',
        quantity: 29,
        price: 16646,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1465 для Подольск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 1466,
        artnumber: '33808',
        barcode: '58606',
        quantity: 35,
        price: 11510,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1466 для Подольск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 1467,
        artnumber: '41132',
        barcode: '59292',
        quantity: 392,
        price: 24011,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1467 для Подольск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 1468,
        artnumber: '88725',
        barcode: '55697',
        quantity: 430,
        price: 15844,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1468 для Подольск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 1469,
        artnumber: '31568',
        barcode: '94857',
        quantity: 453,
        price: 17558,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1469 для Подольск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 1470,
        artnumber: '90555',
        barcode: '56964',
        quantity: 489,
        price: 49626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1470 для Подольск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 1471,
        artnumber: '62168',
        barcode: '76242',
        quantity: 281,
        price: 38144,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1471 для Подольск, Красота, Сервис, Альфа',
    },
    {
        id: 1472,
        artnumber: '61355',
        barcode: '98855',
        quantity: 473,
        price: 2515,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1472 для Подольск, Красота, Сервис, Альфа',
    },
    {
        id: 1473,
        artnumber: '87359',
        barcode: '22554',
        quantity: 48,
        price: 25077,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1473 для Подольск, Красота, Сервис, Альфа',
    },
    {
        id: 1474,
        artnumber: '34741',
        barcode: '75579',
        quantity: 395,
        price: 22480,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1474 для Подольск, Красота, Сервис, Альфа',
    },
    {
        id: 1475,
        artnumber: '80064',
        barcode: '39515',
        quantity: 420,
        price: 36255,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1475 для Подольск, Красота, Сервис, Альфа',
    },
    {
        id: 1476,
        artnumber: '51448',
        barcode: '44690',
        quantity: 254,
        price: 21136,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1476 для Подольск, Красота, Сервис, Альфа',
    },
    {
        id: 1477,
        artnumber: '98565',
        barcode: '42623',
        quantity: 316,
        price: 32531,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1477 для Подольск, Красота, Сервис, Альфа',
    },
    {
        id: 1478,
        artnumber: '85429',
        barcode: '89635',
        quantity: 366,
        price: 5769,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1478 для Подольск, Красота, Сервис, Альфа',
    },
    {
        id: 1479,
        artnumber: '78540',
        barcode: '52402',
        quantity: 409,
        price: 40606,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1479 для Подольск, Красота, Сервис, Альфа',
    },
    {
        id: 1480,
        artnumber: '23030',
        barcode: '95631',
        quantity: 66,
        price: 7328,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1480 для Подольск, Красота, Сервис, Строй',
    },
    {
        id: 1481,
        artnumber: '35948',
        barcode: '26434',
        quantity: 88,
        price: 1723,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1481 для Подольск, Красота, Сервис, Строй',
    },
    {
        id: 1482,
        artnumber: '74097',
        barcode: '96976',
        quantity: 130,
        price: 40103,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1482 для Подольск, Красота, Сервис, Строй',
    },
    {
        id: 1483,
        artnumber: '75357',
        barcode: '44710',
        quantity: 245,
        price: 12543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1483 для Подольск, Красота, Сервис, Строй',
    },
    {
        id: 1484,
        artnumber: '93591',
        barcode: '39676',
        quantity: 364,
        price: 24419,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1484 для Подольск, Красота, Сервис, Строй',
    },
    {
        id: 1485,
        artnumber: '74355',
        barcode: '43007',
        quantity: 300,
        price: 42502,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1485 для Подольск, Красота, Сервис, Строй',
    },
    {
        id: 1486,
        artnumber: '54963',
        barcode: '54296',
        quantity: 179,
        price: 43581,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1486 для Подольск, Красота, Сервис, Строй',
    },
    {
        id: 1487,
        artnumber: '88634',
        barcode: '18184',
        quantity: 55,
        price: 7457,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1487 для Подольск, Красота, Сервис, Строй',
    },
    {
        id: 1488,
        artnumber: '37324',
        barcode: '40006',
        quantity: 240,
        price: 13149,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1488 для Подольск, Красота, Сервис, Строй',
    },
    {
        id: 1489,
        artnumber: '83285',
        barcode: '72942',
        quantity: 394,
        price: 25997,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1489 для Подольск, Красота, Сервис, Бета',
    },
    {
        id: 1490,
        artnumber: '89505',
        barcode: '53250',
        quantity: 262,
        price: 18215,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1490 для Подольск, Красота, Сервис, Бета',
    },
    {
        id: 1491,
        artnumber: '57997',
        barcode: '10078',
        quantity: 322,
        price: 4752,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1491 для Подольск, Красота, Сервис, Бета',
    },
    {
        id: 1492,
        artnumber: '21807',
        barcode: '72938',
        quantity: 92,
        price: 48744,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1492 для Подольск, Красота, Сервис, Бета',
    },
    {
        id: 1493,
        artnumber: '60371',
        barcode: '37074',
        quantity: 319,
        price: 49534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1493 для Подольск, Красота, Сервис, Бета',
    },
    {
        id: 1494,
        artnumber: '45778',
        barcode: '76789',
        quantity: 385,
        price: 38004,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1494 для Подольск, Красота, Сервис, Бета',
    },
    {
        id: 1495,
        artnumber: '29747',
        barcode: '79499',
        quantity: 51,
        price: 37296,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1495 для Подольск, Красота, Сервис, Бета',
    },
    {
        id: 1496,
        artnumber: '22140',
        barcode: '78308',
        quantity: 314,
        price: 899,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1496 для Подольск, Красота, Сервис, Бета',
    },
    {
        id: 1497,
        artnumber: '33102',
        barcode: '30026',
        quantity: 299,
        price: 18349,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1497 для Подольск, Красота, Сервис, Бета',
    },
    {
        id: 1498,
        artnumber: '16581',
        barcode: '78169',
        quantity: 5,
        price: 15120,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1498 для Подольск, Красота, Сервис, Гамма',
    },
    {
        id: 1499,
        artnumber: '28959',
        barcode: '63682',
        quantity: 250,
        price: 4233,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1499 для Подольск, Красота, Сервис, Гамма',
    },
    {
        id: 1500,
        artnumber: '33633',
        barcode: '23637',
        quantity: 456,
        price: 30940,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1500 для Подольск, Красота, Сервис, Гамма',
    },
    {
        id: 1501,
        artnumber: '61831',
        barcode: '95597',
        quantity: 192,
        price: 37577,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1501 для Подольск, Красота, Сервис, Гамма',
    },
    {
        id: 1502,
        artnumber: '73250',
        barcode: '95991',
        quantity: 371,
        price: 10411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1502 для Подольск, Красота, Сервис, Гамма',
    },
    {
        id: 1503,
        artnumber: '30030',
        barcode: '19960',
        quantity: 131,
        price: 15531,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1503 для Подольск, Красота, Сервис, Гамма',
    },
    {
        id: 1504,
        artnumber: '90261',
        barcode: '61352',
        quantity: 121,
        price: 45706,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1504 для Подольск, Красота, Сервис, Гамма',
    },
    {
        id: 1505,
        artnumber: '19744',
        barcode: '65835',
        quantity: 344,
        price: 49811,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1505 для Подольск, Красота, Сервис, Гамма',
    },
    {
        id: 1506,
        artnumber: '36817',
        barcode: '97432',
        quantity: 438,
        price: 33805,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1506 для Подольск, Красота, Сервис, Гамма',
    },
    {
        id: 1507,
        artnumber: '74269',
        barcode: '43934',
        quantity: 412,
        price: 43800,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1507 для Подольск, Красота, Сервис, Трио',
    },
    {
        id: 1508,
        artnumber: '49310',
        barcode: '52131',
        quantity: 491,
        price: 2775,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1508 для Подольск, Красота, Сервис, Трио',
    },
    {
        id: 1509,
        artnumber: '70426',
        barcode: '92103',
        quantity: 11,
        price: 32634,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1509 для Подольск, Красота, Сервис, Трио',
    },
    {
        id: 1510,
        artnumber: '46045',
        barcode: '94328',
        quantity: 68,
        price: 35289,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1510 для Подольск, Красота, Сервис, Трио',
    },
    {
        id: 1511,
        artnumber: '85380',
        barcode: '25128',
        quantity: 499,
        price: 9150,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1511 для Подольск, Красота, Сервис, Трио',
    },
    {
        id: 1512,
        artnumber: '61003',
        barcode: '86558',
        quantity: 316,
        price: 2019,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1512 для Подольск, Красота, Сервис, Трио',
    },
    {
        id: 1513,
        artnumber: '97571',
        barcode: '60877',
        quantity: 238,
        price: 33394,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1513 для Подольск, Красота, Сервис, Трио',
    },
    {
        id: 1514,
        artnumber: '80225',
        barcode: '47385',
        quantity: 118,
        price: 19619,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1514 для Подольск, Красота, Сервис, Трио',
    },
    {
        id: 1515,
        artnumber: '74677',
        barcode: '64137',
        quantity: 231,
        price: 4796,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1515 для Подольск, Красота, Сервис, Трио',
    },
    {
        id: 1516,
        artnumber: '68376',
        barcode: '75620',
        quantity: 125,
        price: 40619,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1516 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1517,
        artnumber: '18602',
        barcode: '79544',
        quantity: 132,
        price: 21421,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1517 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1518,
        artnumber: '35923',
        barcode: '19696',
        quantity: 248,
        price: 34492,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1518 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1519,
        artnumber: '98375',
        barcode: '12940',
        quantity: 273,
        price: 38366,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1519 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1520,
        artnumber: '95502',
        barcode: '76602',
        quantity: 342,
        price: 18629,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1520 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1521,
        artnumber: '86291',
        barcode: '72615',
        quantity: 391,
        price: 36351,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1521 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1522,
        artnumber: '51601',
        barcode: '10316',
        quantity: 369,
        price: 20701,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1522 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1523,
        artnumber: '83157',
        barcode: '78028',
        quantity: 375,
        price: 43770,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1523 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1524,
        artnumber: '21624',
        barcode: '71567',
        quantity: 306,
        price: 42757,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1524 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1525,
        artnumber: '51948',
        barcode: '26138',
        quantity: 183,
        price: 22493,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1525 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1526,
        artnumber: '65843',
        barcode: '93213',
        quantity: 24,
        price: 14410,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1526 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1527,
        artnumber: '26100',
        barcode: '23375',
        quantity: 491,
        price: 49707,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1527 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1528,
        artnumber: '77125',
        barcode: '46974',
        quantity: 69,
        price: 10741,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1528 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1529,
        artnumber: '63858',
        barcode: '59743',
        quantity: 305,
        price: 34804,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1529 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1530,
        artnumber: '79051',
        barcode: '75926',
        quantity: 483,
        price: 29049,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1530 для Подольск, Красота, Запчасти, Альфа',
    },
    {
        id: 1531,
        artnumber: '45421',
        barcode: '13916',
        quantity: 35,
        price: 37312,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1531 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1532,
        artnumber: '68460',
        barcode: '95638',
        quantity: 131,
        price: 27001,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1532 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1533,
        artnumber: '42192',
        barcode: '55136',
        quantity: 104,
        price: 45394,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1533 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1534,
        artnumber: '21238',
        barcode: '55583',
        quantity: 479,
        price: 29122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1534 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1535,
        artnumber: '69076',
        barcode: '73746',
        quantity: 198,
        price: 22199,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1535 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1536,
        artnumber: '60266',
        barcode: '72252',
        quantity: 404,
        price: 3997,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1536 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1537,
        artnumber: '59681',
        barcode: '78831',
        quantity: 293,
        price: 28507,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1537 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1538,
        artnumber: '89536',
        barcode: '52497',
        quantity: 455,
        price: 21445,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1538 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1539,
        artnumber: '13921',
        barcode: '23828',
        quantity: 468,
        price: 46266,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1539 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1540,
        artnumber: '43021',
        barcode: '10391',
        quantity: 189,
        price: 19173,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1540 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1541,
        artnumber: '60064',
        barcode: '45538',
        quantity: 373,
        price: 4122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1541 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1542,
        artnumber: '84383',
        barcode: '61521',
        quantity: 355,
        price: 6289,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1542 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1543,
        artnumber: '43026',
        barcode: '94536',
        quantity: 128,
        price: 13256,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1543 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1544,
        artnumber: '82680',
        barcode: '19634',
        quantity: 424,
        price: 11089,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1544 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1545,
        artnumber: '67572',
        barcode: '87896',
        quantity: 439,
        price: 8498,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1545 для Подольск, Красота, Запчасти, Строй',
    },
    {
        id: 1546,
        artnumber: '80145',
        barcode: '57786',
        quantity: 239,
        price: 29767,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1546 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1547,
        artnumber: '59531',
        barcode: '58594',
        quantity: 205,
        price: 16104,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1547 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1548,
        artnumber: '56579',
        barcode: '14246',
        quantity: 249,
        price: 23595,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1548 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1549,
        artnumber: '79520',
        barcode: '70294',
        quantity: 358,
        price: 23198,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1549 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1550,
        artnumber: '72385',
        barcode: '66905',
        quantity: 306,
        price: 227,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1550 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1551,
        artnumber: '37327',
        barcode: '95000',
        quantity: 176,
        price: 37843,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1551 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1552,
        artnumber: '43133',
        barcode: '19563',
        quantity: 121,
        price: 2276,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1552 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1553,
        artnumber: '85595',
        barcode: '61405',
        quantity: 156,
        price: 48012,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1553 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1554,
        artnumber: '86477',
        barcode: '59951',
        quantity: 144,
        price: 35706,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1554 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1555,
        artnumber: '84763',
        barcode: '42478',
        quantity: 264,
        price: 37013,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1555 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1556,
        artnumber: '24560',
        barcode: '35114',
        quantity: 133,
        price: 9352,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1556 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1557,
        artnumber: '54746',
        barcode: '89699',
        quantity: 262,
        price: 28197,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1557 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1558,
        artnumber: '38427',
        barcode: '98290',
        quantity: 72,
        price: 27881,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1558 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1559,
        artnumber: '51103',
        barcode: '85239',
        quantity: 435,
        price: 27395,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1559 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1560,
        artnumber: '61496',
        barcode: '45061',
        quantity: 355,
        price: 11657,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1560 для Подольск, Красота, Запчасти, Бета',
    },
    {
        id: 1561,
        artnumber: '46213',
        barcode: '28512',
        quantity: 186,
        price: 15609,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1561 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1562,
        artnumber: '66642',
        barcode: '34586',
        quantity: 315,
        price: 6958,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1562 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1563,
        artnumber: '65041',
        barcode: '75328',
        quantity: 187,
        price: 40793,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1563 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1564,
        artnumber: '29048',
        barcode: '66990',
        quantity: 417,
        price: 38,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1564 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1565,
        artnumber: '29079',
        barcode: '84286',
        quantity: 328,
        price: 34336,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1565 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1566,
        artnumber: '72207',
        barcode: '39819',
        quantity: 219,
        price: 20859,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1566 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1567,
        artnumber: '86158',
        barcode: '87824',
        quantity: 100,
        price: 32481,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1567 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1568,
        artnumber: '85040',
        barcode: '47434',
        quantity: 438,
        price: 31910,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1568 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1569,
        artnumber: '76001',
        barcode: '34489',
        quantity: 226,
        price: 20007,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1569 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1570,
        artnumber: '44244',
        barcode: '91191',
        quantity: 168,
        price: 23980,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1570 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1571,
        artnumber: '99420',
        barcode: '93263',
        quantity: 100,
        price: 23149,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1571 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1572,
        artnumber: '16965',
        barcode: '72256',
        quantity: 394,
        price: 23882,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1572 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1573,
        artnumber: '81315',
        barcode: '55981',
        quantity: 269,
        price: 37494,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1573 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1574,
        artnumber: '88176',
        barcode: '61496',
        quantity: 465,
        price: 26366,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1574 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1575,
        artnumber: '71086',
        barcode: '72239',
        quantity: 152,
        price: 20190,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1575 для Подольск, Красота, Запчасти, Гамма',
    },
    {
        id: 1576,
        artnumber: '50248',
        barcode: '73199',
        quantity: 464,
        price: 25405,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1576 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1577,
        artnumber: '82580',
        barcode: '83050',
        quantity: 244,
        price: 4734,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1577 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1578,
        artnumber: '60533',
        barcode: '52760',
        quantity: 370,
        price: 9477,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1578 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1579,
        artnumber: '41287',
        barcode: '96987',
        quantity: 413,
        price: 33060,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1579 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1580,
        artnumber: '81948',
        barcode: '69861',
        quantity: 196,
        price: 43396,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1580 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1581,
        artnumber: '49584',
        barcode: '61636',
        quantity: 41,
        price: 3884,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1581 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1582,
        artnumber: '58560',
        barcode: '93197',
        quantity: 330,
        price: 23013,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1582 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1583,
        artnumber: '83830',
        barcode: '81075',
        quantity: 481,
        price: 41818,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1583 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1584,
        artnumber: '48167',
        barcode: '56259',
        quantity: 236,
        price: 33455,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1584 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1585,
        artnumber: '38387',
        barcode: '49305',
        quantity: 83,
        price: 22843,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1585 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1586,
        artnumber: '37009',
        barcode: '52936',
        quantity: 376,
        price: 38874,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1586 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1587,
        artnumber: '52429',
        barcode: '40253',
        quantity: 147,
        price: 5558,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1587 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1588,
        artnumber: '65961',
        barcode: '39318',
        quantity: 102,
        price: 8042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1588 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1589,
        artnumber: '15761',
        barcode: '77402',
        quantity: 40,
        price: 46473,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1589 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1590,
        artnumber: '21198',
        barcode: '16333',
        quantity: 115,
        price: 37799,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1590 для Подольск, Красота, Запчасти, Трио',
    },
    {
        id: 1591,
        artnumber: '37556',
        barcode: '84458',
        quantity: 301,
        price: 46308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1591 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1592,
        artnumber: '22218',
        barcode: '23683',
        quantity: 123,
        price: 10161,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1592 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1593,
        artnumber: '26732',
        barcode: '82396',
        quantity: 460,
        price: 40317,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1593 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1594,
        artnumber: '16657',
        barcode: '59968',
        quantity: 142,
        price: 14484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1594 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1595,
        artnumber: '17576',
        barcode: '31309',
        quantity: 448,
        price: 34229,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1595 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1596,
        artnumber: '52487',
        barcode: '89426',
        quantity: 308,
        price: 13746,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1596 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1597,
        artnumber: '33168',
        barcode: '82397',
        quantity: 196,
        price: 24343,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1597 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1598,
        artnumber: '63041',
        barcode: '88573',
        quantity: 394,
        price: 15640,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1598 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1599,
        artnumber: '97258',
        barcode: '40478',
        quantity: 205,
        price: 40115,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1599 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1600,
        artnumber: '19832',
        barcode: '34458',
        quantity: 73,
        price: 14667,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1600 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1601,
        artnumber: '33116',
        barcode: '65521',
        quantity: 231,
        price: 12288,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1601 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1602,
        artnumber: '53976',
        barcode: '14634',
        quantity: 245,
        price: 19544,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1602 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1603,
        artnumber: '27895',
        barcode: '35115',
        quantity: 402,
        price: 45832,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1603 для Подольск, Красота, Доставка, Альфа',
    },
    {
        id: 1604,
        artnumber: '33661',
        barcode: '63613',
        quantity: 48,
        price: 19605,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1604 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1605,
        artnumber: '93067',
        barcode: '80098',
        quantity: 376,
        price: 38727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1605 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1606,
        artnumber: '42759',
        barcode: '51442',
        quantity: 425,
        price: 41121,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1606 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1607,
        artnumber: '13256',
        barcode: '99739',
        quantity: 80,
        price: 1278,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1607 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1608,
        artnumber: '49469',
        barcode: '23953',
        quantity: 149,
        price: 41704,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1608 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1609,
        artnumber: '21028',
        barcode: '44765',
        quantity: 4,
        price: 40739,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1609 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1610,
        artnumber: '16058',
        barcode: '90963',
        quantity: 229,
        price: 26695,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1610 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1611,
        artnumber: '15258',
        barcode: '79700',
        quantity: 490,
        price: 37079,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1611 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1612,
        artnumber: '36415',
        barcode: '80762',
        quantity: 235,
        price: 6191,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1612 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1613,
        artnumber: '37271',
        barcode: '41959',
        quantity: 214,
        price: 26265,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1613 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1614,
        artnumber: '58901',
        barcode: '64832',
        quantity: 33,
        price: 9200,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1614 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1615,
        artnumber: '50117',
        barcode: '93347',
        quantity: 24,
        price: 12226,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1615 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1616,
        artnumber: '93781',
        barcode: '96234',
        quantity: 175,
        price: 49374,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1616 для Подольск, Красота, Доставка, Строй',
    },
    {
        id: 1617,
        artnumber: '17294',
        barcode: '17479',
        quantity: 160,
        price: 48360,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1617 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1618,
        artnumber: '70056',
        barcode: '67403',
        quantity: 229,
        price: 32928,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1618 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1619,
        artnumber: '42153',
        barcode: '53640',
        quantity: 107,
        price: 727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1619 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1620,
        artnumber: '91037',
        barcode: '36481',
        quantity: 370,
        price: 9746,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1620 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1621,
        artnumber: '90995',
        barcode: '58469',
        quantity: 358,
        price: 6190,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1621 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1622,
        artnumber: '84916',
        barcode: '19196',
        quantity: 284,
        price: 8396,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1622 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1623,
        artnumber: '50773',
        barcode: '81670',
        quantity: 82,
        price: 11896,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1623 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1624,
        artnumber: '12822',
        barcode: '39647',
        quantity: 152,
        price: 37956,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1624 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1625,
        artnumber: '69607',
        barcode: '77025',
        quantity: 66,
        price: 14818,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1625 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1626,
        artnumber: '76989',
        barcode: '10196',
        quantity: 310,
        price: 45042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1626 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1627,
        artnumber: '72591',
        barcode: '15971',
        quantity: 384,
        price: 27340,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1627 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1628,
        artnumber: '97224',
        barcode: '33496',
        quantity: 311,
        price: 407,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1628 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1629,
        artnumber: '30454',
        barcode: '30500',
        quantity: 447,
        price: 29966,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1629 для Подольск, Красота, Доставка, Бета',
    },
    {
        id: 1630,
        artnumber: '63378',
        barcode: '60061',
        quantity: 499,
        price: 22252,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1630 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1631,
        artnumber: '72527',
        barcode: '74591',
        quantity: 285,
        price: 3482,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1631 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1632,
        artnumber: '47369',
        barcode: '60689',
        quantity: 275,
        price: 40734,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1632 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1633,
        artnumber: '75170',
        barcode: '21748',
        quantity: 10,
        price: 8469,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1633 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1634,
        artnumber: '59274',
        barcode: '48316',
        quantity: 374,
        price: 5110,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1634 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1635,
        artnumber: '71304',
        barcode: '56169',
        quantity: 450,
        price: 44411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1635 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1636,
        artnumber: '42217',
        barcode: '97969',
        quantity: 462,
        price: 18880,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1636 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1637,
        artnumber: '81531',
        barcode: '54925',
        quantity: 16,
        price: 19017,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1637 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1638,
        artnumber: '95142',
        barcode: '32031',
        quantity: 29,
        price: 19235,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1638 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1639,
        artnumber: '94608',
        barcode: '14095',
        quantity: 345,
        price: 42739,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1639 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1640,
        artnumber: '31448',
        barcode: '69258',
        quantity: 39,
        price: 2426,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1640 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1641,
        artnumber: '34338',
        barcode: '51269',
        quantity: 485,
        price: 11860,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1641 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1642,
        artnumber: '82416',
        barcode: '36779',
        quantity: 175,
        price: 35231,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1642 для Подольск, Красота, Доставка, Гамма',
    },
    {
        id: 1643,
        artnumber: '76617',
        barcode: '58045',
        quantity: 471,
        price: 20277,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1643 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1644,
        artnumber: '19630',
        barcode: '49842',
        quantity: 214,
        price: 4889,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1644 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1645,
        artnumber: '42433',
        barcode: '49631',
        quantity: 317,
        price: 37498,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1645 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1646,
        artnumber: '95469',
        barcode: '69309',
        quantity: 304,
        price: 2913,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1646 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1647,
        artnumber: '89386',
        barcode: '77580',
        quantity: 282,
        price: 32363,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1647 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1648,
        artnumber: '45709',
        barcode: '15751',
        quantity: 481,
        price: 30072,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1648 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1649,
        artnumber: '65853',
        barcode: '87093',
        quantity: 248,
        price: 41457,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1649 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1650,
        artnumber: '94824',
        barcode: '57699',
        quantity: 145,
        price: 10217,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1650 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1651,
        artnumber: '60602',
        barcode: '88536',
        quantity: 22,
        price: 4041,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1651 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1652,
        artnumber: '92374',
        barcode: '80963',
        quantity: 468,
        price: 47195,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1652 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1653,
        artnumber: '45248',
        barcode: '74786',
        quantity: 99,
        price: 35527,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1653 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1654,
        artnumber: '49742',
        barcode: '35740',
        quantity: 245,
        price: 13906,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1654 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1655,
        artnumber: '18169',
        barcode: '34368',
        quantity: 442,
        price: 22020,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1655 для Подольск, Красота, Доставка, Трио',
    },
    {
        id: 1656,
        artnumber: '90059',
        barcode: '80917',
        quantity: 405,
        price: 33755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1656 для Подольск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 1657,
        artnumber: '79860',
        barcode: '14307',
        quantity: 408,
        price: 5817,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1657 для Подольск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 1658,
        artnumber: '57513',
        barcode: '76131',
        quantity: 313,
        price: 20227,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1658 для Подольск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 1659,
        artnumber: '83175',
        barcode: '78224',
        quantity: 42,
        price: 48524,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1659 для Подольск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 1660,
        artnumber: '15299',
        barcode: '28815',
        quantity: 447,
        price: 40080,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1660 для Подольск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 1661,
        artnumber: '45301',
        barcode: '12528',
        quantity: 335,
        price: 44249,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1661 для Подольск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 1662,
        artnumber: '45785',
        barcode: '94826',
        quantity: 138,
        price: 38684,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1662 для Подольск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 1663,
        artnumber: '59031',
        barcode: '85647',
        quantity: 51,
        price: 1382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1663 для Подольск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 1664,
        artnumber: '92659',
        barcode: '92520',
        quantity: 360,
        price: 2898,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1664 для Подольск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 1665,
        artnumber: '75574',
        barcode: '46279',
        quantity: 65,
        price: 10843,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1665 для Подольск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 1666,
        artnumber: '32875',
        barcode: '15444',
        quantity: 150,
        price: 21272,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1666 для Подольск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 1667,
        artnumber: '28541',
        barcode: '63619',
        quantity: 79,
        price: 27213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1667 для Подольск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 1668,
        artnumber: '17845',
        barcode: '17415',
        quantity: 329,
        price: 41812,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1668 для Подольск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 1669,
        artnumber: '33748',
        barcode: '82857',
        quantity: 18,
        price: 46667,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1669 для Подольск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 1670,
        artnumber: '33139',
        barcode: '16164',
        quantity: 401,
        price: 9388,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1670 для Подольск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 1671,
        artnumber: '77935',
        barcode: '78280',
        quantity: 140,
        price: 34675,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1671 для Подольск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 1672,
        artnumber: '44612',
        barcode: '79838',
        quantity: 247,
        price: 18540,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1672 для Подольск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 1673,
        artnumber: '34859',
        barcode: '48400',
        quantity: 383,
        price: 39203,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1673 для Подольск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 1674,
        artnumber: '53404',
        barcode: '10010',
        quantity: 478,
        price: 7274,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1674 для Подольск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 1675,
        artnumber: '52593',
        barcode: '85361',
        quantity: 339,
        price: 10886,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1675 для Подольск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 1676,
        artnumber: '45477',
        barcode: '92200',
        quantity: 10,
        price: 17679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1676 для Подольск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 1677,
        artnumber: '72983',
        barcode: '32941',
        quantity: 453,
        price: 3926,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1677 для Подольск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 1678,
        artnumber: '63089',
        barcode: '16601',
        quantity: 104,
        price: 12587,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1678 для Подольск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 1679,
        artnumber: '43956',
        barcode: '58703',
        quantity: 343,
        price: 40515,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1679 для Подольск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 1680,
        artnumber: '11650',
        barcode: '46159',
        quantity: 292,
        price: 28238,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1680 для Подольск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 1681,
        artnumber: '32685',
        barcode: '22463',
        quantity: 244,
        price: 9561,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1681 для Подольск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 1682,
        artnumber: '42696',
        barcode: '16461',
        quantity: 489,
        price: 27789,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1682 для Подольск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 1683,
        artnumber: '91642',
        barcode: '69511',
        quantity: 383,
        price: 6837,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1683 для Подольск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 1684,
        artnumber: '49566',
        barcode: '88283',
        quantity: 129,
        price: 24805,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1684 для Подольск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 1685,
        artnumber: '84517',
        barcode: '45380',
        quantity: 153,
        price: 8682,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1685 для Подольск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 1686,
        artnumber: '51978',
        barcode: '93605',
        quantity: 498,
        price: 22130,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1686 для Подольск, Красота, Замена масла, Альфа',
    },
    {
        id: 1687,
        artnumber: '77321',
        barcode: '58908',
        quantity: 381,
        price: 9257,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1687 для Подольск, Красота, Замена масла, Альфа',
    },
    {
        id: 1688,
        artnumber: '32761',
        barcode: '29843',
        quantity: 462,
        price: 3483,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1688 для Подольск, Красота, Замена масла, Альфа',
    },
    {
        id: 1689,
        artnumber: '62324',
        barcode: '78437',
        quantity: 368,
        price: 7739,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1689 для Подольск, Красота, Замена масла, Альфа',
    },
    {
        id: 1690,
        artnumber: '19762',
        barcode: '30933',
        quantity: 378,
        price: 13871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1690 для Подольск, Красота, Замена масла, Альфа',
    },
    {
        id: 1691,
        artnumber: '61659',
        barcode: '32303',
        quantity: 58,
        price: 2573,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1691 для Подольск, Красота, Замена масла, Альфа',
    },
    {
        id: 1692,
        artnumber: '44995',
        barcode: '87742',
        quantity: 246,
        price: 29235,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1692 для Подольск, Красота, Замена масла, Альфа',
    },
    {
        id: 1693,
        artnumber: '55542',
        barcode: '16903',
        quantity: 425,
        price: 2474,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1693 для Подольск, Красота, Замена масла, Строй',
    },
    {
        id: 1694,
        artnumber: '44272',
        barcode: '92861',
        quantity: 36,
        price: 28626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1694 для Подольск, Красота, Замена масла, Строй',
    },
    {
        id: 1695,
        artnumber: '34608',
        barcode: '10210',
        quantity: 262,
        price: 2560,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1695 для Подольск, Красота, Замена масла, Строй',
    },
    {
        id: 1696,
        artnumber: '55439',
        barcode: '49821',
        quantity: 64,
        price: 42423,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1696 для Подольск, Красота, Замена масла, Строй',
    },
    {
        id: 1697,
        artnumber: '74950',
        barcode: '96298',
        quantity: 167,
        price: 20976,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1697 для Подольск, Красота, Замена масла, Строй',
    },
    {
        id: 1698,
        artnumber: '32291',
        barcode: '85682',
        quantity: 247,
        price: 47203,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1698 для Подольск, Красота, Замена масла, Строй',
    },
    {
        id: 1699,
        artnumber: '44144',
        barcode: '90629',
        quantity: 199,
        price: 24504,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1699 для Подольск, Красота, Замена масла, Строй',
    },
    {
        id: 1700,
        artnumber: '81116',
        barcode: '54409',
        quantity: 234,
        price: 13254,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1700 для Подольск, Красота, Замена масла, Бета',
    },
    {
        id: 1701,
        artnumber: '32250',
        barcode: '19185',
        quantity: 475,
        price: 6378,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1701 для Подольск, Красота, Замена масла, Бета',
    },
    {
        id: 1702,
        artnumber: '61064',
        barcode: '76896',
        quantity: 420,
        price: 28422,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1702 для Подольск, Красота, Замена масла, Бета',
    },
    {
        id: 1703,
        artnumber: '21452',
        barcode: '71985',
        quantity: 85,
        price: 11129,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1703 для Подольск, Красота, Замена масла, Бета',
    },
    {
        id: 1704,
        artnumber: '74637',
        barcode: '40023',
        quantity: 81,
        price: 8034,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1704 для Подольск, Красота, Замена масла, Бета',
    },
    {
        id: 1705,
        artnumber: '21078',
        barcode: '14635',
        quantity: 75,
        price: 11308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1705 для Подольск, Красота, Замена масла, Бета',
    },
    {
        id: 1706,
        artnumber: '80110',
        barcode: '32874',
        quantity: 242,
        price: 44810,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1706 для Подольск, Красота, Замена масла, Бета',
    },
    {
        id: 1707,
        artnumber: '46470',
        barcode: '12941',
        quantity: 48,
        price: 4904,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1707 для Подольск, Красота, Замена масла, Гамма',
    },
    {
        id: 1708,
        artnumber: '34144',
        barcode: '70834',
        quantity: 387,
        price: 42736,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1708 для Подольск, Красота, Замена масла, Гамма',
    },
    {
        id: 1709,
        artnumber: '26487',
        barcode: '96613',
        quantity: 16,
        price: 33213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1709 для Подольск, Красота, Замена масла, Гамма',
    },
    {
        id: 1710,
        artnumber: '87185',
        barcode: '44954',
        quantity: 10,
        price: 40946,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1710 для Подольск, Красота, Замена масла, Гамма',
    },
    {
        id: 1711,
        artnumber: '77604',
        barcode: '51721',
        quantity: 182,
        price: 7960,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1711 для Подольск, Красота, Замена масла, Гамма',
    },
    {
        id: 1712,
        artnumber: '63480',
        barcode: '55627',
        quantity: 186,
        price: 25072,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1712 для Подольск, Красота, Замена масла, Гамма',
    },
    {
        id: 1713,
        artnumber: '33360',
        barcode: '18375',
        quantity: 352,
        price: 34645,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1713 для Подольск, Красота, Замена масла, Гамма',
    },
    {
        id: 1714,
        artnumber: '25959',
        barcode: '95578',
        quantity: 86,
        price: 11930,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1714 для Подольск, Красота, Замена масла, Трио',
    },
    {
        id: 1715,
        artnumber: '28508',
        barcode: '63709',
        quantity: 469,
        price: 669,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1715 для Подольск, Красота, Замена масла, Трио',
    },
    {
        id: 1716,
        artnumber: '35635',
        barcode: '46503',
        quantity: 103,
        price: 19784,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1716 для Подольск, Красота, Замена масла, Трио',
    },
    {
        id: 1717,
        artnumber: '81314',
        barcode: '14099',
        quantity: 453,
        price: 43096,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1717 для Подольск, Красота, Замена масла, Трио',
    },
    {
        id: 1718,
        artnumber: '88991',
        barcode: '73165',
        quantity: 200,
        price: 39675,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1718 для Подольск, Красота, Замена масла, Трио',
    },
    {
        id: 1719,
        artnumber: '35629',
        barcode: '36188',
        quantity: 373,
        price: 31399,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1719 для Подольск, Красота, Замена масла, Трио',
    },
    {
        id: 1720,
        artnumber: '33434',
        barcode: '88689',
        quantity: 57,
        price: 16785,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1720 для Подольск, Красота, Замена масла, Трио',
    },
    {
        id: 1721,
        artnumber: '61588',
        barcode: '29833',
        quantity: 63,
        price: 578,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1721 для Подольск, Здоровье, Сервис, Альфа',
    },
    {
        id: 1722,
        artnumber: '63405',
        barcode: '93871',
        quantity: 468,
        price: 38637,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1722 для Подольск, Здоровье, Сервис, Альфа',
    },
    {
        id: 1723,
        artnumber: '26102',
        barcode: '58410',
        quantity: 416,
        price: 39940,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1723 для Подольск, Здоровье, Сервис, Альфа',
    },
    {
        id: 1724,
        artnumber: '59730',
        barcode: '47863',
        quantity: 146,
        price: 30275,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1724 для Подольск, Здоровье, Сервис, Альфа',
    },
    {
        id: 1725,
        artnumber: '24120',
        barcode: '14049',
        quantity: 488,
        price: 5365,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1725 для Подольск, Здоровье, Сервис, Альфа',
    },
    {
        id: 1726,
        artnumber: '55482',
        barcode: '87155',
        quantity: 87,
        price: 6631,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1726 для Подольск, Здоровье, Сервис, Альфа',
    },
    {
        id: 1727,
        artnumber: '82670',
        barcode: '76934',
        quantity: 466,
        price: 20744,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1727 для Подольск, Здоровье, Сервис, Альфа',
    },
    {
        id: 1728,
        artnumber: '21282',
        barcode: '18618',
        quantity: 310,
        price: 3727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1728 для Подольск, Здоровье, Сервис, Альфа',
    },
    {
        id: 1729,
        artnumber: '85185',
        barcode: '16590',
        quantity: 2,
        price: 11059,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1729 для Подольск, Здоровье, Сервис, Альфа',
    },
    {
        id: 1730,
        artnumber: '15721',
        barcode: '16509',
        quantity: 94,
        price: 5041,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1730 для Подольск, Здоровье, Сервис, Альфа',
    },
    {
        id: 1731,
        artnumber: '17578',
        barcode: '15249',
        quantity: 479,
        price: 27679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1731 для Подольск, Здоровье, Сервис, Строй',
    },
    {
        id: 1732,
        artnumber: '12441',
        barcode: '11144',
        quantity: 420,
        price: 21059,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1732 для Подольск, Здоровье, Сервис, Строй',
    },
    {
        id: 1733,
        artnumber: '84505',
        barcode: '41051',
        quantity: 11,
        price: 13543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1733 для Подольск, Здоровье, Сервис, Строй',
    },
    {
        id: 1734,
        artnumber: '75258',
        barcode: '61314',
        quantity: 359,
        price: 38601,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1734 для Подольск, Здоровье, Сервис, Строй',
    },
    {
        id: 1735,
        artnumber: '98895',
        barcode: '45396',
        quantity: 316,
        price: 14463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1735 для Подольск, Здоровье, Сервис, Строй',
    },
    {
        id: 1736,
        artnumber: '21304',
        barcode: '61176',
        quantity: 164,
        price: 23741,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1736 для Подольск, Здоровье, Сервис, Строй',
    },
    {
        id: 1737,
        artnumber: '30250',
        barcode: '74946',
        quantity: 349,
        price: 664,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1737 для Подольск, Здоровье, Сервис, Строй',
    },
    {
        id: 1738,
        artnumber: '42816',
        barcode: '24720',
        quantity: 3,
        price: 26033,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1738 для Подольск, Здоровье, Сервис, Строй',
    },
    {
        id: 1739,
        artnumber: '98450',
        barcode: '63273',
        quantity: 446,
        price: 11953,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1739 для Подольск, Здоровье, Сервис, Строй',
    },
    {
        id: 1740,
        artnumber: '39574',
        barcode: '23600',
        quantity: 22,
        price: 31178,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1740 для Подольск, Здоровье, Сервис, Строй',
    },
    {
        id: 1741,
        artnumber: '23600',
        barcode: '90034',
        quantity: 118,
        price: 34757,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1741 для Подольск, Здоровье, Сервис, Бета',
    },
    {
        id: 1742,
        artnumber: '16518',
        barcode: '46686',
        quantity: 280,
        price: 42777,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1742 для Подольск, Здоровье, Сервис, Бета',
    },
    {
        id: 1743,
        artnumber: '40254',
        barcode: '16934',
        quantity: 27,
        price: 38052,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1743 для Подольск, Здоровье, Сервис, Бета',
    },
    {
        id: 1744,
        artnumber: '31027',
        barcode: '85953',
        quantity: 298,
        price: 2389,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1744 для Подольск, Здоровье, Сервис, Бета',
    },
    {
        id: 1745,
        artnumber: '99393',
        barcode: '25934',
        quantity: 194,
        price: 6103,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1745 для Подольск, Здоровье, Сервис, Бета',
    },
    {
        id: 1746,
        artnumber: '95986',
        barcode: '34974',
        quantity: 151,
        price: 46807,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1746 для Подольск, Здоровье, Сервис, Бета',
    },
    {
        id: 1747,
        artnumber: '55308',
        barcode: '99287',
        quantity: 158,
        price: 14942,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1747 для Подольск, Здоровье, Сервис, Бета',
    },
    {
        id: 1748,
        artnumber: '64398',
        barcode: '98161',
        quantity: 364,
        price: 14110,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1748 для Подольск, Здоровье, Сервис, Бета',
    },
    {
        id: 1749,
        artnumber: '77791',
        barcode: '32807',
        quantity: 95,
        price: 11166,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1749 для Подольск, Здоровье, Сервис, Бета',
    },
    {
        id: 1750,
        artnumber: '84308',
        barcode: '36846',
        quantity: 370,
        price: 7213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1750 для Подольск, Здоровье, Сервис, Бета',
    },
    {
        id: 1751,
        artnumber: '17351',
        barcode: '63096',
        quantity: 43,
        price: 6488,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1751 для Подольск, Здоровье, Сервис, Гамма',
    },
    {
        id: 1752,
        artnumber: '88488',
        barcode: '32932',
        quantity: 258,
        price: 25191,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1752 для Подольск, Здоровье, Сервис, Гамма',
    },
    {
        id: 1753,
        artnumber: '73004',
        barcode: '62269',
        quantity: 211,
        price: 47554,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1753 для Подольск, Здоровье, Сервис, Гамма',
    },
    {
        id: 1754,
        artnumber: '27977',
        barcode: '99455',
        quantity: 5,
        price: 46942,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1754 для Подольск, Здоровье, Сервис, Гамма',
    },
    {
        id: 1755,
        artnumber: '20174',
        barcode: '37841',
        quantity: 76,
        price: 26379,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1755 для Подольск, Здоровье, Сервис, Гамма',
    },
    {
        id: 1756,
        artnumber: '92244',
        barcode: '36625',
        quantity: 392,
        price: 44536,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1756 для Подольск, Здоровье, Сервис, Гамма',
    },
    {
        id: 1757,
        artnumber: '39135',
        barcode: '17798',
        quantity: 403,
        price: 8381,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1757 для Подольск, Здоровье, Сервис, Гамма',
    },
    {
        id: 1758,
        artnumber: '93067',
        barcode: '20239',
        quantity: 306,
        price: 5025,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1758 для Подольск, Здоровье, Сервис, Гамма',
    },
    {
        id: 1759,
        artnumber: '11983',
        barcode: '55366',
        quantity: 48,
        price: 15085,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1759 для Подольск, Здоровье, Сервис, Гамма',
    },
    {
        id: 1760,
        artnumber: '95319',
        barcode: '32410',
        quantity: 370,
        price: 37525,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1760 для Подольск, Здоровье, Сервис, Гамма',
    },
    {
        id: 1761,
        artnumber: '31873',
        barcode: '78695',
        quantity: 192,
        price: 42505,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1761 для Подольск, Здоровье, Сервис, Трио',
    },
    {
        id: 1762,
        artnumber: '68230',
        barcode: '32677',
        quantity: 199,
        price: 39980,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1762 для Подольск, Здоровье, Сервис, Трио',
    },
    {
        id: 1763,
        artnumber: '30214',
        barcode: '42220',
        quantity: 296,
        price: 30424,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1763 для Подольск, Здоровье, Сервис, Трио',
    },
    {
        id: 1764,
        artnumber: '87407',
        barcode: '71983',
        quantity: 147,
        price: 43240,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1764 для Подольск, Здоровье, Сервис, Трио',
    },
    {
        id: 1765,
        artnumber: '99850',
        barcode: '64395',
        quantity: 7,
        price: 35162,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1765 для Подольск, Здоровье, Сервис, Трио',
    },
    {
        id: 1766,
        artnumber: '93834',
        barcode: '66353',
        quantity: 287,
        price: 41018,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1766 для Подольск, Здоровье, Сервис, Трио',
    },
    {
        id: 1767,
        artnumber: '95536',
        barcode: '41540',
        quantity: 303,
        price: 17460,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1767 для Подольск, Здоровье, Сервис, Трио',
    },
    {
        id: 1768,
        artnumber: '79270',
        barcode: '81398',
        quantity: 472,
        price: 33437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1768 для Подольск, Здоровье, Сервис, Трио',
    },
    {
        id: 1769,
        artnumber: '34997',
        barcode: '92468',
        quantity: 210,
        price: 16702,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1769 для Подольск, Здоровье, Сервис, Трио',
    },
    {
        id: 1770,
        artnumber: '81362',
        barcode: '30178',
        quantity: 313,
        price: 20272,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1770 для Подольск, Здоровье, Сервис, Трио',
    },
    {
        id: 1771,
        artnumber: '23817',
        barcode: '38225',
        quantity: 387,
        price: 22726,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1771 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1772,
        artnumber: '30392',
        barcode: '47090',
        quantity: 8,
        price: 11205,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1772 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1773,
        artnumber: '43169',
        barcode: '93259',
        quantity: 124,
        price: 37746,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1773 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1774,
        artnumber: '31104',
        barcode: '84672',
        quantity: 15,
        price: 49083,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1774 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1775,
        artnumber: '43348',
        barcode: '16264',
        quantity: 291,
        price: 41192,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1775 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1776,
        artnumber: '40138',
        barcode: '16105',
        quantity: 334,
        price: 36240,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1776 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1777,
        artnumber: '43215',
        barcode: '20276',
        quantity: 208,
        price: 26253,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1777 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1778,
        artnumber: '24664',
        barcode: '68160',
        quantity: 82,
        price: 31082,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1778 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1779,
        artnumber: '48293',
        barcode: '61906',
        quantity: 479,
        price: 44160,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1779 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1780,
        artnumber: '97351',
        barcode: '58588',
        quantity: 22,
        price: 26726,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1780 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1781,
        artnumber: '93195',
        barcode: '87623',
        quantity: 413,
        price: 40013,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1781 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1782,
        artnumber: '79812',
        barcode: '69534',
        quantity: 116,
        price: 38187,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1782 для Подольск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 1783,
        artnumber: '70051',
        barcode: '82331',
        quantity: 368,
        price: 34775,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1783 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1784,
        artnumber: '78834',
        barcode: '10209',
        quantity: 185,
        price: 23705,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1784 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1785,
        artnumber: '33048',
        barcode: '95972',
        quantity: 462,
        price: 15812,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1785 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1786,
        artnumber: '21408',
        barcode: '40730',
        quantity: 320,
        price: 291,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1786 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1787,
        artnumber: '64469',
        barcode: '55983',
        quantity: 115,
        price: 22653,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1787 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1788,
        artnumber: '89278',
        barcode: '71122',
        quantity: 322,
        price: 44349,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1788 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1789,
        artnumber: '30871',
        barcode: '32516',
        quantity: 155,
        price: 23704,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1789 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1790,
        artnumber: '38257',
        barcode: '32262',
        quantity: 414,
        price: 9780,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1790 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1791,
        artnumber: '32220',
        barcode: '49212',
        quantity: 249,
        price: 39014,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1791 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1792,
        artnumber: '52349',
        barcode: '43872',
        quantity: 377,
        price: 26173,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1792 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1793,
        artnumber: '83698',
        barcode: '75778',
        quantity: 121,
        price: 26472,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1793 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1794,
        artnumber: '14664',
        barcode: '50756',
        quantity: 294,
        price: 28766,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1794 для Подольск, Здоровье, Запчасти, Строй',
    },
    {
        id: 1795,
        artnumber: '53636',
        barcode: '78894',
        quantity: 350,
        price: 7902,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1795 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1796,
        artnumber: '36811',
        barcode: '17117',
        quantity: 94,
        price: 17880,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1796 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1797,
        artnumber: '23812',
        barcode: '42083',
        quantity: 39,
        price: 47515,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1797 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1798,
        artnumber: '89460',
        barcode: '50955',
        quantity: 487,
        price: 21530,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1798 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1799,
        artnumber: '90314',
        barcode: '26691',
        quantity: 293,
        price: 7036,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1799 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1800,
        artnumber: '17096',
        barcode: '35075',
        quantity: 205,
        price: 15626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1800 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1801,
        artnumber: '48324',
        barcode: '91949',
        quantity: 216,
        price: 20808,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1801 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1802,
        artnumber: '31787',
        barcode: '66969',
        quantity: 136,
        price: 11671,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1802 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1803,
        artnumber: '82028',
        barcode: '88952',
        quantity: 194,
        price: 32997,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1803 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1804,
        artnumber: '40950',
        barcode: '97128',
        quantity: 460,
        price: 28013,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1804 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1805,
        artnumber: '10114',
        barcode: '89010',
        quantity: 209,
        price: 23343,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1805 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1806,
        artnumber: '80862',
        barcode: '82667',
        quantity: 255,
        price: 43162,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1806 для Подольск, Здоровье, Запчасти, Бета',
    },
    {
        id: 1807,
        artnumber: '19857',
        barcode: '21378',
        quantity: 145,
        price: 24780,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1807 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1808,
        artnumber: '16029',
        barcode: '36769',
        quantity: 145,
        price: 19278,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1808 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1809,
        artnumber: '12564',
        barcode: '43547',
        quantity: 377,
        price: 2959,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1809 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1810,
        artnumber: '99396',
        barcode: '49390',
        quantity: 227,
        price: 21160,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1810 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1811,
        artnumber: '39722',
        barcode: '81002',
        quantity: 98,
        price: 41102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1811 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1812,
        artnumber: '34500',
        barcode: '73450',
        quantity: 408,
        price: 30559,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1812 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1813,
        artnumber: '65004',
        barcode: '61096',
        quantity: 391,
        price: 4785,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1813 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1814,
        artnumber: '18350',
        barcode: '43789',
        quantity: 127,
        price: 42172,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1814 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1815,
        artnumber: '69611',
        barcode: '14097',
        quantity: 359,
        price: 22299,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1815 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1816,
        artnumber: '62482',
        barcode: '76904',
        quantity: 286,
        price: 28779,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1816 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1817,
        artnumber: '22378',
        barcode: '84409',
        quantity: 324,
        price: 41723,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1817 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1818,
        artnumber: '67730',
        barcode: '31997',
        quantity: 299,
        price: 26488,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1818 для Подольск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 1819,
        artnumber: '31299',
        barcode: '13659',
        quantity: 222,
        price: 24055,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1819 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1820,
        artnumber: '90830',
        barcode: '93856',
        quantity: 69,
        price: 3846,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1820 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1821,
        artnumber: '61077',
        barcode: '84085',
        quantity: 31,
        price: 5056,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1821 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1822,
        artnumber: '89860',
        barcode: '17379',
        quantity: 166,
        price: 22239,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1822 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1823,
        artnumber: '95039',
        barcode: '14476',
        quantity: 245,
        price: 12489,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1823 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1824,
        artnumber: '88470',
        barcode: '49574',
        quantity: 51,
        price: 8652,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1824 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1825,
        artnumber: '47141',
        barcode: '64957',
        quantity: 316,
        price: 39866,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1825 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1826,
        artnumber: '35242',
        barcode: '53226',
        quantity: 264,
        price: 14831,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1826 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1827,
        artnumber: '32494',
        barcode: '10374',
        quantity: 373,
        price: 34174,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1827 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1828,
        artnumber: '68250',
        barcode: '83284',
        quantity: 303,
        price: 33312,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1828 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1829,
        artnumber: '39984',
        barcode: '30812',
        quantity: 426,
        price: 33609,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1829 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1830,
        artnumber: '57259',
        barcode: '38908',
        quantity: 459,
        price: 23263,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1830 для Подольск, Здоровье, Запчасти, Трио',
    },
    {
        id: 1831,
        artnumber: '44484',
        barcode: '26550',
        quantity: 358,
        price: 46152,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1831 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1832,
        artnumber: '28409',
        barcode: '21351',
        quantity: 111,
        price: 32598,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1832 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1833,
        artnumber: '50702',
        barcode: '39807',
        quantity: 364,
        price: 30134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1833 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1834,
        artnumber: '76162',
        barcode: '84732',
        quantity: 260,
        price: 31914,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1834 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1835,
        artnumber: '14207',
        barcode: '23529',
        quantity: 18,
        price: 4247,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1835 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1836,
        artnumber: '66780',
        barcode: '30637',
        quantity: 157,
        price: 44408,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1836 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1837,
        artnumber: '66891',
        barcode: '97710',
        quantity: 126,
        price: 6465,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1837 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1838,
        artnumber: '84363',
        barcode: '94487',
        quantity: 251,
        price: 6554,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1838 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1839,
        artnumber: '60002',
        barcode: '74695',
        quantity: 218,
        price: 24270,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1839 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1840,
        artnumber: '97381',
        barcode: '73420',
        quantity: 413,
        price: 12261,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1840 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1841,
        artnumber: '56911',
        barcode: '96868',
        quantity: 235,
        price: 11133,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1841 для Подольск, Здоровье, Доставка, Альфа',
    },
    {
        id: 1842,
        artnumber: '47736',
        barcode: '47926',
        quantity: 270,
        price: 13814,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1842 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1843,
        artnumber: '71982',
        barcode: '11630',
        quantity: 436,
        price: 27714,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1843 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1844,
        artnumber: '57208',
        barcode: '10688',
        quantity: 443,
        price: 28569,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1844 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1845,
        artnumber: '93283',
        barcode: '36409',
        quantity: 413,
        price: 38902,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1845 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1846,
        artnumber: '85112',
        barcode: '23661',
        quantity: 15,
        price: 6783,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1846 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1847,
        artnumber: '57799',
        barcode: '33349',
        quantity: 299,
        price: 13797,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1847 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1848,
        artnumber: '27018',
        barcode: '67160',
        quantity: 161,
        price: 34662,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1848 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1849,
        artnumber: '91815',
        barcode: '33103',
        quantity: 244,
        price: 4876,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1849 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1850,
        artnumber: '78447',
        barcode: '28065',
        quantity: 298,
        price: 23313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1850 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1851,
        artnumber: '98627',
        barcode: '50209',
        quantity: 146,
        price: 6004,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1851 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1852,
        artnumber: '48565',
        barcode: '88315',
        quantity: 487,
        price: 14052,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1852 для Подольск, Здоровье, Доставка, Строй',
    },
    {
        id: 1853,
        artnumber: '41773',
        barcode: '33656',
        quantity: 350,
        price: 8593,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1853 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1854,
        artnumber: '33387',
        barcode: '72510',
        quantity: 312,
        price: 40077,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1854 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1855,
        artnumber: '14702',
        barcode: '84781',
        quantity: 480,
        price: 12431,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1855 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1856,
        artnumber: '84059',
        barcode: '29047',
        quantity: 21,
        price: 16117,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1856 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1857,
        artnumber: '97542',
        barcode: '32305',
        quantity: 217,
        price: 46962,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1857 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1858,
        artnumber: '23940',
        barcode: '30344',
        quantity: 427,
        price: 49865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1858 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1859,
        artnumber: '61223',
        barcode: '72290',
        quantity: 448,
        price: 33684,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1859 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1860,
        artnumber: '63852',
        barcode: '42383',
        quantity: 333,
        price: 35322,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1860 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1861,
        artnumber: '23641',
        barcode: '29885',
        quantity: 123,
        price: 20040,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1861 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1862,
        artnumber: '49195',
        barcode: '59556',
        quantity: 161,
        price: 8880,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1862 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1863,
        artnumber: '54025',
        barcode: '61280',
        quantity: 165,
        price: 35,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1863 для Подольск, Здоровье, Доставка, Бета',
    },
    {
        id: 1864,
        artnumber: '28447',
        barcode: '33686',
        quantity: 51,
        price: 48017,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1864 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1865,
        artnumber: '98918',
        barcode: '86184',
        quantity: 293,
        price: 39422,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1865 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1866,
        artnumber: '80580',
        barcode: '22123',
        quantity: 99,
        price: 41833,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1866 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1867,
        artnumber: '92985',
        barcode: '47213',
        quantity: 301,
        price: 3545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1867 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1868,
        artnumber: '77127',
        barcode: '22891',
        quantity: 192,
        price: 10150,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1868 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1869,
        artnumber: '18693',
        barcode: '10237',
        quantity: 320,
        price: 22623,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1869 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1870,
        artnumber: '13741',
        barcode: '47601',
        quantity: 338,
        price: 48134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1870 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1871,
        artnumber: '46526',
        barcode: '54301',
        quantity: 497,
        price: 18505,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1871 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1872,
        artnumber: '43222',
        barcode: '88249',
        quantity: 338,
        price: 34054,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1872 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1873,
        artnumber: '52270',
        barcode: '38695',
        quantity: 391,
        price: 45204,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1873 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1874,
        artnumber: '93418',
        barcode: '78572',
        quantity: 479,
        price: 28283,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1874 для Подольск, Здоровье, Доставка, Гамма',
    },
    {
        id: 1875,
        artnumber: '22185',
        barcode: '94272',
        quantity: 149,
        price: 37909,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1875 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1876,
        artnumber: '53909',
        barcode: '12723',
        quantity: 483,
        price: 22437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1876 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1877,
        artnumber: '38704',
        barcode: '57103',
        quantity: 426,
        price: 43607,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1877 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1878,
        artnumber: '41720',
        barcode: '75823',
        quantity: 191,
        price: 31067,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1878 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1879,
        artnumber: '50843',
        barcode: '61823',
        quantity: 163,
        price: 30409,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1879 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1880,
        artnumber: '11281',
        barcode: '67158',
        quantity: 167,
        price: 6815,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1880 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1881,
        artnumber: '91946',
        barcode: '88755',
        quantity: 147,
        price: 28333,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1881 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1882,
        artnumber: '58672',
        barcode: '88736',
        quantity: 203,
        price: 17797,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1882 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1883,
        artnumber: '79672',
        barcode: '96811',
        quantity: 112,
        price: 18454,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1883 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1884,
        artnumber: '91652',
        barcode: '60704',
        quantity: 19,
        price: 14677,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1884 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1885,
        artnumber: '30029',
        barcode: '29155',
        quantity: 176,
        price: 3551,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1885 для Подольск, Здоровье, Доставка, Трио',
    },
    {
        id: 1886,
        artnumber: '39818',
        barcode: '64365',
        quantity: 407,
        price: 45805,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1886 для Подольск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 1887,
        artnumber: '64662',
        barcode: '32632',
        quantity: 460,
        price: 23283,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1887 для Подольск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 1888,
        artnumber: '81920',
        barcode: '86723',
        quantity: 170,
        price: 9308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1888 для Подольск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 1889,
        artnumber: '40739',
        barcode: '97114',
        quantity: 307,
        price: 26301,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1889 для Подольск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 1890,
        artnumber: '13288',
        barcode: '71774',
        quantity: 347,
        price: 40760,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1890 для Подольск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 1891,
        artnumber: '65429',
        barcode: '86198',
        quantity: 45,
        price: 12301,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1891 для Подольск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 1892,
        artnumber: '73523',
        barcode: '68540',
        quantity: 372,
        price: 38220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1892 для Подольск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 1893,
        artnumber: '42106',
        barcode: '35076',
        quantity: 469,
        price: 12082,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1893 для Подольск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 1894,
        artnumber: '12248',
        barcode: '32967',
        quantity: 488,
        price: 6213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1894 для Подольск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 1895,
        artnumber: '34046',
        barcode: '67122',
        quantity: 113,
        price: 30616,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1895 для Подольск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 1896,
        artnumber: '75790',
        barcode: '60004',
        quantity: 143,
        price: 31305,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1896 для Подольск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 1897,
        artnumber: '94112',
        barcode: '35420',
        quantity: 353,
        price: 6417,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1897 для Подольск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 1898,
        artnumber: '83534',
        barcode: '59716',
        quantity: 142,
        price: 31638,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1898 для Подольск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 1899,
        artnumber: '71192',
        barcode: '38450',
        quantity: 79,
        price: 36936,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1899 для Подольск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 1900,
        artnumber: '21029',
        barcode: '72718',
        quantity: 456,
        price: 7500,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1900 для Подольск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 1901,
        artnumber: '32946',
        barcode: '61857',
        quantity: 228,
        price: 18151,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1901 для Подольск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 1902,
        artnumber: '15803',
        barcode: '11871',
        quantity: 210,
        price: 36685,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1902 для Подольск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 1903,
        artnumber: '57026',
        barcode: '95261',
        quantity: 407,
        price: 34382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1903 для Подольск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 1904,
        artnumber: '32684',
        barcode: '88406',
        quantity: 290,
        price: 23144,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1904 для Подольск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 1905,
        artnumber: '49180',
        barcode: '80119',
        quantity: 295,
        price: 21993,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1905 для Подольск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 1906,
        artnumber: '49394',
        barcode: '89919',
        quantity: 188,
        price: 32305,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1906 для Подольск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 1907,
        artnumber: '52884',
        barcode: '71811',
        quantity: 108,
        price: 12574,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1907 для Подольск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 1908,
        artnumber: '24141',
        barcode: '25731',
        quantity: 435,
        price: 42643,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1908 для Подольск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 1909,
        artnumber: '39185',
        barcode: '93268',
        quantity: 88,
        price: 33191,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1909 для Подольск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 1910,
        artnumber: '95117',
        barcode: '31108',
        quantity: 124,
        price: 20574,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1910 для Подольск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 1911,
        artnumber: '63221',
        barcode: '39252',
        quantity: 25,
        price: 35298,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1911 для Подольск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 1912,
        artnumber: '62136',
        barcode: '20961',
        quantity: 191,
        price: 29854,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1912 для Подольск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 1913,
        artnumber: '88415',
        barcode: '17248',
        quantity: 471,
        price: 20205,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1913 для Подольск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 1914,
        artnumber: '18987',
        barcode: '24903',
        quantity: 247,
        price: 42879,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1914 для Подольск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 1915,
        artnumber: '47822',
        barcode: '93800',
        quantity: 329,
        price: 5841,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1915 для Подольск, Здоровье, Замена масла, Строй',
    },
    {
        id: 1916,
        artnumber: '79688',
        barcode: '14044',
        quantity: 329,
        price: 1231,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1916 для Подольск, Здоровье, Замена масла, Строй',
    },
    {
        id: 1917,
        artnumber: '33011',
        barcode: '48600',
        quantity: 194,
        price: 25630,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1917 для Подольск, Здоровье, Замена масла, Строй',
    },
    {
        id: 1918,
        artnumber: '83531',
        barcode: '83597',
        quantity: 111,
        price: 4584,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1918 для Подольск, Здоровье, Замена масла, Строй',
    },
    {
        id: 1919,
        artnumber: '74157',
        barcode: '46461',
        quantity: 64,
        price: 38611,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1919 для Подольск, Здоровье, Замена масла, Бета',
    },
    {
        id: 1920,
        artnumber: '46108',
        barcode: '19336',
        quantity: 175,
        price: 5240,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1920 для Подольск, Здоровье, Замена масла, Бета',
    },
    {
        id: 1921,
        artnumber: '61611',
        barcode: '17786',
        quantity: 94,
        price: 9249,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1921 для Подольск, Здоровье, Замена масла, Бета',
    },
    {
        id: 1922,
        artnumber: '39966',
        barcode: '91366',
        quantity: 415,
        price: 1691,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1922 для Подольск, Здоровье, Замена масла, Бета',
    },
    {
        id: 1923,
        artnumber: '46826',
        barcode: '90737',
        quantity: 170,
        price: 9037,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1923 для Подольск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 1924,
        artnumber: '69916',
        barcode: '15951',
        quantity: 67,
        price: 6333,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1924 для Подольск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 1925,
        artnumber: '88623',
        barcode: '46538',
        quantity: 419,
        price: 1331,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1925 для Подольск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 1926,
        artnumber: '27484',
        barcode: '67194',
        quantity: 56,
        price: 122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1926 для Подольск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 1927,
        artnumber: '53152',
        barcode: '85714',
        quantity: 71,
        price: 21256,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1927 для Подольск, Здоровье, Замена масла, Трио',
    },
    {
        id: 1928,
        artnumber: '60613',
        barcode: '32382',
        quantity: 367,
        price: 44984,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1928 для Подольск, Здоровье, Замена масла, Трио',
    },
    {
        id: 1929,
        artnumber: '58736',
        barcode: '32439',
        quantity: 273,
        price: 34221,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1929 для Подольск, Здоровье, Замена масла, Трио',
    },
    {
        id: 1930,
        artnumber: '35137',
        barcode: '94608',
        quantity: 400,
        price: 5045,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1930 для Подольск, Здоровье, Замена масла, Трио',
    },
    {
        id: 1931,
        artnumber: '72442',
        barcode: '92956',
        quantity: 467,
        price: 21896,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1931 для Подольск, Техника, Сервис, Альфа',
    },
    {
        id: 1932,
        artnumber: '83355',
        barcode: '44522',
        quantity: 473,
        price: 1012,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1932 для Подольск, Техника, Сервис, Альфа',
    },
    {
        id: 1933,
        artnumber: '31641',
        barcode: '24056',
        quantity: 378,
        price: 14811,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1933 для Подольск, Техника, Сервис, Альфа',
    },
    {
        id: 1934,
        artnumber: '18781',
        barcode: '16240',
        quantity: 316,
        price: 5815,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1934 для Подольск, Техника, Сервис, Альфа',
    },
    {
        id: 1935,
        artnumber: '74355',
        barcode: '99453',
        quantity: 287,
        price: 37121,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1935 для Подольск, Техника, Сервис, Альфа',
    },
    {
        id: 1936,
        artnumber: '41966',
        barcode: '77817',
        quantity: 274,
        price: 9280,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1936 для Подольск, Техника, Сервис, Альфа',
    },
    {
        id: 1937,
        artnumber: '86396',
        barcode: '56722',
        quantity: 37,
        price: 28644,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1937 для Подольск, Техника, Сервис, Альфа',
    },
    {
        id: 1938,
        artnumber: '81925',
        barcode: '55850',
        quantity: 252,
        price: 18578,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1938 для Подольск, Техника, Сервис, Строй',
    },
    {
        id: 1939,
        artnumber: '39077',
        barcode: '78780',
        quantity: 384,
        price: 43249,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1939 для Подольск, Техника, Сервис, Строй',
    },
    {
        id: 1940,
        artnumber: '50501',
        barcode: '95203',
        quantity: 244,
        price: 32060,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1940 для Подольск, Техника, Сервис, Строй',
    },
    {
        id: 1941,
        artnumber: '98610',
        barcode: '78713',
        quantity: 247,
        price: 34382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1941 для Подольск, Техника, Сервис, Строй',
    },
    {
        id: 1942,
        artnumber: '73611',
        barcode: '82783',
        quantity: 98,
        price: 16873,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1942 для Подольск, Техника, Сервис, Строй',
    },
    {
        id: 1943,
        artnumber: '92077',
        barcode: '70784',
        quantity: 407,
        price: 38455,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1943 для Подольск, Техника, Сервис, Строй',
    },
    {
        id: 1944,
        artnumber: '59082',
        barcode: '39795',
        quantity: 393,
        price: 29244,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1944 для Подольск, Техника, Сервис, Строй',
    },
    {
        id: 1945,
        artnumber: '85453',
        barcode: '60727',
        quantity: 72,
        price: 10879,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1945 для Подольск, Техника, Сервис, Бета',
    },
    {
        id: 1946,
        artnumber: '37926',
        barcode: '81678',
        quantity: 167,
        price: 19613,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1946 для Подольск, Техника, Сервис, Бета',
    },
    {
        id: 1947,
        artnumber: '37843',
        barcode: '50906',
        quantity: 422,
        price: 16033,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1947 для Подольск, Техника, Сервис, Бета',
    },
    {
        id: 1948,
        artnumber: '32192',
        barcode: '45916',
        quantity: 302,
        price: 10716,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1948 для Подольск, Техника, Сервис, Бета',
    },
    {
        id: 1949,
        artnumber: '41789',
        barcode: '27369',
        quantity: 324,
        price: 45651,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1949 для Подольск, Техника, Сервис, Бета',
    },
    {
        id: 1950,
        artnumber: '58512',
        barcode: '29790',
        quantity: 281,
        price: 25290,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1950 для Подольск, Техника, Сервис, Бета',
    },
    {
        id: 1951,
        artnumber: '37383',
        barcode: '30658',
        quantity: 369,
        price: 3886,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1951 для Подольск, Техника, Сервис, Бета',
    },
    {
        id: 1952,
        artnumber: '15675',
        barcode: '85468',
        quantity: 335,
        price: 7037,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1952 для Подольск, Техника, Сервис, Гамма',
    },
    {
        id: 1953,
        artnumber: '77577',
        barcode: '48931',
        quantity: 312,
        price: 3935,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1953 для Подольск, Техника, Сервис, Гамма',
    },
    {
        id: 1954,
        artnumber: '66271',
        barcode: '81737',
        quantity: 156,
        price: 27917,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1954 для Подольск, Техника, Сервис, Гамма',
    },
    {
        id: 1955,
        artnumber: '51276',
        barcode: '17479',
        quantity: 404,
        price: 45724,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1955 для Подольск, Техника, Сервис, Гамма',
    },
    {
        id: 1956,
        artnumber: '28148',
        barcode: '11917',
        quantity: 42,
        price: 1850,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1956 для Подольск, Техника, Сервис, Гамма',
    },
    {
        id: 1957,
        artnumber: '92091',
        barcode: '48112',
        quantity: 338,
        price: 1416,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1957 для Подольск, Техника, Сервис, Гамма',
    },
    {
        id: 1958,
        artnumber: '72965',
        barcode: '14474',
        quantity: 450,
        price: 4772,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1958 для Подольск, Техника, Сервис, Гамма',
    },
    {
        id: 1959,
        artnumber: '82451',
        barcode: '22878',
        quantity: 73,
        price: 35853,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1959 для Подольск, Техника, Сервис, Трио',
    },
    {
        id: 1960,
        artnumber: '61105',
        barcode: '83840',
        quantity: 192,
        price: 6105,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1960 для Подольск, Техника, Сервис, Трио',
    },
    {
        id: 1961,
        artnumber: '93947',
        barcode: '75560',
        quantity: 441,
        price: 21183,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1961 для Подольск, Техника, Сервис, Трио',
    },
    {
        id: 1962,
        artnumber: '82085',
        barcode: '59421',
        quantity: 252,
        price: 13528,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1962 для Подольск, Техника, Сервис, Трио',
    },
    {
        id: 1963,
        artnumber: '38530',
        barcode: '51643',
        quantity: 467,
        price: 36803,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1963 для Подольск, Техника, Сервис, Трио',
    },
    {
        id: 1964,
        artnumber: '64259',
        barcode: '83331',
        quantity: 354,
        price: 11220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1964 для Подольск, Техника, Сервис, Трио',
    },
    {
        id: 1965,
        artnumber: '52435',
        barcode: '35407',
        quantity: 405,
        price: 18105,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1965 для Подольск, Техника, Сервис, Трио',
    },
    {
        id: 1966,
        artnumber: '22180',
        barcode: '19599',
        quantity: 459,
        price: 39061,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1966 для Подольск, Техника, Запчасти, Альфа',
    },
    {
        id: 1967,
        artnumber: '59866',
        barcode: '49409',
        quantity: 196,
        price: 37407,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1967 для Подольск, Техника, Запчасти, Альфа',
    },
    {
        id: 1968,
        artnumber: '86321',
        barcode: '90364',
        quantity: 222,
        price: 18866,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1968 для Подольск, Техника, Запчасти, Альфа',
    },
    {
        id: 1969,
        artnumber: '65842',
        barcode: '66010',
        quantity: 434,
        price: 46389,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1969 для Подольск, Техника, Запчасти, Альфа',
    },
    {
        id: 1970,
        artnumber: '55515',
        barcode: '71711',
        quantity: 252,
        price: 44236,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1970 для Подольск, Техника, Запчасти, Альфа',
    },
    {
        id: 1971,
        artnumber: '14003',
        barcode: '79492',
        quantity: 324,
        price: 39556,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1971 для Подольск, Техника, Запчасти, Альфа',
    },
    {
        id: 1972,
        artnumber: '46959',
        barcode: '40356',
        quantity: 387,
        price: 32067,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1972 для Подольск, Техника, Запчасти, Строй',
    },
    {
        id: 1973,
        artnumber: '36336',
        barcode: '63829',
        quantity: 266,
        price: 13233,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1973 для Подольск, Техника, Запчасти, Строй',
    },
    {
        id: 1974,
        artnumber: '68598',
        barcode: '35384',
        quantity: 126,
        price: 31071,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1974 для Подольск, Техника, Запчасти, Строй',
    },
    {
        id: 1975,
        artnumber: '10000',
        barcode: '48108',
        quantity: 471,
        price: 3892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 1975 для Подольск, Техника, Запчасти, Строй',
    },
    {
        id: 1976,
        artnumber: '93460',
        barcode: '59282',
        quantity: 184,
        price: 44108,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1976 для Подольск, Техника, Запчасти, Строй',
    },
    {
        id: 1977,
        artnumber: '96030',
        barcode: '68992',
        quantity: 52,
        price: 23581,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1977 для Подольск, Техника, Запчасти, Строй',
    },
    {
        id: 1978,
        artnumber: '16663',
        barcode: '83048',
        quantity: 332,
        price: 21083,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1978 для Подольск, Техника, Запчасти, Бета',
    },
    {
        id: 1979,
        artnumber: '15698',
        barcode: '32201',
        quantity: 260,
        price: 48591,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1979 для Подольск, Техника, Запчасти, Бета',
    },
    {
        id: 1980,
        artnumber: '51645',
        barcode: '15217',
        quantity: 474,
        price: 11056,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1980 для Подольск, Техника, Запчасти, Бета',
    },
    {
        id: 1981,
        artnumber: '56676',
        barcode: '50251',
        quantity: 323,
        price: 3954,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 1981 для Подольск, Техника, Запчасти, Бета',
    },
    {
        id: 1982,
        artnumber: '71228',
        barcode: '84330',
        quantity: 472,
        price: 13247,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1982 для Подольск, Техника, Запчасти, Бета',
    },
    {
        id: 1983,
        artnumber: '22070',
        barcode: '52542',
        quantity: 16,
        price: 4571,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1983 для Подольск, Техника, Запчасти, Бета',
    },
    {
        id: 1984,
        artnumber: '87657',
        barcode: '82064',
        quantity: 349,
        price: 21235,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 1984 для Подольск, Техника, Запчасти, Гамма',
    },
    {
        id: 1985,
        artnumber: '38575',
        barcode: '64957',
        quantity: 49,
        price: 33909,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1985 для Подольск, Техника, Запчасти, Гамма',
    },
    {
        id: 1986,
        artnumber: '17284',
        barcode: '80591',
        quantity: 446,
        price: 19229,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1986 для Подольск, Техника, Запчасти, Гамма',
    },
    {
        id: 1987,
        artnumber: '65422',
        barcode: '10271',
        quantity: 185,
        price: 35874,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1987 для Подольск, Техника, Запчасти, Гамма',
    },
    {
        id: 1988,
        artnumber: '87691',
        barcode: '36928',
        quantity: 121,
        price: 17881,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1988 для Подольск, Техника, Запчасти, Гамма',
    },
    {
        id: 1989,
        artnumber: '72409',
        barcode: '45735',
        quantity: 295,
        price: 16989,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 1989 для Подольск, Техника, Запчасти, Гамма',
    },
    {
        id: 1990,
        artnumber: '68521',
        barcode: '64071',
        quantity: 473,
        price: 4174,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1990 для Подольск, Техника, Запчасти, Трио',
    },
    {
        id: 1991,
        artnumber: '40617',
        barcode: '33743',
        quantity: 268,
        price: 10388,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1991 для Подольск, Техника, Запчасти, Трио',
    },
    {
        id: 1992,
        artnumber: '66403',
        barcode: '51179',
        quantity: 386,
        price: 1126,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1992 для Подольск, Техника, Запчасти, Трио',
    },
    {
        id: 1993,
        artnumber: '88453',
        barcode: '77268',
        quantity: 426,
        price: 49630,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 1993 для Подольск, Техника, Запчасти, Трио',
    },
    {
        id: 1994,
        artnumber: '76750',
        barcode: '71659',
        quantity: 226,
        price: 3851,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1994 для Подольск, Техника, Запчасти, Трио',
    },
    {
        id: 1995,
        artnumber: '60735',
        barcode: '17689',
        quantity: 299,
        price: 40368,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 1995 для Подольск, Техника, Запчасти, Трио',
    },
    {
        id: 1996,
        artnumber: '35029',
        barcode: '38178',
        quantity: 373,
        price: 35347,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1996 для Подольск, Техника, Доставка, Альфа',
    },
    {
        id: 1997,
        artnumber: '17771',
        barcode: '26403',
        quantity: 401,
        price: 40547,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 1997 для Подольск, Техника, Доставка, Альфа',
    },
    {
        id: 1998,
        artnumber: '21928',
        barcode: '36916',
        quantity: 495,
        price: 10433,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 1998 для Подольск, Техника, Доставка, Альфа',
    },
    {
        id: 1999,
        artnumber: '79674',
        barcode: '57213',
        quantity: 116,
        price: 39412,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 1999 для Подольск, Техника, Доставка, Альфа',
    },
    {
        id: 2000,
        artnumber: '52632',
        barcode: '52003',
        quantity: 168,
        price: 1782,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2000 для Подольск, Техника, Доставка, Альфа',
    },
    {
        id: 2001,
        artnumber: '52778',
        barcode: '93990',
        quantity: 461,
        price: 7820,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2001 для Подольск, Техника, Доставка, Альфа',
    },
    {
        id: 2002,
        artnumber: '35938',
        barcode: '91742',
        quantity: 359,
        price: 42228,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2002 для Подольск, Техника, Доставка, Альфа',
    },
    {
        id: 2003,
        artnumber: '60519',
        barcode: '84802',
        quantity: 322,
        price: 32613,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2003 для Подольск, Техника, Доставка, Строй',
    },
    {
        id: 2004,
        artnumber: '89290',
        barcode: '55503',
        quantity: 311,
        price: 7406,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2004 для Подольск, Техника, Доставка, Строй',
    },
    {
        id: 2005,
        artnumber: '34721',
        barcode: '98664',
        quantity: 230,
        price: 31177,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2005 для Подольск, Техника, Доставка, Строй',
    },
    {
        id: 2006,
        artnumber: '35220',
        barcode: '59589',
        quantity: 326,
        price: 44545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2006 для Подольск, Техника, Доставка, Строй',
    },
    {
        id: 2007,
        artnumber: '42855',
        barcode: '33458',
        quantity: 102,
        price: 43104,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2007 для Подольск, Техника, Доставка, Строй',
    },
    {
        id: 2008,
        artnumber: '28835',
        barcode: '95536',
        quantity: 90,
        price: 30428,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2008 для Подольск, Техника, Доставка, Строй',
    },
    {
        id: 2009,
        artnumber: '69826',
        barcode: '96740',
        quantity: 440,
        price: 17959,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2009 для Подольск, Техника, Доставка, Строй',
    },
    {
        id: 2010,
        artnumber: '89091',
        barcode: '86325',
        quantity: 226,
        price: 9412,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2010 для Подольск, Техника, Доставка, Бета',
    },
    {
        id: 2011,
        artnumber: '68545',
        barcode: '76002',
        quantity: 484,
        price: 37703,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2011 для Подольск, Техника, Доставка, Бета',
    },
    {
        id: 2012,
        artnumber: '55598',
        barcode: '59846',
        quantity: 105,
        price: 22296,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2012 для Подольск, Техника, Доставка, Бета',
    },
    {
        id: 2013,
        artnumber: '44040',
        barcode: '89121',
        quantity: 149,
        price: 25035,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2013 для Подольск, Техника, Доставка, Бета',
    },
    {
        id: 2014,
        artnumber: '38076',
        barcode: '39655',
        quantity: 333,
        price: 13666,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2014 для Подольск, Техника, Доставка, Бета',
    },
    {
        id: 2015,
        artnumber: '11886',
        barcode: '45561',
        quantity: 499,
        price: 18911,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2015 для Подольск, Техника, Доставка, Бета',
    },
    {
        id: 2016,
        artnumber: '32097',
        barcode: '81602',
        quantity: 320,
        price: 19817,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2016 для Подольск, Техника, Доставка, Бета',
    },
    {
        id: 2017,
        artnumber: '24508',
        barcode: '33081',
        quantity: 387,
        price: 36771,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2017 для Подольск, Техника, Доставка, Гамма',
    },
    {
        id: 2018,
        artnumber: '34186',
        barcode: '42589',
        quantity: 275,
        price: 31295,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2018 для Подольск, Техника, Доставка, Гамма',
    },
    {
        id: 2019,
        artnumber: '90361',
        barcode: '46736',
        quantity: 386,
        price: 28138,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2019 для Подольск, Техника, Доставка, Гамма',
    },
    {
        id: 2020,
        artnumber: '37907',
        barcode: '65787',
        quantity: 92,
        price: 23224,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2020 для Подольск, Техника, Доставка, Гамма',
    },
    {
        id: 2021,
        artnumber: '90678',
        barcode: '66887',
        quantity: 208,
        price: 46202,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2021 для Подольск, Техника, Доставка, Гамма',
    },
    {
        id: 2022,
        artnumber: '30527',
        barcode: '72371',
        quantity: 465,
        price: 40473,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2022 для Подольск, Техника, Доставка, Гамма',
    },
    {
        id: 2023,
        artnumber: '98263',
        barcode: '49803',
        quantity: 155,
        price: 4670,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2023 для Подольск, Техника, Доставка, Гамма',
    },
    {
        id: 2024,
        artnumber: '27398',
        barcode: '18845',
        quantity: 239,
        price: 41775,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2024 для Подольск, Техника, Доставка, Трио',
    },
    {
        id: 2025,
        artnumber: '10843',
        barcode: '46355',
        quantity: 133,
        price: 15469,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2025 для Подольск, Техника, Доставка, Трио',
    },
    {
        id: 2026,
        artnumber: '51101',
        barcode: '23761',
        quantity: 137,
        price: 7729,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2026 для Подольск, Техника, Доставка, Трио',
    },
    {
        id: 2027,
        artnumber: '48066',
        barcode: '34496',
        quantity: 324,
        price: 36755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2027 для Подольск, Техника, Доставка, Трио',
    },
    {
        id: 2028,
        artnumber: '23653',
        barcode: '37505',
        quantity: 465,
        price: 49470,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2028 для Подольск, Техника, Доставка, Трио',
    },
    {
        id: 2029,
        artnumber: '42883',
        barcode: '66045',
        quantity: 484,
        price: 40995,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2029 для Подольск, Техника, Доставка, Трио',
    },
    {
        id: 2030,
        artnumber: '66272',
        barcode: '97506',
        quantity: 466,
        price: 6921,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2030 для Подольск, Техника, Доставка, Трио',
    },
    {
        id: 2031,
        artnumber: '26119',
        barcode: '84359',
        quantity: 80,
        price: 19042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2031 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2032,
        artnumber: '37175',
        barcode: '32263',
        quantity: 144,
        price: 18294,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2032 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2033,
        artnumber: '86888',
        barcode: '78128',
        quantity: 19,
        price: 1476,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2033 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2034,
        artnumber: '54562',
        barcode: '34074',
        quantity: 460,
        price: 10867,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2034 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2035,
        artnumber: '73056',
        barcode: '95569',
        quantity: 178,
        price: 32740,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2035 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2036,
        artnumber: '88989',
        barcode: '74617',
        quantity: 322,
        price: 43796,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2036 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2037,
        artnumber: '70321',
        barcode: '71818',
        quantity: 303,
        price: 19726,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2037 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2038,
        artnumber: '72873',
        barcode: '20090',
        quantity: 153,
        price: 3484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2038 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2039,
        artnumber: '91611',
        barcode: '66997',
        quantity: 254,
        price: 9607,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2039 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2040,
        artnumber: '53001',
        barcode: '32199',
        quantity: 253,
        price: 27327,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2040 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2041,
        artnumber: '34479',
        barcode: '70929',
        quantity: 492,
        price: 1978,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2041 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2042,
        artnumber: '39270',
        barcode: '48892',
        quantity: 208,
        price: 4459,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2042 для Подольск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 2043,
        artnumber: '23510',
        barcode: '80220',
        quantity: 190,
        price: 5333,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2043 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2044,
        artnumber: '51047',
        barcode: '44173',
        quantity: 404,
        price: 27918,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2044 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2045,
        artnumber: '53237',
        barcode: '27996',
        quantity: 422,
        price: 46862,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2045 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2046,
        artnumber: '88827',
        barcode: '57806',
        quantity: 448,
        price: 49146,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2046 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2047,
        artnumber: '58387',
        barcode: '97772',
        quantity: 296,
        price: 21365,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2047 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2048,
        artnumber: '24208',
        barcode: '57692',
        quantity: 454,
        price: 22404,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2048 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2049,
        artnumber: '20930',
        barcode: '40539',
        quantity: 364,
        price: 48170,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2049 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2050,
        artnumber: '76079',
        barcode: '12136',
        quantity: 428,
        price: 8050,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2050 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2051,
        artnumber: '87845',
        barcode: '11375',
        quantity: 224,
        price: 36467,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2051 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2052,
        artnumber: '36450',
        barcode: '48553',
        quantity: 288,
        price: 4804,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2052 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2053,
        artnumber: '86798',
        barcode: '54980',
        quantity: 235,
        price: 21863,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2053 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2054,
        artnumber: '79702',
        barcode: '22132',
        quantity: 416,
        price: 34500,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2054 для Подольск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 2055,
        artnumber: '59853',
        barcode: '96804',
        quantity: 358,
        price: 30034,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2055 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2056,
        artnumber: '88741',
        barcode: '37150',
        quantity: 334,
        price: 43073,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2056 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2057,
        artnumber: '86181',
        barcode: '32245',
        quantity: 398,
        price: 43310,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2057 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2058,
        artnumber: '10629',
        barcode: '29422',
        quantity: 494,
        price: 14457,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2058 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2059,
        artnumber: '42530',
        barcode: '63457',
        quantity: 402,
        price: 37107,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2059 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2060,
        artnumber: '25819',
        barcode: '75959',
        quantity: 490,
        price: 12047,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2060 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2061,
        artnumber: '56888',
        barcode: '33893',
        quantity: 93,
        price: 239,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2061 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2062,
        artnumber: '51812',
        barcode: '49345',
        quantity: 360,
        price: 46409,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2062 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2063,
        artnumber: '21729',
        barcode: '89248',
        quantity: 236,
        price: 37865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2063 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2064,
        artnumber: '75808',
        barcode: '76568',
        quantity: 464,
        price: 7576,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2064 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2065,
        artnumber: '28996',
        barcode: '59714',
        quantity: 301,
        price: 23755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2065 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2066,
        artnumber: '87156',
        barcode: '91898',
        quantity: 263,
        price: 40452,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2066 для Подольск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 2067,
        artnumber: '96428',
        barcode: '77601',
        quantity: 482,
        price: 32035,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2067 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2068,
        artnumber: '70772',
        barcode: '35676',
        quantity: 35,
        price: 48102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2068 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2069,
        artnumber: '17313',
        barcode: '34463',
        quantity: 132,
        price: 49118,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2069 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2070,
        artnumber: '63318',
        barcode: '16057',
        quantity: 405,
        price: 3845,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2070 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2071,
        artnumber: '73271',
        barcode: '36955',
        quantity: 319,
        price: 158,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2071 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2072,
        artnumber: '62104',
        barcode: '94228',
        quantity: 79,
        price: 11395,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2072 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2073,
        artnumber: '64999',
        barcode: '65615',
        quantity: 123,
        price: 38200,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2073 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2074,
        artnumber: '61600',
        barcode: '61307',
        quantity: 436,
        price: 6102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2074 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2075,
        artnumber: '95890',
        barcode: '37263',
        quantity: 110,
        price: 35377,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2075 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2076,
        artnumber: '67287',
        barcode: '78081',
        quantity: 74,
        price: 38498,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2076 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2077,
        artnumber: '84645',
        barcode: '31697',
        quantity: 329,
        price: 14387,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2077 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2078,
        artnumber: '13188',
        barcode: '33486',
        quantity: 455,
        price: 41828,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2078 для Подольск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 2079,
        artnumber: '36808',
        barcode: '31009',
        quantity: 285,
        price: 47210,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2079 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2080,
        artnumber: '33571',
        barcode: '84484',
        quantity: 188,
        price: 22034,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2080 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2081,
        artnumber: '92276',
        barcode: '31285',
        quantity: 340,
        price: 5092,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2081 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2082,
        artnumber: '94976',
        barcode: '40495',
        quantity: 482,
        price: 44190,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2082 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2083,
        artnumber: '97692',
        barcode: '52329',
        quantity: 106,
        price: 39461,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2083 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2084,
        artnumber: '94440',
        barcode: '48888',
        quantity: 107,
        price: 18557,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2084 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2085,
        artnumber: '85841',
        barcode: '24335',
        quantity: 11,
        price: 9157,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2085 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2086,
        artnumber: '55420',
        barcode: '73639',
        quantity: 394,
        price: 432,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2086 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2087,
        artnumber: '84770',
        barcode: '89804',
        quantity: 495,
        price: 23106,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2087 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2088,
        artnumber: '52556',
        barcode: '52808',
        quantity: 36,
        price: 42388,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2088 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2089,
        artnumber: '45601',
        barcode: '52839',
        quantity: 2,
        price: 1971,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2089 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2090,
        artnumber: '26431',
        barcode: '45071',
        quantity: 266,
        price: 43896,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2090 для Подольск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 2091,
        artnumber: '77908',
        barcode: '52972',
        quantity: 6,
        price: 31972,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2091 для Подольск, Техника, Замена масла, Альфа',
    },
    {
        id: 2092,
        artnumber: '61140',
        barcode: '73837',
        quantity: 454,
        price: 17032,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2092 для Подольск, Техника, Замена масла, Альфа',
    },
    {
        id: 2093,
        artnumber: '53746',
        barcode: '42434',
        quantity: 323,
        price: 22310,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2093 для Подольск, Техника, Замена масла, Альфа',
    },
    {
        id: 2094,
        artnumber: '57053',
        barcode: '46568',
        quantity: 58,
        price: 35304,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2094 для Подольск, Техника, Замена масла, Альфа',
    },
    {
        id: 2095,
        artnumber: '83435',
        barcode: '79149',
        quantity: 291,
        price: 46639,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2095 для Подольск, Техника, Замена масла, Альфа',
    },
    {
        id: 2096,
        artnumber: '44194',
        barcode: '42598',
        quantity: 86,
        price: 20861,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2096 для Подольск, Техника, Замена масла, Альфа',
    },
    {
        id: 2097,
        artnumber: '52878',
        barcode: '61596',
        quantity: 64,
        price: 39936,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2097 для Подольск, Техника, Замена масла, Альфа',
    },
    {
        id: 2098,
        artnumber: '96451',
        barcode: '17011',
        quantity: 36,
        price: 26750,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2098 для Подольск, Техника, Замена масла, Альфа',
    },
    {
        id: 2099,
        artnumber: '18415',
        barcode: '80950',
        quantity: 297,
        price: 40560,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2099 для Подольск, Техника, Замена масла, Строй',
    },
    {
        id: 2100,
        artnumber: '42641',
        barcode: '77754',
        quantity: 312,
        price: 13690,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2100 для Подольск, Техника, Замена масла, Строй',
    },
    {
        id: 2101,
        artnumber: '52283',
        barcode: '22250',
        quantity: 436,
        price: 42270,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2101 для Подольск, Техника, Замена масла, Строй',
    },
    {
        id: 2102,
        artnumber: '35552',
        barcode: '84228',
        quantity: 192,
        price: 15295,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2102 для Подольск, Техника, Замена масла, Строй',
    },
    {
        id: 2103,
        artnumber: '60109',
        barcode: '68524',
        quantity: 293,
        price: 19561,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2103 для Подольск, Техника, Замена масла, Строй',
    },
    {
        id: 2104,
        artnumber: '83694',
        barcode: '87831',
        quantity: 37,
        price: 11484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2104 для Подольск, Техника, Замена масла, Строй',
    },
    {
        id: 2105,
        artnumber: '37491',
        barcode: '28267',
        quantity: 37,
        price: 29937,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2105 для Подольск, Техника, Замена масла, Строй',
    },
    {
        id: 2106,
        artnumber: '31793',
        barcode: '46958',
        quantity: 63,
        price: 12527,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2106 для Подольск, Техника, Замена масла, Строй',
    },
    {
        id: 2107,
        artnumber: '24721',
        barcode: '69059',
        quantity: 216,
        price: 12754,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2107 для Подольск, Техника, Замена масла, Бета',
    },
    {
        id: 2108,
        artnumber: '98074',
        barcode: '27716',
        quantity: 219,
        price: 25457,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2108 для Подольск, Техника, Замена масла, Бета',
    },
    {
        id: 2109,
        artnumber: '44873',
        barcode: '21591',
        quantity: 162,
        price: 24882,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2109 для Подольск, Техника, Замена масла, Бета',
    },
    {
        id: 2110,
        artnumber: '61275',
        barcode: '37823',
        quantity: 335,
        price: 29934,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2110 для Подольск, Техника, Замена масла, Бета',
    },
    {
        id: 2111,
        artnumber: '66922',
        barcode: '21224',
        quantity: 404,
        price: 6984,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2111 для Подольск, Техника, Замена масла, Бета',
    },
    {
        id: 2112,
        artnumber: '81022',
        barcode: '25877',
        quantity: 110,
        price: 32533,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2112 для Подольск, Техника, Замена масла, Бета',
    },
    {
        id: 2113,
        artnumber: '63116',
        barcode: '55279',
        quantity: 268,
        price: 3283,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2113 для Подольск, Техника, Замена масла, Бета',
    },
    {
        id: 2114,
        artnumber: '12209',
        barcode: '26480',
        quantity: 220,
        price: 8019,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2114 для Подольск, Техника, Замена масла, Бета',
    },
    {
        id: 2115,
        artnumber: '75110',
        barcode: '81141',
        quantity: 481,
        price: 6212,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2115 для Подольск, Техника, Замена масла, Гамма',
    },
    {
        id: 2116,
        artnumber: '27746',
        barcode: '80749',
        quantity: 296,
        price: 44532,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2116 для Подольск, Техника, Замена масла, Гамма',
    },
    {
        id: 2117,
        artnumber: '88107',
        barcode: '16015',
        quantity: 30,
        price: 13665,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2117 для Подольск, Техника, Замена масла, Гамма',
    },
    {
        id: 2118,
        artnumber: '58294',
        barcode: '40645',
        quantity: 341,
        price: 47489,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2118 для Подольск, Техника, Замена масла, Гамма',
    },
    {
        id: 2119,
        artnumber: '73973',
        barcode: '78352',
        quantity: 488,
        price: 19667,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2119 для Подольск, Техника, Замена масла, Гамма',
    },
    {
        id: 2120,
        artnumber: '20961',
        barcode: '60394',
        quantity: 343,
        price: 25171,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2120 для Подольск, Техника, Замена масла, Гамма',
    },
    {
        id: 2121,
        artnumber: '67388',
        barcode: '47807',
        quantity: 364,
        price: 1823,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2121 для Подольск, Техника, Замена масла, Гамма',
    },
    {
        id: 2122,
        artnumber: '25926',
        barcode: '53459',
        quantity: 403,
        price: 38988,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2122 для Подольск, Техника, Замена масла, Гамма',
    },
    {
        id: 2123,
        artnumber: '37355',
        barcode: '43904',
        quantity: 272,
        price: 29905,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2123 для Подольск, Техника, Замена масла, Трио',
    },
    {
        id: 2124,
        artnumber: '83743',
        barcode: '51480',
        quantity: 329,
        price: 27435,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2124 для Подольск, Техника, Замена масла, Трио',
    },
    {
        id: 2125,
        artnumber: '27201',
        barcode: '91658',
        quantity: 75,
        price: 11963,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2125 для Подольск, Техника, Замена масла, Трио',
    },
    {
        id: 2126,
        artnumber: '44025',
        barcode: '96688',
        quantity: 13,
        price: 19019,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2126 для Подольск, Техника, Замена масла, Трио',
    },
    {
        id: 2127,
        artnumber: '57991',
        barcode: '20048',
        quantity: 384,
        price: 21894,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2127 для Подольск, Техника, Замена масла, Трио',
    },
    {
        id: 2128,
        artnumber: '18916',
        barcode: '96390',
        quantity: 112,
        price: 41366,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2128 для Подольск, Техника, Замена масла, Трио',
    },
    {
        id: 2129,
        artnumber: '17078',
        barcode: '29225',
        quantity: 346,
        price: 32242,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2129 для Подольск, Техника, Замена масла, Трио',
    },
    {
        id: 2130,
        artnumber: '52456',
        barcode: '61494',
        quantity: 106,
        price: 24099,
        picture: '/images/products/no_image.svg',
        region: {
            id: 2,
            name: 'Подольск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2130 для Подольск, Техника, Замена масла, Трио',
    },
    {
        id: 2131,
        artnumber: '13881',
        barcode: '68527',
        quantity: 201,
        price: 37212,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2131 для Королев, Авто, Сервис, Альфа',
    },
    {
        id: 2132,
        artnumber: '34335',
        barcode: '96015',
        quantity: 336,
        price: 184,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2132 для Королев, Авто, Сервис, Альфа',
    },
    {
        id: 2133,
        artnumber: '41546',
        barcode: '89403',
        quantity: 398,
        price: 11290,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2133 для Королев, Авто, Сервис, Альфа',
    },
    {
        id: 2134,
        artnumber: '99639',
        barcode: '66236',
        quantity: 160,
        price: 4537,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2134 для Королев, Авто, Сервис, Альфа',
    },
    {
        id: 2135,
        artnumber: '27801',
        barcode: '27809',
        quantity: 111,
        price: 22481,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2135 для Королев, Авто, Сервис, Альфа',
    },
    {
        id: 2136,
        artnumber: '27426',
        barcode: '74726',
        quantity: 155,
        price: 6194,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2136 для Королев, Авто, Сервис, Альфа',
    },
    {
        id: 2137,
        artnumber: '95907',
        barcode: '59877',
        quantity: 430,
        price: 46211,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2137 для Королев, Авто, Сервис, Альфа',
    },
    {
        id: 2138,
        artnumber: '79055',
        barcode: '31915',
        quantity: 497,
        price: 38179,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2138 для Королев, Авто, Сервис, Альфа',
    },
    {
        id: 2139,
        artnumber: '99503',
        barcode: '22003',
        quantity: 355,
        price: 933,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2139 для Королев, Авто, Сервис, Альфа',
    },
    {
        id: 2140,
        artnumber: '62192',
        barcode: '98682',
        quantity: 199,
        price: 30540,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2140 для Королев, Авто, Сервис, Строй',
    },
    {
        id: 2141,
        artnumber: '71261',
        barcode: '35964',
        quantity: 248,
        price: 30041,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2141 для Королев, Авто, Сервис, Строй',
    },
    {
        id: 2142,
        artnumber: '96088',
        barcode: '71029',
        quantity: 222,
        price: 49022,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2142 для Королев, Авто, Сервис, Строй',
    },
    {
        id: 2143,
        artnumber: '78336',
        barcode: '45999',
        quantity: 194,
        price: 48807,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2143 для Королев, Авто, Сервис, Строй',
    },
    {
        id: 2144,
        artnumber: '78904',
        barcode: '68114',
        quantity: 455,
        price: 9111,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2144 для Королев, Авто, Сервис, Строй',
    },
    {
        id: 2145,
        artnumber: '79634',
        barcode: '44067',
        quantity: 305,
        price: 14764,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2145 для Королев, Авто, Сервис, Строй',
    },
    {
        id: 2146,
        artnumber: '39580',
        barcode: '99565',
        quantity: 156,
        price: 41266,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2146 для Королев, Авто, Сервис, Строй',
    },
    {
        id: 2147,
        artnumber: '40801',
        barcode: '61918',
        quantity: 359,
        price: 6760,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2147 для Королев, Авто, Сервис, Строй',
    },
    {
        id: 2148,
        artnumber: '15389',
        barcode: '72589',
        quantity: 317,
        price: 41382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2148 для Королев, Авто, Сервис, Строй',
    },
    {
        id: 2149,
        artnumber: '67757',
        barcode: '61731',
        quantity: 77,
        price: 9913,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2149 для Королев, Авто, Сервис, Бета',
    },
    {
        id: 2150,
        artnumber: '84393',
        barcode: '29825',
        quantity: 217,
        price: 38017,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2150 для Королев, Авто, Сервис, Бета',
    },
    {
        id: 2151,
        artnumber: '89349',
        barcode: '70466',
        quantity: 497,
        price: 31669,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2151 для Королев, Авто, Сервис, Бета',
    },
    {
        id: 2152,
        artnumber: '27633',
        barcode: '10984',
        quantity: 80,
        price: 11013,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2152 для Королев, Авто, Сервис, Бета',
    },
    {
        id: 2153,
        artnumber: '82151',
        barcode: '23981',
        quantity: 66,
        price: 39657,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2153 для Королев, Авто, Сервис, Бета',
    },
    {
        id: 2154,
        artnumber: '58828',
        barcode: '29678',
        quantity: 122,
        price: 38226,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2154 для Королев, Авто, Сервис, Бета',
    },
    {
        id: 2155,
        artnumber: '13248',
        barcode: '39976',
        quantity: 232,
        price: 4028,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2155 для Королев, Авто, Сервис, Бета',
    },
    {
        id: 2156,
        artnumber: '69950',
        barcode: '30082',
        quantity: 2,
        price: 29871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2156 для Королев, Авто, Сервис, Бета',
    },
    {
        id: 2157,
        artnumber: '64874',
        barcode: '18777',
        quantity: 112,
        price: 40543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2157 для Королев, Авто, Сервис, Бета',
    },
    {
        id: 2158,
        artnumber: '13501',
        barcode: '14878',
        quantity: 353,
        price: 155,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2158 для Королев, Авто, Сервис, Гамма',
    },
    {
        id: 2159,
        artnumber: '50957',
        barcode: '94163',
        quantity: 20,
        price: 3571,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2159 для Королев, Авто, Сервис, Гамма',
    },
    {
        id: 2160,
        artnumber: '73978',
        barcode: '80871',
        quantity: 433,
        price: 29803,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2160 для Королев, Авто, Сервис, Гамма',
    },
    {
        id: 2161,
        artnumber: '16053',
        barcode: '84085',
        quantity: 478,
        price: 6662,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2161 для Королев, Авто, Сервис, Гамма',
    },
    {
        id: 2162,
        artnumber: '21649',
        barcode: '83514',
        quantity: 329,
        price: 9660,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2162 для Королев, Авто, Сервис, Гамма',
    },
    {
        id: 2163,
        artnumber: '86514',
        barcode: '96795',
        quantity: 288,
        price: 13147,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2163 для Королев, Авто, Сервис, Гамма',
    },
    {
        id: 2164,
        artnumber: '62321',
        barcode: '37387',
        quantity: 432,
        price: 428,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2164 для Королев, Авто, Сервис, Гамма',
    },
    {
        id: 2165,
        artnumber: '58183',
        barcode: '20570',
        quantity: 470,
        price: 38447,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2165 для Королев, Авто, Сервис, Гамма',
    },
    {
        id: 2166,
        artnumber: '74588',
        barcode: '46660',
        quantity: 454,
        price: 35038,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2166 для Королев, Авто, Сервис, Гамма',
    },
    {
        id: 2167,
        artnumber: '77921',
        barcode: '33218',
        quantity: 232,
        price: 11188,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2167 для Королев, Авто, Сервис, Трио',
    },
    {
        id: 2168,
        artnumber: '97057',
        barcode: '52881',
        quantity: 459,
        price: 46427,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2168 для Королев, Авто, Сервис, Трио',
    },
    {
        id: 2169,
        artnumber: '47366',
        barcode: '85797',
        quantity: 45,
        price: 12011,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2169 для Королев, Авто, Сервис, Трио',
    },
    {
        id: 2170,
        artnumber: '99858',
        barcode: '13746',
        quantity: 220,
        price: 26424,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2170 для Королев, Авто, Сервис, Трио',
    },
    {
        id: 2171,
        artnumber: '32938',
        barcode: '56687',
        quantity: 25,
        price: 48308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2171 для Королев, Авто, Сервис, Трио',
    },
    {
        id: 2172,
        artnumber: '56354',
        barcode: '61375',
        quantity: 471,
        price: 2516,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2172 для Королев, Авто, Сервис, Трио',
    },
    {
        id: 2173,
        artnumber: '20237',
        barcode: '45809',
        quantity: 221,
        price: 44024,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2173 для Королев, Авто, Сервис, Трио',
    },
    {
        id: 2174,
        artnumber: '41235',
        barcode: '18194',
        quantity: 168,
        price: 37036,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2174 для Королев, Авто, Сервис, Трио',
    },
    {
        id: 2175,
        artnumber: '91440',
        barcode: '46285',
        quantity: 394,
        price: 17480,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2175 для Королев, Авто, Сервис, Трио',
    },
    {
        id: 2176,
        artnumber: '80197',
        barcode: '63523',
        quantity: 240,
        price: 27725,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2176 для Королев, Авто, Запчасти, Альфа',
    },
    {
        id: 2177,
        artnumber: '63865',
        barcode: '34221',
        quantity: 253,
        price: 33286,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2177 для Королев, Авто, Запчасти, Альфа',
    },
    {
        id: 2178,
        artnumber: '60639',
        barcode: '19478',
        quantity: 443,
        price: 24771,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2178 для Королев, Авто, Запчасти, Альфа',
    },
    {
        id: 2179,
        artnumber: '77201',
        barcode: '15333',
        quantity: 423,
        price: 41937,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2179 для Королев, Авто, Запчасти, Альфа',
    },
    {
        id: 2180,
        artnumber: '81992',
        barcode: '96278',
        quantity: 9,
        price: 8700,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2180 для Королев, Авто, Запчасти, Альфа',
    },
    {
        id: 2181,
        artnumber: '66778',
        barcode: '20136',
        quantity: 332,
        price: 11441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2181 для Королев, Авто, Запчасти, Альфа',
    },
    {
        id: 2182,
        artnumber: '94158',
        barcode: '78323',
        quantity: 99,
        price: 39497,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2182 для Королев, Авто, Запчасти, Альфа',
    },
    {
        id: 2183,
        artnumber: '53665',
        barcode: '52215',
        quantity: 378,
        price: 30102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2183 для Королев, Авто, Запчасти, Строй',
    },
    {
        id: 2184,
        artnumber: '81321',
        barcode: '42124',
        quantity: 74,
        price: 43372,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2184 для Королев, Авто, Запчасти, Строй',
    },
    {
        id: 2185,
        artnumber: '91066',
        barcode: '65340',
        quantity: 92,
        price: 28222,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2185 для Королев, Авто, Запчасти, Строй',
    },
    {
        id: 2186,
        artnumber: '47347',
        barcode: '50160',
        quantity: 252,
        price: 30212,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2186 для Королев, Авто, Запчасти, Строй',
    },
    {
        id: 2187,
        artnumber: '24186',
        barcode: '38625',
        quantity: 365,
        price: 16255,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2187 для Королев, Авто, Запчасти, Строй',
    },
    {
        id: 2188,
        artnumber: '22316',
        barcode: '96918',
        quantity: 464,
        price: 27319,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2188 для Королев, Авто, Запчасти, Строй',
    },
    {
        id: 2189,
        artnumber: '43002',
        barcode: '14525',
        quantity: 267,
        price: 48743,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2189 для Королев, Авто, Запчасти, Строй',
    },
    {
        id: 2190,
        artnumber: '11355',
        barcode: '78063',
        quantity: 230,
        price: 1653,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2190 для Королев, Авто, Запчасти, Бета',
    },
    {
        id: 2191,
        artnumber: '57689',
        barcode: '64844',
        quantity: 408,
        price: 45742,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2191 для Королев, Авто, Запчасти, Бета',
    },
    {
        id: 2192,
        artnumber: '21193',
        barcode: '22930',
        quantity: 10,
        price: 41986,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2192 для Королев, Авто, Запчасти, Бета',
    },
    {
        id: 2193,
        artnumber: '87742',
        barcode: '14453',
        quantity: 480,
        price: 30095,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2193 для Королев, Авто, Запчасти, Бета',
    },
    {
        id: 2194,
        artnumber: '55680',
        barcode: '74039',
        quantity: 330,
        price: 13697,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2194 для Королев, Авто, Запчасти, Бета',
    },
    {
        id: 2195,
        artnumber: '20883',
        barcode: '90719',
        quantity: 457,
        price: 35280,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2195 для Королев, Авто, Запчасти, Бета',
    },
    {
        id: 2196,
        artnumber: '46022',
        barcode: '32399',
        quantity: 389,
        price: 26367,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2196 для Королев, Авто, Запчасти, Бета',
    },
    {
        id: 2197,
        artnumber: '45997',
        barcode: '30095',
        quantity: 254,
        price: 35685,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2197 для Королев, Авто, Запчасти, Гамма',
    },
    {
        id: 2198,
        artnumber: '70877',
        barcode: '71588',
        quantity: 47,
        price: 28635,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2198 для Королев, Авто, Запчасти, Гамма',
    },
    {
        id: 2199,
        artnumber: '68412',
        barcode: '32272',
        quantity: 348,
        price: 15777,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2199 для Королев, Авто, Запчасти, Гамма',
    },
    {
        id: 2200,
        artnumber: '38783',
        barcode: '14814',
        quantity: 289,
        price: 17422,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2200 для Королев, Авто, Запчасти, Гамма',
    },
    {
        id: 2201,
        artnumber: '93735',
        barcode: '70350',
        quantity: 2,
        price: 5350,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2201 для Королев, Авто, Запчасти, Гамма',
    },
    {
        id: 2202,
        artnumber: '30314',
        barcode: '51941',
        quantity: 176,
        price: 27942,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2202 для Королев, Авто, Запчасти, Гамма',
    },
    {
        id: 2203,
        artnumber: '15172',
        barcode: '48938',
        quantity: 124,
        price: 48659,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2203 для Королев, Авто, Запчасти, Гамма',
    },
    {
        id: 2204,
        artnumber: '25854',
        barcode: '44903',
        quantity: 198,
        price: 875,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2204 для Королев, Авто, Запчасти, Трио',
    },
    {
        id: 2205,
        artnumber: '45469',
        barcode: '94060',
        quantity: 140,
        price: 16715,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2205 для Королев, Авто, Запчасти, Трио',
    },
    {
        id: 2206,
        artnumber: '53512',
        barcode: '28220',
        quantity: 449,
        price: 43242,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2206 для Королев, Авто, Запчасти, Трио',
    },
    {
        id: 2207,
        artnumber: '41115',
        barcode: '55873',
        quantity: 415,
        price: 29739,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2207 для Королев, Авто, Запчасти, Трио',
    },
    {
        id: 2208,
        artnumber: '16571',
        barcode: '44987',
        quantity: 298,
        price: 34121,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2208 для Королев, Авто, Запчасти, Трио',
    },
    {
        id: 2209,
        artnumber: '30612',
        barcode: '11168',
        quantity: 111,
        price: 19980,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2209 для Королев, Авто, Запчасти, Трио',
    },
    {
        id: 2210,
        artnumber: '38330',
        barcode: '31634',
        quantity: 331,
        price: 18745,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2210 для Королев, Авто, Запчасти, Трио',
    },
    {
        id: 2211,
        artnumber: '39453',
        barcode: '65244',
        quantity: 231,
        price: 12250,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2211 для Королев, Авто, Доставка, Альфа',
    },
    {
        id: 2212,
        artnumber: '38612',
        barcode: '85014',
        quantity: 255,
        price: 43304,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2212 для Королев, Авто, Доставка, Альфа',
    },
    {
        id: 2213,
        artnumber: '99700',
        barcode: '15321',
        quantity: 83,
        price: 6545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2213 для Королев, Авто, Доставка, Альфа',
    },
    {
        id: 2214,
        artnumber: '79195',
        barcode: '78092',
        quantity: 306,
        price: 2092,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2214 для Королев, Авто, Доставка, Альфа',
    },
    {
        id: 2215,
        artnumber: '67385',
        barcode: '56688',
        quantity: 5,
        price: 10165,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2215 для Королев, Авто, Доставка, Альфа',
    },
    {
        id: 2216,
        artnumber: '67552',
        barcode: '12115',
        quantity: 500,
        price: 18599,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2216 для Королев, Авто, Доставка, Альфа',
    },
    {
        id: 2217,
        artnumber: '80753',
        barcode: '33944',
        quantity: 332,
        price: 20826,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2217 для Королев, Авто, Доставка, Альфа',
    },
    {
        id: 2218,
        artnumber: '47858',
        barcode: '31892',
        quantity: 240,
        price: 1109,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2218 для Королев, Авто, Доставка, Альфа',
    },
    {
        id: 2219,
        artnumber: '48036',
        barcode: '56377',
        quantity: 138,
        price: 34460,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2219 для Королев, Авто, Доставка, Строй',
    },
    {
        id: 2220,
        artnumber: '81122',
        barcode: '87741',
        quantity: 412,
        price: 31889,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2220 для Королев, Авто, Доставка, Строй',
    },
    {
        id: 2221,
        artnumber: '78468',
        barcode: '30456',
        quantity: 372,
        price: 3680,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2221 для Королев, Авто, Доставка, Строй',
    },
    {
        id: 2222,
        artnumber: '52236',
        barcode: '59538',
        quantity: 226,
        price: 48848,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2222 для Королев, Авто, Доставка, Строй',
    },
    {
        id: 2223,
        artnumber: '17817',
        barcode: '27541',
        quantity: 384,
        price: 14256,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2223 для Королев, Авто, Доставка, Строй',
    },
    {
        id: 2224,
        artnumber: '19157',
        barcode: '17429',
        quantity: 75,
        price: 33018,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2224 для Королев, Авто, Доставка, Строй',
    },
    {
        id: 2225,
        artnumber: '96299',
        barcode: '19337',
        quantity: 107,
        price: 43339,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2225 для Королев, Авто, Доставка, Строй',
    },
    {
        id: 2226,
        artnumber: '25155',
        barcode: '76056',
        quantity: 8,
        price: 10914,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2226 для Королев, Авто, Доставка, Строй',
    },
    {
        id: 2227,
        artnumber: '72280',
        barcode: '21877',
        quantity: 148,
        price: 49038,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2227 для Королев, Авто, Доставка, Бета',
    },
    {
        id: 2228,
        artnumber: '74024',
        barcode: '55445',
        quantity: 317,
        price: 26855,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2228 для Королев, Авто, Доставка, Бета',
    },
    {
        id: 2229,
        artnumber: '51651',
        barcode: '40638',
        quantity: 122,
        price: 37119,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2229 для Королев, Авто, Доставка, Бета',
    },
    {
        id: 2230,
        artnumber: '70909',
        barcode: '72210',
        quantity: 491,
        price: 12650,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2230 для Королев, Авто, Доставка, Бета',
    },
    {
        id: 2231,
        artnumber: '36819',
        barcode: '15867',
        quantity: 131,
        price: 30196,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2231 для Королев, Авто, Доставка, Бета',
    },
    {
        id: 2232,
        artnumber: '13206',
        barcode: '76947',
        quantity: 470,
        price: 21489,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2232 для Королев, Авто, Доставка, Бета',
    },
    {
        id: 2233,
        artnumber: '86227',
        barcode: '42865',
        quantity: 356,
        price: 34689,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2233 для Королев, Авто, Доставка, Бета',
    },
    {
        id: 2234,
        artnumber: '96481',
        barcode: '39607',
        quantity: 495,
        price: 45629,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2234 для Королев, Авто, Доставка, Бета',
    },
    {
        id: 2235,
        artnumber: '71639',
        barcode: '78552',
        quantity: 227,
        price: 7160,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2235 для Королев, Авто, Доставка, Гамма',
    },
    {
        id: 2236,
        artnumber: '21866',
        barcode: '85084',
        quantity: 5,
        price: 32895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2236 для Королев, Авто, Доставка, Гамма',
    },
    {
        id: 2237,
        artnumber: '50844',
        barcode: '21243',
        quantity: 259,
        price: 32470,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2237 для Королев, Авто, Доставка, Гамма',
    },
    {
        id: 2238,
        artnumber: '40993',
        barcode: '77453',
        quantity: 459,
        price: 30244,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2238 для Королев, Авто, Доставка, Гамма',
    },
    {
        id: 2239,
        artnumber: '11570',
        barcode: '22542',
        quantity: 420,
        price: 27972,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2239 для Королев, Авто, Доставка, Гамма',
    },
    {
        id: 2240,
        artnumber: '30880',
        barcode: '70588',
        quantity: 468,
        price: 6805,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2240 для Королев, Авто, Доставка, Гамма',
    },
    {
        id: 2241,
        artnumber: '59227',
        barcode: '68108',
        quantity: 66,
        price: 22711,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2241 для Королев, Авто, Доставка, Гамма',
    },
    {
        id: 2242,
        artnumber: '79122',
        barcode: '47524',
        quantity: 118,
        price: 7343,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2242 для Королев, Авто, Доставка, Гамма',
    },
    {
        id: 2243,
        artnumber: '70581',
        barcode: '25370',
        quantity: 186,
        price: 45867,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2243 для Королев, Авто, Доставка, Трио',
    },
    {
        id: 2244,
        artnumber: '63472',
        barcode: '95417',
        quantity: 368,
        price: 767,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2244 для Королев, Авто, Доставка, Трио',
    },
    {
        id: 2245,
        artnumber: '13183',
        barcode: '39932',
        quantity: 200,
        price: 9134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2245 для Королев, Авто, Доставка, Трио',
    },
    {
        id: 2246,
        artnumber: '62369',
        barcode: '54528',
        quantity: 367,
        price: 48207,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2246 для Королев, Авто, Доставка, Трио',
    },
    {
        id: 2247,
        artnumber: '85213',
        barcode: '39637',
        quantity: 397,
        price: 25964,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2247 для Королев, Авто, Доставка, Трио',
    },
    {
        id: 2248,
        artnumber: '21703',
        barcode: '62668',
        quantity: 5,
        price: 25116,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2248 для Королев, Авто, Доставка, Трио',
    },
    {
        id: 2249,
        artnumber: '80166',
        barcode: '66634',
        quantity: 429,
        price: 13765,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2249 для Королев, Авто, Доставка, Трио',
    },
    {
        id: 2250,
        artnumber: '96455',
        barcode: '51052',
        quantity: 168,
        price: 7210,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2250 для Королев, Авто, Доставка, Трио',
    },
    {
        id: 2251,
        artnumber: '32547',
        barcode: '44744',
        quantity: 356,
        price: 13507,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2251 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2252,
        artnumber: '52575',
        barcode: '25980',
        quantity: 461,
        price: 23938,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2252 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2253,
        artnumber: '35229',
        barcode: '86190',
        quantity: 426,
        price: 49759,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2253 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2254,
        artnumber: '15410',
        barcode: '28431',
        quantity: 283,
        price: 46393,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2254 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2255,
        artnumber: '56001',
        barcode: '50034',
        quantity: 247,
        price: 45067,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2255 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2256,
        artnumber: '63569',
        barcode: '83350',
        quantity: 251,
        price: 30598,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2256 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2257,
        artnumber: '93297',
        barcode: '36923',
        quantity: 27,
        price: 37431,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2257 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2258,
        artnumber: '17003',
        barcode: '38608',
        quantity: 209,
        price: 23331,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2258 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2259,
        artnumber: '55219',
        barcode: '82437',
        quantity: 159,
        price: 23905,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2259 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2260,
        artnumber: '98006',
        barcode: '97339',
        quantity: 70,
        price: 11585,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2260 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2261,
        artnumber: '13044',
        barcode: '36733',
        quantity: 207,
        price: 25511,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2261 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2262,
        artnumber: '16488',
        barcode: '66611',
        quantity: 465,
        price: 33349,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2262 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2263,
        artnumber: '35346',
        barcode: '56305',
        quantity: 465,
        price: 6730,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2263 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2264,
        artnumber: '33602',
        barcode: '87806',
        quantity: 355,
        price: 44324,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2264 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2265,
        artnumber: '90806',
        barcode: '77038',
        quantity: 182,
        price: 13666,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2265 для Королев, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 2266,
        artnumber: '16331',
        barcode: '14829',
        quantity: 464,
        price: 42387,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2266 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2267,
        artnumber: '79893',
        barcode: '18830',
        quantity: 27,
        price: 44476,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2267 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2268,
        artnumber: '76290',
        barcode: '64271',
        quantity: 340,
        price: 45042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2268 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2269,
        artnumber: '42071',
        barcode: '59616',
        quantity: 96,
        price: 48456,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2269 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2270,
        artnumber: '71702',
        barcode: '36038',
        quantity: 160,
        price: 49870,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2270 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2271,
        artnumber: '13549',
        barcode: '43397',
        quantity: 211,
        price: 14986,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2271 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2272,
        artnumber: '44235',
        barcode: '33055',
        quantity: 451,
        price: 44585,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2272 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2273,
        artnumber: '80024',
        barcode: '78469',
        quantity: 135,
        price: 42638,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2273 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2274,
        artnumber: '28004',
        barcode: '68384',
        quantity: 52,
        price: 25186,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2274 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2275,
        artnumber: '39367',
        barcode: '28554',
        quantity: 71,
        price: 10423,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2275 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2276,
        artnumber: '45897',
        barcode: '27151',
        quantity: 301,
        price: 43712,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2276 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2277,
        artnumber: '18318',
        barcode: '57766',
        quantity: 416,
        price: 21104,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2277 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2278,
        artnumber: '72641',
        barcode: '71494',
        quantity: 139,
        price: 27002,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2278 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2279,
        artnumber: '64025',
        barcode: '71692',
        quantity: 429,
        price: 13023,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2279 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2280,
        artnumber: '86262',
        barcode: '66566',
        quantity: 170,
        price: 9939,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2280 для Королев, Авто, Шиномонтаж, Строй',
    },
    {
        id: 2281,
        artnumber: '85389',
        barcode: '15772',
        quantity: 401,
        price: 23560,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2281 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2282,
        artnumber: '50961',
        barcode: '87554',
        quantity: 34,
        price: 39539,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2282 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2283,
        artnumber: '10619',
        barcode: '88329',
        quantity: 188,
        price: 37919,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2283 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2284,
        artnumber: '78652',
        barcode: '63137',
        quantity: 87,
        price: 48841,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2284 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2285,
        artnumber: '49784',
        barcode: '25089',
        quantity: 423,
        price: 38313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2285 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2286,
        artnumber: '23505',
        barcode: '87730',
        quantity: 52,
        price: 3169,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2286 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2287,
        artnumber: '26611',
        barcode: '32193',
        quantity: 79,
        price: 1873,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2287 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2288,
        artnumber: '92798',
        barcode: '66508',
        quantity: 151,
        price: 40286,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2288 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2289,
        artnumber: '97367',
        barcode: '55961',
        quantity: 466,
        price: 11031,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2289 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2290,
        artnumber: '77576',
        barcode: '36873',
        quantity: 24,
        price: 37775,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2290 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2291,
        artnumber: '29588',
        barcode: '68179',
        quantity: 191,
        price: 18926,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2291 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2292,
        artnumber: '92762',
        barcode: '52431',
        quantity: 19,
        price: 6732,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2292 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2293,
        artnumber: '29994',
        barcode: '11294',
        quantity: 272,
        price: 12131,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2293 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2294,
        artnumber: '21918',
        barcode: '24299',
        quantity: 459,
        price: 27393,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2294 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2295,
        artnumber: '87713',
        barcode: '75678',
        quantity: 236,
        price: 16691,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2295 для Королев, Авто, Шиномонтаж, Бета',
    },
    {
        id: 2296,
        artnumber: '71873',
        barcode: '96009',
        quantity: 39,
        price: 49210,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2296 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2297,
        artnumber: '86712',
        barcode: '96372',
        quantity: 277,
        price: 26647,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2297 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2298,
        artnumber: '89570',
        barcode: '49021',
        quantity: 467,
        price: 4713,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2298 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2299,
        artnumber: '67543',
        barcode: '97346',
        quantity: 237,
        price: 20744,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2299 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2300,
        artnumber: '25266',
        barcode: '55317',
        quantity: 21,
        price: 16782,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2300 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2301,
        artnumber: '36388',
        barcode: '91445',
        quantity: 324,
        price: 6508,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2301 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2302,
        artnumber: '31369',
        barcode: '41929',
        quantity: 11,
        price: 19890,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2302 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2303,
        artnumber: '63865',
        barcode: '88494',
        quantity: 168,
        price: 43904,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2303 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2304,
        artnumber: '29613',
        barcode: '13727',
        quantity: 156,
        price: 34931,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2304 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2305,
        artnumber: '60057',
        barcode: '62350',
        quantity: 47,
        price: 18195,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2305 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2306,
        artnumber: '67694',
        barcode: '50323',
        quantity: 95,
        price: 25394,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2306 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2307,
        artnumber: '96368',
        barcode: '49159',
        quantity: 57,
        price: 18774,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2307 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2308,
        artnumber: '21764',
        barcode: '47370',
        quantity: 74,
        price: 42060,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2308 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2309,
        artnumber: '26351',
        barcode: '44489',
        quantity: 135,
        price: 12959,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2309 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2310,
        artnumber: '93022',
        barcode: '13294',
        quantity: 180,
        price: 49626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2310 для Королев, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 2311,
        artnumber: '28462',
        barcode: '42237',
        quantity: 94,
        price: 25217,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2311 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2312,
        artnumber: '32562',
        barcode: '81387',
        quantity: 108,
        price: 30603,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2312 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2313,
        artnumber: '17324',
        barcode: '30153',
        quantity: 494,
        price: 12286,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2313 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2314,
        artnumber: '85006',
        barcode: '11214',
        quantity: 30,
        price: 12568,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2314 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2315,
        artnumber: '91161',
        barcode: '99893',
        quantity: 308,
        price: 47824,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2315 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2316,
        artnumber: '68690',
        barcode: '44840',
        quantity: 1,
        price: 31927,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2316 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2317,
        artnumber: '94435',
        barcode: '43975',
        quantity: 65,
        price: 16559,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2317 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2318,
        artnumber: '70931',
        barcode: '32987',
        quantity: 233,
        price: 38381,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2318 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2319,
        artnumber: '18895',
        barcode: '79881',
        quantity: 462,
        price: 13600,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2319 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2320,
        artnumber: '40648',
        barcode: '36760',
        quantity: 437,
        price: 47679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2320 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2321,
        artnumber: '98732',
        barcode: '41527',
        quantity: 459,
        price: 22077,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2321 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2322,
        artnumber: '59159',
        barcode: '51839',
        quantity: 439,
        price: 22220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2322 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2323,
        artnumber: '85244',
        barcode: '68647',
        quantity: 241,
        price: 31130,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2323 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2324,
        artnumber: '57062',
        barcode: '16256',
        quantity: 192,
        price: 3237,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2324 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2325,
        artnumber: '51197',
        barcode: '14404',
        quantity: 183,
        price: 22836,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2325 для Королев, Авто, Шиномонтаж, Трио',
    },
    {
        id: 2326,
        artnumber: '57394',
        barcode: '36896',
        quantity: 19,
        price: 32228,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2326 для Королев, Авто, Замена масла, Альфа',
    },
    {
        id: 2327,
        artnumber: '86723',
        barcode: '59916',
        quantity: 452,
        price: 32889,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2327 для Королев, Авто, Замена масла, Альфа',
    },
    {
        id: 2328,
        artnumber: '60229',
        barcode: '79244',
        quantity: 360,
        price: 33974,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2328 для Королев, Авто, Замена масла, Альфа',
    },
    {
        id: 2329,
        artnumber: '27093',
        barcode: '78461',
        quantity: 475,
        price: 19704,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2329 для Королев, Авто, Замена масла, Строй',
    },
    {
        id: 2330,
        artnumber: '25473',
        barcode: '70062',
        quantity: 47,
        price: 2203,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2330 для Королев, Авто, Замена масла, Строй',
    },
    {
        id: 2331,
        artnumber: '67534',
        barcode: '75585',
        quantity: 49,
        price: 10954,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2331 для Королев, Авто, Замена масла, Строй',
    },
    {
        id: 2332,
        artnumber: '14977',
        barcode: '16820',
        quantity: 284,
        price: 15462,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2332 для Королев, Авто, Замена масла, Бета',
    },
    {
        id: 2333,
        artnumber: '23731',
        barcode: '82333',
        quantity: 60,
        price: 49220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2333 для Королев, Авто, Замена масла, Бета',
    },
    {
        id: 2334,
        artnumber: '61621',
        barcode: '62816',
        quantity: 154,
        price: 33631,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2334 для Королев, Авто, Замена масла, Бета',
    },
    {
        id: 2335,
        artnumber: '79378',
        barcode: '75573',
        quantity: 288,
        price: 20808,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2335 для Королев, Авто, Замена масла, Гамма',
    },
    {
        id: 2336,
        artnumber: '67447',
        barcode: '32751',
        quantity: 184,
        price: 43955,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2336 для Королев, Авто, Замена масла, Гамма',
    },
    {
        id: 2337,
        artnumber: '44001',
        barcode: '34597',
        quantity: 66,
        price: 7266,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2337 для Королев, Авто, Замена масла, Гамма',
    },
    {
        id: 2338,
        artnumber: '14985',
        barcode: '33746',
        quantity: 433,
        price: 46622,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2338 для Королев, Авто, Замена масла, Трио',
    },
    {
        id: 2339,
        artnumber: '67723',
        barcode: '95916',
        quantity: 311,
        price: 31398,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2339 для Королев, Авто, Замена масла, Трио',
    },
    {
        id: 2340,
        artnumber: '98541',
        barcode: '48601',
        quantity: 50,
        price: 35454,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2340 для Королев, Авто, Замена масла, Трио',
    },
    {
        id: 2341,
        artnumber: '77259',
        barcode: '67548',
        quantity: 245,
        price: 10206,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2341 для Королев, Недвижимость, Сервис, Альфа',
    },
    {
        id: 2342,
        artnumber: '35401',
        barcode: '55834',
        quantity: 349,
        price: 24431,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2342 для Королев, Недвижимость, Сервис, Альфа',
    },
    {
        id: 2343,
        artnumber: '84970',
        barcode: '72493',
        quantity: 148,
        price: 39234,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2343 для Королев, Недвижимость, Сервис, Альфа',
    },
    {
        id: 2344,
        artnumber: '80038',
        barcode: '50406',
        quantity: 48,
        price: 29922,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2344 для Королев, Недвижимость, Сервис, Альфа',
    },
    {
        id: 2345,
        artnumber: '45127',
        barcode: '69859',
        quantity: 273,
        price: 5166,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2345 для Королев, Недвижимость, Сервис, Альфа',
    },
    {
        id: 2346,
        artnumber: '46122',
        barcode: '17137',
        quantity: 205,
        price: 34333,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2346 для Королев, Недвижимость, Сервис, Альфа',
    },
    {
        id: 2347,
        artnumber: '67898',
        barcode: '76850',
        quantity: 399,
        price: 11037,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2347 для Королев, Недвижимость, Сервис, Альфа',
    },
    {
        id: 2348,
        artnumber: '28224',
        barcode: '61691',
        quantity: 496,
        price: 20231,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2348 для Королев, Недвижимость, Сервис, Альфа',
    },
    {
        id: 2349,
        artnumber: '80728',
        barcode: '27738',
        quantity: 174,
        price: 4613,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2349 для Королев, Недвижимость, Сервис, Строй',
    },
    {
        id: 2350,
        artnumber: '10210',
        barcode: '79716',
        quantity: 208,
        price: 32998,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2350 для Королев, Недвижимость, Сервис, Строй',
    },
    {
        id: 2351,
        artnumber: '96475',
        barcode: '18961',
        quantity: 431,
        price: 28269,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2351 для Королев, Недвижимость, Сервис, Строй',
    },
    {
        id: 2352,
        artnumber: '35080',
        barcode: '45475',
        quantity: 315,
        price: 7815,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2352 для Королев, Недвижимость, Сервис, Строй',
    },
    {
        id: 2353,
        artnumber: '74528',
        barcode: '70780',
        quantity: 73,
        price: 24029,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2353 для Королев, Недвижимость, Сервис, Строй',
    },
    {
        id: 2354,
        artnumber: '10961',
        barcode: '29044',
        quantity: 471,
        price: 32101,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2354 для Королев, Недвижимость, Сервис, Строй',
    },
    {
        id: 2355,
        artnumber: '40694',
        barcode: '58184',
        quantity: 14,
        price: 42384,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2355 для Королев, Недвижимость, Сервис, Строй',
    },
    {
        id: 2356,
        artnumber: '63499',
        barcode: '70864',
        quantity: 313,
        price: 12464,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2356 для Королев, Недвижимость, Сервис, Строй',
    },
    {
        id: 2357,
        artnumber: '35907',
        barcode: '26320',
        quantity: 394,
        price: 7084,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2357 для Королев, Недвижимость, Сервис, Бета',
    },
    {
        id: 2358,
        artnumber: '51855',
        barcode: '73381',
        quantity: 437,
        price: 43115,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2358 для Королев, Недвижимость, Сервис, Бета',
    },
    {
        id: 2359,
        artnumber: '73493',
        barcode: '26640',
        quantity: 68,
        price: 1391,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2359 для Королев, Недвижимость, Сервис, Бета',
    },
    {
        id: 2360,
        artnumber: '32433',
        barcode: '29593',
        quantity: 292,
        price: 44898,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2360 для Королев, Недвижимость, Сервис, Бета',
    },
    {
        id: 2361,
        artnumber: '97040',
        barcode: '21340',
        quantity: 289,
        price: 25753,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2361 для Королев, Недвижимость, Сервис, Бета',
    },
    {
        id: 2362,
        artnumber: '33054',
        barcode: '46065',
        quantity: 141,
        price: 38363,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2362 для Королев, Недвижимость, Сервис, Бета',
    },
    {
        id: 2363,
        artnumber: '53819',
        barcode: '24342',
        quantity: 239,
        price: 8773,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2363 для Королев, Недвижимость, Сервис, Бета',
    },
    {
        id: 2364,
        artnumber: '81577',
        barcode: '54225',
        quantity: 215,
        price: 27614,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2364 для Королев, Недвижимость, Сервис, Бета',
    },
    {
        id: 2365,
        artnumber: '36916',
        barcode: '66584',
        quantity: 203,
        price: 45745,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2365 для Королев, Недвижимость, Сервис, Гамма',
    },
    {
        id: 2366,
        artnumber: '92432',
        barcode: '81643',
        quantity: 79,
        price: 46214,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2366 для Королев, Недвижимость, Сервис, Гамма',
    },
    {
        id: 2367,
        artnumber: '49412',
        barcode: '46815',
        quantity: 375,
        price: 28435,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2367 для Королев, Недвижимость, Сервис, Гамма',
    },
    {
        id: 2368,
        artnumber: '40264',
        barcode: '50596',
        quantity: 25,
        price: 8729,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2368 для Королев, Недвижимость, Сервис, Гамма',
    },
    {
        id: 2369,
        artnumber: '41437',
        barcode: '88276',
        quantity: 468,
        price: 7819,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2369 для Королев, Недвижимость, Сервис, Гамма',
    },
    {
        id: 2370,
        artnumber: '99147',
        barcode: '17852',
        quantity: 331,
        price: 16375,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2370 для Королев, Недвижимость, Сервис, Гамма',
    },
    {
        id: 2371,
        artnumber: '13570',
        barcode: '51823',
        quantity: 49,
        price: 17184,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2371 для Королев, Недвижимость, Сервис, Гамма',
    },
    {
        id: 2372,
        artnumber: '62512',
        barcode: '20910',
        quantity: 309,
        price: 36128,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2372 для Королев, Недвижимость, Сервис, Гамма',
    },
    {
        id: 2373,
        artnumber: '12853',
        barcode: '63375',
        quantity: 244,
        price: 46872,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2373 для Королев, Недвижимость, Сервис, Трио',
    },
    {
        id: 2374,
        artnumber: '45039',
        barcode: '47966',
        quantity: 500,
        price: 36909,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2374 для Королев, Недвижимость, Сервис, Трио',
    },
    {
        id: 2375,
        artnumber: '85361',
        barcode: '17053',
        quantity: 346,
        price: 43978,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2375 для Королев, Недвижимость, Сервис, Трио',
    },
    {
        id: 2376,
        artnumber: '80308',
        barcode: '87459',
        quantity: 286,
        price: 25321,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2376 для Королев, Недвижимость, Сервис, Трио',
    },
    {
        id: 2377,
        artnumber: '60391',
        barcode: '97605',
        quantity: 484,
        price: 14437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2377 для Королев, Недвижимость, Сервис, Трио',
    },
    {
        id: 2378,
        artnumber: '32912',
        barcode: '99529',
        quantity: 238,
        price: 15984,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2378 для Королев, Недвижимость, Сервис, Трио',
    },
    {
        id: 2379,
        artnumber: '23005',
        barcode: '89520',
        quantity: 348,
        price: 6838,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2379 для Королев, Недвижимость, Сервис, Трио',
    },
    {
        id: 2380,
        artnumber: '21879',
        barcode: '67127',
        quantity: 8,
        price: 10921,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2380 для Королев, Недвижимость, Сервис, Трио',
    },
    {
        id: 2381,
        artnumber: '43363',
        barcode: '26649',
        quantity: 182,
        price: 5523,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2381 для Королев, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 2382,
        artnumber: '75559',
        barcode: '41603',
        quantity: 398,
        price: 16542,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2382 для Королев, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 2383,
        artnumber: '92202',
        barcode: '10274',
        quantity: 384,
        price: 37762,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2383 для Королев, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 2384,
        artnumber: '14959',
        barcode: '71202',
        quantity: 47,
        price: 7908,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2384 для Королев, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 2385,
        artnumber: '11762',
        barcode: '77888',
        quantity: 44,
        price: 1245,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2385 для Королев, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 2386,
        artnumber: '84431',
        barcode: '87280',
        quantity: 232,
        price: 16847,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2386 для Королев, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 2387,
        artnumber: '20640',
        barcode: '94666',
        quantity: 251,
        price: 20567,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2387 для Королев, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 2388,
        artnumber: '96770',
        barcode: '40636',
        quantity: 212,
        price: 13691,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2388 для Королев, Недвижимость, Запчасти, Строй',
    },
    {
        id: 2389,
        artnumber: '35614',
        barcode: '21835',
        quantity: 465,
        price: 5272,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2389 для Королев, Недвижимость, Запчасти, Строй',
    },
    {
        id: 2390,
        artnumber: '41432',
        barcode: '29669',
        quantity: 222,
        price: 8433,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2390 для Королев, Недвижимость, Запчасти, Строй',
    },
    {
        id: 2391,
        artnumber: '61782',
        barcode: '59876',
        quantity: 38,
        price: 7926,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2391 для Королев, Недвижимость, Запчасти, Строй',
    },
    {
        id: 2392,
        artnumber: '83042',
        barcode: '53954',
        quantity: 309,
        price: 31030,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2392 для Королев, Недвижимость, Запчасти, Строй',
    },
    {
        id: 2393,
        artnumber: '23852',
        barcode: '45908',
        quantity: 199,
        price: 22293,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2393 для Королев, Недвижимость, Запчасти, Строй',
    },
    {
        id: 2394,
        artnumber: '95205',
        barcode: '75018',
        quantity: 64,
        price: 30505,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2394 для Королев, Недвижимость, Запчасти, Строй',
    },
    {
        id: 2395,
        artnumber: '52846',
        barcode: '98927',
        quantity: 246,
        price: 29324,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2395 для Королев, Недвижимость, Запчасти, Бета',
    },
    {
        id: 2396,
        artnumber: '27533',
        barcode: '78560',
        quantity: 302,
        price: 11481,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2396 для Королев, Недвижимость, Запчасти, Бета',
    },
    {
        id: 2397,
        artnumber: '31501',
        barcode: '20300',
        quantity: 231,
        price: 813,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2397 для Королев, Недвижимость, Запчасти, Бета',
    },
    {
        id: 2398,
        artnumber: '40802',
        barcode: '34167',
        quantity: 14,
        price: 9068,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2398 для Королев, Недвижимость, Запчасти, Бета',
    },
    {
        id: 2399,
        artnumber: '68086',
        barcode: '16540',
        quantity: 32,
        price: 15334,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2399 для Королев, Недвижимость, Запчасти, Бета',
    },
    {
        id: 2400,
        artnumber: '54774',
        barcode: '16920',
        quantity: 80,
        price: 48569,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2400 для Королев, Недвижимость, Запчасти, Бета',
    },
    {
        id: 2401,
        artnumber: '35751',
        barcode: '87112',
        quantity: 24,
        price: 47359,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2401 для Королев, Недвижимость, Запчасти, Бета',
    },
    {
        id: 2402,
        artnumber: '44332',
        barcode: '66938',
        quantity: 220,
        price: 10355,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2402 для Королев, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 2403,
        artnumber: '16668',
        barcode: '10136',
        quantity: 73,
        price: 27797,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2403 для Королев, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 2404,
        artnumber: '77040',
        barcode: '13885',
        quantity: 462,
        price: 49622,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2404 для Королев, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 2405,
        artnumber: '51263',
        barcode: '84919',
        quantity: 352,
        price: 49060,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2405 для Королев, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 2406,
        artnumber: '76394',
        barcode: '18273',
        quantity: 200,
        price: 8576,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2406 для Королев, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 2407,
        artnumber: '39558',
        barcode: '83906',
        quantity: 41,
        price: 21607,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2407 для Королев, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 2408,
        artnumber: '81950',
        barcode: '25554',
        quantity: 399,
        price: 44649,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2408 для Королев, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 2409,
        artnumber: '39218',
        barcode: '67960',
        quantity: 123,
        price: 28418,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2409 для Королев, Недвижимость, Запчасти, Трио',
    },
    {
        id: 2410,
        artnumber: '48661',
        barcode: '19752',
        quantity: 289,
        price: 8616,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2410 для Королев, Недвижимость, Запчасти, Трио',
    },
    {
        id: 2411,
        artnumber: '14952',
        barcode: '48573',
        quantity: 264,
        price: 15208,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2411 для Королев, Недвижимость, Запчасти, Трио',
    },
    {
        id: 2412,
        artnumber: '68569',
        barcode: '83379',
        quantity: 406,
        price: 34441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2412 для Королев, Недвижимость, Запчасти, Трио',
    },
    {
        id: 2413,
        artnumber: '42951',
        barcode: '13010',
        quantity: 351,
        price: 1479,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2413 для Королев, Недвижимость, Запчасти, Трио',
    },
    {
        id: 2414,
        artnumber: '20850',
        barcode: '58287',
        quantity: 392,
        price: 41472,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2414 для Королев, Недвижимость, Запчасти, Трио',
    },
    {
        id: 2415,
        artnumber: '62335',
        barcode: '95044',
        quantity: 240,
        price: 42442,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2415 для Королев, Недвижимость, Запчасти, Трио',
    },
    {
        id: 2416,
        artnumber: '49291',
        barcode: '17437',
        quantity: 440,
        price: 22681,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2416 для Королев, Недвижимость, Доставка, Альфа',
    },
    {
        id: 2417,
        artnumber: '57984',
        barcode: '18909',
        quantity: 242,
        price: 31095,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2417 для Королев, Недвижимость, Доставка, Альфа',
    },
    {
        id: 2418,
        artnumber: '76832',
        barcode: '89574',
        quantity: 422,
        price: 8436,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2418 для Королев, Недвижимость, Доставка, Альфа',
    },
    {
        id: 2419,
        artnumber: '72662',
        barcode: '77668',
        quantity: 288,
        price: 36758,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2419 для Королев, Недвижимость, Доставка, Альфа',
    },
    {
        id: 2420,
        artnumber: '73111',
        barcode: '48302',
        quantity: 204,
        price: 1643,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2420 для Королев, Недвижимость, Доставка, Альфа',
    },
    {
        id: 2421,
        artnumber: '62991',
        barcode: '63511',
        quantity: 156,
        price: 39533,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2421 для Королев, Недвижимость, Доставка, Альфа',
    },
    {
        id: 2422,
        artnumber: '20140',
        barcode: '12434',
        quantity: 405,
        price: 23640,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2422 для Королев, Недвижимость, Доставка, Альфа',
    },
    {
        id: 2423,
        artnumber: '92624',
        barcode: '78115',
        quantity: 417,
        price: 17469,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2423 для Королев, Недвижимость, Доставка, Альфа',
    },
    {
        id: 2424,
        artnumber: '41250',
        barcode: '40673',
        quantity: 247,
        price: 386,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2424 для Королев, Недвижимость, Доставка, Альфа',
    },
    {
        id: 2425,
        artnumber: '96382',
        barcode: '48392',
        quantity: 285,
        price: 11258,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2425 для Королев, Недвижимость, Доставка, Строй',
    },
    {
        id: 2426,
        artnumber: '23260',
        barcode: '96904',
        quantity: 96,
        price: 19362,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2426 для Королев, Недвижимость, Доставка, Строй',
    },
    {
        id: 2427,
        artnumber: '87366',
        barcode: '37496',
        quantity: 160,
        price: 44397,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2427 для Королев, Недвижимость, Доставка, Строй',
    },
    {
        id: 2428,
        artnumber: '76521',
        barcode: '69365',
        quantity: 41,
        price: 21502,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2428 для Королев, Недвижимость, Доставка, Строй',
    },
    {
        id: 2429,
        artnumber: '38992',
        barcode: '68928',
        quantity: 193,
        price: 20896,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2429 для Королев, Недвижимость, Доставка, Строй',
    },
    {
        id: 2430,
        artnumber: '95089',
        barcode: '56208',
        quantity: 50,
        price: 57,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2430 для Королев, Недвижимость, Доставка, Строй',
    },
    {
        id: 2431,
        artnumber: '77694',
        barcode: '80753',
        quantity: 406,
        price: 26051,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2431 для Королев, Недвижимость, Доставка, Строй',
    },
    {
        id: 2432,
        artnumber: '84787',
        barcode: '76522',
        quantity: 120,
        price: 45987,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2432 для Королев, Недвижимость, Доставка, Строй',
    },
    {
        id: 2433,
        artnumber: '95195',
        barcode: '21818',
        quantity: 265,
        price: 38942,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2433 для Королев, Недвижимость, Доставка, Строй',
    },
    {
        id: 2434,
        artnumber: '76438',
        barcode: '51012',
        quantity: 331,
        price: 21848,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2434 для Королев, Недвижимость, Доставка, Бета',
    },
    {
        id: 2435,
        artnumber: '73129',
        barcode: '71895',
        quantity: 219,
        price: 6883,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2435 для Королев, Недвижимость, Доставка, Бета',
    },
    {
        id: 2436,
        artnumber: '71661',
        barcode: '93503',
        quantity: 24,
        price: 6069,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2436 для Королев, Недвижимость, Доставка, Бета',
    },
    {
        id: 2437,
        artnumber: '32220',
        barcode: '46258',
        quantity: 249,
        price: 2004,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2437 для Королев, Недвижимость, Доставка, Бета',
    },
    {
        id: 2438,
        artnumber: '15754',
        barcode: '95312',
        quantity: 488,
        price: 8264,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2438 для Королев, Недвижимость, Доставка, Бета',
    },
    {
        id: 2439,
        artnumber: '12182',
        barcode: '90245',
        quantity: 180,
        price: 24092,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2439 для Королев, Недвижимость, Доставка, Бета',
    },
    {
        id: 2440,
        artnumber: '30496',
        barcode: '76762',
        quantity: 366,
        price: 36459,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2440 для Королев, Недвижимость, Доставка, Бета',
    },
    {
        id: 2441,
        artnumber: '27165',
        barcode: '34490',
        quantity: 381,
        price: 45190,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2441 для Королев, Недвижимость, Доставка, Бета',
    },
    {
        id: 2442,
        artnumber: '67176',
        barcode: '82840',
        quantity: 329,
        price: 17575,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2442 для Королев, Недвижимость, Доставка, Бета',
    },
    {
        id: 2443,
        artnumber: '10693',
        barcode: '31483',
        quantity: 268,
        price: 43265,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2443 для Королев, Недвижимость, Доставка, Гамма',
    },
    {
        id: 2444,
        artnumber: '55842',
        barcode: '25623',
        quantity: 123,
        price: 28655,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2444 для Королев, Недвижимость, Доставка, Гамма',
    },
    {
        id: 2445,
        artnumber: '70750',
        barcode: '33882',
        quantity: 420,
        price: 41304,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2445 для Королев, Недвижимость, Доставка, Гамма',
    },
    {
        id: 2446,
        artnumber: '22214',
        barcode: '75969',
        quantity: 451,
        price: 17730,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2446 для Королев, Недвижимость, Доставка, Гамма',
    },
    {
        id: 2447,
        artnumber: '86583',
        barcode: '84370',
        quantity: 298,
        price: 10545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2447 для Королев, Недвижимость, Доставка, Гамма',
    },
    {
        id: 2448,
        artnumber: '27508',
        barcode: '88092',
        quantity: 458,
        price: 29936,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2448 для Королев, Недвижимость, Доставка, Гамма',
    },
    {
        id: 2449,
        artnumber: '85261',
        barcode: '32709',
        quantity: 412,
        price: 22754,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2449 для Королев, Недвижимость, Доставка, Гамма',
    },
    {
        id: 2450,
        artnumber: '82723',
        barcode: '15595',
        quantity: 345,
        price: 20877,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2450 для Королев, Недвижимость, Доставка, Гамма',
    },
    {
        id: 2451,
        artnumber: '16028',
        barcode: '77471',
        quantity: 398,
        price: 46094,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2451 для Королев, Недвижимость, Доставка, Гамма',
    },
    {
        id: 2452,
        artnumber: '57186',
        barcode: '47056',
        quantity: 274,
        price: 43585,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2452 для Королев, Недвижимость, Доставка, Трио',
    },
    {
        id: 2453,
        artnumber: '91701',
        barcode: '31112',
        quantity: 196,
        price: 47504,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2453 для Королев, Недвижимость, Доставка, Трио',
    },
    {
        id: 2454,
        artnumber: '48481',
        barcode: '52578',
        quantity: 32,
        price: 31236,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2454 для Королев, Недвижимость, Доставка, Трио',
    },
    {
        id: 2455,
        artnumber: '86619',
        barcode: '53899',
        quantity: 216,
        price: 9630,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2455 для Королев, Недвижимость, Доставка, Трио',
    },
    {
        id: 2456,
        artnumber: '10925',
        barcode: '36002',
        quantity: 277,
        price: 39266,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2456 для Королев, Недвижимость, Доставка, Трио',
    },
    {
        id: 2457,
        artnumber: '16339',
        barcode: '15917',
        quantity: 75,
        price: 16049,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2457 для Королев, Недвижимость, Доставка, Трио',
    },
    {
        id: 2458,
        artnumber: '32863',
        barcode: '84796',
        quantity: 247,
        price: 22702,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2458 для Королев, Недвижимость, Доставка, Трио',
    },
    {
        id: 2459,
        artnumber: '87235',
        barcode: '41864',
        quantity: 472,
        price: 1635,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2459 для Королев, Недвижимость, Доставка, Трио',
    },
    {
        id: 2460,
        artnumber: '55065',
        barcode: '14739',
        quantity: 162,
        price: 8623,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2460 для Королев, Недвижимость, Доставка, Трио',
    },
    {
        id: 2461,
        artnumber: '74909',
        barcode: '36956',
        quantity: 437,
        price: 42281,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2461 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2462,
        artnumber: '30146',
        barcode: '55167',
        quantity: 416,
        price: 3801,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2462 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2463,
        artnumber: '45301',
        barcode: '55098',
        quantity: 226,
        price: 26123,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2463 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2464,
        artnumber: '17186',
        barcode: '90567',
        quantity: 240,
        price: 47630,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2464 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2465,
        artnumber: '83802',
        barcode: '30816',
        quantity: 173,
        price: 12416,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2465 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2466,
        artnumber: '81304',
        barcode: '94686',
        quantity: 466,
        price: 3306,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2466 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2467,
        artnumber: '45039',
        barcode: '81118',
        quantity: 347,
        price: 47091,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2467 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2468,
        artnumber: '14733',
        barcode: '78978',
        quantity: 352,
        price: 5484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2468 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2469,
        artnumber: '56276',
        barcode: '23601',
        quantity: 453,
        price: 27884,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2469 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2470,
        artnumber: '40677',
        barcode: '24670',
        quantity: 372,
        price: 38986,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2470 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2471,
        artnumber: '13613',
        barcode: '60988',
        quantity: 411,
        price: 20784,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2471 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2472,
        artnumber: '56559',
        barcode: '55723',
        quantity: 39,
        price: 18031,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2472 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2473,
        artnumber: '29041',
        barcode: '25293',
        quantity: 59,
        price: 21231,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2473 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2474,
        artnumber: '41242',
        barcode: '53235',
        quantity: 433,
        price: 2321,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2474 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2475,
        artnumber: '73230',
        barcode: '41468',
        quantity: 241,
        price: 30451,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2475 для Королев, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 2476,
        artnumber: '26447',
        barcode: '66967',
        quantity: 349,
        price: 4648,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2476 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2477,
        artnumber: '42676',
        barcode: '58000',
        quantity: 188,
        price: 23850,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2477 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2478,
        artnumber: '76578',
        barcode: '21904',
        quantity: 139,
        price: 44722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2478 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2479,
        artnumber: '43463',
        barcode: '48550',
        quantity: 39,
        price: 11099,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2479 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2480,
        artnumber: '76552',
        barcode: '66457',
        quantity: 430,
        price: 35883,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2480 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2481,
        artnumber: '68528',
        barcode: '67638',
        quantity: 477,
        price: 28895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2481 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2482,
        artnumber: '64954',
        barcode: '42476',
        quantity: 39,
        price: 46611,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2482 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2483,
        artnumber: '35978',
        barcode: '64061',
        quantity: 41,
        price: 25212,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2483 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2484,
        artnumber: '80198',
        barcode: '29628',
        quantity: 357,
        price: 48037,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2484 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2485,
        artnumber: '96730',
        barcode: '17087',
        quantity: 426,
        price: 15914,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2485 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2486,
        artnumber: '95542',
        barcode: '98912',
        quantity: 143,
        price: 47041,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2486 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2487,
        artnumber: '37175',
        barcode: '40324',
        quantity: 136,
        price: 33962,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2487 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2488,
        artnumber: '50642',
        barcode: '62606',
        quantity: 261,
        price: 49561,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2488 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2489,
        artnumber: '52133',
        barcode: '72938',
        quantity: 455,
        price: 47978,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2489 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2490,
        artnumber: '93862',
        barcode: '84095',
        quantity: 31,
        price: 47913,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2490 для Королев, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 2491,
        artnumber: '39669',
        barcode: '63207',
        quantity: 432,
        price: 29464,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2491 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2492,
        artnumber: '60844',
        barcode: '54457',
        quantity: 7,
        price: 8031,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2492 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2493,
        artnumber: '41350',
        barcode: '96866',
        quantity: 12,
        price: 3739,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2493 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2494,
        artnumber: '98157',
        barcode: '77962',
        quantity: 295,
        price: 19780,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2494 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2495,
        artnumber: '24079',
        barcode: '65744',
        quantity: 77,
        price: 14722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2495 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2496,
        artnumber: '75808',
        barcode: '68062',
        quantity: 210,
        price: 36048,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2496 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2497,
        artnumber: '74046',
        barcode: '80390',
        quantity: 245,
        price: 26143,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2497 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2498,
        artnumber: '65380',
        barcode: '79608',
        quantity: 165,
        price: 44680,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2498 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2499,
        artnumber: '97654',
        barcode: '99465',
        quantity: 15,
        price: 41247,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2499 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2500,
        artnumber: '43575',
        barcode: '21417',
        quantity: 85,
        price: 9576,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2500 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2501,
        artnumber: '20387',
        barcode: '82746',
        quantity: 132,
        price: 107,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2501 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2502,
        artnumber: '74133',
        barcode: '33035',
        quantity: 173,
        price: 14484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2502 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2503,
        artnumber: '96413',
        barcode: '43786',
        quantity: 19,
        price: 33628,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2503 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2504,
        artnumber: '67598',
        barcode: '76127',
        quantity: 342,
        price: 31441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2504 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2505,
        artnumber: '67838',
        barcode: '35032',
        quantity: 458,
        price: 15930,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2505 для Королев, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 2506,
        artnumber: '47337',
        barcode: '35069',
        quantity: 169,
        price: 30882,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2506 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2507,
        artnumber: '95895',
        barcode: '94091',
        quantity: 376,
        price: 5264,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2507 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2508,
        artnumber: '95173',
        barcode: '64796',
        quantity: 348,
        price: 24382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2508 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2509,
        artnumber: '18579',
        barcode: '59233',
        quantity: 271,
        price: 7448,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2509 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2510,
        artnumber: '78309',
        barcode: '40150',
        quantity: 449,
        price: 27664,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2510 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2511,
        artnumber: '11782',
        barcode: '80776',
        quantity: 2,
        price: 45640,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2511 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2512,
        artnumber: '56888',
        barcode: '55788',
        quantity: 325,
        price: 30372,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2512 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2513,
        artnumber: '99422',
        barcode: '21518',
        quantity: 370,
        price: 36582,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2513 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2514,
        artnumber: '83399',
        barcode: '40396',
        quantity: 276,
        price: 24328,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2514 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2515,
        artnumber: '77743',
        barcode: '34280',
        quantity: 144,
        price: 36637,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2515 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2516,
        artnumber: '97844',
        barcode: '24725',
        quantity: 79,
        price: 22128,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2516 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2517,
        artnumber: '10354',
        barcode: '85081',
        quantity: 454,
        price: 5571,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2517 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2518,
        artnumber: '89121',
        barcode: '95461',
        quantity: 2,
        price: 30350,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2518 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2519,
        artnumber: '42388',
        barcode: '96294',
        quantity: 116,
        price: 14479,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2519 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2520,
        artnumber: '17752',
        barcode: '28189',
        quantity: 93,
        price: 2609,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2520 для Королев, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 2521,
        artnumber: '55915',
        barcode: '13593',
        quantity: 231,
        price: 1872,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2521 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2522,
        artnumber: '80794',
        barcode: '34180',
        quantity: 227,
        price: 44626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2522 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2523,
        artnumber: '80282',
        barcode: '93441',
        quantity: 66,
        price: 21313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2523 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2524,
        artnumber: '61289',
        barcode: '40075',
        quantity: 353,
        price: 736,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2524 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2525,
        artnumber: '39567',
        barcode: '47407',
        quantity: 299,
        price: 462,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2525 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2526,
        artnumber: '14504',
        barcode: '49057',
        quantity: 446,
        price: 10058,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2526 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2527,
        artnumber: '63233',
        barcode: '85908',
        quantity: 359,
        price: 33660,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2527 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2528,
        artnumber: '65678',
        barcode: '13922',
        quantity: 488,
        price: 32037,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2528 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2529,
        artnumber: '20824',
        barcode: '30869',
        quantity: 55,
        price: 41212,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2529 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2530,
        artnumber: '77585',
        barcode: '99344',
        quantity: 388,
        price: 40036,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2530 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2531,
        artnumber: '63397',
        barcode: '64190',
        quantity: 169,
        price: 33833,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2531 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2532,
        artnumber: '66079',
        barcode: '23352',
        quantity: 404,
        price: 46937,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2532 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2533,
        artnumber: '55877',
        barcode: '53395',
        quantity: 372,
        price: 44826,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2533 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2534,
        artnumber: '45703',
        barcode: '86945',
        quantity: 42,
        price: 9314,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2534 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2535,
        artnumber: '75415',
        barcode: '90969',
        quantity: 79,
        price: 16716,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2535 для Королев, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 2536,
        artnumber: '99007',
        barcode: '11058',
        quantity: 356,
        price: 39126,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2536 для Королев, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 2537,
        artnumber: '13039',
        barcode: '47586',
        quantity: 486,
        price: 24967,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2537 для Королев, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 2538,
        artnumber: '98321',
        barcode: '67937',
        quantity: 167,
        price: 16881,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2538 для Королев, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 2539,
        artnumber: '72569',
        barcode: '94755',
        quantity: 479,
        price: 16840,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2539 для Королев, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 2540,
        artnumber: '99535',
        barcode: '50976',
        quantity: 395,
        price: 3582,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2540 для Королев, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 2541,
        artnumber: '10230',
        barcode: '68732',
        quantity: 130,
        price: 9417,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2541 для Королев, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 2542,
        artnumber: '29233',
        barcode: '45529',
        quantity: 461,
        price: 37734,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2542 для Королев, Недвижимость, Замена масла, Строй',
    },
    {
        id: 2543,
        artnumber: '75686',
        barcode: '84321',
        quantity: 119,
        price: 8863,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2543 для Королев, Недвижимость, Замена масла, Строй',
    },
    {
        id: 2544,
        artnumber: '70560',
        barcode: '48347',
        quantity: 334,
        price: 14792,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2544 для Королев, Недвижимость, Замена масла, Строй',
    },
    {
        id: 2545,
        artnumber: '80029',
        barcode: '91816',
        quantity: 474,
        price: 33894,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2545 для Королев, Недвижимость, Замена масла, Строй',
    },
    {
        id: 2546,
        artnumber: '27457',
        barcode: '47294',
        quantity: 405,
        price: 7645,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2546 для Королев, Недвижимость, Замена масла, Строй',
    },
    {
        id: 2547,
        artnumber: '95111',
        barcode: '70218',
        quantity: 342,
        price: 39082,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2547 для Королев, Недвижимость, Замена масла, Строй',
    },
    {
        id: 2548,
        artnumber: '51206',
        barcode: '13045',
        quantity: 296,
        price: 35152,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2548 для Королев, Недвижимость, Замена масла, Бета',
    },
    {
        id: 2549,
        artnumber: '47918',
        barcode: '92692',
        quantity: 184,
        price: 36763,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2549 для Королев, Недвижимость, Замена масла, Бета',
    },
    {
        id: 2550,
        artnumber: '75176',
        barcode: '52025',
        quantity: 329,
        price: 6684,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2550 для Королев, Недвижимость, Замена масла, Бета',
    },
    {
        id: 2551,
        artnumber: '57436',
        barcode: '98089',
        quantity: 153,
        price: 26906,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2551 для Королев, Недвижимость, Замена масла, Бета',
    },
    {
        id: 2552,
        artnumber: '32727',
        barcode: '11306',
        quantity: 192,
        price: 42162,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2552 для Королев, Недвижимость, Замена масла, Бета',
    },
    {
        id: 2553,
        artnumber: '88044',
        barcode: '10657',
        quantity: 394,
        price: 12313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2553 для Королев, Недвижимость, Замена масла, Бета',
    },
    {
        id: 2554,
        artnumber: '34574',
        barcode: '84062',
        quantity: 245,
        price: 23448,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2554 для Королев, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 2555,
        artnumber: '40232',
        barcode: '13159',
        quantity: 268,
        price: 1685,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2555 для Королев, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 2556,
        artnumber: '56432',
        barcode: '64905',
        quantity: 267,
        price: 21534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2556 для Королев, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 2557,
        artnumber: '74938',
        barcode: '51159',
        quantity: 159,
        price: 28301,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2557 для Королев, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 2558,
        artnumber: '31334',
        barcode: '40323',
        quantity: 126,
        price: 16541,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2558 для Королев, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 2559,
        artnumber: '24697',
        barcode: '78045',
        quantity: 488,
        price: 22569,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2559 для Королев, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 2560,
        artnumber: '10691',
        barcode: '87643',
        quantity: 401,
        price: 30804,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2560 для Королев, Недвижимость, Замена масла, Трио',
    },
    {
        id: 2561,
        artnumber: '26245',
        barcode: '88701',
        quantity: 48,
        price: 43225,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2561 для Королев, Недвижимость, Замена масла, Трио',
    },
    {
        id: 2562,
        artnumber: '92481',
        barcode: '39207',
        quantity: 215,
        price: 25313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2562 для Королев, Недвижимость, Замена масла, Трио',
    },
    {
        id: 2563,
        artnumber: '79158',
        barcode: '36240',
        quantity: 280,
        price: 25222,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2563 для Королев, Недвижимость, Замена масла, Трио',
    },
    {
        id: 2564,
        artnumber: '13915',
        barcode: '20665',
        quantity: 345,
        price: 26581,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2564 для Королев, Недвижимость, Замена масла, Трио',
    },
    {
        id: 2565,
        artnumber: '64721',
        barcode: '53658',
        quantity: 480,
        price: 36001,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2565 для Королев, Недвижимость, Замена масла, Трио',
    },
    {
        id: 2566,
        artnumber: '65911',
        barcode: '32352',
        quantity: 191,
        price: 10235,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2566 для Королев, Красота, Сервис, Альфа',
    },
    {
        id: 2567,
        artnumber: '36844',
        barcode: '65313',
        quantity: 295,
        price: 45603,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2567 для Королев, Красота, Сервис, Альфа',
    },
    {
        id: 2568,
        artnumber: '88588',
        barcode: '91467',
        quantity: 221,
        price: 32984,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2568 для Королев, Красота, Сервис, Альфа',
    },
    {
        id: 2569,
        artnumber: '66128',
        barcode: '42166',
        quantity: 101,
        price: 10164,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2569 для Королев, Красота, Сервис, Альфа',
    },
    {
        id: 2570,
        artnumber: '89544',
        barcode: '43918',
        quantity: 109,
        price: 29136,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2570 для Королев, Красота, Сервис, Альфа',
    },
    {
        id: 2571,
        artnumber: '29166',
        barcode: '59628',
        quantity: 315,
        price: 29198,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2571 для Королев, Красота, Сервис, Альфа',
    },
    {
        id: 2572,
        artnumber: '29824',
        barcode: '92639',
        quantity: 74,
        price: 28925,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2572 для Королев, Красота, Сервис, Альфа',
    },
    {
        id: 2573,
        artnumber: '19702',
        barcode: '52198',
        quantity: 391,
        price: 3358,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2573 для Королев, Красота, Сервис, Строй',
    },
    {
        id: 2574,
        artnumber: '48063',
        barcode: '16982',
        quantity: 372,
        price: 3095,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2574 для Королев, Красота, Сервис, Строй',
    },
    {
        id: 2575,
        artnumber: '55082',
        barcode: '86810',
        quantity: 408,
        price: 7456,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2575 для Королев, Красота, Сервис, Строй',
    },
    {
        id: 2576,
        artnumber: '55453',
        barcode: '56757',
        quantity: 428,
        price: 44076,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2576 для Королев, Красота, Сервис, Строй',
    },
    {
        id: 2577,
        artnumber: '55767',
        barcode: '89747',
        quantity: 313,
        price: 38478,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2577 для Королев, Красота, Сервис, Строй',
    },
    {
        id: 2578,
        artnumber: '95232',
        barcode: '80617',
        quantity: 109,
        price: 13149,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2578 для Королев, Красота, Сервис, Строй',
    },
    {
        id: 2579,
        artnumber: '45409',
        barcode: '76717',
        quantity: 36,
        price: 5632,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2579 для Королев, Красота, Сервис, Строй',
    },
    {
        id: 2580,
        artnumber: '61255',
        barcode: '30201',
        quantity: 315,
        price: 38579,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2580 для Королев, Красота, Сервис, Бета',
    },
    {
        id: 2581,
        artnumber: '28518',
        barcode: '49576',
        quantity: 459,
        price: 10842,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2581 для Королев, Красота, Сервис, Бета',
    },
    {
        id: 2582,
        artnumber: '62074',
        barcode: '29777',
        quantity: 372,
        price: 46386,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2582 для Королев, Красота, Сервис, Бета',
    },
    {
        id: 2583,
        artnumber: '41776',
        barcode: '81392',
        quantity: 241,
        price: 8781,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2583 для Королев, Красота, Сервис, Бета',
    },
    {
        id: 2584,
        artnumber: '28266',
        barcode: '65876',
        quantity: 419,
        price: 32139,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2584 для Королев, Красота, Сервис, Бета',
    },
    {
        id: 2585,
        artnumber: '52166',
        barcode: '76311',
        quantity: 182,
        price: 27004,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2585 для Королев, Красота, Сервис, Бета',
    },
    {
        id: 2586,
        artnumber: '48845',
        barcode: '89275',
        quantity: 389,
        price: 24247,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2586 для Королев, Красота, Сервис, Бета',
    },
    {
        id: 2587,
        artnumber: '62133',
        barcode: '27327',
        quantity: 242,
        price: 13142,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2587 для Королев, Красота, Сервис, Гамма',
    },
    {
        id: 2588,
        artnumber: '66540',
        barcode: '51812',
        quantity: 132,
        price: 19420,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2588 для Королев, Красота, Сервис, Гамма',
    },
    {
        id: 2589,
        artnumber: '42235',
        barcode: '46901',
        quantity: 437,
        price: 31852,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2589 для Королев, Красота, Сервис, Гамма',
    },
    {
        id: 2590,
        artnumber: '91371',
        barcode: '40005',
        quantity: 443,
        price: 24518,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2590 для Королев, Красота, Сервис, Гамма',
    },
    {
        id: 2591,
        artnumber: '38946',
        barcode: '67429',
        quantity: 325,
        price: 23058,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2591 для Королев, Красота, Сервис, Гамма',
    },
    {
        id: 2592,
        artnumber: '87537',
        barcode: '75915',
        quantity: 352,
        price: 25317,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2592 для Королев, Красота, Сервис, Гамма',
    },
    {
        id: 2593,
        artnumber: '73162',
        barcode: '80868',
        quantity: 396,
        price: 37119,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2593 для Королев, Красота, Сервис, Гамма',
    },
    {
        id: 2594,
        artnumber: '15262',
        barcode: '64386',
        quantity: 141,
        price: 31895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2594 для Королев, Красота, Сервис, Трио',
    },
    {
        id: 2595,
        artnumber: '17047',
        barcode: '58638',
        quantity: 306,
        price: 22130,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2595 для Королев, Красота, Сервис, Трио',
    },
    {
        id: 2596,
        artnumber: '50252',
        barcode: '43424',
        quantity: 84,
        price: 2798,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2596 для Королев, Красота, Сервис, Трио',
    },
    {
        id: 2597,
        artnumber: '25000',
        barcode: '11626',
        quantity: 425,
        price: 39416,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2597 для Королев, Красота, Сервис, Трио',
    },
    {
        id: 2598,
        artnumber: '90268',
        barcode: '22164',
        quantity: 470,
        price: 41483,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2598 для Королев, Красота, Сервис, Трио',
    },
    {
        id: 2599,
        artnumber: '98378',
        barcode: '30402',
        quantity: 415,
        price: 29064,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2599 для Королев, Красота, Сервис, Трио',
    },
    {
        id: 2600,
        artnumber: '60759',
        barcode: '70601',
        quantity: 428,
        price: 32808,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2600 для Королев, Красота, Сервис, Трио',
    },
    {
        id: 2601,
        artnumber: '36803',
        barcode: '80560',
        quantity: 132,
        price: 14370,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2601 для Королев, Красота, Запчасти, Альфа',
    },
    {
        id: 2602,
        artnumber: '27157',
        barcode: '28092',
        quantity: 325,
        price: 11845,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2602 для Королев, Красота, Запчасти, Альфа',
    },
    {
        id: 2603,
        artnumber: '25433',
        barcode: '60075',
        quantity: 105,
        price: 46612,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2603 для Королев, Красота, Запчасти, Альфа',
    },
    {
        id: 2604,
        artnumber: '83862',
        barcode: '72718',
        quantity: 173,
        price: 45142,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2604 для Королев, Красота, Запчасти, Строй',
    },
    {
        id: 2605,
        artnumber: '52357',
        barcode: '79570',
        quantity: 61,
        price: 42780,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2605 для Королев, Красота, Запчасти, Строй',
    },
    {
        id: 2606,
        artnumber: '17650',
        barcode: '26135',
        quantity: 35,
        price: 39380,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2606 для Королев, Красота, Запчасти, Строй',
    },
    {
        id: 2607,
        artnumber: '98748',
        barcode: '99816',
        quantity: 417,
        price: 20335,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2607 для Королев, Красота, Запчасти, Бета',
    },
    {
        id: 2608,
        artnumber: '48088',
        barcode: '15642',
        quantity: 319,
        price: 7815,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2608 для Королев, Красота, Запчасти, Бета',
    },
    {
        id: 2609,
        artnumber: '79517',
        barcode: '63173',
        quantity: 385,
        price: 46813,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2609 для Королев, Красота, Запчасти, Бета',
    },
    {
        id: 2610,
        artnumber: '29082',
        barcode: '42771',
        quantity: 482,
        price: 5818,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2610 для Королев, Красота, Запчасти, Гамма',
    },
    {
        id: 2611,
        artnumber: '96486',
        barcode: '90658',
        quantity: 487,
        price: 1587,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2611 для Королев, Красота, Запчасти, Гамма',
    },
    {
        id: 2612,
        artnumber: '19416',
        barcode: '55852',
        quantity: 107,
        price: 15921,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2612 для Королев, Красота, Запчасти, Гамма',
    },
    {
        id: 2613,
        artnumber: '96888',
        barcode: '61822',
        quantity: 153,
        price: 32065,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2613 для Королев, Красота, Запчасти, Трио',
    },
    {
        id: 2614,
        artnumber: '99168',
        barcode: '41237',
        quantity: 412,
        price: 36243,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2614 для Королев, Красота, Запчасти, Трио',
    },
    {
        id: 2615,
        artnumber: '99755',
        barcode: '71964',
        quantity: 432,
        price: 3278,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2615 для Королев, Красота, Запчасти, Трио',
    },
    {
        id: 2616,
        artnumber: '75660',
        barcode: '44611',
        quantity: 82,
        price: 26807,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2616 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2617,
        artnumber: '75759',
        barcode: '82198',
        quantity: 366,
        price: 10636,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2617 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2618,
        artnumber: '11412',
        barcode: '47720',
        quantity: 450,
        price: 34112,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2618 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2619,
        artnumber: '74158',
        barcode: '59764',
        quantity: 47,
        price: 19735,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2619 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2620,
        artnumber: '99376',
        barcode: '45368',
        quantity: 305,
        price: 37535,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2620 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2621,
        artnumber: '54165',
        barcode: '43845',
        quantity: 448,
        price: 12572,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2621 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2622,
        artnumber: '20225',
        barcode: '26892',
        quantity: 381,
        price: 10963,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2622 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2623,
        artnumber: '93148',
        barcode: '26939',
        quantity: 60,
        price: 21508,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2623 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2624,
        artnumber: '92137',
        barcode: '89795',
        quantity: 217,
        price: 36264,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2624 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2625,
        artnumber: '29385',
        barcode: '23809',
        quantity: 223,
        price: 36288,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2625 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2626,
        artnumber: '89731',
        barcode: '76460',
        quantity: 49,
        price: 6675,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2626 для Королев, Красота, Доставка, Альфа',
    },
    {
        id: 2627,
        artnumber: '73201',
        barcode: '53855',
        quantity: 230,
        price: 23437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2627 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2628,
        artnumber: '87335',
        barcode: '54917',
        quantity: 187,
        price: 44206,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2628 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2629,
        artnumber: '71502',
        barcode: '15089',
        quantity: 473,
        price: 24848,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2629 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2630,
        artnumber: '40133',
        barcode: '25936',
        quantity: 487,
        price: 19370,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2630 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2631,
        artnumber: '62679',
        barcode: '80569',
        quantity: 201,
        price: 10591,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2631 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2632,
        artnumber: '28765',
        barcode: '62705',
        quantity: 429,
        price: 7076,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2632 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2633,
        artnumber: '99698',
        barcode: '61443',
        quantity: 332,
        price: 48835,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2633 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2634,
        artnumber: '81145',
        barcode: '81599',
        quantity: 35,
        price: 29807,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2634 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2635,
        artnumber: '24517',
        barcode: '28130',
        quantity: 425,
        price: 32934,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2635 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2636,
        artnumber: '12489',
        barcode: '40043',
        quantity: 173,
        price: 25199,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2636 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2637,
        artnumber: '45676',
        barcode: '79131',
        quantity: 207,
        price: 47755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2637 для Королев, Красота, Доставка, Строй',
    },
    {
        id: 2638,
        artnumber: '89528',
        barcode: '86028',
        quantity: 381,
        price: 21860,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2638 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2639,
        artnumber: '27185',
        barcode: '17358',
        quantity: 222,
        price: 34486,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2639 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2640,
        artnumber: '94442',
        barcode: '76884',
        quantity: 148,
        price: 34358,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2640 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2641,
        artnumber: '87277',
        barcode: '22002',
        quantity: 483,
        price: 1774,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2641 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2642,
        artnumber: '13701',
        barcode: '62986',
        quantity: 466,
        price: 3196,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2642 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2643,
        artnumber: '99479',
        barcode: '27758',
        quantity: 13,
        price: 44286,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2643 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2644,
        artnumber: '33373',
        barcode: '98493',
        quantity: 402,
        price: 29700,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2644 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2645,
        artnumber: '64636',
        barcode: '71706',
        quantity: 81,
        price: 42439,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2645 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2646,
        artnumber: '23972',
        barcode: '20588',
        quantity: 47,
        price: 15649,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2646 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2647,
        artnumber: '92950',
        barcode: '14745',
        quantity: 187,
        price: 33796,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2647 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2648,
        artnumber: '49370',
        barcode: '66004',
        quantity: 440,
        price: 22605,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2648 для Королев, Красота, Доставка, Бета',
    },
    {
        id: 2649,
        artnumber: '57678',
        barcode: '15114',
        quantity: 86,
        price: 32541,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2649 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2650,
        artnumber: '26397',
        barcode: '61437',
        quantity: 183,
        price: 11929,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2650 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2651,
        artnumber: '38968',
        barcode: '15796',
        quantity: 266,
        price: 27693,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2651 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2652,
        artnumber: '85087',
        barcode: '97134',
        quantity: 477,
        price: 26853,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2652 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2653,
        artnumber: '23057',
        barcode: '63055',
        quantity: 38,
        price: 28623,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2653 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2654,
        artnumber: '79224',
        barcode: '75862',
        quantity: 425,
        price: 429,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2654 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2655,
        artnumber: '32720',
        barcode: '60351',
        quantity: 392,
        price: 24164,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2655 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2656,
        artnumber: '26127',
        barcode: '21449',
        quantity: 187,
        price: 44298,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2656 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2657,
        artnumber: '35686',
        barcode: '43275',
        quantity: 361,
        price: 19972,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2657 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2658,
        artnumber: '67049',
        barcode: '32615',
        quantity: 239,
        price: 29095,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2658 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2659,
        artnumber: '46137',
        barcode: '73951',
        quantity: 481,
        price: 14066,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2659 для Королев, Красота, Доставка, Гамма',
    },
    {
        id: 2660,
        artnumber: '31282',
        barcode: '93430',
        quantity: 391,
        price: 48605,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2660 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2661,
        artnumber: '11582',
        barcode: '41933',
        quantity: 182,
        price: 22685,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2661 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2662,
        artnumber: '87585',
        barcode: '10487',
        quantity: 362,
        price: 3798,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2662 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2663,
        artnumber: '65463',
        barcode: '50504',
        quantity: 479,
        price: 21632,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2663 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2664,
        artnumber: '20444',
        barcode: '15186',
        quantity: 23,
        price: 13626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2664 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2665,
        artnumber: '93425',
        barcode: '94262',
        quantity: 361,
        price: 22253,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2665 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2666,
        artnumber: '81020',
        barcode: '56531',
        quantity: 282,
        price: 33390,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2666 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2667,
        artnumber: '68272',
        barcode: '70754',
        quantity: 13,
        price: 39102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2667 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2668,
        artnumber: '67085',
        barcode: '58366',
        quantity: 254,
        price: 21112,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2668 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2669,
        artnumber: '56348',
        barcode: '74133',
        quantity: 440,
        price: 37478,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2669 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2670,
        artnumber: '60551',
        barcode: '93965',
        quantity: 25,
        price: 32404,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2670 для Королев, Красота, Доставка, Трио',
    },
    {
        id: 2671,
        artnumber: '98578',
        barcode: '78472',
        quantity: 460,
        price: 46436,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2671 для Королев, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 2672,
        artnumber: '44884',
        barcode: '42424',
        quantity: 372,
        price: 48920,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2672 для Королев, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 2673,
        artnumber: '47958',
        barcode: '66376',
        quantity: 3,
        price: 8090,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2673 для Королев, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 2674,
        artnumber: '99762',
        barcode: '33805',
        quantity: 174,
        price: 35138,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2674 для Королев, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 2675,
        artnumber: '75302',
        barcode: '91149',
        quantity: 346,
        price: 3655,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2675 для Королев, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 2676,
        artnumber: '67514',
        barcode: '51524',
        quantity: 38,
        price: 44060,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2676 для Королев, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 2677,
        artnumber: '92616',
        barcode: '91300',
        quantity: 9,
        price: 22770,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2677 для Королев, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 2678,
        artnumber: '72073',
        barcode: '41827',
        quantity: 160,
        price: 16642,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2678 для Королев, Красота, Шиномонтаж, Строй',
    },
    {
        id: 2679,
        artnumber: '31379',
        barcode: '20444',
        quantity: 1,
        price: 13582,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2679 для Королев, Красота, Шиномонтаж, Строй',
    },
    {
        id: 2680,
        artnumber: '87257',
        barcode: '59372',
        quantity: 194,
        price: 37770,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2680 для Королев, Красота, Шиномонтаж, Строй',
    },
    {
        id: 2681,
        artnumber: '76893',
        barcode: '50534',
        quantity: 26,
        price: 14504,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2681 для Королев, Красота, Шиномонтаж, Строй',
    },
    {
        id: 2682,
        artnumber: '83539',
        barcode: '81301',
        quantity: 284,
        price: 25142,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2682 для Королев, Красота, Шиномонтаж, Строй',
    },
    {
        id: 2683,
        artnumber: '91166',
        barcode: '21263',
        quantity: 156,
        price: 14030,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2683 для Королев, Красота, Шиномонтаж, Строй',
    },
    {
        id: 2684,
        artnumber: '57556',
        barcode: '37778',
        quantity: 140,
        price: 10699,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2684 для Королев, Красота, Шиномонтаж, Строй',
    },
    {
        id: 2685,
        artnumber: '21790',
        barcode: '34784',
        quantity: 160,
        price: 45326,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2685 для Королев, Красота, Шиномонтаж, Бета',
    },
    {
        id: 2686,
        artnumber: '76461',
        barcode: '99283',
        quantity: 88,
        price: 24425,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2686 для Королев, Красота, Шиномонтаж, Бета',
    },
    {
        id: 2687,
        artnumber: '41624',
        barcode: '33743',
        quantity: 85,
        price: 6232,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2687 для Королев, Красота, Шиномонтаж, Бета',
    },
    {
        id: 2688,
        artnumber: '96365',
        barcode: '41790',
        quantity: 138,
        price: 8699,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2688 для Королев, Красота, Шиномонтаж, Бета',
    },
    {
        id: 2689,
        artnumber: '89474',
        barcode: '17458',
        quantity: 187,
        price: 35259,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2689 для Королев, Красота, Шиномонтаж, Бета',
    },
    {
        id: 2690,
        artnumber: '72838',
        barcode: '43293',
        quantity: 199,
        price: 12635,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2690 для Королев, Красота, Шиномонтаж, Бета',
    },
    {
        id: 2691,
        artnumber: '99993',
        barcode: '50948',
        quantity: 266,
        price: 49793,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2691 для Королев, Красота, Шиномонтаж, Бета',
    },
    {
        id: 2692,
        artnumber: '41654',
        barcode: '65282',
        quantity: 360,
        price: 27012,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2692 для Королев, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 2693,
        artnumber: '86929',
        barcode: '94721',
        quantity: 150,
        price: 41217,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2693 для Королев, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 2694,
        artnumber: '74634',
        barcode: '85155',
        quantity: 187,
        price: 2198,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2694 для Королев, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 2695,
        artnumber: '93626',
        barcode: '68271',
        quantity: 406,
        price: 40103,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2695 для Королев, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 2696,
        artnumber: '76415',
        barcode: '97297',
        quantity: 395,
        price: 44652,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2696 для Королев, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 2697,
        artnumber: '86581',
        barcode: '55703',
        quantity: 235,
        price: 9251,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2697 для Королев, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 2698,
        artnumber: '69548',
        barcode: '83575',
        quantity: 14,
        price: 38042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2698 для Королев, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 2699,
        artnumber: '64887',
        barcode: '86102',
        quantity: 351,
        price: 26631,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2699 для Королев, Красота, Шиномонтаж, Трио',
    },
    {
        id: 2700,
        artnumber: '55920',
        barcode: '38409',
        quantity: 84,
        price: 227,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2700 для Королев, Красота, Шиномонтаж, Трио',
    },
    {
        id: 2701,
        artnumber: '18262',
        barcode: '88839',
        quantity: 91,
        price: 45565,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2701 для Королев, Красота, Шиномонтаж, Трио',
    },
    {
        id: 2702,
        artnumber: '57609',
        barcode: '19259',
        quantity: 265,
        price: 171,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2702 для Королев, Красота, Шиномонтаж, Трио',
    },
    {
        id: 2703,
        artnumber: '49418',
        barcode: '67149',
        quantity: 211,
        price: 25230,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2703 для Королев, Красота, Шиномонтаж, Трио',
    },
    {
        id: 2704,
        artnumber: '52951',
        barcode: '68641',
        quantity: 310,
        price: 25733,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2704 для Королев, Красота, Шиномонтаж, Трио',
    },
    {
        id: 2705,
        artnumber: '79976',
        barcode: '87341',
        quantity: 465,
        price: 19175,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2705 для Королев, Красота, Шиномонтаж, Трио',
    },
    {
        id: 2706,
        artnumber: '91209',
        barcode: '99359',
        quantity: 441,
        price: 26774,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2706 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2707,
        artnumber: '27823',
        barcode: '45371',
        quantity: 386,
        price: 43450,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2707 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2708,
        artnumber: '64106',
        barcode: '95765',
        quantity: 106,
        price: 13894,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2708 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2709,
        artnumber: '50406',
        barcode: '66597',
        quantity: 266,
        price: 28199,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2709 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2710,
        artnumber: '68519',
        barcode: '29129',
        quantity: 161,
        price: 22303,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2710 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2711,
        artnumber: '78066',
        barcode: '33837',
        quantity: 108,
        price: 47449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2711 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2712,
        artnumber: '76497',
        barcode: '96677',
        quantity: 303,
        price: 13544,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2712 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2713,
        artnumber: '89511',
        barcode: '60074',
        quantity: 363,
        price: 11267,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2713 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2714,
        artnumber: '32822',
        barcode: '86557',
        quantity: 490,
        price: 19903,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2714 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2715,
        artnumber: '94638',
        barcode: '37592',
        quantity: 384,
        price: 1401,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2715 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2716,
        artnumber: '96459',
        barcode: '49184',
        quantity: 356,
        price: 25208,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2716 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2717,
        artnumber: '95964',
        barcode: '37262',
        quantity: 154,
        price: 31585,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2717 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2718,
        artnumber: '90660',
        barcode: '37349',
        quantity: 320,
        price: 28246,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2718 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2719,
        artnumber: '41512',
        barcode: '95566',
        quantity: 121,
        price: 32045,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2719 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2720,
        artnumber: '67267',
        barcode: '90291',
        quantity: 392,
        price: 28124,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2720 для Королев, Красота, Замена масла, Альфа',
    },
    {
        id: 2721,
        artnumber: '49859',
        barcode: '54838',
        quantity: 373,
        price: 18481,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2721 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2722,
        artnumber: '71941',
        barcode: '93017',
        quantity: 54,
        price: 9779,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2722 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2723,
        artnumber: '60432',
        barcode: '17350',
        quantity: 388,
        price: 32633,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2723 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2724,
        artnumber: '51176',
        barcode: '60378',
        quantity: 270,
        price: 45822,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2724 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2725,
        artnumber: '93746',
        barcode: '67198',
        quantity: 272,
        price: 40247,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2725 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2726,
        artnumber: '95678',
        barcode: '63543',
        quantity: 313,
        price: 34650,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2726 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2727,
        artnumber: '51083',
        barcode: '11639',
        quantity: 446,
        price: 3804,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2727 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2728,
        artnumber: '22716',
        barcode: '71265',
        quantity: 450,
        price: 24197,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2728 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2729,
        artnumber: '54625',
        barcode: '14254',
        quantity: 407,
        price: 27624,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2729 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2730,
        artnumber: '10154',
        barcode: '19278',
        quantity: 414,
        price: 21235,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2730 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2731,
        artnumber: '18286',
        barcode: '89578',
        quantity: 341,
        price: 35977,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2731 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2732,
        artnumber: '44185',
        barcode: '55676',
        quantity: 354,
        price: 14545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2732 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2733,
        artnumber: '87073',
        barcode: '10352',
        quantity: 20,
        price: 22821,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2733 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2734,
        artnumber: '55674',
        barcode: '29993',
        quantity: 500,
        price: 7086,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2734 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2735,
        artnumber: '81474',
        barcode: '89355',
        quantity: 354,
        price: 38663,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2735 для Королев, Красота, Замена масла, Строй',
    },
    {
        id: 2736,
        artnumber: '34992',
        barcode: '80128',
        quantity: 192,
        price: 32612,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2736 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2737,
        artnumber: '29895',
        barcode: '31417',
        quantity: 183,
        price: 39519,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2737 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2738,
        artnumber: '88503',
        barcode: '99045',
        quantity: 468,
        price: 4125,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2738 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2739,
        artnumber: '97917',
        barcode: '43664',
        quantity: 480,
        price: 29277,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2739 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2740,
        artnumber: '49553',
        barcode: '78437',
        quantity: 339,
        price: 42874,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2740 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2741,
        artnumber: '29882',
        barcode: '64272',
        quantity: 471,
        price: 15233,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2741 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2742,
        artnumber: '57015',
        barcode: '79886',
        quantity: 148,
        price: 30997,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2742 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2743,
        artnumber: '16595',
        barcode: '80769',
        quantity: 340,
        price: 37394,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2743 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2744,
        artnumber: '91668',
        barcode: '12858',
        quantity: 467,
        price: 22125,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2744 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2745,
        artnumber: '84348',
        barcode: '54742',
        quantity: 300,
        price: 45355,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2745 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2746,
        artnumber: '82363',
        barcode: '63920',
        quantity: 252,
        price: 10525,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2746 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2747,
        artnumber: '80059',
        barcode: '84925',
        quantity: 436,
        price: 31405,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2747 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2748,
        artnumber: '20328',
        barcode: '75707',
        quantity: 186,
        price: 10314,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2748 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2749,
        artnumber: '94748',
        barcode: '35527',
        quantity: 52,
        price: 56,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2749 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2750,
        artnumber: '63382',
        barcode: '40529',
        quantity: 216,
        price: 39446,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2750 для Королев, Красота, Замена масла, Бета',
    },
    {
        id: 2751,
        artnumber: '11667',
        barcode: '52346',
        quantity: 22,
        price: 32831,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2751 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2752,
        artnumber: '73437',
        barcode: '67709',
        quantity: 86,
        price: 18638,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2752 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2753,
        artnumber: '74815',
        barcode: '18516',
        quantity: 408,
        price: 37865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2753 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2754,
        artnumber: '92444',
        barcode: '44944',
        quantity: 269,
        price: 30341,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2754 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2755,
        artnumber: '16832',
        barcode: '67951',
        quantity: 352,
        price: 42953,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2755 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2756,
        artnumber: '29796',
        barcode: '78478',
        quantity: 119,
        price: 48043,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2756 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2757,
        artnumber: '46949',
        barcode: '67619',
        quantity: 49,
        price: 19658,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2757 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2758,
        artnumber: '15896',
        barcode: '86760',
        quantity: 211,
        price: 19575,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2758 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2759,
        artnumber: '95188',
        barcode: '13265',
        quantity: 298,
        price: 1825,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2759 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2760,
        artnumber: '31908',
        barcode: '55866',
        quantity: 457,
        price: 46798,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2760 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2761,
        artnumber: '50604',
        barcode: '78449',
        quantity: 124,
        price: 17020,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2761 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2762,
        artnumber: '41851',
        barcode: '66459',
        quantity: 282,
        price: 8541,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2762 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2763,
        artnumber: '13061',
        barcode: '85127',
        quantity: 380,
        price: 23475,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2763 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2764,
        artnumber: '34914',
        barcode: '97623',
        quantity: 434,
        price: 1898,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2764 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2765,
        artnumber: '91513',
        barcode: '61804',
        quantity: 355,
        price: 48789,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2765 для Королев, Красота, Замена масла, Гамма',
    },
    {
        id: 2766,
        artnumber: '87963',
        barcode: '13443',
        quantity: 323,
        price: 2034,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2766 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2767,
        artnumber: '10685',
        barcode: '75806',
        quantity: 11,
        price: 27280,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2767 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2768,
        artnumber: '90296',
        barcode: '67702',
        quantity: 456,
        price: 23674,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2768 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2769,
        artnumber: '62536',
        barcode: '98925',
        quantity: 430,
        price: 37260,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2769 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2770,
        artnumber: '21365',
        barcode: '44318',
        quantity: 432,
        price: 17109,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2770 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2771,
        artnumber: '95070',
        barcode: '60315',
        quantity: 58,
        price: 4607,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2771 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2772,
        artnumber: '71689',
        barcode: '93932',
        quantity: 461,
        price: 28885,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2772 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2773,
        artnumber: '80753',
        barcode: '13903',
        quantity: 153,
        price: 39068,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2773 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2774,
        artnumber: '98920',
        barcode: '90833',
        quantity: 429,
        price: 8555,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2774 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2775,
        artnumber: '60938',
        barcode: '16905',
        quantity: 166,
        price: 42321,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2775 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2776,
        artnumber: '46653',
        barcode: '47541',
        quantity: 114,
        price: 2176,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2776 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2777,
        artnumber: '46885',
        barcode: '11118',
        quantity: 309,
        price: 14042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2777 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2778,
        artnumber: '65250',
        barcode: '81744',
        quantity: 449,
        price: 33876,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2778 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2779,
        artnumber: '75613',
        barcode: '91942',
        quantity: 16,
        price: 15617,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2779 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2780,
        artnumber: '25626',
        barcode: '44525',
        quantity: 8,
        price: 9695,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2780 для Королев, Красота, Замена масла, Трио',
    },
    {
        id: 2781,
        artnumber: '26280',
        barcode: '52677',
        quantity: 477,
        price: 47892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2781 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2782,
        artnumber: '59175',
        barcode: '95051',
        quantity: 96,
        price: 21373,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2782 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2783,
        artnumber: '57325',
        barcode: '51699',
        quantity: 38,
        price: 20274,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2783 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2784,
        artnumber: '39000',
        barcode: '85375',
        quantity: 48,
        price: 37694,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2784 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2785,
        artnumber: '79185',
        barcode: '70889',
        quantity: 364,
        price: 18432,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2785 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2786,
        artnumber: '68382',
        barcode: '22438',
        quantity: 368,
        price: 7010,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2786 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2787,
        artnumber: '35865',
        barcode: '19882',
        quantity: 469,
        price: 37924,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2787 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2788,
        artnumber: '46418',
        barcode: '87059',
        quantity: 421,
        price: 21728,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2788 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2789,
        artnumber: '28493',
        barcode: '22584',
        quantity: 314,
        price: 29919,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2789 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2790,
        artnumber: '82296',
        barcode: '16863',
        quantity: 206,
        price: 31033,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2790 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2791,
        artnumber: '48356',
        barcode: '12710',
        quantity: 249,
        price: 6610,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2791 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2792,
        artnumber: '96985',
        barcode: '34980',
        quantity: 202,
        price: 32300,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2792 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2793,
        artnumber: '11465',
        barcode: '71305',
        quantity: 317,
        price: 6398,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2793 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2794,
        artnumber: '91931',
        barcode: '40648',
        quantity: 371,
        price: 23476,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2794 для Королев, Здоровье, Сервис, Альфа',
    },
    {
        id: 2795,
        artnumber: '79866',
        barcode: '71129',
        quantity: 304,
        price: 3198,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2795 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2796,
        artnumber: '77684',
        barcode: '56549',
        quantity: 144,
        price: 8997,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2796 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2797,
        artnumber: '72426',
        barcode: '28528',
        quantity: 200,
        price: 33243,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2797 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2798,
        artnumber: '61205',
        barcode: '67898',
        quantity: 449,
        price: 5156,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2798 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2799,
        artnumber: '18147',
        barcode: '82247',
        quantity: 243,
        price: 27637,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2799 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2800,
        artnumber: '98096',
        barcode: '69303',
        quantity: 445,
        price: 1084,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2800 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2801,
        artnumber: '27829',
        barcode: '84258',
        quantity: 330,
        price: 23533,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2801 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2802,
        artnumber: '81350',
        barcode: '85890',
        quantity: 372,
        price: 44679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2802 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2803,
        artnumber: '38092',
        barcode: '28260',
        quantity: 165,
        price: 31391,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2803 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2804,
        artnumber: '31868',
        barcode: '40124',
        quantity: 139,
        price: 21892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2804 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2805,
        artnumber: '44269',
        barcode: '90932',
        quantity: 14,
        price: 6641,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2805 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2806,
        artnumber: '19112',
        barcode: '69670',
        quantity: 36,
        price: 42612,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2806 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2807,
        artnumber: '48600',
        barcode: '18113',
        quantity: 172,
        price: 31754,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2807 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2808,
        artnumber: '48230',
        barcode: '48886',
        quantity: 468,
        price: 24384,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2808 для Королев, Здоровье, Сервис, Строй',
    },
    {
        id: 2809,
        artnumber: '84889',
        barcode: '11284',
        quantity: 451,
        price: 27658,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2809 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2810,
        artnumber: '61508',
        barcode: '31299',
        quantity: 141,
        price: 45363,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2810 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2811,
        artnumber: '94825',
        barcode: '52563',
        quantity: 339,
        price: 38478,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2811 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2812,
        artnumber: '65678',
        barcode: '80077',
        quantity: 488,
        price: 39009,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2812 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2813,
        artnumber: '44855',
        barcode: '72646',
        quantity: 270,
        price: 36399,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2813 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2814,
        artnumber: '74387',
        barcode: '15871',
        quantity: 281,
        price: 43588,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2814 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2815,
        artnumber: '41800',
        barcode: '13491',
        quantity: 175,
        price: 9723,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2815 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2816,
        artnumber: '23609',
        barcode: '89119',
        quantity: 356,
        price: 12396,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2816 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2817,
        artnumber: '98830',
        barcode: '67727',
        quantity: 489,
        price: 46931,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2817 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2818,
        artnumber: '52382',
        barcode: '21749',
        quantity: 190,
        price: 47805,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2818 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2819,
        artnumber: '96647',
        barcode: '84291',
        quantity: 64,
        price: 16982,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2819 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2820,
        artnumber: '43188',
        barcode: '77471',
        quantity: 115,
        price: 15780,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2820 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2821,
        artnumber: '65979',
        barcode: '38165',
        quantity: 270,
        price: 15844,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2821 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2822,
        artnumber: '50250',
        barcode: '79041',
        quantity: 135,
        price: 28781,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2822 для Королев, Здоровье, Сервис, Бета',
    },
    {
        id: 2823,
        artnumber: '32704',
        barcode: '34163',
        quantity: 453,
        price: 8782,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2823 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2824,
        artnumber: '66080',
        barcode: '20353',
        quantity: 334,
        price: 3856,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2824 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2825,
        artnumber: '36214',
        barcode: '38992',
        quantity: 388,
        price: 9750,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2825 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2826,
        artnumber: '90938',
        barcode: '75173',
        quantity: 153,
        price: 2301,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2826 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2827,
        artnumber: '49847',
        barcode: '80323',
        quantity: 152,
        price: 41345,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2827 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2828,
        artnumber: '47675',
        barcode: '46935',
        quantity: 283,
        price: 23081,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2828 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2829,
        artnumber: '90030',
        barcode: '64875',
        quantity: 440,
        price: 829,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2829 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2830,
        artnumber: '93076',
        barcode: '95007',
        quantity: 313,
        price: 20450,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2830 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2831,
        artnumber: '60079',
        barcode: '79717',
        quantity: 463,
        price: 10446,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2831 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2832,
        artnumber: '15476',
        barcode: '21010',
        quantity: 297,
        price: 28104,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2832 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2833,
        artnumber: '35078',
        barcode: '92150',
        quantity: 397,
        price: 31354,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2833 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2834,
        artnumber: '92998',
        barcode: '90342',
        quantity: 239,
        price: 1731,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2834 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2835,
        artnumber: '74324',
        barcode: '39882',
        quantity: 132,
        price: 32008,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2835 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2836,
        artnumber: '26799',
        barcode: '82072',
        quantity: 119,
        price: 46654,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2836 для Королев, Здоровье, Сервис, Гамма',
    },
    {
        id: 2837,
        artnumber: '79406',
        barcode: '98068',
        quantity: 91,
        price: 27192,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2837 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2838,
        artnumber: '43716',
        barcode: '68590',
        quantity: 214,
        price: 28326,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2838 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2839,
        artnumber: '48475',
        barcode: '84590',
        quantity: 441,
        price: 7623,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2839 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2840,
        artnumber: '64846',
        barcode: '30110',
        quantity: 212,
        price: 10396,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2840 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2841,
        artnumber: '58003',
        barcode: '23740',
        quantity: 396,
        price: 39373,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2841 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2842,
        artnumber: '62929',
        barcode: '57180',
        quantity: 328,
        price: 41154,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2842 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2843,
        artnumber: '29906',
        barcode: '40753',
        quantity: 228,
        price: 32532,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2843 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2844,
        artnumber: '49568',
        barcode: '71335',
        quantity: 227,
        price: 44317,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2844 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2845,
        artnumber: '31962',
        barcode: '65524',
        quantity: 348,
        price: 24573,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2845 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2846,
        artnumber: '36762',
        barcode: '86333',
        quantity: 366,
        price: 9315,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2846 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2847,
        artnumber: '73467',
        barcode: '79575',
        quantity: 61,
        price: 26743,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2847 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2848,
        artnumber: '31519',
        barcode: '61266',
        quantity: 305,
        price: 14346,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2848 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2849,
        artnumber: '47506',
        barcode: '94361',
        quantity: 488,
        price: 29302,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2849 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2850,
        artnumber: '74688',
        barcode: '83147',
        quantity: 224,
        price: 47672,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2850 для Королев, Здоровье, Сервис, Трио',
    },
    {
        id: 2851,
        artnumber: '55090',
        barcode: '78063',
        quantity: 49,
        price: 25625,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2851 для Королев, Здоровье, Запчасти, Альфа',
    },
    {
        id: 2852,
        artnumber: '76511',
        barcode: '58218',
        quantity: 74,
        price: 17588,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2852 для Королев, Здоровье, Запчасти, Альфа',
    },
    {
        id: 2853,
        artnumber: '42529',
        barcode: '81022',
        quantity: 373,
        price: 17084,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2853 для Королев, Здоровье, Запчасти, Альфа',
    },
    {
        id: 2854,
        artnumber: '70563',
        barcode: '73777',
        quantity: 82,
        price: 32940,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2854 для Королев, Здоровье, Запчасти, Альфа',
    },
    {
        id: 2855,
        artnumber: '85864',
        barcode: '51160',
        quantity: 222,
        price: 10870,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2855 для Королев, Здоровье, Запчасти, Альфа',
    },
    {
        id: 2856,
        artnumber: '35309',
        barcode: '88585',
        quantity: 324,
        price: 10518,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2856 для Королев, Здоровье, Запчасти, Альфа',
    },
    {
        id: 2857,
        artnumber: '70105',
        barcode: '90932',
        quantity: 238,
        price: 22694,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2857 для Королев, Здоровье, Запчасти, Альфа',
    },
    {
        id: 2858,
        artnumber: '68225',
        barcode: '28568',
        quantity: 230,
        price: 424,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2858 для Королев, Здоровье, Запчасти, Альфа',
    },
    {
        id: 2859,
        artnumber: '41520',
        barcode: '75213',
        quantity: 52,
        price: 17686,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2859 для Королев, Здоровье, Запчасти, Альфа',
    },
    {
        id: 2860,
        artnumber: '53388',
        barcode: '15026',
        quantity: 221,
        price: 3736,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2860 для Королев, Здоровье, Запчасти, Альфа',
    },
    {
        id: 2861,
        artnumber: '75113',
        barcode: '99503',
        quantity: 431,
        price: 10366,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2861 для Королев, Здоровье, Запчасти, Строй',
    },
    {
        id: 2862,
        artnumber: '74711',
        barcode: '99627',
        quantity: 213,
        price: 44087,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2862 для Королев, Здоровье, Запчасти, Строй',
    },
    {
        id: 2863,
        artnumber: '80597',
        barcode: '63867',
        quantity: 52,
        price: 31699,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2863 для Королев, Здоровье, Запчасти, Строй',
    },
    {
        id: 2864,
        artnumber: '66294',
        barcode: '43933',
        quantity: 257,
        price: 41939,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2864 для Королев, Здоровье, Запчасти, Строй',
    },
    {
        id: 2865,
        artnumber: '33469',
        barcode: '89343',
        quantity: 100,
        price: 32048,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2865 для Королев, Здоровье, Запчасти, Строй',
    },
    {
        id: 2866,
        artnumber: '88006',
        barcode: '58298',
        quantity: 444,
        price: 15796,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2866 для Королев, Здоровье, Запчасти, Строй',
    },
    {
        id: 2867,
        artnumber: '98893',
        barcode: '25538',
        quantity: 275,
        price: 38215,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2867 для Королев, Здоровье, Запчасти, Строй',
    },
    {
        id: 2868,
        artnumber: '13677',
        barcode: '59016',
        quantity: 302,
        price: 30429,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2868 для Королев, Здоровье, Запчасти, Строй',
    },
    {
        id: 2869,
        artnumber: '84837',
        barcode: '51793',
        quantity: 98,
        price: 14549,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2869 для Королев, Здоровье, Запчасти, Строй',
    },
    {
        id: 2870,
        artnumber: '87431',
        barcode: '50180',
        quantity: 9,
        price: 7794,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2870 для Королев, Здоровье, Запчасти, Строй',
    },
    {
        id: 2871,
        artnumber: '60415',
        barcode: '82760',
        quantity: 454,
        price: 13207,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2871 для Королев, Здоровье, Запчасти, Бета',
    },
    {
        id: 2872,
        artnumber: '26743',
        barcode: '59980',
        quantity: 382,
        price: 15260,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2872 для Королев, Здоровье, Запчасти, Бета',
    },
    {
        id: 2873,
        artnumber: '32019',
        barcode: '63379',
        quantity: 373,
        price: 33752,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2873 для Королев, Здоровье, Запчасти, Бета',
    },
    {
        id: 2874,
        artnumber: '94928',
        barcode: '93880',
        quantity: 36,
        price: 31017,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2874 для Королев, Здоровье, Запчасти, Бета',
    },
    {
        id: 2875,
        artnumber: '42319',
        barcode: '42938',
        quantity: 16,
        price: 27554,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2875 для Королев, Здоровье, Запчасти, Бета',
    },
    {
        id: 2876,
        artnumber: '67088',
        barcode: '63929',
        quantity: 489,
        price: 33616,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2876 для Королев, Здоровье, Запчасти, Бета',
    },
    {
        id: 2877,
        artnumber: '42255',
        barcode: '57505',
        quantity: 369,
        price: 30757,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2877 для Королев, Здоровье, Запчасти, Бета',
    },
    {
        id: 2878,
        artnumber: '65311',
        barcode: '26602',
        quantity: 24,
        price: 39953,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2878 для Королев, Здоровье, Запчасти, Бета',
    },
    {
        id: 2879,
        artnumber: '79974',
        barcode: '76327',
        quantity: 296,
        price: 38496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2879 для Королев, Здоровье, Запчасти, Бета',
    },
    {
        id: 2880,
        artnumber: '65395',
        barcode: '84503',
        quantity: 481,
        price: 28876,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2880 для Королев, Здоровье, Запчасти, Бета',
    },
    {
        id: 2881,
        artnumber: '78026',
        barcode: '75780',
        quantity: 304,
        price: 22986,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2881 для Королев, Здоровье, Запчасти, Гамма',
    },
    {
        id: 2882,
        artnumber: '99505',
        barcode: '73313',
        quantity: 126,
        price: 34249,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2882 для Королев, Здоровье, Запчасти, Гамма',
    },
    {
        id: 2883,
        artnumber: '13639',
        barcode: '19368',
        quantity: 357,
        price: 10784,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2883 для Королев, Здоровье, Запчасти, Гамма',
    },
    {
        id: 2884,
        artnumber: '25135',
        barcode: '52503',
        quantity: 392,
        price: 19145,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2884 для Королев, Здоровье, Запчасти, Гамма',
    },
    {
        id: 2885,
        artnumber: '57848',
        barcode: '28336',
        quantity: 210,
        price: 40380,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2885 для Королев, Здоровье, Запчасти, Гамма',
    },
    {
        id: 2886,
        artnumber: '68593',
        barcode: '28900',
        quantity: 178,
        price: 18450,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2886 для Королев, Здоровье, Запчасти, Гамма',
    },
    {
        id: 2887,
        artnumber: '24361',
        barcode: '38020',
        quantity: 445,
        price: 21270,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2887 для Королев, Здоровье, Запчасти, Гамма',
    },
    {
        id: 2888,
        artnumber: '88535',
        barcode: '56931',
        quantity: 439,
        price: 21360,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2888 для Королев, Здоровье, Запчасти, Гамма',
    },
    {
        id: 2889,
        artnumber: '22191',
        barcode: '96951',
        quantity: 266,
        price: 44742,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2889 для Королев, Здоровье, Запчасти, Гамма',
    },
    {
        id: 2890,
        artnumber: '36885',
        barcode: '25590',
        quantity: 9,
        price: 42384,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2890 для Королев, Здоровье, Запчасти, Гамма',
    },
    {
        id: 2891,
        artnumber: '61837',
        barcode: '39121',
        quantity: 296,
        price: 31461,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2891 для Королев, Здоровье, Запчасти, Трио',
    },
    {
        id: 2892,
        artnumber: '53519',
        barcode: '73453',
        quantity: 457,
        price: 17458,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2892 для Королев, Здоровье, Запчасти, Трио',
    },
    {
        id: 2893,
        artnumber: '95622',
        barcode: '34948',
        quantity: 466,
        price: 31874,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2893 для Королев, Здоровье, Запчасти, Трио',
    },
    {
        id: 2894,
        artnumber: '65427',
        barcode: '62664',
        quantity: 209,
        price: 26979,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2894 для Королев, Здоровье, Запчасти, Трио',
    },
    {
        id: 2895,
        artnumber: '26500',
        barcode: '44332',
        quantity: 336,
        price: 29589,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2895 для Королев, Здоровье, Запчасти, Трио',
    },
    {
        id: 2896,
        artnumber: '11954',
        barcode: '14046',
        quantity: 259,
        price: 20258,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2896 для Королев, Здоровье, Запчасти, Трио',
    },
    {
        id: 2897,
        artnumber: '53452',
        barcode: '79451',
        quantity: 222,
        price: 43172,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2897 для Королев, Здоровье, Запчасти, Трио',
    },
    {
        id: 2898,
        artnumber: '90538',
        barcode: '83657',
        quantity: 372,
        price: 15137,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2898 для Королев, Здоровье, Запчасти, Трио',
    },
    {
        id: 2899,
        artnumber: '74562',
        barcode: '24461',
        quantity: 302,
        price: 43877,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2899 для Королев, Здоровье, Запчасти, Трио',
    },
    {
        id: 2900,
        artnumber: '68301',
        barcode: '43087',
        quantity: 156,
        price: 47695,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2900 для Королев, Здоровье, Запчасти, Трио',
    },
    {
        id: 2901,
        artnumber: '29183',
        barcode: '69339',
        quantity: 120,
        price: 13575,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2901 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2902,
        artnumber: '32258',
        barcode: '33058',
        quantity: 354,
        price: 19096,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2902 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2903,
        artnumber: '58730',
        barcode: '17440',
        quantity: 197,
        price: 574,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2903 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2904,
        artnumber: '42170',
        barcode: '21608',
        quantity: 404,
        price: 43263,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2904 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2905,
        artnumber: '39691',
        barcode: '75803',
        quantity: 203,
        price: 41172,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2905 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2906,
        artnumber: '96686',
        barcode: '38051',
        quantity: 337,
        price: 38947,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2906 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2907,
        artnumber: '64629',
        barcode: '22512',
        quantity: 371,
        price: 16645,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2907 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2908,
        artnumber: '90042',
        barcode: '86301',
        quantity: 328,
        price: 151,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2908 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2909,
        artnumber: '15482',
        barcode: '87168',
        quantity: 104,
        price: 15259,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2909 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2910,
        artnumber: '82277',
        barcode: '73961',
        quantity: 360,
        price: 29676,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2910 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2911,
        artnumber: '43475',
        barcode: '36037',
        quantity: 350,
        price: 41037,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2911 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2912,
        artnumber: '81544',
        barcode: '43694',
        quantity: 360,
        price: 23647,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2912 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2913,
        artnumber: '36937',
        barcode: '99449',
        quantity: 464,
        price: 11662,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2913 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2914,
        artnumber: '39964',
        barcode: '73616',
        quantity: 214,
        price: 40310,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2914 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2915,
        artnumber: '14398',
        barcode: '55334',
        quantity: 208,
        price: 10877,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2915 для Королев, Здоровье, Доставка, Альфа',
    },
    {
        id: 2916,
        artnumber: '85932',
        barcode: '34308',
        quantity: 387,
        price: 34011,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2916 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2917,
        artnumber: '84672',
        barcode: '27419',
        quantity: 445,
        price: 47430,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2917 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2918,
        artnumber: '14353',
        barcode: '39008',
        quantity: 271,
        price: 21580,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2918 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2919,
        artnumber: '92453',
        barcode: '62037',
        quantity: 306,
        price: 18714,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2919 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2920,
        artnumber: '75311',
        barcode: '94086',
        quantity: 408,
        price: 34076,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2920 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2921,
        artnumber: '84254',
        barcode: '59327',
        quantity: 488,
        price: 49000,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2921 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2922,
        artnumber: '15000',
        barcode: '89785',
        quantity: 7,
        price: 42902,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2922 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2923,
        artnumber: '46204',
        barcode: '44419',
        quantity: 97,
        price: 36704,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2923 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2924,
        artnumber: '20984',
        barcode: '33417',
        quantity: 408,
        price: 12092,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2924 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2925,
        artnumber: '51006',
        barcode: '39154',
        quantity: 292,
        price: 34858,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2925 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2926,
        artnumber: '11195',
        barcode: '76214',
        quantity: 292,
        price: 23086,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2926 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2927,
        artnumber: '52883',
        barcode: '40120',
        quantity: 370,
        price: 44271,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2927 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2928,
        artnumber: '37088',
        barcode: '64757',
        quantity: 73,
        price: 9421,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2928 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2929,
        artnumber: '51349',
        barcode: '86152',
        quantity: 432,
        price: 30593,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2929 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2930,
        artnumber: '29107',
        barcode: '27750',
        quantity: 480,
        price: 2284,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2930 для Королев, Здоровье, Доставка, Строй',
    },
    {
        id: 2931,
        artnumber: '91877',
        barcode: '40597',
        quantity: 445,
        price: 1186,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2931 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2932,
        artnumber: '77061',
        barcode: '46040',
        quantity: 55,
        price: 29939,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2932 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2933,
        artnumber: '11874',
        barcode: '40285',
        quantity: 63,
        price: 12737,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2933 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2934,
        artnumber: '96820',
        barcode: '90501',
        quantity: 376,
        price: 4599,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2934 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2935,
        artnumber: '13999',
        barcode: '31562',
        quantity: 370,
        price: 23326,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2935 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2936,
        artnumber: '83596',
        barcode: '66002',
        quantity: 158,
        price: 32536,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2936 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2937,
        artnumber: '57016',
        barcode: '97269',
        quantity: 337,
        price: 8137,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2937 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2938,
        artnumber: '33274',
        barcode: '57484',
        quantity: 249,
        price: 8049,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2938 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2939,
        artnumber: '23313',
        barcode: '62914',
        quantity: 357,
        price: 5558,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2939 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2940,
        artnumber: '64187',
        barcode: '43975',
        quantity: 316,
        price: 1377,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2940 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2941,
        artnumber: '46236',
        barcode: '94428',
        quantity: 98,
        price: 45241,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2941 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2942,
        artnumber: '56374',
        barcode: '63208',
        quantity: 13,
        price: 34872,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2942 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2943,
        artnumber: '92201',
        barcode: '60884',
        quantity: 378,
        price: 18310,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2943 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2944,
        artnumber: '31792',
        barcode: '97383',
        quantity: 413,
        price: 9273,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2944 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2945,
        artnumber: '40536',
        barcode: '33879',
        quantity: 55,
        price: 22885,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2945 для Королев, Здоровье, Доставка, Бета',
    },
    {
        id: 2946,
        artnumber: '74984',
        barcode: '58970',
        quantity: 284,
        price: 43050,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2946 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2947,
        artnumber: '96997',
        barcode: '92729',
        quantity: 106,
        price: 39567,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2947 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2948,
        artnumber: '38760',
        barcode: '59399',
        quantity: 327,
        price: 33272,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2948 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2949,
        artnumber: '19820',
        barcode: '51647',
        quantity: 498,
        price: 25533,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2949 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2950,
        artnumber: '24434',
        barcode: '69865',
        quantity: 176,
        price: 2621,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2950 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2951,
        artnumber: '96784',
        barcode: '76790',
        quantity: 256,
        price: 2696,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2951 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2952,
        artnumber: '33481',
        barcode: '76103',
        quantity: 377,
        price: 8754,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2952 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2953,
        artnumber: '10266',
        barcode: '31749',
        quantity: 450,
        price: 37358,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2953 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2954,
        artnumber: '46411',
        barcode: '55705',
        quantity: 362,
        price: 20003,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2954 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2955,
        artnumber: '48243',
        barcode: '92935',
        quantity: 496,
        price: 40008,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2955 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2956,
        artnumber: '63428',
        barcode: '14367',
        quantity: 76,
        price: 49199,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2956 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2957,
        artnumber: '48735',
        barcode: '60110',
        quantity: 279,
        price: 34815,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2957 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2958,
        artnumber: '40222',
        barcode: '89850',
        quantity: 80,
        price: 1725,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2958 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2959,
        artnumber: '57751',
        barcode: '19151',
        quantity: 129,
        price: 20091,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2959 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2960,
        artnumber: '14507',
        barcode: '90582',
        quantity: 179,
        price: 361,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2960 для Королев, Здоровье, Доставка, Гамма',
    },
    {
        id: 2961,
        artnumber: '99292',
        barcode: '72283',
        quantity: 101,
        price: 12449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2961 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2962,
        artnumber: '42658',
        barcode: '79894',
        quantity: 13,
        price: 42396,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2962 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2963,
        artnumber: '75182',
        barcode: '79431',
        quantity: 448,
        price: 1337,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2963 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2964,
        artnumber: '62692',
        barcode: '16026',
        quantity: 181,
        price: 34278,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2964 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2965,
        artnumber: '92351',
        barcode: '96944',
        quantity: 486,
        price: 10178,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2965 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2966,
        artnumber: '63805',
        barcode: '43072',
        quantity: 39,
        price: 2753,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2966 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2967,
        artnumber: '47654',
        barcode: '84695',
        quantity: 410,
        price: 13450,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2967 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2968,
        artnumber: '59590',
        barcode: '25627',
        quantity: 87,
        price: 31347,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2968 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2969,
        artnumber: '94329',
        barcode: '52726',
        quantity: 141,
        price: 47030,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2969 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2970,
        artnumber: '49953',
        barcode: '56214',
        quantity: 97,
        price: 30527,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2970 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2971,
        artnumber: '62215',
        barcode: '94623',
        quantity: 214,
        price: 42453,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 2971 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2972,
        artnumber: '93647',
        barcode: '23706',
        quantity: 177,
        price: 39562,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2972 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2973,
        artnumber: '29144',
        barcode: '31853',
        quantity: 437,
        price: 25721,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2973 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2974,
        artnumber: '77641',
        barcode: '93926',
        quantity: 474,
        price: 41630,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2974 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2975,
        artnumber: '21623',
        barcode: '49119',
        quantity: 470,
        price: 32401,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2975 для Королев, Здоровье, Доставка, Трио',
    },
    {
        id: 2976,
        artnumber: '91010',
        barcode: '42618',
        quantity: 302,
        price: 30983,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2976 для Королев, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 2977,
        artnumber: '34155',
        barcode: '21614',
        quantity: 180,
        price: 14723,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2977 для Королев, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 2978,
        artnumber: '85934',
        barcode: '53898',
        quantity: 423,
        price: 9449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2978 для Королев, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 2979,
        artnumber: '72935',
        barcode: '96998',
        quantity: 495,
        price: 22633,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2979 для Королев, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 2980,
        artnumber: '62141',
        barcode: '29576',
        quantity: 254,
        price: 20769,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2980 для Королев, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 2981,
        artnumber: '30024',
        barcode: '75325',
        quantity: 378,
        price: 7403,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2981 для Королев, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 2982,
        artnumber: '59331',
        barcode: '79779',
        quantity: 449,
        price: 47461,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2982 для Королев, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 2983,
        artnumber: '18490',
        barcode: '76227',
        quantity: 462,
        price: 3650,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2983 для Королев, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 2984,
        artnumber: '21729',
        barcode: '89295',
        quantity: 298,
        price: 4155,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2984 для Королев, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 2985,
        artnumber: '61956',
        barcode: '45770',
        quantity: 498,
        price: 534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2985 для Королев, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 2986,
        artnumber: '62380',
        barcode: '25953',
        quantity: 394,
        price: 28990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2986 для Королев, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 2987,
        artnumber: '28843',
        barcode: '42367',
        quantity: 454,
        price: 31873,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2987 для Королев, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 2988,
        artnumber: '38172',
        barcode: '55477',
        quantity: 69,
        price: 44251,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 2988 для Королев, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 2989,
        artnumber: '73857',
        barcode: '91096',
        quantity: 232,
        price: 25529,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2989 для Королев, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 2990,
        artnumber: '70120',
        barcode: '93061',
        quantity: 125,
        price: 43936,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 2990 для Королев, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 2991,
        artnumber: '10784',
        barcode: '13734',
        quantity: 500,
        price: 14804,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2991 для Королев, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 2992,
        artnumber: '90077',
        barcode: '40258',
        quantity: 280,
        price: 46369,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 2992 для Королев, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 2993,
        artnumber: '58622',
        barcode: '59213',
        quantity: 392,
        price: 47293,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 2993 для Королев, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 2994,
        artnumber: '47483',
        barcode: '20403',
        quantity: 237,
        price: 46992,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2994 для Королев, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 2995,
        artnumber: '20964',
        barcode: '91685',
        quantity: 10,
        price: 31818,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 2995 для Королев, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 2996,
        artnumber: '47028',
        barcode: '50534',
        quantity: 291,
        price: 18806,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2996 для Королев, Здоровье, Замена масла, Альфа',
    },
    {
        id: 2997,
        artnumber: '72614',
        barcode: '82034',
        quantity: 251,
        price: 3679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 2997 для Королев, Здоровье, Замена масла, Альфа',
    },
    {
        id: 2998,
        artnumber: '18246',
        barcode: '28055',
        quantity: 280,
        price: 23720,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 2998 для Королев, Здоровье, Замена масла, Альфа',
    },
    {
        id: 2999,
        artnumber: '89000',
        barcode: '48563',
        quantity: 66,
        price: 48504,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 2999 для Королев, Здоровье, Замена масла, Альфа',
    },
    {
        id: 3000,
        artnumber: '17124',
        barcode: '62591',
        quantity: 217,
        price: 19572,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3000 для Королев, Здоровье, Замена масла, Альфа',
    },
    {
        id: 3001,
        artnumber: '32227',
        barcode: '12026',
        quantity: 254,
        price: 18334,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3001 для Королев, Здоровье, Замена масла, Альфа',
    },
    {
        id: 3002,
        artnumber: '97806',
        barcode: '63253',
        quantity: 408,
        price: 24452,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3002 для Королев, Здоровье, Замена масла, Альфа',
    },
    {
        id: 3003,
        artnumber: '55501',
        barcode: '78528',
        quantity: 264,
        price: 32134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3003 для Королев, Здоровье, Замена масла, Строй',
    },
    {
        id: 3004,
        artnumber: '57004',
        barcode: '93964',
        quantity: 402,
        price: 32928,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3004 для Королев, Здоровье, Замена масла, Строй',
    },
    {
        id: 3005,
        artnumber: '77973',
        barcode: '41855',
        quantity: 323,
        price: 9063,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3005 для Королев, Здоровье, Замена масла, Строй',
    },
    {
        id: 3006,
        artnumber: '30627',
        barcode: '32008',
        quantity: 233,
        price: 6416,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3006 для Королев, Здоровье, Замена масла, Строй',
    },
    {
        id: 3007,
        artnumber: '80174',
        barcode: '99656',
        quantity: 62,
        price: 18655,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3007 для Королев, Здоровье, Замена масла, Строй',
    },
    {
        id: 3008,
        artnumber: '65746',
        barcode: '93470',
        quantity: 496,
        price: 18692,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3008 для Королев, Здоровье, Замена масла, Строй',
    },
    {
        id: 3009,
        artnumber: '14095',
        barcode: '96304',
        quantity: 37,
        price: 45756,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3009 для Королев, Здоровье, Замена масла, Строй',
    },
    {
        id: 3010,
        artnumber: '88937',
        barcode: '38328',
        quantity: 140,
        price: 49443,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3010 для Королев, Здоровье, Замена масла, Бета',
    },
    {
        id: 3011,
        artnumber: '41687',
        barcode: '53183',
        quantity: 135,
        price: 6686,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3011 для Королев, Здоровье, Замена масла, Бета',
    },
    {
        id: 3012,
        artnumber: '66428',
        barcode: '12426',
        quantity: 165,
        price: 29484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3012 для Королев, Здоровье, Замена масла, Бета',
    },
    {
        id: 3013,
        artnumber: '30899',
        barcode: '27587',
        quantity: 316,
        price: 43691,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3013 для Королев, Здоровье, Замена масла, Бета',
    },
    {
        id: 3014,
        artnumber: '67999',
        barcode: '83700',
        quantity: 96,
        price: 5252,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3014 для Королев, Здоровье, Замена масла, Бета',
    },
    {
        id: 3015,
        artnumber: '42027',
        barcode: '33699',
        quantity: 311,
        price: 28885,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3015 для Королев, Здоровье, Замена масла, Бета',
    },
    {
        id: 3016,
        artnumber: '88002',
        barcode: '29602',
        quantity: 173,
        price: 32317,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3016 для Королев, Здоровье, Замена масла, Бета',
    },
    {
        id: 3017,
        artnumber: '73464',
        barcode: '71464',
        quantity: 334,
        price: 49800,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3017 для Королев, Здоровье, Замена масла, Гамма',
    },
    {
        id: 3018,
        artnumber: '97650',
        barcode: '44319',
        quantity: 429,
        price: 26787,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3018 для Королев, Здоровье, Замена масла, Гамма',
    },
    {
        id: 3019,
        artnumber: '76654',
        barcode: '84555',
        quantity: 315,
        price: 48929,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3019 для Королев, Здоровье, Замена масла, Гамма',
    },
    {
        id: 3020,
        artnumber: '33443',
        barcode: '81926',
        quantity: 324,
        price: 18972,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3020 для Королев, Здоровье, Замена масла, Гамма',
    },
    {
        id: 3021,
        artnumber: '42037',
        barcode: '58039',
        quantity: 374,
        price: 43726,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3021 для Королев, Здоровье, Замена масла, Гамма',
    },
    {
        id: 3022,
        artnumber: '29083',
        barcode: '21329',
        quantity: 299,
        price: 30315,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3022 для Королев, Здоровье, Замена масла, Гамма',
    },
    {
        id: 3023,
        artnumber: '69963',
        barcode: '86192',
        quantity: 497,
        price: 12229,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3023 для Королев, Здоровье, Замена масла, Гамма',
    },
    {
        id: 3024,
        artnumber: '43534',
        barcode: '81520',
        quantity: 208,
        price: 43086,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3024 для Королев, Здоровье, Замена масла, Трио',
    },
    {
        id: 3025,
        artnumber: '38465',
        barcode: '67923',
        quantity: 200,
        price: 19446,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3025 для Королев, Здоровье, Замена масла, Трио',
    },
    {
        id: 3026,
        artnumber: '49781',
        barcode: '92356',
        quantity: 437,
        price: 17200,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3026 для Королев, Здоровье, Замена масла, Трио',
    },
    {
        id: 3027,
        artnumber: '96159',
        barcode: '43944',
        quantity: 210,
        price: 4281,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3027 для Королев, Здоровье, Замена масла, Трио',
    },
    {
        id: 3028,
        artnumber: '39087',
        barcode: '25844',
        quantity: 75,
        price: 10752,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3028 для Королев, Здоровье, Замена масла, Трио',
    },
    {
        id: 3029,
        artnumber: '26990',
        barcode: '47440',
        quantity: 343,
        price: 46412,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3029 для Королев, Здоровье, Замена масла, Трио',
    },
    {
        id: 3030,
        artnumber: '41093',
        barcode: '96404',
        quantity: 387,
        price: 42046,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3030 для Королев, Здоровье, Замена масла, Трио',
    },
    {
        id: 3031,
        artnumber: '45062',
        barcode: '58509',
        quantity: 240,
        price: 3144,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3031 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3032,
        artnumber: '68649',
        barcode: '46045',
        quantity: 313,
        price: 33367,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3032 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3033,
        artnumber: '23141',
        barcode: '64946',
        quantity: 76,
        price: 14329,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3033 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3034,
        artnumber: '24189',
        barcode: '12199',
        quantity: 15,
        price: 46357,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3034 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3035,
        artnumber: '85178',
        barcode: '99990',
        quantity: 240,
        price: 27505,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3035 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3036,
        artnumber: '97622',
        barcode: '78561',
        quantity: 1,
        price: 34588,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3036 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3037,
        artnumber: '91134',
        barcode: '80436',
        quantity: 270,
        price: 24656,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3037 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3038,
        artnumber: '88133',
        barcode: '45476',
        quantity: 2,
        price: 34398,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3038 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3039,
        artnumber: '70428',
        barcode: '10631',
        quantity: 58,
        price: 12895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3039 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3040,
        artnumber: '11519',
        barcode: '11280',
        quantity: 428,
        price: 40556,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3040 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3041,
        artnumber: '85606',
        barcode: '75784',
        quantity: 46,
        price: 991,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3041 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3042,
        artnumber: '64644',
        barcode: '95946',
        quantity: 492,
        price: 34747,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3042 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3043,
        artnumber: '31999',
        barcode: '58349',
        quantity: 231,
        price: 31319,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3043 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3044,
        artnumber: '51218',
        barcode: '47294',
        quantity: 191,
        price: 27576,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3044 для Королев, Техника, Сервис, Альфа',
    },
    {
        id: 3045,
        artnumber: '50463',
        barcode: '53685',
        quantity: 373,
        price: 25802,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3045 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3046,
        artnumber: '92439',
        barcode: '86999',
        quantity: 102,
        price: 35750,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3046 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3047,
        artnumber: '40112',
        barcode: '70129',
        quantity: 205,
        price: 34205,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3047 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3048,
        artnumber: '78430',
        barcode: '37104',
        quantity: 457,
        price: 39138,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3048 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3049,
        artnumber: '70594',
        barcode: '66495',
        quantity: 223,
        price: 37270,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3049 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3050,
        artnumber: '54903',
        barcode: '87994',
        quantity: 430,
        price: 45298,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3050 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3051,
        artnumber: '29574',
        barcode: '85205',
        quantity: 301,
        price: 1848,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3051 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3052,
        artnumber: '50652',
        barcode: '40861',
        quantity: 282,
        price: 44263,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3052 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3053,
        artnumber: '61794',
        barcode: '60509',
        quantity: 275,
        price: 26465,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3053 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3054,
        artnumber: '12910',
        barcode: '40166',
        quantity: 464,
        price: 22820,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3054 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3055,
        artnumber: '73356',
        barcode: '59534',
        quantity: 495,
        price: 41187,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3055 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3056,
        artnumber: '99697',
        barcode: '86616',
        quantity: 25,
        price: 5799,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3056 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3057,
        artnumber: '52312',
        barcode: '63701',
        quantity: 186,
        price: 41730,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3057 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3058,
        artnumber: '89543',
        barcode: '65762',
        quantity: 425,
        price: 42436,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3058 для Королев, Техника, Сервис, Строй',
    },
    {
        id: 3059,
        artnumber: '35302',
        barcode: '59541',
        quantity: 427,
        price: 39272,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3059 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3060,
        artnumber: '66569',
        barcode: '80005',
        quantity: 192,
        price: 33590,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3060 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3061,
        artnumber: '27257',
        barcode: '61249',
        quantity: 387,
        price: 6079,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3061 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3062,
        artnumber: '10629',
        barcode: '72945',
        quantity: 176,
        price: 42880,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3062 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3063,
        artnumber: '79206',
        barcode: '59199',
        quantity: 114,
        price: 49461,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3063 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3064,
        artnumber: '50483',
        barcode: '10732',
        quantity: 351,
        price: 14396,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3064 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3065,
        artnumber: '60996',
        barcode: '39402',
        quantity: 37,
        price: 34597,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3065 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3066,
        artnumber: '13650',
        barcode: '92184',
        quantity: 198,
        price: 8562,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3066 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3067,
        artnumber: '62274',
        barcode: '46748',
        quantity: 139,
        price: 14801,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3067 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3068,
        artnumber: '29897',
        barcode: '42708',
        quantity: 119,
        price: 45161,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3068 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3069,
        artnumber: '69627',
        barcode: '12783',
        quantity: 385,
        price: 36960,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3069 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3070,
        artnumber: '52583',
        barcode: '91856',
        quantity: 25,
        price: 40545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3070 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3071,
        artnumber: '38493',
        barcode: '48379',
        quantity: 336,
        price: 35615,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3071 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3072,
        artnumber: '89573',
        barcode: '53420',
        quantity: 60,
        price: 36750,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3072 для Королев, Техника, Сервис, Бета',
    },
    {
        id: 3073,
        artnumber: '11401',
        barcode: '30415',
        quantity: 151,
        price: 6211,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3073 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3074,
        artnumber: '21984',
        barcode: '39559',
        quantity: 45,
        price: 2979,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3074 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3075,
        artnumber: '27395',
        barcode: '92815',
        quantity: 7,
        price: 45653,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3075 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3076,
        artnumber: '73270',
        barcode: '83629',
        quantity: 369,
        price: 46294,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3076 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3077,
        artnumber: '34742',
        barcode: '75499',
        quantity: 157,
        price: 36550,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3077 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3078,
        artnumber: '85497',
        barcode: '74616',
        quantity: 15,
        price: 45887,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3078 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3079,
        artnumber: '54650',
        barcode: '11389',
        quantity: 5,
        price: 33084,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3079 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3080,
        artnumber: '28308',
        barcode: '34865',
        quantity: 203,
        price: 32148,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3080 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3081,
        artnumber: '12063',
        barcode: '50402',
        quantity: 398,
        price: 7405,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3081 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3082,
        artnumber: '72814',
        barcode: '21061',
        quantity: 408,
        price: 23321,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3082 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3083,
        artnumber: '38438',
        barcode: '93479',
        quantity: 212,
        price: 38099,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3083 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3084,
        artnumber: '12638',
        barcode: '77978',
        quantity: 180,
        price: 35725,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3084 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3085,
        artnumber: '27876',
        barcode: '46357',
        quantity: 404,
        price: 19877,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3085 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3086,
        artnumber: '76655',
        barcode: '16765',
        quantity: 132,
        price: 3125,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3086 для Королев, Техника, Сервис, Гамма',
    },
    {
        id: 3087,
        artnumber: '40828',
        barcode: '61580',
        quantity: 240,
        price: 49892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3087 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3088,
        artnumber: '37500',
        barcode: '78998',
        quantity: 122,
        price: 37061,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3088 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3089,
        artnumber: '24085',
        barcode: '92410',
        quantity: 74,
        price: 10219,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3089 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3090,
        artnumber: '27323',
        barcode: '41611',
        quantity: 234,
        price: 26354,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3090 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3091,
        artnumber: '59797',
        barcode: '80859',
        quantity: 455,
        price: 2496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3091 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3092,
        artnumber: '98888',
        barcode: '25700',
        quantity: 59,
        price: 49734,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3092 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3093,
        artnumber: '47788',
        barcode: '87325',
        quantity: 172,
        price: 44585,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3093 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3094,
        artnumber: '91479',
        barcode: '66575',
        quantity: 106,
        price: 49069,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3094 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3095,
        artnumber: '79869',
        barcode: '65489',
        quantity: 34,
        price: 22241,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3095 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3096,
        artnumber: '10027',
        barcode: '51176',
        quantity: 223,
        price: 39811,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3096 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3097,
        artnumber: '96815',
        barcode: '88214',
        quantity: 292,
        price: 13151,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3097 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3098,
        artnumber: '86225',
        barcode: '31866',
        quantity: 422,
        price: 21382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3098 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3099,
        artnumber: '91273',
        barcode: '39499',
        quantity: 47,
        price: 20685,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3099 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3100,
        artnumber: '18630',
        barcode: '20376',
        quantity: 175,
        price: 20312,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3100 для Королев, Техника, Сервис, Трио',
    },
    {
        id: 3101,
        artnumber: '52411',
        barcode: '72816',
        quantity: 487,
        price: 12577,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3101 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3102,
        artnumber: '84089',
        barcode: '63509',
        quantity: 253,
        price: 47120,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3102 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3103,
        artnumber: '53076',
        barcode: '42515',
        quantity: 405,
        price: 45499,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3103 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3104,
        artnumber: '78811',
        barcode: '87292',
        quantity: 59,
        price: 44387,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3104 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3105,
        artnumber: '66436',
        barcode: '10518',
        quantity: 321,
        price: 44727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3105 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3106,
        artnumber: '26194',
        barcode: '35704',
        quantity: 164,
        price: 23852,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3106 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3107,
        artnumber: '13156',
        barcode: '34055',
        quantity: 155,
        price: 34694,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3107 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3108,
        artnumber: '68738',
        barcode: '85830',
        quantity: 466,
        price: 12115,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3108 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3109,
        artnumber: '98256',
        barcode: '51937',
        quantity: 191,
        price: 17309,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3109 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3110,
        artnumber: '77894',
        barcode: '23335',
        quantity: 412,
        price: 47755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3110 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3111,
        artnumber: '95525',
        barcode: '29703',
        quantity: 67,
        price: 45411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3111 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3112,
        artnumber: '41016',
        barcode: '29010',
        quantity: 220,
        price: 5426,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3112 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3113,
        artnumber: '78078',
        barcode: '19895',
        quantity: 301,
        price: 10024,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3113 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3114,
        artnumber: '16355',
        barcode: '78946',
        quantity: 6,
        price: 47243,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3114 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3115,
        artnumber: '42361',
        barcode: '38562',
        quantity: 201,
        price: 37776,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3115 для Королев, Техника, Запчасти, Альфа',
    },
    {
        id: 3116,
        artnumber: '17670',
        barcode: '31782',
        quantity: 393,
        price: 42632,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3116 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3117,
        artnumber: '59241',
        barcode: '59187',
        quantity: 36,
        price: 2367,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3117 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3118,
        artnumber: '39645',
        barcode: '80426',
        quantity: 136,
        price: 19542,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3118 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3119,
        artnumber: '19794',
        barcode: '25228',
        quantity: 6,
        price: 12126,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3119 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3120,
        artnumber: '83177',
        barcode: '30947',
        quantity: 279,
        price: 13143,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3120 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3121,
        artnumber: '92928',
        barcode: '97061',
        quantity: 309,
        price: 8568,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3121 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3122,
        artnumber: '63185',
        barcode: '72793',
        quantity: 59,
        price: 41473,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3122 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3123,
        artnumber: '35365',
        barcode: '94815',
        quantity: 497,
        price: 21181,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3123 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3124,
        artnumber: '77636',
        barcode: '80516',
        quantity: 42,
        price: 47085,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3124 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3125,
        artnumber: '58839',
        barcode: '72027',
        quantity: 263,
        price: 43543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3125 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3126,
        artnumber: '84499',
        barcode: '33295',
        quantity: 184,
        price: 49716,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3126 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3127,
        artnumber: '73662',
        barcode: '46233',
        quantity: 21,
        price: 32337,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3127 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3128,
        artnumber: '20693',
        barcode: '51683',
        quantity: 213,
        price: 42900,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3128 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3129,
        artnumber: '23636',
        barcode: '74371',
        quantity: 27,
        price: 47389,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3129 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3130,
        artnumber: '78712',
        barcode: '40958',
        quantity: 278,
        price: 30433,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3130 для Королев, Техника, Запчасти, Строй',
    },
    {
        id: 3131,
        artnumber: '71507',
        barcode: '42245',
        quantity: 26,
        price: 21143,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3131 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3132,
        artnumber: '84115',
        barcode: '30909',
        quantity: 323,
        price: 47882,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3132 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3133,
        artnumber: '74292',
        barcode: '52282',
        quantity: 449,
        price: 42411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3133 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3134,
        artnumber: '66034',
        barcode: '62613',
        quantity: 308,
        price: 9441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3134 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3135,
        artnumber: '88854',
        barcode: '14735',
        quantity: 348,
        price: 46462,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3135 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3136,
        artnumber: '40650',
        barcode: '96494',
        quantity: 170,
        price: 6827,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3136 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3137,
        artnumber: '13174',
        barcode: '21232',
        quantity: 251,
        price: 41207,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3137 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3138,
        artnumber: '75948',
        barcode: '69105',
        quantity: 378,
        price: 5916,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3138 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3139,
        artnumber: '67758',
        barcode: '37334',
        quantity: 263,
        price: 25131,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3139 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3140,
        artnumber: '87892',
        barcode: '77033',
        quantity: 212,
        price: 34002,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3140 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3141,
        artnumber: '46133',
        barcode: '23131',
        quantity: 477,
        price: 41533,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3141 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3142,
        artnumber: '72931',
        barcode: '15055',
        quantity: 115,
        price: 27903,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3142 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3143,
        artnumber: '66148',
        barcode: '53686',
        quantity: 314,
        price: 9596,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3143 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3144,
        artnumber: '50167',
        barcode: '70085',
        quantity: 349,
        price: 27801,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3144 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3145,
        artnumber: '99034',
        barcode: '33758',
        quantity: 157,
        price: 41689,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3145 для Королев, Техника, Запчасти, Бета',
    },
    {
        id: 3146,
        artnumber: '81824',
        barcode: '55418',
        quantity: 341,
        price: 16642,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3146 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3147,
        artnumber: '54450',
        barcode: '11617',
        quantity: 456,
        price: 49773,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3147 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3148,
        artnumber: '21253',
        barcode: '27045',
        quantity: 4,
        price: 38248,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3148 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3149,
        artnumber: '43068',
        barcode: '52502',
        quantity: 178,
        price: 47140,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3149 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3150,
        artnumber: '90848',
        barcode: '96913',
        quantity: 417,
        price: 4346,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3150 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3151,
        artnumber: '93653',
        barcode: '65460',
        quantity: 479,
        price: 27521,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3151 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3152,
        artnumber: '18761',
        barcode: '54261',
        quantity: 328,
        price: 12086,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3152 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3153,
        artnumber: '74846',
        barcode: '50764',
        quantity: 386,
        price: 23667,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3153 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3154,
        artnumber: '77903',
        barcode: '20749',
        quantity: 215,
        price: 37704,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3154 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3155,
        artnumber: '23914',
        barcode: '24668',
        quantity: 329,
        price: 10198,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3155 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3156,
        artnumber: '13824',
        barcode: '83471',
        quantity: 468,
        price: 38038,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3156 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3157,
        artnumber: '84919',
        barcode: '59569',
        quantity: 49,
        price: 19010,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3157 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3158,
        artnumber: '14277',
        barcode: '51413',
        quantity: 338,
        price: 19801,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3158 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3159,
        artnumber: '71770',
        barcode: '58389',
        quantity: 186,
        price: 46551,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3159 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3160,
        artnumber: '94457',
        barcode: '88868',
        quantity: 150,
        price: 15899,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3160 для Королев, Техника, Запчасти, Гамма',
    },
    {
        id: 3161,
        artnumber: '48915',
        barcode: '61785',
        quantity: 241,
        price: 33376,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3161 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3162,
        artnumber: '86159',
        barcode: '55803',
        quantity: 251,
        price: 26539,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3162 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3163,
        artnumber: '58949',
        barcode: '28699',
        quantity: 351,
        price: 522,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3163 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3164,
        artnumber: '43580',
        barcode: '97037',
        quantity: 252,
        price: 5253,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3164 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3165,
        artnumber: '60239',
        barcode: '36689',
        quantity: 171,
        price: 26005,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3165 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3166,
        artnumber: '47907',
        barcode: '87816',
        quantity: 411,
        price: 13491,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3166 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3167,
        artnumber: '21957',
        barcode: '69113',
        quantity: 185,
        price: 12797,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3167 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3168,
        artnumber: '40402',
        barcode: '78368',
        quantity: 313,
        price: 2602,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3168 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3169,
        artnumber: '69827',
        barcode: '64202',
        quantity: 288,
        price: 5074,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3169 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3170,
        artnumber: '39350',
        barcode: '65578',
        quantity: 445,
        price: 29401,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3170 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3171,
        artnumber: '39603',
        barcode: '35581',
        quantity: 468,
        price: 8425,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3171 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3172,
        artnumber: '58255',
        barcode: '69822',
        quantity: 32,
        price: 665,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3172 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3173,
        artnumber: '77091',
        barcode: '15845',
        quantity: 90,
        price: 21477,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3173 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3174,
        artnumber: '81991',
        barcode: '19865',
        quantity: 10,
        price: 35003,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3174 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3175,
        artnumber: '69935',
        barcode: '24723',
        quantity: 362,
        price: 37718,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3175 для Королев, Техника, Запчасти, Трио',
    },
    {
        id: 3176,
        artnumber: '67465',
        barcode: '37783',
        quantity: 498,
        price: 13011,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3176 для Королев, Техника, Доставка, Альфа',
    },
    {
        id: 3177,
        artnumber: '73619',
        barcode: '75657',
        quantity: 259,
        price: 36751,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3177 для Королев, Техника, Доставка, Альфа',
    },
    {
        id: 3178,
        artnumber: '17183',
        barcode: '26524',
        quantity: 306,
        price: 44327,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3178 для Королев, Техника, Доставка, Альфа',
    },
    {
        id: 3179,
        artnumber: '32833',
        barcode: '99396',
        quantity: 465,
        price: 39099,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3179 для Королев, Техника, Доставка, Альфа',
    },
    {
        id: 3180,
        artnumber: '32605',
        barcode: '96287',
        quantity: 387,
        price: 11700,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3180 для Королев, Техника, Доставка, Альфа',
    },
    {
        id: 3181,
        artnumber: '14966',
        barcode: '43298',
        quantity: 46,
        price: 46440,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3181 для Королев, Техника, Доставка, Альфа',
    },
    {
        id: 3182,
        artnumber: '57451',
        barcode: '52891',
        quantity: 361,
        price: 27515,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3182 для Королев, Техника, Доставка, Строй',
    },
    {
        id: 3183,
        artnumber: '63391',
        barcode: '24731',
        quantity: 209,
        price: 44965,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3183 для Королев, Техника, Доставка, Строй',
    },
    {
        id: 3184,
        artnumber: '38016',
        barcode: '49409',
        quantity: 496,
        price: 24657,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3184 для Королев, Техника, Доставка, Строй',
    },
    {
        id: 3185,
        artnumber: '25790',
        barcode: '84094',
        quantity: 494,
        price: 41131,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3185 для Королев, Техника, Доставка, Строй',
    },
    {
        id: 3186,
        artnumber: '37830',
        barcode: '75888',
        quantity: 144,
        price: 21588,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3186 для Королев, Техника, Доставка, Строй',
    },
    {
        id: 3187,
        artnumber: '19343',
        barcode: '95821',
        quantity: 310,
        price: 48429,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3187 для Королев, Техника, Доставка, Строй',
    },
    {
        id: 3188,
        artnumber: '24549',
        barcode: '17101',
        quantity: 414,
        price: 17266,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3188 для Королев, Техника, Доставка, Бета',
    },
    {
        id: 3189,
        artnumber: '51842',
        barcode: '95507',
        quantity: 211,
        price: 28999,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3189 для Королев, Техника, Доставка, Бета',
    },
    {
        id: 3190,
        artnumber: '14829',
        barcode: '79663',
        quantity: 111,
        price: 47167,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3190 для Королев, Техника, Доставка, Бета',
    },
    {
        id: 3191,
        artnumber: '22907',
        barcode: '22466',
        quantity: 120,
        price: 8881,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3191 для Королев, Техника, Доставка, Бета',
    },
    {
        id: 3192,
        artnumber: '45521',
        barcode: '27015',
        quantity: 432,
        price: 3916,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3192 для Королев, Техника, Доставка, Бета',
    },
    {
        id: 3193,
        artnumber: '75096',
        barcode: '82546',
        quantity: 494,
        price: 12911,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3193 для Королев, Техника, Доставка, Бета',
    },
    {
        id: 3194,
        artnumber: '63815',
        barcode: '57688',
        quantity: 223,
        price: 14072,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3194 для Королев, Техника, Доставка, Гамма',
    },
    {
        id: 3195,
        artnumber: '30156',
        barcode: '62165',
        quantity: 266,
        price: 31011,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3195 для Королев, Техника, Доставка, Гамма',
    },
    {
        id: 3196,
        artnumber: '84262',
        barcode: '87597',
        quantity: 127,
        price: 17792,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3196 для Королев, Техника, Доставка, Гамма',
    },
    {
        id: 3197,
        artnumber: '56774',
        barcode: '11752',
        quantity: 346,
        price: 12902,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3197 для Королев, Техника, Доставка, Гамма',
    },
    {
        id: 3198,
        artnumber: '67145',
        barcode: '71258',
        quantity: 302,
        price: 38970,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3198 для Королев, Техника, Доставка, Гамма',
    },
    {
        id: 3199,
        artnumber: '16970',
        barcode: '32649',
        quantity: 110,
        price: 23118,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3199 для Королев, Техника, Доставка, Гамма',
    },
    {
        id: 3200,
        artnumber: '58248',
        barcode: '19850',
        quantity: 411,
        price: 32732,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3200 для Королев, Техника, Доставка, Трио',
    },
    {
        id: 3201,
        artnumber: '83987',
        barcode: '56582',
        quantity: 64,
        price: 18574,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3201 для Королев, Техника, Доставка, Трио',
    },
    {
        id: 3202,
        artnumber: '31885',
        barcode: '50883',
        quantity: 64,
        price: 48000,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3202 для Королев, Техника, Доставка, Трио',
    },
    {
        id: 3203,
        artnumber: '87166',
        barcode: '83111',
        quantity: 409,
        price: 40391,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3203 для Королев, Техника, Доставка, Трио',
    },
    {
        id: 3204,
        artnumber: '67841',
        barcode: '96452',
        quantity: 231,
        price: 37658,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3204 для Королев, Техника, Доставка, Трио',
    },
    {
        id: 3205,
        artnumber: '72429',
        barcode: '71575',
        quantity: 251,
        price: 39360,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3205 для Королев, Техника, Доставка, Трио',
    },
    {
        id: 3206,
        artnumber: '64425',
        barcode: '87131',
        quantity: 158,
        price: 15129,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3206 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3207,
        artnumber: '60354',
        barcode: '58580',
        quantity: 21,
        price: 40814,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3207 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3208,
        artnumber: '29439',
        barcode: '96661',
        quantity: 352,
        price: 18087,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3208 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3209,
        artnumber: '23681',
        barcode: '48887',
        quantity: 262,
        price: 31377,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3209 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3210,
        artnumber: '34876',
        barcode: '89718',
        quantity: 284,
        price: 34766,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3210 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3211,
        artnumber: '86119',
        barcode: '99061',
        quantity: 18,
        price: 18970,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3211 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3212,
        artnumber: '55529',
        barcode: '26994',
        quantity: 450,
        price: 7472,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3212 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3213,
        artnumber: '95028',
        barcode: '11982',
        quantity: 375,
        price: 14423,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3213 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3214,
        artnumber: '20534',
        barcode: '58584',
        quantity: 280,
        price: 15589,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3214 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3215,
        artnumber: '91190',
        barcode: '43157',
        quantity: 429,
        price: 6761,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3215 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3216,
        artnumber: '87289',
        barcode: '38468',
        quantity: 242,
        price: 9026,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3216 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3217,
        artnumber: '77590',
        barcode: '22671',
        quantity: 74,
        price: 8345,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3217 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3218,
        artnumber: '82831',
        barcode: '41622',
        quantity: 178,
        price: 17425,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3218 для Королев, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 3219,
        artnumber: '22964',
        barcode: '99112',
        quantity: 45,
        price: 47909,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3219 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3220,
        artnumber: '54347',
        barcode: '82716',
        quantity: 416,
        price: 535,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3220 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3221,
        artnumber: '13554',
        barcode: '65334',
        quantity: 334,
        price: 22045,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3221 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3222,
        artnumber: '91663',
        barcode: '12772',
        quantity: 269,
        price: 18350,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3222 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3223,
        artnumber: '64020',
        barcode: '36626',
        quantity: 173,
        price: 2919,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3223 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3224,
        artnumber: '19449',
        barcode: '29420',
        quantity: 242,
        price: 39716,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3224 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3225,
        artnumber: '27771',
        barcode: '87398',
        quantity: 132,
        price: 5925,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3225 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3226,
        artnumber: '95210',
        barcode: '74774',
        quantity: 99,
        price: 47983,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3226 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3227,
        artnumber: '36405',
        barcode: '32556',
        quantity: 63,
        price: 10030,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3227 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3228,
        artnumber: '90707',
        barcode: '62203',
        quantity: 288,
        price: 162,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3228 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3229,
        artnumber: '89563',
        barcode: '33994',
        quantity: 33,
        price: 33209,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3229 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3230,
        artnumber: '88208',
        barcode: '31124',
        quantity: 104,
        price: 35726,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3230 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3231,
        artnumber: '86892',
        barcode: '36903',
        quantity: 337,
        price: 34242,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3231 для Королев, Техника, Шиномонтаж, Строй',
    },
    {
        id: 3232,
        artnumber: '78357',
        barcode: '28646',
        quantity: 262,
        price: 5065,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3232 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3233,
        artnumber: '15861',
        barcode: '23582',
        quantity: 487,
        price: 7449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3233 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3234,
        artnumber: '27059',
        barcode: '30546',
        quantity: 441,
        price: 42684,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3234 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3235,
        artnumber: '57144',
        barcode: '82100',
        quantity: 139,
        price: 26787,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3235 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3236,
        artnumber: '29605',
        barcode: '50219',
        quantity: 38,
        price: 7889,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3236 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3237,
        artnumber: '11831',
        barcode: '54169',
        quantity: 147,
        price: 37109,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3237 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3238,
        artnumber: '62936',
        barcode: '95714',
        quantity: 489,
        price: 4460,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3238 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3239,
        artnumber: '58474',
        barcode: '53543',
        quantity: 388,
        price: 25115,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3239 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3240,
        artnumber: '82455',
        barcode: '97538',
        quantity: 49,
        price: 34613,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3240 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3241,
        artnumber: '36513',
        barcode: '84293',
        quantity: 165,
        price: 2544,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3241 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3242,
        artnumber: '22261',
        barcode: '92516',
        quantity: 310,
        price: 41244,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3242 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3243,
        artnumber: '68010',
        barcode: '71191',
        quantity: 247,
        price: 86,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3243 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3244,
        artnumber: '62776',
        barcode: '26718',
        quantity: 386,
        price: 22876,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3244 для Королев, Техника, Шиномонтаж, Бета',
    },
    {
        id: 3245,
        artnumber: '79917',
        barcode: '69282',
        quantity: 374,
        price: 10263,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3245 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3246,
        artnumber: '19044',
        barcode: '76447',
        quantity: 279,
        price: 18394,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3246 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3247,
        artnumber: '16947',
        barcode: '97253',
        quantity: 459,
        price: 39707,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3247 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3248,
        artnumber: '97952',
        barcode: '32549',
        quantity: 158,
        price: 21353,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3248 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3249,
        artnumber: '78740',
        barcode: '90149',
        quantity: 130,
        price: 49760,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3249 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3250,
        artnumber: '90154',
        barcode: '65262',
        quantity: 170,
        price: 173,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3250 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3251,
        artnumber: '38078',
        barcode: '15620',
        quantity: 24,
        price: 531,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3251 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3252,
        artnumber: '66316',
        barcode: '85731',
        quantity: 1,
        price: 13382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3252 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3253,
        artnumber: '32712',
        barcode: '86897',
        quantity: 245,
        price: 38062,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3253 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3254,
        artnumber: '60048',
        barcode: '88982',
        quantity: 374,
        price: 42244,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3254 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3255,
        artnumber: '12608',
        barcode: '93310',
        quantity: 129,
        price: 35435,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3255 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3256,
        artnumber: '69476',
        barcode: '22553',
        quantity: 335,
        price: 37223,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3256 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3257,
        artnumber: '67915',
        barcode: '19334',
        quantity: 176,
        price: 12226,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3257 для Королев, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 3258,
        artnumber: '57890',
        barcode: '99056',
        quantity: 78,
        price: 29179,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3258 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3259,
        artnumber: '94660',
        barcode: '25071',
        quantity: 12,
        price: 9770,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3259 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3260,
        artnumber: '70205',
        barcode: '68871',
        quantity: 94,
        price: 10547,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3260 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3261,
        artnumber: '50923',
        barcode: '88082',
        quantity: 132,
        price: 15891,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3261 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3262,
        artnumber: '15640',
        barcode: '14622',
        quantity: 445,
        price: 24301,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3262 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3263,
        artnumber: '14652',
        barcode: '65434',
        quantity: 160,
        price: 13779,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3263 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3264,
        artnumber: '34771',
        barcode: '74944',
        quantity: 390,
        price: 3603,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3264 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3265,
        artnumber: '34182',
        barcode: '22738',
        quantity: 142,
        price: 5081,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3265 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3266,
        artnumber: '96618',
        barcode: '76402',
        quantity: 107,
        price: 6892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3266 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3267,
        artnumber: '99496',
        barcode: '83323',
        quantity: 447,
        price: 16034,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3267 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3268,
        artnumber: '63091',
        barcode: '22632',
        quantity: 439,
        price: 41135,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3268 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3269,
        artnumber: '54551',
        barcode: '19079',
        quantity: 266,
        price: 28534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3269 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3270,
        artnumber: '39086',
        barcode: '63694',
        quantity: 338,
        price: 36565,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3270 для Королев, Техника, Шиномонтаж, Трио',
    },
    {
        id: 3271,
        artnumber: '43552',
        barcode: '76913',
        quantity: 422,
        price: 31461,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3271 для Королев, Техника, Замена масла, Альфа',
    },
    {
        id: 3272,
        artnumber: '46301',
        barcode: '35443',
        quantity: 461,
        price: 35791,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3272 для Королев, Техника, Замена масла, Альфа',
    },
    {
        id: 3273,
        artnumber: '37290',
        barcode: '40548',
        quantity: 357,
        price: 23028,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3273 для Королев, Техника, Замена масла, Альфа',
    },
    {
        id: 3274,
        artnumber: '54519',
        barcode: '58899',
        quantity: 221,
        price: 32825,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3274 для Королев, Техника, Замена масла, Строй',
    },
    {
        id: 3275,
        artnumber: '70757',
        barcode: '33318',
        quantity: 427,
        price: 13459,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3275 для Королев, Техника, Замена масла, Строй',
    },
    {
        id: 3276,
        artnumber: '88112',
        barcode: '73430',
        quantity: 200,
        price: 26945,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3276 для Королев, Техника, Замена масла, Строй',
    },
    {
        id: 3277,
        artnumber: '48219',
        barcode: '26549',
        quantity: 356,
        price: 45484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3277 для Королев, Техника, Замена масла, Бета',
    },
    {
        id: 3278,
        artnumber: '16610',
        barcode: '24700',
        quantity: 65,
        price: 42345,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3278 для Королев, Техника, Замена масла, Бета',
    },
    {
        id: 3279,
        artnumber: '94940',
        barcode: '25694',
        quantity: 442,
        price: 30945,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3279 для Королев, Техника, Замена масла, Бета',
    },
    {
        id: 3280,
        artnumber: '64237',
        barcode: '44350',
        quantity: 291,
        price: 49500,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3280 для Королев, Техника, Замена масла, Гамма',
    },
    {
        id: 3281,
        artnumber: '69137',
        barcode: '28289',
        quantity: 294,
        price: 3743,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3281 для Королев, Техника, Замена масла, Гамма',
    },
    {
        id: 3282,
        artnumber: '61792',
        barcode: '36016',
        quantity: 259,
        price: 7240,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3282 для Королев, Техника, Замена масла, Гамма',
    },
    {
        id: 3283,
        artnumber: '72585',
        barcode: '27778',
        quantity: 446,
        price: 18862,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3283 для Королев, Техника, Замена масла, Трио',
    },
    {
        id: 3284,
        artnumber: '47877',
        barcode: '28473',
        quantity: 424,
        price: 33033,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3284 для Королев, Техника, Замена масла, Трио',
    },
    {
        id: 3285,
        artnumber: '26111',
        barcode: '42105',
        quantity: 396,
        price: 46644,
        picture: '/images/products/no_image.svg',
        region: {
            id: 3,
            name: 'Королев',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3285 для Королев, Техника, Замена масла, Трио',
    },
    {
        id: 3286,
        artnumber: '41425',
        barcode: '70430',
        quantity: 268,
        price: 47139,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3286 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3287,
        artnumber: '78047',
        barcode: '19875',
        quantity: 446,
        price: 21520,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3287 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3288,
        artnumber: '15143',
        barcode: '72017',
        quantity: 293,
        price: 36727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3288 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3289,
        artnumber: '40844',
        barcode: '33268',
        quantity: 451,
        price: 36594,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3289 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3290,
        artnumber: '33903',
        barcode: '28306',
        quantity: 381,
        price: 16101,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3290 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3291,
        artnumber: '76047',
        barcode: '22682',
        quantity: 43,
        price: 39757,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3291 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3292,
        artnumber: '84352',
        barcode: '24631',
        quantity: 331,
        price: 36844,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3292 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3293,
        artnumber: '26651',
        barcode: '71984',
        quantity: 459,
        price: 8255,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3293 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3294,
        artnumber: '79691',
        barcode: '50131',
        quantity: 73,
        price: 11264,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3294 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3295,
        artnumber: '62192',
        barcode: '82897',
        quantity: 470,
        price: 4883,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3295 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3296,
        artnumber: '76991',
        barcode: '10478',
        quantity: 251,
        price: 35276,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3296 для Мытищи, Авто, Сервис, Альфа',
    },
    {
        id: 3297,
        artnumber: '63078',
        barcode: '72689',
        quantity: 288,
        price: 33896,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3297 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3298,
        artnumber: '71391',
        barcode: '55462',
        quantity: 305,
        price: 19797,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3298 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3299,
        artnumber: '74747',
        barcode: '66232',
        quantity: 24,
        price: 38059,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3299 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3300,
        artnumber: '98439',
        barcode: '56987',
        quantity: 386,
        price: 3543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3300 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3301,
        artnumber: '74125',
        barcode: '97612',
        quantity: 53,
        price: 48314,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3301 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3302,
        artnumber: '81203',
        barcode: '56815',
        quantity: 309,
        price: 19627,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3302 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3303,
        artnumber: '41301',
        barcode: '37984',
        quantity: 402,
        price: 23161,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3303 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3304,
        artnumber: '19768',
        barcode: '10059',
        quantity: 271,
        price: 30202,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3304 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3305,
        artnumber: '58115',
        barcode: '97877',
        quantity: 351,
        price: 46109,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3305 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3306,
        artnumber: '40524',
        barcode: '66112',
        quantity: 197,
        price: 17506,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3306 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3307,
        artnumber: '90519',
        barcode: '31183',
        quantity: 361,
        price: 13308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3307 для Мытищи, Авто, Сервис, Строй',
    },
    {
        id: 3308,
        artnumber: '13206',
        barcode: '81281',
        quantity: 99,
        price: 18729,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3308 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3309,
        artnumber: '95728',
        barcode: '20822',
        quantity: 219,
        price: 49819,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3309 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3310,
        artnumber: '38737',
        barcode: '30462',
        quantity: 413,
        price: 39134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3310 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3311,
        artnumber: '62390',
        barcode: '11820',
        quantity: 95,
        price: 18457,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3311 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3312,
        artnumber: '19292',
        barcode: '35025',
        quantity: 58,
        price: 14411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3312 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3313,
        artnumber: '57377',
        barcode: '27536',
        quantity: 128,
        price: 6300,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3313 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3314,
        artnumber: '14170',
        barcode: '90041',
        quantity: 199,
        price: 15538,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3314 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3315,
        artnumber: '17257',
        barcode: '51362',
        quantity: 156,
        price: 47416,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3315 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3316,
        artnumber: '62876',
        barcode: '50778',
        quantity: 64,
        price: 5494,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3316 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3317,
        artnumber: '34391',
        barcode: '35808',
        quantity: 368,
        price: 29926,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3317 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3318,
        artnumber: '21997',
        barcode: '17605',
        quantity: 215,
        price: 35760,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3318 для Мытищи, Авто, Сервис, Бета',
    },
    {
        id: 3319,
        artnumber: '37418',
        barcode: '85196',
        quantity: 6,
        price: 11603,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3319 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3320,
        artnumber: '46368',
        barcode: '32832',
        quantity: 127,
        price: 11048,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3320 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3321,
        artnumber: '41293',
        barcode: '59704',
        quantity: 271,
        price: 47066,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3321 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3322,
        artnumber: '69367',
        barcode: '73950',
        quantity: 328,
        price: 32794,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3322 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3323,
        artnumber: '84451',
        barcode: '60747',
        quantity: 122,
        price: 25656,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3323 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3324,
        artnumber: '33253',
        barcode: '44459',
        quantity: 464,
        price: 27256,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3324 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3325,
        artnumber: '43827',
        barcode: '45040',
        quantity: 363,
        price: 34115,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3325 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3326,
        artnumber: '93599',
        barcode: '27791',
        quantity: 162,
        price: 30485,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3326 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3327,
        artnumber: '99038',
        barcode: '52941',
        quantity: 165,
        price: 43876,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3327 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3328,
        artnumber: '94715',
        barcode: '62158',
        quantity: 191,
        price: 43560,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3328 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3329,
        artnumber: '21418',
        barcode: '19910',
        quantity: 59,
        price: 32597,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3329 для Мытищи, Авто, Сервис, Гамма',
    },
    {
        id: 3330,
        artnumber: '46928',
        barcode: '23385',
        quantity: 131,
        price: 8411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3330 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3331,
        artnumber: '86198',
        barcode: '54976',
        quantity: 271,
        price: 23772,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3331 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3332,
        artnumber: '12786',
        barcode: '25194',
        quantity: 447,
        price: 5256,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3332 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3333,
        artnumber: '42541',
        barcode: '34113',
        quantity: 398,
        price: 19987,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3333 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3334,
        artnumber: '42246',
        barcode: '56606',
        quantity: 222,
        price: 38196,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3334 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3335,
        artnumber: '44211',
        barcode: '58572',
        quantity: 229,
        price: 43525,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3335 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3336,
        artnumber: '92063',
        barcode: '48486',
        quantity: 133,
        price: 18194,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3336 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3337,
        artnumber: '54142',
        barcode: '51201',
        quantity: 144,
        price: 21374,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3337 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3338,
        artnumber: '25319',
        barcode: '63984',
        quantity: 490,
        price: 24938,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3338 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3339,
        artnumber: '36651',
        barcode: '58412',
        quantity: 282,
        price: 30579,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3339 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3340,
        artnumber: '71128',
        barcode: '25800',
        quantity: 117,
        price: 14949,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3340 для Мытищи, Авто, Сервис, Трио',
    },
    {
        id: 3341,
        artnumber: '98680',
        barcode: '62700',
        quantity: 30,
        price: 27837,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3341 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3342,
        artnumber: '91764',
        barcode: '31914',
        quantity: 41,
        price: 10673,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3342 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3343,
        artnumber: '65636',
        barcode: '17860',
        quantity: 405,
        price: 35669,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3343 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3344,
        artnumber: '98675',
        barcode: '15018',
        quantity: 280,
        price: 2469,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3344 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3345,
        artnumber: '89228',
        barcode: '90159',
        quantity: 24,
        price: 9416,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3345 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3346,
        artnumber: '99762',
        barcode: '74908',
        quantity: 467,
        price: 1017,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3346 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3347,
        artnumber: '18117',
        barcode: '95947',
        quantity: 8,
        price: 44293,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3347 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3348,
        artnumber: '70458',
        barcode: '77999',
        quantity: 141,
        price: 39817,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3348 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3349,
        artnumber: '36355',
        barcode: '36722',
        quantity: 284,
        price: 9312,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3349 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3350,
        artnumber: '68353',
        barcode: '56045',
        quantity: 279,
        price: 27558,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3350 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3351,
        artnumber: '43562',
        barcode: '37631',
        quantity: 361,
        price: 3278,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3351 для Мытищи, Авто, Запчасти, Альфа',
    },
    {
        id: 3352,
        artnumber: '74363',
        barcode: '50782',
        quantity: 382,
        price: 19929,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3352 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3353,
        artnumber: '92910',
        barcode: '22429',
        quantity: 337,
        price: 11751,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3353 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3354,
        artnumber: '13433',
        barcode: '65932',
        quantity: 369,
        price: 10482,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3354 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3355,
        artnumber: '87354',
        barcode: '49496',
        quantity: 351,
        price: 21284,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3355 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3356,
        artnumber: '40880',
        barcode: '40175',
        quantity: 261,
        price: 28440,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3356 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3357,
        artnumber: '76501',
        barcode: '53938',
        quantity: 44,
        price: 27946,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3357 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3358,
        artnumber: '36609',
        barcode: '15462',
        quantity: 138,
        price: 19725,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3358 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3359,
        artnumber: '15763',
        barcode: '84548',
        quantity: 93,
        price: 18763,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3359 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3360,
        artnumber: '84200',
        barcode: '28974',
        quantity: 363,
        price: 26710,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3360 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3361,
        artnumber: '34078',
        barcode: '31016',
        quantity: 305,
        price: 11574,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3361 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3362,
        artnumber: '51734',
        barcode: '65823',
        quantity: 164,
        price: 44210,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3362 для Мытищи, Авто, Запчасти, Строй',
    },
    {
        id: 3363,
        artnumber: '74734',
        barcode: '25382',
        quantity: 36,
        price: 15518,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3363 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3364,
        artnumber: '40134',
        barcode: '58592',
        quantity: 137,
        price: 9656,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3364 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3365,
        artnumber: '62046',
        barcode: '66415',
        quantity: 20,
        price: 463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3365 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3366,
        artnumber: '48123',
        barcode: '57367',
        quantity: 406,
        price: 25955,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3366 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3367,
        artnumber: '72253',
        barcode: '30273',
        quantity: 18,
        price: 47921,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3367 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3368,
        artnumber: '28886',
        barcode: '83679',
        quantity: 284,
        price: 11784,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3368 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3369,
        artnumber: '31610',
        barcode: '69616',
        quantity: 223,
        price: 9066,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3369 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3370,
        artnumber: '30921',
        barcode: '31556',
        quantity: 321,
        price: 43204,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3370 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3371,
        artnumber: '97781',
        barcode: '45145',
        quantity: 62,
        price: 39578,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3371 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3372,
        artnumber: '88084',
        barcode: '68158',
        quantity: 324,
        price: 11483,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3372 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3373,
        artnumber: '80463',
        barcode: '31436',
        quantity: 171,
        price: 30200,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3373 для Мытищи, Авто, Запчасти, Бета',
    },
    {
        id: 3374,
        artnumber: '20880',
        barcode: '18084',
        quantity: 498,
        price: 22268,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3374 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3375,
        artnumber: '46889',
        barcode: '20277',
        quantity: 373,
        price: 5455,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3375 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3376,
        artnumber: '64040',
        barcode: '12071',
        quantity: 90,
        price: 25706,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3376 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3377,
        artnumber: '63063',
        barcode: '82578',
        quantity: 315,
        price: 18383,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3377 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3378,
        artnumber: '98335',
        barcode: '59863',
        quantity: 284,
        price: 22361,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3378 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3379,
        artnumber: '57898',
        barcode: '79641',
        quantity: 128,
        price: 44199,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3379 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3380,
        artnumber: '59326',
        barcode: '13415',
        quantity: 74,
        price: 23177,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3380 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3381,
        artnumber: '71478',
        barcode: '34324',
        quantity: 12,
        price: 41063,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3381 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3382,
        artnumber: '29370',
        barcode: '47699',
        quantity: 201,
        price: 13363,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3382 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3383,
        artnumber: '17614',
        barcode: '57479',
        quantity: 316,
        price: 21154,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3383 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3384,
        artnumber: '96128',
        barcode: '32980',
        quantity: 63,
        price: 16441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3384 для Мытищи, Авто, Запчасти, Гамма',
    },
    {
        id: 3385,
        artnumber: '27466',
        barcode: '55535',
        quantity: 137,
        price: 34506,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3385 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3386,
        artnumber: '99025',
        barcode: '76574',
        quantity: 13,
        price: 10312,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3386 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3387,
        artnumber: '85036',
        barcode: '77600',
        quantity: 444,
        price: 10599,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3387 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3388,
        artnumber: '53648',
        barcode: '73614',
        quantity: 123,
        price: 27237,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3388 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3389,
        artnumber: '62865',
        barcode: '72049',
        quantity: 90,
        price: 18793,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3389 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3390,
        artnumber: '72806',
        barcode: '30301',
        quantity: 269,
        price: 16220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3390 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3391,
        artnumber: '68464',
        barcode: '37537',
        quantity: 329,
        price: 14905,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3391 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3392,
        artnumber: '40303',
        barcode: '80566',
        quantity: 293,
        price: 37849,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3392 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3393,
        artnumber: '11927',
        barcode: '11747',
        quantity: 158,
        price: 730,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3393 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3394,
        artnumber: '35582',
        barcode: '25982',
        quantity: 232,
        price: 17837,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3394 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3395,
        artnumber: '14724',
        barcode: '24375',
        quantity: 250,
        price: 32216,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3395 для Мытищи, Авто, Запчасти, Трио',
    },
    {
        id: 3396,
        artnumber: '52189',
        barcode: '14077',
        quantity: 327,
        price: 30092,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3396 для Мытищи, Авто, Доставка, Альфа',
    },
    {
        id: 3397,
        artnumber: '70338',
        barcode: '47779',
        quantity: 54,
        price: 13033,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3397 для Мытищи, Авто, Доставка, Альфа',
    },
    {
        id: 3398,
        artnumber: '96275',
        barcode: '79203',
        quantity: 143,
        price: 42997,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3398 для Мытищи, Авто, Доставка, Альфа',
    },
    {
        id: 3399,
        artnumber: '25107',
        barcode: '72822',
        quantity: 398,
        price: 8318,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3399 для Мытищи, Авто, Доставка, Строй',
    },
    {
        id: 3400,
        artnumber: '86885',
        barcode: '45699',
        quantity: 389,
        price: 23204,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3400 для Мытищи, Авто, Доставка, Строй',
    },
    {
        id: 3401,
        artnumber: '25033',
        barcode: '93037',
        quantity: 416,
        price: 23199,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3401 для Мытищи, Авто, Доставка, Строй',
    },
    {
        id: 3402,
        artnumber: '41038',
        barcode: '37151',
        quantity: 196,
        price: 2094,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3402 для Мытищи, Авто, Доставка, Бета',
    },
    {
        id: 3403,
        artnumber: '83808',
        barcode: '99484',
        quantity: 53,
        price: 44486,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3403 для Мытищи, Авто, Доставка, Бета',
    },
    {
        id: 3404,
        artnumber: '77958',
        barcode: '42870',
        quantity: 198,
        price: 3344,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3404 для Мытищи, Авто, Доставка, Бета',
    },
    {
        id: 3405,
        artnumber: '24437',
        barcode: '74683',
        quantity: 34,
        price: 2605,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3405 для Мытищи, Авто, Доставка, Гамма',
    },
    {
        id: 3406,
        artnumber: '38933',
        barcode: '52035',
        quantity: 130,
        price: 8624,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3406 для Мытищи, Авто, Доставка, Гамма',
    },
    {
        id: 3407,
        artnumber: '28731',
        barcode: '45877',
        quantity: 6,
        price: 33575,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3407 для Мытищи, Авто, Доставка, Гамма',
    },
    {
        id: 3408,
        artnumber: '33438',
        barcode: '35160',
        quantity: 292,
        price: 48223,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3408 для Мытищи, Авто, Доставка, Трио',
    },
    {
        id: 3409,
        artnumber: '50282',
        barcode: '15185',
        quantity: 153,
        price: 19865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3409 для Мытищи, Авто, Доставка, Трио',
    },
    {
        id: 3410,
        artnumber: '82165',
        barcode: '31241',
        quantity: 164,
        price: 21596,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3410 для Мытищи, Авто, Доставка, Трио',
    },
    {
        id: 3411,
        artnumber: '83772',
        barcode: '82265',
        quantity: 241,
        price: 3861,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3411 для Мытищи, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 3412,
        artnumber: '67082',
        barcode: '41028',
        quantity: 140,
        price: 4432,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3412 для Мытищи, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 3413,
        artnumber: '85516',
        barcode: '64806',
        quantity: 230,
        price: 32620,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3413 для Мытищи, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 3414,
        artnumber: '31223',
        barcode: '60246',
        quantity: 468,
        price: 12125,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3414 для Мытищи, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 3415,
        artnumber: '37994',
        barcode: '83220',
        quantity: 21,
        price: 41526,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3415 для Мытищи, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 3416,
        artnumber: '49862',
        barcode: '54730',
        quantity: 15,
        price: 46616,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3416 для Мытищи, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 3417,
        artnumber: '45385',
        barcode: '87667',
        quantity: 307,
        price: 30965,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3417 для Мытищи, Авто, Шиномонтаж, Строй',
    },
    {
        id: 3418,
        artnumber: '65444',
        barcode: '95906',
        quantity: 303,
        price: 24681,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3418 для Мытищи, Авто, Шиномонтаж, Строй',
    },
    {
        id: 3419,
        artnumber: '67850',
        barcode: '77917',
        quantity: 169,
        price: 49466,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3419 для Мытищи, Авто, Шиномонтаж, Строй',
    },
    {
        id: 3420,
        artnumber: '81276',
        barcode: '52209',
        quantity: 371,
        price: 19724,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3420 для Мытищи, Авто, Шиномонтаж, Строй',
    },
    {
        id: 3421,
        artnumber: '15792',
        barcode: '71812',
        quantity: 292,
        price: 42809,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3421 для Мытищи, Авто, Шиномонтаж, Строй',
    },
    {
        id: 3422,
        artnumber: '10193',
        barcode: '92396',
        quantity: 412,
        price: 38828,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3422 для Мытищи, Авто, Шиномонтаж, Строй',
    },
    {
        id: 3423,
        artnumber: '35847',
        barcode: '53479',
        quantity: 177,
        price: 44194,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3423 для Мытищи, Авто, Шиномонтаж, Бета',
    },
    {
        id: 3424,
        artnumber: '75070',
        barcode: '85082',
        quantity: 382,
        price: 15214,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3424 для Мытищи, Авто, Шиномонтаж, Бета',
    },
    {
        id: 3425,
        artnumber: '97878',
        barcode: '66441',
        quantity: 492,
        price: 27422,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3425 для Мытищи, Авто, Шиномонтаж, Бета',
    },
    {
        id: 3426,
        artnumber: '53516',
        barcode: '57100',
        quantity: 20,
        price: 7861,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3426 для Мытищи, Авто, Шиномонтаж, Бета',
    },
    {
        id: 3427,
        artnumber: '60179',
        barcode: '31954',
        quantity: 79,
        price: 521,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3427 для Мытищи, Авто, Шиномонтаж, Бета',
    },
    {
        id: 3428,
        artnumber: '74529',
        barcode: '49804',
        quantity: 327,
        price: 34202,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3428 для Мытищи, Авто, Шиномонтаж, Бета',
    },
    {
        id: 3429,
        artnumber: '29361',
        barcode: '53874',
        quantity: 215,
        price: 27609,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3429 для Мытищи, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 3430,
        artnumber: '67019',
        barcode: '78573',
        quantity: 259,
        price: 37720,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3430 для Мытищи, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 3431,
        artnumber: '42347',
        barcode: '31429',
        quantity: 183,
        price: 19033,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3431 для Мытищи, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 3432,
        artnumber: '24240',
        barcode: '42117',
        quantity: 122,
        price: 23421,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3432 для Мытищи, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 3433,
        artnumber: '75148',
        barcode: '89946',
        quantity: 156,
        price: 36781,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3433 для Мытищи, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 3434,
        artnumber: '40454',
        barcode: '59825',
        quantity: 292,
        price: 23821,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3434 для Мытищи, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 3435,
        artnumber: '62630',
        barcode: '97738',
        quantity: 280,
        price: 40056,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3435 для Мытищи, Авто, Шиномонтаж, Трио',
    },
    {
        id: 3436,
        artnumber: '47220',
        barcode: '63307',
        quantity: 358,
        price: 7983,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3436 для Мытищи, Авто, Шиномонтаж, Трио',
    },
    {
        id: 3437,
        artnumber: '69134',
        barcode: '43299',
        quantity: 406,
        price: 25979,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3437 для Мытищи, Авто, Шиномонтаж, Трио',
    },
    {
        id: 3438,
        artnumber: '41155',
        barcode: '83610',
        quantity: 413,
        price: 15888,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3438 для Мытищи, Авто, Шиномонтаж, Трио',
    },
    {
        id: 3439,
        artnumber: '74029',
        barcode: '46334',
        quantity: 443,
        price: 28458,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3439 для Мытищи, Авто, Шиномонтаж, Трио',
    },
    {
        id: 3440,
        artnumber: '37556',
        barcode: '63430',
        quantity: 354,
        price: 32473,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3440 для Мытищи, Авто, Шиномонтаж, Трио',
    },
    {
        id: 3441,
        artnumber: '29012',
        barcode: '29360',
        quantity: 358,
        price: 4408,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3441 для Мытищи, Авто, Замена масла, Альфа',
    },
    {
        id: 3442,
        artnumber: '74844',
        barcode: '51948',
        quantity: 375,
        price: 44545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3442 для Мытищи, Авто, Замена масла, Альфа',
    },
    {
        id: 3443,
        artnumber: '84305',
        barcode: '59561',
        quantity: 306,
        price: 47101,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3443 для Мытищи, Авто, Замена масла, Альфа',
    },
    {
        id: 3444,
        artnumber: '50964',
        barcode: '32071',
        quantity: 223,
        price: 8875,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3444 для Мытищи, Авто, Замена масла, Альфа',
    },
    {
        id: 3445,
        artnumber: '91172',
        barcode: '41763',
        quantity: 402,
        price: 49614,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3445 для Мытищи, Авто, Замена масла, Альфа',
    },
    {
        id: 3446,
        artnumber: '33958',
        barcode: '66647',
        quantity: 91,
        price: 28715,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3446 для Мытищи, Авто, Замена масла, Альфа',
    },
    {
        id: 3447,
        artnumber: '97544',
        barcode: '20454',
        quantity: 150,
        price: 8193,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3447 для Мытищи, Авто, Замена масла, Альфа',
    },
    {
        id: 3448,
        artnumber: '30860',
        barcode: '59751',
        quantity: 87,
        price: 9927,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3448 для Мытищи, Авто, Замена масла, Альфа',
    },
    {
        id: 3449,
        artnumber: '77174',
        barcode: '80003',
        quantity: 226,
        price: 18402,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3449 для Мытищи, Авто, Замена масла, Альфа',
    },
    {
        id: 3450,
        artnumber: '64210',
        barcode: '85472',
        quantity: 140,
        price: 24704,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3450 для Мытищи, Авто, Замена масла, Строй',
    },
    {
        id: 3451,
        artnumber: '52298',
        barcode: '21636',
        quantity: 195,
        price: 12195,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3451 для Мытищи, Авто, Замена масла, Строй',
    },
    {
        id: 3452,
        artnumber: '63590',
        barcode: '86173',
        quantity: 439,
        price: 11233,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3452 для Мытищи, Авто, Замена масла, Строй',
    },
    {
        id: 3453,
        artnumber: '88272',
        barcode: '19509',
        quantity: 111,
        price: 6135,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3453 для Мытищи, Авто, Замена масла, Строй',
    },
    {
        id: 3454,
        artnumber: '24830',
        barcode: '33132',
        quantity: 409,
        price: 15938,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3454 для Мытищи, Авто, Замена масла, Строй',
    },
    {
        id: 3455,
        artnumber: '34489',
        barcode: '35526',
        quantity: 368,
        price: 37655,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3455 для Мытищи, Авто, Замена масла, Строй',
    },
    {
        id: 3456,
        artnumber: '57051',
        barcode: '58245',
        quantity: 168,
        price: 1368,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3456 для Мытищи, Авто, Замена масла, Строй',
    },
    {
        id: 3457,
        artnumber: '34225',
        barcode: '15019',
        quantity: 71,
        price: 43881,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3457 для Мытищи, Авто, Замена масла, Строй',
    },
    {
        id: 3458,
        artnumber: '79410',
        barcode: '43206',
        quantity: 34,
        price: 38165,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3458 для Мытищи, Авто, Замена масла, Строй',
    },
    {
        id: 3459,
        artnumber: '25492',
        barcode: '62955',
        quantity: 236,
        price: 45307,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3459 для Мытищи, Авто, Замена масла, Бета',
    },
    {
        id: 3460,
        artnumber: '47286',
        barcode: '68396',
        quantity: 66,
        price: 462,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3460 для Мытищи, Авто, Замена масла, Бета',
    },
    {
        id: 3461,
        artnumber: '90577',
        barcode: '97418',
        quantity: 253,
        price: 20411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3461 для Мытищи, Авто, Замена масла, Бета',
    },
    {
        id: 3462,
        artnumber: '97297',
        barcode: '95928',
        quantity: 330,
        price: 29632,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3462 для Мытищи, Авто, Замена масла, Бета',
    },
    {
        id: 3463,
        artnumber: '21974',
        barcode: '84322',
        quantity: 346,
        price: 6537,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3463 для Мытищи, Авто, Замена масла, Бета',
    },
    {
        id: 3464,
        artnumber: '19848',
        barcode: '69564',
        quantity: 95,
        price: 21707,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3464 для Мытищи, Авто, Замена масла, Бета',
    },
    {
        id: 3465,
        artnumber: '96354',
        barcode: '35001',
        quantity: 247,
        price: 40894,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3465 для Мытищи, Авто, Замена масла, Бета',
    },
    {
        id: 3466,
        artnumber: '31297',
        barcode: '73123',
        quantity: 138,
        price: 179,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3466 для Мытищи, Авто, Замена масла, Бета',
    },
    {
        id: 3467,
        artnumber: '21860',
        barcode: '70167',
        quantity: 209,
        price: 522,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3467 для Мытищи, Авто, Замена масла, Бета',
    },
    {
        id: 3468,
        artnumber: '94466',
        barcode: '65141',
        quantity: 359,
        price: 13487,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3468 для Мытищи, Авто, Замена масла, Гамма',
    },
    {
        id: 3469,
        artnumber: '84157',
        barcode: '99563',
        quantity: 426,
        price: 31822,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3469 для Мытищи, Авто, Замена масла, Гамма',
    },
    {
        id: 3470,
        artnumber: '20548',
        barcode: '98238',
        quantity: 233,
        price: 49743,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3470 для Мытищи, Авто, Замена масла, Гамма',
    },
    {
        id: 3471,
        artnumber: '94451',
        barcode: '66318',
        quantity: 292,
        price: 30822,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3471 для Мытищи, Авто, Замена масла, Гамма',
    },
    {
        id: 3472,
        artnumber: '82198',
        barcode: '79388',
        quantity: 460,
        price: 29871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3472 для Мытищи, Авто, Замена масла, Гамма',
    },
    {
        id: 3473,
        artnumber: '82965',
        barcode: '90958',
        quantity: 216,
        price: 33925,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3473 для Мытищи, Авто, Замена масла, Гамма',
    },
    {
        id: 3474,
        artnumber: '54266',
        barcode: '42762',
        quantity: 268,
        price: 44505,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3474 для Мытищи, Авто, Замена масла, Гамма',
    },
    {
        id: 3475,
        artnumber: '94130',
        barcode: '97583',
        quantity: 157,
        price: 3597,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3475 для Мытищи, Авто, Замена масла, Гамма',
    },
    {
        id: 3476,
        artnumber: '74035',
        barcode: '14304',
        quantity: 12,
        price: 42584,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3476 для Мытищи, Авто, Замена масла, Гамма',
    },
    {
        id: 3477,
        artnumber: '78235',
        barcode: '42750',
        quantity: 51,
        price: 12253,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3477 для Мытищи, Авто, Замена масла, Трио',
    },
    {
        id: 3478,
        artnumber: '28704',
        barcode: '25779',
        quantity: 267,
        price: 11047,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3478 для Мытищи, Авто, Замена масла, Трио',
    },
    {
        id: 3479,
        artnumber: '86708',
        barcode: '14325',
        quantity: 125,
        price: 20723,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3479 для Мытищи, Авто, Замена масла, Трио',
    },
    {
        id: 3480,
        artnumber: '92674',
        barcode: '77869',
        quantity: 394,
        price: 4920,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3480 для Мытищи, Авто, Замена масла, Трио',
    },
    {
        id: 3481,
        artnumber: '49628',
        barcode: '74520',
        quantity: 400,
        price: 40127,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3481 для Мытищи, Авто, Замена масла, Трио',
    },
    {
        id: 3482,
        artnumber: '12470',
        barcode: '89002',
        quantity: 155,
        price: 40489,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3482 для Мытищи, Авто, Замена масла, Трио',
    },
    {
        id: 3483,
        artnumber: '72797',
        barcode: '64123',
        quantity: 93,
        price: 11787,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3483 для Мытищи, Авто, Замена масла, Трио',
    },
    {
        id: 3484,
        artnumber: '12790',
        barcode: '66862',
        quantity: 210,
        price: 20866,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3484 для Мытищи, Авто, Замена масла, Трио',
    },
    {
        id: 3485,
        artnumber: '72474',
        barcode: '54518',
        quantity: 374,
        price: 28454,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3485 для Мытищи, Авто, Замена масла, Трио',
    },
    {
        id: 3486,
        artnumber: '17325',
        barcode: '25635',
        quantity: 357,
        price: 28773,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3486 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3487,
        artnumber: '33063',
        barcode: '45080',
        quantity: 38,
        price: 42188,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3487 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3488,
        artnumber: '39137',
        barcode: '53428',
        quantity: 309,
        price: 23073,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3488 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3489,
        artnumber: '25108',
        barcode: '14268',
        quantity: 276,
        price: 28882,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3489 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3490,
        artnumber: '56286',
        barcode: '71822',
        quantity: 423,
        price: 44613,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3490 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3491,
        artnumber: '53908',
        barcode: '92789',
        quantity: 490,
        price: 3492,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3491 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3492,
        artnumber: '97919',
        barcode: '17929',
        quantity: 66,
        price: 22437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3492 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3493,
        artnumber: '79493',
        barcode: '27913',
        quantity: 167,
        price: 24158,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3493 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3494,
        artnumber: '57639',
        barcode: '58311',
        quantity: 34,
        price: 3569,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3494 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3495,
        artnumber: '29459',
        barcode: '76028',
        quantity: 446,
        price: 4192,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3495 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3496,
        artnumber: '49719',
        barcode: '86573',
        quantity: 166,
        price: 48728,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3496 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3497,
        artnumber: '68830',
        barcode: '85236',
        quantity: 20,
        price: 15519,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3497 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3498,
        artnumber: '73485',
        barcode: '47540',
        quantity: 242,
        price: 17665,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3498 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3499,
        artnumber: '17020',
        barcode: '30178',
        quantity: 142,
        price: 49373,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3499 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3500,
        artnumber: '70814',
        barcode: '29822',
        quantity: 430,
        price: 35627,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3500 для Мытищи, Недвижимость, Сервис, Альфа',
    },
    {
        id: 3501,
        artnumber: '82787',
        barcode: '67093',
        quantity: 66,
        price: 12001,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3501 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3502,
        artnumber: '92542',
        barcode: '89597',
        quantity: 194,
        price: 14542,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3502 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3503,
        artnumber: '69266',
        barcode: '83446',
        quantity: 252,
        price: 37727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3503 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3504,
        artnumber: '36902',
        barcode: '78207',
        quantity: 287,
        price: 29127,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3504 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3505,
        artnumber: '58097',
        barcode: '55316',
        quantity: 252,
        price: 26327,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3505 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3506,
        artnumber: '98886',
        barcode: '31765',
        quantity: 134,
        price: 17633,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3506 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3507,
        artnumber: '71914',
        barcode: '15666',
        quantity: 299,
        price: 4760,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3507 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3508,
        artnumber: '37116',
        barcode: '60281',
        quantity: 365,
        price: 13403,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3508 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3509,
        artnumber: '31706',
        barcode: '82570',
        quantity: 220,
        price: 15606,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3509 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3510,
        artnumber: '36538',
        barcode: '66343',
        quantity: 321,
        price: 47928,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3510 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3511,
        artnumber: '38003',
        barcode: '44924',
        quantity: 227,
        price: 32843,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3511 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3512,
        artnumber: '85610',
        barcode: '17176',
        quantity: 131,
        price: 42398,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3512 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3513,
        artnumber: '12789',
        barcode: '21887',
        quantity: 326,
        price: 48121,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3513 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3514,
        artnumber: '51057',
        barcode: '83324',
        quantity: 368,
        price: 20162,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3514 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3515,
        artnumber: '23998',
        barcode: '27717',
        quantity: 29,
        price: 1382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3515 для Мытищи, Недвижимость, Сервис, Строй',
    },
    {
        id: 3516,
        artnumber: '80110',
        barcode: '87507',
        quantity: 374,
        price: 25335,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3516 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3517,
        artnumber: '86313',
        barcode: '61931',
        quantity: 351,
        price: 36402,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3517 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3518,
        artnumber: '98781',
        barcode: '72895',
        quantity: 198,
        price: 37408,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3518 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3519,
        artnumber: '81811',
        barcode: '22739',
        quantity: 489,
        price: 14160,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3519 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3520,
        artnumber: '59409',
        barcode: '75232',
        quantity: 316,
        price: 49354,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3520 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3521,
        artnumber: '23505',
        barcode: '45536',
        quantity: 198,
        price: 33421,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3521 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3522,
        artnumber: '81411',
        barcode: '44926',
        quantity: 383,
        price: 6155,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3522 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3523,
        artnumber: '57980',
        barcode: '17597',
        quantity: 253,
        price: 20878,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3523 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3524,
        artnumber: '53483',
        barcode: '48729',
        quantity: 497,
        price: 6851,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3524 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3525,
        artnumber: '44134',
        barcode: '67660',
        quantity: 326,
        price: 26343,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3525 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3526,
        artnumber: '36362',
        barcode: '28846',
        quantity: 224,
        price: 16849,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3526 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3527,
        artnumber: '63060',
        barcode: '52555',
        quantity: 472,
        price: 47679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3527 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3528,
        artnumber: '26716',
        barcode: '46375',
        quantity: 344,
        price: 46855,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3528 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3529,
        artnumber: '92591',
        barcode: '24095',
        quantity: 14,
        price: 36833,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3529 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3530,
        artnumber: '76093',
        barcode: '74637',
        quantity: 473,
        price: 13898,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3530 для Мытищи, Недвижимость, Сервис, Бета',
    },
    {
        id: 3531,
        artnumber: '62675',
        barcode: '68260',
        quantity: 128,
        price: 1037,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3531 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3532,
        artnumber: '89332',
        barcode: '67087',
        quantity: 312,
        price: 1206,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3532 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3533,
        artnumber: '87986',
        barcode: '12655',
        quantity: 18,
        price: 35308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3533 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3534,
        artnumber: '68219',
        barcode: '61247',
        quantity: 69,
        price: 840,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3534 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3535,
        artnumber: '78759',
        barcode: '69750',
        quantity: 168,
        price: 28109,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3535 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3536,
        artnumber: '64900',
        barcode: '33236',
        quantity: 166,
        price: 40549,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3536 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3537,
        artnumber: '72571',
        barcode: '47222',
        quantity: 320,
        price: 20724,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3537 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3538,
        artnumber: '21707',
        barcode: '77273',
        quantity: 97,
        price: 6232,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3538 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3539,
        artnumber: '27592',
        barcode: '65672',
        quantity: 423,
        price: 26368,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3539 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3540,
        artnumber: '49790',
        barcode: '15105',
        quantity: 131,
        price: 14500,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3540 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3541,
        artnumber: '57944',
        barcode: '58530',
        quantity: 238,
        price: 10268,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3541 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3542,
        artnumber: '74677',
        barcode: '37602',
        quantity: 384,
        price: 5108,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3542 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3543,
        artnumber: '55331',
        barcode: '23888',
        quantity: 196,
        price: 49008,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3543 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3544,
        artnumber: '94726',
        barcode: '53528',
        quantity: 486,
        price: 46751,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3544 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3545,
        artnumber: '58261',
        barcode: '94132',
        quantity: 453,
        price: 11621,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3545 для Мытищи, Недвижимость, Сервис, Гамма',
    },
    {
        id: 3546,
        artnumber: '77417',
        barcode: '96678',
        quantity: 397,
        price: 17215,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3546 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3547,
        artnumber: '29105',
        barcode: '46578',
        quantity: 348,
        price: 14803,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3547 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3548,
        artnumber: '66963',
        barcode: '30509',
        quantity: 380,
        price: 17562,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3548 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3549,
        artnumber: '29301',
        barcode: '75760',
        quantity: 359,
        price: 6017,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3549 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3550,
        artnumber: '63334',
        barcode: '93277',
        quantity: 343,
        price: 44931,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3550 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3551,
        artnumber: '24624',
        barcode: '75296',
        quantity: 332,
        price: 27642,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3551 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3552,
        artnumber: '95690',
        barcode: '80452',
        quantity: 74,
        price: 36484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3552 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3553,
        artnumber: '93788',
        barcode: '85889',
        quantity: 458,
        price: 37541,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3553 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3554,
        artnumber: '38787',
        barcode: '38166',
        quantity: 91,
        price: 5972,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3554 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3555,
        artnumber: '47011',
        barcode: '92250',
        quantity: 111,
        price: 26380,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3555 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3556,
        artnumber: '76428',
        barcode: '16272',
        quantity: 159,
        price: 36614,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3556 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3557,
        artnumber: '21795',
        barcode: '34569',
        quantity: 361,
        price: 23771,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3557 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3558,
        artnumber: '63862',
        barcode: '58113',
        quantity: 398,
        price: 5197,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3558 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3559,
        artnumber: '79568',
        barcode: '87226',
        quantity: 244,
        price: 45007,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3559 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3560,
        artnumber: '86113',
        barcode: '38546',
        quantity: 150,
        price: 16593,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3560 для Мытищи, Недвижимость, Сервис, Трио',
    },
    {
        id: 3561,
        artnumber: '63230',
        barcode: '37610',
        quantity: 432,
        price: 35313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3561 для Мытищи, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 3562,
        artnumber: '58245',
        barcode: '72529',
        quantity: 273,
        price: 32030,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3562 для Мытищи, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 3563,
        artnumber: '30074',
        barcode: '90853',
        quantity: 351,
        price: 46564,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3563 для Мытищи, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 3564,
        artnumber: '17812',
        barcode: '74095',
        quantity: 312,
        price: 44108,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3564 для Мытищи, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 3565,
        artnumber: '45217',
        barcode: '11510',
        quantity: 252,
        price: 6050,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3565 для Мытищи, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 3566,
        artnumber: '23200',
        barcode: '24014',
        quantity: 227,
        price: 21750,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3566 для Мытищи, Недвижимость, Запчасти, Строй',
    },
    {
        id: 3567,
        artnumber: '42184',
        barcode: '76815',
        quantity: 293,
        price: 40638,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3567 для Мытищи, Недвижимость, Запчасти, Строй',
    },
    {
        id: 3568,
        artnumber: '18386',
        barcode: '74836',
        quantity: 150,
        price: 7121,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3568 для Мытищи, Недвижимость, Запчасти, Строй',
    },
    {
        id: 3569,
        artnumber: '50388',
        barcode: '79613',
        quantity: 134,
        price: 43203,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3569 для Мытищи, Недвижимость, Запчасти, Строй',
    },
    {
        id: 3570,
        artnumber: '82497',
        barcode: '94046',
        quantity: 95,
        price: 16534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3570 для Мытищи, Недвижимость, Запчасти, Строй',
    },
    {
        id: 3571,
        artnumber: '75645',
        barcode: '85352',
        quantity: 169,
        price: 40333,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3571 для Мытищи, Недвижимость, Запчасти, Бета',
    },
    {
        id: 3572,
        artnumber: '96615',
        barcode: '78832',
        quantity: 353,
        price: 2165,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3572 для Мытищи, Недвижимость, Запчасти, Бета',
    },
    {
        id: 3573,
        artnumber: '51921',
        barcode: '52719',
        quantity: 241,
        price: 28877,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3573 для Мытищи, Недвижимость, Запчасти, Бета',
    },
    {
        id: 3574,
        artnumber: '75581',
        barcode: '99133',
        quantity: 372,
        price: 21059,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3574 для Мытищи, Недвижимость, Запчасти, Бета',
    },
    {
        id: 3575,
        artnumber: '23825',
        barcode: '52942',
        quantity: 266,
        price: 22332,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3575 для Мытищи, Недвижимость, Запчасти, Бета',
    },
    {
        id: 3576,
        artnumber: '87273',
        barcode: '68678',
        quantity: 34,
        price: 47275,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3576 для Мытищи, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 3577,
        artnumber: '98175',
        barcode: '84680',
        quantity: 467,
        price: 5120,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3577 для Мытищи, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 3578,
        artnumber: '26008',
        barcode: '90064',
        quantity: 43,
        price: 26450,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3578 для Мытищи, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 3579,
        artnumber: '52645',
        barcode: '53870',
        quantity: 175,
        price: 19170,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3579 для Мытищи, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 3580,
        artnumber: '19740',
        barcode: '25484',
        quantity: 248,
        price: 16306,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3580 для Мытищи, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 3581,
        artnumber: '39431',
        barcode: '27615',
        quantity: 102,
        price: 13240,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3581 для Мытищи, Недвижимость, Запчасти, Трио',
    },
    {
        id: 3582,
        artnumber: '35179',
        barcode: '92886',
        quantity: 423,
        price: 28680,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3582 для Мытищи, Недвижимость, Запчасти, Трио',
    },
    {
        id: 3583,
        artnumber: '67114',
        barcode: '61021',
        quantity: 327,
        price: 37121,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3583 для Мытищи, Недвижимость, Запчасти, Трио',
    },
    {
        id: 3584,
        artnumber: '37572',
        barcode: '34483',
        quantity: 48,
        price: 31620,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3584 для Мытищи, Недвижимость, Запчасти, Трио',
    },
    {
        id: 3585,
        artnumber: '64694',
        barcode: '69426',
        quantity: 125,
        price: 34632,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3585 для Мытищи, Недвижимость, Запчасти, Трио',
    },
    {
        id: 3586,
        artnumber: '74920',
        barcode: '57635',
        quantity: 457,
        price: 19086,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3586 для Мытищи, Недвижимость, Доставка, Альфа',
    },
    {
        id: 3587,
        artnumber: '20440',
        barcode: '52465',
        quantity: 77,
        price: 32744,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3587 для Мытищи, Недвижимость, Доставка, Альфа',
    },
    {
        id: 3588,
        artnumber: '68273',
        barcode: '27766',
        quantity: 336,
        price: 48140,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3588 для Мытищи, Недвижимость, Доставка, Альфа',
    },
    {
        id: 3589,
        artnumber: '18918',
        barcode: '13690',
        quantity: 431,
        price: 1122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3589 для Мытищи, Недвижимость, Доставка, Альфа',
    },
    {
        id: 3590,
        artnumber: '25335',
        barcode: '89310',
        quantity: 494,
        price: 33408,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3590 для Мытищи, Недвижимость, Доставка, Альфа',
    },
    {
        id: 3591,
        artnumber: '51827',
        barcode: '33771',
        quantity: 242,
        price: 15216,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3591 для Мытищи, Недвижимость, Доставка, Альфа',
    },
    {
        id: 3592,
        artnumber: '65756',
        barcode: '10251',
        quantity: 193,
        price: 44350,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3592 для Мытищи, Недвижимость, Доставка, Альфа',
    },
    {
        id: 3593,
        artnumber: '88891',
        barcode: '38328',
        quantity: 39,
        price: 6102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3593 для Мытищи, Недвижимость, Доставка, Альфа',
    },
    {
        id: 3594,
        artnumber: '15432',
        barcode: '38716',
        quantity: 285,
        price: 288,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3594 для Мытищи, Недвижимость, Доставка, Альфа',
    },
    {
        id: 3595,
        artnumber: '88499',
        barcode: '74073',
        quantity: 124,
        price: 32237,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3595 для Мытищи, Недвижимость, Доставка, Строй',
    },
    {
        id: 3596,
        artnumber: '16363',
        barcode: '44382',
        quantity: 55,
        price: 29173,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3596 для Мытищи, Недвижимость, Доставка, Строй',
    },
    {
        id: 3597,
        artnumber: '73115',
        barcode: '35855',
        quantity: 482,
        price: 28009,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3597 для Мытищи, Недвижимость, Доставка, Строй',
    },
    {
        id: 3598,
        artnumber: '43735',
        barcode: '77590',
        quantity: 462,
        price: 39388,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3598 для Мытищи, Недвижимость, Доставка, Строй',
    },
    {
        id: 3599,
        artnumber: '67839',
        barcode: '19748',
        quantity: 379,
        price: 10020,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3599 для Мытищи, Недвижимость, Доставка, Строй',
    },
    {
        id: 3600,
        artnumber: '63182',
        barcode: '99168',
        quantity: 463,
        price: 18447,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3600 для Мытищи, Недвижимость, Доставка, Строй',
    },
    {
        id: 3601,
        artnumber: '74376',
        barcode: '82886',
        quantity: 326,
        price: 42532,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3601 для Мытищи, Недвижимость, Доставка, Строй',
    },
    {
        id: 3602,
        artnumber: '25715',
        barcode: '25991',
        quantity: 315,
        price: 32112,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3602 для Мытищи, Недвижимость, Доставка, Строй',
    },
    {
        id: 3603,
        artnumber: '10403',
        barcode: '79103',
        quantity: 211,
        price: 16858,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3603 для Мытищи, Недвижимость, Доставка, Строй',
    },
    {
        id: 3604,
        artnumber: '45992',
        barcode: '18149',
        quantity: 7,
        price: 22099,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3604 для Мытищи, Недвижимость, Доставка, Бета',
    },
    {
        id: 3605,
        artnumber: '91262',
        barcode: '51308',
        quantity: 265,
        price: 25537,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3605 для Мытищи, Недвижимость, Доставка, Бета',
    },
    {
        id: 3606,
        artnumber: '34129',
        barcode: '58063',
        quantity: 317,
        price: 45278,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3606 для Мытищи, Недвижимость, Доставка, Бета',
    },
    {
        id: 3607,
        artnumber: '47254',
        barcode: '39876',
        quantity: 71,
        price: 25476,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3607 для Мытищи, Недвижимость, Доставка, Бета',
    },
    {
        id: 3608,
        artnumber: '72964',
        barcode: '83455',
        quantity: 270,
        price: 1322,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3608 для Мытищи, Недвижимость, Доставка, Бета',
    },
    {
        id: 3609,
        artnumber: '56734',
        barcode: '90488',
        quantity: 43,
        price: 41128,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3609 для Мытищи, Недвижимость, Доставка, Бета',
    },
    {
        id: 3610,
        artnumber: '32065',
        barcode: '37195',
        quantity: 85,
        price: 45878,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3610 для Мытищи, Недвижимость, Доставка, Бета',
    },
    {
        id: 3611,
        artnumber: '33459',
        barcode: '76308',
        quantity: 345,
        price: 34527,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3611 для Мытищи, Недвижимость, Доставка, Бета',
    },
    {
        id: 3612,
        artnumber: '22673',
        barcode: '86982',
        quantity: 146,
        price: 20970,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3612 для Мытищи, Недвижимость, Доставка, Бета',
    },
    {
        id: 3613,
        artnumber: '25967',
        barcode: '16495',
        quantity: 467,
        price: 34922,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3613 для Мытищи, Недвижимость, Доставка, Гамма',
    },
    {
        id: 3614,
        artnumber: '87975',
        barcode: '84706',
        quantity: 246,
        price: 37792,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3614 для Мытищи, Недвижимость, Доставка, Гамма',
    },
    {
        id: 3615,
        artnumber: '75081',
        barcode: '99217',
        quantity: 491,
        price: 36388,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3615 для Мытищи, Недвижимость, Доставка, Гамма',
    },
    {
        id: 3616,
        artnumber: '66566',
        barcode: '35487',
        quantity: 382,
        price: 47080,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3616 для Мытищи, Недвижимость, Доставка, Гамма',
    },
    {
        id: 3617,
        artnumber: '54109',
        barcode: '90059',
        quantity: 65,
        price: 11691,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3617 для Мытищи, Недвижимость, Доставка, Гамма',
    },
    {
        id: 3618,
        artnumber: '15245',
        barcode: '28630',
        quantity: 486,
        price: 19772,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3618 для Мытищи, Недвижимость, Доставка, Гамма',
    },
    {
        id: 3619,
        artnumber: '61871',
        barcode: '92794',
        quantity: 396,
        price: 34661,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3619 для Мытищи, Недвижимость, Доставка, Гамма',
    },
    {
        id: 3620,
        artnumber: '84522',
        barcode: '43677',
        quantity: 91,
        price: 41528,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3620 для Мытищи, Недвижимость, Доставка, Гамма',
    },
    {
        id: 3621,
        artnumber: '81534',
        barcode: '98263',
        quantity: 188,
        price: 23984,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3621 для Мытищи, Недвижимость, Доставка, Гамма',
    },
    {
        id: 3622,
        artnumber: '69432',
        barcode: '81769',
        quantity: 102,
        price: 34722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3622 для Мытищи, Недвижимость, Доставка, Трио',
    },
    {
        id: 3623,
        artnumber: '82993',
        barcode: '75306',
        quantity: 306,
        price: 13203,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3623 для Мытищи, Недвижимость, Доставка, Трио',
    },
    {
        id: 3624,
        artnumber: '12736',
        barcode: '48058',
        quantity: 279,
        price: 444,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3624 для Мытищи, Недвижимость, Доставка, Трио',
    },
    {
        id: 3625,
        artnumber: '45510',
        barcode: '45477',
        quantity: 267,
        price: 5807,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3625 для Мытищи, Недвижимость, Доставка, Трио',
    },
    {
        id: 3626,
        artnumber: '61261',
        barcode: '33543',
        quantity: 343,
        price: 5282,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3626 для Мытищи, Недвижимость, Доставка, Трио',
    },
    {
        id: 3627,
        artnumber: '87913',
        barcode: '39617',
        quantity: 389,
        price: 38835,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3627 для Мытищи, Недвижимость, Доставка, Трио',
    },
    {
        id: 3628,
        artnumber: '30269',
        barcode: '40631',
        quantity: 469,
        price: 45102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3628 для Мытищи, Недвижимость, Доставка, Трио',
    },
    {
        id: 3629,
        artnumber: '98069',
        barcode: '24053',
        quantity: 252,
        price: 8254,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3629 для Мытищи, Недвижимость, Доставка, Трио',
    },
    {
        id: 3630,
        artnumber: '22595',
        barcode: '38494',
        quantity: 230,
        price: 13602,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3630 для Мытищи, Недвижимость, Доставка, Трио',
    },
    {
        id: 3631,
        artnumber: '98552',
        barcode: '12765',
        quantity: 361,
        price: 33699,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3631 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3632,
        artnumber: '83649',
        barcode: '68985',
        quantity: 495,
        price: 7782,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3632 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3633,
        artnumber: '12654',
        barcode: '63557',
        quantity: 264,
        price: 30329,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3633 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3634,
        artnumber: '87623',
        barcode: '40286',
        quantity: 118,
        price: 5690,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3634 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3635,
        artnumber: '38214',
        barcode: '71811',
        quantity: 1,
        price: 311,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3635 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3636,
        artnumber: '45889',
        barcode: '17554',
        quantity: 332,
        price: 22974,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3636 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3637,
        artnumber: '47767',
        barcode: '44177',
        quantity: 430,
        price: 42790,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3637 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3638,
        artnumber: '82408',
        barcode: '30475',
        quantity: 240,
        price: 4983,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3638 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3639,
        artnumber: '13684',
        barcode: '66782',
        quantity: 49,
        price: 23285,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3639 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3640,
        artnumber: '75751',
        barcode: '16451',
        quantity: 140,
        price: 41597,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3640 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3641,
        artnumber: '69911',
        barcode: '35397',
        quantity: 129,
        price: 45928,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3641 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3642,
        artnumber: '77868',
        barcode: '91990',
        quantity: 175,
        price: 11616,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3642 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3643,
        artnumber: '14518',
        barcode: '52624',
        quantity: 105,
        price: 33626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3643 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3644,
        artnumber: '52292',
        barcode: '16596',
        quantity: 14,
        price: 41287,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3644 для Мытищи, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 3645,
        artnumber: '51818',
        barcode: '60348',
        quantity: 173,
        price: 33143,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3645 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3646,
        artnumber: '35806',
        barcode: '11737',
        quantity: 441,
        price: 11288,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3646 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3647,
        artnumber: '36664',
        barcode: '84337',
        quantity: 100,
        price: 731,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3647 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3648,
        artnumber: '62291',
        barcode: '13382',
        quantity: 411,
        price: 38424,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3648 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3649,
        artnumber: '70366',
        barcode: '75124',
        quantity: 360,
        price: 36987,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3649 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3650,
        artnumber: '22707',
        barcode: '37553',
        quantity: 154,
        price: 31971,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3650 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3651,
        artnumber: '28376',
        barcode: '76719',
        quantity: 233,
        price: 37485,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3651 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3652,
        artnumber: '55027',
        barcode: '32923',
        quantity: 362,
        price: 48534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3652 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3653,
        artnumber: '66457',
        barcode: '70973',
        quantity: 23,
        price: 16415,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3653 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3654,
        artnumber: '72975',
        barcode: '41416',
        quantity: 67,
        price: 23300,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3654 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3655,
        artnumber: '96126',
        barcode: '93401',
        quantity: 363,
        price: 12101,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3655 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3656,
        artnumber: '22873',
        barcode: '63618',
        quantity: 336,
        price: 38070,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3656 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3657,
        artnumber: '55269',
        barcode: '39327',
        quantity: 90,
        price: 28541,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3657 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3658,
        artnumber: '44554',
        barcode: '63839',
        quantity: 407,
        price: 49496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3658 для Мытищи, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 3659,
        artnumber: '91913',
        barcode: '24432',
        quantity: 252,
        price: 27589,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3659 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3660,
        artnumber: '53972',
        barcode: '99172',
        quantity: 37,
        price: 17830,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3660 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3661,
        artnumber: '45420',
        barcode: '91580',
        quantity: 117,
        price: 1238,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3661 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3662,
        artnumber: '70005',
        barcode: '43148',
        quantity: 293,
        price: 22836,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3662 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3663,
        artnumber: '45611',
        barcode: '38108',
        quantity: 393,
        price: 47647,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3663 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3664,
        artnumber: '71466',
        barcode: '41495',
        quantity: 131,
        price: 24995,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3664 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3665,
        artnumber: '68677',
        barcode: '31222',
        quantity: 53,
        price: 45656,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3665 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3666,
        artnumber: '34287',
        barcode: '87737',
        quantity: 98,
        price: 10138,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3666 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3667,
        artnumber: '33397',
        barcode: '83909',
        quantity: 296,
        price: 14530,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3667 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3668,
        artnumber: '18822',
        barcode: '52372',
        quantity: 218,
        price: 18684,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3668 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3669,
        artnumber: '45858',
        barcode: '63823',
        quantity: 235,
        price: 33095,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3669 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3670,
        artnumber: '29236',
        barcode: '63550',
        quantity: 125,
        price: 15338,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3670 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3671,
        artnumber: '65668',
        barcode: '26343',
        quantity: 450,
        price: 41898,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3671 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3672,
        artnumber: '19878',
        barcode: '21088',
        quantity: 487,
        price: 37343,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3672 для Мытищи, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 3673,
        artnumber: '91768',
        barcode: '43644',
        quantity: 222,
        price: 48615,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3673 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3674,
        artnumber: '49323',
        barcode: '65622',
        quantity: 29,
        price: 31441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3674 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3675,
        artnumber: '73911',
        barcode: '73517',
        quantity: 64,
        price: 8607,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3675 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3676,
        artnumber: '16229',
        barcode: '71676',
        quantity: 187,
        price: 5500,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3676 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3677,
        artnumber: '43584',
        barcode: '71250',
        quantity: 8,
        price: 22136,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3677 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3678,
        artnumber: '57528',
        barcode: '64744',
        quantity: 412,
        price: 6475,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3678 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3679,
        artnumber: '83738',
        barcode: '57296',
        quantity: 71,
        price: 43587,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3679 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3680,
        artnumber: '47130',
        barcode: '91700',
        quantity: 431,
        price: 34981,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3680 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3681,
        artnumber: '36573',
        barcode: '24321',
        quantity: 87,
        price: 13620,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3681 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3682,
        artnumber: '80976',
        barcode: '49472',
        quantity: 340,
        price: 27214,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3682 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3683,
        artnumber: '65649',
        barcode: '92280',
        quantity: 57,
        price: 1013,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3683 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3684,
        artnumber: '52670',
        barcode: '46005',
        quantity: 357,
        price: 15478,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3684 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3685,
        artnumber: '33401',
        barcode: '93734',
        quantity: 239,
        price: 26082,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3685 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3686,
        artnumber: '69410',
        barcode: '92272',
        quantity: 26,
        price: 10931,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3686 для Мытищи, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 3687,
        artnumber: '39367',
        barcode: '74049',
        quantity: 7,
        price: 47840,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3687 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3688,
        artnumber: '34693',
        barcode: '38934',
        quantity: 132,
        price: 9301,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3688 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3689,
        artnumber: '80791',
        barcode: '16845',
        quantity: 406,
        price: 29282,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3689 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3690,
        artnumber: '78127',
        barcode: '80678',
        quantity: 300,
        price: 41727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3690 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3691,
        artnumber: '63572',
        barcode: '72035',
        quantity: 216,
        price: 37048,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3691 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3692,
        artnumber: '87794',
        barcode: '14573',
        quantity: 429,
        price: 18335,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3692 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3693,
        artnumber: '29172',
        barcode: '64575',
        quantity: 17,
        price: 21326,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3693 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3694,
        artnumber: '73450',
        barcode: '86364',
        quantity: 231,
        price: 38201,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3694 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3695,
        artnumber: '40156',
        barcode: '73973',
        quantity: 288,
        price: 26093,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3695 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3696,
        artnumber: '56984',
        barcode: '44759',
        quantity: 163,
        price: 29229,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3696 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3697,
        artnumber: '98701',
        barcode: '21284',
        quantity: 420,
        price: 4321,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3697 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3698,
        artnumber: '90904',
        barcode: '60734',
        quantity: 113,
        price: 5943,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3698 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3699,
        artnumber: '49808',
        barcode: '80638',
        quantity: 458,
        price: 24904,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3699 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3700,
        artnumber: '85126',
        barcode: '95757',
        quantity: 148,
        price: 15524,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3700 для Мытищи, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 3701,
        artnumber: '66807',
        barcode: '37425',
        quantity: 269,
        price: 48122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3701 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3702,
        artnumber: '28565',
        barcode: '95929',
        quantity: 53,
        price: 8454,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3702 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3703,
        artnumber: '30655',
        barcode: '27619',
        quantity: 234,
        price: 46666,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3703 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3704,
        artnumber: '81886',
        barcode: '90647',
        quantity: 39,
        price: 14943,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3704 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3705,
        artnumber: '30306',
        barcode: '18995',
        quantity: 106,
        price: 4661,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3705 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3706,
        artnumber: '92421',
        barcode: '61913',
        quantity: 37,
        price: 20952,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3706 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3707,
        artnumber: '81279',
        barcode: '24809',
        quantity: 25,
        price: 12389,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3707 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3708,
        artnumber: '13565',
        barcode: '10712',
        quantity: 138,
        price: 26668,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3708 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3709,
        artnumber: '93546',
        barcode: '31335',
        quantity: 293,
        price: 1482,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3709 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3710,
        artnumber: '64415',
        barcode: '61455',
        quantity: 453,
        price: 39670,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3710 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3711,
        artnumber: '98566',
        barcode: '40010',
        quantity: 443,
        price: 24569,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3711 для Мытищи, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 3712,
        artnumber: '92213',
        barcode: '12966',
        quantity: 50,
        price: 29381,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3712 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3713,
        artnumber: '25208',
        barcode: '27458',
        quantity: 447,
        price: 30463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3713 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3714,
        artnumber: '71093',
        barcode: '12584',
        quantity: 120,
        price: 46176,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3714 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3715,
        artnumber: '86140',
        barcode: '84531',
        quantity: 456,
        price: 35634,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3715 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3716,
        artnumber: '33584',
        barcode: '34729',
        quantity: 163,
        price: 29872,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3716 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3717,
        artnumber: '32704',
        barcode: '32669',
        quantity: 305,
        price: 5279,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3717 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3718,
        artnumber: '96419',
        barcode: '85148',
        quantity: 230,
        price: 2763,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3718 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3719,
        artnumber: '24910',
        barcode: '74677',
        quantity: 163,
        price: 29418,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3719 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3720,
        artnumber: '59262',
        barcode: '69832',
        quantity: 88,
        price: 9646,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3720 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3721,
        artnumber: '54257',
        barcode: '83410',
        quantity: 182,
        price: 12865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3721 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3722,
        artnumber: '99879',
        barcode: '41845',
        quantity: 418,
        price: 41256,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3722 для Мытищи, Недвижимость, Замена масла, Строй',
    },
    {
        id: 3723,
        artnumber: '96086',
        barcode: '99287',
        quantity: 481,
        price: 2589,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3723 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3724,
        artnumber: '88638',
        barcode: '66292',
        quantity: 357,
        price: 24772,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3724 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3725,
        artnumber: '50856',
        barcode: '49809',
        quantity: 442,
        price: 7938,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3725 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3726,
        artnumber: '57664',
        barcode: '10486',
        quantity: 68,
        price: 12121,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3726 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3727,
        artnumber: '56638',
        barcode: '43510',
        quantity: 422,
        price: 7813,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3727 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3728,
        artnumber: '12042',
        barcode: '94765',
        quantity: 477,
        price: 43057,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3728 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3729,
        artnumber: '33571',
        barcode: '75558',
        quantity: 420,
        price: 45864,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3729 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3730,
        artnumber: '29224',
        barcode: '92624',
        quantity: 135,
        price: 40804,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3730 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3731,
        artnumber: '84437',
        barcode: '51659',
        quantity: 356,
        price: 41625,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3731 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3732,
        artnumber: '31759',
        barcode: '39051',
        quantity: 437,
        price: 23557,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3732 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3733,
        artnumber: '20131',
        barcode: '31012',
        quantity: 158,
        price: 38014,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3733 для Мытищи, Недвижимость, Замена масла, Бета',
    },
    {
        id: 3734,
        artnumber: '92269',
        barcode: '29422',
        quantity: 357,
        price: 36467,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3734 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3735,
        artnumber: '73482',
        barcode: '40659',
        quantity: 141,
        price: 8213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3735 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3736,
        artnumber: '50354',
        barcode: '30871',
        quantity: 82,
        price: 46422,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3736 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3737,
        artnumber: '71087',
        barcode: '12382',
        quantity: 418,
        price: 24220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3737 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3738,
        artnumber: '35117',
        barcode: '47797',
        quantity: 496,
        price: 1837,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3738 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3739,
        artnumber: '63333',
        barcode: '83150',
        quantity: 374,
        price: 8936,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3739 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3740,
        artnumber: '58184',
        barcode: '70965',
        quantity: 164,
        price: 23834,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3740 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3741,
        artnumber: '91426',
        barcode: '66699',
        quantity: 388,
        price: 9535,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3741 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3742,
        artnumber: '25569',
        barcode: '57252',
        quantity: 310,
        price: 13003,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3742 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3743,
        artnumber: '43343',
        barcode: '22239',
        quantity: 301,
        price: 47824,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3743 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3744,
        artnumber: '90170',
        barcode: '80455',
        quantity: 52,
        price: 19210,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3744 для Мытищи, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 3745,
        artnumber: '71193',
        barcode: '60500',
        quantity: 67,
        price: 42726,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3745 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3746,
        artnumber: '57480',
        barcode: '53514',
        quantity: 396,
        price: 41102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3746 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3747,
        artnumber: '25249',
        barcode: '23571',
        quantity: 89,
        price: 19287,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3747 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3748,
        artnumber: '50516',
        barcode: '58110',
        quantity: 406,
        price: 30095,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3748 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3749,
        artnumber: '46919',
        barcode: '97733',
        quantity: 147,
        price: 37046,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3749 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3750,
        artnumber: '78650',
        barcode: '45924',
        quantity: 490,
        price: 3876,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3750 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3751,
        artnumber: '95464',
        barcode: '10961',
        quantity: 13,
        price: 26912,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3751 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3752,
        artnumber: '14900',
        barcode: '47650',
        quantity: 381,
        price: 32096,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3752 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3753,
        artnumber: '33959',
        barcode: '17312',
        quantity: 248,
        price: 30862,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3753 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3754,
        artnumber: '94328',
        barcode: '79807',
        quantity: 257,
        price: 1672,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3754 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3755,
        artnumber: '44195',
        barcode: '37617',
        quantity: 183,
        price: 17303,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3755 для Мытищи, Недвижимость, Замена масла, Трио',
    },
    {
        id: 3756,
        artnumber: '19354',
        barcode: '50042',
        quantity: 157,
        price: 39655,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3756 для Мытищи, Красота, Сервис, Альфа',
    },
    {
        id: 3757,
        artnumber: '38279',
        barcode: '90539',
        quantity: 350,
        price: 9353,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3757 для Мытищи, Красота, Сервис, Альфа',
    },
    {
        id: 3758,
        artnumber: '30417',
        barcode: '39312',
        quantity: 63,
        price: 11844,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3758 для Мытищи, Красота, Сервис, Альфа',
    },
    {
        id: 3759,
        artnumber: '71019',
        barcode: '26121',
        quantity: 109,
        price: 27059,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3759 для Мытищи, Красота, Сервис, Альфа',
    },
    {
        id: 3760,
        artnumber: '39766',
        barcode: '70148',
        quantity: 12,
        price: 16474,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3760 для Мытищи, Красота, Сервис, Альфа',
    },
    {
        id: 3761,
        artnumber: '15520',
        barcode: '73843',
        quantity: 388,
        price: 47906,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3761 для Мытищи, Красота, Сервис, Альфа',
    },
    {
        id: 3762,
        artnumber: '31924',
        barcode: '35946',
        quantity: 10,
        price: 27723,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3762 для Мытищи, Красота, Сервис, Строй',
    },
    {
        id: 3763,
        artnumber: '20923',
        barcode: '24724',
        quantity: 392,
        price: 18276,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3763 для Мытищи, Красота, Сервис, Строй',
    },
    {
        id: 3764,
        artnumber: '74134',
        barcode: '25828',
        quantity: 226,
        price: 46335,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3764 для Мытищи, Красота, Сервис, Строй',
    },
    {
        id: 3765,
        artnumber: '43954',
        barcode: '19588',
        quantity: 102,
        price: 12112,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3765 для Мытищи, Красота, Сервис, Строй',
    },
    {
        id: 3766,
        artnumber: '77889',
        barcode: '94192',
        quantity: 100,
        price: 33993,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3766 для Мытищи, Красота, Сервис, Строй',
    },
    {
        id: 3767,
        artnumber: '71122',
        barcode: '61804',
        quantity: 336,
        price: 47548,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3767 для Мытищи, Красота, Сервис, Строй',
    },
    {
        id: 3768,
        artnumber: '32260',
        barcode: '49161',
        quantity: 490,
        price: 5971,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3768 для Мытищи, Красота, Сервис, Бета',
    },
    {
        id: 3769,
        artnumber: '26647',
        barcode: '26009',
        quantity: 167,
        price: 25356,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3769 для Мытищи, Красота, Сервис, Бета',
    },
    {
        id: 3770,
        artnumber: '61884',
        barcode: '28975',
        quantity: 299,
        price: 32365,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3770 для Мытищи, Красота, Сервис, Бета',
    },
    {
        id: 3771,
        artnumber: '77200',
        barcode: '80121',
        quantity: 255,
        price: 6695,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3771 для Мытищи, Красота, Сервис, Бета',
    },
    {
        id: 3772,
        artnumber: '94284',
        barcode: '50360',
        quantity: 40,
        price: 37158,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3772 для Мытищи, Красота, Сервис, Бета',
    },
    {
        id: 3773,
        artnumber: '70329',
        barcode: '95340',
        quantity: 212,
        price: 46166,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3773 для Мытищи, Красота, Сервис, Бета',
    },
    {
        id: 3774,
        artnumber: '66748',
        barcode: '32224',
        quantity: 426,
        price: 39498,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3774 для Мытищи, Красота, Сервис, Гамма',
    },
    {
        id: 3775,
        artnumber: '13996',
        barcode: '88491',
        quantity: 456,
        price: 43118,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3775 для Мытищи, Красота, Сервис, Гамма',
    },
    {
        id: 3776,
        artnumber: '13573',
        barcode: '51147',
        quantity: 93,
        price: 28348,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3776 для Мытищи, Красота, Сервис, Гамма',
    },
    {
        id: 3777,
        artnumber: '38751',
        barcode: '81157',
        quantity: 92,
        price: 37978,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3777 для Мытищи, Красота, Сервис, Гамма',
    },
    {
        id: 3778,
        artnumber: '75735',
        barcode: '40306',
        quantity: 126,
        price: 7307,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3778 для Мытищи, Красота, Сервис, Гамма',
    },
    {
        id: 3779,
        artnumber: '76844',
        barcode: '41201',
        quantity: 101,
        price: 6446,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3779 для Мытищи, Красота, Сервис, Гамма',
    },
    {
        id: 3780,
        artnumber: '48200',
        barcode: '72633',
        quantity: 286,
        price: 46363,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3780 для Мытищи, Красота, Сервис, Трио',
    },
    {
        id: 3781,
        artnumber: '41476',
        barcode: '17896',
        quantity: 203,
        price: 38420,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3781 для Мытищи, Красота, Сервис, Трио',
    },
    {
        id: 3782,
        artnumber: '83105',
        barcode: '39251',
        quantity: 43,
        price: 9463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3782 для Мытищи, Красота, Сервис, Трио',
    },
    {
        id: 3783,
        artnumber: '98642',
        barcode: '85560',
        quantity: 436,
        price: 37987,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3783 для Мытищи, Красота, Сервис, Трио',
    },
    {
        id: 3784,
        artnumber: '45868',
        barcode: '33652',
        quantity: 106,
        price: 13172,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3784 для Мытищи, Красота, Сервис, Трио',
    },
    {
        id: 3785,
        artnumber: '71338',
        barcode: '30515',
        quantity: 68,
        price: 25206,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3785 для Мытищи, Красота, Сервис, Трио',
    },
    {
        id: 3786,
        artnumber: '37010',
        barcode: '68915',
        quantity: 305,
        price: 35469,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3786 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3787,
        artnumber: '51320',
        barcode: '77550',
        quantity: 206,
        price: 19865,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3787 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3788,
        artnumber: '91649',
        barcode: '48272',
        quantity: 479,
        price: 37315,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3788 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3789,
        artnumber: '61052',
        barcode: '83559',
        quantity: 370,
        price: 34164,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3789 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3790,
        artnumber: '91744',
        barcode: '60031',
        quantity: 292,
        price: 32695,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3790 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3791,
        artnumber: '27148',
        barcode: '68356',
        quantity: 123,
        price: 34923,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3791 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3792,
        artnumber: '44106',
        barcode: '82048',
        quantity: 188,
        price: 7312,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3792 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3793,
        artnumber: '24677',
        barcode: '12376',
        quantity: 393,
        price: 23788,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3793 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3794,
        artnumber: '59178',
        barcode: '31746',
        quantity: 328,
        price: 46053,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3794 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3795,
        artnumber: '88872',
        barcode: '64044',
        quantity: 35,
        price: 29619,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3795 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3796,
        artnumber: '93858',
        barcode: '99821',
        quantity: 283,
        price: 21254,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3796 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3797,
        artnumber: '79470',
        barcode: '36406',
        quantity: 51,
        price: 31203,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3797 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3798,
        artnumber: '18328',
        barcode: '15903',
        quantity: 409,
        price: 14746,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3798 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3799,
        artnumber: '25183',
        barcode: '88467',
        quantity: 408,
        price: 6657,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3799 для Мытищи, Красота, Запчасти, Альфа',
    },
    {
        id: 3800,
        artnumber: '42607',
        barcode: '30116',
        quantity: 242,
        price: 21122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3800 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3801,
        artnumber: '51902',
        barcode: '32091',
        quantity: 440,
        price: 37158,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3801 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3802,
        artnumber: '55105',
        barcode: '11022',
        quantity: 357,
        price: 34314,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3802 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3803,
        artnumber: '55106',
        barcode: '31133',
        quantity: 278,
        price: 22487,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3803 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3804,
        artnumber: '50305',
        barcode: '42241',
        quantity: 424,
        price: 6200,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3804 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3805,
        artnumber: '62978',
        barcode: '28219',
        quantity: 207,
        price: 38765,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3805 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3806,
        artnumber: '32933',
        barcode: '84958',
        quantity: 309,
        price: 2744,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3806 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3807,
        artnumber: '39572',
        barcode: '92390',
        quantity: 323,
        price: 10263,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3807 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3808,
        artnumber: '80470',
        barcode: '35964',
        quantity: 483,
        price: 35056,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3808 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3809,
        artnumber: '31000',
        barcode: '76915',
        quantity: 401,
        price: 5917,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3809 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3810,
        artnumber: '82793',
        barcode: '49214',
        quantity: 293,
        price: 49391,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3810 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3811,
        artnumber: '48512',
        barcode: '87201',
        quantity: 161,
        price: 37570,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3811 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3812,
        artnumber: '97224',
        barcode: '32479',
        quantity: 168,
        price: 12923,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3812 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3813,
        artnumber: '77223',
        barcode: '29936',
        quantity: 160,
        price: 11884,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3813 для Мытищи, Красота, Запчасти, Строй',
    },
    {
        id: 3814,
        artnumber: '48260',
        barcode: '27220',
        quantity: 160,
        price: 23791,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3814 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3815,
        artnumber: '97926',
        barcode: '81713',
        quantity: 190,
        price: 48835,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3815 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3816,
        artnumber: '96440',
        barcode: '61160',
        quantity: 219,
        price: 31758,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3816 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3817,
        artnumber: '41817',
        barcode: '28363',
        quantity: 289,
        price: 25300,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3817 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3818,
        artnumber: '85620',
        barcode: '88999',
        quantity: 215,
        price: 23981,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3818 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3819,
        artnumber: '55689',
        barcode: '48363',
        quantity: 486,
        price: 21447,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3819 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3820,
        artnumber: '91752',
        barcode: '54630',
        quantity: 486,
        price: 17062,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3820 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3821,
        artnumber: '88155',
        barcode: '85061',
        quantity: 142,
        price: 41905,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3821 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3822,
        artnumber: '31213',
        barcode: '98131',
        quantity: 288,
        price: 40099,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3822 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3823,
        artnumber: '58023',
        barcode: '30753',
        quantity: 317,
        price: 37357,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3823 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3824,
        artnumber: '66422',
        barcode: '70876',
        quantity: 100,
        price: 14001,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3824 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3825,
        artnumber: '22885',
        barcode: '55246',
        quantity: 456,
        price: 27107,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3825 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3826,
        artnumber: '91077',
        barcode: '97503',
        quantity: 10,
        price: 25379,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3826 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3827,
        artnumber: '59431',
        barcode: '11348',
        quantity: 149,
        price: 625,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3827 для Мытищи, Красота, Запчасти, Бета',
    },
    {
        id: 3828,
        artnumber: '72844',
        barcode: '72483',
        quantity: 78,
        price: 24832,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3828 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3829,
        artnumber: '17231',
        barcode: '93346',
        quantity: 457,
        price: 12074,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3829 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3830,
        artnumber: '36252',
        barcode: '62939',
        quantity: 4,
        price: 48612,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3830 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3831,
        artnumber: '68160',
        barcode: '29044',
        quantity: 301,
        price: 34000,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3831 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3832,
        artnumber: '19738',
        barcode: '53208',
        quantity: 451,
        price: 37922,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3832 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3833,
        artnumber: '14218',
        barcode: '92998',
        quantity: 319,
        price: 19127,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3833 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3834,
        artnumber: '14180',
        barcode: '10330',
        quantity: 307,
        price: 25066,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3834 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3835,
        artnumber: '27703',
        barcode: '58191',
        quantity: 53,
        price: 15644,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3835 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3836,
        artnumber: '33691',
        barcode: '67991',
        quantity: 232,
        price: 13236,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3836 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3837,
        artnumber: '89248',
        barcode: '26264',
        quantity: 189,
        price: 7800,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3837 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3838,
        artnumber: '57986',
        barcode: '93504',
        quantity: 92,
        price: 1707,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3838 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3839,
        artnumber: '95623',
        barcode: '99590',
        quantity: 135,
        price: 21317,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3839 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3840,
        artnumber: '58132',
        barcode: '84769',
        quantity: 251,
        price: 5481,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3840 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3841,
        artnumber: '19111',
        barcode: '40994',
        quantity: 23,
        price: 40091,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3841 для Мытищи, Красота, Запчасти, Гамма',
    },
    {
        id: 3842,
        artnumber: '55600',
        barcode: '35960',
        quantity: 461,
        price: 12740,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3842 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3843,
        artnumber: '56348',
        barcode: '10982',
        quantity: 372,
        price: 6210,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3843 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3844,
        artnumber: '97928',
        barcode: '53842',
        quantity: 209,
        price: 11577,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3844 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3845,
        artnumber: '61084',
        barcode: '18250',
        quantity: 423,
        price: 40755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3845 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3846,
        artnumber: '75787',
        barcode: '75833',
        quantity: 2,
        price: 7576,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3846 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3847,
        artnumber: '51595',
        barcode: '16654',
        quantity: 474,
        price: 28832,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3847 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3848,
        artnumber: '11844',
        barcode: '56765',
        quantity: 296,
        price: 25023,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3848 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3849,
        artnumber: '49745',
        barcode: '67397',
        quantity: 237,
        price: 33206,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3849 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3850,
        artnumber: '43034',
        barcode: '79663',
        quantity: 153,
        price: 29862,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3850 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3851,
        artnumber: '91567',
        barcode: '67027',
        quantity: 268,
        price: 31046,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3851 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3852,
        artnumber: '89595',
        barcode: '24632',
        quantity: 221,
        price: 32610,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3852 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3853,
        artnumber: '70809',
        barcode: '18626',
        quantity: 243,
        price: 6853,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3853 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3854,
        artnumber: '79420',
        barcode: '93086',
        quantity: 126,
        price: 7399,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3854 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3855,
        artnumber: '89620',
        barcode: '49275',
        quantity: 49,
        price: 11112,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3855 для Мытищи, Красота, Запчасти, Трио',
    },
    {
        id: 3856,
        artnumber: '81497',
        barcode: '11943',
        quantity: 222,
        price: 29791,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3856 для Мытищи, Красота, Доставка, Альфа',
    },
    {
        id: 3857,
        artnumber: '81184',
        barcode: '76655',
        quantity: 475,
        price: 2766,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3857 для Мытищи, Красота, Доставка, Альфа',
    },
    {
        id: 3858,
        artnumber: '62413',
        barcode: '27323',
        quantity: 438,
        price: 29847,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3858 для Мытищи, Красота, Доставка, Альфа',
    },
    {
        id: 3859,
        artnumber: '89694',
        barcode: '17099',
        quantity: 459,
        price: 22599,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3859 для Мытищи, Красота, Доставка, Альфа',
    },
    {
        id: 3860,
        artnumber: '76106',
        barcode: '53365',
        quantity: 285,
        price: 30870,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3860 для Мытищи, Красота, Доставка, Альфа',
    },
    {
        id: 3861,
        artnumber: '93529',
        barcode: '95249',
        quantity: 333,
        price: 33275,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3861 для Мытищи, Красота, Доставка, Альфа',
    },
    {
        id: 3862,
        artnumber: '91590',
        barcode: '85058',
        quantity: 328,
        price: 19609,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3862 для Мытищи, Красота, Доставка, Альфа',
    },
    {
        id: 3863,
        artnumber: '24623',
        barcode: '87132',
        quantity: 259,
        price: 18209,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3863 для Мытищи, Красота, Доставка, Альфа',
    },
    {
        id: 3864,
        artnumber: '46726',
        barcode: '80884',
        quantity: 273,
        price: 36777,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3864 для Мытищи, Красота, Доставка, Альфа',
    },
    {
        id: 3865,
        artnumber: '82757',
        barcode: '64283',
        quantity: 189,
        price: 5845,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3865 для Мытищи, Красота, Доставка, Альфа',
    },
    {
        id: 3866,
        artnumber: '11442',
        barcode: '65331',
        quantity: 4,
        price: 42087,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3866 для Мытищи, Красота, Доставка, Строй',
    },
    {
        id: 3867,
        artnumber: '40988',
        barcode: '84209',
        quantity: 237,
        price: 3826,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3867 для Мытищи, Красота, Доставка, Строй',
    },
    {
        id: 3868,
        artnumber: '64593',
        barcode: '70460',
        quantity: 492,
        price: 9921,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3868 для Мытищи, Красота, Доставка, Строй',
    },
    {
        id: 3869,
        artnumber: '57385',
        barcode: '39851',
        quantity: 351,
        price: 43544,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3869 для Мытищи, Красота, Доставка, Строй',
    },
    {
        id: 3870,
        artnumber: '65643',
        barcode: '19194',
        quantity: 47,
        price: 45743,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3870 для Мытищи, Красота, Доставка, Строй',
    },
    {
        id: 3871,
        artnumber: '88734',
        barcode: '10845',
        quantity: 249,
        price: 24333,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3871 для Мытищи, Красота, Доставка, Строй',
    },
    {
        id: 3872,
        artnumber: '62797',
        barcode: '20331',
        quantity: 282,
        price: 35783,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3872 для Мытищи, Красота, Доставка, Строй',
    },
    {
        id: 3873,
        artnumber: '54039',
        barcode: '32965',
        quantity: 27,
        price: 15568,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3873 для Мытищи, Красота, Доставка, Строй',
    },
    {
        id: 3874,
        artnumber: '45994',
        barcode: '72732',
        quantity: 339,
        price: 19264,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3874 для Мытищи, Красота, Доставка, Строй',
    },
    {
        id: 3875,
        artnumber: '30048',
        barcode: '32231',
        quantity: 84,
        price: 36335,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3875 для Мытищи, Красота, Доставка, Строй',
    },
    {
        id: 3876,
        artnumber: '22673',
        barcode: '15431',
        quantity: 53,
        price: 33233,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3876 для Мытищи, Красота, Доставка, Бета',
    },
    {
        id: 3877,
        artnumber: '53920',
        barcode: '40715',
        quantity: 465,
        price: 8103,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3877 для Мытищи, Красота, Доставка, Бета',
    },
    {
        id: 3878,
        artnumber: '64848',
        barcode: '63707',
        quantity: 82,
        price: 1402,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3878 для Мытищи, Красота, Доставка, Бета',
    },
    {
        id: 3879,
        artnumber: '33328',
        barcode: '54814',
        quantity: 44,
        price: 17080,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3879 для Мытищи, Красота, Доставка, Бета',
    },
    {
        id: 3880,
        artnumber: '25302',
        barcode: '39705',
        quantity: 188,
        price: 4634,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3880 для Мытищи, Красота, Доставка, Бета',
    },
    {
        id: 3881,
        artnumber: '87517',
        barcode: '73607',
        quantity: 428,
        price: 21437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3881 для Мытищи, Красота, Доставка, Бета',
    },
    {
        id: 3882,
        artnumber: '34438',
        barcode: '83683',
        quantity: 214,
        price: 13266,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3882 для Мытищи, Красота, Доставка, Бета',
    },
    {
        id: 3883,
        artnumber: '95964',
        barcode: '75333',
        quantity: 107,
        price: 40349,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3883 для Мытищи, Красота, Доставка, Бета',
    },
    {
        id: 3884,
        artnumber: '32704',
        barcode: '23439',
        quantity: 358,
        price: 11819,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3884 для Мытищи, Красота, Доставка, Бета',
    },
    {
        id: 3885,
        artnumber: '16911',
        barcode: '89325',
        quantity: 439,
        price: 32960,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3885 для Мытищи, Красота, Доставка, Бета',
    },
    {
        id: 3886,
        artnumber: '26684',
        barcode: '46147',
        quantity: 424,
        price: 47028,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3886 для Мытищи, Красота, Доставка, Гамма',
    },
    {
        id: 3887,
        artnumber: '24998',
        barcode: '29063',
        quantity: 283,
        price: 9125,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3887 для Мытищи, Красота, Доставка, Гамма',
    },
    {
        id: 3888,
        artnumber: '30250',
        barcode: '90584',
        quantity: 383,
        price: 11990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3888 для Мытищи, Красота, Доставка, Гамма',
    },
    {
        id: 3889,
        artnumber: '10105',
        barcode: '76790',
        quantity: 447,
        price: 28160,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3889 для Мытищи, Красота, Доставка, Гамма',
    },
    {
        id: 3890,
        artnumber: '75220',
        barcode: '54784',
        quantity: 133,
        price: 46181,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3890 для Мытищи, Красота, Доставка, Гамма',
    },
    {
        id: 3891,
        artnumber: '41958',
        barcode: '16811',
        quantity: 138,
        price: 22124,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3891 для Мытищи, Красота, Доставка, Гамма',
    },
    {
        id: 3892,
        artnumber: '83158',
        barcode: '61009',
        quantity: 71,
        price: 33041,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3892 для Мытищи, Красота, Доставка, Гамма',
    },
    {
        id: 3893,
        artnumber: '28583',
        barcode: '58200',
        quantity: 253,
        price: 37980,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3893 для Мытищи, Красота, Доставка, Гамма',
    },
    {
        id: 3894,
        artnumber: '37942',
        barcode: '75281',
        quantity: 375,
        price: 38203,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3894 для Мытищи, Красота, Доставка, Гамма',
    },
    {
        id: 3895,
        artnumber: '81804',
        barcode: '54786',
        quantity: 171,
        price: 17456,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3895 для Мытищи, Красота, Доставка, Гамма',
    },
    {
        id: 3896,
        artnumber: '23171',
        barcode: '68956',
        quantity: 142,
        price: 28531,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3896 для Мытищи, Красота, Доставка, Трио',
    },
    {
        id: 3897,
        artnumber: '49940',
        barcode: '12509',
        quantity: 2,
        price: 36210,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3897 для Мытищи, Красота, Доставка, Трио',
    },
    {
        id: 3898,
        artnumber: '31876',
        barcode: '13682',
        quantity: 39,
        price: 37188,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3898 для Мытищи, Красота, Доставка, Трио',
    },
    {
        id: 3899,
        artnumber: '56415',
        barcode: '72321',
        quantity: 408,
        price: 17975,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3899 для Мытищи, Красота, Доставка, Трио',
    },
    {
        id: 3900,
        artnumber: '35050',
        barcode: '79337',
        quantity: 60,
        price: 33734,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3900 для Мытищи, Красота, Доставка, Трио',
    },
    {
        id: 3901,
        artnumber: '51725',
        barcode: '18833',
        quantity: 375,
        price: 7138,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3901 для Мытищи, Красота, Доставка, Трио',
    },
    {
        id: 3902,
        artnumber: '70410',
        barcode: '69023',
        quantity: 480,
        price: 32176,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3902 для Мытищи, Красота, Доставка, Трио',
    },
    {
        id: 3903,
        artnumber: '38055',
        barcode: '69745',
        quantity: 20,
        price: 360,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3903 для Мытищи, Красота, Доставка, Трио',
    },
    {
        id: 3904,
        artnumber: '37724',
        barcode: '36817',
        quantity: 475,
        price: 18055,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3904 для Мытищи, Красота, Доставка, Трио',
    },
    {
        id: 3905,
        artnumber: '40481',
        barcode: '45265',
        quantity: 130,
        price: 25754,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3905 для Мытищи, Красота, Доставка, Трио',
    },
    {
        id: 3906,
        artnumber: '16449',
        barcode: '26498',
        quantity: 200,
        price: 31705,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3906 для Мытищи, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 3907,
        artnumber: '67878',
        barcode: '37950',
        quantity: 281,
        price: 48417,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3907 для Мытищи, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 3908,
        artnumber: '27639',
        barcode: '63831',
        quantity: 4,
        price: 42642,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3908 для Мытищи, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 3909,
        artnumber: '11774',
        barcode: '13123',
        quantity: 188,
        price: 45489,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3909 для Мытищи, Красота, Шиномонтаж, Строй',
    },
    {
        id: 3910,
        artnumber: '29983',
        barcode: '77474',
        quantity: 215,
        price: 10065,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3910 для Мытищи, Красота, Шиномонтаж, Строй',
    },
    {
        id: 3911,
        artnumber: '24337',
        barcode: '63202',
        quantity: 202,
        price: 14713,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3911 для Мытищи, Красота, Шиномонтаж, Строй',
    },
    {
        id: 3912,
        artnumber: '92329',
        barcode: '44047',
        quantity: 277,
        price: 39663,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3912 для Мытищи, Красота, Шиномонтаж, Бета',
    },
    {
        id: 3913,
        artnumber: '39870',
        barcode: '76449',
        quantity: 162,
        price: 10514,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3913 для Мытищи, Красота, Шиномонтаж, Бета',
    },
    {
        id: 3914,
        artnumber: '51424',
        barcode: '35771',
        quantity: 255,
        price: 46730,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3914 для Мытищи, Красота, Шиномонтаж, Бета',
    },
    {
        id: 3915,
        artnumber: '79942',
        barcode: '68907',
        quantity: 393,
        price: 805,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3915 для Мытищи, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 3916,
        artnumber: '50120',
        barcode: '24170',
        quantity: 190,
        price: 45817,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3916 для Мытищи, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 3917,
        artnumber: '50080',
        barcode: '42216',
        quantity: 409,
        price: 28228,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3917 для Мытищи, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 3918,
        artnumber: '53376',
        barcode: '78352',
        quantity: 22,
        price: 20026,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3918 для Мытищи, Красота, Шиномонтаж, Трио',
    },
    {
        id: 3919,
        artnumber: '48975',
        barcode: '76960',
        quantity: 445,
        price: 5459,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3919 для Мытищи, Красота, Шиномонтаж, Трио',
    },
    {
        id: 3920,
        artnumber: '32763',
        barcode: '38319',
        quantity: 470,
        price: 13135,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3920 для Мытищи, Красота, Шиномонтаж, Трио',
    },
    {
        id: 3921,
        artnumber: '46371',
        barcode: '54635',
        quantity: 418,
        price: 41494,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3921 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3922,
        artnumber: '28322',
        barcode: '68202',
        quantity: 237,
        price: 43452,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3922 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3923,
        artnumber: '48300',
        barcode: '74331',
        quantity: 347,
        price: 39968,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3923 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3924,
        artnumber: '72251',
        barcode: '20982',
        quantity: 444,
        price: 24111,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3924 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3925,
        artnumber: '65598',
        barcode: '78391',
        quantity: 200,
        price: 49796,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3925 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3926,
        artnumber: '75643',
        barcode: '93549',
        quantity: 195,
        price: 27485,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3926 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3927,
        artnumber: '50068',
        barcode: '81499',
        quantity: 313,
        price: 35014,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3927 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3928,
        artnumber: '57256',
        barcode: '13636',
        quantity: 188,
        price: 7868,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3928 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3929,
        artnumber: '74692',
        barcode: '27527',
        quantity: 398,
        price: 8444,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3929 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3930,
        artnumber: '86733',
        barcode: '11891',
        quantity: 123,
        price: 8768,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3930 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3931,
        artnumber: '17816',
        barcode: '45548',
        quantity: 476,
        price: 2659,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3931 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3932,
        artnumber: '66180',
        barcode: '72200',
        quantity: 189,
        price: 4405,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3932 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3933,
        artnumber: '97602',
        barcode: '17350',
        quantity: 262,
        price: 18513,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3933 для Мытищи, Красота, Замена масла, Альфа',
    },
    {
        id: 3934,
        artnumber: '48910',
        barcode: '25242',
        quantity: 498,
        price: 34317,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3934 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3935,
        artnumber: '52533',
        barcode: '69542',
        quantity: 177,
        price: 10238,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3935 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3936,
        artnumber: '74686',
        barcode: '39035',
        quantity: 388,
        price: 48610,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3936 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3937,
        artnumber: '27933',
        barcode: '21046',
        quantity: 420,
        price: 14802,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3937 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3938,
        artnumber: '13611',
        barcode: '26846',
        quantity: 392,
        price: 40385,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3938 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3939,
        artnumber: '48717',
        barcode: '93567',
        quantity: 161,
        price: 4905,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3939 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3940,
        artnumber: '29668',
        barcode: '51760',
        quantity: 443,
        price: 49767,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3940 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3941,
        artnumber: '74717',
        barcode: '66916',
        quantity: 442,
        price: 21740,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3941 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3942,
        artnumber: '82891',
        barcode: '34145',
        quantity: 426,
        price: 44338,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3942 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3943,
        artnumber: '61611',
        barcode: '51645',
        quantity: 371,
        price: 37918,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3943 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3944,
        artnumber: '38582',
        barcode: '65998',
        quantity: 111,
        price: 38260,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3944 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3945,
        artnumber: '89982',
        barcode: '71365',
        quantity: 45,
        price: 15080,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3945 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3946,
        artnumber: '20990',
        barcode: '10592',
        quantity: 289,
        price: 11102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3946 для Мытищи, Красота, Замена масла, Строй',
    },
    {
        id: 3947,
        artnumber: '91106',
        barcode: '93953',
        quantity: 144,
        price: 16077,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3947 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3948,
        artnumber: '78778',
        barcode: '38444',
        quantity: 38,
        price: 15821,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3948 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3949,
        artnumber: '11599',
        barcode: '79605',
        quantity: 366,
        price: 5377,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3949 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3950,
        artnumber: '34962',
        barcode: '49166',
        quantity: 77,
        price: 27581,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3950 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3951,
        artnumber: '92755',
        barcode: '16306',
        quantity: 120,
        price: 16848,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3951 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3952,
        artnumber: '46986',
        barcode: '83959',
        quantity: 494,
        price: 45635,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3952 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3953,
        artnumber: '52794',
        barcode: '34416',
        quantity: 266,
        price: 48056,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3953 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3954,
        artnumber: '47809',
        barcode: '15262',
        quantity: 157,
        price: 1381,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3954 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3955,
        artnumber: '69291',
        barcode: '37791',
        quantity: 405,
        price: 43535,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3955 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3956,
        artnumber: '97383',
        barcode: '22055',
        quantity: 84,
        price: 22322,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3956 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3957,
        artnumber: '50485',
        barcode: '64689',
        quantity: 378,
        price: 14202,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3957 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3958,
        artnumber: '35760',
        barcode: '50460',
        quantity: 62,
        price: 22284,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3958 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3959,
        artnumber: '21707',
        barcode: '30230',
        quantity: 207,
        price: 46122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3959 для Мытищи, Красота, Замена масла, Бета',
    },
    {
        id: 3960,
        artnumber: '60228',
        barcode: '54283',
        quantity: 473,
        price: 12862,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3960 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3961,
        artnumber: '97052',
        barcode: '40394',
        quantity: 171,
        price: 8587,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3961 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3962,
        artnumber: '18024',
        barcode: '66343',
        quantity: 175,
        price: 33945,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3962 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3963,
        artnumber: '26062',
        barcode: '83389',
        quantity: 223,
        price: 31930,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3963 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3964,
        artnumber: '55140',
        barcode: '23947',
        quantity: 60,
        price: 32229,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3964 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3965,
        artnumber: '64379',
        barcode: '48128',
        quantity: 278,
        price: 39225,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3965 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3966,
        artnumber: '18016',
        barcode: '15037',
        quantity: 448,
        price: 11041,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3966 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3967,
        artnumber: '60009',
        barcode: '22509',
        quantity: 346,
        price: 1000,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3967 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3968,
        artnumber: '43111',
        barcode: '31264',
        quantity: 50,
        price: 4562,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3968 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3969,
        artnumber: '20517',
        barcode: '84564',
        quantity: 494,
        price: 29805,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3969 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3970,
        artnumber: '75173',
        barcode: '40947',
        quantity: 267,
        price: 48652,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3970 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3971,
        artnumber: '66219',
        barcode: '34934',
        quantity: 202,
        price: 37311,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3971 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3972,
        artnumber: '53467',
        barcode: '46388',
        quantity: 202,
        price: 17909,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 3972 для Мытищи, Красота, Замена масла, Гамма',
    },
    {
        id: 3973,
        artnumber: '46331',
        barcode: '77950',
        quantity: 57,
        price: 7452,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3973 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3974,
        artnumber: '95708',
        barcode: '88419',
        quantity: 431,
        price: 40538,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3974 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3975,
        artnumber: '73430',
        barcode: '73155',
        quantity: 49,
        price: 40595,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3975 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3976,
        artnumber: '59936',
        barcode: '62863',
        quantity: 44,
        price: 31553,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3976 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3977,
        artnumber: '58876',
        barcode: '50054',
        quantity: 36,
        price: 5553,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3977 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3978,
        artnumber: '60891',
        barcode: '22830',
        quantity: 298,
        price: 23403,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3978 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3979,
        artnumber: '28761',
        barcode: '67687',
        quantity: 350,
        price: 19045,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3979 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3980,
        artnumber: '78060',
        barcode: '57501',
        quantity: 125,
        price: 11163,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3980 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3981,
        artnumber: '66298',
        barcode: '67579',
        quantity: 193,
        price: 20314,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3981 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3982,
        artnumber: '96797',
        barcode: '71992',
        quantity: 278,
        price: 22707,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3982 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3983,
        artnumber: '96379',
        barcode: '19240',
        quantity: 60,
        price: 2307,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3983 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3984,
        artnumber: '33154',
        barcode: '64749',
        quantity: 32,
        price: 23634,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3984 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3985,
        artnumber: '82372',
        barcode: '89712',
        quantity: 206,
        price: 43283,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 3985 для Мытищи, Красота, Замена масла, Трио',
    },
    {
        id: 3986,
        artnumber: '23185',
        barcode: '28642',
        quantity: 197,
        price: 47203,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3986 для Мытищи, Здоровье, Сервис, Альфа',
    },
    {
        id: 3987,
        artnumber: '23253',
        barcode: '89514',
        quantity: 183,
        price: 2993,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 3987 для Мытищи, Здоровье, Сервис, Альфа',
    },
    {
        id: 3988,
        artnumber: '82357',
        barcode: '59721',
        quantity: 330,
        price: 9683,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3988 для Мытищи, Здоровье, Сервис, Альфа',
    },
    {
        id: 3989,
        artnumber: '94271',
        barcode: '80515',
        quantity: 91,
        price: 47738,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3989 для Мытищи, Здоровье, Сервис, Альфа',
    },
    {
        id: 3990,
        artnumber: '96490',
        barcode: '12024',
        quantity: 303,
        price: 10694,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 3990 для Мытищи, Здоровье, Сервис, Строй',
    },
    {
        id: 3991,
        artnumber: '12461',
        barcode: '44549',
        quantity: 496,
        price: 9421,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 3991 для Мытищи, Здоровье, Сервис, Строй',
    },
    {
        id: 3992,
        artnumber: '69302',
        barcode: '47821',
        quantity: 257,
        price: 8969,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3992 для Мытищи, Здоровье, Сервис, Строй',
    },
    {
        id: 3993,
        artnumber: '13260',
        barcode: '94622',
        quantity: 69,
        price: 11406,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3993 для Мытищи, Здоровье, Сервис, Строй',
    },
    {
        id: 3994,
        artnumber: '15169',
        barcode: '95893',
        quantity: 97,
        price: 30713,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3994 для Мытищи, Здоровье, Сервис, Бета',
    },
    {
        id: 3995,
        artnumber: '26775',
        barcode: '54113',
        quantity: 404,
        price: 16437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 3995 для Мытищи, Здоровье, Сервис, Бета',
    },
    {
        id: 3996,
        artnumber: '65640',
        barcode: '26320',
        quantity: 290,
        price: 1448,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 3996 для Мытищи, Здоровье, Сервис, Бета',
    },
    {
        id: 3997,
        artnumber: '23934',
        barcode: '36037',
        quantity: 438,
        price: 49094,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3997 для Мытищи, Здоровье, Сервис, Бета',
    },
    {
        id: 3998,
        artnumber: '81579',
        barcode: '87142',
        quantity: 371,
        price: 45969,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 3998 для Мытищи, Здоровье, Сервис, Гамма',
    },
    {
        id: 3999,
        artnumber: '21753',
        barcode: '34430',
        quantity: 417,
        price: 27452,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 3999 для Мытищи, Здоровье, Сервис, Гамма',
    },
    {
        id: 4000,
        artnumber: '43359',
        barcode: '55032',
        quantity: 54,
        price: 16522,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4000 для Мытищи, Здоровье, Сервис, Гамма',
    },
    {
        id: 4001,
        artnumber: '40345',
        barcode: '29687',
        quantity: 478,
        price: 9630,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4001 для Мытищи, Здоровье, Сервис, Гамма',
    },
    {
        id: 4002,
        artnumber: '67620',
        barcode: '84704',
        quantity: 29,
        price: 48313,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4002 для Мытищи, Здоровье, Сервис, Трио',
    },
    {
        id: 4003,
        artnumber: '29217',
        barcode: '59893',
        quantity: 459,
        price: 48291,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4003 для Мытищи, Здоровье, Сервис, Трио',
    },
    {
        id: 4004,
        artnumber: '77365',
        barcode: '97383',
        quantity: 344,
        price: 6878,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4004 для Мытищи, Здоровье, Сервис, Трио',
    },
    {
        id: 4005,
        artnumber: '11752',
        barcode: '43921',
        quantity: 120,
        price: 47437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4005 для Мытищи, Здоровье, Сервис, Трио',
    },
    {
        id: 4006,
        artnumber: '89346',
        barcode: '87849',
        quantity: 105,
        price: 49340,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4006 для Мытищи, Здоровье, Запчасти, Альфа',
    },
    {
        id: 4007,
        artnumber: '88089',
        barcode: '14423',
        quantity: 121,
        price: 13459,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4007 для Мытищи, Здоровье, Запчасти, Альфа',
    },
    {
        id: 4008,
        artnumber: '56514',
        barcode: '13189',
        quantity: 373,
        price: 9895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4008 для Мытищи, Здоровье, Запчасти, Альфа',
    },
    {
        id: 4009,
        artnumber: '59099',
        barcode: '86162',
        quantity: 303,
        price: 41696,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4009 для Мытищи, Здоровье, Запчасти, Альфа',
    },
    {
        id: 4010,
        artnumber: '16640',
        barcode: '29900',
        quantity: 282,
        price: 20773,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4010 для Мытищи, Здоровье, Запчасти, Строй',
    },
    {
        id: 4011,
        artnumber: '12782',
        barcode: '54600',
        quantity: 402,
        price: 15196,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4011 для Мытищи, Здоровье, Запчасти, Строй',
    },
    {
        id: 4012,
        artnumber: '76278',
        barcode: '36158',
        quantity: 154,
        price: 19126,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4012 для Мытищи, Здоровье, Запчасти, Строй',
    },
    {
        id: 4013,
        artnumber: '91773',
        barcode: '86476',
        quantity: 3,
        price: 38078,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4013 для Мытищи, Здоровье, Запчасти, Строй',
    },
    {
        id: 4014,
        artnumber: '89181',
        barcode: '35709',
        quantity: 92,
        price: 18378,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4014 для Мытищи, Здоровье, Запчасти, Бета',
    },
    {
        id: 4015,
        artnumber: '77116',
        barcode: '97191',
        quantity: 103,
        price: 27881,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4015 для Мытищи, Здоровье, Запчасти, Бета',
    },
    {
        id: 4016,
        artnumber: '70118',
        barcode: '41316',
        quantity: 475,
        price: 12273,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4016 для Мытищи, Здоровье, Запчасти, Бета',
    },
    {
        id: 4017,
        artnumber: '70590',
        barcode: '78884',
        quantity: 303,
        price: 19047,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4017 для Мытищи, Здоровье, Запчасти, Бета',
    },
    {
        id: 4018,
        artnumber: '19199',
        barcode: '72039',
        quantity: 244,
        price: 11078,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4018 для Мытищи, Здоровье, Запчасти, Гамма',
    },
    {
        id: 4019,
        artnumber: '55580',
        barcode: '39929',
        quantity: 351,
        price: 42306,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4019 для Мытищи, Здоровье, Запчасти, Гамма',
    },
    {
        id: 4020,
        artnumber: '56201',
        barcode: '34556',
        quantity: 322,
        price: 20119,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4020 для Мытищи, Здоровье, Запчасти, Гамма',
    },
    {
        id: 4021,
        artnumber: '76386',
        barcode: '92567',
        quantity: 354,
        price: 45404,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4021 для Мытищи, Здоровье, Запчасти, Гамма',
    },
    {
        id: 4022,
        artnumber: '72648',
        barcode: '61887',
        quantity: 257,
        price: 29463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4022 для Мытищи, Здоровье, Запчасти, Трио',
    },
    {
        id: 4023,
        artnumber: '36518',
        barcode: '43809',
        quantity: 79,
        price: 44618,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4023 для Мытищи, Здоровье, Запчасти, Трио',
    },
    {
        id: 4024,
        artnumber: '94437',
        barcode: '18948',
        quantity: 400,
        price: 20628,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4024 для Мытищи, Здоровье, Запчасти, Трио',
    },
    {
        id: 4025,
        artnumber: '74023',
        barcode: '39231',
        quantity: 328,
        price: 2162,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4025 для Мытищи, Здоровье, Запчасти, Трио',
    },
    {
        id: 4026,
        artnumber: '48386',
        barcode: '91775',
        quantity: 393,
        price: 8249,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4026 для Мытищи, Здоровье, Доставка, Альфа',
    },
    {
        id: 4027,
        artnumber: '84019',
        barcode: '41351',
        quantity: 188,
        price: 49820,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4027 для Мытищи, Здоровье, Доставка, Альфа',
    },
    {
        id: 4028,
        artnumber: '89990',
        barcode: '32967',
        quantity: 306,
        price: 34737,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4028 для Мытищи, Здоровье, Доставка, Альфа',
    },
    {
        id: 4029,
        artnumber: '14797',
        barcode: '14064',
        quantity: 43,
        price: 11778,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4029 для Мытищи, Здоровье, Доставка, Строй',
    },
    {
        id: 4030,
        artnumber: '46482',
        barcode: '51621',
        quantity: 340,
        price: 12134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4030 для Мытищи, Здоровье, Доставка, Строй',
    },
    {
        id: 4031,
        artnumber: '88094',
        barcode: '43147',
        quantity: 262,
        price: 15686,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4031 для Мытищи, Здоровье, Доставка, Строй',
    },
    {
        id: 4032,
        artnumber: '64665',
        barcode: '17912',
        quantity: 424,
        price: 12764,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4032 для Мытищи, Здоровье, Доставка, Бета',
    },
    {
        id: 4033,
        artnumber: '27846',
        barcode: '10016',
        quantity: 9,
        price: 3264,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4033 для Мытищи, Здоровье, Доставка, Бета',
    },
    {
        id: 4034,
        artnumber: '72178',
        barcode: '57227',
        quantity: 500,
        price: 45585,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4034 для Мытищи, Здоровье, Доставка, Бета',
    },
    {
        id: 4035,
        artnumber: '70986',
        barcode: '64885',
        quantity: 291,
        price: 47229,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4035 для Мытищи, Здоровье, Доставка, Гамма',
    },
    {
        id: 4036,
        artnumber: '67404',
        barcode: '28770',
        quantity: 132,
        price: 1712,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4036 для Мытищи, Здоровье, Доставка, Гамма',
    },
    {
        id: 4037,
        artnumber: '59734',
        barcode: '66297',
        quantity: 330,
        price: 10609,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4037 для Мытищи, Здоровье, Доставка, Гамма',
    },
    {
        id: 4038,
        artnumber: '20439',
        barcode: '40546',
        quantity: 372,
        price: 13183,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4038 для Мытищи, Здоровье, Доставка, Трио',
    },
    {
        id: 4039,
        artnumber: '31635',
        barcode: '95942',
        quantity: 357,
        price: 4028,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4039 для Мытищи, Здоровье, Доставка, Трио',
    },
    {
        id: 4040,
        artnumber: '40673',
        barcode: '10008',
        quantity: 25,
        price: 42455,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4040 для Мытищи, Здоровье, Доставка, Трио',
    },
    {
        id: 4041,
        artnumber: '50112',
        barcode: '10811',
        quantity: 87,
        price: 25121,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4041 для Мытищи, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 4042,
        artnumber: '74670',
        barcode: '61998',
        quantity: 366,
        price: 48561,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4042 для Мытищи, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 4043,
        artnumber: '94759',
        barcode: '22535',
        quantity: 347,
        price: 2523,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4043 для Мытищи, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 4044,
        artnumber: '38035',
        barcode: '44665',
        quantity: 244,
        price: 48311,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4044 для Мытищи, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 4045,
        artnumber: '59191',
        barcode: '42611',
        quantity: 489,
        price: 3045,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4045 для Мытищи, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 4046,
        artnumber: '81771',
        barcode: '56703',
        quantity: 165,
        price: 9583,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4046 для Мытищи, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 4047,
        artnumber: '87327',
        barcode: '14586',
        quantity: 410,
        price: 4318,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4047 для Мытищи, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 4048,
        artnumber: '32264',
        barcode: '15560',
        quantity: 110,
        price: 4664,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4048 для Мытищи, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 4049,
        artnumber: '44652',
        barcode: '14120',
        quantity: 9,
        price: 22292,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4049 для Мытищи, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 4050,
        artnumber: '78446',
        barcode: '54032',
        quantity: 430,
        price: 15195,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4050 для Мытищи, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 4051,
        artnumber: '18002',
        barcode: '54251',
        quantity: 99,
        price: 18035,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4051 для Мытищи, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 4052,
        artnumber: '69321',
        barcode: '71732',
        quantity: 282,
        price: 474,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4052 для Мытищи, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 4053,
        artnumber: '11304',
        barcode: '28434',
        quantity: 166,
        price: 44001,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4053 для Мытищи, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 4054,
        artnumber: '17236',
        barcode: '36278',
        quantity: 434,
        price: 23057,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4054 для Мытищи, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 4055,
        artnumber: '25566',
        barcode: '29610',
        quantity: 192,
        price: 27522,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4055 для Мытищи, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 4056,
        artnumber: '38878',
        barcode: '13690',
        quantity: 438,
        price: 44770,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4056 для Мытищи, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 4057,
        artnumber: '14680',
        barcode: '51903',
        quantity: 296,
        price: 42010,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4057 для Мытищи, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 4058,
        artnumber: '67705',
        barcode: '52061',
        quantity: 443,
        price: 19534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4058 для Мытищи, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 4059,
        artnumber: '25366',
        barcode: '35878',
        quantity: 293,
        price: 26232,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4059 для Мытищи, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 4060,
        artnumber: '27481',
        barcode: '85574',
        quantity: 390,
        price: 29287,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4060 для Мытищи, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 4061,
        artnumber: '55809',
        barcode: '49246',
        quantity: 191,
        price: 45679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4061 для Мытищи, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 4062,
        artnumber: '38764',
        barcode: '24678',
        quantity: 164,
        price: 13699,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4062 для Мытищи, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 4063,
        artnumber: '88532',
        barcode: '42616',
        quantity: 178,
        price: 20608,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4063 для Мытищи, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 4064,
        artnumber: '58503',
        barcode: '76039',
        quantity: 345,
        price: 30824,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4064 для Мытищи, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 4065,
        artnumber: '34991',
        barcode: '84811',
        quantity: 256,
        price: 43280,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4065 для Мытищи, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 4066,
        artnumber: '22356',
        barcode: '22818',
        quantity: 359,
        price: 46130,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4066 для Мытищи, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 4067,
        artnumber: '49454',
        barcode: '72168',
        quantity: 482,
        price: 6640,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4067 для Мытищи, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 4068,
        artnumber: '13376',
        barcode: '89549',
        quantity: 440,
        price: 46768,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4068 для Мытищи, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 4069,
        artnumber: '70349',
        barcode: '33185',
        quantity: 390,
        price: 45726,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4069 для Мытищи, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 4070,
        artnumber: '61854',
        barcode: '37101',
        quantity: 119,
        price: 34179,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4070 для Мытищи, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 4071,
        artnumber: '11314',
        barcode: '40009',
        quantity: 211,
        price: 5197,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4071 для Мытищи, Здоровье, Замена масла, Альфа',
    },
    {
        id: 4072,
        artnumber: '25147',
        barcode: '65801',
        quantity: 341,
        price: 31449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4072 для Мытищи, Здоровье, Замена масла, Альфа',
    },
    {
        id: 4073,
        artnumber: '38143',
        barcode: '28221',
        quantity: 180,
        price: 47103,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4073 для Мытищи, Здоровье, Замена масла, Альфа',
    },
    {
        id: 4074,
        artnumber: '82232',
        barcode: '37660',
        quantity: 286,
        price: 26608,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4074 для Мытищи, Здоровье, Замена масла, Строй',
    },
    {
        id: 4075,
        artnumber: '42510',
        barcode: '77888',
        quantity: 239,
        price: 22601,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4075 для Мытищи, Здоровье, Замена масла, Строй',
    },
    {
        id: 4076,
        artnumber: '79273',
        barcode: '78707',
        quantity: 200,
        price: 29586,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4076 для Мытищи, Здоровье, Замена масла, Строй',
    },
    {
        id: 4077,
        artnumber: '13673',
        barcode: '19183',
        quantity: 262,
        price: 17328,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4077 для Мытищи, Здоровье, Замена масла, Бета',
    },
    {
        id: 4078,
        artnumber: '17897',
        barcode: '88852',
        quantity: 129,
        price: 24803,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4078 для Мытищи, Здоровье, Замена масла, Бета',
    },
    {
        id: 4079,
        artnumber: '62677',
        barcode: '40955',
        quantity: 342,
        price: 26334,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4079 для Мытищи, Здоровье, Замена масла, Бета',
    },
    {
        id: 4080,
        artnumber: '59611',
        barcode: '86719',
        quantity: 478,
        price: 24229,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4080 для Мытищи, Здоровье, Замена масла, Гамма',
    },
    {
        id: 4081,
        artnumber: '40286',
        barcode: '81842',
        quantity: 177,
        price: 33482,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4081 для Мытищи, Здоровье, Замена масла, Гамма',
    },
    {
        id: 4082,
        artnumber: '47921',
        barcode: '41875',
        quantity: 451,
        price: 6438,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4082 для Мытищи, Здоровье, Замена масла, Гамма',
    },
    {
        id: 4083,
        artnumber: '76605',
        barcode: '91773',
        quantity: 402,
        price: 11972,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4083 для Мытищи, Здоровье, Замена масла, Трио',
    },
    {
        id: 4084,
        artnumber: '50937',
        barcode: '64093',
        quantity: 468,
        price: 6470,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4084 для Мытищи, Здоровье, Замена масла, Трио',
    },
    {
        id: 4085,
        artnumber: '16669',
        barcode: '37338',
        quantity: 98,
        price: 11644,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4085 для Мытищи, Здоровье, Замена масла, Трио',
    },
    {
        id: 4086,
        artnumber: '49124',
        barcode: '68219',
        quantity: 194,
        price: 5028,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4086 для Мытищи, Техника, Сервис, Альфа',
    },
    {
        id: 4087,
        artnumber: '23707',
        barcode: '67627',
        quantity: 323,
        price: 3721,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4087 для Мытищи, Техника, Сервис, Альфа',
    },
    {
        id: 4088,
        artnumber: '34946',
        barcode: '90633',
        quantity: 241,
        price: 29495,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4088 для Мытищи, Техника, Сервис, Альфа',
    },
    {
        id: 4089,
        artnumber: '85616',
        barcode: '53792',
        quantity: 301,
        price: 10767,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4089 для Мытищи, Техника, Сервис, Альфа',
    },
    {
        id: 4090,
        artnumber: '43219',
        barcode: '42918',
        quantity: 235,
        price: 14773,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4090 для Мытищи, Техника, Сервис, Строй',
    },
    {
        id: 4091,
        artnumber: '70252',
        barcode: '21798',
        quantity: 190,
        price: 15727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4091 для Мытищи, Техника, Сервис, Строй',
    },
    {
        id: 4092,
        artnumber: '73718',
        barcode: '95341',
        quantity: 332,
        price: 40358,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4092 для Мытищи, Техника, Сервис, Строй',
    },
    {
        id: 4093,
        artnumber: '51919',
        barcode: '19910',
        quantity: 447,
        price: 6147,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4093 для Мытищи, Техника, Сервис, Строй',
    },
    {
        id: 4094,
        artnumber: '86930',
        barcode: '79836',
        quantity: 339,
        price: 22852,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4094 для Мытищи, Техника, Сервис, Бета',
    },
    {
        id: 4095,
        artnumber: '29788',
        barcode: '75999',
        quantity: 234,
        price: 26553,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4095 для Мытищи, Техника, Сервис, Бета',
    },
    {
        id: 4096,
        artnumber: '97453',
        barcode: '52363',
        quantity: 465,
        price: 22531,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4096 для Мытищи, Техника, Сервис, Бета',
    },
    {
        id: 4097,
        artnumber: '68347',
        barcode: '95150',
        quantity: 347,
        price: 23040,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4097 для Мытищи, Техника, Сервис, Бета',
    },
    {
        id: 4098,
        artnumber: '19686',
        barcode: '40354',
        quantity: 245,
        price: 33293,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4098 для Мытищи, Техника, Сервис, Гамма',
    },
    {
        id: 4099,
        artnumber: '31187',
        barcode: '43260',
        quantity: 406,
        price: 16559,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4099 для Мытищи, Техника, Сервис, Гамма',
    },
    {
        id: 4100,
        artnumber: '20880',
        barcode: '83380',
        quantity: 219,
        price: 42954,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4100 для Мытищи, Техника, Сервис, Гамма',
    },
    {
        id: 4101,
        artnumber: '79414',
        barcode: '77020',
        quantity: 209,
        price: 19562,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4101 для Мытищи, Техника, Сервис, Гамма',
    },
    {
        id: 4102,
        artnumber: '53880',
        barcode: '56196',
        quantity: 301,
        price: 7518,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4102 для Мытищи, Техника, Сервис, Трио',
    },
    {
        id: 4103,
        artnumber: '73969',
        barcode: '64487',
        quantity: 487,
        price: 19699,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4103 для Мытищи, Техника, Сервис, Трио',
    },
    {
        id: 4104,
        artnumber: '96173',
        barcode: '54499',
        quantity: 186,
        price: 8172,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4104 для Мытищи, Техника, Сервис, Трио',
    },
    {
        id: 4105,
        artnumber: '87128',
        barcode: '33495',
        quantity: 44,
        price: 47012,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4105 для Мытищи, Техника, Сервис, Трио',
    },
    {
        id: 4106,
        artnumber: '18738',
        barcode: '42100',
        quantity: 484,
        price: 24678,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4106 для Мытищи, Техника, Запчасти, Альфа',
    },
    {
        id: 4107,
        artnumber: '36725',
        barcode: '80204',
        quantity: 41,
        price: 44230,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4107 для Мытищи, Техника, Запчасти, Альфа',
    },
    {
        id: 4108,
        artnumber: '79062',
        barcode: '29451',
        quantity: 391,
        price: 30007,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4108 для Мытищи, Техника, Запчасти, Альфа',
    },
    {
        id: 4109,
        artnumber: '47590',
        barcode: '14467',
        quantity: 388,
        price: 23493,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4109 для Мытищи, Техника, Запчасти, Альфа',
    },
    {
        id: 4110,
        artnumber: '25443',
        barcode: '29101',
        quantity: 185,
        price: 44034,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4110 для Мытищи, Техника, Запчасти, Альфа',
    },
    {
        id: 4111,
        artnumber: '89610',
        barcode: '42587',
        quantity: 262,
        price: 22388,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4111 для Мытищи, Техника, Запчасти, Альфа',
    },
    {
        id: 4112,
        artnumber: '60411',
        barcode: '81962',
        quantity: 131,
        price: 10416,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4112 для Мытищи, Техника, Запчасти, Строй',
    },
    {
        id: 4113,
        artnumber: '31877',
        barcode: '47537',
        quantity: 226,
        price: 44211,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4113 для Мытищи, Техника, Запчасти, Строй',
    },
    {
        id: 4114,
        artnumber: '40254',
        barcode: '53420',
        quantity: 457,
        price: 37146,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4114 для Мытищи, Техника, Запчасти, Строй',
    },
    {
        id: 4115,
        artnumber: '49530',
        barcode: '46521',
        quantity: 448,
        price: 18327,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4115 для Мытищи, Техника, Запчасти, Строй',
    },
    {
        id: 4116,
        artnumber: '29327',
        barcode: '78920',
        quantity: 32,
        price: 8187,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4116 для Мытищи, Техника, Запчасти, Строй',
    },
    {
        id: 4117,
        artnumber: '76423',
        barcode: '74280',
        quantity: 314,
        price: 38104,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4117 для Мытищи, Техника, Запчасти, Строй',
    },
    {
        id: 4118,
        artnumber: '32825',
        barcode: '32814',
        quantity: 399,
        price: 1880,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4118 для Мытищи, Техника, Запчасти, Бета',
    },
    {
        id: 4119,
        artnumber: '19532',
        barcode: '24241',
        quantity: 424,
        price: 7747,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4119 для Мытищи, Техника, Запчасти, Бета',
    },
    {
        id: 4120,
        artnumber: '41176',
        barcode: '13259',
        quantity: 492,
        price: 28273,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4120 для Мытищи, Техника, Запчасти, Бета',
    },
    {
        id: 4121,
        artnumber: '68742',
        barcode: '91526',
        quantity: 141,
        price: 28366,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4121 для Мытищи, Техника, Запчасти, Бета',
    },
    {
        id: 4122,
        artnumber: '79377',
        barcode: '85612',
        quantity: 171,
        price: 29677,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4122 для Мытищи, Техника, Запчасти, Бета',
    },
    {
        id: 4123,
        artnumber: '82326',
        barcode: '85915',
        quantity: 22,
        price: 32609,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4123 для Мытищи, Техника, Запчасти, Бета',
    },
    {
        id: 4124,
        artnumber: '92701',
        barcode: '74371',
        quantity: 290,
        price: 7202,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4124 для Мытищи, Техника, Запчасти, Гамма',
    },
    {
        id: 4125,
        artnumber: '80461',
        barcode: '45032',
        quantity: 379,
        price: 37722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4125 для Мытищи, Техника, Запчасти, Гамма',
    },
    {
        id: 4126,
        artnumber: '80521',
        barcode: '54963',
        quantity: 246,
        price: 21496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4126 для Мытищи, Техника, Запчасти, Гамма',
    },
    {
        id: 4127,
        artnumber: '86947',
        barcode: '10378',
        quantity: 443,
        price: 11632,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4127 для Мытищи, Техника, Запчасти, Гамма',
    },
    {
        id: 4128,
        artnumber: '46216',
        barcode: '93705',
        quantity: 465,
        price: 42366,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4128 для Мытищи, Техника, Запчасти, Гамма',
    },
    {
        id: 4129,
        artnumber: '86061',
        barcode: '45656',
        quantity: 308,
        price: 13081,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4129 для Мытищи, Техника, Запчасти, Гамма',
    },
    {
        id: 4130,
        artnumber: '27753',
        barcode: '95515',
        quantity: 187,
        price: 34589,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4130 для Мытищи, Техника, Запчасти, Трио',
    },
    {
        id: 4131,
        artnumber: '61375',
        barcode: '69701',
        quantity: 252,
        price: 4316,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4131 для Мытищи, Техника, Запчасти, Трио',
    },
    {
        id: 4132,
        artnumber: '31336',
        barcode: '16436',
        quantity: 59,
        price: 5750,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4132 для Мытищи, Техника, Запчасти, Трио',
    },
    {
        id: 4133,
        artnumber: '80726',
        barcode: '75395',
        quantity: 316,
        price: 40104,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4133 для Мытищи, Техника, Запчасти, Трио',
    },
    {
        id: 4134,
        artnumber: '27743',
        barcode: '37989',
        quantity: 277,
        price: 45911,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4134 для Мытищи, Техника, Запчасти, Трио',
    },
    {
        id: 4135,
        artnumber: '70419',
        barcode: '60959',
        quantity: 458,
        price: 19859,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4135 для Мытищи, Техника, Запчасти, Трио',
    },
    {
        id: 4136,
        artnumber: '26264',
        barcode: '89903',
        quantity: 17,
        price: 25211,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4136 для Мытищи, Техника, Доставка, Альфа',
    },
    {
        id: 4137,
        artnumber: '63871',
        barcode: '56642',
        quantity: 178,
        price: 5309,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4137 для Мытищи, Техника, Доставка, Альфа',
    },
    {
        id: 4138,
        artnumber: '14381',
        barcode: '32164',
        quantity: 169,
        price: 9864,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4138 для Мытищи, Техника, Доставка, Альфа',
    },
    {
        id: 4139,
        artnumber: '83830',
        barcode: '65375',
        quantity: 461,
        price: 32635,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4139 для Мытищи, Техника, Доставка, Альфа',
    },
    {
        id: 4140,
        artnumber: '54178',
        barcode: '34658',
        quantity: 162,
        price: 48816,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4140 для Мытищи, Техника, Доставка, Альфа',
    },
    {
        id: 4141,
        artnumber: '72713',
        barcode: '15699',
        quantity: 266,
        price: 10837,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4141 для Мытищи, Техника, Доставка, Альфа',
    },
    {
        id: 4142,
        artnumber: '74587',
        barcode: '84735',
        quantity: 15,
        price: 41908,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4142 для Мытищи, Техника, Доставка, Альфа',
    },
    {
        id: 4143,
        artnumber: '82242',
        barcode: '81603',
        quantity: 222,
        price: 31183,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4143 для Мытищи, Техника, Доставка, Альфа',
    },
    {
        id: 4144,
        artnumber: '82949',
        barcode: '31672',
        quantity: 255,
        price: 18384,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4144 для Мытищи, Техника, Доставка, Альфа',
    },
    {
        id: 4145,
        artnumber: '69017',
        barcode: '43326',
        quantity: 203,
        price: 46776,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4145 для Мытищи, Техника, Доставка, Альфа',
    },
    {
        id: 4146,
        artnumber: '48282',
        barcode: '11014',
        quantity: 242,
        price: 11529,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4146 для Мытищи, Техника, Доставка, Строй',
    },
    {
        id: 4147,
        artnumber: '97523',
        barcode: '69265',
        quantity: 471,
        price: 22089,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4147 для Мытищи, Техника, Доставка, Строй',
    },
    {
        id: 4148,
        artnumber: '46768',
        barcode: '86826',
        quantity: 321,
        price: 2402,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4148 для Мытищи, Техника, Доставка, Строй',
    },
    {
        id: 4149,
        artnumber: '17653',
        barcode: '94485',
        quantity: 199,
        price: 26689,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4149 для Мытищи, Техника, Доставка, Строй',
    },
    {
        id: 4150,
        artnumber: '43172',
        barcode: '84761',
        quantity: 60,
        price: 27708,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4150 для Мытищи, Техника, Доставка, Строй',
    },
    {
        id: 4151,
        artnumber: '13262',
        barcode: '57013',
        quantity: 4,
        price: 34788,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4151 для Мытищи, Техника, Доставка, Строй',
    },
    {
        id: 4152,
        artnumber: '31932',
        barcode: '57940',
        quantity: 395,
        price: 16669,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4152 для Мытищи, Техника, Доставка, Строй',
    },
    {
        id: 4153,
        artnumber: '53291',
        barcode: '94239',
        quantity: 46,
        price: 22513,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4153 для Мытищи, Техника, Доставка, Строй',
    },
    {
        id: 4154,
        artnumber: '36942',
        barcode: '16046',
        quantity: 397,
        price: 34683,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4154 для Мытищи, Техника, Доставка, Строй',
    },
    {
        id: 4155,
        artnumber: '23597',
        barcode: '74522',
        quantity: 20,
        price: 37887,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4155 для Мытищи, Техника, Доставка, Строй',
    },
    {
        id: 4156,
        artnumber: '78149',
        barcode: '84937',
        quantity: 376,
        price: 16694,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4156 для Мытищи, Техника, Доставка, Бета',
    },
    {
        id: 4157,
        artnumber: '78307',
        barcode: '55425',
        quantity: 6,
        price: 41616,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4157 для Мытищи, Техника, Доставка, Бета',
    },
    {
        id: 4158,
        artnumber: '69168',
        barcode: '98196',
        quantity: 312,
        price: 44883,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4158 для Мытищи, Техника, Доставка, Бета',
    },
    {
        id: 4159,
        artnumber: '80021',
        barcode: '57359',
        quantity: 304,
        price: 17385,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4159 для Мытищи, Техника, Доставка, Бета',
    },
    {
        id: 4160,
        artnumber: '42788',
        barcode: '98538',
        quantity: 236,
        price: 29438,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4160 для Мытищи, Техника, Доставка, Бета',
    },
    {
        id: 4161,
        artnumber: '52833',
        barcode: '90912',
        quantity: 488,
        price: 38391,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4161 для Мытищи, Техника, Доставка, Бета',
    },
    {
        id: 4162,
        artnumber: '60760',
        barcode: '70548',
        quantity: 468,
        price: 48399,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4162 для Мытищи, Техника, Доставка, Бета',
    },
    {
        id: 4163,
        artnumber: '67389',
        barcode: '65149',
        quantity: 318,
        price: 22458,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4163 для Мытищи, Техника, Доставка, Бета',
    },
    {
        id: 4164,
        artnumber: '26017',
        barcode: '67310',
        quantity: 415,
        price: 46100,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4164 для Мытищи, Техника, Доставка, Бета',
    },
    {
        id: 4165,
        artnumber: '31823',
        barcode: '37416',
        quantity: 134,
        price: 42088,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4165 для Мытищи, Техника, Доставка, Бета',
    },
    {
        id: 4166,
        artnumber: '31967',
        barcode: '39145',
        quantity: 473,
        price: 383,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4166 для Мытищи, Техника, Доставка, Гамма',
    },
    {
        id: 4167,
        artnumber: '35516',
        barcode: '92310',
        quantity: 229,
        price: 49366,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4167 для Мытищи, Техника, Доставка, Гамма',
    },
    {
        id: 4168,
        artnumber: '47149',
        barcode: '14046',
        quantity: 36,
        price: 46220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4168 для Мытищи, Техника, Доставка, Гамма',
    },
    {
        id: 4169,
        artnumber: '21700',
        barcode: '31957',
        quantity: 445,
        price: 37914,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4169 для Мытищи, Техника, Доставка, Гамма',
    },
    {
        id: 4170,
        artnumber: '52715',
        barcode: '61518',
        quantity: 421,
        price: 28223,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4170 для Мытищи, Техника, Доставка, Гамма',
    },
    {
        id: 4171,
        artnumber: '83137',
        barcode: '92863',
        quantity: 146,
        price: 1906,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4171 для Мытищи, Техника, Доставка, Гамма',
    },
    {
        id: 4172,
        artnumber: '87175',
        barcode: '30295',
        quantity: 177,
        price: 23852,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4172 для Мытищи, Техника, Доставка, Гамма',
    },
    {
        id: 4173,
        artnumber: '39720',
        barcode: '42568',
        quantity: 469,
        price: 27767,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4173 для Мытищи, Техника, Доставка, Гамма',
    },
    {
        id: 4174,
        artnumber: '82848',
        barcode: '26276',
        quantity: 170,
        price: 16960,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4174 для Мытищи, Техника, Доставка, Гамма',
    },
    {
        id: 4175,
        artnumber: '59118',
        barcode: '10002',
        quantity: 281,
        price: 17064,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4175 для Мытищи, Техника, Доставка, Гамма',
    },
    {
        id: 4176,
        artnumber: '43617',
        barcode: '93237',
        quantity: 410,
        price: 12591,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4176 для Мытищи, Техника, Доставка, Трио',
    },
    {
        id: 4177,
        artnumber: '13609',
        barcode: '67134',
        quantity: 42,
        price: 34569,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4177 для Мытищи, Техника, Доставка, Трио',
    },
    {
        id: 4178,
        artnumber: '23394',
        barcode: '61729',
        quantity: 440,
        price: 40658,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4178 для Мытищи, Техника, Доставка, Трио',
    },
    {
        id: 4179,
        artnumber: '55127',
        barcode: '89752',
        quantity: 53,
        price: 30457,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4179 для Мытищи, Техника, Доставка, Трио',
    },
    {
        id: 4180,
        artnumber: '40591',
        barcode: '84936',
        quantity: 255,
        price: 21337,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4180 для Мытищи, Техника, Доставка, Трио',
    },
    {
        id: 4181,
        artnumber: '68652',
        barcode: '68251',
        quantity: 228,
        price: 49608,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4181 для Мытищи, Техника, Доставка, Трио',
    },
    {
        id: 4182,
        artnumber: '60252',
        barcode: '30777',
        quantity: 382,
        price: 29128,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4182 для Мытищи, Техника, Доставка, Трио',
    },
    {
        id: 4183,
        artnumber: '23569',
        barcode: '18664',
        quantity: 242,
        price: 41036,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4183 для Мытищи, Техника, Доставка, Трио',
    },
    {
        id: 4184,
        artnumber: '98266',
        barcode: '21243',
        quantity: 414,
        price: 22091,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4184 для Мытищи, Техника, Доставка, Трио',
    },
    {
        id: 4185,
        artnumber: '64353',
        barcode: '36838',
        quantity: 465,
        price: 15511,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4185 для Мытищи, Техника, Доставка, Трио',
    },
    {
        id: 4186,
        artnumber: '40606',
        barcode: '27829',
        quantity: 234,
        price: 11393,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4186 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4187,
        artnumber: '95222',
        barcode: '36356',
        quantity: 246,
        price: 24084,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4187 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4188,
        artnumber: '65996',
        barcode: '37452',
        quantity: 151,
        price: 43382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4188 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4189,
        artnumber: '90609',
        barcode: '79368',
        quantity: 139,
        price: 22366,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4189 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4190,
        artnumber: '33174',
        barcode: '21083',
        quantity: 391,
        price: 6317,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4190 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4191,
        artnumber: '16536',
        barcode: '27402',
        quantity: 35,
        price: 40293,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4191 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4192,
        artnumber: '79083',
        barcode: '87149',
        quantity: 477,
        price: 13545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4192 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4193,
        artnumber: '74725',
        barcode: '65562',
        quantity: 458,
        price: 9766,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4193 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4194,
        artnumber: '10565',
        barcode: '17607',
        quantity: 77,
        price: 28021,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4194 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4195,
        artnumber: '65680',
        barcode: '45862',
        quantity: 135,
        price: 20375,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4195 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4196,
        artnumber: '43834',
        barcode: '67861',
        quantity: 123,
        price: 34078,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4196 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4197,
        artnumber: '47220',
        barcode: '47159',
        quantity: 176,
        price: 10241,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4197 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4198,
        artnumber: '41304',
        barcode: '56804',
        quantity: 358,
        price: 557,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4198 для Мытищи, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 4199,
        artnumber: '87082',
        barcode: '63588',
        quantity: 87,
        price: 5506,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4199 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4200,
        artnumber: '59787',
        barcode: '23187',
        quantity: 480,
        price: 44362,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4200 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4201,
        artnumber: '61981',
        barcode: '24095',
        quantity: 365,
        price: 15999,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4201 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4202,
        artnumber: '14570',
        barcode: '84733',
        quantity: 167,
        price: 14804,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4202 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4203,
        artnumber: '48508',
        barcode: '19736',
        quantity: 95,
        price: 6443,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4203 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4204,
        artnumber: '66574',
        barcode: '35828',
        quantity: 228,
        price: 46915,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4204 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4205,
        artnumber: '52634',
        barcode: '26011',
        quantity: 271,
        price: 7582,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4205 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4206,
        artnumber: '58942',
        barcode: '34196',
        quantity: 147,
        price: 38404,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4206 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4207,
        artnumber: '62480',
        barcode: '88635',
        quantity: 341,
        price: 4289,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4207 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4208,
        artnumber: '29064',
        barcode: '20203',
        quantity: 153,
        price: 22604,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4208 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4209,
        artnumber: '91895',
        barcode: '17687',
        quantity: 165,
        price: 45130,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4209 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4210,
        artnumber: '85574',
        barcode: '70543',
        quantity: 286,
        price: 18395,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4210 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4211,
        artnumber: '31548',
        barcode: '64455',
        quantity: 51,
        price: 6265,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4211 для Мытищи, Техника, Шиномонтаж, Строй',
    },
    {
        id: 4212,
        artnumber: '99718',
        barcode: '58948',
        quantity: 193,
        price: 31833,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4212 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4213,
        artnumber: '10388',
        barcode: '63214',
        quantity: 1,
        price: 13254,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4213 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4214,
        artnumber: '64303',
        barcode: '67844',
        quantity: 141,
        price: 42290,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4214 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4215,
        artnumber: '10337',
        barcode: '14100',
        quantity: 61,
        price: 29656,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4215 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4216,
        artnumber: '83424',
        barcode: '12207',
        quantity: 208,
        price: 29521,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4216 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4217,
        artnumber: '49341',
        barcode: '44343',
        quantity: 21,
        price: 12552,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4217 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4218,
        artnumber: '77286',
        barcode: '15474',
        quantity: 225,
        price: 40570,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4218 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4219,
        artnumber: '44116',
        barcode: '55350',
        quantity: 150,
        price: 48254,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4219 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4220,
        artnumber: '66896',
        barcode: '41041',
        quantity: 425,
        price: 3069,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4220 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4221,
        artnumber: '15735',
        barcode: '39114',
        quantity: 401,
        price: 33022,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4221 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4222,
        artnumber: '43311',
        barcode: '14432',
        quantity: 53,
        price: 34283,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4222 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4223,
        artnumber: '13705',
        barcode: '43815',
        quantity: 37,
        price: 38491,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4223 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4224,
        artnumber: '57559',
        barcode: '97966',
        quantity: 467,
        price: 22857,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4224 для Мытищи, Техника, Шиномонтаж, Бета',
    },
    {
        id: 4225,
        artnumber: '25358',
        barcode: '56645',
        quantity: 188,
        price: 1805,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4225 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4226,
        artnumber: '14494',
        barcode: '49508',
        quantity: 252,
        price: 49659,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4226 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4227,
        artnumber: '55835',
        barcode: '63869',
        quantity: 441,
        price: 4378,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4227 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4228,
        artnumber: '46861',
        barcode: '65361',
        quantity: 80,
        price: 36370,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4228 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4229,
        artnumber: '33193',
        barcode: '60314',
        quantity: 444,
        price: 331,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4229 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4230,
        artnumber: '25370',
        barcode: '18358',
        quantity: 431,
        price: 1298,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4230 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4231,
        artnumber: '25242',
        barcode: '62338',
        quantity: 371,
        price: 13212,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4231 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4232,
        artnumber: '14679',
        barcode: '54599',
        quantity: 414,
        price: 16382,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4232 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4233,
        artnumber: '95355',
        barcode: '14362',
        quantity: 445,
        price: 10821,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4233 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4234,
        artnumber: '82533',
        barcode: '87116',
        quantity: 42,
        price: 22309,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4234 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4235,
        artnumber: '29674',
        barcode: '97530',
        quantity: 153,
        price: 3516,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4235 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4236,
        artnumber: '57966',
        barcode: '68293',
        quantity: 145,
        price: 22649,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4236 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4237,
        artnumber: '77887',
        barcode: '57915',
        quantity: 202,
        price: 28115,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4237 для Мытищи, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 4238,
        artnumber: '27557',
        barcode: '81774',
        quantity: 383,
        price: 18853,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4238 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4239,
        artnumber: '74105',
        barcode: '41687',
        quantity: 351,
        price: 26990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4239 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4240,
        artnumber: '29465',
        barcode: '65624',
        quantity: 243,
        price: 26449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4240 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4241,
        artnumber: '73455',
        barcode: '56244',
        quantity: 321,
        price: 28059,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4241 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4242,
        artnumber: '42797',
        barcode: '23455',
        quantity: 146,
        price: 3475,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4242 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4243,
        artnumber: '88951',
        barcode: '73065',
        quantity: 287,
        price: 42687,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4243 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4244,
        artnumber: '51591',
        barcode: '11637',
        quantity: 89,
        price: 14602,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4244 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4245,
        artnumber: '71590',
        barcode: '35974',
        quantity: 211,
        price: 44354,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4245 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4246,
        artnumber: '19022',
        barcode: '96513',
        quantity: 464,
        price: 7507,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4246 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4247,
        artnumber: '94192',
        barcode: '41456',
        quantity: 402,
        price: 24496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4247 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4248,
        artnumber: '71975',
        barcode: '95145',
        quantity: 191,
        price: 7444,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4248 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4249,
        artnumber: '78671',
        barcode: '24955',
        quantity: 173,
        price: 23827,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4249 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4250,
        artnumber: '58852',
        barcode: '20411',
        quantity: 28,
        price: 37156,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4250 для Мытищи, Техника, Шиномонтаж, Трио',
    },
    {
        id: 4251,
        artnumber: '30120',
        barcode: '10403',
        quantity: 388,
        price: 42402,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4251 для Мытищи, Техника, Замена масла, Альфа',
    },
    {
        id: 4252,
        artnumber: '40325',
        barcode: '69429',
        quantity: 123,
        price: 41847,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4252 для Мытищи, Техника, Замена масла, Альфа',
    },
    {
        id: 4253,
        artnumber: '95184',
        barcode: '82547',
        quantity: 190,
        price: 43812,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4253 для Мытищи, Техника, Замена масла, Альфа',
    },
    {
        id: 4254,
        artnumber: '31602',
        barcode: '32910',
        quantity: 441,
        price: 36630,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4254 для Мытищи, Техника, Замена масла, Альфа',
    },
    {
        id: 4255,
        artnumber: '21227',
        barcode: '97404',
        quantity: 303,
        price: 13222,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4255 для Мытищи, Техника, Замена масла, Альфа',
    },
    {
        id: 4256,
        artnumber: '55030',
        barcode: '24094',
        quantity: 244,
        price: 3883,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4256 для Мытищи, Техника, Замена масла, Альфа',
    },
    {
        id: 4257,
        artnumber: '29177',
        barcode: '27476',
        quantity: 377,
        price: 30179,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4257 для Мытищи, Техника, Замена масла, Альфа',
    },
    {
        id: 4258,
        artnumber: '79592',
        barcode: '58991',
        quantity: 27,
        price: 35333,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4258 для Мытищи, Техника, Замена масла, Альфа',
    },
    {
        id: 4259,
        artnumber: '42927',
        barcode: '34808',
        quantity: 281,
        price: 9350,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4259 для Мытищи, Техника, Замена масла, Строй',
    },
    {
        id: 4260,
        artnumber: '60012',
        barcode: '90512',
        quantity: 184,
        price: 5020,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4260 для Мытищи, Техника, Замена масла, Строй',
    },
    {
        id: 4261,
        artnumber: '40476',
        barcode: '74687',
        quantity: 36,
        price: 33563,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4261 для Мытищи, Техника, Замена масла, Строй',
    },
    {
        id: 4262,
        artnumber: '26569',
        barcode: '52872',
        quantity: 391,
        price: 6588,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4262 для Мытищи, Техника, Замена масла, Строй',
    },
    {
        id: 4263,
        artnumber: '50519',
        barcode: '95032',
        quantity: 356,
        price: 22911,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4263 для Мытищи, Техника, Замена масла, Строй',
    },
    {
        id: 4264,
        artnumber: '86878',
        barcode: '62621',
        quantity: 375,
        price: 35424,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4264 для Мытищи, Техника, Замена масла, Строй',
    },
    {
        id: 4265,
        artnumber: '61733',
        barcode: '73068',
        quantity: 388,
        price: 48719,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4265 для Мытищи, Техника, Замена масла, Строй',
    },
    {
        id: 4266,
        artnumber: '23933',
        barcode: '37354',
        quantity: 256,
        price: 21471,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4266 для Мытищи, Техника, Замена масла, Строй',
    },
    {
        id: 4267,
        artnumber: '63846',
        barcode: '35334',
        quantity: 178,
        price: 43775,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4267 для Мытищи, Техника, Замена масла, Бета',
    },
    {
        id: 4268,
        artnumber: '82527',
        barcode: '93085',
        quantity: 309,
        price: 34318,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4268 для Мытищи, Техника, Замена масла, Бета',
    },
    {
        id: 4269,
        artnumber: '50544',
        barcode: '64389',
        quantity: 345,
        price: 37439,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4269 для Мытищи, Техника, Замена масла, Бета',
    },
    {
        id: 4270,
        artnumber: '85035',
        barcode: '53733',
        quantity: 224,
        price: 29243,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4270 для Мытищи, Техника, Замена масла, Бета',
    },
    {
        id: 4271,
        artnumber: '29389',
        barcode: '15721',
        quantity: 429,
        price: 39990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4271 для Мытищи, Техника, Замена масла, Бета',
    },
    {
        id: 4272,
        artnumber: '99959',
        barcode: '26023',
        quantity: 330,
        price: 6006,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4272 для Мытищи, Техника, Замена масла, Бета',
    },
    {
        id: 4273,
        artnumber: '18020',
        barcode: '93567',
        quantity: 37,
        price: 26931,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4273 для Мытищи, Техника, Замена масла, Бета',
    },
    {
        id: 4274,
        artnumber: '91518',
        barcode: '51911',
        quantity: 417,
        price: 8727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4274 для Мытищи, Техника, Замена масла, Бета',
    },
    {
        id: 4275,
        artnumber: '95144',
        barcode: '53235',
        quantity: 15,
        price: 9612,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4275 для Мытищи, Техника, Замена масла, Гамма',
    },
    {
        id: 4276,
        artnumber: '81555',
        barcode: '72810',
        quantity: 309,
        price: 15240,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4276 для Мытищи, Техника, Замена масла, Гамма',
    },
    {
        id: 4277,
        artnumber: '52961',
        barcode: '34374',
        quantity: 247,
        price: 42397,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4277 для Мытищи, Техника, Замена масла, Гамма',
    },
    {
        id: 4278,
        artnumber: '77318',
        barcode: '30869',
        quantity: 453,
        price: 9972,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4278 для Мытищи, Техника, Замена масла, Гамма',
    },
    {
        id: 4279,
        artnumber: '38062',
        barcode: '61327',
        quantity: 267,
        price: 5463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4279 для Мытищи, Техника, Замена масла, Гамма',
    },
    {
        id: 4280,
        artnumber: '69523',
        barcode: '38193',
        quantity: 396,
        price: 12213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4280 для Мытищи, Техника, Замена масла, Гамма',
    },
    {
        id: 4281,
        artnumber: '29874',
        barcode: '69353',
        quantity: 55,
        price: 5759,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4281 для Мытищи, Техника, Замена масла, Гамма',
    },
    {
        id: 4282,
        artnumber: '79202',
        barcode: '78684',
        quantity: 482,
        price: 24381,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4282 для Мытищи, Техника, Замена масла, Гамма',
    },
    {
        id: 4283,
        artnumber: '47345',
        barcode: '90947',
        quantity: 256,
        price: 37792,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4283 для Мытищи, Техника, Замена масла, Трио',
    },
    {
        id: 4284,
        artnumber: '11303',
        barcode: '78129',
        quantity: 193,
        price: 10144,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4284 для Мытищи, Техника, Замена масла, Трио',
    },
    {
        id: 4285,
        artnumber: '43873',
        barcode: '43625',
        quantity: 287,
        price: 35955,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4285 для Мытищи, Техника, Замена масла, Трио',
    },
    {
        id: 4286,
        artnumber: '17235',
        barcode: '88318',
        quantity: 3,
        price: 23913,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4286 для Мытищи, Техника, Замена масла, Трио',
    },
    {
        id: 4287,
        artnumber: '57578',
        barcode: '62426',
        quantity: 74,
        price: 49987,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4287 для Мытищи, Техника, Замена масла, Трио',
    },
    {
        id: 4288,
        artnumber: '51496',
        barcode: '41866',
        quantity: 337,
        price: 27735,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4288 для Мытищи, Техника, Замена масла, Трио',
    },
    {
        id: 4289,
        artnumber: '81742',
        barcode: '36156',
        quantity: 194,
        price: 28145,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4289 для Мытищи, Техника, Замена масла, Трио',
    },
    {
        id: 4290,
        artnumber: '17848',
        barcode: '57883',
        quantity: 463,
        price: 32025,
        picture: '/images/products/no_image.svg',
        region: {
            id: 4,
            name: 'Мытищи',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4290 для Мытищи, Техника, Замена масла, Трио',
    },
    {
        id: 4291,
        artnumber: '81465',
        barcode: '48260',
        quantity: 76,
        price: 5946,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4291 для Красногорск, Авто, Сервис, Альфа',
    },
    {
        id: 4292,
        artnumber: '44323',
        barcode: '80768',
        quantity: 146,
        price: 20136,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4292 для Красногорск, Авто, Сервис, Альфа',
    },
    {
        id: 4293,
        artnumber: '61691',
        barcode: '54674',
        quantity: 374,
        price: 19447,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4293 для Красногорск, Авто, Сервис, Альфа',
    },
    {
        id: 4294,
        artnumber: '95736',
        barcode: '64681',
        quantity: 170,
        price: 35197,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4294 для Красногорск, Авто, Сервис, Альфа',
    },
    {
        id: 4295,
        artnumber: '22810',
        barcode: '72081',
        quantity: 182,
        price: 18835,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4295 для Красногорск, Авто, Сервис, Альфа',
    },
    {
        id: 4296,
        artnumber: '83861',
        barcode: '86714',
        quantity: 129,
        price: 20295,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4296 для Красногорск, Авто, Сервис, Альфа',
    },
    {
        id: 4297,
        artnumber: '87800',
        barcode: '91078',
        quantity: 258,
        price: 39528,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4297 для Красногорск, Авто, Сервис, Альфа',
    },
    {
        id: 4298,
        artnumber: '20028',
        barcode: '91654',
        quantity: 146,
        price: 11990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4298 для Красногорск, Авто, Сервис, Альфа',
    },
    {
        id: 4299,
        artnumber: '64606',
        barcode: '32778',
        quantity: 378,
        price: 1897,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4299 для Красногорск, Авто, Сервис, Альфа',
    },
    {
        id: 4300,
        artnumber: '63696',
        barcode: '82161',
        quantity: 148,
        price: 3977,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4300 для Красногорск, Авто, Сервис, Строй',
    },
    {
        id: 4301,
        artnumber: '23738',
        barcode: '58551',
        quantity: 492,
        price: 39602,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4301 для Красногорск, Авто, Сервис, Строй',
    },
    {
        id: 4302,
        artnumber: '11811',
        barcode: '68363',
        quantity: 477,
        price: 36469,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4302 для Красногорск, Авто, Сервис, Строй',
    },
    {
        id: 4303,
        artnumber: '36783',
        barcode: '11526',
        quantity: 419,
        price: 18031,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4303 для Красногорск, Авто, Сервис, Строй',
    },
    {
        id: 4304,
        artnumber: '32325',
        barcode: '21103',
        quantity: 485,
        price: 17679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4304 для Красногорск, Авто, Сервис, Строй',
    },
    {
        id: 4305,
        artnumber: '34146',
        barcode: '47202',
        quantity: 147,
        price: 13074,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4305 для Красногорск, Авто, Сервис, Строй',
    },
    {
        id: 4306,
        artnumber: '11487',
        barcode: '25820',
        quantity: 65,
        price: 1250,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4306 для Красногорск, Авто, Сервис, Строй',
    },
    {
        id: 4307,
        artnumber: '20007',
        barcode: '81955',
        quantity: 378,
        price: 42235,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4307 для Красногорск, Авто, Сервис, Строй',
    },
    {
        id: 4308,
        artnumber: '88348',
        barcode: '21094',
        quantity: 90,
        price: 16035,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4308 для Красногорск, Авто, Сервис, Строй',
    },
    {
        id: 4309,
        artnumber: '92817',
        barcode: '79463',
        quantity: 223,
        price: 12589,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4309 для Красногорск, Авто, Сервис, Бета',
    },
    {
        id: 4310,
        artnumber: '11191',
        barcode: '78401',
        quantity: 457,
        price: 15534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4310 для Красногорск, Авто, Сервис, Бета',
    },
    {
        id: 4311,
        artnumber: '86243',
        barcode: '39981',
        quantity: 419,
        price: 25764,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4311 для Красногорск, Авто, Сервис, Бета',
    },
    {
        id: 4312,
        artnumber: '98413',
        barcode: '65491',
        quantity: 384,
        price: 28802,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4312 для Красногорск, Авто, Сервис, Бета',
    },
    {
        id: 4313,
        artnumber: '15928',
        barcode: '50057',
        quantity: 194,
        price: 18430,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4313 для Красногорск, Авто, Сервис, Бета',
    },
    {
        id: 4314,
        artnumber: '91846',
        barcode: '55148',
        quantity: 324,
        price: 17154,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4314 для Красногорск, Авто, Сервис, Бета',
    },
    {
        id: 4315,
        artnumber: '90431',
        barcode: '95566',
        quantity: 464,
        price: 746,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4315 для Красногорск, Авто, Сервис, Бета',
    },
    {
        id: 4316,
        artnumber: '20568',
        barcode: '68832',
        quantity: 261,
        price: 25868,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4316 для Красногорск, Авто, Сервис, Бета',
    },
    {
        id: 4317,
        artnumber: '82474',
        barcode: '65019',
        quantity: 14,
        price: 33991,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4317 для Красногорск, Авто, Сервис, Бета',
    },
    {
        id: 4318,
        artnumber: '21344',
        barcode: '96475',
        quantity: 407,
        price: 49776,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4318 для Красногорск, Авто, Сервис, Гамма',
    },
    {
        id: 4319,
        artnumber: '28235',
        barcode: '68571',
        quantity: 158,
        price: 12582,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4319 для Красногорск, Авто, Сервис, Гамма',
    },
    {
        id: 4320,
        artnumber: '25394',
        barcode: '95532',
        quantity: 79,
        price: 13105,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4320 для Красногорск, Авто, Сервис, Гамма',
    },
    {
        id: 4321,
        artnumber: '75537',
        barcode: '91997',
        quantity: 22,
        price: 9587,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4321 для Красногорск, Авто, Сервис, Гамма',
    },
    {
        id: 4322,
        artnumber: '77200',
        barcode: '78289',
        quantity: 17,
        price: 11512,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4322 для Красногорск, Авто, Сервис, Гамма',
    },
    {
        id: 4323,
        artnumber: '26221',
        barcode: '60875',
        quantity: 72,
        price: 23500,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4323 для Красногорск, Авто, Сервис, Гамма',
    },
    {
        id: 4324,
        artnumber: '67740',
        barcode: '32414',
        quantity: 135,
        price: 19645,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4324 для Красногорск, Авто, Сервис, Гамма',
    },
    {
        id: 4325,
        artnumber: '16425',
        barcode: '86545',
        quantity: 66,
        price: 14249,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4325 для Красногорск, Авто, Сервис, Гамма',
    },
    {
        id: 4326,
        artnumber: '37703',
        barcode: '58368',
        quantity: 289,
        price: 22773,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4326 для Красногорск, Авто, Сервис, Гамма',
    },
    {
        id: 4327,
        artnumber: '95651',
        barcode: '31733',
        quantity: 240,
        price: 1442,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4327 для Красногорск, Авто, Сервис, Трио',
    },
    {
        id: 4328,
        artnumber: '30845',
        barcode: '17213',
        quantity: 79,
        price: 47723,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4328 для Красногорск, Авто, Сервис, Трио',
    },
    {
        id: 4329,
        artnumber: '60607',
        barcode: '52288',
        quantity: 104,
        price: 31060,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4329 для Красногорск, Авто, Сервис, Трио',
    },
    {
        id: 4330,
        artnumber: '27131',
        barcode: '93347',
        quantity: 171,
        price: 6779,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4330 для Красногорск, Авто, Сервис, Трио',
    },
    {
        id: 4331,
        artnumber: '21822',
        barcode: '68143',
        quantity: 209,
        price: 10748,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4331 для Красногорск, Авто, Сервис, Трио',
    },
    {
        id: 4332,
        artnumber: '88181',
        barcode: '71919',
        quantity: 406,
        price: 43655,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4332 для Красногорск, Авто, Сервис, Трио',
    },
    {
        id: 4333,
        artnumber: '79092',
        barcode: '50321',
        quantity: 389,
        price: 47953,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4333 для Красногорск, Авто, Сервис, Трио',
    },
    {
        id: 4334,
        artnumber: '52725',
        barcode: '50304',
        quantity: 242,
        price: 24566,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4334 для Красногорск, Авто, Сервис, Трио',
    },
    {
        id: 4335,
        artnumber: '37269',
        barcode: '91956',
        quantity: 309,
        price: 18799,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4335 для Красногорск, Авто, Сервис, Трио',
    },
    {
        id: 4336,
        artnumber: '70850',
        barcode: '88614',
        quantity: 15,
        price: 15568,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4336 для Красногорск, Авто, Запчасти, Альфа',
    },
    {
        id: 4337,
        artnumber: '98626',
        barcode: '79279',
        quantity: 360,
        price: 4326,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4337 для Красногорск, Авто, Запчасти, Альфа',
    },
    {
        id: 4338,
        artnumber: '71028',
        barcode: '91944',
        quantity: 325,
        price: 39386,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4338 для Красногорск, Авто, Запчасти, Альфа',
    },
    {
        id: 4339,
        artnumber: '78595',
        barcode: '46660',
        quantity: 122,
        price: 31740,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4339 для Красногорск, Авто, Запчасти, Альфа',
    },
    {
        id: 4340,
        artnumber: '88784',
        barcode: '24492',
        quantity: 389,
        price: 2480,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4340 для Красногорск, Авто, Запчасти, Альфа',
    },
    {
        id: 4341,
        artnumber: '45565',
        barcode: '96129',
        quantity: 427,
        price: 47134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4341 для Красногорск, Авто, Запчасти, Альфа',
    },
    {
        id: 4342,
        artnumber: '76254',
        barcode: '92052',
        quantity: 413,
        price: 12846,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4342 для Красногорск, Авто, Запчасти, Альфа',
    },
    {
        id: 4343,
        artnumber: '82861',
        barcode: '18263',
        quantity: 388,
        price: 2058,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4343 для Красногорск, Авто, Запчасти, Альфа',
    },
    {
        id: 4344,
        artnumber: '92129',
        barcode: '20621',
        quantity: 169,
        price: 24370,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4344 для Красногорск, Авто, Запчасти, Строй',
    },
    {
        id: 4345,
        artnumber: '54194',
        barcode: '94155',
        quantity: 10,
        price: 20950,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4345 для Красногорск, Авто, Запчасти, Строй',
    },
    {
        id: 4346,
        artnumber: '19953',
        barcode: '45423',
        quantity: 235,
        price: 25817,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4346 для Красногорск, Авто, Запчасти, Строй',
    },
    {
        id: 4347,
        artnumber: '12878',
        barcode: '59850',
        quantity: 231,
        price: 49399,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4347 для Красногорск, Авто, Запчасти, Строй',
    },
    {
        id: 4348,
        artnumber: '27259',
        barcode: '29363',
        quantity: 281,
        price: 39809,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4348 для Красногорск, Авто, Запчасти, Строй',
    },
    {
        id: 4349,
        artnumber: '23562',
        barcode: '31118',
        quantity: 127,
        price: 34007,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4349 для Красногорск, Авто, Запчасти, Строй',
    },
    {
        id: 4350,
        artnumber: '42176',
        barcode: '48961',
        quantity: 389,
        price: 27744,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4350 для Красногорск, Авто, Запчасти, Строй',
    },
    {
        id: 4351,
        artnumber: '73468',
        barcode: '50106',
        quantity: 204,
        price: 35893,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4351 для Красногорск, Авто, Запчасти, Строй',
    },
    {
        id: 4352,
        artnumber: '15668',
        barcode: '19387',
        quantity: 146,
        price: 20752,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4352 для Красногорск, Авто, Запчасти, Бета',
    },
    {
        id: 4353,
        artnumber: '32095',
        barcode: '64539',
        quantity: 456,
        price: 2025,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4353 для Красногорск, Авто, Запчасти, Бета',
    },
    {
        id: 4354,
        artnumber: '65456',
        barcode: '56889',
        quantity: 261,
        price: 21075,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4354 для Красногорск, Авто, Запчасти, Бета',
    },
    {
        id: 4355,
        artnumber: '17042',
        barcode: '11717',
        quantity: 388,
        price: 43830,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4355 для Красногорск, Авто, Запчасти, Бета',
    },
    {
        id: 4356,
        artnumber: '49688',
        barcode: '54478',
        quantity: 433,
        price: 37406,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4356 для Красногорск, Авто, Запчасти, Бета',
    },
    {
        id: 4357,
        artnumber: '88651',
        barcode: '14520',
        quantity: 43,
        price: 38577,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4357 для Красногорск, Авто, Запчасти, Бета',
    },
    {
        id: 4358,
        artnumber: '30627',
        barcode: '62591',
        quantity: 217,
        price: 15891,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4358 для Красногорск, Авто, Запчасти, Бета',
    },
    {
        id: 4359,
        artnumber: '93942',
        barcode: '67180',
        quantity: 151,
        price: 2551,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4359 для Красногорск, Авто, Запчасти, Бета',
    },
    {
        id: 4360,
        artnumber: '41782',
        barcode: '25618',
        quantity: 8,
        price: 33899,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4360 для Красногорск, Авто, Запчасти, Гамма',
    },
    {
        id: 4361,
        artnumber: '48041',
        barcode: '86921',
        quantity: 454,
        price: 12229,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4361 для Красногорск, Авто, Запчасти, Гамма',
    },
    {
        id: 4362,
        artnumber: '14324',
        barcode: '28933',
        quantity: 156,
        price: 24174,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4362 для Красногорск, Авто, Запчасти, Гамма',
    },
    {
        id: 4363,
        artnumber: '71655',
        barcode: '50192',
        quantity: 469,
        price: 45148,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4363 для Красногорск, Авто, Запчасти, Гамма',
    },
    {
        id: 4364,
        artnumber: '86193',
        barcode: '10915',
        quantity: 272,
        price: 31448,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4364 для Красногорск, Авто, Запчасти, Гамма',
    },
    {
        id: 4365,
        artnumber: '90057',
        barcode: '26506',
        quantity: 181,
        price: 15244,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4365 для Красногорск, Авто, Запчасти, Гамма',
    },
    {
        id: 4366,
        artnumber: '60082',
        barcode: '27067',
        quantity: 152,
        price: 24742,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4366 для Красногорск, Авто, Запчасти, Гамма',
    },
    {
        id: 4367,
        artnumber: '12302',
        barcode: '37701',
        quantity: 199,
        price: 45331,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4367 для Красногорск, Авто, Запчасти, Гамма',
    },
    {
        id: 4368,
        artnumber: '77088',
        barcode: '98234',
        quantity: 165,
        price: 4919,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4368 для Красногорск, Авто, Запчасти, Трио',
    },
    {
        id: 4369,
        artnumber: '13147',
        barcode: '47524',
        quantity: 340,
        price: 42331,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4369 для Красногорск, Авто, Запчасти, Трио',
    },
    {
        id: 4370,
        artnumber: '32169',
        barcode: '82093',
        quantity: 63,
        price: 45691,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4370 для Красногорск, Авто, Запчасти, Трио',
    },
    {
        id: 4371,
        artnumber: '60184',
        barcode: '46767',
        quantity: 362,
        price: 22418,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4371 для Красногорск, Авто, Запчасти, Трио',
    },
    {
        id: 4372,
        artnumber: '79886',
        barcode: '53592',
        quantity: 25,
        price: 11723,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4372 для Красногорск, Авто, Запчасти, Трио',
    },
    {
        id: 4373,
        artnumber: '23550',
        barcode: '98244',
        quantity: 379,
        price: 38451,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4373 для Красногорск, Авто, Запчасти, Трио',
    },
    {
        id: 4374,
        artnumber: '39941',
        barcode: '53423',
        quantity: 273,
        price: 41989,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4374 для Красногорск, Авто, Запчасти, Трио',
    },
    {
        id: 4375,
        artnumber: '57070',
        barcode: '14114',
        quantity: 447,
        price: 35385,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4375 для Красногорск, Авто, Запчасти, Трио',
    },
    {
        id: 4376,
        artnumber: '85656',
        barcode: '70010',
        quantity: 493,
        price: 32025,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4376 для Красногорск, Авто, Доставка, Альфа',
    },
    {
        id: 4377,
        artnumber: '45777',
        barcode: '86103',
        quantity: 415,
        price: 3621,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4377 для Красногорск, Авто, Доставка, Альфа',
    },
    {
        id: 4378,
        artnumber: '93989',
        barcode: '54327',
        quantity: 246,
        price: 8698,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4378 для Красногорск, Авто, Доставка, Альфа',
    },
    {
        id: 4379,
        artnumber: '57292',
        barcode: '67804',
        quantity: 372,
        price: 29006,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4379 для Красногорск, Авто, Доставка, Строй',
    },
    {
        id: 4380,
        artnumber: '37634',
        barcode: '44133',
        quantity: 234,
        price: 20790,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4380 для Красногорск, Авто, Доставка, Строй',
    },
    {
        id: 4381,
        artnumber: '65775',
        barcode: '76921',
        quantity: 323,
        price: 48429,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4381 для Красногорск, Авто, Доставка, Строй',
    },
    {
        id: 4382,
        artnumber: '47433',
        barcode: '69100',
        quantity: 315,
        price: 14866,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4382 для Красногорск, Авто, Доставка, Бета',
    },
    {
        id: 4383,
        artnumber: '33380',
        barcode: '55611',
        quantity: 287,
        price: 42519,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4383 для Красногорск, Авто, Доставка, Бета',
    },
    {
        id: 4384,
        artnumber: '50477',
        barcode: '53453',
        quantity: 172,
        price: 30562,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4384 для Красногорск, Авто, Доставка, Бета',
    },
    {
        id: 4385,
        artnumber: '38454',
        barcode: '51401',
        quantity: 175,
        price: 41337,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4385 для Красногорск, Авто, Доставка, Гамма',
    },
    {
        id: 4386,
        artnumber: '24656',
        barcode: '17655',
        quantity: 15,
        price: 38433,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4386 для Красногорск, Авто, Доставка, Гамма',
    },
    {
        id: 4387,
        artnumber: '76059',
        barcode: '19731',
        quantity: 198,
        price: 23935,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4387 для Красногорск, Авто, Доставка, Гамма',
    },
    {
        id: 4388,
        artnumber: '88618',
        barcode: '87943',
        quantity: 403,
        price: 9337,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4388 для Красногорск, Авто, Доставка, Трио',
    },
    {
        id: 4389,
        artnumber: '29902',
        barcode: '31161',
        quantity: 9,
        price: 38830,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4389 для Красногорск, Авто, Доставка, Трио',
    },
    {
        id: 4390,
        artnumber: '62766',
        barcode: '62190',
        quantity: 8,
        price: 38971,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4390 для Красногорск, Авто, Доставка, Трио',
    },
    {
        id: 4391,
        artnumber: '82798',
        barcode: '78799',
        quantity: 18,
        price: 1173,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4391 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4392,
        artnumber: '48897',
        barcode: '14925',
        quantity: 371,
        price: 20748,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4392 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4393,
        artnumber: '72451',
        barcode: '13020',
        quantity: 371,
        price: 16033,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4393 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4394,
        artnumber: '60023',
        barcode: '90450',
        quantity: 73,
        price: 37800,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4394 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4395,
        artnumber: '25337',
        barcode: '49442',
        quantity: 440,
        price: 6708,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4395 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4396,
        artnumber: '73799',
        barcode: '90560',
        quantity: 93,
        price: 29284,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4396 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4397,
        artnumber: '68622',
        barcode: '65403',
        quantity: 461,
        price: 9600,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4397 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4398,
        artnumber: '96019',
        barcode: '58880',
        quantity: 29,
        price: 26175,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4398 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4399,
        artnumber: '11839',
        barcode: '49177',
        quantity: 32,
        price: 37569,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4399 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4400,
        artnumber: '45180',
        barcode: '17416',
        quantity: 450,
        price: 13284,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4400 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4401,
        artnumber: '75210',
        barcode: '55985',
        quantity: 54,
        price: 37261,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4401 для Красногорск, Авто, Шиномонтаж, Альфа',
    },
    {
        id: 4402,
        artnumber: '66323',
        barcode: '20602',
        quantity: 246,
        price: 42848,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4402 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4403,
        artnumber: '93407',
        barcode: '62313',
        quantity: 127,
        price: 43292,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4403 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4404,
        artnumber: '66165',
        barcode: '99694',
        quantity: 486,
        price: 23208,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4404 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4405,
        artnumber: '32405',
        barcode: '51949',
        quantity: 291,
        price: 45714,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4405 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4406,
        artnumber: '99534',
        barcode: '65573',
        quantity: 302,
        price: 19739,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4406 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4407,
        artnumber: '56247',
        barcode: '25257',
        quantity: 276,
        price: 11342,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4407 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4408,
        artnumber: '31389',
        barcode: '90544',
        quantity: 423,
        price: 19591,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4408 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4409,
        artnumber: '62868',
        barcode: '63361',
        quantity: 309,
        price: 29885,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4409 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4410,
        artnumber: '10357',
        barcode: '26097',
        quantity: 235,
        price: 29819,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4410 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4411,
        artnumber: '11317',
        barcode: '86881',
        quantity: 498,
        price: 17043,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4411 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4412,
        artnumber: '67363',
        barcode: '33852',
        quantity: 66,
        price: 16747,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4412 для Красногорск, Авто, Шиномонтаж, Строй',
    },
    {
        id: 4413,
        artnumber: '83380',
        barcode: '47944',
        quantity: 477,
        price: 42738,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4413 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4414,
        artnumber: '22932',
        barcode: '55182',
        quantity: 212,
        price: 31340,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4414 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4415,
        artnumber: '21283',
        barcode: '80958',
        quantity: 8,
        price: 29758,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4415 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4416,
        artnumber: '35991',
        barcode: '73500',
        quantity: 30,
        price: 16571,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4416 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4417,
        artnumber: '33565',
        barcode: '23601',
        quantity: 470,
        price: 30156,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4417 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4418,
        artnumber: '88532',
        barcode: '65545',
        quantity: 264,
        price: 29951,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4418 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4419,
        artnumber: '68587',
        barcode: '24285',
        quantity: 425,
        price: 47089,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4419 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4420,
        artnumber: '94748',
        barcode: '19703',
        quantity: 217,
        price: 35791,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4420 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4421,
        artnumber: '41486',
        barcode: '22631',
        quantity: 461,
        price: 39408,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4421 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4422,
        artnumber: '61697',
        barcode: '25801',
        quantity: 416,
        price: 3054,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4422 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4423,
        artnumber: '28936',
        barcode: '96681',
        quantity: 323,
        price: 36856,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4423 для Красногорск, Авто, Шиномонтаж, Бета',
    },
    {
        id: 4424,
        artnumber: '97320',
        barcode: '81595',
        quantity: 343,
        price: 152,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4424 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4425,
        artnumber: '14236',
        barcode: '36437',
        quantity: 257,
        price: 9618,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4425 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4426,
        artnumber: '68019',
        barcode: '65626',
        quantity: 47,
        price: 7367,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4426 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4427,
        artnumber: '31737',
        barcode: '50380',
        quantity: 390,
        price: 19411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4427 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4428,
        artnumber: '25217',
        barcode: '63661',
        quantity: 52,
        price: 45983,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4428 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4429,
        artnumber: '47274',
        barcode: '35991',
        quantity: 58,
        price: 10664,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4429 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4430,
        artnumber: '29525',
        barcode: '93392',
        quantity: 190,
        price: 9996,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4430 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4431,
        artnumber: '12749',
        barcode: '70644',
        quantity: 251,
        price: 23042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4431 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4432,
        artnumber: '80436',
        barcode: '23839',
        quantity: 381,
        price: 8738,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4432 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4433,
        artnumber: '64614',
        barcode: '15624',
        quantity: 3,
        price: 24048,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4433 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4434,
        artnumber: '61120',
        barcode: '81971',
        quantity: 320,
        price: 11906,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4434 для Красногорск, Авто, Шиномонтаж, Гамма',
    },
    {
        id: 4435,
        artnumber: '10573',
        barcode: '96206',
        quantity: 109,
        price: 23118,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4435 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4436,
        artnumber: '57195',
        barcode: '36708',
        quantity: 385,
        price: 30587,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4436 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4437,
        artnumber: '45644',
        barcode: '10839',
        quantity: 102,
        price: 33741,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4437 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4438,
        artnumber: '27575',
        barcode: '22404',
        quantity: 8,
        price: 12082,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4438 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4439,
        artnumber: '86345',
        barcode: '15225',
        quantity: 454,
        price: 5758,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4439 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4440,
        artnumber: '58237',
        barcode: '79435',
        quantity: 44,
        price: 25661,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4440 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4441,
        artnumber: '19817',
        barcode: '25081',
        quantity: 52,
        price: 41551,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4441 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4442,
        artnumber: '98199',
        barcode: '51330',
        quantity: 427,
        price: 11190,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4442 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4443,
        artnumber: '95370',
        barcode: '98853',
        quantity: 234,
        price: 41415,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4443 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4444,
        artnumber: '63021',
        barcode: '41374',
        quantity: 29,
        price: 6965,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4444 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4445,
        artnumber: '59091',
        barcode: '36505',
        quantity: 139,
        price: 25414,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4445 для Красногорск, Авто, Шиномонтаж, Трио',
    },
    {
        id: 4446,
        artnumber: '70354',
        barcode: '87321',
        quantity: 469,
        price: 7582,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4446 для Красногорск, Авто, Замена масла, Альфа',
    },
    {
        id: 4447,
        artnumber: '86064',
        barcode: '49230',
        quantity: 303,
        price: 27557,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4447 для Красногорск, Авто, Замена масла, Альфа',
    },
    {
        id: 4448,
        artnumber: '57766',
        barcode: '56095',
        quantity: 251,
        price: 33019,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4448 для Красногорск, Авто, Замена масла, Альфа',
    },
    {
        id: 4449,
        artnumber: '68292',
        barcode: '36508',
        quantity: 469,
        price: 13500,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4449 для Красногорск, Авто, Замена масла, Альфа',
    },
    {
        id: 4450,
        artnumber: '45213',
        barcode: '82742',
        quantity: 278,
        price: 20413,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4450 для Красногорск, Авто, Замена масла, Альфа',
    },
    {
        id: 4451,
        artnumber: '39942',
        barcode: '76836',
        quantity: 57,
        price: 33904,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4451 для Красногорск, Авто, Замена масла, Альфа',
    },
    {
        id: 4452,
        artnumber: '61082',
        barcode: '61487',
        quantity: 320,
        price: 9820,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4452 для Красногорск, Авто, Замена масла, Альфа',
    },
    {
        id: 4453,
        artnumber: '72999',
        barcode: '85441',
        quantity: 347,
        price: 16653,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4453 для Красногорск, Авто, Замена масла, Альфа',
    },
    {
        id: 4454,
        artnumber: '56551',
        barcode: '96833',
        quantity: 239,
        price: 9385,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4454 для Красногорск, Авто, Замена масла, Альфа',
    },
    {
        id: 4455,
        artnumber: '63366',
        barcode: '34804',
        quantity: 491,
        price: 20908,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4455 для Красногорск, Авто, Замена масла, Строй',
    },
    {
        id: 4456,
        artnumber: '22967',
        barcode: '61909',
        quantity: 344,
        price: 14977,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4456 для Красногорск, Авто, Замена масла, Строй',
    },
    {
        id: 4457,
        artnumber: '92206',
        barcode: '43974',
        quantity: 288,
        price: 32637,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4457 для Красногорск, Авто, Замена масла, Строй',
    },
    {
        id: 4458,
        artnumber: '43032',
        barcode: '15380',
        quantity: 122,
        price: 3357,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4458 для Красногорск, Авто, Замена масла, Строй',
    },
    {
        id: 4459,
        artnumber: '20154',
        barcode: '68302',
        quantity: 303,
        price: 8326,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4459 для Красногорск, Авто, Замена масла, Строй',
    },
    {
        id: 4460,
        artnumber: '27577',
        barcode: '48412',
        quantity: 359,
        price: 43024,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4460 для Красногорск, Авто, Замена масла, Строй',
    },
    {
        id: 4461,
        artnumber: '75313',
        barcode: '25656',
        quantity: 67,
        price: 6624,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4461 для Красногорск, Авто, Замена масла, Строй',
    },
    {
        id: 4462,
        artnumber: '19816',
        barcode: '87403',
        quantity: 94,
        price: 33775,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4462 для Красногорск, Авто, Замена масла, Строй',
    },
    {
        id: 4463,
        artnumber: '40894',
        barcode: '87772',
        quantity: 213,
        price: 44420,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4463 для Красногорск, Авто, Замена масла, Строй',
    },
    {
        id: 4464,
        artnumber: '84470',
        barcode: '52739',
        quantity: 230,
        price: 3384,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4464 для Красногорск, Авто, Замена масла, Бета',
    },
    {
        id: 4465,
        artnumber: '63377',
        barcode: '84204',
        quantity: 128,
        price: 18375,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4465 для Красногорск, Авто, Замена масла, Бета',
    },
    {
        id: 4466,
        artnumber: '39530',
        barcode: '26072',
        quantity: 26,
        price: 48391,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4466 для Красногорск, Авто, Замена масла, Бета',
    },
    {
        id: 4467,
        artnumber: '18964',
        barcode: '95130',
        quantity: 87,
        price: 12544,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4467 для Красногорск, Авто, Замена масла, Бета',
    },
    {
        id: 4468,
        artnumber: '95437',
        barcode: '17316',
        quantity: 439,
        price: 6875,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4468 для Красногорск, Авто, Замена масла, Бета',
    },
    {
        id: 4469,
        artnumber: '43794',
        barcode: '89920',
        quantity: 383,
        price: 12749,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4469 для Красногорск, Авто, Замена масла, Бета',
    },
    {
        id: 4470,
        artnumber: '10219',
        barcode: '74426',
        quantity: 186,
        price: 47371,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4470 для Красногорск, Авто, Замена масла, Бета',
    },
    {
        id: 4471,
        artnumber: '43520',
        barcode: '97960',
        quantity: 158,
        price: 41211,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4471 для Красногорск, Авто, Замена масла, Бета',
    },
    {
        id: 4472,
        artnumber: '64085',
        barcode: '77850',
        quantity: 418,
        price: 42164,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4472 для Красногорск, Авто, Замена масла, Бета',
    },
    {
        id: 4473,
        artnumber: '23804',
        barcode: '20596',
        quantity: 175,
        price: 20134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4473 для Красногорск, Авто, Замена масла, Гамма',
    },
    {
        id: 4474,
        artnumber: '58436',
        barcode: '23900',
        quantity: 68,
        price: 44720,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4474 для Красногорск, Авто, Замена масла, Гамма',
    },
    {
        id: 4475,
        artnumber: '24716',
        barcode: '10279',
        quantity: 462,
        price: 33361,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4475 для Красногорск, Авто, Замена масла, Гамма',
    },
    {
        id: 4476,
        artnumber: '30104',
        barcode: '75920',
        quantity: 448,
        price: 6357,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4476 для Красногорск, Авто, Замена масла, Гамма',
    },
    {
        id: 4477,
        artnumber: '42069',
        barcode: '91831',
        quantity: 179,
        price: 11011,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4477 для Красногорск, Авто, Замена масла, Гамма',
    },
    {
        id: 4478,
        artnumber: '82227',
        barcode: '44778',
        quantity: 417,
        price: 23493,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4478 для Красногорск, Авто, Замена масла, Гамма',
    },
    {
        id: 4479,
        artnumber: '70367',
        barcode: '40618',
        quantity: 273,
        price: 8511,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4479 для Красногорск, Авто, Замена масла, Гамма',
    },
    {
        id: 4480,
        artnumber: '13562',
        barcode: '98051',
        quantity: 449,
        price: 36424,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4480 для Красногорск, Авто, Замена масла, Гамма',
    },
    {
        id: 4481,
        artnumber: '88655',
        barcode: '69023',
        quantity: 278,
        price: 35945,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4481 для Красногорск, Авто, Замена масла, Гамма',
    },
    {
        id: 4482,
        artnumber: '81204',
        barcode: '70073',
        quantity: 359,
        price: 11897,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4482 для Красногорск, Авто, Замена масла, Трио',
    },
    {
        id: 4483,
        artnumber: '67680',
        barcode: '84823',
        quantity: 209,
        price: 47139,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4483 для Красногорск, Авто, Замена масла, Трио',
    },
    {
        id: 4484,
        artnumber: '86099',
        barcode: '64030',
        quantity: 153,
        price: 12496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4484 для Красногорск, Авто, Замена масла, Трио',
    },
    {
        id: 4485,
        artnumber: '36855',
        barcode: '97938',
        quantity: 194,
        price: 12331,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4485 для Красногорск, Авто, Замена масла, Трио',
    },
    {
        id: 4486,
        artnumber: '63034',
        barcode: '79808',
        quantity: 401,
        price: 12216,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4486 для Красногорск, Авто, Замена масла, Трио',
    },
    {
        id: 4487,
        artnumber: '48396',
        barcode: '68881',
        quantity: 313,
        price: 9136,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4487 для Красногорск, Авто, Замена масла, Трио',
    },
    {
        id: 4488,
        artnumber: '88612',
        barcode: '81762',
        quantity: 327,
        price: 7160,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4488 для Красногорск, Авто, Замена масла, Трио',
    },
    {
        id: 4489,
        artnumber: '65732',
        barcode: '62529',
        quantity: 175,
        price: 292,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4489 для Красногорск, Авто, Замена масла, Трио',
    },
    {
        id: 4490,
        artnumber: '31736',
        barcode: '69552',
        quantity: 292,
        price: 17675,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 1,
            name: 'Авто',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4490 для Красногорск, Авто, Замена масла, Трио',
    },
    {
        id: 4491,
        artnumber: '19801',
        barcode: '28859',
        quantity: 163,
        price: 5606,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4491 для Красногорск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 4492,
        artnumber: '34805',
        barcode: '62501',
        quantity: 480,
        price: 828,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4492 для Красногорск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 4493,
        artnumber: '17831',
        barcode: '87871',
        quantity: 309,
        price: 22784,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4493 для Красногорск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 4494,
        artnumber: '68435',
        barcode: '11967',
        quantity: 405,
        price: 6624,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4494 для Красногорск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 4495,
        artnumber: '61485',
        barcode: '97277',
        quantity: 216,
        price: 32369,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4495 для Красногорск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 4496,
        artnumber: '62780',
        barcode: '64938',
        quantity: 285,
        price: 2999,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4496 для Красногорск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 4497,
        artnumber: '25840',
        barcode: '51877',
        quantity: 147,
        price: 43530,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4497 для Красногорск, Недвижимость, Сервис, Альфа',
    },
    {
        id: 4498,
        artnumber: '15667',
        barcode: '16799',
        quantity: 91,
        price: 23368,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4498 для Красногорск, Недвижимость, Сервис, Строй',
    },
    {
        id: 4499,
        artnumber: '30035',
        barcode: '24768',
        quantity: 370,
        price: 43407,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4499 для Красногорск, Недвижимость, Сервис, Строй',
    },
    {
        id: 4500,
        artnumber: '88243',
        barcode: '34599',
        quantity: 55,
        price: 39998,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4500 для Красногорск, Недвижимость, Сервис, Строй',
    },
    {
        id: 4501,
        artnumber: '89252',
        barcode: '66660',
        quantity: 125,
        price: 12929,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4501 для Красногорск, Недвижимость, Сервис, Строй',
    },
    {
        id: 4502,
        artnumber: '42255',
        barcode: '22393',
        quantity: 93,
        price: 35777,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4502 для Красногорск, Недвижимость, Сервис, Строй',
    },
    {
        id: 4503,
        artnumber: '18719',
        barcode: '20272',
        quantity: 434,
        price: 21655,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4503 для Красногорск, Недвижимость, Сервис, Строй',
    },
    {
        id: 4504,
        artnumber: '98752',
        barcode: '40410',
        quantity: 213,
        price: 26171,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4504 для Красногорск, Недвижимость, Сервис, Строй',
    },
    {
        id: 4505,
        artnumber: '87419',
        barcode: '23431',
        quantity: 310,
        price: 39371,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4505 для Красногорск, Недвижимость, Сервис, Бета',
    },
    {
        id: 4506,
        artnumber: '34731',
        barcode: '87356',
        quantity: 10,
        price: 26043,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4506 для Красногорск, Недвижимость, Сервис, Бета',
    },
    {
        id: 4507,
        artnumber: '32407',
        barcode: '95387',
        quantity: 197,
        price: 38043,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4507 для Красногорск, Недвижимость, Сервис, Бета',
    },
    {
        id: 4508,
        artnumber: '32336',
        barcode: '77012',
        quantity: 253,
        price: 48932,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4508 для Красногорск, Недвижимость, Сервис, Бета',
    },
    {
        id: 4509,
        artnumber: '35576',
        barcode: '16735',
        quantity: 332,
        price: 3385,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4509 для Красногорск, Недвижимость, Сервис, Бета',
    },
    {
        id: 4510,
        artnumber: '22506',
        barcode: '15543',
        quantity: 421,
        price: 11892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4510 для Красногорск, Недвижимость, Сервис, Бета',
    },
    {
        id: 4511,
        artnumber: '43974',
        barcode: '49640',
        quantity: 398,
        price: 37527,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4511 для Красногорск, Недвижимость, Сервис, Бета',
    },
    {
        id: 4512,
        artnumber: '19342',
        barcode: '46993',
        quantity: 228,
        price: 39989,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4512 для Красногорск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 4513,
        artnumber: '39395',
        barcode: '24584',
        quantity: 301,
        price: 10572,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4513 для Красногорск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 4514,
        artnumber: '70378',
        barcode: '65967',
        quantity: 400,
        price: 31450,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4514 для Красногорск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 4515,
        artnumber: '65091',
        barcode: '86863',
        quantity: 495,
        price: 18456,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4515 для Красногорск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 4516,
        artnumber: '90888',
        barcode: '99065',
        quantity: 427,
        price: 22590,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4516 для Красногорск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 4517,
        artnumber: '47367',
        barcode: '15502',
        quantity: 183,
        price: 41775,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4517 для Красногорск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 4518,
        artnumber: '50761',
        barcode: '72204',
        quantity: 72,
        price: 43502,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4518 для Красногорск, Недвижимость, Сервис, Гамма',
    },
    {
        id: 4519,
        artnumber: '10780',
        barcode: '19259',
        quantity: 136,
        price: 13556,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4519 для Красногорск, Недвижимость, Сервис, Трио',
    },
    {
        id: 4520,
        artnumber: '99642',
        barcode: '15257',
        quantity: 419,
        price: 7370,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4520 для Красногорск, Недвижимость, Сервис, Трио',
    },
    {
        id: 4521,
        artnumber: '13470',
        barcode: '91857',
        quantity: 396,
        price: 40650,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4521 для Красногорск, Недвижимость, Сервис, Трио',
    },
    {
        id: 4522,
        artnumber: '27534',
        barcode: '42038',
        quantity: 461,
        price: 22518,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4522 для Красногорск, Недвижимость, Сервис, Трио',
    },
    {
        id: 4523,
        artnumber: '39994',
        barcode: '11976',
        quantity: 133,
        price: 21213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4523 для Красногорск, Недвижимость, Сервис, Трио',
    },
    {
        id: 4524,
        artnumber: '80880',
        barcode: '67012',
        quantity: 311,
        price: 435,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4524 для Красногорск, Недвижимость, Сервис, Трио',
    },
    {
        id: 4525,
        artnumber: '38774',
        barcode: '14643',
        quantity: 361,
        price: 48570,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4525 для Красногорск, Недвижимость, Сервис, Трио',
    },
    {
        id: 4526,
        artnumber: '19840',
        barcode: '28624',
        quantity: 485,
        price: 25923,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4526 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4527,
        artnumber: '34235',
        barcode: '25725',
        quantity: 52,
        price: 11833,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4527 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4528,
        artnumber: '77479',
        barcode: '91150',
        quantity: 289,
        price: 3113,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4528 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4529,
        artnumber: '88312',
        barcode: '29278',
        quantity: 59,
        price: 48812,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4529 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4530,
        artnumber: '67212',
        barcode: '89577',
        quantity: 415,
        price: 24233,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4530 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4531,
        artnumber: '65809',
        barcode: '20742',
        quantity: 66,
        price: 21687,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4531 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4532,
        artnumber: '82697',
        barcode: '10987',
        quantity: 21,
        price: 24994,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4532 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4533,
        artnumber: '96902',
        barcode: '57895',
        quantity: 364,
        price: 48392,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4533 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4534,
        artnumber: '75837',
        barcode: '16528',
        quantity: 428,
        price: 5636,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4534 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4535,
        artnumber: '87840',
        barcode: '37279',
        quantity: 110,
        price: 1725,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4535 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4536,
        artnumber: '87682',
        barcode: '87869',
        quantity: 33,
        price: 48169,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4536 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4537,
        artnumber: '56692',
        barcode: '33436',
        quantity: 407,
        price: 22227,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4537 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4538,
        artnumber: '61859',
        barcode: '94836',
        quantity: 212,
        price: 26416,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4538 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4539,
        artnumber: '22897',
        barcode: '43056',
        quantity: 289,
        price: 42484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4539 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4540,
        artnumber: '86314',
        barcode: '26665',
        quantity: 249,
        price: 38730,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4540 для Красногорск, Недвижимость, Запчасти, Альфа',
    },
    {
        id: 4541,
        artnumber: '57595',
        barcode: '59637',
        quantity: 324,
        price: 45371,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4541 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4542,
        artnumber: '65527',
        barcode: '87695',
        quantity: 178,
        price: 6693,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4542 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4543,
        artnumber: '25154',
        barcode: '42672',
        quantity: 363,
        price: 27186,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4543 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4544,
        artnumber: '48762',
        barcode: '90620',
        quantity: 130,
        price: 48082,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4544 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4545,
        artnumber: '14228',
        barcode: '54967',
        quantity: 358,
        price: 36448,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4545 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4546,
        artnumber: '48571',
        barcode: '94007',
        quantity: 346,
        price: 23508,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4546 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4547,
        artnumber: '86414',
        barcode: '23530',
        quantity: 244,
        price: 12362,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4547 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4548,
        artnumber: '60414',
        barcode: '24435',
        quantity: 139,
        price: 139,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4548 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4549,
        artnumber: '36229',
        barcode: '97954',
        quantity: 419,
        price: 44725,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4549 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4550,
        artnumber: '86927',
        barcode: '63781',
        quantity: 307,
        price: 45811,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4550 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4551,
        artnumber: '99953',
        barcode: '51358',
        quantity: 445,
        price: 26944,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4551 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4552,
        artnumber: '63284',
        barcode: '38091',
        quantity: 46,
        price: 16358,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4552 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4553,
        artnumber: '35620',
        barcode: '91427',
        quantity: 458,
        price: 43755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4553 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4554,
        artnumber: '78942',
        barcode: '45072',
        quantity: 52,
        price: 5159,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4554 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4555,
        artnumber: '37411',
        barcode: '29848',
        quantity: 101,
        price: 26476,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4555 для Красногорск, Недвижимость, Запчасти, Строй',
    },
    {
        id: 4556,
        artnumber: '86608',
        barcode: '12314',
        quantity: 336,
        price: 8864,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4556 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4557,
        artnumber: '51691',
        barcode: '45898',
        quantity: 120,
        price: 39036,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4557 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4558,
        artnumber: '82044',
        barcode: '94255',
        quantity: 274,
        price: 11482,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4558 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4559,
        artnumber: '30263',
        barcode: '10115',
        quantity: 457,
        price: 47295,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4559 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4560,
        artnumber: '61768',
        barcode: '33887',
        quantity: 313,
        price: 48839,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4560 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4561,
        artnumber: '69411',
        barcode: '66115',
        quantity: 384,
        price: 29485,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4561 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4562,
        artnumber: '37908',
        barcode: '79692',
        quantity: 261,
        price: 20283,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4562 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4563,
        artnumber: '30145',
        barcode: '61322',
        quantity: 500,
        price: 20379,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4563 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4564,
        artnumber: '70332',
        barcode: '90033',
        quantity: 126,
        price: 44074,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4564 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4565,
        artnumber: '17933',
        barcode: '90467',
        quantity: 273,
        price: 13239,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4565 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4566,
        artnumber: '72880',
        barcode: '92864',
        quantity: 491,
        price: 11658,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4566 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4567,
        artnumber: '34976',
        barcode: '87504',
        quantity: 352,
        price: 5553,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4567 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4568,
        artnumber: '22194',
        barcode: '10924',
        quantity: 407,
        price: 14963,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4568 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4569,
        artnumber: '97351',
        barcode: '77924',
        quantity: 391,
        price: 28245,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4569 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4570,
        artnumber: '33786',
        barcode: '32088',
        quantity: 348,
        price: 46513,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4570 для Красногорск, Недвижимость, Запчасти, Бета',
    },
    {
        id: 4571,
        artnumber: '49517',
        barcode: '74229',
        quantity: 289,
        price: 24899,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4571 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4572,
        artnumber: '20558',
        barcode: '31790',
        quantity: 191,
        price: 35571,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4572 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4573,
        artnumber: '66593',
        barcode: '21369',
        quantity: 20,
        price: 9711,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4573 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4574,
        artnumber: '27226',
        barcode: '61411',
        quantity: 220,
        price: 41923,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4574 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4575,
        artnumber: '43463',
        barcode: '44394',
        quantity: 67,
        price: 45487,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4575 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4576,
        artnumber: '29209',
        barcode: '39622',
        quantity: 56,
        price: 19771,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4576 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4577,
        artnumber: '67681',
        barcode: '57489',
        quantity: 59,
        price: 23969,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4577 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4578,
        artnumber: '46003',
        barcode: '89845',
        quantity: 83,
        price: 48216,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4578 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4579,
        artnumber: '11486',
        barcode: '26571',
        quantity: 422,
        price: 7224,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4579 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4580,
        artnumber: '74782',
        barcode: '13997',
        quantity: 165,
        price: 6245,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4580 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4581,
        artnumber: '71385',
        barcode: '17326',
        quantity: 156,
        price: 23813,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4581 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4582,
        artnumber: '49957',
        barcode: '27988',
        quantity: 92,
        price: 26,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4582 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4583,
        artnumber: '64769',
        barcode: '85989',
        quantity: 378,
        price: 16480,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4583 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4584,
        artnumber: '75057',
        barcode: '38409',
        quantity: 459,
        price: 29603,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4584 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4585,
        artnumber: '23093',
        barcode: '94916',
        quantity: 297,
        price: 9359,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4585 для Красногорск, Недвижимость, Запчасти, Гамма',
    },
    {
        id: 4586,
        artnumber: '65956',
        barcode: '12791',
        quantity: 53,
        price: 9887,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4586 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4587,
        artnumber: '39687',
        barcode: '87041',
        quantity: 257,
        price: 20337,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4587 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4588,
        artnumber: '74796',
        barcode: '81918',
        quantity: 150,
        price: 33404,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4588 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4589,
        artnumber: '95999',
        barcode: '56154',
        quantity: 285,
        price: 48454,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4589 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4590,
        artnumber: '62237',
        barcode: '92750',
        quantity: 442,
        price: 39106,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4590 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4591,
        artnumber: '73280',
        barcode: '83190',
        quantity: 277,
        price: 16804,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4591 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4592,
        artnumber: '36328',
        barcode: '33677',
        quantity: 413,
        price: 11821,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4592 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4593,
        artnumber: '46925',
        barcode: '47672',
        quantity: 105,
        price: 6312,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4593 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4594,
        artnumber: '76684',
        barcode: '55746',
        quantity: 417,
        price: 36492,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4594 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4595,
        artnumber: '43906',
        barcode: '35800',
        quantity: 170,
        price: 33518,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4595 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4596,
        artnumber: '11285',
        barcode: '45607',
        quantity: 67,
        price: 25748,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4596 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4597,
        artnumber: '86775',
        barcode: '28694',
        quantity: 144,
        price: 24556,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4597 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4598,
        artnumber: '45750',
        barcode: '90807',
        quantity: 333,
        price: 34619,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4598 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4599,
        artnumber: '53143',
        barcode: '92543',
        quantity: 125,
        price: 42323,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4599 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4600,
        artnumber: '36706',
        barcode: '77778',
        quantity: 246,
        price: 26175,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4600 для Красногорск, Недвижимость, Запчасти, Трио',
    },
    {
        id: 4601,
        artnumber: '54404',
        barcode: '22485',
        quantity: 88,
        price: 47211,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4601 для Красногорск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 4602,
        artnumber: '70359',
        barcode: '12366',
        quantity: 326,
        price: 28325,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4602 для Красногорск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 4603,
        artnumber: '40636',
        barcode: '27295',
        quantity: 154,
        price: 46629,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4603 для Красногорск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 4604,
        artnumber: '10056',
        barcode: '62742',
        quantity: 478,
        price: 14790,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4604 для Красногорск, Недвижимость, Доставка, Альфа',
    },
    {
        id: 4605,
        artnumber: '85770',
        barcode: '59462',
        quantity: 204,
        price: 20055,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4605 для Красногорск, Недвижимость, Доставка, Строй',
    },
    {
        id: 4606,
        artnumber: '14103',
        barcode: '80748',
        quantity: 489,
        price: 33270,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4606 для Красногорск, Недвижимость, Доставка, Строй',
    },
    {
        id: 4607,
        artnumber: '79248',
        barcode: '19792',
        quantity: 218,
        price: 4384,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4607 для Красногорск, Недвижимость, Доставка, Строй',
    },
    {
        id: 4608,
        artnumber: '62395',
        barcode: '82978',
        quantity: 431,
        price: 22077,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4608 для Красногорск, Недвижимость, Доставка, Строй',
    },
    {
        id: 4609,
        artnumber: '76241',
        barcode: '77017',
        quantity: 208,
        price: 43790,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4609 для Красногорск, Недвижимость, Доставка, Бета',
    },
    {
        id: 4610,
        artnumber: '23383',
        barcode: '39768',
        quantity: 474,
        price: 35027,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4610 для Красногорск, Недвижимость, Доставка, Бета',
    },
    {
        id: 4611,
        artnumber: '16598',
        barcode: '24073',
        quantity: 472,
        price: 8531,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4611 для Красногорск, Недвижимость, Доставка, Бета',
    },
    {
        id: 4612,
        artnumber: '53895',
        barcode: '53115',
        quantity: 445,
        price: 9400,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4612 для Красногорск, Недвижимость, Доставка, Бета',
    },
    {
        id: 4613,
        artnumber: '33603',
        barcode: '61981',
        quantity: 499,
        price: 18540,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4613 для Красногорск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 4614,
        artnumber: '66722',
        barcode: '35367',
        quantity: 295,
        price: 4591,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4614 для Красногорск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 4615,
        artnumber: '57502',
        barcode: '32036',
        quantity: 498,
        price: 39068,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4615 для Красногорск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 4616,
        artnumber: '71992',
        barcode: '47657',
        quantity: 38,
        price: 32800,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4616 для Красногорск, Недвижимость, Доставка, Гамма',
    },
    {
        id: 4617,
        artnumber: '23433',
        barcode: '36663',
        quantity: 200,
        price: 20704,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4617 для Красногорск, Недвижимость, Доставка, Трио',
    },
    {
        id: 4618,
        artnumber: '85547',
        barcode: '18050',
        quantity: 72,
        price: 1068,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4618 для Красногорск, Недвижимость, Доставка, Трио',
    },
    {
        id: 4619,
        artnumber: '37289',
        barcode: '61978',
        quantity: 128,
        price: 41819,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4619 для Красногорск, Недвижимость, Доставка, Трио',
    },
    {
        id: 4620,
        artnumber: '68809',
        barcode: '94019',
        quantity: 363,
        price: 14026,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4620 для Красногорск, Недвижимость, Доставка, Трио',
    },
    {
        id: 4621,
        artnumber: '20852',
        barcode: '38331',
        quantity: 71,
        price: 31539,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4621 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4622,
        artnumber: '70900',
        barcode: '46017',
        quantity: 39,
        price: 41598,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4622 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4623,
        artnumber: '86412',
        barcode: '37223',
        quantity: 273,
        price: 35226,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4623 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4624,
        artnumber: '94966',
        barcode: '40568',
        quantity: 119,
        price: 8612,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4624 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4625,
        artnumber: '54048',
        barcode: '90178',
        quantity: 250,
        price: 17426,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4625 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4626,
        artnumber: '55424',
        barcode: '24094',
        quantity: 293,
        price: 10529,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4626 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4627,
        artnumber: '49453',
        barcode: '25173',
        quantity: 86,
        price: 20895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4627 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4628,
        artnumber: '55721',
        barcode: '89718',
        quantity: 284,
        price: 5695,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4628 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4629,
        artnumber: '14680',
        barcode: '24508',
        quantity: 21,
        price: 29800,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4629 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4630,
        artnumber: '87988',
        barcode: '84002',
        quantity: 157,
        price: 1933,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4630 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4631,
        artnumber: '42068',
        barcode: '16523',
        quantity: 77,
        price: 3458,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4631 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4632,
        artnumber: '20111',
        barcode: '19214',
        quantity: 98,
        price: 46808,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4632 для Красногорск, Недвижимость, Шиномонтаж, Альфа',
    },
    {
        id: 4633,
        artnumber: '42885',
        barcode: '25229',
        quantity: 171,
        price: 2676,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4633 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4634,
        artnumber: '16601',
        barcode: '14489',
        quantity: 373,
        price: 28666,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4634 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4635,
        artnumber: '10756',
        barcode: '49358',
        quantity: 311,
        price: 12465,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4635 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4636,
        artnumber: '49650',
        barcode: '18504',
        quantity: 200,
        price: 45007,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4636 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4637,
        artnumber: '78037',
        barcode: '79027',
        quantity: 253,
        price: 14237,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4637 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4638,
        artnumber: '47062',
        barcode: '80486',
        quantity: 168,
        price: 19366,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4638 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4639,
        artnumber: '36251',
        barcode: '39389',
        quantity: 498,
        price: 4679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4639 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4640,
        artnumber: '31880',
        barcode: '42443',
        quantity: 111,
        price: 7342,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4640 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4641,
        artnumber: '81457',
        barcode: '29415',
        quantity: 252,
        price: 35883,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4641 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4642,
        artnumber: '43013',
        barcode: '19500',
        quantity: 389,
        price: 9242,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4642 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4643,
        artnumber: '59404',
        barcode: '97741',
        quantity: 274,
        price: 49496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4643 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4644,
        artnumber: '34022',
        barcode: '69498',
        quantity: 355,
        price: 7700,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4644 для Красногорск, Недвижимость, Шиномонтаж, Строй',
    },
    {
        id: 4645,
        artnumber: '75132',
        barcode: '48577',
        quantity: 491,
        price: 40036,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4645 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4646,
        artnumber: '56090',
        barcode: '18277',
        quantity: 228,
        price: 7919,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4646 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4647,
        artnumber: '98514',
        barcode: '80507',
        quantity: 38,
        price: 20934,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4647 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4648,
        artnumber: '86335',
        barcode: '68367',
        quantity: 14,
        price: 12188,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4648 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4649,
        artnumber: '94106',
        barcode: '98247',
        quantity: 427,
        price: 1706,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4649 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4650,
        artnumber: '12217',
        barcode: '80068',
        quantity: 329,
        price: 46219,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4650 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4651,
        artnumber: '83263',
        barcode: '16980',
        quantity: 148,
        price: 37409,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4651 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4652,
        artnumber: '86166',
        barcode: '74478',
        quantity: 173,
        price: 32754,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4652 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4653,
        artnumber: '45652',
        barcode: '86353',
        quantity: 309,
        price: 18990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4653 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4654,
        artnumber: '81598',
        barcode: '87853',
        quantity: 413,
        price: 5470,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4654 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4655,
        artnumber: '49886',
        barcode: '13650',
        quantity: 224,
        price: 40932,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4655 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4656,
        artnumber: '73421',
        barcode: '44658',
        quantity: 280,
        price: 11339,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4656 для Красногорск, Недвижимость, Шиномонтаж, Бета',
    },
    {
        id: 4657,
        artnumber: '58381',
        barcode: '38360',
        quantity: 317,
        price: 3342,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4657 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4658,
        artnumber: '62829',
        barcode: '61977',
        quantity: 406,
        price: 38303,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4658 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4659,
        artnumber: '69240',
        barcode: '26240',
        quantity: 447,
        price: 47477,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4659 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4660,
        artnumber: '14699',
        barcode: '29409',
        quantity: 114,
        price: 29859,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4660 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4661,
        artnumber: '56637',
        barcode: '95074',
        quantity: 292,
        price: 21083,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4661 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4662,
        artnumber: '96485',
        barcode: '85466',
        quantity: 261,
        price: 16221,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4662 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4663,
        artnumber: '31317',
        barcode: '10206',
        quantity: 173,
        price: 15327,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4663 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4664,
        artnumber: '79247',
        barcode: '72409',
        quantity: 185,
        price: 32680,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4664 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4665,
        artnumber: '18911',
        barcode: '21087',
        quantity: 429,
        price: 20761,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4665 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4666,
        artnumber: '72239',
        barcode: '25496',
        quantity: 62,
        price: 35610,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4666 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4667,
        artnumber: '79891',
        barcode: '88297',
        quantity: 490,
        price: 47196,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4667 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4668,
        artnumber: '64677',
        barcode: '32241',
        quantity: 17,
        price: 47587,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4668 для Красногорск, Недвижимость, Шиномонтаж, Гамма',
    },
    {
        id: 4669,
        artnumber: '10005',
        barcode: '80374',
        quantity: 263,
        price: 27102,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4669 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4670,
        artnumber: '26672',
        barcode: '12580',
        quantity: 424,
        price: 17352,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4670 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4671,
        artnumber: '33964',
        barcode: '38829',
        quantity: 256,
        price: 11524,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4671 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4672,
        artnumber: '73443',
        barcode: '93800',
        quantity: 324,
        price: 28255,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4672 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4673,
        artnumber: '16110',
        barcode: '23666',
        quantity: 197,
        price: 15047,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4673 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4674,
        artnumber: '34048',
        barcode: '19907',
        quantity: 21,
        price: 45258,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4674 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4675,
        artnumber: '70760',
        barcode: '22140',
        quantity: 186,
        price: 31086,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4675 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4676,
        artnumber: '14290',
        barcode: '80246',
        quantity: 125,
        price: 45540,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4676 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4677,
        artnumber: '97130',
        barcode: '91329',
        quantity: 359,
        price: 38917,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4677 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4678,
        artnumber: '47970',
        barcode: '15280',
        quantity: 147,
        price: 3426,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4678 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4679,
        artnumber: '32766',
        barcode: '94459',
        quantity: 309,
        price: 7899,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4679 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4680,
        artnumber: '98258',
        barcode: '61552',
        quantity: 328,
        price: 8748,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4680 для Красногорск, Недвижимость, Шиномонтаж, Трио',
    },
    {
        id: 4681,
        artnumber: '34045',
        barcode: '93532',
        quantity: 261,
        price: 30441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4681 для Красногорск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 4682,
        artnumber: '65157',
        barcode: '81978',
        quantity: 251,
        price: 18906,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4682 для Красногорск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 4683,
        artnumber: '15107',
        barcode: '58199',
        quantity: 57,
        price: 5116,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4683 для Красногорск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 4684,
        artnumber: '60933',
        barcode: '95470',
        quantity: 159,
        price: 22220,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4684 для Красногорск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 4685,
        artnumber: '45878',
        barcode: '79534',
        quantity: 184,
        price: 21690,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4685 для Красногорск, Недвижимость, Замена масла, Альфа',
    },
    {
        id: 4686,
        artnumber: '99349',
        barcode: '13811',
        quantity: 112,
        price: 27406,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4686 для Красногорск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 4687,
        artnumber: '91779',
        barcode: '39106',
        quantity: 65,
        price: 2105,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4687 для Красногорск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 4688,
        artnumber: '88130',
        barcode: '90710',
        quantity: 205,
        price: 48740,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4688 для Красногорск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 4689,
        artnumber: '84391',
        barcode: '90361',
        quantity: 399,
        price: 47108,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4689 для Красногорск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 4690,
        artnumber: '86194',
        barcode: '39001',
        quantity: 307,
        price: 5943,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4690 для Красногорск, Недвижимость, Замена масла, Строй',
    },
    {
        id: 4691,
        artnumber: '18503',
        barcode: '75124',
        quantity: 455,
        price: 49496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4691 для Красногорск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 4692,
        artnumber: '14851',
        barcode: '55818',
        quantity: 488,
        price: 38990,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4692 для Красногорск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 4693,
        artnumber: '90159',
        barcode: '22879',
        quantity: 438,
        price: 12015,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4693 для Красногорск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 4694,
        artnumber: '53058',
        barcode: '72303',
        quantity: 413,
        price: 48808,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4694 для Красногорск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 4695,
        artnumber: '56195',
        barcode: '52351',
        quantity: 65,
        price: 14867,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4695 для Красногорск, Недвижимость, Замена масла, Бета',
    },
    {
        id: 4696,
        artnumber: '53017',
        barcode: '67102',
        quantity: 273,
        price: 49011,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4696 для Красногорск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 4697,
        artnumber: '93783',
        barcode: '63821',
        quantity: 36,
        price: 12778,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4697 для Красногорск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 4698,
        artnumber: '57485',
        barcode: '53935',
        quantity: 274,
        price: 34667,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4698 для Красногорск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 4699,
        artnumber: '78545',
        barcode: '93365',
        quantity: 252,
        price: 43959,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4699 для Красногорск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 4700,
        artnumber: '29903',
        barcode: '72156',
        quantity: 347,
        price: 30307,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4700 для Красногорск, Недвижимость, Замена масла, Гамма',
    },
    {
        id: 4701,
        artnumber: '98408',
        barcode: '86511',
        quantity: 415,
        price: 44567,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4701 для Красногорск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 4702,
        artnumber: '57353',
        barcode: '29613',
        quantity: 334,
        price: 3734,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4702 для Красногорск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 4703,
        artnumber: '30277',
        barcode: '12190',
        quantity: 260,
        price: 7266,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4703 для Красногорск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 4704,
        artnumber: '17836',
        barcode: '23979',
        quantity: 181,
        price: 11116,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4704 для Красногорск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 4705,
        artnumber: '73735',
        barcode: '11265',
        quantity: 355,
        price: 32178,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 2,
            name: 'Недвижимость',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4705 для Красногорск, Недвижимость, Замена масла, Трио',
    },
    {
        id: 4706,
        artnumber: '43147',
        barcode: '93889',
        quantity: 384,
        price: 4253,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4706 для Красногорск, Красота, Сервис, Альфа',
    },
    {
        id: 4707,
        artnumber: '59922',
        barcode: '80151',
        quantity: 249,
        price: 36934,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4707 для Красногорск, Красота, Сервис, Альфа',
    },
    {
        id: 4708,
        artnumber: '20439',
        barcode: '50561',
        quantity: 366,
        price: 49890,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4708 для Красногорск, Красота, Сервис, Альфа',
    },
    {
        id: 4709,
        artnumber: '69735',
        barcode: '63487',
        quantity: 258,
        price: 33130,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4709 для Красногорск, Красота, Сервис, Альфа',
    },
    {
        id: 4710,
        artnumber: '84510',
        barcode: '39991',
        quantity: 489,
        price: 28481,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4710 для Красногорск, Красота, Сервис, Альфа',
    },
    {
        id: 4711,
        artnumber: '57647',
        barcode: '83842',
        quantity: 187,
        price: 7609,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4711 для Красногорск, Красота, Сервис, Альфа',
    },
    {
        id: 4712,
        artnumber: '74091',
        barcode: '51415',
        quantity: 482,
        price: 5834,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4712 для Красногорск, Красота, Сервис, Альфа',
    },
    {
        id: 4713,
        artnumber: '19586',
        barcode: '23982',
        quantity: 203,
        price: 2522,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4713 для Красногорск, Красота, Сервис, Строй',
    },
    {
        id: 4714,
        artnumber: '74882',
        barcode: '46589',
        quantity: 15,
        price: 4210,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4714 для Красногорск, Красота, Сервис, Строй',
    },
    {
        id: 4715,
        artnumber: '77482',
        barcode: '61638',
        quantity: 223,
        price: 35237,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4715 для Красногорск, Красота, Сервис, Строй',
    },
    {
        id: 4716,
        artnumber: '45667',
        barcode: '29553',
        quantity: 400,
        price: 33610,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4716 для Красногорск, Красота, Сервис, Строй',
    },
    {
        id: 4717,
        artnumber: '63253',
        barcode: '50367',
        quantity: 18,
        price: 9340,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4717 для Красногорск, Красота, Сервис, Строй',
    },
    {
        id: 4718,
        artnumber: '96313',
        barcode: '26000',
        quantity: 178,
        price: 15755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4718 для Красногорск, Красота, Сервис, Строй',
    },
    {
        id: 4719,
        artnumber: '15597',
        barcode: '77993',
        quantity: 199,
        price: 27804,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4719 для Красногорск, Красота, Сервис, Строй',
    },
    {
        id: 4720,
        artnumber: '29359',
        barcode: '47229',
        quantity: 36,
        price: 6664,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4720 для Красногорск, Красота, Сервис, Бета',
    },
    {
        id: 4721,
        artnumber: '42950',
        barcode: '28229',
        quantity: 195,
        price: 39985,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4721 для Красногорск, Красота, Сервис, Бета',
    },
    {
        id: 4722,
        artnumber: '59947',
        barcode: '21309',
        quantity: 272,
        price: 14717,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4722 для Красногорск, Красота, Сервис, Бета',
    },
    {
        id: 4723,
        artnumber: '77569',
        barcode: '29830',
        quantity: 392,
        price: 24860,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4723 для Красногорск, Красота, Сервис, Бета',
    },
    {
        id: 4724,
        artnumber: '18114',
        barcode: '47596',
        quantity: 141,
        price: 43753,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4724 для Красногорск, Красота, Сервис, Бета',
    },
    {
        id: 4725,
        artnumber: '89089',
        barcode: '14404',
        quantity: 422,
        price: 40863,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4725 для Красногорск, Красота, Сервис, Бета',
    },
    {
        id: 4726,
        artnumber: '55752',
        barcode: '46348',
        quantity: 334,
        price: 15289,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4726 для Красногорск, Красота, Сервис, Бета',
    },
    {
        id: 4727,
        artnumber: '21501',
        barcode: '46999',
        quantity: 479,
        price: 16513,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4727 для Красногорск, Красота, Сервис, Гамма',
    },
    {
        id: 4728,
        artnumber: '93407',
        barcode: '81491',
        quantity: 204,
        price: 23142,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4728 для Красногорск, Красота, Сервис, Гамма',
    },
    {
        id: 4729,
        artnumber: '82531',
        barcode: '93221',
        quantity: 28,
        price: 12732,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4729 для Красногорск, Красота, Сервис, Гамма',
    },
    {
        id: 4730,
        artnumber: '53046',
        barcode: '91336',
        quantity: 255,
        price: 40713,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4730 для Красногорск, Красота, Сервис, Гамма',
    },
    {
        id: 4731,
        artnumber: '67288',
        barcode: '38417',
        quantity: 27,
        price: 27261,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4731 для Красногорск, Красота, Сервис, Гамма',
    },
    {
        id: 4732,
        artnumber: '43159',
        barcode: '42938',
        quantity: 50,
        price: 864,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4732 для Красногорск, Красота, Сервис, Гамма',
    },
    {
        id: 4733,
        artnumber: '41885',
        barcode: '35044',
        quantity: 317,
        price: 44246,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4733 для Красногорск, Красота, Сервис, Гамма',
    },
    {
        id: 4734,
        artnumber: '35352',
        barcode: '10599',
        quantity: 71,
        price: 47009,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4734 для Красногорск, Красота, Сервис, Трио',
    },
    {
        id: 4735,
        artnumber: '75675',
        barcode: '71703',
        quantity: 203,
        price: 24953,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4735 для Красногорск, Красота, Сервис, Трио',
    },
    {
        id: 4736,
        artnumber: '43309',
        barcode: '30467',
        quantity: 186,
        price: 26507,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4736 для Красногорск, Красота, Сервис, Трио',
    },
    {
        id: 4737,
        artnumber: '79741',
        barcode: '47651',
        quantity: 159,
        price: 13374,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4737 для Красногорск, Красота, Сервис, Трио',
    },
    {
        id: 4738,
        artnumber: '11726',
        barcode: '45844',
        quantity: 493,
        price: 22393,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4738 для Красногорск, Красота, Сервис, Трио',
    },
    {
        id: 4739,
        artnumber: '43031',
        barcode: '80328',
        quantity: 235,
        price: 25123,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4739 для Красногорск, Красота, Сервис, Трио',
    },
    {
        id: 4740,
        artnumber: '14932',
        barcode: '54125',
        quantity: 224,
        price: 10057,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4740 для Красногорск, Красота, Сервис, Трио',
    },
    {
        id: 4741,
        artnumber: '30385',
        barcode: '34063',
        quantity: 179,
        price: 18053,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4741 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4742,
        artnumber: '23613',
        barcode: '43705',
        quantity: 376,
        price: 40029,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4742 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4743,
        artnumber: '29508',
        barcode: '56833',
        quantity: 169,
        price: 29421,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4743 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4744,
        artnumber: '28005',
        barcode: '52441',
        quantity: 154,
        price: 1871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4744 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4745,
        artnumber: '57083',
        barcode: '53886',
        quantity: 473,
        price: 6018,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4745 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4746,
        artnumber: '86922',
        barcode: '28426',
        quantity: 484,
        price: 25601,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4746 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4747,
        artnumber: '45467',
        barcode: '96629',
        quantity: 281,
        price: 43680,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4747 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4748,
        artnumber: '11202',
        barcode: '54617',
        quantity: 86,
        price: 35985,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4748 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4749,
        artnumber: '67881',
        barcode: '90472',
        quantity: 72,
        price: 36190,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4749 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4750,
        artnumber: '85515',
        barcode: '28150',
        quantity: 118,
        price: 29482,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4750 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4751,
        artnumber: '61839',
        barcode: '74223',
        quantity: 51,
        price: 42827,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4751 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4752,
        artnumber: '76796',
        barcode: '19334',
        quantity: 94,
        price: 24250,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4752 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4753,
        artnumber: '50113',
        barcode: '97337',
        quantity: 425,
        price: 14263,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4753 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4754,
        artnumber: '98652',
        barcode: '88673',
        quantity: 301,
        price: 42964,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4754 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4755,
        artnumber: '80931',
        barcode: '98396',
        quantity: 278,
        price: 30518,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4755 для Красногорск, Красота, Запчасти, Альфа',
    },
    {
        id: 4756,
        artnumber: '31983',
        barcode: '33500',
        quantity: 216,
        price: 22501,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4756 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4757,
        artnumber: '93488',
        barcode: '84081',
        quantity: 256,
        price: 1238,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4757 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4758,
        artnumber: '40007',
        barcode: '17429',
        quantity: 456,
        price: 33455,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4758 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4759,
        artnumber: '73991',
        barcode: '20512',
        quantity: 139,
        price: 25517,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4759 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4760,
        artnumber: '40406',
        barcode: '79195',
        quantity: 142,
        price: 35888,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4760 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4761,
        artnumber: '95468',
        barcode: '50821',
        quantity: 222,
        price: 47633,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4761 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4762,
        artnumber: '23112',
        barcode: '90729',
        quantity: 90,
        price: 40130,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4762 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4763,
        artnumber: '29735',
        barcode: '29910',
        quantity: 293,
        price: 15490,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4763 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4764,
        artnumber: '78626',
        barcode: '17192',
        quantity: 19,
        price: 28499,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4764 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4765,
        artnumber: '16934',
        barcode: '26138',
        quantity: 440,
        price: 28136,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4765 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4766,
        artnumber: '15848',
        barcode: '62838',
        quantity: 119,
        price: 40368,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4766 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4767,
        artnumber: '51945',
        barcode: '20317',
        quantity: 284,
        price: 45649,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4767 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4768,
        artnumber: '69912',
        barcode: '18234',
        quantity: 234,
        price: 2940,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4768 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4769,
        artnumber: '42496',
        barcode: '89239',
        quantity: 20,
        price: 33918,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4769 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4770,
        artnumber: '51326',
        barcode: '49250',
        quantity: 107,
        price: 16350,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4770 для Красногорск, Красота, Запчасти, Строй',
    },
    {
        id: 4771,
        artnumber: '24263',
        barcode: '93488',
        quantity: 26,
        price: 27612,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4771 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4772,
        artnumber: '22560',
        barcode: '53210',
        quantity: 461,
        price: 20844,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4772 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4773,
        artnumber: '72200',
        barcode: '66716',
        quantity: 335,
        price: 39507,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4773 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4774,
        artnumber: '71753',
        barcode: '49094',
        quantity: 53,
        price: 37027,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4774 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4775,
        artnumber: '34089',
        barcode: '92599',
        quantity: 229,
        price: 2573,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4775 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4776,
        artnumber: '71851',
        barcode: '74155',
        quantity: 471,
        price: 13344,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4776 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4777,
        artnumber: '48475',
        barcode: '97385',
        quantity: 79,
        price: 23361,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4777 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4778,
        artnumber: '89675',
        barcode: '39064',
        quantity: 16,
        price: 20765,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4778 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4779,
        artnumber: '74176',
        barcode: '19378',
        quantity: 398,
        price: 26821,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4779 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4780,
        artnumber: '46958',
        barcode: '53443',
        quantity: 375,
        price: 35517,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4780 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4781,
        artnumber: '73076',
        barcode: '92373',
        quantity: 378,
        price: 31424,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4781 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4782,
        artnumber: '39952',
        barcode: '77779',
        quantity: 165,
        price: 43061,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4782 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4783,
        artnumber: '28930',
        barcode: '82307',
        quantity: 381,
        price: 8036,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4783 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4784,
        artnumber: '58709',
        barcode: '50452',
        quantity: 97,
        price: 33833,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4784 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4785,
        artnumber: '22858',
        barcode: '70748',
        quantity: 139,
        price: 5672,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4785 для Красногорск, Красота, Запчасти, Бета',
    },
    {
        id: 4786,
        artnumber: '47116',
        barcode: '81867',
        quantity: 124,
        price: 3793,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4786 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4787,
        artnumber: '62528',
        barcode: '30173',
        quantity: 473,
        price: 34205,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4787 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4788,
        artnumber: '60891',
        barcode: '54184',
        quantity: 263,
        price: 41671,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4788 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4789,
        artnumber: '44002',
        barcode: '98190',
        quantity: 44,
        price: 6761,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4789 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4790,
        artnumber: '57907',
        barcode: '47846',
        quantity: 271,
        price: 46114,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4790 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4791,
        artnumber: '70365',
        barcode: '32516',
        quantity: 284,
        price: 16211,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4791 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4792,
        artnumber: '79805',
        barcode: '11074',
        quantity: 382,
        price: 16601,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4792 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4793,
        artnumber: '69062',
        barcode: '80848',
        quantity: 114,
        price: 4139,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4793 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4794,
        artnumber: '87730',
        barcode: '49919',
        quantity: 463,
        price: 15120,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4794 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4795,
        artnumber: '90027',
        barcode: '83078',
        quantity: 50,
        price: 21663,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4795 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4796,
        artnumber: '64988',
        barcode: '16107',
        quantity: 188,
        price: 39593,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4796 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4797,
        artnumber: '92217',
        barcode: '68079',
        quantity: 144,
        price: 8476,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4797 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4798,
        artnumber: '90881',
        barcode: '78925',
        quantity: 128,
        price: 7745,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4798 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4799,
        artnumber: '22136',
        barcode: '39070',
        quantity: 429,
        price: 41992,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4799 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4800,
        artnumber: '74905',
        barcode: '55346',
        quantity: 169,
        price: 18550,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4800 для Красногорск, Красота, Запчасти, Гамма',
    },
    {
        id: 4801,
        artnumber: '38440',
        barcode: '74748',
        quantity: 174,
        price: 20987,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4801 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4802,
        artnumber: '25277',
        barcode: '16795',
        quantity: 268,
        price: 39372,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4802 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4803,
        artnumber: '84220',
        barcode: '90596',
        quantity: 125,
        price: 16814,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4803 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4804,
        artnumber: '46487',
        barcode: '73979',
        quantity: 361,
        price: 46154,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4804 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4805,
        artnumber: '30148',
        barcode: '14694',
        quantity: 256,
        price: 24842,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4805 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4806,
        artnumber: '85914',
        barcode: '19218',
        quantity: 477,
        price: 1422,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4806 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4807,
        artnumber: '62910',
        barcode: '82422',
        quantity: 428,
        price: 45299,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4807 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4808,
        artnumber: '19497',
        barcode: '23928',
        quantity: 495,
        price: 35104,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4808 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4809,
        artnumber: '25833',
        barcode: '34560',
        quantity: 344,
        price: 5369,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4809 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4810,
        artnumber: '55246',
        barcode: '73965',
        quantity: 167,
        price: 14538,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4810 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4811,
        artnumber: '91267',
        barcode: '23956',
        quantity: 480,
        price: 46266,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4811 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4812,
        artnumber: '76502',
        barcode: '42326',
        quantity: 119,
        price: 11709,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4812 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4813,
        artnumber: '54941',
        barcode: '16341',
        quantity: 394,
        price: 15429,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4813 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4814,
        artnumber: '15741',
        barcode: '44486',
        quantity: 487,
        price: 2558,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4814 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4815,
        artnumber: '85278',
        barcode: '59421',
        quantity: 141,
        price: 2129,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4815 для Красногорск, Красота, Запчасти, Трио',
    },
    {
        id: 4816,
        artnumber: '78499',
        barcode: '87919',
        quantity: 242,
        price: 48679,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4816 для Красногорск, Красота, Доставка, Альфа',
    },
    {
        id: 4817,
        artnumber: '12440',
        barcode: '98929',
        quantity: 367,
        price: 445,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4817 для Красногорск, Красота, Доставка, Альфа',
    },
    {
        id: 4818,
        artnumber: '69996',
        barcode: '44061',
        quantity: 423,
        price: 46889,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4818 для Красногорск, Красота, Доставка, Альфа',
    },
    {
        id: 4819,
        artnumber: '83069',
        barcode: '86202',
        quantity: 101,
        price: 39088,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4819 для Красногорск, Красота, Доставка, Альфа',
    },
    {
        id: 4820,
        artnumber: '15672',
        barcode: '81194',
        quantity: 53,
        price: 28602,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4820 для Красногорск, Красота, Доставка, Строй',
    },
    {
        id: 4821,
        artnumber: '77879',
        barcode: '50276',
        quantity: 216,
        price: 31977,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4821 для Красногорск, Красота, Доставка, Строй',
    },
    {
        id: 4822,
        artnumber: '34424',
        barcode: '23180',
        quantity: 276,
        price: 34185,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4822 для Красногорск, Красота, Доставка, Строй',
    },
    {
        id: 4823,
        artnumber: '73872',
        barcode: '74774',
        quantity: 96,
        price: 9534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4823 для Красногорск, Красота, Доставка, Строй',
    },
    {
        id: 4824,
        artnumber: '10754',
        barcode: '52388',
        quantity: 325,
        price: 39146,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4824 для Красногорск, Красота, Доставка, Бета',
    },
    {
        id: 4825,
        artnumber: '19098',
        barcode: '53930',
        quantity: 382,
        price: 27396,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4825 для Красногорск, Красота, Доставка, Бета',
    },
    {
        id: 4826,
        artnumber: '22084',
        barcode: '48701',
        quantity: 340,
        price: 24786,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4826 для Красногорск, Красота, Доставка, Бета',
    },
    {
        id: 4827,
        artnumber: '66538',
        barcode: '46675',
        quantity: 240,
        price: 30900,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4827 для Красногорск, Красота, Доставка, Бета',
    },
    {
        id: 4828,
        artnumber: '47907',
        barcode: '86985',
        quantity: 328,
        price: 39292,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4828 для Красногорск, Красота, Доставка, Гамма',
    },
    {
        id: 4829,
        artnumber: '68800',
        barcode: '78523',
        quantity: 232,
        price: 14782,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4829 для Красногорск, Красота, Доставка, Гамма',
    },
    {
        id: 4830,
        artnumber: '30270',
        barcode: '98741',
        quantity: 490,
        price: 13863,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4830 для Красногорск, Красота, Доставка, Гамма',
    },
    {
        id: 4831,
        artnumber: '47280',
        barcode: '44755',
        quantity: 178,
        price: 49259,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4831 для Красногорск, Красота, Доставка, Гамма',
    },
    {
        id: 4832,
        artnumber: '94978',
        barcode: '49338',
        quantity: 9,
        price: 47563,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4832 для Красногорск, Красота, Доставка, Трио',
    },
    {
        id: 4833,
        artnumber: '90738',
        barcode: '82577',
        quantity: 19,
        price: 42485,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4833 для Красногорск, Красота, Доставка, Трио',
    },
    {
        id: 4834,
        artnumber: '64199',
        barcode: '58361',
        quantity: 317,
        price: 19247,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4834 для Красногорск, Красота, Доставка, Трио',
    },
    {
        id: 4835,
        artnumber: '37176',
        barcode: '10410',
        quantity: 36,
        price: 2708,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4835 для Красногорск, Красота, Доставка, Трио',
    },
    {
        id: 4836,
        artnumber: '91460',
        barcode: '28188',
        quantity: 252,
        price: 17259,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4836 для Красногорск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 4837,
        artnumber: '10117',
        barcode: '78775',
        quantity: 212,
        price: 26459,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4837 для Красногорск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 4838,
        artnumber: '55329',
        barcode: '53580',
        quantity: 262,
        price: 406,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4838 для Красногорск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 4839,
        artnumber: '45848',
        barcode: '10934',
        quantity: 121,
        price: 459,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4839 для Красногорск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 4840,
        artnumber: '47309',
        barcode: '13497',
        quantity: 144,
        price: 44999,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4840 для Красногорск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 4841,
        artnumber: '67485',
        barcode: '77917',
        quantity: 2,
        price: 25994,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4841 для Красногорск, Красота, Шиномонтаж, Альфа',
    },
    {
        id: 4842,
        artnumber: '56178',
        barcode: '38573',
        quantity: 400,
        price: 36979,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4842 для Красногорск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 4843,
        artnumber: '47496',
        barcode: '24443',
        quantity: 379,
        price: 41735,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4843 для Красногорск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 4844,
        artnumber: '47219',
        barcode: '73514',
        quantity: 121,
        price: 40358,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4844 для Красногорск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 4845,
        artnumber: '66734',
        barcode: '60756',
        quantity: 251,
        price: 15412,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4845 для Красногорск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 4846,
        artnumber: '87083',
        barcode: '64887',
        quantity: 411,
        price: 17867,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4846 для Красногорск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 4847,
        artnumber: '59032',
        barcode: '84684',
        quantity: 186,
        price: 32737,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4847 для Красногорск, Красота, Шиномонтаж, Строй',
    },
    {
        id: 4848,
        artnumber: '92047',
        barcode: '91208',
        quantity: 165,
        price: 44885,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4848 для Красногорск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 4849,
        artnumber: '59072',
        barcode: '38846',
        quantity: 90,
        price: 3245,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4849 для Красногорск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 4850,
        artnumber: '50170',
        barcode: '71506',
        quantity: 76,
        price: 237,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4850 для Красногорск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 4851,
        artnumber: '23133',
        barcode: '10964',
        quantity: 202,
        price: 12809,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4851 для Красногорск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 4852,
        artnumber: '61796',
        barcode: '52285',
        quantity: 191,
        price: 30422,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4852 для Красногорск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 4853,
        artnumber: '87712',
        barcode: '87647',
        quantity: 285,
        price: 20886,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4853 для Красногорск, Красота, Шиномонтаж, Бета',
    },
    {
        id: 4854,
        artnumber: '21398',
        barcode: '62587',
        quantity: 452,
        price: 2167,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4854 для Красногорск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 4855,
        artnumber: '96973',
        barcode: '87728',
        quantity: 23,
        price: 16025,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4855 для Красногорск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 4856,
        artnumber: '35448',
        barcode: '19443',
        quantity: 170,
        price: 23057,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4856 для Красногорск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 4857,
        artnumber: '83367',
        barcode: '52191',
        quantity: 402,
        price: 45091,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4857 для Красногорск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 4858,
        artnumber: '35340',
        barcode: '41579',
        quantity: 293,
        price: 10370,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4858 для Красногорск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 4859,
        artnumber: '36714',
        barcode: '37818',
        quantity: 140,
        price: 994,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4859 для Красногорск, Красота, Шиномонтаж, Гамма',
    },
    {
        id: 4860,
        artnumber: '99221',
        barcode: '89928',
        quantity: 468,
        price: 6972,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4860 для Красногорск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 4861,
        artnumber: '28871',
        barcode: '89835',
        quantity: 234,
        price: 27717,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4861 для Красногорск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 4862,
        artnumber: '54019',
        barcode: '32360',
        quantity: 432,
        price: 28609,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4862 для Красногорск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 4863,
        artnumber: '50087',
        barcode: '57701',
        quantity: 400,
        price: 16583,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4863 для Красногорск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 4864,
        artnumber: '65588',
        barcode: '33552',
        quantity: 181,
        price: 39721,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4864 для Красногорск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 4865,
        artnumber: '26675',
        barcode: '42976',
        quantity: 149,
        price: 2430,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4865 для Красногорск, Красота, Шиномонтаж, Трио',
    },
    {
        id: 4866,
        artnumber: '77978',
        barcode: '19242',
        quantity: 424,
        price: 34242,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4866 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4867,
        artnumber: '74655',
        barcode: '68715',
        quantity: 454,
        price: 28397,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4867 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4868,
        artnumber: '37402',
        barcode: '67945',
        quantity: 39,
        price: 37927,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4868 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4869,
        artnumber: '32576',
        barcode: '53065',
        quantity: 364,
        price: 6211,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4869 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4870,
        artnumber: '63586',
        barcode: '30491',
        quantity: 233,
        price: 20064,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4870 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4871,
        artnumber: '24214',
        barcode: '11690',
        quantity: 105,
        price: 36235,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4871 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4872,
        artnumber: '42405',
        barcode: '29483',
        quantity: 244,
        price: 49008,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4872 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4873,
        artnumber: '32608',
        barcode: '91429',
        quantity: 37,
        price: 38257,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4873 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4874,
        artnumber: '26325',
        barcode: '72920',
        quantity: 72,
        price: 22549,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4874 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4875,
        artnumber: '88280',
        barcode: '55893',
        quantity: 480,
        price: 46950,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4875 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4876,
        artnumber: '44170',
        barcode: '12193',
        quantity: 104,
        price: 4848,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4876 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4877,
        artnumber: '15333',
        barcode: '40557',
        quantity: 109,
        price: 4760,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4877 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4878,
        artnumber: '10304',
        barcode: '27488',
        quantity: 333,
        price: 41304,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4878 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4879,
        artnumber: '91049',
        barcode: '89972',
        quantity: 433,
        price: 10777,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4879 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4880,
        artnumber: '32062',
        barcode: '63325',
        quantity: 203,
        price: 8690,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4880 для Красногорск, Красота, Замена масла, Альфа',
    },
    {
        id: 4881,
        artnumber: '56944',
        barcode: '73534',
        quantity: 169,
        price: 39157,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4881 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4882,
        artnumber: '43037',
        barcode: '35367',
        quantity: 83,
        price: 30016,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4882 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4883,
        artnumber: '51911',
        barcode: '65494',
        quantity: 76,
        price: 13006,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4883 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4884,
        artnumber: '14229',
        barcode: '43308',
        quantity: 109,
        price: 8134,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4884 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4885,
        artnumber: '78511',
        barcode: '98383',
        quantity: 13,
        price: 7602,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4885 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4886,
        artnumber: '68424',
        barcode: '34554',
        quantity: 250,
        price: 47363,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4886 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4887,
        artnumber: '86658',
        barcode: '13421',
        quantity: 142,
        price: 21066,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4887 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4888,
        artnumber: '59235',
        barcode: '97338',
        quantity: 398,
        price: 39966,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4888 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4889,
        artnumber: '69171',
        barcode: '18686',
        quantity: 387,
        price: 33684,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4889 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4890,
        artnumber: '20453',
        barcode: '85043',
        quantity: 4,
        price: 30748,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4890 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4891,
        artnumber: '46117',
        barcode: '18767',
        quantity: 99,
        price: 41511,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4891 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4892,
        artnumber: '78584',
        barcode: '98526',
        quantity: 25,
        price: 13,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4892 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4893,
        artnumber: '27335',
        barcode: '77560',
        quantity: 252,
        price: 7175,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4893 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4894,
        artnumber: '91545',
        barcode: '61528',
        quantity: 454,
        price: 14975,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4894 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4895,
        artnumber: '64324',
        barcode: '60698',
        quantity: 394,
        price: 24947,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4895 для Красногорск, Красота, Замена масла, Строй',
    },
    {
        id: 4896,
        artnumber: '46837',
        barcode: '85157',
        quantity: 53,
        price: 42757,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4896 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4897,
        artnumber: '67047',
        barcode: '79737',
        quantity: 149,
        price: 42670,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4897 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4898,
        artnumber: '54932',
        barcode: '24315',
        quantity: 390,
        price: 39617,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4898 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4899,
        artnumber: '47677',
        barcode: '29368',
        quantity: 363,
        price: 39093,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4899 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4900,
        artnumber: '75509',
        barcode: '32852',
        quantity: 134,
        price: 3961,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4900 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4901,
        artnumber: '34523',
        barcode: '85760',
        quantity: 301,
        price: 32204,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4901 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4902,
        artnumber: '40734',
        barcode: '45585',
        quantity: 199,
        price: 46763,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4902 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4903,
        artnumber: '97544',
        barcode: '40367',
        quantity: 258,
        price: 48122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4903 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4904,
        artnumber: '20107',
        barcode: '11496',
        quantity: 164,
        price: 38921,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4904 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4905,
        artnumber: '20611',
        barcode: '65522',
        quantity: 240,
        price: 8162,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4905 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4906,
        artnumber: '26234',
        barcode: '47440',
        quantity: 221,
        price: 45148,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4906 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4907,
        artnumber: '50692',
        barcode: '47352',
        quantity: 60,
        price: 12002,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4907 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4908,
        artnumber: '94252',
        barcode: '35734',
        quantity: 400,
        price: 31453,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4908 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4909,
        artnumber: '66158',
        barcode: '85556',
        quantity: 263,
        price: 34794,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4909 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4910,
        artnumber: '12045',
        barcode: '13514',
        quantity: 56,
        price: 7256,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4910 для Красногорск, Красота, Замена масла, Бета',
    },
    {
        id: 4911,
        artnumber: '25572',
        barcode: '49075',
        quantity: 322,
        price: 43787,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4911 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4912,
        artnumber: '98757',
        barcode: '78686',
        quantity: 382,
        price: 45772,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4912 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4913,
        artnumber: '95103',
        barcode: '74108',
        quantity: 27,
        price: 29691,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4913 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4914,
        artnumber: '87109',
        barcode: '86808',
        quantity: 154,
        price: 28518,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4914 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4915,
        artnumber: '24796',
        barcode: '87770',
        quantity: 444,
        price: 10770,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4915 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4916,
        artnumber: '61498',
        barcode: '33374',
        quantity: 240,
        price: 35405,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4916 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4917,
        artnumber: '18252',
        barcode: '50116',
        quantity: 59,
        price: 21729,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4917 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4918,
        artnumber: '16838',
        barcode: '25241',
        quantity: 484,
        price: 23362,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4918 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4919,
        artnumber: '29652',
        barcode: '21369',
        quantity: 205,
        price: 4870,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4919 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4920,
        artnumber: '42295',
        barcode: '30974',
        quantity: 250,
        price: 34436,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4920 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4921,
        artnumber: '32085',
        barcode: '59481',
        quantity: 276,
        price: 33406,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4921 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4922,
        artnumber: '41404',
        barcode: '52376',
        quantity: 497,
        price: 43590,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4922 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4923,
        artnumber: '72221',
        barcode: '32868',
        quantity: 168,
        price: 28867,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4923 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4924,
        artnumber: '22388',
        barcode: '45179',
        quantity: 242,
        price: 28505,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4924 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4925,
        artnumber: '99313',
        barcode: '80406',
        quantity: 461,
        price: 3326,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4925 для Красногорск, Красота, Замена масла, Гамма',
    },
    {
        id: 4926,
        artnumber: '84387',
        barcode: '26022',
        quantity: 365,
        price: 36037,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4926 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4927,
        artnumber: '41272',
        barcode: '92772',
        quantity: 261,
        price: 10629,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4927 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4928,
        artnumber: '31629',
        barcode: '71664',
        quantity: 422,
        price: 7110,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4928 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4929,
        artnumber: '87706',
        barcode: '17028',
        quantity: 478,
        price: 24142,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4929 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4930,
        artnumber: '54413',
        barcode: '76647',
        quantity: 293,
        price: 15580,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4930 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4931,
        artnumber: '33518',
        barcode: '33943',
        quantity: 477,
        price: 33386,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4931 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4932,
        artnumber: '13544',
        barcode: '82944',
        quantity: 339,
        price: 37252,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4932 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4933,
        artnumber: '49297',
        barcode: '90093',
        quantity: 117,
        price: 25832,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4933 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4934,
        artnumber: '27794',
        barcode: '47898',
        quantity: 471,
        price: 15283,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4934 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4935,
        artnumber: '80044',
        barcode: '17028',
        quantity: 94,
        price: 48323,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4935 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4936,
        artnumber: '63704',
        barcode: '33939',
        quantity: 304,
        price: 21124,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4936 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4937,
        artnumber: '42043',
        barcode: '65498',
        quantity: 239,
        price: 32470,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4937 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4938,
        artnumber: '82742',
        barcode: '25333',
        quantity: 166,
        price: 20570,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4938 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4939,
        artnumber: '73795',
        barcode: '11437',
        quantity: 92,
        price: 30590,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4939 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4940,
        artnumber: '68341',
        barcode: '59916',
        quantity: 110,
        price: 1504,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 3,
            name: 'Красота',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4940 для Красногорск, Красота, Замена масла, Трио',
    },
    {
        id: 4941,
        artnumber: '29401',
        barcode: '29201',
        quantity: 313,
        price: 18925,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4941 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4942,
        artnumber: '40353',
        barcode: '75053',
        quantity: 312,
        price: 22422,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4942 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4943,
        artnumber: '73715',
        barcode: '29551',
        quantity: 346,
        price: 29484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4943 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4944,
        artnumber: '36323',
        barcode: '51590',
        quantity: 444,
        price: 19974,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4944 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4945,
        artnumber: '83594',
        barcode: '24473',
        quantity: 388,
        price: 1893,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4945 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4946,
        artnumber: '43027',
        barcode: '67918',
        quantity: 346,
        price: 42307,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4946 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4947,
        artnumber: '97232',
        barcode: '19757',
        quantity: 306,
        price: 34584,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4947 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4948,
        artnumber: '66319',
        barcode: '24555',
        quantity: 301,
        price: 9930,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4948 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4949,
        artnumber: '87338',
        barcode: '86484',
        quantity: 283,
        price: 31892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4949 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4950,
        artnumber: '53874',
        barcode: '13989',
        quantity: 52,
        price: 13546,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4950 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4951,
        artnumber: '17097',
        barcode: '96579',
        quantity: 431,
        price: 34430,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4951 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4952,
        artnumber: '66299',
        barcode: '97057',
        quantity: 223,
        price: 32960,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4952 для Красногорск, Здоровье, Сервис, Альфа',
    },
    {
        id: 4953,
        artnumber: '42310',
        barcode: '81097',
        quantity: 352,
        price: 42041,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4953 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4954,
        artnumber: '14542',
        barcode: '62562',
        quantity: 145,
        price: 25626,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4954 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4955,
        artnumber: '70141',
        barcode: '57910',
        quantity: 447,
        price: 30727,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 4955 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4956,
        artnumber: '16656',
        barcode: '72206',
        quantity: 7,
        price: 19075,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4956 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4957,
        artnumber: '13415',
        barcode: '14869',
        quantity: 467,
        price: 24515,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4957 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4958,
        artnumber: '44646',
        barcode: '72390',
        quantity: 35,
        price: 21465,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4958 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4959,
        artnumber: '68369',
        barcode: '37916',
        quantity: 449,
        price: 36607,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4959 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4960,
        artnumber: '84582',
        barcode: '48672',
        quantity: 389,
        price: 8428,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4960 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4961,
        artnumber: '33945',
        barcode: '78802',
        quantity: 346,
        price: 2755,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4961 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4962,
        artnumber: '98667',
        barcode: '73178',
        quantity: 245,
        price: 26437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4962 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4963,
        artnumber: '63137',
        barcode: '23739',
        quantity: 432,
        price: 28064,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4963 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4964,
        artnumber: '18932',
        barcode: '97594',
        quantity: 253,
        price: 47042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4964 для Красногорск, Здоровье, Сервис, Строй',
    },
    {
        id: 4965,
        artnumber: '90456',
        barcode: '88788',
        quantity: 179,
        price: 3436,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4965 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4966,
        artnumber: '85632',
        barcode: '50841',
        quantity: 414,
        price: 30447,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4966 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4967,
        artnumber: '62377',
        barcode: '74301',
        quantity: 382,
        price: 34882,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4967 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4968,
        artnumber: '84923',
        barcode: '77940',
        quantity: 271,
        price: 14291,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4968 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4969,
        artnumber: '11560',
        barcode: '35420',
        quantity: 225,
        price: 36150,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4969 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4970,
        artnumber: '37289',
        barcode: '88204',
        quantity: 22,
        price: 42890,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4970 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4971,
        artnumber: '41778',
        barcode: '80828',
        quantity: 388,
        price: 10125,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4971 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4972,
        artnumber: '68415',
        barcode: '24831',
        quantity: 402,
        price: 33457,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4972 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4973,
        artnumber: '20639',
        barcode: '34438',
        quantity: 420,
        price: 39348,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4973 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4974,
        artnumber: '70235',
        barcode: '20392',
        quantity: 235,
        price: 41172,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4974 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4975,
        artnumber: '62120',
        barcode: '68511',
        quantity: 342,
        price: 39081,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4975 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4976,
        artnumber: '34720',
        barcode: '74202',
        quantity: 311,
        price: 15902,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4976 для Красногорск, Здоровье, Сервис, Бета',
    },
    {
        id: 4977,
        artnumber: '27063',
        barcode: '31500',
        quantity: 409,
        price: 34959,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4977 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4978,
        artnumber: '37563',
        barcode: '21809',
        quantity: 139,
        price: 11603,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4978 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4979,
        artnumber: '25415',
        barcode: '44262',
        quantity: 146,
        price: 17093,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4979 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4980,
        artnumber: '52399',
        barcode: '75266',
        quantity: 390,
        price: 45535,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4980 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4981,
        artnumber: '52414',
        barcode: '82031',
        quantity: 264,
        price: 35863,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4981 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4982,
        artnumber: '50960',
        barcode: '45800',
        quantity: 251,
        price: 37616,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4982 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4983,
        artnumber: '85831',
        barcode: '40216',
        quantity: 40,
        price: 33809,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 4983 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4984,
        artnumber: '77053',
        barcode: '54581',
        quantity: 139,
        price: 37435,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4984 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4985,
        artnumber: '25235',
        barcode: '65847',
        quantity: 155,
        price: 31209,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4985 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4986,
        artnumber: '17685',
        barcode: '89630',
        quantity: 35,
        price: 34170,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 4986 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4987,
        artnumber: '62729',
        barcode: '94080',
        quantity: 40,
        price: 38257,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4987 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4988,
        artnumber: '77416',
        barcode: '61471',
        quantity: 252,
        price: 44277,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4988 для Красногорск, Здоровье, Сервис, Гамма',
    },
    {
        id: 4989,
        artnumber: '27616',
        barcode: '13063',
        quantity: 16,
        price: 46163,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4989 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 4990,
        artnumber: '72403',
        barcode: '44464',
        quantity: 237,
        price: 7112,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4990 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 4991,
        artnumber: '50854',
        barcode: '98749',
        quantity: 469,
        price: 33673,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4991 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 4992,
        artnumber: '14108',
        barcode: '35328',
        quantity: 360,
        price: 41583,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4992 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 4993,
        artnumber: '67412',
        barcode: '85877',
        quantity: 68,
        price: 1137,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 4993 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 4994,
        artnumber: '47625',
        barcode: '42095',
        quantity: 389,
        price: 43571,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 4994 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 4995,
        artnumber: '69566',
        barcode: '80615',
        quantity: 281,
        price: 6182,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4995 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 4996,
        artnumber: '12490',
        barcode: '37633',
        quantity: 162,
        price: 16831,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 4996 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 4997,
        artnumber: '49526',
        barcode: '77191',
        quantity: 261,
        price: 45080,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 4997 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 4998,
        artnumber: '74203',
        barcode: '76244',
        quantity: 102,
        price: 19069,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 4998 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 4999,
        artnumber: '29763',
        barcode: '19857',
        quantity: 208,
        price: 46762,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 4999 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 5000,
        artnumber: '20083',
        barcode: '78571',
        quantity: 278,
        price: 47862,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5000 для Красногорск, Здоровье, Сервис, Трио',
    },
    {
        id: 5001,
        artnumber: '88627',
        barcode: '51361',
        quantity: 134,
        price: 8000,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5001 для Красногорск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 5002,
        artnumber: '98577',
        barcode: '11157',
        quantity: 437,
        price: 25351,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5002 для Красногорск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 5003,
        artnumber: '24414',
        barcode: '99289',
        quantity: 150,
        price: 41698,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5003 для Красногорск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 5004,
        artnumber: '65566',
        barcode: '31599',
        quantity: 117,
        price: 17892,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5004 для Красногорск, Здоровье, Запчасти, Альфа',
    },
    {
        id: 5005,
        artnumber: '25898',
        barcode: '46184',
        quantity: 166,
        price: 20127,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5005 для Красногорск, Здоровье, Запчасти, Строй',
    },
    {
        id: 5006,
        artnumber: '55464',
        barcode: '84610',
        quantity: 70,
        price: 4153,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5006 для Красногорск, Здоровье, Запчасти, Строй',
    },
    {
        id: 5007,
        artnumber: '57987',
        barcode: '90145',
        quantity: 148,
        price: 16749,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5007 для Красногорск, Здоровье, Запчасти, Строй',
    },
    {
        id: 5008,
        artnumber: '61173',
        barcode: '98845',
        quantity: 103,
        price: 49466,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5008 для Красногорск, Здоровье, Запчасти, Строй',
    },
    {
        id: 5009,
        artnumber: '75866',
        barcode: '37984',
        quantity: 398,
        price: 24367,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5009 для Красногорск, Здоровье, Запчасти, Бета',
    },
    {
        id: 5010,
        artnumber: '93824',
        barcode: '34409',
        quantity: 368,
        price: 13321,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5010 для Красногорск, Здоровье, Запчасти, Бета',
    },
    {
        id: 5011,
        artnumber: '24479',
        barcode: '41103',
        quantity: 90,
        price: 5033,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5011 для Красногорск, Здоровье, Запчасти, Бета',
    },
    {
        id: 5012,
        artnumber: '29175',
        barcode: '94639',
        quantity: 412,
        price: 10834,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5012 для Красногорск, Здоровье, Запчасти, Бета',
    },
    {
        id: 5013,
        artnumber: '59957',
        barcode: '10141',
        quantity: 134,
        price: 48021,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5013 для Красногорск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 5014,
        artnumber: '16042',
        barcode: '38253',
        quantity: 334,
        price: 8393,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5014 для Красногорск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 5015,
        artnumber: '48258',
        barcode: '61393',
        quantity: 69,
        price: 38718,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5015 для Красногорск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 5016,
        artnumber: '35149',
        barcode: '42192',
        quantity: 475,
        price: 41152,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5016 для Красногорск, Здоровье, Запчасти, Гамма',
    },
    {
        id: 5017,
        artnumber: '36206',
        barcode: '15829',
        quantity: 109,
        price: 35890,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5017 для Красногорск, Здоровье, Запчасти, Трио',
    },
    {
        id: 5018,
        artnumber: '33551',
        barcode: '67460',
        quantity: 138,
        price: 41981,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5018 для Красногорск, Здоровье, Запчасти, Трио',
    },
    {
        id: 5019,
        artnumber: '69357',
        barcode: '10202',
        quantity: 117,
        price: 30201,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5019 для Красногорск, Здоровье, Запчасти, Трио',
    },
    {
        id: 5020,
        artnumber: '83581',
        barcode: '11617',
        quantity: 312,
        price: 45387,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5020 для Красногорск, Здоровье, Запчасти, Трио',
    },
    {
        id: 5021,
        artnumber: '11544',
        barcode: '37855',
        quantity: 245,
        price: 34022,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5021 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5022,
        artnumber: '80194',
        barcode: '52574',
        quantity: 459,
        price: 30693,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5022 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5023,
        artnumber: '56081',
        barcode: '52091',
        quantity: 451,
        price: 979,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5023 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5024,
        artnumber: '51101',
        barcode: '34471',
        quantity: 432,
        price: 43309,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5024 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5025,
        artnumber: '30860',
        barcode: '92864',
        quantity: 160,
        price: 4449,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5025 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5026,
        artnumber: '48004',
        barcode: '37155',
        quantity: 346,
        price: 14675,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5026 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5027,
        artnumber: '63015',
        barcode: '77479',
        quantity: 86,
        price: 33653,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5027 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5028,
        artnumber: '45152',
        barcode: '19607',
        quantity: 151,
        price: 45006,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5028 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5029,
        artnumber: '63289',
        barcode: '29344',
        quantity: 437,
        price: 49030,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5029 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5030,
        artnumber: '78802',
        barcode: '80778',
        quantity: 368,
        price: 43311,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5030 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5031,
        artnumber: '28974',
        barcode: '34083',
        quantity: 157,
        price: 39879,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5031 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5032,
        artnumber: '57436',
        barcode: '75709',
        quantity: 452,
        price: 32135,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5032 для Красногорск, Здоровье, Доставка, Альфа',
    },
    {
        id: 5033,
        artnumber: '79930',
        barcode: '67030',
        quantity: 86,
        price: 47706,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5033 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5034,
        artnumber: '45341',
        barcode: '28630',
        quantity: 341,
        price: 32234,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5034 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5035,
        artnumber: '40271',
        barcode: '35123',
        quantity: 17,
        price: 8381,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5035 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5036,
        artnumber: '85877',
        barcode: '47196',
        quantity: 109,
        price: 16563,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5036 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5037,
        artnumber: '92661',
        barcode: '10406',
        quantity: 474,
        price: 20257,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5037 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5038,
        artnumber: '82705',
        barcode: '74927',
        quantity: 200,
        price: 22984,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5038 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5039,
        artnumber: '48964',
        barcode: '74893',
        quantity: 418,
        price: 23005,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5039 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5040,
        artnumber: '44405',
        barcode: '59608',
        quantity: 447,
        price: 2497,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5040 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5041,
        artnumber: '12895',
        barcode: '58859',
        quantity: 488,
        price: 1300,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5041 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5042,
        artnumber: '84353',
        barcode: '22938',
        quantity: 35,
        price: 11624,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5042 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5043,
        artnumber: '42786',
        barcode: '81027',
        quantity: 38,
        price: 2662,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5043 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5044,
        artnumber: '33973',
        barcode: '71620',
        quantity: 9,
        price: 46067,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5044 для Красногорск, Здоровье, Доставка, Строй',
    },
    {
        id: 5045,
        artnumber: '76790',
        barcode: '12940',
        quantity: 69,
        price: 17332,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5045 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5046,
        artnumber: '66589',
        barcode: '86257',
        quantity: 144,
        price: 35978,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5046 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5047,
        artnumber: '91368',
        barcode: '43663',
        quantity: 62,
        price: 29970,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5047 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5048,
        artnumber: '80369',
        barcode: '68649',
        quantity: 126,
        price: 8811,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5048 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5049,
        artnumber: '83856',
        barcode: '21296',
        quantity: 17,
        price: 1722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5049 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5050,
        artnumber: '48445',
        barcode: '21363',
        quantity: 332,
        price: 45858,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5050 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5051,
        artnumber: '21528',
        barcode: '85519',
        quantity: 477,
        price: 26470,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5051 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5052,
        artnumber: '76064',
        barcode: '66402',
        quantity: 147,
        price: 42830,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5052 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5053,
        artnumber: '36250',
        barcode: '51185',
        quantity: 49,
        price: 2685,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5053 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5054,
        artnumber: '52974',
        barcode: '25142',
        quantity: 304,
        price: 19698,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5054 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5055,
        artnumber: '35611',
        barcode: '79856',
        quantity: 183,
        price: 49922,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5055 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5056,
        artnumber: '62090',
        barcode: '53240',
        quantity: 397,
        price: 47837,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5056 для Красногорск, Здоровье, Доставка, Бета',
    },
    {
        id: 5057,
        artnumber: '83555',
        barcode: '68813',
        quantity: 232,
        price: 43174,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5057 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5058,
        artnumber: '50623',
        barcode: '43436',
        quantity: 375,
        price: 17830,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5058 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5059,
        artnumber: '81608',
        barcode: '31359',
        quantity: 27,
        price: 39787,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5059 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5060,
        artnumber: '97917',
        barcode: '98129',
        quantity: 477,
        price: 13837,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5060 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5061,
        artnumber: '92845',
        barcode: '17404',
        quantity: 285,
        price: 32661,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5061 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5062,
        artnumber: '33890',
        barcode: '18169',
        quantity: 196,
        price: 34122,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5062 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5063,
        artnumber: '36530',
        barcode: '47376',
        quantity: 363,
        price: 14451,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5063 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5064,
        artnumber: '46087',
        barcode: '16512',
        quantity: 128,
        price: 47096,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5064 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5065,
        artnumber: '40310',
        barcode: '90037',
        quantity: 456,
        price: 25589,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5065 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5066,
        artnumber: '39886',
        barcode: '52128',
        quantity: 416,
        price: 45759,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5066 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5067,
        artnumber: '68973',
        barcode: '47515',
        quantity: 107,
        price: 11473,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5067 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5068,
        artnumber: '96175',
        barcode: '32097',
        quantity: 101,
        price: 7849,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5068 для Красногорск, Здоровье, Доставка, Гамма',
    },
    {
        id: 5069,
        artnumber: '93356',
        barcode: '57682',
        quantity: 212,
        price: 25897,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5069 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5070,
        artnumber: '82423',
        barcode: '94950',
        quantity: 467,
        price: 15308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5070 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5071,
        artnumber: '64158',
        barcode: '59737',
        quantity: 7,
        price: 29388,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5071 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5072,
        artnumber: '10002',
        barcode: '87697',
        quantity: 486,
        price: 10106,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5072 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5073,
        artnumber: '84599',
        barcode: '80130',
        quantity: 232,
        price: 34169,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5073 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5074,
        artnumber: '33591',
        barcode: '72568',
        quantity: 173,
        price: 36525,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5074 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5075,
        artnumber: '81420',
        barcode: '82251',
        quantity: 10,
        price: 2964,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5075 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5076,
        artnumber: '75184',
        barcode: '58265',
        quantity: 468,
        price: 25750,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5076 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5077,
        artnumber: '30345',
        barcode: '57366',
        quantity: 360,
        price: 26642,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5077 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5078,
        artnumber: '83880',
        barcode: '28664',
        quantity: 176,
        price: 759,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5078 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5079,
        artnumber: '15865',
        barcode: '87207',
        quantity: 407,
        price: 17500,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5079 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5080,
        artnumber: '28816',
        barcode: '76359',
        quantity: 384,
        price: 30764,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5080 для Красногорск, Здоровье, Доставка, Трио',
    },
    {
        id: 5081,
        artnumber: '84931',
        barcode: '15331',
        quantity: 52,
        price: 9483,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5081 для Красногорск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 5082,
        artnumber: '96146',
        barcode: '43358',
        quantity: 72,
        price: 34086,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5082 для Красногорск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 5083,
        artnumber: '76856',
        barcode: '84908',
        quantity: 173,
        price: 47457,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5083 для Красногорск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 5084,
        artnumber: '53933',
        barcode: '60663',
        quantity: 324,
        price: 29632,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5084 для Красногорск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 5085,
        artnumber: '94921',
        barcode: '15163',
        quantity: 412,
        price: 2454,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5085 для Красногорск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 5086,
        artnumber: '44681',
        barcode: '60634',
        quantity: 11,
        price: 16398,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5086 для Красногорск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 5087,
        artnumber: '62957',
        barcode: '19523',
        quantity: 238,
        price: 18163,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5087 для Красногорск, Здоровье, Шиномонтаж, Альфа',
    },
    {
        id: 5088,
        artnumber: '86278',
        barcode: '99345',
        quantity: 394,
        price: 8098,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5088 для Красногорск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 5089,
        artnumber: '41920',
        barcode: '85523',
        quantity: 322,
        price: 17684,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5089 для Красногорск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 5090,
        artnumber: '95405',
        barcode: '72245',
        quantity: 493,
        price: 49885,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5090 для Красногорск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 5091,
        artnumber: '56834',
        barcode: '79821',
        quantity: 367,
        price: 2916,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5091 для Красногорск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 5092,
        artnumber: '88675',
        barcode: '63243',
        quantity: 467,
        price: 14818,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5092 для Красногорск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 5093,
        artnumber: '71187',
        barcode: '21346',
        quantity: 194,
        price: 20781,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5093 для Красногорск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 5094,
        artnumber: '34419',
        barcode: '64429',
        quantity: 314,
        price: 16920,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5094 для Красногорск, Здоровье, Шиномонтаж, Строй',
    },
    {
        id: 5095,
        artnumber: '44794',
        barcode: '45025',
        quantity: 413,
        price: 2680,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5095 для Красногорск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 5096,
        artnumber: '64776',
        barcode: '90327',
        quantity: 29,
        price: 5167,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5096 для Красногорск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 5097,
        artnumber: '78592',
        barcode: '50304',
        quantity: 226,
        price: 19495,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5097 для Красногорск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 5098,
        artnumber: '22883',
        barcode: '42462',
        quantity: 434,
        price: 5617,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5098 для Красногорск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 5099,
        artnumber: '23918',
        barcode: '81359',
        quantity: 254,
        price: 4322,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5099 для Красногорск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 5100,
        artnumber: '52705',
        barcode: '27437',
        quantity: 246,
        price: 40998,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5100 для Красногорск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 5101,
        artnumber: '49401',
        barcode: '48042',
        quantity: 93,
        price: 40399,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5101 для Красногорск, Здоровье, Шиномонтаж, Бета',
    },
    {
        id: 5102,
        artnumber: '69219',
        barcode: '94396',
        quantity: 109,
        price: 28250,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5102 для Красногорск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 5103,
        artnumber: '57260',
        barcode: '50812',
        quantity: 440,
        price: 36384,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5103 для Красногорск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 5104,
        artnumber: '38496',
        barcode: '63816',
        quantity: 330,
        price: 9659,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5104 для Красногорск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 5105,
        artnumber: '49835',
        barcode: '57013',
        quantity: 111,
        price: 45058,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5105 для Красногорск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 5106,
        artnumber: '41497',
        barcode: '32603',
        quantity: 496,
        price: 40820,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5106 для Красногорск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 5107,
        artnumber: '14894',
        barcode: '73406',
        quantity: 238,
        price: 41781,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5107 для Красногорск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 5108,
        artnumber: '66387',
        barcode: '66125',
        quantity: 391,
        price: 32613,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5108 для Красногорск, Здоровье, Шиномонтаж, Гамма',
    },
    {
        id: 5109,
        artnumber: '81631',
        barcode: '11932',
        quantity: 1,
        price: 13686,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5109 для Красногорск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 5110,
        artnumber: '46417',
        barcode: '22733',
        quantity: 155,
        price: 31046,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5110 для Красногорск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 5111,
        artnumber: '46536',
        barcode: '83611',
        quantity: 488,
        price: 14246,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5111 для Красногорск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 5112,
        artnumber: '65876',
        barcode: '28400',
        quantity: 333,
        price: 11574,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5112 для Красногорск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 5113,
        artnumber: '53271',
        barcode: '89670',
        quantity: 387,
        price: 16776,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5113 для Красногорск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 5114,
        artnumber: '73171',
        barcode: '20033',
        quantity: 346,
        price: 21035,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5114 для Красногорск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 5115,
        artnumber: '43718',
        barcode: '61019',
        quantity: 205,
        price: 11667,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5115 для Красногорск, Здоровье, Шиномонтаж, Трио',
    },
    {
        id: 5116,
        artnumber: '74819',
        barcode: '30627',
        quantity: 490,
        price: 19189,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5116 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5117,
        artnumber: '92002',
        barcode: '83471',
        quantity: 430,
        price: 25231,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5117 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5118,
        artnumber: '23658',
        barcode: '75299',
        quantity: 474,
        price: 10463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5118 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5119,
        artnumber: '45750',
        barcode: '77567',
        quantity: 399,
        price: 11718,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5119 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5120,
        artnumber: '31156',
        barcode: '32482',
        quantity: 185,
        price: 26140,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5120 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5121,
        artnumber: '34483',
        barcode: '31482',
        quantity: 495,
        price: 26745,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5121 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5122,
        artnumber: '80962',
        barcode: '47238',
        quantity: 28,
        price: 49346,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5122 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5123,
        artnumber: '68128',
        barcode: '33279',
        quantity: 77,
        price: 33213,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5123 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5124,
        artnumber: '55491',
        barcode: '61724',
        quantity: 113,
        price: 20442,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5124 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5125,
        artnumber: '71564',
        barcode: '64896',
        quantity: 463,
        price: 1530,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5125 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5126,
        artnumber: '33612',
        barcode: '75815',
        quantity: 130,
        price: 13053,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5126 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5127,
        artnumber: '91184',
        barcode: '68349',
        quantity: 226,
        price: 12002,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5127 для Красногорск, Здоровье, Замена масла, Альфа',
    },
    {
        id: 5128,
        artnumber: '33790',
        barcode: '82388',
        quantity: 56,
        price: 8106,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5128 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5129,
        artnumber: '50353',
        barcode: '23935',
        quantity: 30,
        price: 29904,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5129 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5130,
        artnumber: '20906',
        barcode: '66750',
        quantity: 188,
        price: 44271,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5130 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5131,
        artnumber: '99514',
        barcode: '36477',
        quantity: 115,
        price: 24223,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5131 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5132,
        artnumber: '80508',
        barcode: '56970',
        quantity: 16,
        price: 46872,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5132 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5133,
        artnumber: '25159',
        barcode: '43974',
        quantity: 487,
        price: 24497,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5133 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5134,
        artnumber: '90531',
        barcode: '18203',
        quantity: 345,
        price: 40284,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5134 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5135,
        artnumber: '76717',
        barcode: '27265',
        quantity: 79,
        price: 28842,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5135 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5136,
        artnumber: '42314',
        barcode: '93865',
        quantity: 106,
        price: 30579,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5136 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5137,
        artnumber: '63185',
        barcode: '94402',
        quantity: 162,
        price: 22987,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5137 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5138,
        artnumber: '36321',
        barcode: '45146',
        quantity: 495,
        price: 34087,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5138 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5139,
        artnumber: '84152',
        barcode: '66991',
        quantity: 245,
        price: 21363,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5139 для Красногорск, Здоровье, Замена масла, Строй',
    },
    {
        id: 5140,
        artnumber: '57821',
        barcode: '90198',
        quantity: 7,
        price: 47257,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5140 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5141,
        artnumber: '16711',
        barcode: '15951',
        quantity: 384,
        price: 6711,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5141 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5142,
        artnumber: '17324',
        barcode: '75346',
        quantity: 77,
        price: 6083,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5142 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5143,
        artnumber: '67580',
        barcode: '15018',
        quantity: 431,
        price: 48006,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5143 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5144,
        artnumber: '18606',
        barcode: '64157',
        quantity: 432,
        price: 34412,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5144 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5145,
        artnumber: '66075',
        barcode: '85417',
        quantity: 101,
        price: 1649,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5145 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5146,
        artnumber: '56811',
        barcode: '45346',
        quantity: 103,
        price: 20710,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5146 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5147,
        artnumber: '32386',
        barcode: '79639',
        quantity: 422,
        price: 31266,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5147 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5148,
        artnumber: '28197',
        barcode: '82495',
        quantity: 149,
        price: 37753,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5148 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5149,
        artnumber: '45364',
        barcode: '11778',
        quantity: 292,
        price: 34857,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5149 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5150,
        artnumber: '38941',
        barcode: '54902',
        quantity: 153,
        price: 16610,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5150 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5151,
        artnumber: '86079',
        barcode: '97095',
        quantity: 51,
        price: 29869,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5151 для Красногорск, Здоровье, Замена масла, Бета',
    },
    {
        id: 5152,
        artnumber: '42383',
        barcode: '13737',
        quantity: 344,
        price: 36985,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5152 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5153,
        artnumber: '78416',
        barcode: '78178',
        quantity: 103,
        price: 48127,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5153 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5154,
        artnumber: '47825',
        barcode: '44441',
        quantity: 48,
        price: 1095,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5154 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5155,
        artnumber: '42626',
        barcode: '75168',
        quantity: 149,
        price: 11284,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5155 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5156,
        artnumber: '56316',
        barcode: '91923',
        quantity: 21,
        price: 34267,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5156 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5157,
        artnumber: '90952',
        barcode: '85433',
        quantity: 415,
        price: 19226,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5157 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5158,
        artnumber: '80270',
        barcode: '53383',
        quantity: 184,
        price: 45125,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5158 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5159,
        artnumber: '13492',
        barcode: '10234',
        quantity: 47,
        price: 49499,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5159 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5160,
        artnumber: '37536',
        barcode: '87011',
        quantity: 446,
        price: 3722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5160 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5161,
        artnumber: '14152',
        barcode: '96406',
        quantity: 270,
        price: 15866,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5161 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5162,
        artnumber: '37737',
        barcode: '89261',
        quantity: 478,
        price: 40391,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5162 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5163,
        artnumber: '91711',
        barcode: '88362',
        quantity: 332,
        price: 3488,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5163 для Красногорск, Здоровье, Замена масла, Гамма',
    },
    {
        id: 5164,
        artnumber: '18748',
        barcode: '68918',
        quantity: 128,
        price: 32299,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5164 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5165,
        artnumber: '67607',
        barcode: '47137',
        quantity: 485,
        price: 752,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5165 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5166,
        artnumber: '20310',
        barcode: '82954',
        quantity: 228,
        price: 34860,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5166 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5167,
        artnumber: '90322',
        barcode: '58499',
        quantity: 399,
        price: 28474,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5167 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5168,
        artnumber: '25311',
        barcode: '86463',
        quantity: 74,
        price: 9602,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5168 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5169,
        artnumber: '42301',
        barcode: '16111',
        quantity: 429,
        price: 20063,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5169 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5170,
        artnumber: '40792',
        barcode: '13703',
        quantity: 381,
        price: 40636,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5170 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5171,
        artnumber: '21544',
        barcode: '83395',
        quantity: 203,
        price: 347,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5171 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5172,
        artnumber: '65416',
        barcode: '42110',
        quantity: 265,
        price: 27757,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5172 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5173,
        artnumber: '38365',
        barcode: '56346',
        quantity: 461,
        price: 8368,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5173 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5174,
        artnumber: '75702',
        barcode: '99355',
        quantity: 380,
        price: 11186,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5174 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5175,
        artnumber: '51533',
        barcode: '57897',
        quantity: 427,
        price: 28715,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 4,
            name: 'Здоровье',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5175 для Красногорск, Здоровье, Замена масла, Трио',
    },
    {
        id: 5176,
        artnumber: '19498',
        barcode: '84318',
        quantity: 275,
        price: 22273,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5176 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5177,
        artnumber: '31338',
        barcode: '21856',
        quantity: 60,
        price: 40958,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5177 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5178,
        artnumber: '47355',
        barcode: '15907',
        quantity: 231,
        price: 24061,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5178 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5179,
        artnumber: '98374',
        barcode: '56462',
        quantity: 137,
        price: 48854,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5179 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5180,
        artnumber: '36715',
        barcode: '28880',
        quantity: 3,
        price: 26141,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5180 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5181,
        artnumber: '21520',
        barcode: '35874',
        quantity: 344,
        price: 22212,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5181 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5182,
        artnumber: '61609',
        barcode: '15423',
        quantity: 94,
        price: 39310,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5182 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5183,
        artnumber: '92150',
        barcode: '93631',
        quantity: 220,
        price: 35341,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5183 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5184,
        artnumber: '90849',
        barcode: '77341',
        quantity: 343,
        price: 44954,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5184 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5185,
        artnumber: '54908',
        barcode: '49955',
        quantity: 282,
        price: 9037,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5185 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5186,
        artnumber: '63916',
        barcode: '23561',
        quantity: 497,
        price: 8716,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5186 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5187,
        artnumber: '95164',
        barcode: '87420',
        quantity: 485,
        price: 32335,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5187 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5188,
        artnumber: '16657',
        barcode: '57115',
        quantity: 332,
        price: 33731,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5188 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5189,
        artnumber: '73368',
        barcode: '54319',
        quantity: 376,
        price: 25240,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5189 для Красногорск, Техника, Сервис, Альфа',
    },
    {
        id: 5190,
        artnumber: '39493',
        barcode: '64202',
        quantity: 293,
        price: 32836,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5190 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5191,
        artnumber: '30005',
        barcode: '46727',
        quantity: 1,
        price: 33508,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5191 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5192,
        artnumber: '66346',
        barcode: '82064',
        quantity: 172,
        price: 34005,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5192 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5193,
        artnumber: '32890',
        barcode: '73604',
        quantity: 376,
        price: 47055,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5193 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5194,
        artnumber: '59215',
        barcode: '58389',
        quantity: 349,
        price: 9089,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5194 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5195,
        artnumber: '68023',
        barcode: '72654',
        quantity: 254,
        price: 10210,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5195 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5196,
        artnumber: '53235',
        barcode: '31146',
        quantity: 267,
        price: 38561,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5196 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5197,
        artnumber: '78037',
        barcode: '46296',
        quantity: 475,
        price: 18142,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5197 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5198,
        artnumber: '98824',
        barcode: '92992',
        quantity: 186,
        price: 37508,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5198 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5199,
        artnumber: '47948',
        barcode: '94983',
        quantity: 51,
        price: 34395,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5199 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5200,
        artnumber: '14851',
        barcode: '35854',
        quantity: 86,
        price: 32296,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5200 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5201,
        artnumber: '94754',
        barcode: '16841',
        quantity: 192,
        price: 49809,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5201 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5202,
        artnumber: '71009',
        barcode: '62361',
        quantity: 277,
        price: 48920,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5202 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5203,
        artnumber: '90926',
        barcode: '87270',
        quantity: 345,
        price: 33209,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5203 для Красногорск, Техника, Сервис, Строй',
    },
    {
        id: 5204,
        artnumber: '40747',
        barcode: '48343',
        quantity: 474,
        price: 49909,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5204 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5205,
        artnumber: '81087',
        barcode: '71418',
        quantity: 104,
        price: 15564,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5205 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5206,
        artnumber: '14916',
        barcode: '81455',
        quantity: 62,
        price: 12326,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5206 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5207,
        artnumber: '80019',
        barcode: '86449',
        quantity: 123,
        price: 43722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5207 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5208,
        artnumber: '67203',
        barcode: '70518',
        quantity: 105,
        price: 48502,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5208 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5209,
        artnumber: '84358',
        barcode: '51501',
        quantity: 177,
        price: 30435,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5209 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5210,
        artnumber: '63974',
        barcode: '76055',
        quantity: 268,
        price: 30300,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5210 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5211,
        artnumber: '51195',
        barcode: '32548',
        quantity: 252,
        price: 36889,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5211 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5212,
        artnumber: '86604',
        barcode: '98525',
        quantity: 114,
        price: 43754,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5212 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5213,
        artnumber: '97445',
        barcode: '57303',
        quantity: 279,
        price: 31533,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5213 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5214,
        artnumber: '15366',
        barcode: '37236',
        quantity: 171,
        price: 17245,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5214 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5215,
        artnumber: '84400',
        barcode: '14624',
        quantity: 42,
        price: 40411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5215 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5216,
        artnumber: '64581',
        barcode: '39692',
        quantity: 332,
        price: 32125,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5216 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5217,
        artnumber: '28057',
        barcode: '52222',
        quantity: 102,
        price: 49123,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5217 для Красногорск, Техника, Сервис, Бета',
    },
    {
        id: 5218,
        artnumber: '14657',
        barcode: '18417',
        quantity: 382,
        price: 10722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5218 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5219,
        artnumber: '50009',
        barcode: '27174',
        quantity: 489,
        price: 22733,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5219 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5220,
        artnumber: '16607',
        barcode: '27529',
        quantity: 244,
        price: 4337,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5220 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5221,
        artnumber: '47680',
        barcode: '33777',
        quantity: 3,
        price: 16001,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5221 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5222,
        artnumber: '37222',
        barcode: '68973',
        quantity: 305,
        price: 19111,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5222 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5223,
        artnumber: '41555',
        barcode: '12695',
        quantity: 179,
        price: 16316,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5223 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5224,
        artnumber: '37518',
        barcode: '25687',
        quantity: 274,
        price: 29185,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5224 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5225,
        artnumber: '12697',
        barcode: '92201',
        quantity: 357,
        price: 40416,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5225 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5226,
        artnumber: '96313',
        barcode: '82347',
        quantity: 140,
        price: 15120,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5226 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5227,
        artnumber: '42778',
        barcode: '72931',
        quantity: 234,
        price: 23509,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5227 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5228,
        artnumber: '31340',
        barcode: '45578',
        quantity: 171,
        price: 10415,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5228 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5229,
        artnumber: '90562',
        barcode: '71540',
        quantity: 46,
        price: 29942,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5229 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5230,
        artnumber: '61661',
        barcode: '93770',
        quantity: 460,
        price: 35281,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5230 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5231,
        artnumber: '96217',
        barcode: '51379',
        quantity: 52,
        price: 22606,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5231 для Красногорск, Техника, Сервис, Гамма',
    },
    {
        id: 5232,
        artnumber: '98538',
        barcode: '33756',
        quantity: 118,
        price: 8671,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5232 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5233,
        artnumber: '12993',
        barcode: '82055',
        quantity: 362,
        price: 351,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5233 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5234,
        artnumber: '74324',
        barcode: '83651',
        quantity: 500,
        price: 9437,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5234 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5235,
        artnumber: '61452',
        barcode: '33060',
        quantity: 321,
        price: 14234,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5235 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5236,
        artnumber: '16942',
        barcode: '37772',
        quantity: 123,
        price: 46701,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5236 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5237,
        artnumber: '28183',
        barcode: '56440',
        quantity: 271,
        price: 25185,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5237 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5238,
        artnumber: '26246',
        barcode: '93065',
        quantity: 335,
        price: 27441,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5238 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5239,
        artnumber: '26133',
        barcode: '49334',
        quantity: 68,
        price: 22571,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5239 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5240,
        artnumber: '80202',
        barcode: '81641',
        quantity: 83,
        price: 17795,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5240 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5241,
        artnumber: '28334',
        barcode: '85962',
        quantity: 360,
        price: 20664,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5241 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5242,
        artnumber: '55437',
        barcode: '62626',
        quantity: 172,
        price: 33878,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5242 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5243,
        artnumber: '26599',
        barcode: '57657',
        quantity: 334,
        price: 8436,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5243 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5244,
        artnumber: '59666',
        barcode: '94862',
        quantity: 16,
        price: 16282,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5244 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5245,
        artnumber: '82700',
        barcode: '71431',
        quantity: 38,
        price: 41577,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 1,
            name: 'Сервис',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5245 для Красногорск, Техника, Сервис, Трио',
    },
    {
        id: 5246,
        artnumber: '68087',
        barcode: '86025',
        quantity: 192,
        price: 37895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5246 для Красногорск, Техника, Запчасти, Альфа',
    },
    {
        id: 5247,
        artnumber: '42928',
        barcode: '77395',
        quantity: 101,
        price: 3316,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5247 для Красногорск, Техника, Запчасти, Альфа',
    },
    {
        id: 5248,
        artnumber: '44644',
        barcode: '73231',
        quantity: 311,
        price: 1963,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5248 для Красногорск, Техника, Запчасти, Альфа',
    },
    {
        id: 5249,
        artnumber: '96740',
        barcode: '95998',
        quantity: 329,
        price: 10272,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5249 для Красногорск, Техника, Запчасти, Альфа',
    },
    {
        id: 5250,
        artnumber: '42238',
        barcode: '21232',
        quantity: 179,
        price: 8651,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5250 для Красногорск, Техника, Запчасти, Альфа',
    },
    {
        id: 5251,
        artnumber: '46443',
        barcode: '51099',
        quantity: 171,
        price: 1013,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5251 для Красногорск, Техника, Запчасти, Альфа',
    },
    {
        id: 5252,
        artnumber: '93784',
        barcode: '11328',
        quantity: 109,
        price: 19276,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5252 для Красногорск, Техника, Запчасти, Альфа',
    },
    {
        id: 5253,
        artnumber: '73845',
        barcode: '89440',
        quantity: 402,
        price: 6336,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5253 для Красногорск, Техника, Запчасти, Альфа',
    },
    {
        id: 5254,
        artnumber: '42857',
        barcode: '80723',
        quantity: 104,
        price: 43987,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5254 для Красногорск, Техника, Запчасти, Альфа',
    },
    {
        id: 5255,
        artnumber: '69412',
        barcode: '94660',
        quantity: 5,
        price: 19076,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5255 для Красногорск, Техника, Запчасти, Альфа',
    },
    {
        id: 5256,
        artnumber: '17270',
        barcode: '17435',
        quantity: 315,
        price: 20587,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5256 для Красногорск, Техника, Запчасти, Строй',
    },
    {
        id: 5257,
        artnumber: '81680',
        barcode: '45465',
        quantity: 161,
        price: 15600,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5257 для Красногорск, Техника, Запчасти, Строй',
    },
    {
        id: 5258,
        artnumber: '98302',
        barcode: '80398',
        quantity: 481,
        price: 20888,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5258 для Красногорск, Техника, Запчасти, Строй',
    },
    {
        id: 5259,
        artnumber: '25723',
        barcode: '38977',
        quantity: 151,
        price: 28688,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5259 для Красногорск, Техника, Запчасти, Строй',
    },
    {
        id: 5260,
        artnumber: '91275',
        barcode: '68851',
        quantity: 482,
        price: 5144,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5260 для Красногорск, Техника, Запчасти, Строй',
    },
    {
        id: 5261,
        artnumber: '97496',
        barcode: '23732',
        quantity: 118,
        price: 26101,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5261 для Красногорск, Техника, Запчасти, Строй',
    },
    {
        id: 5262,
        artnumber: '11580',
        barcode: '75723',
        quantity: 173,
        price: 4958,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5262 для Красногорск, Техника, Запчасти, Строй',
    },
    {
        id: 5263,
        artnumber: '73712',
        barcode: '58307',
        quantity: 415,
        price: 8237,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5263 для Красногорск, Техника, Запчасти, Строй',
    },
    {
        id: 5264,
        artnumber: '87692',
        barcode: '90488',
        quantity: 113,
        price: 9111,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5264 для Красногорск, Техника, Запчасти, Строй',
    },
    {
        id: 5265,
        artnumber: '52588',
        barcode: '81616',
        quantity: 276,
        price: 385,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5265 для Красногорск, Техника, Запчасти, Строй',
    },
    {
        id: 5266,
        artnumber: '29435',
        barcode: '88086',
        quantity: 394,
        price: 31377,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5266 для Красногорск, Техника, Запчасти, Бета',
    },
    {
        id: 5267,
        artnumber: '60469',
        barcode: '25763',
        quantity: 408,
        price: 24698,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5267 для Красногорск, Техника, Запчасти, Бета',
    },
    {
        id: 5268,
        artnumber: '53474',
        barcode: '73548',
        quantity: 301,
        price: 2717,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5268 для Красногорск, Техника, Запчасти, Бета',
    },
    {
        id: 5269,
        artnumber: '47641',
        barcode: '81488',
        quantity: 230,
        price: 35742,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5269 для Красногорск, Техника, Запчасти, Бета',
    },
    {
        id: 5270,
        artnumber: '13831',
        barcode: '80354',
        quantity: 472,
        price: 37737,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5270 для Красногорск, Техника, Запчасти, Бета',
    },
    {
        id: 5271,
        artnumber: '56056',
        barcode: '20879',
        quantity: 180,
        price: 35334,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5271 для Красногорск, Техника, Запчасти, Бета',
    },
    {
        id: 5272,
        artnumber: '73960',
        barcode: '69855',
        quantity: 308,
        price: 5534,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5272 для Красногорск, Техника, Запчасти, Бета',
    },
    {
        id: 5273,
        artnumber: '58872',
        barcode: '34093',
        quantity: 152,
        price: 38416,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5273 для Красногорск, Техника, Запчасти, Бета',
    },
    {
        id: 5274,
        artnumber: '88810',
        barcode: '79076',
        quantity: 180,
        price: 39687,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5274 для Красногорск, Техника, Запчасти, Бета',
    },
    {
        id: 5275,
        artnumber: '95008',
        barcode: '43990',
        quantity: 212,
        price: 48936,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5275 для Красногорск, Техника, Запчасти, Бета',
    },
    {
        id: 5276,
        artnumber: '66709',
        barcode: '90077',
        quantity: 235,
        price: 5771,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5276 для Красногорск, Техника, Запчасти, Гамма',
    },
    {
        id: 5277,
        artnumber: '21938',
        barcode: '88328',
        quantity: 121,
        price: 25163,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5277 для Красногорск, Техника, Запчасти, Гамма',
    },
    {
        id: 5278,
        artnumber: '69694',
        barcode: '60606',
        quantity: 430,
        price: 30608,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5278 для Красногорск, Техника, Запчасти, Гамма',
    },
    {
        id: 5279,
        artnumber: '68324',
        barcode: '73696',
        quantity: 489,
        price: 14876,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5279 для Красногорск, Техника, Запчасти, Гамма',
    },
    {
        id: 5280,
        artnumber: '45956',
        barcode: '22997',
        quantity: 145,
        price: 14128,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5280 для Красногорск, Техника, Запчасти, Гамма',
    },
    {
        id: 5281,
        artnumber: '49246',
        barcode: '11259',
        quantity: 386,
        price: 27871,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5281 для Красногорск, Техника, Запчасти, Гамма',
    },
    {
        id: 5282,
        artnumber: '66257',
        barcode: '87456',
        quantity: 469,
        price: 7310,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5282 для Красногорск, Техника, Запчасти, Гамма',
    },
    {
        id: 5283,
        artnumber: '24900',
        barcode: '13392',
        quantity: 243,
        price: 11545,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5283 для Красногорск, Техника, Запчасти, Гамма',
    },
    {
        id: 5284,
        artnumber: '94343',
        barcode: '35985',
        quantity: 115,
        price: 16117,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5284 для Красногорск, Техника, Запчасти, Гамма',
    },
    {
        id: 5285,
        artnumber: '42935',
        barcode: '93693',
        quantity: 245,
        price: 16319,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5285 для Красногорск, Техника, Запчасти, Гамма',
    },
    {
        id: 5286,
        artnumber: '53799',
        barcode: '19179',
        quantity: 268,
        price: 35184,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5286 для Красногорск, Техника, Запчасти, Трио',
    },
    {
        id: 5287,
        artnumber: '46209',
        barcode: '60910',
        quantity: 284,
        price: 18146,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5287 для Красногорск, Техника, Запчасти, Трио',
    },
    {
        id: 5288,
        artnumber: '71878',
        barcode: '79946',
        quantity: 108,
        price: 6329,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5288 для Красногорск, Техника, Запчасти, Трио',
    },
    {
        id: 5289,
        artnumber: '99964',
        barcode: '97424',
        quantity: 91,
        price: 15052,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5289 для Красногорск, Техника, Запчасти, Трио',
    },
    {
        id: 5290,
        artnumber: '85945',
        barcode: '40148',
        quantity: 234,
        price: 36722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5290 для Красногорск, Техника, Запчасти, Трио',
    },
    {
        id: 5291,
        artnumber: '72334',
        barcode: '45210',
        quantity: 271,
        price: 8521,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5291 для Красногорск, Техника, Запчасти, Трио',
    },
    {
        id: 5292,
        artnumber: '84607',
        barcode: '82774',
        quantity: 117,
        price: 1303,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5292 для Красногорск, Техника, Запчасти, Трио',
    },
    {
        id: 5293,
        artnumber: '20966',
        barcode: '89379',
        quantity: 82,
        price: 2753,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5293 для Красногорск, Техника, Запчасти, Трио',
    },
    {
        id: 5294,
        artnumber: '61657',
        barcode: '86289',
        quantity: 169,
        price: 32979,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5294 для Красногорск, Техника, Запчасти, Трио',
    },
    {
        id: 5295,
        artnumber: '56522',
        barcode: '63997',
        quantity: 288,
        price: 33029,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 2,
            name: 'Запчасти',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5295 для Красногорск, Техника, Запчасти, Трио',
    },
    {
        id: 5296,
        artnumber: '77461',
        barcode: '79026',
        quantity: 69,
        price: 25463,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5296 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5297,
        artnumber: '34696',
        barcode: '61483',
        quantity: 97,
        price: 3585,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5297 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5298,
        artnumber: '49349',
        barcode: '66725',
        quantity: 338,
        price: 21611,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5298 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5299,
        artnumber: '91560',
        barcode: '90808',
        quantity: 499,
        price: 22621,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5299 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5300,
        artnumber: '78633',
        barcode: '67454',
        quantity: 496,
        price: 28953,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5300 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5301,
        artnumber: '74557',
        barcode: '88804',
        quantity: 493,
        price: 37955,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5301 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5302,
        artnumber: '25512',
        barcode: '71124',
        quantity: 302,
        price: 23503,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5302 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5303,
        artnumber: '96146',
        barcode: '15779',
        quantity: 72,
        price: 27204,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5303 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5304,
        artnumber: '92961',
        barcode: '62636',
        quantity: 395,
        price: 4095,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5304 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5305,
        artnumber: '46861',
        barcode: '81667',
        quantity: 108,
        price: 47393,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5305 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5306,
        artnumber: '10146',
        barcode: '46445',
        quantity: 58,
        price: 49803,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5306 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5307,
        artnumber: '58059',
        barcode: '39639',
        quantity: 211,
        price: 13440,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5307 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5308,
        artnumber: '86947',
        barcode: '15293',
        quantity: 187,
        price: 36294,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5308 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5309,
        artnumber: '66726',
        barcode: '28935',
        quantity: 411,
        price: 15496,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5309 для Красногорск, Техника, Доставка, Альфа',
    },
    {
        id: 5310,
        artnumber: '41490',
        barcode: '87291',
        quantity: 385,
        price: 22521,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5310 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5311,
        artnumber: '15138',
        barcode: '58513',
        quantity: 155,
        price: 13029,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5311 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5312,
        artnumber: '87072',
        barcode: '58215',
        quantity: 429,
        price: 38017,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5312 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5313,
        artnumber: '53149',
        barcode: '55143',
        quantity: 441,
        price: 8468,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5313 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5314,
        artnumber: '52300',
        barcode: '75554',
        quantity: 2,
        price: 3747,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5314 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5315,
        artnumber: '38537',
        barcode: '39551',
        quantity: 273,
        price: 16042,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5315 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5316,
        artnumber: '41261',
        barcode: '72671',
        quantity: 425,
        price: 45487,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5316 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5317,
        artnumber: '70173',
        barcode: '78656',
        quantity: 415,
        price: 2245,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5317 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5318,
        artnumber: '49233',
        barcode: '63479',
        quantity: 52,
        price: 31238,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5318 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5319,
        artnumber: '51311',
        barcode: '83060',
        quantity: 459,
        price: 22543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5319 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5320,
        artnumber: '46692',
        barcode: '39119',
        quantity: 250,
        price: 3274,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5320 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5321,
        artnumber: '16526',
        barcode: '83804',
        quantity: 247,
        price: 25248,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5321 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5322,
        artnumber: '98280',
        barcode: '97097',
        quantity: 157,
        price: 5764,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5322 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5323,
        artnumber: '80361',
        barcode: '64902',
        quantity: 474,
        price: 12019,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5323 для Красногорск, Техника, Доставка, Строй',
    },
    {
        id: 5324,
        artnumber: '84109',
        barcode: '75699',
        quantity: 132,
        price: 23284,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5324 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5325,
        artnumber: '87821',
        barcode: '88698',
        quantity: 414,
        price: 36314,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5325 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5326,
        artnumber: '71352',
        barcode: '80395',
        quantity: 407,
        price: 20320,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5326 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5327,
        artnumber: '57493',
        barcode: '80109',
        quantity: 56,
        price: 1939,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5327 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5328,
        artnumber: '20285',
        barcode: '96265',
        quantity: 114,
        price: 18705,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5328 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5329,
        artnumber: '39758',
        barcode: '88291',
        quantity: 227,
        price: 26252,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5329 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5330,
        artnumber: '68437',
        barcode: '28483',
        quantity: 84,
        price: 18934,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5330 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5331,
        artnumber: '99173',
        barcode: '59417',
        quantity: 238,
        price: 1148,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5331 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5332,
        artnumber: '87172',
        barcode: '22784',
        quantity: 345,
        price: 30461,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5332 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5333,
        artnumber: '55924',
        barcode: '27277',
        quantity: 489,
        price: 22288,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5333 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5334,
        artnumber: '17529',
        barcode: '94629',
        quantity: 454,
        price: 7455,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5334 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5335,
        artnumber: '26259',
        barcode: '35456',
        quantity: 390,
        price: 39995,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5335 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5336,
        artnumber: '65919',
        barcode: '29929',
        quantity: 413,
        price: 21602,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5336 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5337,
        artnumber: '24928',
        barcode: '41372',
        quantity: 389,
        price: 7387,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5337 для Красногорск, Техника, Доставка, Бета',
    },
    {
        id: 5338,
        artnumber: '64168',
        barcode: '69681',
        quantity: 209,
        price: 42547,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5338 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5339,
        artnumber: '53921',
        barcode: '66351',
        quantity: 405,
        price: 13403,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5339 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5340,
        artnumber: '21524',
        barcode: '25746',
        quantity: 63,
        price: 11687,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5340 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5341,
        artnumber: '99780',
        barcode: '47214',
        quantity: 286,
        price: 23157,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5341 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5342,
        artnumber: '22743',
        barcode: '23263',
        quantity: 161,
        price: 16008,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5342 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5343,
        artnumber: '73666',
        barcode: '69294',
        quantity: 493,
        price: 25540,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5343 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5344,
        artnumber: '32915',
        barcode: '70790',
        quantity: 330,
        price: 387,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5344 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5345,
        artnumber: '69231',
        barcode: '43908',
        quantity: 366,
        price: 45701,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5345 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5346,
        artnumber: '56560',
        barcode: '24793',
        quantity: 451,
        price: 9451,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5346 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5347,
        artnumber: '84523',
        barcode: '12215',
        quantity: 392,
        price: 44783,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5347 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5348,
        artnumber: '25385',
        barcode: '61755',
        quantity: 181,
        price: 23165,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5348 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5349,
        artnumber: '78443',
        barcode: '31690',
        quantity: 360,
        price: 25235,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5349 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5350,
        artnumber: '84011',
        barcode: '84540',
        quantity: 234,
        price: 2797,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5350 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5351,
        artnumber: '83692',
        barcode: '19075',
        quantity: 182,
        price: 6766,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5351 для Красногорск, Техника, Доставка, Гамма',
    },
    {
        id: 5352,
        artnumber: '34087',
        barcode: '42832',
        quantity: 165,
        price: 48579,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5352 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5353,
        artnumber: '28509',
        barcode: '38442',
        quantity: 295,
        price: 25576,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5353 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5354,
        artnumber: '27020',
        barcode: '99555',
        quantity: 425,
        price: 46419,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5354 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5355,
        artnumber: '62295',
        barcode: '83811',
        quantity: 85,
        price: 31587,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5355 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5356,
        artnumber: '20406',
        barcode: '86405',
        quantity: 173,
        price: 41034,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5356 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5357,
        artnumber: '29427',
        barcode: '81260',
        quantity: 82,
        price: 20696,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5357 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5358,
        artnumber: '94847',
        barcode: '92401',
        quantity: 69,
        price: 45411,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5358 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5359,
        artnumber: '39163',
        barcode: '28361',
        quantity: 182,
        price: 9484,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5359 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5360,
        artnumber: '44659',
        barcode: '60472',
        quantity: 157,
        price: 43895,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5360 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5361,
        artnumber: '96060',
        barcode: '79309',
        quantity: 131,
        price: 36778,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5361 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5362,
        artnumber: '97869',
        barcode: '98181',
        quantity: 427,
        price: 9853,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5362 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5363,
        artnumber: '74851',
        barcode: '25975',
        quantity: 152,
        price: 32074,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5363 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5364,
        artnumber: '76158',
        barcode: '85495',
        quantity: 137,
        price: 25937,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5364 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5365,
        artnumber: '87968',
        barcode: '81811',
        quantity: 274,
        price: 19734,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 3,
            name: 'Доставка',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5365 для Красногорск, Техника, Доставка, Трио',
    },
    {
        id: 5366,
        artnumber: '36237',
        barcode: '42278',
        quantity: 119,
        price: 25444,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5366 для Красногорск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 5367,
        artnumber: '57856',
        barcode: '44072',
        quantity: 276,
        price: 42153,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5367 для Красногорск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 5368,
        artnumber: '29696',
        barcode: '11895',
        quantity: 428,
        price: 4144,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5368 для Красногорск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 5369,
        artnumber: '82805',
        barcode: '14530',
        quantity: 256,
        price: 49518,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5369 для Красногорск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 5370,
        artnumber: '36475',
        barcode: '94996',
        quantity: 208,
        price: 13576,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5370 для Красногорск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 5371,
        artnumber: '86180',
        barcode: '71204',
        quantity: 157,
        price: 33966,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5371 для Красногорск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 5372,
        artnumber: '62796',
        barcode: '93485',
        quantity: 32,
        price: 4068,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5372 для Красногорск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 5373,
        artnumber: '48737',
        barcode: '20551',
        quantity: 193,
        price: 21155,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5373 для Красногорск, Техника, Шиномонтаж, Альфа',
    },
    {
        id: 5374,
        artnumber: '30592',
        barcode: '26041',
        quantity: 200,
        price: 31008,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5374 для Красногорск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 5375,
        artnumber: '13764',
        barcode: '81312',
        quantity: 404,
        price: 2940,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5375 для Красногорск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 5376,
        artnumber: '20122',
        barcode: '98473',
        quantity: 66,
        price: 17205,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5376 для Красногорск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 5377,
        artnumber: '84120',
        barcode: '51997',
        quantity: 180,
        price: 30723,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5377 для Красногорск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 5378,
        artnumber: '76664',
        barcode: '76854',
        quantity: 434,
        price: 25722,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5378 для Красногорск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 5379,
        artnumber: '34522',
        barcode: '56923',
        quantity: 231,
        price: 28510,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5379 для Красногорск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 5380,
        artnumber: '47160',
        barcode: '75556',
        quantity: 356,
        price: 29261,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5380 для Красногорск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 5381,
        artnumber: '96015',
        barcode: '28644',
        quantity: 173,
        price: 774,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5381 для Красногорск, Техника, Шиномонтаж, Строй',
    },
    {
        id: 5382,
        artnumber: '91581',
        barcode: '69178',
        quantity: 35,
        price: 2339,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5382 для Красногорск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 5383,
        artnumber: '19455',
        barcode: '40278',
        quantity: 289,
        price: 15732,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5383 для Красногорск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 5384,
        artnumber: '24814',
        barcode: '22142',
        quantity: 416,
        price: 18423,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5384 для Красногорск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 5385,
        artnumber: '70929',
        barcode: '78233',
        quantity: 386,
        price: 6968,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5385 для Красногорск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 5386,
        artnumber: '56611',
        barcode: '80262',
        quantity: 83,
        price: 45294,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5386 для Красногорск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 5387,
        artnumber: '17870',
        barcode: '65020',
        quantity: 334,
        price: 47199,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5387 для Красногорск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 5388,
        artnumber: '99537',
        barcode: '63212',
        quantity: 191,
        price: 31901,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5388 для Красногорск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 5389,
        artnumber: '99930',
        barcode: '18944',
        quantity: 310,
        price: 29397,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5389 для Красногорск, Техника, Шиномонтаж, Бета',
    },
    {
        id: 5390,
        artnumber: '62181',
        barcode: '37365',
        quantity: 198,
        price: 31613,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5390 для Красногорск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 5391,
        artnumber: '29982',
        barcode: '64034',
        quantity: 415,
        price: 27448,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5391 для Красногорск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 5392,
        artnumber: '99600',
        barcode: '77639',
        quantity: 346,
        price: 27090,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5392 для Красногорск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 5393,
        artnumber: '39231',
        barcode: '84582',
        quantity: 220,
        price: 48360,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5393 для Красногорск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 5394,
        artnumber: '84755',
        barcode: '92550',
        quantity: 227,
        price: 31257,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5394 для Красногорск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 5395,
        artnumber: '89320',
        barcode: '87844',
        quantity: 175,
        price: 28280,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5395 для Красногорск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 5396,
        artnumber: '19116',
        barcode: '11659',
        quantity: 300,
        price: 31308,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5396 для Красногорск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 5397,
        artnumber: '79001',
        barcode: '75785',
        quantity: 273,
        price: 3582,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5397 для Красногорск, Техника, Шиномонтаж, Гамма',
    },
    {
        id: 5398,
        artnumber: '22110',
        barcode: '78987',
        quantity: 195,
        price: 28908,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5398 для Красногорск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 5399,
        artnumber: '52878',
        barcode: '46544',
        quantity: 448,
        price: 7272,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5399 для Красногорск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 5400,
        artnumber: '56202',
        barcode: '41838',
        quantity: 148,
        price: 9131,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5400 для Красногорск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 5401,
        artnumber: '25552',
        barcode: '81035',
        quantity: 448,
        price: 18387,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5401 для Красногорск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 5402,
        artnumber: '23595',
        barcode: '46566',
        quantity: 218,
        price: 37543,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5402 для Красногорск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 5403,
        artnumber: '73384',
        barcode: '33369',
        quantity: 236,
        price: 9095,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5403 для Красногорск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 5404,
        artnumber: '13810',
        barcode: '66966',
        quantity: 474,
        price: 25909,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5404 для Красногорск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 5405,
        artnumber: '85169',
        barcode: '96431',
        quantity: 497,
        price: 7644,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 4,
            name: 'Шиномонтаж',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5405 для Красногорск, Техника, Шиномонтаж, Трио',
    },
    {
        id: 5406,
        artnumber: '88198',
        barcode: '67095',
        quantity: 64,
        price: 33849,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5406 для Красногорск, Техника, Замена масла, Альфа',
    },
    {
        id: 5407,
        artnumber: '79733',
        barcode: '50270',
        quantity: 232,
        price: 10323,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5407 для Красногорск, Техника, Замена масла, Альфа',
    },
    {
        id: 5408,
        artnumber: '25286',
        barcode: '50515',
        quantity: 253,
        price: 7549,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5408 для Красногорск, Техника, Замена масла, Альфа',
    },
    {
        id: 5409,
        artnumber: '62339',
        barcode: '65455',
        quantity: 143,
        price: 21146,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5409 для Красногорск, Техника, Замена масла, Альфа',
    },
    {
        id: 5410,
        artnumber: '89986',
        barcode: '94776',
        quantity: 110,
        price: 20184,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5410 для Красногорск, Техника, Замена масла, Альфа',
    },
    {
        id: 5411,
        artnumber: '94220',
        barcode: '38318',
        quantity: 121,
        price: 45026,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 6,
            name: 'Объекты заказов',
            code: 'order_objects',
        },
        name: 'Элемент 5411 для Красногорск, Техника, Замена масла, Альфа',
    },
    {
        id: 5412,
        artnumber: '63365',
        barcode: '83462',
        quantity: 376,
        price: 3526,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5412 для Красногорск, Техника, Замена масла, Альфа',
    },
    {
        id: 5413,
        artnumber: '24959',
        barcode: '67152',
        quantity: 377,
        price: 14073,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 1,
            name: 'Альфа',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5413 для Красногорск, Техника, Замена масла, Альфа',
    },
    {
        id: 5414,
        artnumber: '25661',
        barcode: '19790',
        quantity: 458,
        price: 42012,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5414 для Красногорск, Техника, Замена масла, Строй',
    },
    {
        id: 5415,
        artnumber: '66538',
        barcode: '77650',
        quantity: 245,
        price: 23137,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5415 для Красногорск, Техника, Замена масла, Строй',
    },
    {
        id: 5416,
        artnumber: '77053',
        barcode: '96125',
        quantity: 88,
        price: 14893,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5416 для Красногорск, Техника, Замена масла, Строй',
    },
    {
        id: 5417,
        artnumber: '21690',
        barcode: '16290',
        quantity: 192,
        price: 32268,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5417 для Красногорск, Техника, Замена масла, Строй',
    },
    {
        id: 5418,
        artnumber: '42577',
        barcode: '43842',
        quantity: 423,
        price: 5808,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5418 для Красногорск, Техника, Замена масла, Строй',
    },
    {
        id: 5419,
        artnumber: '80033',
        barcode: '80724',
        quantity: 96,
        price: 39267,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5419 для Красногорск, Техника, Замена масла, Строй',
    },
    {
        id: 5420,
        artnumber: '16065',
        barcode: '62798',
        quantity: 191,
        price: 28610,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5420 для Красногорск, Техника, Замена масла, Строй',
    },
    {
        id: 5421,
        artnumber: '13694',
        barcode: '80553',
        quantity: 311,
        price: 37711,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 2,
            name: 'Строй',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5421 для Красногорск, Техника, Замена масла, Строй',
    },
    {
        id: 5422,
        artnumber: '93123',
        barcode: '40929',
        quantity: 91,
        price: 45921,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5422 для Красногорск, Техника, Замена масла, Бета',
    },
    {
        id: 5423,
        artnumber: '49300',
        barcode: '24385',
        quantity: 18,
        price: 10272,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5423 для Красногорск, Техника, Замена масла, Бета',
    },
    {
        id: 5424,
        artnumber: '58002',
        barcode: '41203',
        quantity: 191,
        price: 13976,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5424 для Красногорск, Техника, Замена масла, Бета',
    },
    {
        id: 5425,
        artnumber: '12316',
        barcode: '37454',
        quantity: 496,
        price: 6564,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5425 для Красногорск, Техника, Замена масла, Бета',
    },
    {
        id: 5426,
        artnumber: '35500',
        barcode: '19759',
        quantity: 106,
        price: 33310,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5426 для Красногорск, Техника, Замена масла, Бета',
    },
    {
        id: 5427,
        artnumber: '65032',
        barcode: '57986',
        quantity: 58,
        price: 45409,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5427 для Красногорск, Техника, Замена масла, Бета',
    },
    {
        id: 5428,
        artnumber: '11084',
        barcode: '80758',
        quantity: 20,
        price: 9477,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5428 для Красногорск, Техника, Замена масла, Бета',
    },
    {
        id: 5429,
        artnumber: '92088',
        barcode: '40513',
        quantity: 123,
        price: 43240,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 3,
            name: 'Бета',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5429 для Красногорск, Техника, Замена масла, Бета',
    },
    {
        id: 5430,
        artnumber: '30321',
        barcode: '24027',
        quantity: 351,
        price: 3216,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5430 для Красногорск, Техника, Замена масла, Гамма',
    },
    {
        id: 5431,
        artnumber: '72532',
        barcode: '17820',
        quantity: 261,
        price: 39343,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5431 для Красногорск, Техника, Замена масла, Гамма',
    },
    {
        id: 5432,
        artnumber: '40143',
        barcode: '16817',
        quantity: 12,
        price: 26514,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 5,
            name: 'Пользователи',
            code: 'users',
        },
        name: 'Элемент 5432 для Красногорск, Техника, Замена масла, Гамма',
    },
    {
        id: 5433,
        artnumber: '34759',
        barcode: '50051',
        quantity: 127,
        price: 7319,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 3,
            name: 'Комбо',
            code: 'combo',
        },
        name: 'Элемент 5433 для Красногорск, Техника, Замена масла, Гамма',
    },
    {
        id: 5434,
        artnumber: '92055',
        barcode: '43604',
        quantity: 462,
        price: 36405,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5434 для Красногорск, Техника, Замена масла, Гамма',
    },
    {
        id: 5435,
        artnumber: '76021',
        barcode: '45508',
        quantity: 442,
        price: 44397,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5435 для Красногорск, Техника, Замена масла, Гамма',
    },
    {
        id: 5436,
        artnumber: '98040',
        barcode: '42167',
        quantity: 126,
        price: 40415,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5436 для Красногорск, Техника, Замена масла, Гамма',
    },
    {
        id: 5437,
        artnumber: '69134',
        barcode: '27618',
        quantity: 307,
        price: 38467,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 4,
            name: 'Гамма',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5437 для Красногорск, Техника, Замена масла, Гамма',
    },
    {
        id: 5438,
        artnumber: '30147',
        barcode: '89767',
        quantity: 36,
        price: 34017,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5438 для Красногорск, Техника, Замена масла, Трио',
    },
    {
        id: 5439,
        artnumber: '91512',
        barcode: '29017',
        quantity: 248,
        price: 47364,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 1,
            name: 'Услуги',
            code: 'services',
        },
        name: 'Элемент 5439 для Красногорск, Техника, Замена масла, Трио',
    },
    {
        id: 5440,
        artnumber: '47932',
        barcode: '35247',
        quantity: 449,
        price: 14756,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 9,
            name: 'Компании',
            code: 'companies',
        },
        name: 'Элемент 5440 для Красногорск, Техника, Замена масла, Трио',
    },
    {
        id: 5441,
        artnumber: '95760',
        barcode: '59268',
        quantity: 180,
        price: 40525,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5441 для Красногорск, Техника, Замена масла, Трио',
    },
    {
        id: 5442,
        artnumber: '49463',
        barcode: '55642',
        quantity: 238,
        price: 27383,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 2,
            name: 'Товары',
            code: 'products',
        },
        name: 'Элемент 5442 для Красногорск, Техника, Замена масла, Трио',
    },
    {
        id: 5443,
        artnumber: '94602',
        barcode: '72379',
        quantity: 411,
        price: 36202,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 8,
            name: 'Поставщики',
            code: 'suppliers',
        },
        name: 'Элемент 5443 для Красногорск, Техника, Замена масла, Трио',
    },
    {
        id: 5444,
        artnumber: '87185',
        barcode: '94882',
        quantity: 191,
        price: 48175,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 7,
            name: 'Рабочие места',
            code: 'workplaces',
        },
        name: 'Элемент 5444 для Красногорск, Техника, Замена масла, Трио',
    },
    {
        id: 5445,
        artnumber: '49275',
        barcode: '40810',
        quantity: 168,
        price: 17955,
        picture: '/images/products/no_image.svg',
        region: {
            id: 5,
            name: 'Красногорск',
        },
        categoryOfActivity: {
            id: 5,
            name: 'Техника',
        },
        subcategoryOfActivity: {
            id: 5,
            name: 'Замена масла',
        },
        company: {
            id: 5,
            name: 'Трио',
        },
        section: {
            id: 4,
            name: 'Задачи',
            code: 'tasks',
        },
        name: 'Элемент 5445 для Красногорск, Техника, Замена масла, Трио',
    },
];
