import { Col, Divider, Row } from 'antd';

import { SettingsManager } from '@classes/settingsManager';
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GeneralInfo = () => {
    const creds = SettingsManager.getConnectionCredentials();

    return (
        <>
            <Divider>Основная информация</Divider>

            <Row className="main-info">
                <Col className="outer-box">
                    <Row className="label-row">
                        <Col>
                            <p>
                                Должность{' '}
                                <FontAwesomeIcon
                                    style={{ color: '#AC7D0C', fontSize: '10px' }}
                                    icon={faStarOfLife}
                                />
                            </p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            {/* TODO: редактирование из выпадающего модалкой */}
                            <p>-</p>
                        </Col>
                    </Row>
                </Col>
                <Col className="outer-box">
                    <Row className="label-row">
                        <Col>
                            <p>Ограничения</p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            <button
                                disabled={creds.crmID == null}
                                onClick={() => {
                                    // setPermissionsPickerVisible(true);
                                }}
                            >
                                Настроить
                            </button>
                        </Col>
                    </Row>
                </Col>
                <Col className="outer-box">
                    <Row className="label-row">
                        <Col>
                            <p>
                                Группа{' '}
                                <FontAwesomeIcon
                                    style={{ color: '#AC7D0C', fontSize: '10px' }}
                                    icon={faStarOfLife}
                                />
                            </p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            <p>-</p>
                        </Col>
                    </Row>
                </Col>
                <Col className="outer-box">
                    <Row className="label-row" onClick={() => {}}>
                        <Col>
                            <p>
                                График работы{' '}
                                <FontAwesomeIcon
                                    style={{ color: '#AC7D0C', fontSize: '10px' }}
                                    icon={faStarOfLife}
                                />
                            </p>
                        </Col>
                    </Row>
                    <Row className="value-row" onClick={() => {}}>
                        <Col>
                            <p style={{ color: creds.crmID == null ? 'grey' : '#1890ff' }}>
                                5/2 9:00-18:00
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
