import { Subject } from "rxjs";
import { MessageBarTypes } from "@enums/messageBarTypes";
import { TNotification } from "../types/notification";

const subject = new Subject();

export const messageService = {
    sendMessage: (value: TNotification) => {
        subject.next(value)
    },
    sendError: (message: string) => {
        subject.next({ type: MessageBarTypes.error, text: message })
    },
    sendInfo: (message: string) => {
        subject.next({ type: MessageBarTypes.info, text: message })
    },
    sendSuccess: (message: string) => {
        subject.next({ type: MessageBarTypes.success, text: message })
    },
    clearMessages: () => subject.next(null),
    getMessage: () => subject.asObservable(),
    sendErrorList: (errors: any) => {
        try { 
            errors.forEach(err => messageService.sendError(err)); 
        }
        catch (e) {
            messageService.sendError(errors.message);
        }
    }
};