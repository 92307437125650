import { Col, Divider, Input, Row } from 'antd';

export const Earnings = ({ isEdit = false }) => {
    return (
        <>
            <Divider>Заработок</Divider>

            <Row className="income-info">
                <Col className="outer-box">
                    <Row className="label-row">
                        <Col>
                            <p>% с работы</p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            {isEdit ? (
                                <Input
                                    type="number"
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={0}
                                    suffix={<span>%</span>}
                                    onChange={(e) => console.log(e)}
                                />
                            ) : (
                                <p>{Math.floor(Math.random()) + '%'}</p>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col className="outer-box">
                    <Row className="label-row">
                        <Col>
                            <p>% с товара</p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            {isEdit ? (
                                <Input
                                    type="number"
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={0}
                                    suffix={<span>%</span>}
                                    onChange={(e) => console.log(e)}
                                />
                            ) : (
                                <p>{Math.floor(Math.random()) + '%'}</p>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col className="outer-box">
                    <Row className="label-row">
                        <Col>
                            <p>Ставка</p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            {isEdit ? (
                                <Input
                                    type="number"
                                    min={0}
                                    max={5000}
                                    step={1}
                                    value={0}
                                    onChange={(e) => console.log(e)}
                                />
                            ) : (
                                <p>{Math.floor(Math.random() * 3000) + 'р/день'}</p>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
