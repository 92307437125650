import { Button, Space, Typography } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    name: string;
    icon: any;
    background: string;
    onClick: () => void;
}

export const MessengerButton: React.FC<Props> = ({ background = '#ffff', icon, onClick, name }) => {
    return (
        <>
            <Space
                onClick={onClick}
                direction="vertical"
                align="center"
                size={'small'}
                style={{ cursor: 'pointer' }}
            >
                <Button
                    icon={<FontAwesomeIcon icon={icon} color="#ffff" />}
                    color={'blue'}
                    shape="circle"
                    size="large"
                    style={{
                        background,
                        color: 'unset',
                    }}
                />
                <Typography.Text>{name}</Typography.Text>
            </Space>
        </>
    );
};
