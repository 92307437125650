import { useState, useEffect, useRef } from 'react';

const useGetCardWidth = ({ cardRef }) => {
    const [width, setWidth] = useState(0);
    const prevWidthRef = useRef(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (!entries[0].contentRect) return;
            const newWidth = entries[0].contentRect.width;
            if (prevWidthRef.current === newWidth) return;
            prevWidthRef.current = newWidth;
            setWidth(newWidth);
        });
        if (cardRef.current) resizeObserver.observe(cardRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return width;
};

export default useGetCardWidth;
