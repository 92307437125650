import { filter, includes, isEmpty, map } from "lodash";

const getRecipientsString = (recipients) =>
    map(recipients, (rec) => {
        return `${rec.name} ${rec.surname}`;
    }).join(' ');

export const getSelectedMessagesString = (comments, selectedComments) => {
    const selected = filter(comments, ({ id }) => includes(selectedComments, id));
            return map(
                selected,
                (comment) =>
                    `Отправитель: ${comment.author.name}\nПолучатель: ${ !isEmpty(comment.repliedTo) ? getRecipientsString(comment.repliedTo): 'Общий чат'}\nТема: ${comment.topic ? comment.topic : 'Без темы'}\n${comment.body.text}`
            ).join('\n\n');
}