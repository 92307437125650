import { CRMAPIBase } from "@api/crmApiBase";
import { RequestResult } from "@api/responseModels/requestResult";
import { TaskListResp } from "@api/responseModels/task/taskListResponse";
import { TaskResp } from "@api/responseModels/task/taskResponse";
import { TTask } from "@components/tasks/common/types";
import { rootStore } from "@store/rootStore/instanse";
import { TaskParams } from "types/getParams";

class TaskGroup extends CRMAPIBase {
    /**
     * Добавление задачи
     * @param task Задача
     * @returns Результат запроса
     */
    async saveTask(task: TTask): Promise<RequestResult<TaskResp>> {
        const result = rootStore.taskStore.saveTask(task);
        return result;
    }

    /**
     * Получение задачи
     * @param crmId ID CRM
     * @param id ID задачи
     * @returns Результат запроса
     */
    async getTask(crmId: number, id: number): Promise<RequestResult<TaskResp>> {
        const result = rootStore.taskStore.getTask(crmId, id);
        return result;
    }

    /**
     * Получение списка задач
     * @param params Параметры запроса задач
     * @returns Результат запроса
     */
    async getTaskList(params: TaskParams, tab: string): Promise<RequestResult<TaskListResp>> {
        const result = rootStore.taskStore.getTaskList(params, tab);
        return result;
    }

    /**
     * Обновление задачи
     * @param task Задача
     * @returns Результат запроса
     */
    async updateTask(task: TTask): Promise<RequestResult<TaskResp>> {
        const result = rootStore.taskStore.updateTask(task);
        return result;
    }

    /**
     * Удаление задачи
     * @param id ID задачи
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeTask(id: number, crmId: number): Promise<RequestResult<TaskResp>> {
        const result = rootStore.taskStore.removeTask(crmId, id);
        return result;
    }

    /**
     * Восстановление задачи
     * @param id ID задачи
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreTask(id: number, crmId: number): Promise<RequestResult<TaskResp>> {
        const result = rootStore.taskStore.restoreTask(crmId, id);
        return result;
    }
}

export { TaskGroup };