import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Space, Tabs } from 'antd';
import { filter } from 'lodash';
import { observer } from 'mobx-react';

import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { HeaderListBreadcrumbs } from '@molecules/breadcrumbs/listsBreadcrumbs/HeaderListBreadcrumbs';
import { ServiceProductList } from '@molecules/renderList/serviceProductList';
import { SECTION_NAMES } from '@organisms/administrator/common/constants';
import { getDataResultList, getSearchValue } from '@organisms/administrator/common/redux/selectors';
import { AdminData } from '@organisms/administrator/common/types';
import { FilterStore } from '@organisms/productServicesFilter/filterStore';
import { ProductServicesFilter } from '@organisms/productServicesFilter/productServicesFilter';

import { AdminFilter } from '../adminFilter/adminFilter';
import { ResultsHeader } from '../results/blocks/resultsHeader';
import { OptionsMenu } from './blocks/optionsMenu';

const ITEMS_PER_PAGE = 20;

export const Section = observer(() => {
    const filterStore = new FilterStore();
    const { sectionCode } = useParams();

    const dataResultList: AdminData[] = useSelector(getDataResultList);
    const searchValue: string = useSelector(getSearchValue);

    const [currentPage, setCurrentPage] = useState(1);
    const [_, setTabNumber] = useState('1');
    const [loadedElems, setLoadedElems] = useState([]);

    const dataForSection: AdminData[] = useMemo(() => {
        return filter(dataResultList, (item) => item.section.code === sectionCode);
    }, [dataResultList]);

    function onChangeTab(key: string) {
        setTabNumber(key);
    }

    const handleNext = () => {
        setTimeout(() => {
            setCurrentPage(currentPage + 1);
            const slicedData = dataForSection.slice(
                currentPage * ITEMS_PER_PAGE,
                (currentPage + 1) * ITEMS_PER_PAGE
            );
            setLoadedElems([...loadedElems, ...slicedData]);
        }, 1000);
    };

    useEffect(() => {
        const slicedData = dataForSection.slice(0, ITEMS_PER_PAGE);

        setLoadedElems(slicedData);
        setCurrentPage(1);
    }, [JSON.stringify(dataForSection)]);

    return (
        <div id="app-product-list">
            <AdminFilter />
            <ResultsHeader quantity={dataForSection.length} />
            <HeaderListBreadcrumbs
                dataTotal={dataForSection.length}
                title={SECTION_NAMES[sectionCode]}
                dataTitle={SECTION_NAMES[sectionCode]}
                dataIcon={faBarcode}
                dataPrice={null}
                flag={true}
                isProduct={true}
                categoryPath={[]}
                openCategoryCard={() => {}}
                onSearch={() => {}}
                searchPlaceHolder="По товарам"
                isAdmin
                headless
            >
                <Space>
                    <ProductServicesFilter store={filterStore} />
                    <OptionsMenu />
                </Space>
            </HeaderListBreadcrumbs>
            <Tabs
                items={[
                    {
                        label: SECTION_NAMES[sectionCode],
                        key: '1',
                        children: (
                            <ServiceProductList
                                productCategoryList={[]}
                                serviceCategoryList={null}
                                productList={loadedElems}
                                serviceList={null}
                                selectCatList={[]}
                                selectElemList={[]}
                                currentCategoryPage={1}
                                currentCategoryMeta={null}
                                handleShowMoreCategories={null}
                                setSelectCatList={() => {}}
                                setSelectElemList={() => {}}
                                openCategoryCard={() => {}}
                                openCard={() => {}}
                                hideQuantityInProductCard
                                searchValue={searchValue}
                                infiniteScrollProps={{
                                    isActive: true,
                                    onNext: handleNext,
                                    totalItems: dataForSection.length,
                                    itemsName: SECTION_NAMES[sectionCode],
                                }}
                            />
                        ),
                    },
                    {
                        label: 'На модерацию',
                        key: '2',
                        children: null,
                    },
                    {
                        label: 'Аналитика',
                        key: '3',
                        children: null,
                    },
                ]}
                onChange={(key) => {
                    onChangeTab(key);
                }}
            />
        </div>
    );
});
