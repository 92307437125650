import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Space, Tabs } from 'antd';
import { observer } from 'mobx-react';
import { TMetadata } from 'types/metadata';
import { TUser } from 'types/user/user';

import { CustomerProfileResp } from '@api/responseModels/customer/customerProfileResponse';
import { Loader } from '@atoms/loader';
import { Common } from '@classes/commonMethods';
import { CRMAPIManager } from '@classes/crmApiManager';
import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import { GlobalConstants } from '@constants/global';
import { InvitationStatuses } from '@enums/invitationStatuses';
import {
    faPlus,
    faTrashCan,
    faTrashCanArrowUp,
    faUserTie,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetchDataList } from '@hooks/useFetchDataList';
import { OptionsMenu } from '@molecules/OptionsMenu';
import { HeaderListBreadcrumbs } from '@molecules/breadcrumbs/listsBreadcrumbs/HeaderListBreadcrumbs';
import { FunctionalMenu } from '@molecules/functionalMenu';
import { columnViewStore } from '@molecules/listViewChanger/listViewStore';
import { store as pStore } from '@molecules/paginationControls/pageSizeStore';
import { RenderUserList } from '@molecules/renderList/userList';
import { FilterStore } from '@organisms/workersFilter/filterStore';
import { WorkersFilter } from '@organisms/workersFilter/workersFilter';

import { debounce } from '../../../utils/functions';
import { CreateWorkerDialog } from '../dialogs/createWorkerDialog';
import { TAB_ROUTES, WORKPLACES } from './common/constants';
import { BrigadeList } from './components/brigadeList';
import { addBrigade } from './components/brigadeList/common/redux/brigadeListSlice';
import { Brigade } from './components/brigadeList/common/types';
import { CreateBrigadeModal } from './components/createBrigadeModal';
import { WorkplaceList } from './workplaceList';

const filterStore = new FilterStore();
const filter = filterStore.filter;

const WorkerList = observer((): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [workerList, setWorkerList] = useState<Array<TUser>>([]);
    const [selectList, setSelectList] = useState<
        Array<{ id: number; name: string; profileId: number }>
    >([]);
    const [inviteWorkerOpen, setInviteWorkerOpen] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentMeta, setCurrentMeta] = useState<TMetadata>(null);
    const [funcMenuItems, setFuncMenuItems] = useState([]);
    const [funcMenuDropdownItems, setFuncMenuDropdownItems] = useState([]);
    const [brigadeModalOpen, setBrigadeModalOpen] = useState(false);

    const tabNumber = useRef<string>('');

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { currentTab, groupId } = useParams();
    const { pathname: currentUri } = useLocation();

    const IS_GROUPS = currentTab === TAB_ROUTES.GROUPS;
    const IS_WORKPLACES = currentUri.split('/').at(-1) === WORKPLACES;

    const creds = SettingsManager.getConnectionCredentials();

    const { getDataList, isLoading: isWorkerListLoading } = useFetchDataList({
        filter,
        profile: 'worker',
        setCurrentMeta,
        setDataList: setWorkerList,
        getFnKey: 'getUserList',
    });

    async function handleChangePage() {
        getDataList(currentPage + 1).then(() => {
            setCurrentPage((prev) => prev + 1);
        });
    }

    async function deleteSelected() {
        setIsLoading(true);
        const creds = SettingsManager.getConnectionCredentials();
        while (selectList.length != 0) {
            const target = selectList.pop();
            try {
                const del = await CRMAPIManager.request<CustomerProfileResp>(async (api) => {
                    return await api.removeWorkerProfile(target.profileId, creds.crmID);
                });
                if (del.errorMessages) throw del.errorMessages;
            } catch (err) {
                messageService.sendError(target.name + ': ' + err.message);
            }
            setSelectList((oldList) => oldList.filter((sli) => sli != target));
        }
        await getDataList(1);
        setCurrentPage(1);
        setIsLoading(false);
    }

    async function restoreSelected() {
        setIsLoading(true);
        const creds = SettingsManager.getConnectionCredentials();
        while (selectList.length != 0) {
            const target = selectList.pop();
            try {
                const restored = await CRMAPIManager.request<CustomerProfileResp>(async (api) => {
                    return await api.restoreWorkerProfile(target.profileId, creds.crmID);
                });
                if (restored.errorMessages) throw restored.errorMessages;
            } catch (errors) {
                messageService.sendErrorList(errors);
            }
            setSelectList((oldList) => oldList.filter((sli) => sli != target));
        }
        await getDataList(1);
        setCurrentPage(1);
        setIsLoading(false);
    }

    const handleSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        filterStore.changeQuery(e.target.value);
    }, GlobalConstants.SearchTimeout);

    function beforeMountWorkerList() {
        Common.checkUserBelongToCrm(GlobalConstants.CrmOrderinUrl).then((res) => {
            if (!res) navigate(`/lk/worker/crm/${creds.crmID}`);
        });
        if (localStorage.getItem('invitationIsSent')) {
            localStorage.removeItem('invitationIsSent');
            messageService.sendSuccess('Приглашение отправлено');
        }
        getDataList(1);
        setCurrentPage(1);
    }

    useEffect(() => {
        filterStore.changeDeleted(currentTab === TAB_ROUTES.BASKET ? 'only' : 'null');
        tabNumber.current = currentTab;
    }, []);

    function onChangeTab(key: string) {
        if (key == TAB_ROUTES.BASKET) filterStore.changeDeleted('only');
        else {
            filterStore.changeDeleted('null');
        }
        navigate(`/lk/worker/crm/${creds.crmID}/workers/tabs/${key}`);
        setSelectList([]);
        tabNumber.current = key;
    }

    useEffect(() => {
        beforeMountWorkerList();
    }, [
        filter.sortBy,
        filter.sortDirection,
        filter.updatedDates,
        filter.createdDates,
        filter.query,
        filter.deleted,
    ]);

    useEffect(() => {
        if (currentMeta?.total == null || currentMeta?.total == 0) return;
        const newPage = Math.ceil(currentMeta.from / pStore.pS);

        getDataList(newPage).then(() => {
            setCurrentPage(newPage);
        });
    }, [pStore.pS]);

    const handleCreateBrigadeModalOk = (brigade: Brigade) => {
        dispatch(addBrigade(brigade));
        setBrigadeModalOpen(false);
    };

    useEffect(() => {
        if (selectList.length > 0 && !IS_GROUPS) {
            setFuncMenuItems([
                {
                    key: 'createElement',
                    label: 'Пригласить пользователя',
                    icon: <FontAwesomeIcon icon={faPlus} />,
                    onClick: () => {
                        setInviteWorkerOpen(true);
                    },
                },
                {
                    key: 'abort',
                    label: 'Отмена',
                    icon: <FontAwesomeIcon icon={faXmark} />,
                    onClick: () => {
                        setSelectList([]);
                    },
                },
            ]);

            setFuncMenuDropdownItems([
                {
                    key: tabNumber.current == TAB_ROUTES.BASKET ? 'restore' : 'delete',
                    label: tabNumber.current == TAB_ROUTES.BASKET ? 'Восстановить' : 'Удалить',
                    icon:
                        tabNumber.current == TAB_ROUTES.BASKET ? (
                            <FontAwesomeIcon icon={faTrashCanArrowUp} />
                        ) : (
                            <FontAwesomeIcon icon={faTrashCan} />
                        ),
                    onClick:
                        tabNumber.current == TAB_ROUTES.BASKET ? restoreSelected : deleteSelected,
                },
            ]);
        } else if (selectList.length === 0 && !IS_GROUPS) {
            setFuncMenuItems([
                {
                    key: 'createElement',
                    label: 'Пригласить пользователя',
                    icon: <FontAwesomeIcon icon={faPlus} />,
                    onClick: () => {
                        setInviteWorkerOpen(true);
                    },
                },
            ]);

            setFuncMenuDropdownItems([]);
        } else if (IS_GROUPS && !IS_WORKPLACES) {
            setFuncMenuItems([
                {
                    key: 'createElement',
                    label: 'Создать бригаду',
                    icon: <FontAwesomeIcon icon={faPlus} />,
                    onClick: () => {
                        setBrigadeModalOpen(true);
                    },
                },
            ]);

            setFuncMenuDropdownItems([]);
        } else {
            setFuncMenuItems([
                {
                    key: 'createElement',
                    label: 'Создать рабочее место',
                    icon: <FontAwesomeIcon icon={faPlus} />,
                    onClick: () => {
                        navigate(`${currentUri}/create`);
                    },
                },
            ]);
        }
    }, [selectList.length, tabNumber.current, IS_WORKPLACES, IS_GROUPS]);

    return (
        <div id="app-worker-list">
            {(isLoading || isWorkerListLoading) && <Loader />}
            <CreateWorkerDialog
                isModalOpen={inviteWorkerOpen}
                setIsModalOpen={setInviteWorkerOpen}
                isCustomer={false}
                getCustomerList={getDataList}
            />
            <CreateBrigadeModal
                open={brigadeModalOpen}
                onCancel={() => setBrigadeModalOpen(false)}
                onOk={handleCreateBrigadeModalOk}
            />
            <FunctionalMenu
                items={funcMenuItems}
                dropdownItems={funcMenuDropdownItems}
                selected={selectList.length}
            />

            <HeaderListBreadcrumbs
                dataTotal={currentMeta?.total}
                title={'Сотрудники'}
                dataTitle={'Сотрудники'}
                dataIcon={faUserTie}
                dataPrice={null}
                flag={false}
                isProduct={false}
                categoryPath={null}
                openCategoryCard={null}
                onSearch={handleSearchChange}
                searchPlaceHolder="По сотрудникам"
            >
                <Space>
                    <WorkersFilter store={filterStore} />
                    <OptionsMenu />
                </Space>
            </HeaderListBreadcrumbs>
            <Tabs
                activeKey={currentTab}
                items={[
                    {
                        label: 'Работают',
                        key: TAB_ROUTES.WORKING_NOW,
                        children: (
                            <RenderUserList
                                setSelectList={setSelectList}
                                inviteStatus={InvitationStatuses.accepted}
                                userList={workerList}
                                selectList={selectList}
                                isWorker={true}
                                col={columnViewStore.col}
                                currentMetaTotal={currentMeta?.total}
                                handleChangePage={handleChangePage}
                            />
                        ),
                    },
                    {
                        label: 'Рабочие места',
                        key: TAB_ROUTES.GROUPS,
                        children: groupId ? <WorkplaceList /> : <BrigadeList />,
                    },
                    {
                        label: 'Приглашенные',
                        key: TAB_ROUTES.INVITED,
                        children: (
                            <RenderUserList
                                inviteStatus={InvitationStatuses.pending}
                                userList={workerList}
                                selectList={selectList}
                                setSelectList={setSelectList}
                                isWorker={true}
                                col={columnViewStore.col}
                                currentMetaTotal={currentMeta?.total}
                                handleChangePage={handleChangePage}
                            />
                        ),
                    },
                    {
                        label: 'Корзина',
                        key: TAB_ROUTES.BASKET,
                        children: (
                            <RenderUserList
                                inviteStatus={null}
                                userList={workerList}
                                selectList={selectList}
                                setSelectList={setSelectList}
                                isWorker={true}
                                col={columnViewStore.col}
                                currentMetaTotal={currentMeta?.total}
                                handleChangePage={handleChangePage}
                            />
                        ),
                    },
                ]}
                onChange={onChangeTab}
            />
            {/* {currentMeta && currentPage < currentMeta.last_page && (
                <ShowMoreButton onClick={handleShowMore} text="Показать ещё" />
            )}
            <Pagination
                current={currentPage}
                defaultCurrent={1}
                onChange={handleChangePage}
                pageSize={pStore.pS}
                showSizeChanger={false}
                total={currentMeta?.total ?? 1}
            /> */}
        </div>
    );
});

export { WorkerList };
