export const OFFICE_FILES = {
    EXCEL: [
        {
            uri: 'https://temp.smart-laboratory.ru/example.xlsx',
            fileType: 'xlsx',
            fileName: 'Пример файла Excel',
        },
    ],

    WORD: [
        {
            uri: 'https://temp.smart-laboratory.ru/example.docx',
            fileType: 'docx',
            fileName: 'Пример файла Word',
        },
    ],
};
