import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMount } from 'ahooks';
import { Breadcrumb, Col, List, Row } from 'antd';
import { TWorksheet } from 'types/worksheets/worksheets';

import { WorksheetResp } from '@api/responseModels/worksheet/worksheetsResponse';
import { Loader } from '@atoms/loader';
import { CRMAPIManager } from '@classes/crmApiManager';
import { SettingsManager } from '@classes/settingsManager';
import { faGear, faHouse, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { columnViewStore } from '@molecules/listViewChanger/listViewStore';

import { sectionsData as data } from '../common/constants';

import '../style.scss';

const Worksheets = (): JSX.Element => {
    const params = useParams();
    const sectionName = params?.sectionName;
    const creds = SettingsManager.getConnectionCredentials();
    const navigate = useNavigate();
    const [worksheets, setWorksheets] = useState<TWorksheet[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getWorksheets = async () => {
        try {
            setLoading(true);
            const resp = await CRMAPIManager.request<WorksheetResp>(async (api) => {
                return await api.getWorksheets(creds.crmID);
            });
            setWorksheets(resp.data.data);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    useMount(() => {
        getWorksheets();
    });

    return (
        <>
            <div id="app-settings-list">
                {loading && <Loader />}
                <Row className="breadcrumb-header">
                    <Col className="header-location">
                        <span>Рабочие листы</span>
                    </Col>
                </Row>
                <Row className="breadcrumbs-controls">
                    <Col className="breadcrumbs">
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate(`/lk/worker/crm/${creds?.crmID}`)}
                            >
                                <FontAwesomeIcon icon={faHouse} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                onClick={() => navigate(`/lk/worker/crm/${creds?.crmID}/settings`)}
                            >
                                <FontAwesomeIcon icon={faGear} />
                                <span className="crumb-name">Настройки</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                onClick={() =>
                                    navigate(
                                        `/lk/worker/crm/${creds?.crmID}/settings/${sectionName}`
                                    )
                                }
                            >
                                <FontAwesomeIcon icon={data[sectionName]?.icon ?? faQuestion} />
                                <span className="crumb-name">
                                    {data[sectionName]?.name ?? sectionName}
                                </span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Рабочие листы</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <List
                    className="setting-card-list"
                    dataSource={worksheets}
                    itemLayout="horizontal"
                    grid={{ column: columnViewStore.col }}
                    renderItem={(item) => (
                        <List.Item className="setting-card">
                            <Row gutter={[0, 16]} onClick={() => navigate(`${item.id}`)}>
                                <Col span={24} className="card-body">
                                    <p>{item.name}</p>
                                    <p>Перейти</p>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </div>
        </>
    );
};

export { Worksheets };
