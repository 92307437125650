import { TOrderProduct } from 'types/Orders/products';
import { TOrderService } from 'types/Orders/services';
import { OrderParams } from 'types/getParams';

import { OrderCreateResp } from '@api/responseModels/order/orderCreateResponse';
import { OrderDetailsResp } from '@api/responseModels/order/orderDetailsResponse';
import { OrderListResp } from '@api/responseModels/order/orderListResponse';
import { OrderResp } from '@api/responseModels/order/orderResponse';
import { RequestResult } from '@api/responseModels/requestResult';

import { CRMAPIBase } from '../crmApiBase';

class OrderGroup extends CRMAPIBase {
    /**
     * Получение списка заказов
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getOrderList(params: OrderParams): Promise<RequestResult<OrderListResp>> {
        const result = await this.get<OrderListResp>('orders', { ...params });
        return result;
    }

    async getOrderDetails(
        crmId: number,
        profile_id: number
    ): Promise<RequestResult<OrderDetailsResp>> {
        const result = await this.get<OrderDetailsResp>('customer-order-params', {
            crm_id: crmId,
            customer_profile_id: profile_id,
        });
        return result;
    }

    /**
     * Получение заказа
     * @param id ID заказа
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getOrder(id: number, crmId: number): Promise<RequestResult<OrderResp>> {
        const result = await this.get<OrderResp>(`orders/${id}`, { crm_id: crmId });
        return result;
    }

    async saveOrder(
        crm_id: number,
        products: Array<TOrderProduct>,
        services: Array<TOrderService>,
        customer_profile_id: number,
        startPlannedAt: Date,
        order_object_id
    ) {
        const result = await this.post<OrderCreateResp>('/orders', {
            crm_id,
            products,
            services,
            customer_profile_id,
            start_planned_at: startPlannedAt,
            order_object_id,
        });
        return result;
    }

    async updateOrder(
        crmID: number,
        id: number,
        products: Array<TOrderProduct>,
        services: Array<TOrderService>,
        profileId: number,
        profileType: string,
        startPlannedAt: Date,
        order_object_id: number
    ) {
        const result = await this.put<OrderCreateResp>(`/orders/${id}`, {
            crm_id: crmID,
            products: products,
            services: services,
            customer_profile_id: profileId,
            profile_type: profileType,
            start_planned_at: startPlannedAt,
            order_object_id,
        });
        return result;
    }

    async syncOrderFieldsInOrders(
        id: number,
        crm_id: number,
        order_fields
    ): Promise<RequestResult<OrderResp>> {
        const result = await this.post<OrderResp>(`orders/${id}/sync-fields`, {
            crm_id,
            order_fields,
        });
        return result;
    }
}

export { OrderGroup };
