import { action, computed, makeObservable, observable } from 'mobx';
import storeLocalStorage from "../hooks/storeLocalStorage";
import { CRMAPIManager } from "@classes/crmApiManager";
import { CurrentUserResponse } from "@api/responseModels/user/currentUserResponse";
import { rootStore } from "@store/rootStore/instanse";
import { messageService } from "@classes/messageService";
import { TUser } from 'types/user/user';

class CurrentUserStore {
    public user: TUser | {} = {};

    constructor() {
        makeObservable(this,{
            user: observable,
            setUser: action,
            fetchCurrentUser: action,
            getUser: computed,
        });
    }

    get getUser() {
        const { getValue } = storeLocalStorage(this.user);
        return getValue('currentUser');
    }

    setUser(user) {
        const { setValue } = storeLocalStorage({});
        this.user = user;
        setValue('currentUser', user);
    }

    async fetchCurrentUser() {
        try {
            const cUser = await CRMAPIManager.request<CurrentUserResponse>(async (api) => {
                return await api.currentUser();
            });
            if (cUser.errorMessages) throw cUser.errorMessages;
            rootStore.currentUserStore.setUser({...cUser?.data?.data});
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
    }
}

export { CurrentUserStore };