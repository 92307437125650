import { TProduct } from 'types/product';

type TOrderProduct = {
    id: number | string | null;
    name: string;
    quantity: number | null;
    price: number | null;
    total: number | null;
};

const normalizeOrderProduct = (product: TProduct, quantity: number): TOrderProduct => {
    return {
        id: product.id,
        name: product.name,
        quantity: quantity,
        price: product.price,
        total: product.price * quantity,
    };
};

export { TOrderProduct, normalizeOrderProduct };
