import * as React from 'react';
import {Alert, Button, Card, Col, Modal, Row} from 'antd';
import {useEffect, useState} from 'react';
import {CRMAPIManager} from '../../../classes/crmApiManager';
import {SettingsManager} from '../../../classes/settingsManager';
import {TPermissionCategory} from '../../../types/specialty';
import {Loader} from '../../atoms/loader';
import {TUser} from 'types/user/user';
import {CreateSpecialtyDialog} from '../dialogs/createSpecialtyDialog';
import {ProfileResp} from "@api/responseModels/profile/profileResponse";
import {
    PermissionCategoryListResponse,
} from "@api/responseModels/permissions/permissionsResponse";

type TProps = {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    currentUser: TUser;
    setCurrentUser: React.Dispatch<React.SetStateAction<TUser>>;
    editing: boolean;
};

const PermissionsPicker = ({
    isModalOpen,
    setIsModalOpen,
    currentUser,
    setCurrentUser,
    editing,
}: TProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [permissionsList, setPermissionsList] = useState<Record<string, TPermissionCategory>>(null);
    const [lastError, setLastError] = useState<string>(null);
    const [isCreateSpecialtyOpen, setIsCreateSpecialtyOpen] = useState<boolean>(false);

    const checkExistingPermissions = (permissionCategories: Record<string, TPermissionCategory>) => {
        setPermissionsList((prev) => {
            Object.keys(permissionCategories).forEach(key => {
                const permissions = permissionCategories[key].permissions;

                if (!permissions) {
                    return;
                }

                permissions.forEach((permission) => {
                    const foundIndex = prev[key].permissions.findIndex(tpermission => tpermission.id == permission.id);

                    if (foundIndex != -1) {
                        prev[key].permissions[foundIndex] = {
                            ...prev[key].permissions[foundIndex],
                            checked: true,
                        };
                    }
                })
            })

            return {
                ...prev
            }
        });
    };

    async function getSpecialty(id) {
        setIsLoading(true);
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const specialtyData = await CRMAPIManager.request<ProfileResp>(async (api) => {
                return await api.getProfile(id, creds.crmID);
            });
            if (specialtyData.errorMessages) throw new Error(specialtyData.errorMessages[0]);

            checkExistingPermissions(specialtyData.data.data.permissions);
        } catch (err) {
            setLastError(err.message);
        }
        setIsLoading(false);
    }

    async function getPermissions() {
        setIsLoading(true);
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const permissions = await CRMAPIManager.request<PermissionCategoryListResponse>(async (api) => {
                return await api.getCategorizedPermissionList(creds.crmID);
            });

            if (permissions.errorMessages) throw new Error(permissions.errorMessages[0]);
            setPermissionsList(permissions.data.data);
        } catch (e) {
            setLastError(e.message);
        }
        setIsLoading(false);
    }

    function handlePickerOK() {
        setCurrentUser((prev) => {
            const permissions = Object.keys(permissionsList).reduce((acc, key) => {
                const permissions = permissionsList[key].permissions.filter(item => item.checked);

                acc[key] = {
                    ...permissionsList[key],
                    permissions: permissions,
                }

                return acc;
            }, {});

            prev.worker_profile.permissions = {
                ...permissions
            };
            return {...prev};
        });

        setIsModalOpen(false);
    }

    function handlePickerCancel() {
        setIsModalOpen(false);
    }

    const handleCheckboxPermission = (checked: boolean, permission, index) => {
        setPermissionsList((prev) => {
            const permissions = prev[permission.category_name].permissions;

            permissions[index] = {
                ...permissions[index],
                checked: checked,
            };

            prev[permission.category_name].permissions = [
                ...permissions
            ];

            return {
                ...prev
            }
        })
    };

    const refreshPermissions = () => {
        setPermissionsList(null);
        getPermissions();
    }

    useEffect(() => {
        console.log('mounted')
        getPermissions();
    }, []);

    useEffect(() => {
        if (permissionsList) {
            console.log(currentUser?.worker_profile?.permissions && !!Object.values(currentUser?.worker_profile?.permissions).length)

            if (currentUser?.worker_profile?.permissions && Object.values(currentUser?.worker_profile?.permissions).length) {
                console.log('check', permissionsList, currentUser?.worker_profile?.permissions)
                checkExistingPermissions(currentUser?.worker_profile?.permissions);
            } else {
                getSpecialty(currentUser?.worker_profile?.role?.id);
            }
        }
    }, [!permissionsList]);

    return (
        <div>
            <Modal
                className="specialty-picker"
                title="Выбор профиля"
                open={isModalOpen}
                onOk={handlePickerOK}
                onCancel={handlePickerCancel}
                cancelText="Отмена"
                wrapClassName='sticky-modal'
            >
                {isLoading && <Loader/>}
                {lastError && (
                    <Alert
                        className="picker-alert"
                        message={lastError}
                        type="error"
                        closable
                        onClose={() => setLastError(null)}
                    />
                )}
                <Row className="picker-controls">
                    <Col>
                        <Button onClick={refreshPermissions} type="default">
                            Обновить
                        </Button>
                    </Col>
                </Row>
                {editing && permissionsList && Object.values(permissionsList).length ? Object.values(permissionsList).map((permissionCategory, categoryIndex) => {
                    return (
                        <>
                            <div key={categoryIndex} className={`outer-box`}>
                                <Card key={categoryIndex} title={permissionCategory.name}>
                                    {permissionCategory.permissions.map((permission, index) => {
                                        return (
                                            <>
                                                <div key={index}>
                                                    <label htmlFor={`permission-${index}`}>
                                                        <input type="checkbox" id={`permission-${index}`}
                                                               onChange={(e) => {
                                                                   handleCheckboxPermission(e.target.checked, permission, index)
                                                               }} checked={permission.checked}/>
                                                        &nbsp;{permission.name_locale}
                                                    </label>
                                                </div>
                                            </>
                                        );
                                    })}
                                </Card>
                            </div>
                        </>
                    );
                }) : (
                    <></>
                )}
            </Modal>
            <CreateSpecialtyDialog
                isModalOpen={isCreateSpecialtyOpen}
                setIsModalOpen={setIsCreateSpecialtyOpen}
            />
        </div>
    );
};

export {PermissionsPicker};
