import { useRef } from 'react';
import { SyntheticEvent } from 'react';
import Highlighter from 'react-highlight-words';
import { useParams } from 'react-router-dom';

import { Button, InputNumber, Typography, message } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { normalizeOrderProduct } from 'types/Orders/products';
import { TProduct } from 'types/product';
import { TProductCategory } from 'types/productCategory';

import AutoResizeText from '@atoms/listsCard/autoResizeText/autoResizeText';
import useGetCardWidth from '@atoms/listsCard/hooks/useGetCardWidth';
import { Common } from '@classes/commonMethods';
import { SettingsManager } from '@classes/settingsManager';
import RenderIf from '@common/RenderIf';
import { GlobalConstants } from '@constants/global';
import { faCheck, faEye, faPencil, faPlus, faRubleSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { columnViewStore } from '@molecules/listViewChanger/listViewStore';
import { CardQuantityStore } from '@store/cardQuantityStore';
import { rootStore } from '@store/rootStore/instanse';

import styles from './productCard.module.scss';

type Props = {
    avoidRootStore?: boolean;
    noQuantitySelector?: boolean;
    noNavigation?: boolean;
    data: TProduct & { category: TProductCategory };
    selectElemList: Array<{ id: number; name: string; parentId: number | null }>;
    openCard: (id: number) => void | null;
    handleProductSelectButtonClick: (
        e: SyntheticEvent,
        categoryId: number,
        categoryName: string,
        parentId: number | null
    ) => void | null;
    hideQuantity?: boolean;
    searchValue?: string;
    showPermisionButtons?: boolean;
    onPermissionButtonClick?: (id: number, type: 'show' | 'update' | 'execute') => void;
};

const ProductCard = observer(
    ({
        avoidRootStore = false,
        noQuantitySelector = false,
        noNavigation = false,
        data,
        selectElemList,
        openCard,
        handleProductSelectButtonClick,
        hideQuantity,
        searchValue = '',
        showPermisionButtons,
        onPermissionButtonClick,
    }: Props) => {
        const cardRef = useRef(null);
        const cardWidth = useGetCardWidth({ cardRef });
        const store = new CardQuantityStore(
            data.id,
            rootStore.orderStore.products[data.id]?.quantity ?? 0,
            data.quantity
        );

        const creds = SettingsManager.getConnectionCredentials();
        const params = useParams();
        const isCurrentUser = +params?.workerID === creds.userId;

        const handleSelectCard = (e: SyntheticEvent) => {
            handleProductSelectButtonClick(e, data.id, data.name, data.category?.id);
            if (!avoidRootStore) {
                if (store.controlsIsVisible) {
                    store.setQuantity(0);
                    store.setVisible(false);
                } else {
                    store.setVisible(true);
                    store.inc();
                }
                rootStore.orderStore.updateProductList(normalizeOrderProduct(data, store.quantity));
            }
        };
        const handlePlusClick = (e: SyntheticEvent) => {
            e.stopPropagation();
            store.inc();
            rootStore.orderStore.updateProductList(normalizeOrderProduct(data, store.quantity));
        };
        const handleMinusClick = (e: SyntheticEvent) => {
            e.stopPropagation();
            store.dec();
            rootStore.orderStore.updateProductList(normalizeOrderProduct(data, store.quantity));
            if (!store.controlsIsVisible) {
                handleProductSelectButtonClick(e, data.id, data.name, data.category?.id);
            }
        };

        const handleOpenCard = (id) => {
            if (!data.permissions?.[0]?.show && !showPermisionButtons) {
                message.error('У вас нет прав на выполнение данной операции');
                return;
            }
            openCard(id);
        };

        return (
            <div
                ref={cardRef}
                className={cn(styles.card, {
                    [styles['card_vertical']]: columnViewStore.col > 1,
                })}
                onClick={(e: SyntheticEvent) => {
                    if (noNavigation) {
                        handleSelectCard(e);
                    } else {
                        handleOpenCard(data.id);
                    }
                }}
            >
                <div
                    className={cn(styles['card__image-container'], {
                        [styles['card__image-container_vertical']]: columnViewStore.col > 1,
                    })}
                >
                    <img src={`${GlobalConstants.BaseUrlForImg}${data?.picture as string}`} />
                    {!hideQuantity && (
                        <span className={styles['card__quantity']}>{data.quantity}</span>
                    )}
                </div>
                <div className={styles['card__info']}>
                    <p className={styles['card__name']}>
                        <Highlighter
                            searchWords={searchValue.split(' ') || []}
                            autoEscape={true}
                            textToHighlight={data.name}
                        />
                    </p>
                    <div className={styles['card__articul']}>
                        {'Арт. '}{' '}
                        <Typography.Text
                            copyable={{
                                tooltips: ['копировать', 'скопировано'],
                            }}
                        >
                            {data.artnumber}
                        </Typography.Text>
                    </div>
                    <div className={styles['card__price-container']}>
                        <AutoResizeText maxWidth={cardWidth}>
                            {Common.formatCurrency(data.price)}&nbsp;
                            <FontAwesomeIcon color="black" icon={faRubleSign} />
                        </AutoResizeText>
                    </div>
                </div>
                <div className={cn(styles['card__select-group'])}>
                    <RenderIf condition={noQuantitySelector}>
                        <Button
                            className={styles['card__controls-select-btn']}
                            onClick={handleSelectCard}
                            icon={<FontAwesomeIcon icon={faCheck} />}
                            shape="circle"
                            type={
                                selectElemList.find((sli) => sli.id == data.id)
                                    ? 'primary'
                                    : 'default'
                            }
                        />
                    </RenderIf>
                    <RenderIf
                        condition={
                            !noQuantitySelector &&
                            !showPermisionButtons &&
                            data.permissions?.[0]?.show
                        }
                    >
                        {!selectElemList.find((sli) => sli.id == data.id) ? (
                            <Button
                                className={styles['card__controls-select-btn']}
                                onClick={handleSelectCard}
                                icon={<FontAwesomeIcon icon={faCheck} />}
                                shape="circle"
                                type={
                                    selectElemList.find((sli) => sli.id == data.id)
                                        ? 'primary'
                                        : 'default'
                                }
                            />
                        ) : (
                            <div
                                className={
                                    styles[
                                        columnViewStore.col > 1
                                            ? 'card__controls-vertical'
                                            : 'card__controls'
                                    ]
                                }
                            >
                                <div className={styles['card__select-row']}>
                                    <InputNumber
                                        className={styles['card__select-amount']}
                                        controls={false}
                                        value={store.quantity}
                                        min={0}
                                        max={data.quantity}
                                        onClick={(e) => e.stopPropagation()}
                                        style={{ width: '100%' }}
                                    />
                                    <Button
                                        className={styles['card__select-rect-btn']}
                                        onClick={handleSelectCard}
                                        icon={<FontAwesomeIcon icon={faCheck} />}
                                        shape="default"
                                        type={
                                            selectElemList.find((sli) => sli.id == data.id)
                                                ? 'primary'
                                                : 'default'
                                        }
                                    />
                                </div>
                                <div className={styles['card__select-row']}>
                                    <Button
                                        className={styles['card__select-minus']}
                                        onClick={handleMinusClick}
                                        type="primary"
                                    >
                                        -
                                    </Button>
                                    <Button
                                        className={styles['card__select-plus']}
                                        onClick={handlePlusClick}
                                        type="primary"
                                    >
                                        +
                                    </Button>
                                </div>
                            </div>
                        )}
                    </RenderIf>

                    <RenderIf condition={!!showPermisionButtons}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                shape="circle"
                                icon={<FontAwesomeIcon icon={faEye} />}
                                onClick={() => onPermissionButtonClick(data.id, 'show')}
                                type={
                                    (isCurrentUser
                                        ? data.permissions
                                        : data.permissions_for_worker)?.[0].show
                                        ? 'primary'
                                        : 'default'
                                }
                            />
                            <Button
                                shape="circle"
                                icon={<FontAwesomeIcon icon={faPencil} />}
                                onClick={() => onPermissionButtonClick(data.id, 'update')}
                                type={
                                    (isCurrentUser
                                        ? data.permissions
                                        : data.permissions_for_worker)?.[1].update
                                        ? 'primary'
                                        : 'default'
                                }
                            />
                            <Button
                                shape="circle"
                                icon={
                                    <FontAwesomeIcon
                                        icon={
                                            (isCurrentUser
                                                ? data.permissions
                                                : data.permissions_for_worker)?.[2].execute
                                                ? faCheck
                                                : faPlus
                                        }
                                    />
                                }
                                onClick={() => onPermissionButtonClick(data.id, 'execute')}
                                type={
                                    (isCurrentUser
                                        ? data.permissions
                                        : data.permissions_for_worker)?.[2].execute
                                        ? 'primary'
                                        : 'default'
                                }
                            />
                        </div>
                    </RenderIf>
                </div>
            </div>
        );
    }
);

export { ProductCard };
