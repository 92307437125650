import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { useReactive } from 'ahooks';
import { Button, Select } from 'antd';
import { filter, includes, isEmpty, map } from 'lodash';

import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SelectedItems } from '../selectedItems/selectedItems';
import { SelectItemModal } from './blocks/selectItemModal';

import styles from './style.module.scss';

const { Option } = Select;

export const CategoriesMenu = ({
    onSelect,
    categoryType,
    data,
    selectedItems,
    style = {},
    disabled = false,
}) => {
    const state = useReactive({
        searchValue: '',
        isFocused: false,
        isModalOpen: false,
    });

    const [dropdownData, setDropdownData] = useState([]);

    const handleSearch = (val) => {
        if (!state.isFocused) return;

        state.searchValue = val;
        setDropdownData(
            val ? filter(data, (item) => includes(item.name.toLowerCase(), val.toLowerCase())) : []
        );
    };

    useEffect(() => {
        if (disabled) state.searchValue = '';
    }, [disabled]);

    const renderFrequentlyUseditems = () => {
        const notSelectedItemsData = filter(data, (item) => {
            return !includes(selectedItems, item.id);
        });

        return (
            <div className={styles.list}>
                {map(notSelectedItemsData, (item) => (
                    <div
                        key={item.id}
                        className={`${styles.item} ${
                            includes(selectedItems, item.id) ? styles.selected : ''
                        }`}
                        onClick={() => onSelect(item.id, categoryType)}
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.customMenuWrapper}>
            <div className={styles.customMenu} style={style}>
                <div className={`${styles.menuWrapper} ${disabled ? styles.disabled : ''}`}>
                    <div className={styles.search_box}>
                        <Select
                            onDropdownVisibleChange={(val) => {
                                state.isFocused = val;
                            }}
                            allowClear={true}
                            showSearch
                            searchValue={state.searchValue}
                            value={null}
                            className={`${styles.custom_select} ${
                                isEmpty(selectedItems) ? styles.hide_border : ''
                            }`}
                            defaultActiveFirstOption={false}
                            suffixIcon={null}
                            filterOption={false}
                            onSearch={handleSearch}
                            notFoundContent={'Нет результатов'}
                        >
                            {dropdownData?.map((item) => (
                                <Option key={item.id}>
                                    <div
                                        className={styles.custom_option}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onSelect(item.id, categoryType);
                                        }}
                                    >
                                        <Highlighter
                                            searchWords={state.searchValue.split(' ') || []}
                                            autoEscape={true}
                                            textToHighlight={item.name}
                                        />
                                        <div>
                                            <Button
                                                icon={<FontAwesomeIcon icon={faCheck} />}
                                                shape="circle"
                                                type={
                                                    includes(selectedItems, item.id)
                                                        ? 'primary'
                                                        : 'default'
                                                }
                                            />
                                        </div>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                        <span className={styles.all_btn} onClick={() => (state.isModalOpen = true)}>
                            Все
                        </span>
                    </div>
                    {!disabled ? (
                        <SelectedItems
                            categoryType={categoryType}
                            data={data}
                            onSelect={onSelect}
                            selectedItems={selectedItems}
                        />
                    ) : null}
                </div>

                {!disabled ? renderFrequentlyUseditems() : null}
            </div>
            <SelectItemModal
                isModalOpen={state.isModalOpen}
                setIsModalOpen={() => (state.isModalOpen = !state.isModalOpen)}
                data={data}
                selectedItems={selectedItems}
                onSelect={onSelect}
                categoryType={categoryType}
            />
        </div>
    );
};
