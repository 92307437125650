import { CRMAPIBase } from '../crmApiBase';
import { TFile } from '../../types/file';
import { RequestResult } from '../responseModels/requestResult';
import { FileResp } from '../responseModels/file/fileResponse';

class FileGroup extends CRMAPIBase {
    /**
     * Добавление файла
     * @param file Файл
     * @returns Результат запроса
     */
    async saveFile(file: TFile): Promise<RequestResult<FileResp>> {
        // return await sendRequest('/products', file, 'post');

        let result = new RequestResult<FileResp>();
        try {
            const response = await this._httpClient.request<FileResp>({
                method: 'post',
                url: '/files',
                data: file,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            result.statusCode = response.status;
            if (response.status >= 200 && response.status <= 300) {
                result.data = response.data;
            } else {
                result = CRMAPIBase.parseErrors<FileResp>(response.status, response.data);
            }
        } catch (error) {
            result = CRMAPIBase.parseErrors<FileResp>(error.response.status, error.response.data);
        }
        return result;
    }

    /**
     * Удаление файла
     * @param file Объект из сущности-родителя
     * @returns Файл на замену удалённого
     */
    async removeFile(file: TFile): Promise<RequestResult<FileResp>> {
        const result = this.post<FileResp>(`/files/delete`, {
            crm_id: file.crm_id,
            picture: file.picture,
            entity_id: file.entity_id,
            model: file.model,
        });
        return result;
    }
}

export { FileGroup };
