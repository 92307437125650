import { TWorker } from '../../types/worker';
import { CRMAPIBase } from '../crmApiBase';
import { RequestResult } from '../responseModels/requestResult';
import { WorkerProfileListResp } from '../responseModels/worker/workerProfileListResponse';
import { WorkerProfileResp } from '../responseModels/worker/workerProfileResponse';

class WorkerProfileGroup extends CRMAPIBase {
    /**
     * Добавление профиля сотруденика
     * @param crmId ID CRM
     * @param workerProfile Профиль сотрудника
     * @returns Результат запроса
     */
    async saveWorkerProfile(
        crmId: number,
        workerProfile: TWorker
    ): Promise<RequestResult<WorkerProfileResp>> {
        const result = await this.post<WorkerProfileResp>('/worker-profiles', {
            crm_id: crmId,
            ...workerProfile,
        });
        return result;
    }

    /**
     * Получение профиля сотрудника
     * @param id ID сотрудника
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getWorkerProfile(id: number, crmId: number): Promise<RequestResult<WorkerProfileResp>> {
        const result = await this.get<WorkerProfileResp>(`/worker-profiles/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Обновление профиля сотрудника
     * @param crmId ID CRM
     * @param workerProfile Профиль сотрудника
     * @returns Результат запроса
     */
    async updateWorkerProfile(
        crmId: number,
        workerProfile: TWorker
    ): Promise<RequestResult<WorkerProfileResp>> {
        const result = await this.put<WorkerProfileResp>(`/worker-profiles/${workerProfile.id}`, {
            crm_id: crmId,
            ...workerProfile,
        });
        return result;
    }

    /**
     * Удаление профиля сотрудника
     * @param id ID профиля сотрудника
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeWorkerProfile(
        id: number,
        crmId: number
    ): Promise<RequestResult<WorkerProfileResp>> {
        const result = await this.delete<WorkerProfileResp>(`/worker-profiles/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Восстановление профиля сотрудника
     * @param id ID профиля сотрудника
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreWorkerProfile(
        id: number,
        crmId: number
    ): Promise<RequestResult<WorkerProfileResp>> {
        const result = await this.post<WorkerProfileResp>(`/worker-profiles/${id}/restore`, {
            crm_id: crmId,
        });
        return result;
    }
}

export { WorkerProfileGroup };
