import { ruRU } from "@mui/x-date-pickers/locales";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { FieldChangeHandlerContext } from "@mui/x-date-pickers/internals";
import { TimeValidationError, TimeView } from "@mui/x-date-pickers";
import moment from "moment";
import { StyledEngineProvider } from "@mui/material/styles";

type BruhProps = {
    closeOnSelect?: boolean,
    defaultValue?: moment.Moment,
    disabled?: boolean,
    disableFuture?: boolean,
    disablePast?: boolean,
    hasError?: boolean,
    format: string,
    onChange: (value: any, context: FieldChangeHandlerContext<TimeValidationError>) => void,
    shouldDisableTime?: (value: any, view: TimeView) => boolean,
    value: moment.Moment
};

const BruhTimePicker = ({
    closeOnSelect = false,
    defaultValue = moment(), 
    disabled = false,
    disableFuture = false,
    disablePast = false,
    hasError = false,
    format = "HH:mm",
    onChange,
    shouldDisableTime = () => false,
    value
}: BruhProps): JSX.Element => {
    return (
        <StyledEngineProvider injectFirst>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                localeText={
                    ruRU.components.MuiLocalizationProvider.defaultProps.localeText
                }
            >
                <MobileTimePicker
                    ampm={false}
                    className={hasError ? "bruh-time-picker bruh-time-picker-error" : "bruh-time-picker"}
                    closeOnSelect={closeOnSelect}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    format={format}
                    onChange={onChange}
                    shouldDisableTime={shouldDisableTime}
                    value={value}
                />
            </LocalizationProvider>
        </StyledEngineProvider>
    );
};

export { BruhTimePicker };