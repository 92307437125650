import { createSlice } from '@reduxjs/toolkit';

const employeesSlice = createSlice({
    name: 'employees',
    initialState: {
        data: [],
    },
    reducers: {},
});

export const employeesReducer = employeesSlice.reducer;