enum ErrorCodes {
    AccessDenied = 'AccessDenied',
    NotFound = 'NotFound',
    WrongCode = 'WrongCode',
    BadRequest = 'BadRequest',
    AlreadyInvited = 'AlreadyInvited',
    NoInvitePermission = 'NoInvitePermission',
    CategoryNotEmpty = 'CategoryNotEmpty',
    ItemAlreadyExists = 'ItemAlreadyExists',
}

export { ErrorCodes };
