import { Modal, Row, Col, Radio, Space, Input } from "antd";

type TProps = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onOk: () => void;
    selectedSchedule: number;
    setSelectedSchedule: (value: number) => void;
    activeTemplateName: string;
    changeActiveTemplateName: (value: string) => void;
    placeholder: string;
    similarScheduleList: { value: number, name: string }[];
};

const SimilarScheduleModal = ({ isOpen, setIsOpen, onOk, selectedSchedule, setSelectedSchedule, activeTemplateName, changeActiveTemplateName, placeholder, similarScheduleList }: TProps): JSX.Element => {
    return (
        <Modal
            className="schedule-save-modal"
            title={`Сохранение графика`}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            onOk={onOk}
        >
            <Row className="vcentered vpadded">
                <Col className="hpadded">
                    <label>
                        Были обнаружены схожие шаблоны, выберите подходящий
                    </label>
                </Col>
            </Row>
            <Row className="vcentered vpadded">
                <Col className="hpadded">
                    <Radio.Group onChange={(e) => setSelectedSchedule(e.target.value)} value={selectedSchedule}>
                        <Space direction="vertical">
                            <Radio value={-1}>
                                <Input 
                                    className="template-header"
                                    onChange={(e) => changeActiveTemplateName(e.target.value)}
                                    placeholder={placeholder}
                                    value={activeTemplateName}
                                    style={{width: 360}}
                                />
                            </Radio>
                            {
                                similarScheduleList.map(sc => {
                                    return (<Radio value={sc.value}>{sc.name}</Radio>);
                                })
                            }
                        </Space>
                    </Radio.Group>
                </Col>
            </Row>
        </Modal>
    );
};

export { SimilarScheduleModal };