import { CSSProperties, memo } from 'react';

import { Modal } from 'antd';

import { CreateTemplateModalProps } from './common/types';

export const CreateTemplateModal: React.FC<CreateTemplateModalProps> = memo(
    ({
        isModalOpen,
        isTemplateAlreadyExists,
        newTemplateValue,
        handleOk,
        handleCancel,
        tableWithFixedValues,
    }) => {
        const modalBodyStyle: CSSProperties = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        };

        return (
            <Modal
                centered
                title="Создание нового шаблона"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    disabled: isTemplateAlreadyExists || !Boolean(newTemplateValue),
                }}
                okText={'Создать'}
                bodyStyle={modalBodyStyle}
            >
                {tableWithFixedValues}
            </Modal>
        );
    }
);
