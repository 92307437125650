import { SpecialtyParams } from 'types/getParams';
import { CRMAPIBase } from '../crmApiBase';
import { TProfileSearch } from '../models/profileSearch';
import { ProfileListResp } from '../responseModels/profile/profileListResponse';
import { ProfileResp } from '../responseModels/profile/profileResponse';
import { RequestResult } from '../responseModels/requestResult';

//На backend'е -> сущность Role; на фронтенде в UI -> Должность
class ProfileGroup extends CRMAPIBase {
    /**
     * Добавление профиля
     * @param name Название профиля
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async saveProfile(name: string, crmId: number): Promise<RequestResult<ProfileResp>> {
        const result = this.post<ProfileResp>('/roles', { name: name, crm_id: crmId });
        return result;
    }

    /**
     * Получение профиля
     * @param id ID профиля
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getProfile(id: number, crmId: number): Promise<RequestResult<ProfileResp>> {
        const result = await this.get<ProfileResp>(`/roles/${id}`, { crm_id: crmId });
        return result;
    }

    /**
     * Получение списка профилей
     * @param params Параметры списка
     * @returns Результат запроса
     */
    async getProfileList(params: SpecialtyParams): Promise<RequestResult<ProfileListResp>> {
        const result = await this.get<ProfileListResp>('/roles', { ...params });
        return result;
    }

    /**
     * Обновление профиля
     * @param id ID профиля
     * @param crmId ID CRM
     * @param name Название профиля
     * @returns Результат запроса
     */
    async updateProfile(
        id: number,
        crmId: number,
        name: string
    ): Promise<RequestResult<ProfileResp>> {
        const result = this.put<ProfileResp>(`/roles/${id}`, {
            id: id,
            crm_id: crmId,
            name: name,
        });
        return result;
    }

    /**
     * Удаление профиля
     * @param id ID профиля
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeProfile(id: number, crmId: number): Promise<RequestResult<ProfileResp>> {
        const result = this.delete<ProfileResp>(`/roles/${id}`, { id: id, crm_id: crmId });
        return result;
    }
    /**
     * Восстановление профиля
     * @param id ID профиля
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreProfile(id: number, crmId: number): Promise<RequestResult<ProfileResp>> {
        const result = this.post<ProfileResp>(`/roles/${id}/restore`, { crm_id: crmId });
        return result;
    }

    async syncSpecialtyPermissions(
        id: number,
        crmId: number,
        permissions: Array<number>
    ): Promise<RequestResult<ProfileResp>> {
        const result = this.post<ProfileResp>(`/roles/${id}/sync-permissions`, {
            crm_id: crmId,
            permissions: permissions,
        });
        return result;
    }

    //Раскомментируем, когда на бэке появится поиск
    // /**
    //  * Поиск профилей
    //  * @param crmId ID CRM
    //  * @param profileSearchModel Объект модели поиска профилей
    //  * @returns Результат запроса
    //  */
    // async searchProfile(
    //     crmId: number,
    //     profileSearchModel: TProfileSearch
    // ): Promise<RequestResult<ProfileListResp>> {
    //     let query = '';
    //     Object.keys(profileSearchModel).forEach((key) => {
    //         query += key + '=' + (profileSearchModel[key] ? profileSearchModel[key] : '') + '&';
    //     });
    //     query = query.slice(0, -1);
    //     const result = await this.get<ProfileListResp>('/profile/search', {
    //         crm_id: crmId,
    //         query: query,
    //     });
    //     return result;
    // }
}

export { ProfileGroup };
