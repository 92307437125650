import { CRMAPI } from "../api/crmApi";
import { RequestResult } from "../api/responseModels/requestResult";
import { SettingsManager } from "./settingsManager";

class CRMAPIManager {
    /**
    * Вызывает метод из API
    * @param method Вызов метода из API
    * @returns Результат вызова метода
    */
    static async request<T>(method: (api: CRMAPI) => Promise<RequestResult<any>>): Promise<RequestResult<T>> {
        let ca: CRMAPI;
        const creds = SettingsManager.getConnectionCredentials();
        if(!creds.token) {
            return { statusCode: -1, errorCode: "-1", errorMessages: ["Срок действия сессии закончился."]};
        }
        else {
            ca = new CRMAPI(creds.token);
        }
        
        let result = await method(ca);
        return result;
    }
}

export { CRMAPIManager };