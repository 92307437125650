import { Earnings } from './blocks/earnings';
import { GeneralInfo } from './blocks/generalInfo';
import { Other } from './blocks/other';

export const WorkplaceInfoCard = () => {
    return (
        <>
            <GeneralInfo />
            <Earnings />
            <Other />
        </>
    );
};
