import { CSSProperties, useEffect, useRef, useState } from 'react';
import styles from './style.module.scss';

const AutoResizeText = ({ maxWidth, children }) => {
    const textRef = useRef(null);
    const [fontSize, setFontSize] = useState(22);
    const newMaxWidth = maxWidth - 30;
    const containerWidth = textRef?.current?.offsetWidth;
    
    useEffect(() => {
        if (!textRef.current || newMaxWidth <= 0) return;
        const newFontSize = (newMaxWidth / containerWidth) * fontSize;
        if(newFontSize <= 22 ) {
            setFontSize(+newFontSize);
            return;
        };
        setFontSize(22)
    }, [children, maxWidth, textRef]);

    const style: CSSProperties = {
        fontSize: `${fontSize}px`,
    };

    return (
        <p className={styles.card__price} ref={textRef} style={style}>
            {children}
        </p>
    );
};

export default AutoResizeText;