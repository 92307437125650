import { CRMAPIBase } from "@api/crmApiBase";
import { RequestResult } from "@api/responseModels/requestResult";
import { TaskCategoryListResp } from "@api/responseModels/task/taskCategoryListResponse";
import { TaskCategoryResp } from "@api/responseModels/task/taskCategoryResponse";
import { TaskListResp } from "@api/responseModels/task/taskListResponse";
import { rootStore } from "@store/rootStore/instanse";
import { CategoryParams } from "types/getParams";

class TaskCategoryGroup extends CRMAPIBase {
    /**
     * Добавление категории задач
     * @param name Название категории
     * @param crmId ID CRM
     * @param picture Фото категории
     * @returns Результат запроса
     */
    async saveTaskCategory(
        name: string,
        crmId: number,
        parentCatId?: number,
        picture?: File
    ): Promise<RequestResult<TaskCategoryResp>> {
        const result = rootStore.taskStore.saveTaskCategory(name, crmId, parentCatId, picture);
        return result;
    }

    /**
     * Получение категории задач
     * @param categoryId ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getTaskCategory(
        crmId: number,
        categoryId?: number,
    ): Promise<RequestResult<TaskCategoryResp>> {
        const result = rootStore.taskStore.getTaskCategory(crmId, categoryId);
        return result;
    }

    /**
     * Получение списка категорий задач
     * @param params Параметры списка
     * @returns Результат запроса
     */
    async getTaskCategoryList(params: CategoryParams): Promise<RequestResult<TaskCategoryListResp>> {
        const result = rootStore.taskStore.getTaskCategoryList(params);
        return result;
    }

    /**
     * Обновление категории задач
     * @param categoryId ID Категории
     * @param name Название категории
     * @param crmId ID CRM
     * @param picture Фото категории
     * @returns Результат запроса
     */
    async updateTaskCategory(
        name: string,
        crmId: number,
        categoryId?: number,
        parentId?: number,
        picture?: File
    ): Promise<RequestResult<TaskCategoryResp>> {
        const result = rootStore.taskStore.updateTaskCategory(name, crmId, categoryId, parentId, picture);
        return result;
    }

    /**
     * Удаление категории задач
     * @param categoryId ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeTaskCategory(
        crmId: number,
        categoryId?: number,
    ): Promise<RequestResult<TaskCategoryResp>> {
        const result = rootStore.taskStore.removeTaskCategory(crmId, categoryId);
        return result;
    }

    /**
     * Восстановление категории задач
     * @param categoryId ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreTaskCategory(
        crmId: number,
        categoryId: number,
    ): Promise<RequestResult<TaskCategoryResp>> {
        const result = rootStore.taskStore.restoreTaskCategory(crmId, categoryId);
        return result;
    }

    /**
     * Получение задач категории задач
     * @param categoryId ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getTaskCategoryTaskList(
        crmId: number,
        categoryId: number,
    ): Promise<RequestResult<TaskListResp>> {
        const result = rootStore.taskStore.getTaskCategoryTaskList(crmId, categoryId);
        return result;
    }

    /**
     * Получение id всех дочерних категорий, включая потомки потомков
     * @param crmId ID CRM
     * @param categoryId id категории
     * @returns Результат запроса
     */
    async getAllIncludedTaskCategories(
        crmId: number,
        categoryId?: number
    ): Promise<RequestResult<any>> {
        const result = rootStore.taskStore.getAllIncludedTaskCategories(crmId, categoryId);
        return result;
    }

    /**
     * Получение общего количества задач, если передается параметр id, то поиск идет в этом разделе и всех подразделах
     * @param crmId ID CRM
     * @param categoryId id категории
     * @returns Результат запроса
     */
    async getTaskCatalogInfo(crmId: number, categoryId?: number): Promise<RequestResult<any>> {
        const result = rootStore.taskStore.getTaskCatalogInfo(crmId, categoryId);
        return result;
    }
}

export { TaskCategoryGroup };