import { ServiceParams } from 'types/getParams';
import { TService } from '../../types/service';
import { CRMAPIBase } from '../crmApiBase';
import { TServiceSearch } from '../models/serviceSearch';
import { ProductListResp } from '../responseModels/product/productListResponse';
import { RequestResult } from '../responseModels/requestResult';
import { ServiceListResp } from '../responseModels/service/serviceListResponse';
import { ServiceResp } from '../responseModels/service/serviceResponse';

class ServiceGroup extends CRMAPIBase {
    /**
     * Добавление услуги
     * @param service Услуга
     * @returns Результат запроса
     */
    async saveService(service: TService): Promise<RequestResult<ServiceResp>> {
        const result = await this.post<ServiceResp>('/services', { ...service });
        return result;
    }

    /**
     * Получение услуги
     * @param id ID услуги
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getService(id: number, crmId: number): Promise<RequestResult<ServiceResp>> {
        const result = await this.get<ServiceResp>(`/services/${id}`, { crm_id: crmId });
        return result;
    }

    /**
     * Получение списка услуг
     * @param params Параметры запроса услуг
     * @param page Страница
     * @param perPage На страницу
     * @returns Результат запроса
     */
    async getServiceList(params: ServiceParams): Promise<RequestResult<ServiceListResp>> {
        const result = await this.get<ServiceListResp>('/services', { ...params });
        return result;
    }

    /**
     * Обновление услуги
     * @param service Услуга
     * @returns Результат запроса
     */
    async updateService(service: TService): Promise<RequestResult<ServiceResp>> {
        const result = await this.put<ServiceResp>(`/services/${service.id}`, { ...service });
        return result;
    }

    /**
     * Удаление услуги
     * @param id ID услуги
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeService(id: number, crmId: number): Promise<RequestResult<ServiceResp>> {
        const result = this.delete<ServiceResp>(`/services/${id}`, { crm_id: crmId });
        return result;
    }
    /**
     * Восстановление услуги
     * @param id ID услуги
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreService(id: number, crmId: number): Promise<RequestResult<ServiceResp>> {
        const result = this.post<ServiceResp>(`/services/${id}/restore`, { crm_id: crmId });
        return result;
    }
    /**
     * Получение списка товаров услуги
     * @param id ID услуги
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getServiceProductList(
        id: number,
        crmId: number
    ): Promise<RequestResult<ProductListResp>> {
        const result = await this.get<ProductListResp>(`/services/${id}/products`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * @deprecated
     * Поиск услуг
     * @param params ServiceParams,
     * @param serviceSearchModel Объект модели поиска услуг
     * @returns Результат запроса
     */
    async searchService(
        params: ServiceParams,
        serviceSearchModel: TServiceSearch
    ): Promise<RequestResult<ServiceListResp>> {
        let query = '';
        Object.keys(serviceSearchModel).forEach((key) => {
            query += key + '=' + (serviceSearchModel[key] ? serviceSearchModel[key] : '') + '&';
        });
        query = query.slice(0, -1);
        const result = await this.get<ServiceListResp>('/services/search', {
            ...params,
            query: serviceSearchModel.name,
        });
        return result;
    }

    /**
     * Обновление категорий услуг
     * @param crmId ID CRM
     * @param services Карта перемещений
     * @returns Результат запроса
     */
    async updateServiceCategories(
        crmId: number,
        services: Array<{ id: number; service_category_id: number }>
    ): Promise<RequestResult<ServiceListResp>> {
        const result = await this.post<ServiceListResp>('/services/categories', {
            crm_id: crmId,
            services: services,
        });
        return result;
    }
}

export { ServiceGroup };
