import { Button, Col, Row } from 'antd';
import './style.scss';
import { memo } from 'react';
import { useReactive } from 'ahooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const OrderWorkers: React.FC = memo(() => {
    const state = useReactive({
        workers: [
            { id: 1, statusTime: 100 },
            { id: 2, statusTime: 70 },
            { id: 3, statusTime: 40 },
            { id: 4, statusTime: 0 },
            { id: 5, statusTime: 0 },
            { id: 6, statusTime: 0 },
            { id: 7, statusTime: 0 },
        ],
        selected: []
    });

    return (
        <Row className="order-workers">
            <Col span={24} className="border-top">
                <Row style={{alignItems: 'center'}}>
                    <h3 style={{ marginBottom: "6px" }}>Исполнители заказа:</h3>
                    <h2 style={{ marginBottom: "6px", marginLeft: "6px", marginRight: "6px", color: "#8c3dc7" }}>{state.workers.length}</h2>
                    <Button
                        icon={<FontAwesomeIcon icon={faCheck} />}
                        onClick={() => {
                            if(state.selected.length == state.workers.length) state.selected = [];
                            else state.selected = state.workers.map(w => w.id);
                        }}
                        shape="circle"
                        type={state.selected.length == state.workers.length ? 'primary' : 'default'}
                    />
                </Row>
            </Col>
            <Col className="workers-list" span={24}>
                <Row>
                    {state.workers.map((item) => {
                        return (
                            <Col key={item.id} className="worker-card" span={6}>
                                <Button
                                    className="contractor-select"
                                    icon={<FontAwesomeIcon icon={faCheck} />}
                                    onClick={() => {
                                        if(state.selected.find(sel => sel == item.id)) {
                                            state.selected = state.selected.filter(sel => sel != item.id);
                                        }
                                        else state.selected = state.selected.concat(item.id);
                                    }}
                                    size='small'
                                    shape="circle"
                                    type={state.selected.includes(item.id) ? 'primary' : 'default'}
                                />
                                <img
                                    src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                                    alt=""
                                />
                                <p>Старший механик{item.id}</p>
                                <div
                                    className="progress-bar"
                                    style={{
                                        background: `linear-gradient(to right, ${
                                            item.statusTime != 0
                                                ? `#FC680D ${item.statusTime}%`
                                                : `white 100%`
                                        }, white ${100 - item.statusTime}%)`,
                                    }}
                                >
                                    <div
                                        className="worker-time"
                                        style={{
                                            background: `linear-gradient(to right, ${
                                                item.statusTime != 0
                                                    ? `white ${item.statusTime}%`
                                                    : `#FC680D 100%`
                                            }, #FC680D ${100 - item.statusTime}%)`,
                                        }}
                                    >
                                        13:45-15:45
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Col>
        </Row>
    );
});
