import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

export const PhotoCheckbox = ({ onChange, checked }) => {
    return (
        <div onClick={onChange} className={`photo-checkbox ${checked ? 'checked' : ''}`}>
            <FontAwesomeIcon
                className="camera"
                icon={faCamera}
                color={`${checked ? 'white' : '#1890ff'}`}
            />
        </div>
    );
};
