import { Input, UploadProps, message } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
    faCancel,
    faCheck,
    faCopy,
    faPaperclip,
    faPlus,
    faSave,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DataType, GetInitialMenuItemsProps } from './types';

export const uploadP: UploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

export const getInitialMenuItems = ({
    handleDeleteSelectedItems,
    handleSaveOrder,
    handleDeselectAllItems,
}: GetInitialMenuItemsProps) => {
    return [
        // {
        //     key: 'edit',
        //     label: 'Редактировать',
        //     icon: <FontAwesomeIcon icon={faEdit} />,
        //     onClick: handleEditOrder,
        // },
        {
            key: 'save',
            label: 'Сохранить',
            icon: <FontAwesomeIcon icon={faSave} />,
            onClick: handleSaveOrder,
        },
        {
            key: 'copy',
            label: 'Копировать',
            icon: <FontAwesomeIcon icon={faCopy} />,
        },
        {
            key: 'completed',
            label: 'Выполнено и использовано',
            icon: <FontAwesomeIcon icon={faCheck} />,
        },
        {
            key: 'attach',
            label: 'Прикрепить к заказу',
            icon: <FontAwesomeIcon icon={faPaperclip} />,
        },
        {
            key: 'delete',
            label: 'Удалить',
            icon: <FontAwesomeIcon icon={faTrash} />,
            onClick: handleDeleteSelectedItems,
            // disabled: getIsItemSelected(),
        },
        {
            key: 'cancel',
            label: 'Отмена',
            icon: <FontAwesomeIcon icon={faCancel} />,
            onClick: handleDeselectAllItems,
        },
    ];
};

export const getMenuItemsForItemsWithoutExecutor = ({handleDeselectAllItems}) => [
    {
        key: 'save',
        label: 'Назначить исполнителя',
        icon: <FontAwesomeIcon icon={faPlus} />,
        onClick: () => {},
    },
    {
        key: 'delete',
        label: 'Удалить',
        icon: <FontAwesomeIcon icon={faTrash} />,
        onClick: () => {},
        // disabled: getIsItemSelected(),
    },
    {
        key: 'cancel',
        label: 'Отмена',
        icon: <FontAwesomeIcon icon={faCancel} />,
        onClick: handleDeselectAllItems,
    },
];

export const getColumnsNewOrderObject = ({ onChange }): ColumnsType<DataType> => {
    return [
        {
            dataIndex: 'id',
            width: '5%',
            key: 'id',
            render: (_text, _record, index) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                        }}
                    >
                        <span>{index + 1}</span>
                    </div>
                );
            },
        },
        {
            dataIndex: 'fieldName',
            key: 'fieldName',
            width: '47.5%',
        },
        {
            dataIndex: 'value',
            key: 'value',
            width: '47.5%',
            render: (value) => {
                return (
                    <>
                        <Input
                            style={{
                                fontWeight: 600,
                                borderRadius: '10px',
                            }}
                            placeholder="Введите значение"
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                        />
                    </>
                );
            },
        },
    ];
};
