import React, { memo, useEffect, useRef } from 'react';
import '../style.scss';
import { TOrderTask } from './common/types';
import { Col, Row, Table } from 'antd';
import { useCreation } from 'ahooks';
import { getColumnsTask } from './common/data';
import { useDispatch, useSelector } from 'react-redux';
import { getComments } from '../orderComment/common/redux/selectors';
import { clearSelectedComments } from '../orderComment/common/redux/commentSlice';
import { EmptyRows } from '../../common/constants';
import { isEmpty } from 'lodash';
import { handleFormData } from '../../common/helpers/handleFormData';
import { rootStore } from '@store/rootStore/instanse';
import { SettingsManager } from '@classes/settingsManager';
import { setOrderProperty } from '../../common/redux/orderSlice';

export const TaskTable: React.FC<any> = memo(({ 
    nameCellWidth,
    tasks,
    setTasks,
    selectedTasks,
    setSelectedTasks,
    totalTasks,
    setTotalTasks 
}) => {
    const dispatch = useDispatch();
    const { selectedComments } = useSelector(getComments);
    const inputRefs = useRef([]);

    const setInputRef = (index, el) => {
        inputRefs.current[index] = el;
    };

    const handleSearchInput = (item: TOrderTask, _, rowIndex: number) => {
        const data = handleFormData(item, rowIndex, tasks);
        setTasks(data);
        dispatch(setOrderProperty({ key: 'updatedRowIndex', value: rowIndex }));
    };

    const handleChangeQuantity = (rowIndex: number, newQuantity: number) => {
        const tasksCopy = [...tasks];
        tasksCopy[rowIndex] = {
            ...tasks[rowIndex],
            quantity: newQuantity,
            total: newQuantity * tasks[rowIndex].price,
        };
        setTasks(tasksCopy);
        let newTotal = 0;
        tasksCopy.forEach(tc => newTotal += tc.total);
        setTotalTasks(newTotal);
    };

    const handleChangePrice = (rowIndex: number, newPrice: number) => {
        const tasksCopy = [...tasks];
        tasksCopy[rowIndex] = {
            ...tasks[rowIndex],
            price: newPrice,
            total: newPrice * tasks[rowIndex].quantity,
        };
        setTasks(tasksCopy);
        let newTotal = 0;
        tasksCopy.forEach(tc => newTotal += tc.total);
        setTotalTasks(newTotal);
    };

    const handleSelectTask = (id: number) => {
        dispatch(clearSelectedComments());
        if (selectedTasks.includes(id)) setSelectedTasks(selectedTasks.filter(st => st != id));
        else setSelectedTasks([...selectedTasks, id]);
    };

    const handleSelectAllItems = () => {
        if (selectedTasks.length !== tasks.length - 2) {
            setSelectedTasks(tasks
                .map((item) => item.id)
                .filter((id) => id !== EmptyRows.EMPTY_ROW_1 && id !== EmptyRows.EMPTY_ROW_2)
            );
        }
        else setSelectedTasks([]);
    }

    useEffect(() => {
        let defTasks = rootStore.taskStore.getTaskList({
            crm_id: SettingsManager.getConnectionCredentials().crmID,
            category_id: null,
            filters: {
                created_at: [null, null],
                deleted: 'all',
            },
            query: "",
            sort_direction: 'asc',
            sort_by: 'name',
            per_page: 3,
            page: 1
        }, 'available').data.data
        .map(t => {return { id: t.id, name: t.name, quantity: 1, price: t.reward, total: t.reward}});
        defTasks.push(...[
            {
                "id": "EMPTY_ROW_1",
                "name": null,
                "quantity": null,
                "price": null,
                "total": null
            },
            {
                "id": "EMPTY_ROW_2",
                "name": "",
                "quantity": null,
                "price": null,
                "total": null
            }
        ]);
        setTasks(defTasks);
        let newTotal = 0;
        defTasks.forEach(tc => newTotal += tc.total);
        setTotalTasks(newTotal);
    }, []);

    const columnsTask = useCreation(() => {
        return getColumnsTask({
            handleSearchInput,
            handleChangeQuantity,
            handleChangePrice,
            handleSelectItem: handleSelectTask,
            selectedItems: selectedTasks,
            handleSelectAllItems,
            itemsCount: tasks.length - 2,
            nameCellWidth,
            isCommentSelected: !isEmpty(selectedComments),
            inputRefs,
            setInputRef,
        });
    }, [selectedTasks.length, nameCellWidth, !isEmpty(selectedComments), tasks]);

    return (
        <Row className="table-service">
            <Table
                columns={columnsTask}
                bordered
                dataSource={tasks}
                pagination={false}
                className="table-size"
                size="small"
                rowKey={(record) => record.id}
                footer={() => {
                    return (
                        <Row className="t-footer" justify={"space-between"} align={"middle"}>
                            <Col span={4}>
                                <h3>Итого</h3>
                            </Col>
                            <Col span={6}>
                                <h3>{totalTasks.toLocaleString('ru-RU')} ₽</h3>
                            </Col>
                        </Row>
                    );
                }}
                onHeaderRow={(columns, _index) => {
                    return {
                        onClick: () => {
                            columns[1].className = 'td-service-cell enlarged';
                        },
                    };
                }}
            />
        </Row>
    )
});