import { TOrderProduct } from 'types/Orders/products';
import { TOrderService } from 'types/Orders/services';
import { TOrderTask } from '../components/taskTable/common/types';

export enum EmptyRows {
    EMPTY_ROW_1 = 'EMPTY_ROW_1',
    EMPTY_ROW_2 = 'EMPTY_ROW_2',
}

export interface State {
    isDetailsActive: boolean;
    totalSum: number;
    isModalOpen: boolean;
    isSaveButtonActive: boolean;
    nameCellWidth: string;
    tasks: TOrderTask[];
    selectedTasks: number[];
    totalTasks: number;
}

export interface Template {
    id: number;
    name: string;
    fields?: WorksheetValues[];
}

export interface DataType {
    key: React.Key;
    fieldName: string;
    useField: boolean;
    isRequired: boolean;
    fixValueForAll: boolean;
    useByDefault: boolean;
    autocomplete?: boolean;
    isEditable: boolean;
    isPhotoField?: boolean;
    value: string;
}

export interface WorksheetValues {
    name: string;
    value: string;
}

export type HandleSearchInput = (
    item: TOrderProduct | TOrderService,
    type: 'product' | 'service' | 'task',
    rowIndex: number
) => void;

export type HandleChangeQuantity = (
    rowIndex: number,
    newQuantity: number,
    type: 'product' | 'service'
) => void;

export type HandleChangePrice = (
    rowIndex: number,
    newPrice: number,
    type: 'product' | 'service' | 'task'
) => void;

export type HandleSelectItem = (id: number | string, type: 'product' | 'service' | 'task') => void;

export type HandleSelectAllItems = (type: 'product' | 'service' | 'task') => void;

export interface GetColumnsProps {
    handleSearchInput: HandleSearchInput;
    handleChangeQuantity: HandleChangeQuantity;
    handleChangePrice: HandleChangePrice;
    handleSelectItem: HandleSelectItem;
    selectedItems: (number | string)[];
    handleSelectAllItems: HandleSelectAllItems;
    itemsCount: number;
    nameCellWidth: string;
    isCommentSelected: boolean;
    inputRefs: any;
    setInputRef: (index: number, el: any) => void;
}

export interface GetInitialMenuItemsProps {
    handleSaveOrder: () => void;
    handleDeleteSelectedItems: () => void;
    handleDeselectAllItems: () => void;
}
