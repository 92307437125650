import { TPivot } from 'types/worksheets/worksheetFields';

import { RequestResult } from '@api/responseModels/requestResult';
import { WorksheetFieldsResp } from '@api/responseModels/worksheet/worksheetFieldsResponse';
import { WorksheetResp } from '@api/responseModels/worksheet/worksheetsResponse';

import { CRMAPIBase } from '../crmApiBase';
import { RequiredOrderFieldsResp } from '@api/responseModels/worksheet/requiredOrderFieldsResponse';

class WorksheetGroup extends CRMAPIBase {
    async getWorksheets(crm_id: number): Promise<RequestResult<WorksheetResp>> {
        const result = await this.get<WorksheetResp>(`worksheets`, { crm_id });
        return result;
    }

    async getWorksheetFields(
        worksheetId: number,
        crm_id: number
    ): Promise<RequestResult<WorksheetFieldsResp>> {
        const result = await this.get<WorksheetFieldsResp>(`worksheets/${worksheetId}`, { crm_id });
        return result;
    }

    async updateWorksheetFields(
        worksheetId: number,
        crm_id: number,
        order_fields: TPivot[]
    ): Promise<RequestResult<WorksheetFieldsResp>> {
        const result = await this.post<WorksheetFieldsResp>(
            `worksheets/${worksheetId}/sync-fields`,
            {
                crm_id,
                order_fields,
            }
        );
        return result;
    }

    async getAllRequiredFields(crm_id): Promise<RequestResult<RequiredOrderFieldsResp>> {
        const result = await this.post<RequiredOrderFieldsResp>(`worksheets/get-all-required-fields`, {
            crm_id,
        });
        return result;
    }
}

export { WorksheetGroup };
