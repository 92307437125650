import { TPermissionCategory } from 'types/specialty';

import { rootStore } from '@store/rootStore/instanse';

export class Permissions {
    public static CustomerProfileList = 'CustomerProfile.getList';
    public static CustomerProfileGet = 'CustomerProfile.get';
    public static CustomerProfileSave = 'CustomerProfile.save';
    public static CustomerProfileUpdate = 'CustomerProfile.update';
    public static CustomerProfileRemove = 'CustomerProfile.remove';
    public static CustomerProfileRestore = 'CustomerProfile.restore';
    public static CustomerProfileBan = 'CustomerProfile.ban';
    public static CustomerProfileUnban = 'CustomerProfile.unban';

    public static WorkerProfileList = 'WorkerProfile.getList';
    public static WorkerProfileGet = 'WorkerProfile.get';
    public static WorkerProfileSave = 'WorkerProfile.save';
    public static WorkerProfileUpdate = 'WorkerProfile.update';
    public static WorkerProfileRemove = 'WorkerProfile.remove';
    public static WorkerProfileRestore = 'WorkerProfile.restore';
    public static WorkerProfileBan = 'WorkerProfile.ban';
    public static WorkerProfileUnban = 'WorkerProfile.unban';

    public static OrderList = 'Order.getList';
    public static OrderGet = 'Order.get';
    public static OrderUpdate = 'Order.update';
    public static OrderSave = 'Order.save';
    public static OrderRestore = 'Order.restore';
    public static OrderRemove = 'Order.remove';
    public static OrderStart = 'Order.start';
    public static OrderSyncParams = 'Order.syncParams';

    public static ScheduleTemplateList = 'ScheduleTemplate.getList';
    public static ScheduleTemplateGet = 'ScheduleTemplate.get';
    public static ScheduleTemplateUpdate = 'ScheduleTemplate.update';
    public static ScheduleTemplateSave = 'ScheduleTemplate.save';
    public static ScheduleTemplateRestore = 'ScheduleTemplate.restore';
    public static ScheduleTemplateRemove = 'ScheduleTemplate.remove';

    public static PaymentMethodList = 'PaymentMethod.getList';
    public static PaymentMethodUpdate = 'PaymentMethod.update';
    public static PaymentMethodSave = 'PaymentMethod.save';
    public static PaymentMethodRestore = 'PaymentMethod.restore';
    public static PaymentMethodRemove = 'PaymentMethod.remove';

    public static TransactionList = 'Transaction.getList';
    public static TransactionGet = 'Transaction.get';
    public static TransactionSave = 'Transaction.save';

    public static ServiceCategoryList = 'ServiceCategory.getList';
    public static ServiceCategoryGet = 'ServiceCategory.get';
    public static ServiceCategoryUpdate = 'ServiceCategory.update';
    public static ServiceCategorySave = 'ServiceCategory.save';
    public static ServiceCategoryRestore = 'ServiceCategory.restore';
    public static ServiceCategoryRemove = 'ServiceCategory.remove';
    public static ServiceCategoryUpdateCategories = 'ServiceCategory.updateCategories';
    public static ServiceCategoryGetServices = 'ServiceCategory.getServices';
    public static ServiceCategoryGetCategories = 'ServiceCategory.getCategories';
    public static ServiceCategoryCatalog = 'ServiceCategory.catalog';

    public static InvitationInvite = 'Invitation.invite';
    public static InvitationList = 'Invitation.getList';

    public static ProductList = 'Product.getList';
    public static ProductGet = 'Product.get';
    public static ProductUpdate = 'Product.update';
    public static ProductSave = 'Product.save';
    public static ProductRestore = 'Product.restore';
    public static ProductRemove = 'Product.remove';
    public static ProductUpdateCategories = 'Product.updateCategories';
    public static ProductGetServices = 'Product.getServices';

    public static RequisiteList = 'Requisite.getList';
    public static RequisiteGet = 'Requisite.get';
    public static RequisiteUpdate = 'Requisite.update';
    public static RequisiteSave = 'Requisite.save';
    public static RequisiteRestore = 'Requisite.restore';
    public static RequisiteRemove = 'Requisite.remove';

    public static UserList = 'User.getList';
    public static UserGet = 'User.get';
    public static UserUpdate = 'User.update';
    public static UserSave = 'User.save';
    public static UserRestore = 'User.restore';
    public static UserRemove = 'User.remove';
    public static UserCurrent = 'User.current';
    public static UserGetRequisites = 'User.getRequisites';
    public static UserGetWorkerProfile = 'User.getWorkerProfile';
    public static UserGetSendInvitations = 'User.getSendInvitations';
    public static UserGetReceiveInvitations = 'User.getReceiveInvitations';

    public static CrmList = 'Crm.getList';
    public static CrmGet = 'Crm.get';
    public static CrmUpdate = 'Crm.update';
    public static CrmSave = 'Crm.save';
    public static CrmRestore = 'Crm.restore';
    public static CrmRemove = 'Crm.remove';
    public static CrmTotal = 'Crm.total';

    public static ScheduleList = 'Schedule.getList';
    public static ScheduleGet = 'Schedule.get';
    public static ScheduleUpdate = 'Schedule.update';
    public static ScheduleSave = 'Schedule.save';
    public static ScheduleRestore = 'Schedule.restore';
    public static ScheduleRemove = 'Schedule.remove';

    public static NotificationList = 'Notification.getList';
    public static NotificationGet = 'Notification.get';

    public static ProductCategoryList = 'ProductCategory.getList';
    public static ProductCategoryGet = 'ProductCategory.get';
    public static ProductCategoryUpdate = 'ProductCategory.update';
    public static ProductCategorySave = 'ProductCategory.save';
    public static ProductCategoryRestore = 'ProductCategory.restore';
    public static ProductCategoryRemove = 'ProductCategory.remove';
    public static ProductCategoryUpdateCategories = 'ProductCategory.updateCategories';
    public static ProductCategoryGetServices = 'ProductCategory.getServices';

    public static ServiceList = 'Service.getList';
    public static ServiceGet = 'Service.get';
    public static ServiceUpdate = 'Service.update';
    public static ServiceSave = 'Service.save';
    public static ServiceRestore = 'Service.restore';
    public static ServiceRemove = 'Service.remove';
    public static ServiceUpdateCategories = 'Service.updateCategories';
    public static ServiceGetProducts = 'Service.getProducts';

    public static RoleList = 'Role.getList';
    public static RoleGet = 'Role.get';
    public static RoleUpdate = 'Role.update';
    public static RoleSave = 'Role.save';
    public static RoleRestore = 'Role.restore';
    public static RoleRemove = 'Role.remove';
    public static RoleSyncPermissions = 'Role.syncPermissions';

    public static LogList = 'Log.getList';
    public static LogGet = 'Log.get';
    public static LogUpdate = 'Log.update';
    public static LogSave = 'Log.save';
    public static LogRestore = 'Log.restore';
    public static LogRemove = 'Log.remove';

    public static checkPermission(permission): boolean {
        if (!rootStore.currentUserStore.getUser?.worker_profile?.permissions) {
            return true;
        }

        const permValues: TPermissionCategory[] = Object.values(
            rootStore.currentUserStore.getUser.worker_profile.permissions
        );
        return !permValues?.find((item) =>
            item?.permissions?.find((perm) => perm?.name == permission)
        );
    }
}
