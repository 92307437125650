export async function getAudioDuration(blob) {
    const promise = new Promise((resolve, reject) => {
        const audioContext = new window.AudioContext();
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const arrayBuffer = event.target.result;
            audioContext.decodeAudioData(
                arrayBuffer as ArrayBuffer,
                (audioBuffer) => {
                    const duration = audioBuffer.duration;
                    resolve(duration);
                },
                (error) => {
                    reject(error);
                }
            );
        };

        fileReader.readAsArrayBuffer(blob);
    });
    const audioDuration = await promise;
    return audioDuration;
}