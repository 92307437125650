enum RUStrings {
    AccessDenied = 'В доступе отказано',
    NotFound = 'Объект не найден',
    WrongCode = 'Некорректный код авторизации',
    BadRequest = 'Некорректные данные запроса',
    AlreadyInvited = 'Пользователь уже приглашён',
    NoInvitePermission = 'Недостаточно прав для приглашения',
    CategoryNotEmpty = 'Удаляемая категория не пуста',
    ItemAlreadyExists = 'Данный элемент уже существует в данной CRM',
}

export { RUStrings };
