import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        fontSize: '10px',
        flexDirection: 'column',
        padding: '1.5cm',
        textAlign: 'justify',
    },
    container: {
        width: '100%',
    },
    title: {
        fontSize: '12px',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        marginBottom: '5mm',
    },
    text: {
        marginBottom: '1mm',
    },
    fwBold: {
        fontWeight: 'bold',
    },
    hr_1: {
        width: '100%',
        height: '.5mm',
        backgroundColor: 'black',
        marginTop: '5mm',
    },
    rowSpaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    p_1: {
        padding: '1mm',
    },
    m_0: {
        margin: 0,
    },
    mt_5: {
        marginTop: '5mm',
    },
    mt_10: {
        marginTop: '10mm',
    },
    mb_10: {
        marginBottom: '10mm',
    },
    mb_5: {
        marginBottom: '5mm',
    },
    flex_1: {
        flex: '1',
    },
    flex_2: {
        flex: '2',
    },
    flex_3: {
        flex: '3',
    },
    tAlignLeft: {
        textAlign: 'left',
    },
    tAlignRight: {
        textAlign: 'right',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    tableHead: {
        justifyContent: 'center',
        fontWeight: 'normal',
        textAlign: 'left',
        padding: '1mm',
    },

    table: {
        display: 'flex',
    },

    column: {
        marginBottom: '1em',
        width: '100%',
    },

    row: {
        border: '1px black solid',
        height: '3em',
        margin: 'auto',
        width: '100%',
    },
});
