import moment from "moment";
import { TTask, TTaskCategory } from "./types";
import { rootStore } from "@store/rootStore/instanse";

export const makeEmptyTask = (crmId: number, parent_id?: number): TTask & { category: TTaskCategory } => {
    let rscategory = rootStore.taskStore.getTaskCategory(parent_id, crmId);
    return {
        crm_id: crmId,
        id: -1,
        name: "Тестовое имя",
        picture: "/images/no_image.png",
        task_statement: null,
        description_type: 'text',
        description: null,
        duration: 25*60+15,
        total_time: false,
        reward: 123456,
        task_category_id: parent_id ?? null,
        products: [],
        services: [],
        min_performers: 1,
        created_at: new Date().toISOString(),
        deleted: null,
        category: rscategory.statusCode == 200 ? rscategory.data.data : null,
    };
};

export const makeEmptyTaskCategory = (crmId: number, parent_id?: number): TTaskCategory => {
    return {
        id: -1,
        parent_task_category_id: parent_id != undefined ? parent_id : parent_id,
        name: "Тестовая категория",
        picture: "/images/no_image.png",
        crm_id: crmId,
        task_count: 0,
        quantity_total: 0,
        created_at: new Date().toISOString(),
        deleted: null,
    };
};

export const initialTaskCategories: TTaskCategory[] = [
    {
        id: 1,
        parent_task_category_id: null,
        name: "Родительская",
        picture: "/images/no_image.png",
        crm_id: 1,
        task_count: 1,
        quantity_total: 2,
        created_at: new Date().toISOString(),
        deleted: null
    },
    {
        id: 2,
        parent_task_category_id: 1,
        name: "Дочерняя",
        picture: "/images/no_image.png",
        crm_id: 1,
        task_count: 1,
        quantity_total: 1,
        created_at: new Date().toISOString(),
        deleted: null
    },
];

export const initialTasks: TTask[] = [
    {
        crm_id: 1,
        id: 0,
        name: "Тестовая №1",
        picture: "/images/no_image.png",
        task_statement: null,
        description_type: 'text',
        description: null,
        duration: 25*60+15,
        total_time: false,
        reward: 123456,
        task_category_id: null,
        products: [],
        services: [],
        min_performers: 1,
        created_at: new Date().toISOString(),
        deleted: null
    },
    {
        crm_id: 1,
        id: 1,
        name: "Тестовая №2",
        picture: "/images/no_image.png",
        task_statement: null,
        description_type: 'text',
        description: null,
        duration: 23*60+15,
        total_time: false,
        reward: 123456,
        task_category_id: 1,
        products: [],
        services: [],
        min_performers: 1,
        created_at: new Date().toISOString(),
        deleted: null
    },
    {
        crm_id: 1,
        id: 2,
        name: "Тестовая №3",
        picture: "/images/no_image.png",
        task_statement: null,
        description_type: 'text',
        description: null,
        duration: 23*60+15,
        total_time: false,
        reward: 123456,
        task_category_id: 2,
        products: [],
        services: [],
        min_performers: 1,
        created_at: new Date().toISOString(),
        deleted: null
    },
    {
        crm_id: 1,
        id: 3,
        name: "Тестовая №4",
        picture: "/images/no_image.png",
        task_statement: null,
        description_type: 'text',
        description: null,
        duration: 23*60+15,
        total_time: false,
        reward: 123456,
        task_category_id: 2,
        products: [],
        services: [],
        min_performers: 1,
        created_at: new Date().toISOString(),
        deleted: moment().subtract(1, "hours").toISOString()
    }
]