import { Button } from 'antd';
import { filter, includes, map } from 'lodash';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './style.module.scss';

export const SelectedItems = ({ onSelect, selectedItems, categoryType, data }) => {
    const selectedItemsData = filter(data, (item) => {
        return includes(selectedItems, item.id);
    });
    return (
        <div className={styles.list} style={{ marginTop: '10px' }}>
            {map(selectedItemsData, (item) => (
                <div
                    key={item.id}
                    className={`${styles.item} ${
                        includes(selectedItems, item.id) ? styles.selected : ''
                    }`}
                    onClick={() => onSelect(item.id, categoryType)}
                >
                    {item.name}
                    <Button
                        className={styles.remove}
                        danger
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        size="small"
                        shape="circle"
                        type="primary"
                    />
                </div>
            ))}
        </div>
    );
};
