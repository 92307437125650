import { faGear } from '@fortawesome/free-solid-svg-icons';
import '../style.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderBreadcrumbProfiles } from '@molecules/breadcrumbs/profilesBreadcrumbs/HeaderProfilesBreadcrumb';
import { SettingsManager } from '@classes/settingsManager';
import { Col, List, Row } from 'antd';
import { columnViewStore } from '@molecules/listViewChanger/listViewStore';
import { sectionsData as data} from '../common/constants';

const CustomizableSection = (): JSX.Element => {
    const params = useParams();
    const sectionName = params?.sectionName;
    const creds = SettingsManager.getConnectionCredentials();
    const navigate = useNavigate();
    const sections = [
        {
            name: 'Рабочие листы',
        },
    ]

    return (
        <>
            <div id="app-settings-list">
                <HeaderBreadcrumbProfiles
                    dataIcon={faGear}
                    dataId={data[sectionName]?.name ?? sectionName}
                    dataTitle={'Настройки'}
                    title={data[sectionName]?.name ?? sectionName}
                    route={`/lk/worker/crm/${creds?.crmID}/settings`}
                    isForInvitation={false}
                    dataName={data[sectionName]?.name ?? sectionName}
                    isSpecialty={true}
                />
                <List
                    className="setting-card-list"
                    dataSource={sections}
                    itemLayout="horizontal"
                    grid={{ column: columnViewStore.col }}
                    renderItem={(item) => (
                        <List.Item className="setting-card">
                            <Row
                                gutter={[0, 16]}
                                onClick={() => navigate('worksheets')}
                            >
                                <Col span={24} className="card-body">
                                    <p>{item.name}</p>
                                    <p>Перейти</p>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </div>
        </>
    );
};

export { CustomizableSection };