import { Button, Col, Modal, Row } from 'antd';

import { faTelegram, faVk, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { MessengerButton } from './common/components/messengerButton';
import { shareOnTelegram } from './common/helpers/shareOnTelegram';
import { shareOnVK } from './common/helpers/shareOnVk';
import { shareOnWhatsApp } from './common/helpers/shareOnWhatsApp';

interface Props {
    visible: boolean;
    handleCancel: () => void;
    message: string;
}

export const ContentShareModal: React.FC<Props> = ({ visible, handleCancel, message }) => {
    return (
        <Modal
            title="Поделиться"
            open={visible}
            onCancel={handleCancel}
            centered
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Отмена
                </Button>,
            ]}
        >
            <Row justify={'center'} align={'middle'} gutter={[15, 0]} wrap>
                {/* WHATSAPP */}
                <Col>
                    <MessengerButton
                        name="Whatsapp"
                        onClick={() => shareOnWhatsApp(message)}
                        background="#25D366"
                        icon={faWhatsapp}
                    />
                </Col>

                {/* VK */}
                <Col>
                    <MessengerButton
                        name="VK"
                        onClick={() => shareOnVK(message)}
                        background="#4680C2"
                        icon={faVk}
                    />
                </Col>

                {/*  TELEGRAM */}
                <Col>
                    <MessengerButton
                        name="Telegram"
                        onClick={() => shareOnTelegram(message)}
                        background="#30A3E6"
                        icon={faTelegram}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
