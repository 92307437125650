import { Button, Space } from 'antd';
import moment from 'moment';

import { faChevronDown, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SwitchButtonsProps } from '../models';

import './style.scss';

export const SwitchButtons: React.FC<SwitchButtonsProps> = ({
    orderData,
    handleSwitchOrder,
    handleBtnClick,
    isDetailsActive,
    hideOrderSwitch = false,
}) => {
    return (
        <div className="switch-buttons-container">
            {hideOrderSwitch ? (
                <div></div>
            ) : (
                <div className="switch-buttons-wrapper">
                    <Button
                        className="btn-arrLeft"
                        onClick={() => handleSwitchOrder(orderData.prev)}
                        disabled={orderData?.prev === null}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} color="white" />
                    </Button>
                    <div>
                        <p>
                            <span> №_{orderData?.id} </span> от{' '}
                            {orderData?.created_at
                                ? moment(orderData?.created_at).format('DD/MM/YYYY')
                                : moment().format('DD/MM/YYYY')}
                        </p>
                    </div>
                    <Button
                        className="btn-arrRight"
                        onClick={() => handleSwitchOrder(orderData.next)}
                        disabled={orderData?.next === null}
                    >
                        <FontAwesomeIcon icon={faChevronRight} color="white" />
                    </Button>
                </div>
            )}
            <Button
                className={isDetailsActive ? 'btn-details-order' : 'btn-details-order active'}
                onClick={() =>
                    handleBtnClick({
                        type: 'details',
                        details: !isDetailsActive,
                        // field: activeBtnField,
                    })
                }
            >
                <Space>
                    Детали заказа
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className={isDetailsActive ? 'arrow-active' : 'arrow-inactive'}
                    />
                </Space>
            </Button>
        </div>
    );
};
