import { Loader } from "@atoms/loader";
import { SettingsManager } from "@classes/settingsManager";
import { TTask, TTaskCategory } from "@components/tasks/common/types";
import { TaskCategoryCard } from "@components/tasks/partials/taskCategoryCard/taskCategoryCard";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { rootStore } from "@store/rootStore/instanse";
import { useReactive } from "ahooks";
import { Button, Col, List, Modal, Row, Tabs } from "antd";
import { SyntheticEvent, useEffect } from "react";
import "../../../components/tasks/category/style.scss";

type TProps = {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    multiple: boolean;
    selectedByDefault?: { id: number; name: string; parentId: number; }[];
    setSelected: (selected?: { id: number; name: string; parentId: number; }[]) => void;
    exception?: number;
};

const TaskCategoryPicker = ({
    isModalOpen,
    setIsModalOpen,
    multiple,
    selectedByDefault,
    setSelected,
    exception = null
}: TProps): JSX.Element => {
    type TState = {
        isLoading: boolean;
        selected: { id: number; name: string; parentId: number; }[];
        taskCategoryList: Array<TTaskCategory & { tasks: Array<TTask>; categories: Array<TTaskCategory>; }>;
        currentTab: 'list' | 'selected';
    };
    const state = useReactive<TState>({
        isLoading: true,
        selected: selectedByDefault,
        taskCategoryList: [],
        currentTab: selectedByDefault.length > 0 ? 'selected' : 'list',
    });
    const creds = SettingsManager.getConnectionCredentials();

    function getTaskCategoryList() {
        state.isLoading = true;
        let cats = rootStore.taskStore.getTaskCategoryFlatMap(creds.crmID);
        if (exception != null) cats = cats.filter(c => c.id != exception);
        state.taskCategoryList = cats;
        if (selectedByDefault.length > 0 
            && selectedByDefault[0].name == null 
            && selectedByDefault[0].parentId == null
        ) {
            const cat = state.taskCategoryList.find(c => c.id == selectedByDefault[0].id);
            state.selected[0].name = cat.name;
            state.selected[0].parentId = cat.parent_task_category_id;
        }
        state.isLoading = false;
    }
    
    function handlePickerOk() {
        setSelected(state.selected);
        setIsModalOpen(false);
    }

    function handlePickerCancel() {
        setIsModalOpen(false);
    }

    useEffect(() => {
        state.selected = selectedByDefault;
        getTaskCategoryList();
    }, [isModalOpen]);

    function setSelectElemList(value: { id: number; name: string; parentId: number; }[]): void {
        if (!multiple) state.selected = value.length > 0 ? [value[value.length-1]] : [];
        state.selected = value;
    }

    function deselect(e: SyntheticEvent, item: { id: number; name: string; parentId: number; }) {
        e.stopPropagation();
        state.selected = state.selected.filter(sel => sel.id != item.id);
    }

    function handleCategoryClick(_select: boolean, id: number, _name: string) {
        if (state.selected.find(sel => sel.id == id)) {
            setSelectElemList(state.selected.filter(sel => sel.id != id));
        } else {
            const target = state.taskCategoryList.find(c => c.id == id);
            if (multiple) setSelectElemList([...state.selected, { id: target.id, name: target.name, parentId: target.parent_task_category_id}]);
            else setSelectElemList([{ id: target.id, name: target.name, parentId: target.parent_task_category_id}]);
        }
    }

    return (
        <div>
            <Modal
                className='picker-template task-category-picker'
                title={`Выбор категорий задач`}
                open={isModalOpen}
                onOk={handlePickerOk}
                onCancel={handlePickerCancel}
                cancelText='Отмена'
                wrapClassName='sticky-modal'
            >
                {state.isLoading && <Loader />}
                <Tabs
                    items={[
                        {
                            label: 'Список',
                            key: 'list',
                            children: (
                                <List
                                    className="list-template task-category-list"
                                    dataSource={state.taskCategoryList}
                                    itemLayout="horizontal"
                                    renderItem={(item) => (
                                        <List.Item className="task-category-card">
                                            <TaskCategoryCard
                                                data={item}
                                                selectList={state.selected}
                                                route={null}
                                                handleCategoryClick={handleCategoryClick}
                                                noNavigation
                                            />
                                        </List.Item>
                                    )}
                                />
                            )
                        },
                        {
                            label: 'Выбранные',
                            key: 'selected',
                            children: (
                                <List
                                    className='picker-selected-list'
                                    dataSource={state.selected}
                                    itemLayout='vertical'
                                    bordered
                                    renderItem={(item) => (
                                        <List.Item className='picker-selected-card'>
                                            <Row>
                                                <Col>
                                                    <h3>{item.name}</h3>
                                                    &nbsp;
                                                    <i>{`Категория ${item.parentId == undefined ? 'базовая' : '№'+item.parentId}/#${item.id}`}</i>    
                                                </Col>
                                                <Col className="button-select">
                                                    <Button
                                                        danger
                                                        icon={<FontAwesomeIcon icon={faXmark} />}
                                                        onClick={(e) => deselect(e, item)}
                                                        shape="circle"
                                                        type='primary'
                                                    />
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            )
                        }
                    ]}
                    onChange={() => null}
                    defaultActiveKey={selectedByDefault.length > 0 ? 'selected' : 'list'}
                />
            </Modal>
        </div>
    );
};

export { TaskCategoryPicker };