import { useReactive } from 'ahooks';
import { useEffect } from 'react';

interface Props {
    isRunning: boolean;
    isPaused: boolean;
}

const useStopwatch = ({ isRunning, isPaused }: Props) => {
    const state = useReactive({
        interval: null,
        seconds: 0,
        minutes: 0,
    });

    const formatTime = (value: number) => {
        return value < 10 ? `0${value}` : value.toString();
    };

    const timeController = () => {
        if (state.seconds !== 60) return;
        state.seconds = 0;
        state.minutes++;
    };


    useEffect(() => {
        if (isRunning && !isPaused) {
            state.interval = setInterval(() => {
                state.seconds++;
                timeController();
            }, 1000);
        } else {
            clearInterval(state.interval);
        }

        if (isRunning) return;
        state.minutes = 0;
        state.seconds = 0;
        clearInterval(state.interval);
    }, [isRunning, isPaused]);

    return {
        minutes: formatTime(state.minutes),
        seconds: formatTime(state.seconds),
    };
};

export default useStopwatch;
