import { Loader } from "@atoms/loader";
import { OptionsMenu } from "@molecules/OptionsMenu";
import { HeaderListBreadcrumbs } from "@molecules/breadcrumbs/listsBreadcrumbs/HeaderListBreadcrumbs";
import { FunctionalMenu } from "@molecules/functionalMenu";
import { ShowMoreButton } from "@molecules/paginationControls/showMoreButton";
import { Pagination, Space, Tabs } from "antd";
import { useEffect, useRef } from "react";
import { TAB_LIST, TAB_ROUTES } from "./common/constants";
import { faListCheck, faPlus, faTrashCan, faTrashCanArrowUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import { TMetadata } from "types/metadata";
import { useReactive } from "ahooks";
import { useNavigate, useParams } from "react-router-dom";
import { SettingsManager } from "@classes/settingsManager";
import { TTask, TTaskCategory } from "./common/types";
import { store as pStore } from '@molecules/paginationControls/pageSizeStore';
import { debounce } from "@utils/functions";
import { GlobalConstants } from "@constants/global";
import { TaskFilterStore } from "./partials/taskFilter/filterStore";
import { TaskFilterComponent } from "./partials/taskFilter/taskFilter";
import { RenderTaskList } from "./partials/renderTaskList/renderTaskList";
import "./style.scss";
import { CategoryParams, TaskParams } from "types/getParams";
import { CRMAPIManager } from "@classes/crmApiManager";
import { TaskListResp } from "@api/responseModels/task/taskListResponse";
import { messageService } from "@classes/messageService";
import { Common } from "@classes/commonMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TaskResp } from "@api/responseModels/task/taskResponse";
import { TaskCategoryListResp } from "@api/responseModels/task/taskCategoryListResponse";
import { LastIdStore } from "@pages/lastIdStore";
import { TaskCategoryResp } from "@api/responseModels/task/taskCategoryResponse";

type TState = {
    /** Статус загрузки */
    isLoading: boolean;
    /** Список задач */
    taskList: Array<TTask>;
    /** Список выбранных */
    selectList: Array<{ id: number }>;
    /** Список категорий  */
    taskCategoryList: Array<TTaskCategory & { tasks: Array<TTask>; categories: Array<TTaskCategory> }>;
    /** Текущая страница */ 
    currentPage: number;
    /** Текущая страница категорий */
    currentCategoryPage: number;
    /** Метаданные */
    currentMeta?: TMetadata;
    /** Метаданные категорий */
    currentCategoryMeta?: TMetadata;
    /** Путь проводника */
    categoryPath: Array<{ id: number; name: string }>
};

const taskFilterStore = new TaskFilterStore();
const filter = taskFilterStore.filter;

const TaskList = (): JSX.Element => {
    const creds = SettingsManager.getConnectionCredentials();
    const state = useReactive<TState>({
        isLoading: false,
        taskList: [],
        selectList: [],
        taskCategoryList: [],
        currentPage: 1,
        currentCategoryPage: 1,
        currentMeta: null,
        currentCategoryMeta: null,
        categoryPath: []
    });

    const tabKey = useRef<string>(TAB_ROUTES.AVAILABLE);
    const { currentTab } = useParams();
    const navigate = useNavigate();
    
    async function getTaskList(page: number = state.currentPage, addition: boolean = false) {
        state.isLoading = true;
        try {
            const params: TaskParams = {
                crm_id: creds.crmID,
                category_id: LastIdStore.lastTaskCategoryId ?? '',
                sort_by: filter.sortBy,
                sort_direction: filter.sortDirection,
                filters: {
                    created_at: [...filter.createdDates],
                    deleted: filter.deleted,
                },
                query: filter.query,
                page: page,
                per_page: pStore.pS
            };
            const taskList = await CRMAPIManager.request<TaskListResp>(async (api) => {
                return await api.getTaskList(params, tabKey.current);
            });          
            if (taskList.errorMessages) {
                state.taskList = [];
                throw taskList.errorMessages;
            }
            if (addition) {
                state.taskList = state.taskList.concat(taskList.data.data);
            }
            else {
                state.taskList = taskList.data.data;
            }
            state.currentMeta = taskList.data.meta;
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    async function getTaskCategoryList(page: number = state.currentCategoryPage, addition: boolean = false) {
        state.isLoading = true;
        try {
            const cats = await CRMAPIManager.request<TaskCategoryListResp>(
                async (api) => {
                    const params: CategoryParams = {
                        crm_id: creds.crmID,
                        category_id: LastIdStore.lastTaskCategoryId ?? null,
                        sort_by: "name",
                        sort_direction: filter.sortDirection,
                        filters: {
                            created_at: [...filter.createdDates],
                            deleted: filter.deleted,
                        },
                        query: filter.query,
                        page,
                        per_page: pStore.pS,
                    };
                    return await api.getTaskCategoryList(params);
                }
            )
            if (cats.errorMessages) throw cats.errorMessages;
            if (addition) {
                state.taskCategoryList = [...state.taskCategoryList, ...cats.data.data];
            }
            else {
                state.taskCategoryList = [...cats.data.data];
            }
            state.currentCategoryMeta = cats.data.meta;
            if (state.categoryPath.length == 0 && LastIdStore.lastTaskCategoryId != null) {
                let tTaskCat = null;
                let tCurFolderId= LastIdStore.lastTaskCategoryId;
                let tPath = [];
                while (tCurFolderId != null) {
                    tTaskCat = await CRMAPIManager.request<TaskCategoryResp>(async (api) => {
                        return await api.getTaskCategory(creds.crmID, tCurFolderId);
                    });
                    tPath = [{ id: tCurFolderId, name: tTaskCat.data.data.name }, ...tPath];
                    tCurFolderId = tTaskCat.data.data.parent_task_category_id;
                }
                state.categoryPath = tPath;
            }
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    async function openCategoryCard(id: number | null, back = false) {
        if (id != null) navigate(`/lk/worker/crm/${creds.crmID}/tasks/nav/${id}`);
        else navigate(`/lk/worker/crm/${creds.crmID}/tasks`);
        LastIdStore.setLastTaskCategoryId(id);
        console.log(LastIdStore.lastTaskCategoryId)
        const tPath = [...state.categoryPath];
        if (back) {
            for (let i = tPath.length - 1; i >= 0; i--) {
                if (tPath[i].id !== id) {
                    tPath.pop();
                } else {
                    break;
                }
            }
        } else {
            const cat = state.taskCategoryList.find((tc) => tc.id == id);
            tPath.push({ id: id, name: cat?.name });
        }
        state.categoryPath = tPath;

        await getTaskList(1);
        await getTaskCategoryList(1);
        state.currentPage = 1;
        state.currentCategoryPage = 1;
    }

    async function deleteSelected() {
        state.isLoading = true;
        while (state.selectList.length != 0) {
            const target = state.selectList.pop();
            try {
                const del = await CRMAPIManager.request<TaskResp>(async (api) => {
                    return await api.removeTask(target.id, creds.crmID);
                });
                if (del.errorMessages) throw del.errorMessages;
            } catch (errors) {
                messageService.sendErrorList(errors);
            }
        }
        await getTaskList(1);
        await getTaskCategoryList(1);
        state.currentPage = 1;
        state.currentCategoryPage = 1;
    }

    async function restoreSelected() {
        state.isLoading = true;
        while (state.selectList.length != 0) {
            const target = state.selectList.pop();
            try {
                const del = await CRMAPIManager.request<TaskResp>(async (api) => {
                    return await api.restoreTask(target.id, creds.crmID);
                });
                if (del.errorMessages) throw del.errorMessages;
            } catch (errors) {
                messageService.sendErrorList(errors);
            }
        }
        await getTaskList(1);
        await getTaskCategoryList(1);
        state.currentPage = 1;
        state.currentCategoryPage = 1;
    }

    function onChangeTab(key: string) {
        state.selectList = [];
        state.currentPage = 1;
        tabKey.current = key;
        getTaskList();
    }

    async function handleChangePage(page: number, pageSize: number) {
        await getTaskList(page);
        state.currentPage = page;
    }

    const handleSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        taskFilterStore.changeQuery(e.target.value)
    }, GlobalConstants.SearchTimeout)

    function openCard(id: number) {
        LastIdStore.setLastTaskCategoryId(id);
        navigate(`/lk/worker/crm/${creds.crmID}/tasks/${id}`);
    }

    function setSelectList(newList: Array<{ id: number }>) {
        state.selectList = newList;
    }

    async function handleShowMoreTasks() {
        await getTaskList(state.currentPage + 1, true);
        state.currentPage = state.currentPage + 1;
    }

    async function handleShowMoreCategories() {
        await getTaskCategoryList(state.currentCategoryPage + 1, true);
        state.currentCategoryPage += 1;
    }

    function handleCreateTaskCategory() {
        navigate(`/lk/worker/crm/${creds.crmID}/task-categories/create`);
    }

    function handleCreateTask() {
        navigate(`/lk/worker/crm/${creds.crmID}/tasks/create`);
    }

    function beforeMountTaskList() {
        Common.checkUserBelongToCrm(GlobalConstants.CrmOrderinUrl).then((res) => {
            if(!res) navigate(`/lk/worker/crm/${creds.crmID}`);
        });
        if (location.pathname.includes("nav")) {
            const path = location.pathname.split('/');
            LastIdStore.setLastTaskCategoryId(Number(path[path.length - 1]));
        }
        else {
            LastIdStore.setLastTaskCategoryId(null);
        }
        getTaskList().then(() => {
            getTaskCategoryList();
        });
    }

    useEffect(() => {
        beforeMountTaskList();
    }, [
        filter.sortBy,
        filter.sortDirection,
        filter.createdDates,
        filter.query
    ]);

    useEffect(() => {
        if (state.currentMeta?.total) {
            let newPage = Math.ceil(state.currentMeta.from / pStore.pS);
            getTaskList(newPage).then(() => {
                state.currentPage = newPage;
            });
        }
        if (state.currentCategoryMeta?.total) {
            let newPage = Math.ceil(state.currentCategoryMeta.from / pStore.pS);
            getTaskCategoryList(newPage).then(() => {
                state.currentCategoryPage = newPage;
            });
        }
    }, [pStore.pS]);

    return (
        <div className="list-template task-list">
            {state.isLoading && <Loader/>}
            <FunctionalMenu
                items={
                    state.selectList.length == 0
                    ? [
                        {
                            key: 'createCategory',
                            label: 'Создать категорию',
                            icon: <FontAwesomeIcon icon={faPlus} />,
                            onClick: handleCreateTaskCategory,
                        },
                        {
                            key: 'createElement',
                            label: 'Создать задачу',
                            icon: <FontAwesomeIcon icon={faPlus} />,
                            onClick: handleCreateTask,
                        },
                    ] : [
                        {
                            key: 'createCategory',
                            label: 'Создать категорию',
                            icon: <FontAwesomeIcon icon={faPlus} />,
                            onClick: handleCreateTaskCategory,
                        },
                        {
                            key: 'createElement',
                            label: 'Создать задачу',
                            icon: <FontAwesomeIcon icon={faPlus} />,
                            onClick: handleCreateTask,
                        },
                        {
                            key: 'abort',
                            label: 'Отмена',
                            icon: <FontAwesomeIcon icon={faXmark} />,
                            onClick: () => {
                                state.selectList = [];
                            },
                        },
                    ]
                }
                dropdownItems={
                    state.selectList.length == 0 ? [] : [
                        {
                            key: tabKey.current == 'deleted' ? 'restore' : 'delete',
                            label: tabKey.current == 'deleted' ? 'Восстановить' : 'Удалить',
                            icon: tabKey.current == 'deleted' ? <FontAwesomeIcon icon={faTrashCanArrowUp} /> : <FontAwesomeIcon icon={faTrashCan} />,
                            onClick: tabKey.current == 'deleted' ? restoreSelected : deleteSelected,
                        }
                    ]
                }
                selected={state.selectList.length}
            />
            <HeaderListBreadcrumbs
                dataTotal={state.currentMeta?.total}
                title={'Задачи'}
                dataTitle={'Задачи'}
                dataIcon={faListCheck}
                dataPrice={null}
                flag={true}
                isProduct={false}
                categoryPath={state.categoryPath}
                openCategoryCard={openCategoryCard}
                onSearch={handleSearchChange}
                searchPlaceHolder='По задачам'
            >
                <Space>
                    <TaskFilterComponent store={taskFilterStore} />
                    <OptionsMenu />
                </Space>
            </HeaderListBreadcrumbs>
            <Tabs
                activeKey={currentTab}
                items={TAB_LIST.map((tab) => {
                    return {
                        label: tab.label,
                        key: tab.key,
                        children: (
                            <RenderTaskList
                                taskList={state.taskList}
                                taskCategoryList={state.taskCategoryList}
                                setSelectList={setSelectList}
                                selectList={state.selectList}
                                openCard={openCard}
                                openCategoryCard={openCategoryCard}
                                currentCategoryMeta={state.currentCategoryMeta}
                                currentCategoryPage={state.currentCategoryPage}
                                handleShowMoreCategories={handleShowMoreCategories}
                            />
                        ),
                    }})
                }
                onChange={onChangeTab}
            />
            { state.currentMeta && state.currentPage < state.currentMeta.last_page && <ShowMoreButton onClick={handleShowMoreTasks} text='Показать ещё' /> }
            <Pagination
                current={state.currentPage}
                defaultCurrent={1}
                onChange={handleChangePage}
                pageSize={pStore.pS}
                showSizeChanger={false}
                total={state.currentMeta?.total ?? 1}
            />
        </div>
    );
};

export { TaskList };