import { CRMAPIBase } from '../crmApiBase';
import { TProduct } from '../../types/product';
import { RequestResult } from '../responseModels/requestResult';
import { ProductResp } from '../responseModels/product/productResponse';
import { ProductListResp } from '../responseModels/product/productListResponse';
import { ServiceListResp } from '../responseModels/service/serviceListResponse';
import { TProductSearch } from '../models/productSearch';
import { ProductParams } from 'types/getParams';

class ProductGroup extends CRMAPIBase {
    /**
     * Добавление товара
     * @param product Товар
     * @returns Результат запроса
     */
    async saveProduct(product: TProduct): Promise<RequestResult<ProductResp>> {
        const result = await this.post<ProductResp>('/products', { ...product });
        return result;
    }

    /**
     * Получение товара
     * @param id ID товара
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getProduct(id: number, crmId: number): Promise<RequestResult<ProductResp>> {
        const result = await this.get<ProductResp>(`/products/${id}`, { crm_id: crmId });
        return result;
    }

    /**
     * Получение списка товаров
     * @param params Параметры получения списка товаров
     * @param page Страница
     * @param perPage На страницу
     * @returns Результат запроса
     */
    async getProductList(params: ProductParams): Promise<RequestResult<ProductListResp>> {
        const result = await this.get<ProductListResp>('/products', {
            ...params,
        });
        return result;
    }

    /**
     * Обновление товара
     * @param product Товар
     * @returns Результат запроса
     */
    async updateProduct(product: TProduct): Promise<RequestResult<ProductResp>> {
        const result = await this.put<ProductResp>(`/products/${product.id}`, { ...product });
        return result;
    }

    /**
     * Удаление товара
     * @param id ID товара
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeProduct(id: number, crmId: number): Promise<RequestResult<ProductResp>> {
        const result = await this.delete<ProductResp>(`/products/${id}`, { crm_id: crmId });
        return result;
    }

    /**
     * Восстановление товара
     * @param id ID товара
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreProduct(id: number, crmId: number): Promise<RequestResult<ProductResp>> {
        const result = await this.post<ProductResp>(`/products/${id}/restore`, { crm_id: crmId });
        return result;
    }

    /**
     * Получение списка услуг товара
     * @param id ID товара
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getProductServiceList(
        id: number,
        crmId: number
    ): Promise<RequestResult<ServiceListResp>> {
        const result = await this.get<ServiceListResp>(`/products/${id}/services`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * @deprecated
     * Поиск товаров
     * @param params ProductParams
     * @param productSearchModel Объект модели поиска товаров
     * @returns Результат запроса
     */
    async searchProduct(
        params: ProductParams,
        productSearchModel: TProductSearch
    ): Promise<RequestResult<ProductListResp>> {
        let query = '';
        Object.keys(productSearchModel).forEach((key) => {
            query += key + '=' + (productSearchModel[key] ? productSearchModel[key] : '') + '&';
        });
        query = query.slice(0, -1);
        const result = await this.get<ProductListResp>('/product/search', {
            ...params,
            query: productSearchModel.name,
        });
        return result;
    }

    /**
     * Обновление категорий товаров
     * @param crmId ID CRM
     * @param products Карта перемещений
     * @returns Результат запроса
     */
    async updateProductCategories(
        crmId: number,
        products: Array<{ id: number; product_category_id: number }>
    ): Promise<RequestResult<ProductListResp>> {
        const result = await this.post<ProductListResp>('/products/categories', {
            crm_id: crmId,
            products: products,
        });
        return result;
    }
}

export { ProductGroup };
