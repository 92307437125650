export const CATEGORY_TYPES = {
    REGIONS: 'regions',
    CATEGORY_OF_ACTIVITY: 'categoryOfActivity',
    SUBCATEGORY_OF_ACTIVITY: 'subcategoryOfActivity',
    COMPANIES: 'companies',
};

export const SECTION_TYPES = {
    SERVICES: 'services',
    PRODUCTS: 'products',
    COMBO: 'combo',
    TASKS: 'tasks',
    USERS: 'users',
    ORDER_OBJECTS: 'order_objects',
    WORKPLACES: 'workplaces',
    SUPPLIERS: 'suppliers',
    COMPANIES: 'companies',
};

export const SECTION_NAMES = {
    services: 'Услуги',
    products: 'Товары',
    combo: 'Комбо',
    tasks: 'Задачи',
    users: 'Пользователи',
    order_objects: 'Объекты заказов',
    workplaces: 'Рабочие места',
    suppliers: 'Поставщики',
    companies: 'Компании',
};
