import { cloneDeep } from 'lodash';
import { TOrderProduct } from 'types/Orders/products';
import { TOrderService } from 'types/Orders/services';

import { EmptyRows } from '../types';
import { TOrderTask } from '../../components/taskTable/common/types';

export const handleFormData = (
    item: TOrderProduct | TOrderService | TOrderTask,
    rowIndex: number,
    itemsData: Array<TOrderProduct> | Array<TOrderService> | Array<TOrderTask>
) => {
    const itemsDataCopy = cloneDeep(itemsData);
    const foundIndex = itemsData.findIndex((findItem) => findItem.id == item.id);

    if (foundIndex != -1) {
        if (confirm('Добавить ещё один к товару?')) {
            itemsDataCopy[foundIndex] = {
                ...itemsData[foundIndex],
                quantity: itemsData[foundIndex].quantity + 1,
                total: (itemsData[foundIndex].quantity + 1) * itemsData[foundIndex].price,
            };
        }
    } else if (foundIndex == -1 && rowIndex !== itemsData.length - 2) {
        itemsDataCopy[rowIndex] = item;
    } else {
        itemsDataCopy[rowIndex] = item;
        itemsDataCopy.at(-1).name = null;
        itemsDataCopy.at(-1).id = EmptyRows.EMPTY_ROW_1;
        itemsDataCopy.push({
            id: EmptyRows.EMPTY_ROW_2,
            name: '',
            quantity: null,
            price: null,
            total: null,
        });
    }
    return itemsDataCopy;
};
