import { Document, Font, Page } from '@react-pdf/renderer';

import { WORKSHEETS } from './common/constants';
import { styles } from './common/styles';
import { CertificateOfCompletion, Check, DiagnosticReport, WorkOrder } from './worksheets';

Font.register({
    family: 'Roboto',
    src: '/fonts/Roboto-Regular.ttf',
    fonts: [
        {
            src: '/fonts/Roboto-Regular.ttf',
        },
        {
            src: '/fonts/Roboto-Bold.ttf',
            fontWeight: 'bold',
        },
        {
            src: '/fonts/Roboto-Italic.ttf',
            fontStyle: 'italic',
        },
    ],
});

export const PdfFilesFactory = ({ type, data }) => {
    const worksheets = {
        [WORKSHEETS.CERTIFICATE_OF_COMPLETION]: <CertificateOfCompletion data={data} />,
        [WORKSHEETS.WORK_ORDER]: <WorkOrder data={data} />,
        [WORKSHEETS.DIAGNOSTIC_REPORT]: <DiagnosticReport data={data} />,
        [WORKSHEETS.CHECK]: <Check data={data} />,
    };

    return (
        <Document title={data.name}>
            <Page size="A4" style={styles.page}>
                {worksheets[type]}
            </Page>
        </Document>
    );
};
