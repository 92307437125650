import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import { CATEGORY_TYPES } from '@organisms/administrator/common/constants';
import {
    filterElementsByCriteria,
    filterElementsBySearchValue,
} from '@organisms/administrator/common/redux/administratorSlice';
import {
    getAdminCategoryOfActivity,
    getAdminCompanies,
    getAdminDataRegions,
    getAdminSubcategoryOfActivity,
} from '@organisms/administrator/common/redux/selectors';
import { CategoriesMenu } from '@organisms/administrator/components/categoriesMenu/categoriesMenu';

import style from './style.module.scss';

const menuStyle = {
    width: '90%',
    paddingRight: '10px',
    marginBottom: '10px',
};

export const AdminFilter = ({ hideHeader = false }) => {
    const regions = useSelector(getAdminDataRegions);
    const categoryOfActivity = useSelector(getAdminCategoryOfActivity);
    const subcategoryOfActivity = useSelector(getAdminSubcategoryOfActivity);
    const companies = useSelector(getAdminCompanies);

    const dispatch = useDispatch();

    const [regionsData] = useState([
        { id: 1, name: 'Москва' },
        { id: 2, name: 'Подольск' },
        { id: 3, name: 'Королев' },
        { id: 4, name: 'Мытищи' },
        { id: 5, name: 'Красногорск' },
    ]);

    const [categoryOfActivityData] = useState([
        { id: 1, name: 'Авто' },
        { id: 2, name: 'Недвижимость' },
        { id: 3, name: 'Красота' },
        { id: 4, name: 'Здоровье' },
        { id: 5, name: 'Техника' },
    ]);

    const [subcategoryOfActivityData] = useState([
        { id: 1, name: 'Сервис' },
        { id: 2, name: 'Запчасти' },
        { id: 3, name: 'Доставка' },
        { id: 4, name: 'Шиномонтаж' },
        { id: 5, name: 'Замена масла' },
    ]);

    const [companiesData] = useState([
        { id: 1, name: 'Альфа' },
        { id: 2, name: 'Строй' },
        { id: 3, name: 'Бета' },
        { id: 4, name: 'Гамма' },
        { id: 5, name: 'Трио' },
    ]);

    const handleCategorySelect = (itemId, categoryType) => {
        dispatch(filterElementsBySearchValue(''));
        dispatch(filterElementsByCriteria({ itemId, categoryType }));
    };

    return (
        <div className={style.categories_wrapper}>
            {!hideHeader && (
                <div className={style.header}>
                    <h2>Административная панель</h2>
                </div>
            )}

            <h3 className={style.category_type}>Регион</h3>
            <CategoriesMenu
                categoryType={CATEGORY_TYPES.REGIONS}
                data={regionsData}
                onSelect={handleCategorySelect}
                selectedItems={regions}
                style={menuStyle}
            />

            <h3 className={style.category_type}>Категория деятельности</h3>
            <CategoriesMenu
                categoryType={CATEGORY_TYPES.CATEGORY_OF_ACTIVITY}
                data={categoryOfActivityData}
                onSelect={handleCategorySelect}
                selectedItems={categoryOfActivity}
                style={menuStyle}
            />

            <h3 className={style.category_type}>Подкатегория деятельности</h3>
            <CategoriesMenu
                categoryType={CATEGORY_TYPES.SUBCATEGORY_OF_ACTIVITY}
                data={subcategoryOfActivityData}
                onSelect={handleCategorySelect}
                selectedItems={subcategoryOfActivity}
                style={menuStyle}
                disabled={isEmpty(categoryOfActivity)}
            />

            <h3 className={style.category_type}>Компания</h3>
            <CategoriesMenu
                categoryType={CATEGORY_TYPES.COMPANIES}
                data={companiesData}
                onSelect={handleCategorySelect}
                selectedItems={companies}
                style={menuStyle}
            />
        </div>
    );
};
