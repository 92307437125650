import { chunk, filter, map } from 'lodash';
import moment from 'moment';

import { Text, View } from '@react-pdf/renderer';

import { styles } from '../../../common/styles';

export const Header = ({ data }) => {
    const filteredOrderFields = filter(data.order_fields, (field) => Boolean(field.pivot.value));
    const orderFields = chunk(filteredOrderFields, 2);

    return (
        <View style={styles.container}>
            <Text style={styles.title}>
                {data.name} от {moment(data.finished_at ?? new Date()).format('DD.MM.YYYY')}
            </Text>
            <Text style={styles.fwBold}>
                Исполнитель:&nbsp;&nbsp;&nbsp;&nbsp; {data.crm.organization_name}
            </Text>
            <Text style={styles.fwBold}>
                Адрес:&nbsp;&nbsp;&nbsp;&nbsp; ул. Розы Люксенбург 31А, г. Белгород
            </Text>
            <Text style={styles.fwBold}>ИНН:&nbsp;&nbsp;&nbsp;&nbsp; 311301369020</Text>
            <Text style={styles.fwBold}>
                Телефон:&nbsp;&nbsp;&nbsp;&nbsp; 8{'('}4722{')'} 219-299
            </Text>
            <View style={styles.hr_1} />
            <Text style={styles.fwBold}>
                Заказчик:&nbsp;&nbsp;&nbsp;&nbsp; {data.customer.name} {data.customer.surname}
            </Text>
            <Text style={[styles.fwBold]}>
                Адрес:&nbsp;&nbsp;&nbsp;&nbsp; {data.customer.address}
            </Text>
            <Text style={styles.fwBold}>
                Телефон:&nbsp;&nbsp;&nbsp;&nbsp; {data.customer.phone}
            </Text>
            <View style={styles.rowSpaceBetween}>
                <Text style={[styles.fwBold, styles.mt_5, styles.flex_3]}>
                    Объект заказа:&nbsp;&nbsp;&nbsp;&nbsp; {data.order_object?.name}
                </Text>
                <Text style={[styles.fwBold, styles.mt_5, styles.flex_2]}>
                    Принял:&nbsp;&nbsp;&nbsp;&nbsp; Николай Д.
                </Text>
            </View>
            <View style={[styles.hr_1, styles.m_0]} />

            <View style={styles.rowSpaceBetween}>
                <Text style={[styles.fwBold, styles.mt_5, styles.flex_3]}>
                    Заказ принят:&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    {moment(data.started_at).format('DD.MM.YYYY // HH:mm')}
                </Text>
                <Text style={[styles.fwBold, styles.mt_5, styles.flex_2]}>
                    Завершение заказа:&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    {moment(data.finished_at ?? new Date()).format('DD.MM.YYYY // HH:mm')}
                </Text>
            </View>
            <View style={[styles.hr_1, styles.m_0, styles.mb_5]} />

            {map(orderFields, (fields) => {
                return (
                    <View style={styles.rowSpaceBetween}>
                        {map(fields, (field, index) => {
                            return (
                                <Text
                                    style={[
                                        styles.fwBold,
                                        index === 0 ? styles.flex_3 : styles.flex_2,
                                    ]}
                                >
                                    {field.name}:&nbsp;&nbsp;&nbsp;&nbsp; {field.pivot.value}
                                </Text>
                            );
                        })}
                    </View>
                );
            })}
        </View>
    );
};
