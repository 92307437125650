import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { List } from 'antd';
import { observer } from 'mobx-react';

import { SettingsManager } from '@classes/settingsManager';
import { columnViewStore } from '@molecules/listViewChanger/listViewStore';

import { getBrigade } from './common/redux/selectors';
import { Brigade } from './common/types';
import { BrigadeCard } from './partials';

export const BrigadeList = observer(() => {
    const brigade = useSelector(getBrigade);
    const navigate = useNavigate();

    const creds = SettingsManager.getConnectionCredentials();

    return (
        <List
            className="worker-card-list"
            dataSource={brigade}
            itemLayout="horizontal"
            grid={{ column: columnViewStore.col }}
            renderItem={(item: Brigade) => (
                <List.Item>
                    <BrigadeCard
                        name={item.name}
                        number={item.workplaces.length}
                        onClick={() =>
                            navigate(`/lk/worker/crm/${creds.crmID}/workers/tabs/groups/${item.id}/workplaces`)
                        }
                    />
                </List.Item>
            )}
        />
    );
});
