import { useCreation, useReactive, useToggle } from 'ahooks';
import { Button, Col, Row } from 'antd';

import RenderIf from '@common/RenderIf';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { topicCategory } from '@organisms/orders/profile/common/constants';
import { CommentTopicsPicker } from '@organisms/orders/profile/components/orderComment/common/components/commentTopicsPicker';

import { TopicType } from '../../common/types';

import './topic.scss';

export const Topic = () => {
    const state = useReactive({
        topic: null,
    });

    const [isTopicsModalVisible, { toggle: toggleTopicsModal }] = useToggle(false);

    const selectTopic = (topic: TopicType) => {
        state.topic = topic;
    };

    const topicTitle = useCreation(() => {
        if (!state.topic) return null;
        return `${topicCategory[state.topic.category]}: ${state.topic.name}`;
    }, [state.topic]);

    return (
        <Row className="topic-block" align={'middle'}>
            <Col span={4}>
                <span className="topic-name">Тема:</span>
            </Col>
            <Col span={20}>
                <Button onClick={toggleTopicsModal} className="attribute-btn">
                    <p className="comment-topic">{topicTitle ?? 'Без темы'}</p>
                    <FontAwesomeIcon className="attribute-btn-arrow" icon={faChevronDown} />
                </Button>
            </Col>
            <RenderIf condition={isTopicsModalVisible}>
                <CommentTopicsPicker
                    isModalOpen={isTopicsModalVisible}
                    setIsModalOpen={toggleTopicsModal}
                    currentItems={state.topic}
                    setCurrentItems={selectTopic}
                />
            </RenderIf>
        </Row>
    );
};
