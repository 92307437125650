import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

import { SECTION_TYPES } from '@organisms/administrator/common/constants';
import { getQuantity } from '@organisms/administrator/common/helpers/getQuantity';
import { getDataResultList } from '@organisms/administrator/common/redux/selectors';

import { ResultsHeader } from './blocks/resultsHeader';

import style from './style.module.scss';

export const Results = () => {
    const dataResultList = useSelector(getDataResultList);

    const navigate = useNavigate();

    return (
        <>
            <ResultsHeader quantity={dataResultList.length} />

            <div className={style.results_container}>
                <Button
                    block
                    className=""
                    onClick={() => navigate(`/lk/admin/${SECTION_TYPES.SERVICES}`)}
                >
                    <span>
                        Услуги{' '}
                        <span className={style.quantity}>
                            ({getQuantity(dataResultList, SECTION_TYPES.SERVICES)})
                        </span>
                    </span>
                </Button>
                <Button
                    block
                    className=""
                    onClick={() => navigate(`/lk/admin/${SECTION_TYPES.PRODUCTS}`)}
                >
                    <span>
                        Товары{' '}
                        <span className={style.quantity}>
                            ({getQuantity(dataResultList, SECTION_TYPES.PRODUCTS)})
                        </span>
                    </span>
                </Button>
                <Button
                    block
                    className=""
                    onClick={() => navigate(`/lk/admin/${SECTION_TYPES.COMBO}`)}
                >
                    <span>
                        Комбо{' '}
                        <span className={style.quantity}>
                            ({getQuantity(dataResultList, SECTION_TYPES.COMBO)})
                        </span>
                    </span>
                </Button>
                <Button
                    block
                    className=""
                    onClick={() => navigate(`/lk/admin/${SECTION_TYPES.TASKS}`)}
                >
                    <span>
                        Задачи{' '}
                        <span className={style.quantity}>
                            ({getQuantity(dataResultList, SECTION_TYPES.TASKS)})
                        </span>
                    </span>
                </Button>
                <Button
                    block
                    className=""
                    onClick={() => navigate(`/lk/admin/${SECTION_TYPES.ORDER_OBJECTS}`)}
                >
                    <span>
                        Объекты заказов{' '}
                        <span className={style.quantity}>
                            ({getQuantity(dataResultList, SECTION_TYPES.ORDER_OBJECTS)})
                        </span>
                    </span>
                </Button>
                <Button
                    block
                    className=""
                    onClick={() => navigate(`/lk/admin/${SECTION_TYPES.WORKPLACES}`)}
                >
                    <span>
                        Рабочие места{' '}
                        <span className={style.quantity}>
                            ({getQuantity(dataResultList, SECTION_TYPES.WORKPLACES)})
                        </span>
                    </span>
                </Button>
                <Button
                    block
                    className=""
                    onClick={() => navigate(`/lk/admin/${SECTION_TYPES.SUPPLIERS}`)}
                >
                    <span>
                        Поставщики{' '}
                        <span className={style.quantity}>
                            ({getQuantity(dataResultList, SECTION_TYPES.SUPPLIERS)})
                        </span>
                    </span>
                </Button>
                <Button
                    block
                    className=""
                    onClick={() => navigate(`/lk/admin/${SECTION_TYPES.COMPANIES}`)}
                >
                    <span>
                        Компании{' '}
                        <span className={style.quantity}>
                            ({getQuantity(dataResultList, SECTION_TYPES.COMPANIES)})
                        </span>
                    </span>
                </Button>
                <Button
                    block
                    className=""
                    onClick={() => navigate(`/lk/admin/${SECTION_TYPES.USERS}`)}
                >
                    <span>
                        Пользователи{' '}
                        <span className={style.quantity}>
                            ({getQuantity(dataResultList, SECTION_TYPES.USERS)})
                        </span>
                    </span>
                </Button>
            </div>
        </>
    );
};
