import { Loader } from '@atoms/loader';
import { SettingsManager } from '@classes/settingsManager';
import { ServiceProductList } from '@molecules/renderList/serviceProductList';
import { LastIdStore } from '@pages/lastIdStore';
import { useReactive } from 'ahooks';
import { Button, Col, List, Modal, Pagination, Row, Tabs } from 'antd';
import { SyntheticEvent, useEffect } from 'react';
import { CategoryParams, ServiceParams } from 'types/getParams';
import { TMetadata } from 'types/metadata';
import { TService } from 'types/service';
import { TServiceCategory } from 'types/serviceCategory';
import { store as pStore } from '@molecules/paginationControls/pageSizeStore';
import { CRMAPIManager } from '@classes/crmApiManager';
import { ServiceListResp } from '@api/responseModels/service/serviceListResponse';
import { messageService } from '@classes/messageService';
import { ShowMoreButton } from '@molecules/paginationControls/showMoreButton';
import { ServiceCategoryListResp } from '@api/responseModels/service/serviceCategoryListResponse';
import { ServiceCategoryResp } from '@api/responseModels/service/serviceCategoryResponse';
import { HeaderListBreadcrumbs } from '@molecules/breadcrumbs/listsBreadcrumbs/HeaderListBreadcrumbs';
import { faScrewdriverWrench, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type TProps = {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    multiple: boolean;
    selectedByDefault?: { id: number; name: string; parentId: number; }[];
    setSelected: (selected?: { id: number; name: string; parentId: number; }[]) => void;
};

const ServicePicker = ({
    isModalOpen,
    setIsModalOpen,
    multiple,
    selectedByDefault,
    setSelected,
}: TProps): JSX.Element => {
    type TState = {
        isLoading: boolean;
        selected: { id: number; name: string; parentId: number; }[];
        serviceList: Array<TService & { category: TServiceCategory }>;
        serviceCategoryList: Array<
            TServiceCategory & {
                services: Array<TService>;
                categories: Array<TServiceCategory>;
            }
        >;
        currentTab: 'list' | 'selected';
        currentCategoryMeta?: TMetadata;
        categoryPath: Array<{ id: number; name: string }>;
        currentMeta?: TMetadata;
        currentCategoryPage: number;
        currentPage: number;
    };
    
    const state = useReactive<TState>({
        isLoading: true,
        selected: selectedByDefault,
        serviceList: [],
        serviceCategoryList: [],
        currentTab: selectedByDefault.length > 0 ? 'selected' : 'list',
        currentCategoryMeta: null,
        categoryPath: [],
        currentMeta: null,
        currentCategoryPage: 1,
        currentPage: 1,
    });

    async function getServiceList(page: number = state.currentPage, addition: boolean = false) {
        state.isLoading = true;
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const params: ServiceParams = {
                crm_id: creds.crmID,
                category_id: LastIdStore.lastServiceCategoryId ?? '',
                sort_by: 'name',
                sort_direction: 'asc',
                filters: {
                    created_at: null,
                    updated_at: null,
                    price: null,
                    deleted: 'null',
                },
                query: null,
                page,
                per_page: pStore.pS,
            };
            Object.keys(params.filters).filter(key => params.filters[key] === null && delete params.filters[key])
            const servList = await CRMAPIManager.request<ServiceListResp>(async (api) => {
                return await api.getServiceList(params);
            });
            if (servList.errorMessages) {
                state.serviceList = [];
                throw servList.errorMessages;
            }
            if (addition) {
                state.serviceList = [...state.serviceList, ...servList.data.data];
            }
            else {
                state.serviceList = servList.data.data;
            }
            state.currentMeta = servList.data.meta;
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    async function getServiceCategoryList(page: number = state.currentCategoryPage, addition: boolean = false) {
        state.isLoading = true;
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const servCatList = await CRMAPIManager.request<ServiceCategoryListResp>(
                async (api) => {
                    const params: CategoryParams = {
                        crm_id: creds.crmID,
                        category_id: LastIdStore.lastServiceCategoryId ?? '',
                        sort_by: 'name',
                        sort_direction: 'asc',
                        filters: {
                            created_at: null,
                            deleted: 'null',
                        },
                        query: null,
                        page,
                        per_page: pStore.pS,
                    };
                    Object.keys(params.filters).filter(key => params.filters[key] === null && delete params.filters[key])
                    return await api.getServiceCategoryList(params);

                }
            );
            if (servCatList.errorMessages) throw servCatList.errorMessages;
            if (addition){
                state.serviceCategoryList = [...state.serviceCategoryList, ...servCatList.data.data];
            }
            else {
                state.serviceCategoryList = servCatList.data.data;
            }
            state.currentCategoryMeta = servCatList.data.meta;
            if (state.categoryPath.length == 0 && LastIdStore.lastServiceCategoryId != null) {
                let tServCat = null;
                let tCurFolderId = LastIdStore.lastServiceCategoryId;
                let tPath = [];
                while (tCurFolderId != null) {
                    tServCat = await CRMAPIManager.request<ServiceCategoryResp>(async (api) => {
                        return await api.getServiceCategory(tCurFolderId, creds.crmID);
                    });
                    tPath = [{ id: tCurFolderId, name: tServCat.data.data.name }, ...tPath];
                    tCurFolderId = tServCat.data.data.parent_service_category_id;
                }
                state.categoryPath = tPath;
            }
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    function handlePickerOk() {
        setSelected(state.selected);
        setIsModalOpen(false);
    }

    function handlePickerCancel() {
        setIsModalOpen(false);
    }

    async function handleShowMoreServices() {
        await getServiceList(state.currentPage + 1, true);
        state.currentPage += 1;
    }

    useEffect(() => {
        state.selected = selectedByDefault;
        LastIdStore.setLastServiceCategoryId(null);
        getServiceList().then(() => {
            getServiceCategoryList();
        });
    }, [isModalOpen]);

    function setSelectElemList(value: { id: number; name: string; parentId: number; }[]): void {
        if (!multiple) state.selected = value.length > 0 ? [value[value.length-1]] : [];
        state.selected = value;
    }

    useEffect(() => {
        if(state.currentMeta?.total) {
            let newPage = Math.ceil(state.currentMeta.from / pStore.pS);
            getServiceList(newPage).then(() => {
                state.currentPage = newPage;
            });
        }
        if(state.currentCategoryMeta?.total) {
            let newPage = Math.ceil(state.currentCategoryMeta.from / pStore.pS);
            getServiceCategoryList(newPage).then(() => {
                state.currentCategoryPage = newPage;
            });
        }
    }, [pStore.pS]);

    async function handleChangePage(newPage: number) {
        await getServiceList(newPage);
        state.currentPage = newPage;
    }

    async function handleShowMoreCategories() {
        await getServiceCategoryList(state.currentCategoryPage + 1, true);
        state.currentCategoryPage += 1;
    }

    async function openCategoryCard(id: number | null, back = false) {
        LastIdStore.setLastServiceCategoryId(id);
        const tPath = [...state.categoryPath];
        if (back) {
            for (let i = tPath.length - 1; i >= 0; i--) {
                if (tPath[i].id !== id) {
                    tPath.pop();
                } else {
                    break;
                }
            }
        } else {
            const category = state.serviceCategoryList.find((sc) => sc.id == id);
            tPath.push({ id: id, name: category?.name });
        }
        state.categoryPath = tPath;

        await getServiceList(1);
        await getServiceCategoryList(1);
        state.currentPage = 1;
        state.currentCategoryPage = 1;
    }

    function deselect(e: SyntheticEvent, item: { id: number; name: string; parentId: number; }) {
        e.stopPropagation();
        state.selected = state.selected.filter(sel => sel.id != item.id);
    }

    return (
        <div>
            <Modal
                className='picker-template service-picker'
                title={`Выбор услуг${multiple ? '' : 'и'}`}
                open={isModalOpen}
                onOk={handlePickerOk}
                onCancel={handlePickerCancel}
                cancelText='Отмена'
                wrapClassName='sticky-modal'
            >
                {state.isLoading && <Loader />}
                <Tabs
                    items={[
                        {
                            label: 'Список',
                            key: 'list',
                            children: (
                                <div>
                                    <HeaderListBreadcrumbs
                                        dataTotal={null}
                                        title={'Услуги'}
                                        dataTitle={'Услуги'}
                                        dataIcon={faScrewdriverWrench}
                                        dataPrice={null}
                                        flag={true}
                                        isProduct={false}
                                        categoryPath={state.categoryPath}
                                        openCategoryCard={openCategoryCard}
                                        onSearch={() => null}
                                        showSearchInput={false}
                                        searchPlaceHolder='По услугам'
                                        headless={true}
                                    ><></></HeaderListBreadcrumbs>
                                    <ServiceProductList
                                        avoidRootStore={true}
                                        noQuantitySelector={true}
                                        noNavigation={true}
                                        productCategoryList={null}
                                        serviceCategoryList={state.serviceCategoryList}
                                        productList={null}
                                        serviceList={state.serviceList}
                                        selectCatList={[]}
                                        selectElemList={state.selected}
                                        setSelectCatList={() => null}
                                        setSelectElemList={setSelectElemList}
                                        openCategoryCard={openCategoryCard}
                                        openCard={() => null}
                                        currentCategoryMeta={state.currentCategoryMeta}
                                        currentCategoryPage={state.currentCategoryPage}
                                        handleShowMoreCategories={handleShowMoreCategories}
                                    />
                                    { state.currentMeta && state.currentPage < state.currentMeta.last_page && <ShowMoreButton onClick={handleShowMoreServices} text='Показать ещё' /> }
                                    <Pagination
                                        current={state.currentPage}
                                        defaultCurrent={1}
                                        onChange={handleChangePage}
                                        pageSize={pStore.pS}
                                        showSizeChanger={false}
                                        total={state.currentMeta?.total ?? 1}
                                    />
                                </div>
                            )
                        },
                        {
                            label: 'Выбранные',
                            key: 'selected',
                            children: (
                                <List
                                    className='picker-selected-list'
                                    dataSource={state.selected}
                                    itemLayout='vertical'
                                    bordered
                                    renderItem={(item) => (
                                        <List.Item className='picker-selected-card'>
                                            <Row>
                                                <Col>
                                                    <h3>{item.name}</h3>
                                                    &nbsp;
                                                    <i>{`Категория ${item.parentId == undefined ? 'базовая' : '№'+item.parentId}/#${item.id}`}</i>    
                                                </Col>
                                                <Col className="button-select">
                                                    <Button
                                                        danger
                                                        icon={<FontAwesomeIcon icon={faXmark} />}
                                                        onClick={(e) => deselect(e, item)}
                                                        shape="circle"
                                                        type='primary'
                                                    />
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            )
                        }
                    ]}
                    onChange={() => null}
                    defaultActiveKey={selectedByDefault.length > 0 ? 'selected' : 'list'}
                />
            </Modal>
        </div>
    );
};

export { ServicePicker };