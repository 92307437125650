import { filter } from 'lodash';
import { TWorksheetFields } from 'types/worksheets/worksheetFields';

export const getFilteredWorksheetFields = (worksheetFields: TWorksheetFields): TWorksheetFields => {
    const filteredOrderFields = filter(
        worksheetFields.order_fields,
        (field) => field.pivot.is_used === 1 && field.pivot.is_shown === 1
    );

    return {
        ...worksheetFields,
        order_fields: filteredOrderFields,
    };
};
