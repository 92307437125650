import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMemoizedFn, useReactive } from 'ahooks';
import { DatePicker, Dropdown, Menu, Space } from 'antd';
import { isNil } from 'lodash';
import moment from 'moment';
import { TOrder } from 'types/Orders/order';

import { FilesViewer } from '@components/common/filesPreview';
import { useGetPdfData } from '@components/orders/pdfFile';
import { GlobalConstants } from '@constants/global';
import { faBars, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalizationProvider, MobileTimePicker, ruRU } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { OrderCustomerPicker } from '@organisms/pickers/orderCustomerPicker';

import { setOrderData } from '../../common/redux/orderSlice';
import { getOrder } from '../../common/redux/selectors';

import './breadcrumbHeader.scss';

const planed_at = {
    start: 'start_planned_at',
    finish: 'finish_planned_at',
};

interface Props {
    hidePdfView?: boolean;
}

export const BreadcrumbHeader: React.FC<Props> = memo(({ hidePdfView = false }) => {
    const state = useReactive({
        isModalOpen: false,
        currentOrderCustomer: null,
    });

    const dispatch = useDispatch();

    const orderData: TOrder = useSelector(getOrder);

    const setIsModalOpen = (value: boolean) => {
        state.isModalOpen = value;
    };

    const [isOpen, setIsOpen] = useState(false);

    const pdfData = useGetPdfData(orderData);

    const openPdfPreview = () => {
        setIsOpen(true);
    };

    const handleClosePreview = () => {
        setIsOpen(false);
    };

    const setCurrentOrderCustomer = useMemoizedFn((customer) => {
        dispatch(
            setOrderData({
                ...orderData,
                customer,
            })
        );
    });

    const handleDateChange = useMemoizedFn(
        (value: moment.Moment, dateString: string, type: string, planed_at: string) => {
            let date = new Date(orderData[planed_at]);
            if (type === 'date' && value) {
                const ymd = value
                    .format('DD-MM-YYYY')
                    .split('-')
                    .map((s) => Number(s));
                date.setFullYear(ymd[2], ymd[1] - 1, ymd[0]);
            } else {
                date = null;
            }
            if (type === 'time') {
                const hm = dateString.split(':').map((s) => Number(s));
                date.setHours(hm[0], hm[1]);
            }
            dispatch(
                setOrderData({
                    ...orderData,
                    [planed_at]: date,
                })
            );
        }
    );

    return (
        <div className="breadcrumb-header" style={{ height: 'auto', paddingTop: 4 }}>
            {!hidePdfView && (
                <FilesViewer
                    data={pdfData}
                    funcMenuData={[
                        {
                            key: 'download',
                            label: 'Скачать',
                            icon: <FontAwesomeIcon icon={faDownload} color="" />,
                            onClick: () => {},
                        },
                    ]}
                    openFileId={0}
                    open={isOpen}
                    onClose={() => handleClosePreview()}
                />
            )}
            <OrderCustomerPicker
                isModalOpen={state.isModalOpen}
                setIsModalOpen={setIsModalOpen}
                currentOrderCustomer={state.currentOrderCustomer}
                setCurrentOrderCustomer={setCurrentOrderCustomer}
            />

            <div className="logo-date-container">
                <img
                    src={`${GlobalConstants.BaseUrlForImg}${
                        orderData?.customer?.picture ?? GlobalConstants.NoImageUrl
                    }`}
                    className="customer-logo"
                    alt="Customer avatar"
                />

                <div>
                    <div className="customer-name">
                        <h2>
                            {orderData?.customer?.name} {orderData?.customer?.surname}
                        </h2>
                        {!isNil(orderData?.customer?.customer_profile?.balance) && (
                            <p>{orderData?.customer?.customer_profile?.balance} ₽</p>
                        )}
                    </div>

                    <div className="order-date">
                        <div style={{ flexGrow: '1' }}>
                            <Space>
                                <span>с</span>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                    localeText={
                                        ruRU.components.MuiLocalizationProvider.defaultProps
                                            .localeText
                                    }
                                >
                                    <MobileTimePicker
                                        ampm={false}
                                        className="bruh-time-picker"
                                        defaultValue={moment()}
                                        disabled={false}
                                        disableFuture={false}
                                        disablePast={false}
                                        format={'HH:mm'}
                                        onChange={() => {}}
                                        value={moment(orderData?.start_planned_at)}
                                        closeOnSelect
                                    />
                                </LocalizationProvider>
                                <span>/</span>
                                <DatePicker
                                    picker="date"
                                    format="DD/MM"
                                    value={moment(orderData?.start_planned_at)}
                                    onChange={(value, dateString) =>
                                        handleDateChange(value, dateString, 'date', planed_at.start)
                                    }
                                    className="date-picker"
                                    allowClear={false}
                                    suffixIcon={null}
                                />
                            </Space>
                        </div>

                        <div>
                            <Space>
                                <span>по</span>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                    localeText={
                                        ruRU.components.MuiLocalizationProvider.defaultProps
                                            .localeText
                                    }
                                >
                                    <MobileTimePicker
                                        ampm={false}
                                        className="bruh-time-picker"
                                        defaultValue={moment()}
                                        disabled={false}
                                        disableFuture={false}
                                        disablePast={false}
                                        format={'HH:mm'}
                                        onChange={() => {}}
                                        value={moment(orderData?.finish_planned_at)}
                                        closeOnSelect
                                    />
                                </LocalizationProvider>

                                <span>/</span>
                                <DatePicker
                                    picker="date"
                                    format="DD/MM"
                                    value={moment(orderData?.finish_planned_at)}
                                    onChange={(value, dateString) =>
                                        handleDateChange(
                                            value,
                                            dateString,
                                            'date',
                                            planed_at.finish
                                        )
                                    }
                                    className="date-picker"
                                    allowClear={false}
                                    suffixIcon={null}
                                />
                            </Space>
                        </div>
                    </div>
                </div>
            </div>

            <div className="customer-btn-menu">
                <Dropdown.Button
                    className="btn-menu"
                    overlay={
                        <Menu
                            items={[
                                {
                                    // Заглушка
                                    key: 'new-customer',
                                    label: 'Новый клиент',
                                    disabled: true,
                                    onClick: () => {},
                                },
                                {
                                    // Заглушка
                                    key: 'call-list',
                                    label: 'Список звонков',
                                    disabled: true,
                                    onClick: () => {},
                                },
                                {
                                    key: 'customers-list',
                                    label: 'Список клиентов',
                                    onClick: () => {
                                        state.isModalOpen = true;
                                    },
                                },
                                !hidePdfView
                                    ? {
                                          key: 'worksheets-pdf',
                                          label: 'Просмотр',
                                          onClick: openPdfPreview,
                                      }
                                    : null,
                            ]}
                        />
                    }
                    icon={<FontAwesomeIcon icon={faBars} color="white" className="icon-menu" />}
                ></Dropdown.Button>
            </div>
        </div>
    );
});
