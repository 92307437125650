import React, { ReactNode } from 'react';

type RenderIfProps = {
    condition: boolean | any;
    children: ReactNode;
};

const RenderIf: React.FC<RenderIfProps> = ({ condition, children }) => {
    if (Boolean(condition)) {
        return <>{children}</>;
    }
    return null;
};

export default RenderIf;
