import { useEffect, useMemo, useState } from 'react';

import { filter, find } from 'lodash';

import { EmptyRows } from '@organisms/orders/profile/common/constants';
import { usePDF } from '@react-pdf/renderer';

import { WORKSHEETS, WORKSHEET_IDS } from './common/constants';
import { PdfData } from './common/types';
import { PdfFilesFactory } from './pdfFilesFactory';

type UseGetPdfDataHook = (data: any) => PdfData[];

export const useGetPdfData: UseGetPdfDataHook = (data) => {
    const { id: orderId } = data || {};
    const filteredData = useMemo(() => {
        if (!data?.id) return undefined;

        return {
            ...data,
            products: data?.products?.filter(
                (product) =>
                    product.id !== EmptyRows.EMPTY_ROW_1 && product.id !== EmptyRows.EMPTY_ROW_2
            ),
            services: data?.services?.filter(
                (service) =>
                    service.id !== EmptyRows.EMPTY_ROW_1 && service.id !== EmptyRows.EMPTY_ROW_2
            ),
        };
    }, [JSON.stringify(data)]);

    const getOrderFieldsForWorksheet = (id) => {
        return filter(data.order_fields, (field) => {
            const worksheet = find(field.worksheets, (worksheet) => {
                return worksheet.id === id;
            });
            if (!worksheet || !worksheet?.pivot?.is_used) return false;

            return true;
        });
    };

    const [certificateOfCompletion, updateCertificateOfCompletion] = usePDF({
        document: null,
    });

    const [workOrder, updateWorkOrder] = usePDF({
        document: null,
    });

    const [diagnosticReport, updateDiagnosticReport] = usePDF({
        document: null,
    });

    const [check, updateCheck] = usePDF({
        document: null,
    });

    const [pdfData, setPdfData] = useState([]);

    const isReady =
        !workOrder.loading &&
        !certificateOfCompletion.loading &&
        !diagnosticReport.loading &&
        !check.loading;

    useEffect(() => {
        if (!isReady) return;

        setPdfData([
            {
                id: '1',
                name: `Акт выполненных работ № ${orderId}`,
                blob: certificateOfCompletion.blob,
                extension: 'pdf',
            },
            {
                id: '2',
                name: `Заказ - наряд № ${orderId}`,
                blob: workOrder.blob,
                extension: 'pdf',
            },
            {
                id: '3',
                name: `Диагностический акт № ${orderId}`,
                blob: diagnosticReport.blob,
                extension: 'pdf',
            },
            {
                id: '4',
                name: `Счёт № ${orderId}`,
                blob: check.blob,
                extension: 'pdf',
            },
        ]);
    }, [isReady]);

    useEffect(() => {
        if (!filteredData) return;

        updateCertificateOfCompletion(
            <PdfFilesFactory
                type={WORKSHEETS.CERTIFICATE_OF_COMPLETION}
                data={{
                    name: `Акт выполненых работ № ${orderId}`,
                    ...filteredData,
                    order_fields: getOrderFieldsForWorksheet(
                        WORKSHEET_IDS.CERTIFICATE_OF_COMPLETION
                    ),
                }}
            />
        );

        updateWorkOrder(
            <PdfFilesFactory
                type={WORKSHEETS.WORK_ORDER}
                data={{
                    name: `Заказ - наряд № ${orderId}`,
                    ...filteredData,
                    order_fields: getOrderFieldsForWorksheet(WORKSHEET_IDS.WORK_ORDER),
                }}
            />
        );

        updateDiagnosticReport(
            <PdfFilesFactory
                type={WORKSHEETS.DIAGNOSTIC_REPORT}
                data={{
                    name: `Диагностический акт № ${orderId}`,
                    ...filteredData,
                    order_fields: getOrderFieldsForWorksheet(WORKSHEET_IDS.DIAGNOSTIC_REPORT),
                }}
            />
        );

        updateCheck(
            <PdfFilesFactory
                type={WORKSHEETS.CHECK}
                data={{
                    name: `Счёт № ${orderId}`,
                    ...filteredData,
                    order_fields: getOrderFieldsForWorksheet(WORKSHEET_IDS.CHECK),
                }}
            />
        );
    }, [filteredData]);

    return pdfData;
};
