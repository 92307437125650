import React from 'react';
import { Button, Carousel, Col, Row } from 'antd';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrderStatusProps } from '../models';
import './style.scss';

export const OrderStatus: React.FC<OrderStatusProps> = ({ sliderStatusRef }) => {
    const btnCarouselProp = {
        slidesToShow: 1,
        centerMode: false,
        draggable: true,
        swipeToSlide: true,
        dots: false,
        infinite: false,
    };

    return (
        <Row className="order-status">
            <Col span={3}>
                <Button
                    className="btn-arrow-left"
                    onClick={() => {
                        const slider = sliderStatusRef.current.innerSlider;
                        if (slider.state.currentSlide == slider.state.slideCount) {
                            return false;
                        } else {
                            return sliderStatusRef.current.prev();
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} color="white" />
                </Button>
            </Col>
            <Col span={18}>
                <Carousel ref={sliderStatusRef} {...btnCarouselProp}>
                    <div>
                        <Button className="btn-status" block>
                            Старт
                        </Button>
                    </div>
                    <div>
                        <Button className="btn-status" block>
                            Ожидается
                        </Button>
                    </div>
                    <div>
                        <Button className="btn-status" block>
                            Завершить
                        </Button>
                    </div>
                    <div>
                        <Button className="btn-status" block>
                            Отменить
                        </Button>
                    </div>
                </Carousel>
            </Col>
            <Col span={3}>
                <Button
                    className="btn-arrow-right"
                    onClick={() => {
                        const slider = sliderStatusRef.current.innerSlider;
                        if (slider.state.currentSlide == slider.state.slideCount) {
                            return false;
                        } else {
                            return sliderStatusRef.current.next();
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faChevronRight} color="white" />
                </Button>
            </Col>
        </Row>
    );
};
