import axios from 'axios';
import { GlobalConstants } from '../constants/global';
import { RequestResult } from './responseModels/requestResult';
import { SettingsManager } from '../classes/settingsManager';
import { CRMAPIBase } from './crmApiBase';
import { TMetadata } from 'types/metadata';

async function sendRequest<T>(
    url: string,
    formData: FormData | object,
    method = 'post',
    params = {}
): Promise<RequestResult<T>> {
    const creds = SettingsManager.getConnectionCredentials();
    if (!creds.token) {
        return {
            statusCode: -1,
            errorCode: '-1',
            errorMessages: ['Срок действия сессии закончился.'],
        };
    }
    const config = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${creds.token}`,
            'Content-type': 'multipart/form-data',
        },
    };
    const fullUrl = `${GlobalConstants.BaseUrl}${url}`;
    let result = new RequestResult<T>();
    try {
        const response = await axios<T & { meta?: TMetadata }>({
            url: fullUrl,
            data: formData,
            ...config,
            method: method,
            params: params,
        });
        result.statusCode = response.status;
        if (response.status >= 200 && response.status <= 300) {
            result.data = response.data;
        } else {
            result = CRMAPIBase.parseErrors<T>(response.status, response.data);
        }
    } catch (error) {
        result = CRMAPIBase.parseErrors<T>(error.response.status, error.response.data);
    }
    return result;
}

export { sendRequest };
