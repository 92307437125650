import { every, filter, includes, isEmpty } from 'lodash';

export const getFilteredDataForComments = (selectedComments, allComments, isEditMode: boolean) => {
    const allCommentsHasFile = every(selectedComments, (comment) => !isEmpty(comment.body.files));

    if (isEditMode) {
        const shownItems = ['save', 'cancel-edit'];
        return filter(allComments, (item) => includes(shownItems, item.key));
    }

    if (allCommentsHasFile && selectedComments.length > 1) {
        const hiddenItems = ['reply', 'edit', 'save', 'cancel-edit'];
        return filter(allComments, (item) => !includes(hiddenItems, item.key));
    }

    if (allCommentsHasFile && selectedComments.length === 1) {
        const hiddenItems = ['save', 'cancel-edit'];
        return filter(allComments, (item) => !includes(hiddenItems, item.key));
    }

    if (selectedComments.length > 1 && !allCommentsHasFile) {
        const hiddenItems = ['reply', 'edit', 'download', 'save', 'cancel-edit'];
        return filter(allComments, (item) => !includes(hiddenItems, item.key));
    }

    if (selectedComments.length === 1 && !allCommentsHasFile) {
        const hiddenItems = ['download', 'save', 'cancel-edit'];
        return filter(allComments, (item) => !includes(hiddenItems, item.key));
    }
    return allComments;
};
