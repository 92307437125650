import { TExceptionType } from "./types";

export const weekDays = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"];

export const exceptionTypes: {[key: TExceptionType['name']] : TExceptionType;} = {
    "Отгул": {
        name: "Отгул", 
        minDuration: 60,
        maxDuration: 12 * 60,
        step: 1,
        dailyBoundaries: false,
    }, 
    "Отпуск": {
        name: "Отпуск", 
        minDuration: 3 * 24 * 60,
        maxDuration: 30 * 24 * 60,
        step: 24 * 60,
        dailyBoundaries: true,
    },
    "Переработка": {
        name: "Переработка", 
        minDuration: 1,
        maxDuration: 12 * 60,
        step: 1,
        dailyBoundaries: false,
    },
    "Выходной": {
        name: "Выходной",
        minDuration: 24 * 60,
        maxDuration: 3 * 24 * 60,
        step: 24 * 60,
        dailyBoundaries: true,
    }
};