export const WORKSHEETS = {
    WORK_ORDER: 'WORK_ORDER',
    CERTIFICATE_OF_COMPLETION: 'CERTIFICATE_OF_COMPLETION',
    DIAGNOSTIC_REPORT: 'DIAGNOSTIC_REPORT',
    CHECK: 'CHECK',
};

export const WORKSHEET_IDS = {
    CERTIFICATE_OF_COMPLETION: 1,
    WORK_ORDER: 2,
    DIAGNOSTIC_REPORT: 3,
    CHECK: 4,
};
