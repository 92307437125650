import { TOrder } from 'types/Orders/order';

export const nameCellLarge = '32%';
export const nameCellSmall = '16%';

export const topicCategory = {
    product: 'Продукт',
    service: 'Услуга',
};

export enum EmptyRows {
    EMPTY_ROW_1 = 'EMPTY_ROW_1',
    EMPTY_ROW_2 = 'EMPTY_ROW_2',
}

export const withoutExecutorFields = [
    {
        id: 1111,
        name: 'ТЕСТ 1',
        price: '827.00',
        quantity: 4,
        total: 3308,
    },
    {
        id: 2222,
        name: 'ТЕСТ 2',
        price: '827.00',
        quantity: 4,
        total: 3308,
    },
    {
        id: 3333,
        name: 'ТЕСТ 3',
        price: '827.00',
        quantity: 4,
        total: 3308,
    },
    {
        id: 4444,
        name: 'ТЕСТ 4',
        price: '827.00',
        quantity: 4,
        total: 3308,
    },
    {
        id: 5555,
        name: 'ТЕСТ 5',
        price: '827.00',
        quantity: 4,
        total: 3308,
    },
    {
        id: EmptyRows.EMPTY_ROW_1,
        name: null,
        quantity: null,
        price: null,
        total: null,
    },
    {
        id: EmptyRows.EMPTY_ROW_2,
        name: '',
        quantity: null,
        price: null,
        total: null,
    },
];

export const initialOrderData: TOrder = {
    id: null,
    crm: null,
    customer: null,
    order_object: null,
    all_required_order_fields: [],
    order_fields: [],
    products: [],
    services: [],
    total_sum: 0,
    order_status: null,
    percent_of_work: null,
    start_planned_at: null,
    started_at: null,
    finish_planned_at: null,
    finished_at: null,
    // Временно
    withoutExecutorFields: [],
};

export const ORDER_OBJECT = 'Объект заказа (кто/что)';
