import { createSelector } from '@reduxjs/toolkit';

export const getAdmin = (state) => state.administrator;

export const getAdminData = (state) => state.administrator.data;

export const getAdminDataRegions = createSelector(getAdminData, (data) => data.regions);

export const getAdminCategoryOfActivity = createSelector(
    getAdminData,
    (data) => data.categoryOfActivity
);

export const getAdminSubcategoryOfActivity = createSelector(
    getAdminData,
    (data) => data.subcategoryOfActivity
);

export const getDataList = createSelector(getAdminData, (data) => data.list);

export const getDataResultList = createSelector(getAdminData, (data) => data.resultList);

export const getAdminCompanies = createSelector(getAdminData, (data) => data.companies);

export const getSearchValue = createSelector(getAdminData, (data) => data.searchValue);
