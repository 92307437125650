import { Col, Divider, Row, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { messageService } from '@classes/messageService';
import { faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Other = ({ isEdit = false }) => {
    return (
        <>
            <Divider>Прочее</Divider>

            <Row className="other-info outer-box">
                <Col>
                    <Row className="label-row">
                        <Col>
                            <p>Страна/Валюта</p>
                        </Col>
                    </Row>
                    <Row
                        className="value-row"
                        onClick={() => {
                            if (isEdit) return;
                            if (navigator.clipboard != null) {
                                messageService.sendError(
                                    'Невозможно копировать, включите доступ к буферу обмена'
                                );
                            } else {
                                navigator.clipboard?.writeText('user?.country');
                                message.success('Страна скопирована');
                            }
                        }}
                    >
                        <Col>
                            {isEdit ? (
                                <TextArea autoSize={{ minRows: 2, maxRows: 5 }} value={''} />
                            ) : (
                                <p>{'-'}</p>
                            )}
                        </Col>
                    </Row>
                </Col>

                <Col>
                    <Row className="label-row">
                        <Col>
                            <p>Адрес</p>
                        </Col>
                    </Row>
                    <Row
                        className="value-row"
                        onClick={() => {
                            if (isEdit) return;
                            if (navigator.clipboard != null) {
                                messageService.sendError(
                                    'Невозможно копировать, включите доступ к буферу обмена'
                                );
                            } else {
                                navigator.clipboard?.writeText('user?.address');
                                message.success('Адрес скопирован');
                            }
                        }}
                    >
                        <Col>
                            {isEdit ? (
                                <TextArea
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                    maxLength={255}
                                    value={''}
                                    onChange={() => {}}
                                />
                            ) : (
                                <p>{'-'}</p>
                            )}
                        </Col>
                    </Row>
                </Col>

                <Col>
                    <Row className="label-row">
                        <Col>
                            <p>Цвет</p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            {isEdit ? (
                                <div
                                    className="user-color"
                                    onClick={() => {}}
                                    style={{
                                        backgroundColor: 'white',
                                    }}
                                />
                            ) : (
                                <div className="user-color" style={{ backgroundColor: 'white' }} />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="other-info outer-box" style={{ justifyContent: 'center' }}>
                <Col className="outer-box">
                    <Row className="label-row">
                        <Col>
                            <p>Доступные клиенты</p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            <p>-</p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="other-info outer-box">
                <Col className="">
                    <Row className="label-row">
                        <Col>
                            <p>
                                Услуги профиля{' '}
                                <FontAwesomeIcon
                                    style={{ color: '#AC7D0C', fontSize: '10px' }}
                                    icon={faStarOfLife}
                                />
                            </p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            <p>{Math.floor(Math.random() * 100)}</p>
                        </Col>
                    </Row>
                </Col>
                <Col className="">
                    <Row className="label-row">
                        <Col>
                            <p>Товары профиля</p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            <p>{Math.floor(Math.random() * 100)}</p>
                        </Col>
                    </Row>
                </Col>
                <Col className="">
                    <Row className="label-row">
                        <Col>
                            <p>Доступные сотрудники</p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            <p>{Math.floor(Math.random() * 100)}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="user-comment outer-box">
                <Col>
                    <Row className="label-row">
                        <Col>
                            <p>Комментарий</p>
                        </Col>
                    </Row>
                    <Row className="value-row">
                        <Col>
                            <p>Работяга</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
