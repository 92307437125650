import { createSlice } from '@reduxjs/toolkit';

import { initialBrigadeList } from '../constants';

const brigadeListSlice = createSlice({
    name: 'brigade',
    initialState: {
        data: initialBrigadeList,
    },
    reducers: {
        addBrigade: (state, { payload }) => {
            state.data.push(payload);
        },
    },
});

export const { addBrigade } = brigadeListSlice.actions;
export default brigadeListSlice.reducer;
