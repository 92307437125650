export const ExcelIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96">
        <path
            fill="#fff"
            d="M27 88h58a1 1 0 0 0 1-1V28H69c-1.654 0-3-1.346-3-3V8H27a1 1 0 0 0-1 1v78a1 1 0 0 0 1 1z"
        />
        <path fill="#fff" d="M85.586 26 68 8.414V25a1 1 0 0 0 1 1h16.586z" />
        <path
            fill="#605E5C"
            fill-rule="evenodd"
            d="M87.121 24.707 69.293 6.879A2.978 2.978 0 0 0 67.172 6H27c-1.654 0-3 1.346-3 3v78c0 1.654 1.346 3 3 3h58c1.654 0 3-1.346 3-3V26.828c0-.8-.313-1.554-.879-2.121zM68 8.414 85.586 26H69a1 1 0 0 1-1-1V8.414zM27 88h58a1 1 0 0 0 1-1V28H69c-1.654 0-3-1.346-3-3V8H27a1 1 0 0 0-1 1v78a1 1 0 0 0 1 1z"
            clip-rule="evenodd"
            opacity=".64"
        />
        <path
            fill="#134A2C"
            d="M79 64H68a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1z"
        />
        <path
            fill="#185C37"
            d="M63 64H52a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1z"
        />
        <path
            fill="#21A366"
            d="M79 56H68a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1z"
        />
        <path
            fill="#107C41"
            d="M63 56H52a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1z"
        />
        <path
            fill="#33C481"
            d="M79 48H68a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1z"
        />
        <path
            fill="#21A366"
            d="M63 48H52a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1z"
        />
        <path
            fill="#107C41"
            d="M12 74h32a4 4 0 0 0 4-4V38a4 4 0 0 0-4-4H12a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4z"
        />
        <path
            fill="#F9F7F7"
            d="m16.95 66 7.883-12.034L17.611 42h5.812l3.942 7.649c.362.725.611 1.266.747 1.623h.051c.262-.58.533-1.143.816-1.69L33.193 42h5.335L31.12 53.9 38.716 66H33.04l-4.554-8.402a6.951 6.951 0 0 1-.544-1.121h-.067c-.08.268-.255.63-.526 1.088L22.66 66h-5.71z"
        />
    </svg>
);
