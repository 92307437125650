import { useDispatch, useSelector } from 'react-redux';

import { Input } from 'antd';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filterElementsBySearchValue } from '@organisms/administrator/common/redux/administratorSlice';
import { getSearchValue } from '@organisms/administrator/common/redux/selectors';

import style from '../style.module.scss';

export const ResultsHeader = ({ quantity }) => {
    const dispatch = useDispatch();

    const searchValue = useSelector(getSearchValue);

    const handleSearch = ({ target: { value } }) => {
        dispatch(filterElementsBySearchValue(value));
    };
    return (
        <>
            <div className={style.header}>
                <h2>
                    Результаты <span className={style.quantity}>({quantity})</span>
                </h2>
            </div>

            <Input
                className={style.search}
                placeholder="Поиск"
                suffix={<FontAwesomeIcon icon={faSearch} color="#ff9100" />}
                allowClear
                onChange={handleSearch}
                value={searchValue}
            />
        </>
    );
};
