import {faCheck, faEdit, faUserGear, faXmark,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Card, Col, Divider, Empty, Input, Row} from 'antd';
import {useEffect, useState, useRef} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {ProfileResp} from '../../../api/responseModels/profile/profileResponse';
import {CRMAPIManager} from '../../../classes/crmApiManager';
import {messageService} from '../../../classes/messageService';
import {SettingsManager} from '../../../classes/settingsManager';
import {TPermissionCategory, TSpecialty} from '../../../types/specialty';
import {Loader} from '../../atoms/loader';
import {Common} from '../../../classes/commonMethods';
import {GlobalConstants} from '@constants/global';
import {HeaderBreadcrumbProfiles} from "@molecules/breadcrumbs/profilesBreadcrumbs/HeaderProfilesBreadcrumb";
import { LastIdStore } from '@pages/lastIdStore';
import {PermissionRequestParams, TPermission} from "types/settings/permissions";
import {PermissionListResponse} from "@api/responseModels/permissions/permissionsResponse";
import { FunctionalMenu } from '@molecules/functionalMenu';

const SpecialtyProfile = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [specialty, setSpecialty] = useState<TSpecialty>(null);
    const [editing, setEditing] = useState<boolean>(false);
    const [permissionList, setPermissionList] = useState<Record<string, TPermissionCategory>>();
    const currentID = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const creds = SettingsManager.getConnectionCredentials();

    function checkCurrentUrl() {
        if (location.pathname.includes('edit')) {
            setEditing(true);
        } else {
            navigate('');
        }
    }

    async function getSpecialty() {
        setIsLoading(true);
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const spec = await CRMAPIManager.request<ProfileResp>(async (api) => {
                return await api.getProfile(LastIdStore.lastSpecialtyId || currentID.current, creds.crmID);
            });
            if (spec.errorMessages) throw spec.errorMessages;
            await setSpecialty(spec.data.data);

            if (editing) {
                await loadAllPermissions(spec.data.data.permissions);
            }
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        setIsLoading(false);
    }

    function handleEditing() {
        navigate(`/lk/worker/crm/${creds.crmID}/specialties/${LastIdStore.lastSpecialtyId || currentID.current}/edit`);
        setEditing(true);
    }

    const preparePermissionsForRequest = (permissionList: Record<string, TPermissionCategory>): Array<number> => {
        return Object.values(permissionList).reduce((acc, permissionCategory) => {
            acc = [
                ...acc,
                ...permissionCategory.permissions.filter(item => item.checked).map(item => item.id)
            ];
            return acc;
        }, []);
    };

    async function handleSaveEditing() {
        if (specialty.name == '') {
            messageService.sendError('Имя профиля не может быть пустым!');
            return;
        }
        preparePermissionsForRequest(permissionList);
        navigate(`/lk/worker/crm/${creds.crmID}/specialties`);

        setIsLoading(true);
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const spec = await CRMAPIManager.request<ProfileResp>(async (api) => {
                return await api.updateProfile(
                    LastIdStore.lastSpecialtyId || currentID.current,
                    creds.crmID,
                    specialty.name
                );
            });
            const result = await CRMAPIManager.request<ProfileResp>(async (api) => {
                const permissions = preparePermissionsForRequest(permissionList);
                return await api.syncSpecialtyPermissions(specialty.id, creds.crmID, permissions);
            });
            if (spec.errorMessages) throw spec.errorMessages;
            if (result.errorMessages) throw result.errorMessages;
            setSpecialty(spec.data.data);
            setEditing(false);
            navigate(`/lk/worker/crm/${creds.crmID}/specialties/${LastIdStore.lastSpecialtyId || currentID.current}`);
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        setIsLoading(false);
    }

    async function handleAbortEditing() {
        setIsLoading(true);
        navigate(`/lk/worker/crm/${creds.crmID}/specialties/${LastIdStore.lastSpecialtyId || currentID.current}`);
        setEditing(false);
        await getSpecialty();
        setIsLoading(false);
    }

    async function checkSpecialtyBelongToCrm() {
        try {
            const urlArr = window.location.pathname.split('/');
            if (urlArr[urlArr.length - 1] == 'edit') {
                currentID.current = Number(urlArr[urlArr.length - 2]);
            } else {
                currentID.current = Number(urlArr[urlArr.length - 1]);
            }
            const spec = await CRMAPIManager.request<ProfileResp>(async (api) => {
                return await api.getProfile(currentID.current, creds.crmID);
            });
            if (!isNaN(currentID.current) && spec.statusCode == 404) {
                navigate(`/lk/worker/crm/${creds.crmID}/specialties`);
            }


        } catch (errors) {
            messageService.sendErrorList(errors);
        }
    }

    const loadAllPermissions = async (permissions: Record<string, TPermissionCategory>) => {
        setIsLoading(true);
        try {
            const permissionsList = await CRMAPIManager.request<PermissionListResponse>(async (api) => {
                const params: PermissionRequestParams = {
                    crm_id: creds.crmID,
                    page: null,
                    per_page: null,
                    filters: {
                        worker_profile_id: null,
                    }
                };
                return await api.getPermissionList(params);
            });
            if (permissionsList.errorMessages) throw permissionsList.errorMessages;

            const permissionsData = Object.values(permissions).reduce((acc, record) => {
                acc = [...acc, ...record.permissions];
                return acc;
            }, []);

            const data = permissionsList.data.data.map((permission) => {
                const foundIndex = permissionsData.findIndex(item => item.id == permission.id);

                permission = {...permission, checked: false};

                if (foundIndex != -1) {
                    permission = {...permission, checked: true};
                }

                return permission;
            }).reduce((acc, rec) => {
                acc[rec.category_name] = {
                    name: acc[rec.category_name]?.name,
                    permissions: acc[rec.category_name]?.permissions ? [...acc[rec.category_name].permissions] : [],
                };
                acc[rec.category_name].name = rec.category_name_locale;
                acc[rec.category_name].permissions = [
                    ...acc[rec.category_name].permissions,
                    {
                        ...rec
                    }
                ];

                return acc;
            }, {});

            console.log(data);

            setPermissionList({...data});
        } catch (e) {
            messageService.sendErrorList(e);
        }
        setIsLoading(false);
    };

    const handleCheckboxPermission = (checked: boolean, permission: TPermission, index: number) => {
        setPermissionList((prev) => {
            const permissions = prev[permission.category_name].permissions;

            permissions[index] = {
                ...permissions[index],
                checked: checked,
            };

            prev[permission.category_name].permissions = [
                ...permissions
            ];

            return {
                ...prev,
            };
        });
    };

    function beforeMountSpecialtyProfile() {
        checkCurrentUrl();
        Common.checkUserBelongToCrm(GlobalConstants.CrmOrderinUrl).then((res) => {
            if (!res) navigate(`/lk/worker/crm/${creds.crmID}`);
        });
        if (!isNaN(currentID.current)) {
            checkSpecialtyBelongToCrm().then(() => {
                if (currentID.current != 0) getSpecialty();
            })
        } else {
            setEditing(true);
        }
    }

    useEffect(() => {
        if (editing) {
            getSpecialty();
        }
    }, [editing])

    useEffect(() => {
        beforeMountSpecialtyProfile()
    }, [currentID.current, LastIdStore.lastSpecialtyId]);

    return (
        <div id="app-specialty-profile">
            {isLoading && <Loader/>}
            <FunctionalMenu
                items={editing
                    ? [
                        {
                            key: 'save',
                            label: 'Сохранить',
                            icon: <FontAwesomeIcon icon={faCheck} />,
                            onClick: async () => {
                                await handleSaveEditing();
                            },
                        },
                        {
                            key: 'abort',
                            label: 'Отменить',
                            icon: <FontAwesomeIcon icon={faXmark} />,
                            onClick: handleAbortEditing,
                        },
                    ] : [
                        {
                            key: 'edit',
                            label: 'Редактировать',
                            icon: <FontAwesomeIcon icon={faEdit} />,
                            onClick: handleEditing,
                        },
                ]}
                dropdownItems={[]}
            />
            <HeaderBreadcrumbProfiles
                dataIcon={faUserGear}
                dataId={specialty?.id}
                dataTitle={'Профили'}
                title={'Профиль работы'}
                route={`/lk/worker/crm/${creds.crmID}/specialties`}
                isForInvitation={false}
                dataName={''}
                isSpecialty={true}
            />
            <div className="specialty-profile">
                <Row className="specialty-info">
                    <Col className="outer-box">
                        <Row className="label-row">
                            <Col>
                                <p>Название</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                {editing ? (
                                    <Input
                                        value={specialty?.name}
                                        onChange={(e) =>
                                            setSpecialty({...specialty, name: e.target.value})
                                        }
                                    />
                                ) : (
                                    <p>{specialty?.name}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="outer-box">
                        <Row className="label-row">
                            <Col>
                                <p>Код</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                <p>{specialty?.id ?? '-'}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider>Разрешения</Divider>
                {!editing && (
                    specialty?.permissions && !!Object.values(specialty?.permissions).length ?
                        (
                            <>
                                <Row justify={'start'}>
                                    {
                                        Object.values(specialty.permissions).map((permissionCategory) => {
                                            return (
                                                <>
                                                    <Col key={permissionCategory.name} span={12}>
                                                        <Card size={'small'} className={'outer-box'}
                                                              title={permissionCategory.name}>
                                                            <div>
                                                                {permissionCategory.permissions.map((permission) => {
                                                                    return (
                                                                        <>
                                                                            <p key={permission.id}>{permission.name_locale}</p>
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </>
                                            );
                                        })
                                    }
                                </Row>

                            </>
                        )
                        : (
                            <>
                                <Row justify={'center'}>
                                    <Col>
                                        <Empty/>
                                    </Col>
                                </Row>
                            </>
                        )
                )}

                {editing && permissionList && Object.values(permissionList).length ? Object.values(permissionList).map((permissionCategory, categoryIndex) => {
                    return (
                        <>
                            <div key={categoryIndex} className={`outer-box`}>
                                <Card title={permissionCategory.name}>
                                    {permissionCategory.permissions.map((permission, index) => {
                                        return (
                                            <>
                                                <div key={index}>
                                                    <label htmlFor={`permission-${index}`}>
                                                        <input type="checkbox" id={`permission-${index}`} onChange={(e) => {handleCheckboxPermission(e.target.checked, permission, index)}} checked={permission.checked}/>
                                                        &nbsp;{permission.name_locale}
                                                    </label>
                                                </div>
                                            </>
                                        );
                                    })}
                                </Card>
                            </div>
                        </>
                    );
                }) : (
                    <></>
                ) }
            </div>
        </div>
    );
};

export {SpecialtyProfile};
