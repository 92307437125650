import { EyeSlashIcon } from '../../svg/EyeSlashIcon';

import './unsupportThumbnail.scss';

export const UnsupportThumbnail = (props) => {
    const { extension } = props;

    return (
        <div className="unsupport-thumbnail-wrapper" {...props}>
            <span>{`"${extension.toUpperCase()}"`}</span>
            <div className="unsupport-thumbnail-icon">
                <EyeSlashIcon />
            </div>
        </div>
    );
};
