import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useReactive } from 'ahooks';
import { Button, Col, Input, Row } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { observer } from 'mobx-react';

import { FileResp } from '@api/responseModels/file/fileResponse';
import { RequestResult } from '@api/responseModels/requestResult';
import { GlobalConstants } from '@constants/global';
import { CategoryPickerUsage } from '@enums/categoryPickerUsage';
import { faCheck, faEdit, faScrewdriverWrench, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeaderBreadcrumbProfiles } from '@molecules/breadcrumbs/profilesBreadcrumbs/HeaderProfilesBreadcrumb';
import { FunctionalMenu } from '@molecules/functionalMenu';
import { ImgUploader } from '@organisms/imgUploader/imgUploader';
import { LastIdStore } from '@pages/lastIdStore';

import { ServiceResp } from '../../../api/responseModels/service/serviceResponse';
import { Common } from '../../../classes/commonMethods';
import { CRMAPIManager } from '../../../classes/crmApiManager';
import { messageService } from '../../../classes/messageService';
import { SettingsManager } from '../../../classes/settingsManager';
import { TService } from '../../../types/service';
import { TServiceCategory } from '../../../types/serviceCategory';
import { Loader } from '../../atoms/loader';
import { DurationPicker } from '../pickers/durationPicker';
import { ServiceCategoryPicker } from '../pickers/serviceCategoryPicker';

dayjs.extend(customParseFormat);

const { TextArea } = Input;

type TState = {
    service: TService & { category: TServiceCategory };
};

const ServiceProfile = observer((): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const state = useReactive<TState>({ service: null });

    const [editing, setEditing] = useState<boolean>(false);
    const [newService, setNewService] = useState<boolean>(false);
    const [isServiceCategoryOpen, setIsServiceCategoryOpen] = useState<boolean>(false);
    const currentID = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const creds = SettingsManager.getConnectionCredentials();
    const [isDurationPickerVisible, setDurationPickerVisible] = useState<boolean>(false);

    function onCancelDuration() {
        setDurationPickerVisible(false);
    }

    async function getService() {
        setIsLoading(true);
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const serv = await CRMAPIManager.request<ServiceResp>(async (api) => {
                return await api.getService(
                    currentID.current || LastIdStore.lastServiceId,
                    creds.crmID
                );
            });
            if (serv.errorMessages) throw serv.errorMessages;
            state.service = serv.data.data;
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        setIsLoading(false);
    }

    async function handleEditing() {
        if (!state.service.permissions?.[1]?.update) {
            messageService.sendError('У вас нет прав на выполнение данной операции');
            return;
        }
        navigate(
            `/lk/worker/crm/${creds.crmID}/services/${
                currentID.current || LastIdStore.lastServiceId
            }/edit`
        );
        setEditing(true);
    }

    function validateBeforeSave(): boolean {
        if (state.service.name == '' || state.service.name === null) {
            messageService.sendError('Имя услуги не может быть пустым');
            return false;
        }
        if (state.service.duration == null || state.service.duration < 0) {
            messageService.sendError('Продолжительность должна быть от 0 минут');
            return false;
        }
        if (
            state.service.price == null ||
            state.service.price <= 0 ||
            state.service.price > 9999999.99
        ) {
            messageService.sendError('Цена должна быть от 0,1 до 9 999 999,99 рублей');
            return false;
        }
        if (
            state.service.min_performers == null ||
            state.service.min_performers < 1 ||
            state.service.min_performers > 10
        ) {
            messageService.sendError('Количество исполнителей должно быть от 1 до 10');
            return false;
        }
        return true;
    }

    async function handleSaveEditing() {
        if (!validateBeforeSave()) {
            return;
        }
        setIsLoading(true);

        try {
            state.service.picture =
                state.service.picture === '/images/services/no_image.svg'
                    ? null
                    : state.service.picture;
            console.log(state.service.min_performers);

            let serv: RequestResult<ServiceResp>;
            if (newService) {
                serv = await CRMAPIManager.request<ServiceResp>(async (api) => {
                    return await api.saveService(state.service);
                });

                if (serv.errorMessages) throw serv.errorMessages;

                state.service = { ...serv.data.data, picture: state.service.picture };
            } else {
                serv = await CRMAPIManager.request<ServiceResp>(async (api) => {
                    return await api.updateService(state.service);
                });

                state.service = { ...serv.data.data, picture: state.service.picture };

                //удаляем картинку
                if (!state.service.picture) {
                    try {
                        const picture = await CRMAPIManager.request<FileResp>(async (api) => {
                            return await api.removeFile({
                                crm_id: state.service.crm_id,
                                model: 'service',
                                entity_id: state.service.id,
                            });
                        });

                        state.service.picture = picture.data.data;
                    } catch (err) {
                        messageService.sendError(err[0][0]);
                    }
                }

                if (serv.errorMessages) throw serv.errorMessages;
            }

            currentID.current = serv.data.data.id;

            if (state.service.picture && typeof state.service.picture == 'object') {
                try {
                    const picture = await CRMAPIManager.request<FileResp>(async (api) => {
                        return await api.saveFile({
                            crm_id: state.service.crm_id,
                            model: 'service',
                            entity_id: state.service.id,
                            picture: state.service.picture,
                        });
                    });

                    state.service.picture = picture.data.data;
                } catch (err) {
                    messageService.sendError(err[0][0]);
                }
            }

            setEditing(false);
            navigate(`/lk/worker/crm/${creds.crmID}/services/${serv.data.data.id}`);
            setNewService(false);
        } catch (err) {
            messageService.sendError(err[0][0]);
        }

        setIsLoading(false);
    }

    async function handleAbortEditing() {
        setIsLoading(true);
        setEditing(false);
        navigate(`/lk/worker/crm/${creds.crmID}/services/`);
        if (newService) {
            LastIdStore.setLastServiceId(null);
        } else {
            await getService();
        }
        setIsLoading(false);
    }

    function handleImageSelect(file: File) {
        state.service.picture = file;
    }

    function handleImageDelete() {
        state.service.picture = null;
    }

    function checkCurrentUrl() {
        if (location.pathname.includes('create')) {
            setEditing(true);
        } else if (location.pathname.includes('edit')) {
            setEditing(true);
        }
    }

    async function checkServiceBelongToCrm() {
        try {
            const urlArr = window.location.pathname.split('/');
            if (urlArr[urlArr.length - 1] == 'edit') {
                currentID.current = Number(urlArr[urlArr.length - 2]);
            } else {
                currentID.current = Number(urlArr[urlArr.length - 1]);
            }
            const serv = await CRMAPIManager.request<ServiceResp>(async (api) => {
                return await api.getService(currentID.current, creds.crmID);
            });
            if (!isNaN(currentID.current) && serv.statusCode == 404) {
                navigate(`/lk/worker/crm/${creds.crmID}/services`);
            }
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
    }

    function beforeMountService() {
        checkCurrentUrl();
        Common.checkUserBelongToCrm(GlobalConstants.CrmOrderinUrl).then((res) => {
            if (!res) navigate(`/lk/worker/crm/${creds.crmID}`);
        });

        if (!isNaN(currentID.current)) {
            checkServiceBelongToCrm().then(() => {
                if (currentID.current != 0 && Number.isNaN(!currentID.current)) {
                    getService();
                } else if (
                    Number.isNaN(currentID.current) ||
                    LastIdStore.lastServiceId === -1 ||
                    (LastIdStore.lastServiceId >= 0 && (LastIdStore.lastServiceId * 10) % 10 === 1)
                ) {
                    setNewService(true);
                    setEditing(true);
                    state.service = {
                        id: -1,
                        name: null,
                        picture: null,
                        description: null,
                        duration: 0,
                        total_time: false,
                        price: 0,
                        service_category_id: LastIdStore.lastServiceCategoryId,
                        min_performers: 1,
                        comment: null,
                        crm_id: SettingsManager.getConnectionCredentials().crmID,
                        category: null,
                    };
                    setIsLoading(false);
                } else {
                    getService();
                }
            });
        }
    }

    useEffect(() => {
        beforeMountService();
    }, [LastIdStore.lastServiceId]);

    return (
        <div id="app-service-profile">
            {isLoading && <Loader />}
            {isServiceCategoryOpen && (
                <ServiceCategoryPicker
                    isModalOpen={isServiceCategoryOpen}
                    setIsModalOpen={setIsServiceCategoryOpen}
                    currentCategorySelected={state.service.category}
                    setCurrentCategorySelected={(sel: TServiceCategory) => {
                        state.service.category = sel;
                        state.service.service_category_id = sel?.id;
                    }}
                    useCase={CategoryPickerUsage.createCategoryOrCreateOrEditProductOrService}
                />
            )}
            {isDurationPickerVisible && (
                <DurationPicker
                    isModalOpen={isDurationPickerVisible}
                    onCancel={onCancelDuration}
                    defaultValue={state.service?.duration}
                    totalTime={state.service?.total_time}
                    onOk={(duration, total_time) => {
                        state.service.duration = duration;
                        state.service.total_time = total_time;
                        setDurationPickerVisible(false);
                    }}
                />
            )}
            <FunctionalMenu
                items={
                    editing
                        ? [
                              {
                                  key: 'save',
                                  label: 'Сохранить',
                                  icon: <FontAwesomeIcon icon={faCheck} />,
                                  onClick: async () => {
                                      await handleSaveEditing();
                                  },
                              },
                              {
                                  key: 'abort',
                                  label: 'Отменить',
                                  icon: <FontAwesomeIcon icon={faXmark} />,
                                  onClick: handleAbortEditing,
                              },
                          ]
                        : [
                              {
                                  key: 'edit',
                                  label: 'Редактировать',
                                  icon: <FontAwesomeIcon icon={faEdit} />,
                                  onClick: handleEditing,
                              },
                          ]
                }
                dropdownItems={[]}
            />
            <HeaderBreadcrumbProfiles
                dataIcon={faScrewdriverWrench}
                dataId={state.service?.id}
                dataTitle={'Услуги'}
                title={'Профиль услуги'}
                route={`/lk/worker/crm/${creds?.crmID}/services`}
                isForInvitation={false}
                dataName={'Новая услуга'}
                isSpecialty={false}
            />
            <div className="service-profile">
                <Row className="service-info">
                    <Col className="avatar outer-box" flex={1}>
                        <ImgUploader
                            photoSrc={state.service?.picture as string}
                            onImageSelect={handleImageSelect}
                            isEdit={editing}
                            onImageDelete={handleImageDelete}
                        />
                    </Col>
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Название</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                {editing ? (
                                    <TextArea
                                        autoSize={{ minRows: 2, maxRows: 5 }}
                                        value={state.service?.name}
                                        onChange={(e) => {
                                            state.service.name = e.target.value;
                                        }}
                                    />
                                ) : (
                                    <p>{state.service?.name}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>

                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Продолжительность</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {editing && (
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<FontAwesomeIcon icon={faEdit} />}
                                        onClick={() => setDurationPickerVisible(true)}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                <p>
                                    {state.service?.duration == 0
                                        ? 'Не задана'
                                        : Common.convertMinutes2Date(state.service?.duration)}
                                </p>
                                <p>
                                    Учитывается
                                    {state.service?.total_time
                                        ? ' общее время'
                                        : ' только рабочее время'}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="service-info">
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Цена</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                {editing ? (
                                    <Input
                                        type="number"
                                        inputMode="numeric"
                                        min={0}
                                        max={9999999}
                                        step={0.01}
                                        value={state.service?.price}
                                        onChange={(e) => {
                                            state.service.price = Number.parseFloat(e.target.value);
                                        }}
                                    />
                                ) : (
                                    <p>{state.service?.price + ' руб'}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Группа</p>
                            </Col>
                        </Row>
                        <Row
                            className="value-row"
                            onClick={() => {
                                if (editing) setIsServiceCategoryOpen(true);
                            }}
                        >
                            <Col>
                                {editing ? (
                                    <a>
                                        {state.service?.service_category_id ??
                                            state.service?.category?.name ??
                                            'Не выбрана'}
                                    </a>
                                ) : (
                                    <p>{state.service?.category?.name ?? 'Не выбрана'}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Соп. товары</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                <p>{Math.floor(Math.random() * 10)}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col className="outer-box" span={8}>
                        <Row className="label-row">
                            <Col>
                                <p>Мин. исполнителей</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                {editing ? (
                                    <Input
                                        type="number"
                                        inputMode="numeric"
                                        min={1}
                                        max={10}
                                        step={1}
                                        value={state.service?.min_performers}
                                        onChange={(e) => {
                                            state.service.min_performers = Number.parseInt(
                                                e.target.value
                                            );
                                        }}
                                    />
                                ) : (
                                    <p>{state.service?.min_performers}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="service-comment outer-box">
                    <Col>
                        <Row className="label-row">
                            <Col>
                                <p>Комментарий</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                {editing ? (
                                    <TextArea
                                        autoSize={{ minRows: 2, maxRows: 5 }}
                                        maxLength={255}
                                        onChange={(e) => {
                                            state.service.comment = e.target.value;
                                        }}
                                        value={state.service?.comment}
                                    />
                                ) : (
                                    <p>{state.service?.comment}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
});

export { ServiceProfile };
