import { find, includes, isEmpty, map, without } from 'lodash';

import { getSelectedMessagesString } from '@helpers/getSelectedMessagesString';
import { createSlice } from '@reduxjs/toolkit';

import { initialComment, replyCommentInitialData } from '../constants';
import { getFilesNameString } from '../helpers/getFilesNameString';

const commentSlice = createSlice({
    name: 'comment',
    initialState: {
        data: [initialComment],
        selectedComments: [],
        selectedFiles: [],
        copiedSuccess: true,
        selectedRecipients: [],
        isEditMode: false,
        isReplyMode: false,
        replyCommentData: replyCommentInitialData,
        filterData: {
            topics: [],
            members: [],
            files: [],
            date: null,
            searchText: '',
        },
    },
    reducers: {
        addComment: (state, { payload }) => {
            state.data.push(payload);
        },

        selectComment: (state, { payload }) => {
            if (!includes(state.selectedComments, payload)) {
                state.selectedComments.push(payload);
                return;
            }
            state.selectedComments = without(state.selectedComments, payload);
            state.isEditMode = false;
        },

        selectFile: (state, { payload }) => {
            if (!includes(state.selectedFiles, payload)) {
                state.selectedFiles.push(payload);
                return;
            }
            state.selectedFiles = without(state.selectedFiles, payload);
        },

        deleteSelectedComments: (state) => {
            state.data = state.data.filter(
                (comment) => !includes(state.selectedComments, comment.id)
            );
            state.selectedComments = [];
        },

        copyToClipboard: (state) => {
            const text = getSelectedMessagesString(state.data, state.selectedComments);

            try {
                navigator.clipboard.writeText(text);
                state.copiedSuccess = true;
                state.selectedComments = [];
            } catch {
                state.copiedSuccess = false;
            }
        },

        selectRecipients: (state, { payload }) => {
            state.selectedRecipients = payload;
        },

        toggleEdit: (state, { payload }) => {
            state.isEditMode = payload;
        },

        saveEditedComment: (state, { payload }) => {
            state.data = map(state.data, (com) => {
                if (com.id !== payload.id) return com;

                return {
                    ...com,
                    body: {
                        ...com.body,
                        text: payload.value,
                    },
                };
            });

            state.selectedComments = [];
            state.isEditMode = false;
        },

        toggleReply: (state, { payload }) => {
            state.isReplyMode = payload;
            if (!payload) {
                state.replyCommentData = replyCommentInitialData;
                return;
            }

            const {
                author: recipient,
                body: { text: comment, files },
                topic,
            } = find(state.data, (com) => com.id === state.selectedComments[0]);

            state.replyCommentData = {
                comment: comment ? comment : getFilesNameString(files),
                recipient,
                topic,
            };
            state.selectedComments = [];
        },

        clearSelectedComments: (state) => {
            state.selectedComments = [];
        },

        setFilterArrayItem: (state, { payload }) => {
            const { item, value } = payload;

            const selectedIds = map(state.filterData[item], (elem) => elem.id);

            if (!includes(selectedIds, value.id)) {
                state.filterData[item].push(value);
                return;
            }
            state.filterData[item] = without(selectedIds, value.id);
        },

        resetFilterArrayItem: (state, { payload }) => {
            state.filterData[payload] = [];
        },

        setFilterDate: (state, { payload }) => {
            state.filterData.date = payload;
        },

        setFilterFiles: (state, { payload }) => {
            if (!includes(state.filterData.files, payload)) {
                state.filterData.files.push(payload);
                return;
            }

            state.filterData.files = without(state.filterData.files, payload);
        },

        setFilterAllFiles: (state, { payload }) => {
            if (isEmpty(state.filterData.files)) {
                state.filterData.files = payload;
                return;
            }

            state.filterData.files = [];
        },

        setSearchText: (state, { payload }) => {
            state.filterData.searchText = payload;
        },
    },
});

export const {
    addComment,
    selectComment,
    selectFile,
    clearSelectedComments,
    deleteSelectedComments,
    copyToClipboard,
    selectRecipients,
    toggleEdit,
    saveEditedComment,
    toggleReply,
    setFilterArrayItem,
    resetFilterArrayItem,
    setFilterDate,
    setFilterFiles,
    setSearchText,
    setFilterAllFiles,
} = commentSlice.actions;
export default commentSlice.reducer;
