import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
    BrowserRouter as Router,
} from 'react-router-dom';

import { ConfigProvider } from 'antd';
import ru from 'antd/es/locale/ru_RU';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from '@utils/redux/configureStore';

import { GlobalConstants } from '../constants/global';
import { Loader } from '../ui/atoms/loader';
import { App } from '../ui/pages/app';
import '../utils/configureMobX';

import 'antd/dist/antd.min.css';
import '../styles/style.scss';

import * as pdfjs from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

const queryClient = new QueryClient()

export const render = (isLoading = false) => {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const container = document.getElementById(GlobalConstants.MainRoot);
    const root = createRoot(container);
    root.render(
        <QueryClientProvider client={queryClient}>
            <ConfigProvider locale={ru}>
                <Provider store={store}>
                    <Router>
                        {isLoading ? (
                            <Loader isFullSize={true} />
                        ) : (
                            <App />
                        )}
                    </Router>
                </Provider>
            </ConfigProvider>
        </QueryClientProvider>
    );
};

render();
