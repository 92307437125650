import { rootStore } from '@store/rootStore/instanse';
import { ILocalStore } from '@utils/useLocalStore';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

type PrivateType = '_quantity' | '_isVisible';

class CardQuantityStore implements ILocalStore {
    id: number;
    private _quantity: number;
    private _maxQuantity?: number
    private _isVisible: boolean;
    constructor(id: number, quantity: number, maxQuantity?: number) {
        this._maxQuantity = maxQuantity;
        this.id = id;
        this._quantity = quantity;
        this._isVisible = this._quantity > 0;
        makeObservable<CardQuantityStore, PrivateType>(this, {
            _quantity: observable,
            _isVisible: observable,
            inc: action,
            dec: action,
            setQuantity: action,
            quantity: computed,
            controlsIsVisible: computed,
            setVisible: action,
        });
    }

    get quantity() {
        return this._quantity;
    }

    get controlsIsVisible() {
        return this._isVisible;
    }

    setQuantity = (val: number) => {
        this._quantity = val;
    };

    setVisible = (val: boolean) => {
        this._isVisible = val;
    };

    inc = () => {
        if (this._maxQuantity && this._quantity < this._maxQuantity) {
            this._quantity += 1;
        }
        if (!this._maxQuantity) {
            this._quantity += 1;
        }
    };

    dec = () => {
        this._quantity -= 1;
        if (this._quantity < 1) {
            this.setVisible(false);
            this._quantity = 0;
            return;
        }
    };

    _orderResetReact = reaction(
        () => rootStore.orderStore.allEntitiesCount,
        (count) => {
            if (count < 1) {
                this._quantity = 0;
                this._isVisible = false;
            }
        }
    )

    destroy = () => {
        this._quantity = 0;
        this._isVisible = false;
        this._orderResetReact();
    };
}

export { CardQuantityStore };
