import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Breadcrumb, Col, Row, Tabs } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { cloneDeep, findIndex, isEmpty, map } from 'lodash';
import { observer } from 'mobx-react';
import { CategoryParams } from 'types/getParams';
import { TMetadata } from 'types/metadata';
import { TProduct } from 'types/product';
import { TProductCategory } from 'types/productCategory';
import { UpdateWorkerPermissionRequestParams } from 'types/settings/permissions';

import { PermissionCategoryListResponse } from '@api/responseModels/permissions/permissionsResponse';
import { ProductCategoryListResp } from '@api/responseModels/product/productCategoryListResponse';
import { ProductCategoryResp } from '@api/responseModels/product/productCategoryResponse';
import { Loader } from '@atoms/loader';
import { CRMAPIManager } from '@classes/crmApiManager';
import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetchDataList } from '@hooks/useFetchDataList';
import { store as pStore } from '@molecules/paginationControls/pageSizeStore';
import { ServiceProductList } from '@molecules/renderList/serviceProductList';
import { FilterStore } from '@organisms/productServicesFilter/filterStore';
import { LastIdStore } from '@pages/lastIdStore';

const filterStore = new FilterStore();
const filter = filterStore.filter;

export const ProductAndServiceListModal = observer(
    ({ isOpen, onChangeVisibility, type }): JSX.Element => {
        const [isLoading, setIsLoading] = useState<boolean>(true);
        const [itemsList, setItemsList] = useState<
            Array<TProduct & { category: TProductCategory }>
        >([]);
        const [itemCategoryList, setItemCategoryList] = useState<
            Array<
                TProductCategory & {
                    products: Array<TProduct>;
                    categories: Array<TProductCategory>;
                }
            >
        >([]);
        const [categoryPath, setCategoryPath] = useState<Array<{ id: number; name: string }>>([]);

        const [selectCatList, setSelectCatList] = useState<
            Array<{ id: number; name: string; parentId: number | null }>
        >([]);
        const creds = SettingsManager.getConnectionCredentials();
        const [currentPage, setCurrentPage] = useState<number>(1);
        const [currentCategoryPage, setCurrentCategoryPage] = useState<number>(1);
        const [currentMeta, setCurrentMeta] = useState<TMetadata>(null);
        const [currentCategoryMeta, setCurrentCategoryMeta] = useState<TMetadata>(null);
        const [changedEntities, setChangedEntities] = useState([]);

        const { workerID } = useParams();

        const { getDataList, isLoading: isListLoading } = useFetchDataList({
            filter,
            setCurrentMeta,
            setDataList: setItemsList,
            getFnKey: type === 'products' ? 'getProductList' : 'getServiceList',
            worker_id: +workerID,
        });

        const categoryId =
            (type === 'products'
                ? LastIdStore.lastProductCategoryId
                : LastIdStore.lastServiceCategoryId) ?? '';

        async function fetchItemsList(page: number = currentPage, categoryId?: number | string) {
            setIsLoading(true);
            try {
                await getDataList({ page, categoryId });
            } catch (errors) {
                messageService.sendErrorList(errors);
            }
            setIsLoading(false);
        }

        const handleUpdatePermissions = async () => {
            if (isEmpty(changedEntities)) {
                onChangeVisibility(false);
                return;
            }

            setIsLoading(true);
            try {
                const creds = SettingsManager.getConnectionCredentials();
                const params: UpdateWorkerPermissionRequestParams = {
                    crm_id: creds.crmID,
                    user_id: +workerID,
                    entity: type,
                    entities: changedEntities,
                };

                await CRMAPIManager.request<PermissionCategoryListResponse>(async (api) => {
                    return await api.updateWorkerPermissions(params);
                });
                onChangeVisibility(false);
                messageService.sendSuccess('Настройки сохранены');
            } catch (err) {
                console.error(err);
            }
            setIsLoading(false);
        };

        async function getItemCategoryList(page: number = currentPage, addition: boolean = false) {
            setIsLoading(true);
            try {
                const creds = SettingsManager.getConnectionCredentials();
                const itemCatList = await CRMAPIManager.request<ProductCategoryListResp>(
                    async (api) => {
                        const params: CategoryParams = {
                            crm_id: creds.crmID,
                            category_id:
                                (type === 'products'
                                    ? LastIdStore.lastProductCategoryId
                                    : LastIdStore.lastServiceCategoryId) ?? '',
                            sort_by: filter.sortBy,
                            sort_direction: filter.sortDirection,
                            filters: {
                                created_at: [...filter.createdDates],
                                deleted: filter.deleted,
                            },
                            page,
                            per_page: pStore.pS,
                        };
                        Object.keys(params.filters).filter(
                            (key) => params.filters[key] === null && delete params.filters[key]
                        );
                        return type === 'products'
                            ? await api.getProductCategoryList(params)
                            : await api.getServiceCategoryList(params);
                    }
                );

                if (itemCatList.errorMessages) throw itemCatList.errorMessages;
                if (addition) {
                    setItemCategoryList((pcl) => [...pcl, ...itemCatList.data.data]);
                } else {
                    setItemCategoryList(itemCatList.data.data);
                }
                setCurrentCategoryMeta(itemCatList.data.meta);
                if (categoryPath.length == 0 && LastIdStore.lastProductCategoryId != null) {
                    let tItemCat = null;
                    let tCurFolderId =
                        type === 'products'
                            ? LastIdStore.lastProductCategoryId
                            : LastIdStore.lastServiceCategoryId;
                    let tPath = [];
                    while (tCurFolderId != null) {
                        tItemCat = await CRMAPIManager.request<ProductCategoryResp>(async (api) => {
                            return type === 'products'
                                ? await api.getProductCategory(tCurFolderId, creds.crmID)
                                : await api.getServiceCategory(tCurFolderId, creds.crmID);
                        });
                        tPath = [{ id: tCurFolderId, name: tItemCat.data.data.name }, ...tPath];
                        tCurFolderId =
                            type === 'products'
                                ? tItemCat.data.data.parent_product_category_id
                                : tItemCat.data.data.parent_service_category_id;
                    }
                    setCategoryPath(tPath);
                }
            } catch (errors) {
                messageService.sendErrorList(errors);
            }
            setIsLoading(false);
        }

        async function handleShowMoreCategories() {
            await getItemCategoryList(currentCategoryPage + 1, true);
            setCurrentCategoryPage((pageNumber) => pageNumber + 1);
        }

        async function handleChangePage() {
            fetchItemsList(currentPage + 1, categoryId).then(() => {
                setCurrentPage((prev) => prev + 1);
            });
        }

        async function openCategoryCard(id: number | null, back = false) {
            type === 'products'
                ? LastIdStore.setLastProductCategoryId(id)
                : LastIdStore.setLastServiceCategoryId(id);

            const tPath = [...categoryPath];
            if (back) {
                for (let i = tPath.length - 1; i >= 0; i--) {
                    if (tPath[i].id !== id) {
                        tPath.pop();
                    } else {
                        break;
                    }
                }
            } else {
                const category = itemCategoryList.find((pc) => pc.id == id);
                tPath.push({ id: id, name: category?.name });
            }
            setCategoryPath(tPath);

            await fetchItemsList(1, id);
            await getItemCategoryList(1);
            setCurrentPage(1);
            setCurrentCategoryPage(1);
        }

        useEffect(() => {
            if (currentMeta?.total) {
                let newPage = Math.ceil(currentMeta.from / pStore.pS);
                fetchItemsList(newPage, categoryId).then(() => {
                    setCurrentPage(newPage);
                });
            }
            if (currentCategoryMeta?.total) {
                let newPage = Math.ceil(currentCategoryMeta.from / pStore.pS);
                getItemCategoryList(newPage).then(() => {
                    setCurrentCategoryPage(newPage);
                });
            }
        }, [pStore.pS]);

        useEffect(() => {
            if (!isOpen) return;

            setChangedEntities([]);
            fetchItemsList(1, categoryId).then(() => {
                getItemCategoryList();
                setCurrentPage(1);
            });
        }, [isOpen]);

        const isCurrentUser = +workerID === creds.userId;

        const handlePermissionButtonClick = (id: number, type: 'show' | 'update' | 'execute') => {
            switch (type) {
                case 'show':
                    setItemsList(
                        map(itemsList, (item) => {
                            if (item.id === id) {
                                const changedEntitiesCopy = cloneDeep(changedEntities);

                                const show = (
                                    isCurrentUser ? item.permissions : item.permissions_for_worker
                                )[0].show;

                                const update = (
                                    isCurrentUser ? item.permissions : item.permissions_for_worker
                                )[1].update;

                                const execute = (
                                    isCurrentUser ? item.permissions : item.permissions_for_worker
                                )[2].execute;

                                const updatedElement = {
                                    id,
                                    show: !show,
                                    update,
                                    execute,
                                };

                                const index = findIndex(changedEntitiesCopy, { id });

                                index !== -1
                                    ? (changedEntitiesCopy[index] = updatedElement)
                                    : changedEntitiesCopy.push(updatedElement);
                                setChangedEntities(changedEntitiesCopy);

                                return isCurrentUser
                                    ? {
                                          ...item,
                                          permissions: [{ show: !show }, { update }, { execute }],
                                      }
                                    : {
                                          ...item,
                                          permissions_for_worker: [
                                              { show: !show },
                                              { update },
                                              { execute },
                                          ],
                                      };
                            }
                            return item;
                        })
                    );
                    break;

                case 'update':
                    setItemsList(
                        map(itemsList, (item) => {
                            if (item.id === id) {
                                const changedEntitiesCopy = cloneDeep(changedEntities);

                                const show = (
                                    isCurrentUser ? item.permissions : item.permissions_for_worker
                                )[0].show;

                                const update = (
                                    isCurrentUser ? item.permissions : item.permissions_for_worker
                                )[1].update;

                                const execute = (
                                    isCurrentUser ? item.permissions : item.permissions_for_worker
                                )[2].execute;

                                const updatedElement = {
                                    id,
                                    show,
                                    update: !update,
                                    execute,
                                };

                                const index = findIndex(changedEntitiesCopy, { id });

                                index !== -1
                                    ? (changedEntitiesCopy[index] = updatedElement)
                                    : changedEntitiesCopy.push(updatedElement);
                                setChangedEntities(changedEntitiesCopy);

                                return isCurrentUser
                                    ? {
                                          ...item,
                                          permissions: [{ show }, { update: !update }, { execute }],
                                      }
                                    : {
                                          ...item,
                                          permissions_for_worker: [
                                              { show },
                                              { update: !update },
                                              { execute },
                                          ],
                                      };
                            }
                            return item;
                        })
                    );
                    break;

                case 'execute':
                    setItemsList(
                        map(itemsList, (item) => {
                            if (item.id === id) {
                                const changedEntitiesCopy = cloneDeep(changedEntities);

                                const show = (
                                    isCurrentUser ? item.permissions : item.permissions_for_worker
                                )[0].show;

                                const update = (
                                    isCurrentUser ? item.permissions : item.permissions_for_worker
                                )[1].update;

                                const execute = (
                                    isCurrentUser ? item.permissions : item.permissions_for_worker
                                )[2].execute;

                                const updatedElement = {
                                    id,
                                    show,
                                    update,
                                    execute: !execute,
                                };

                                const index = findIndex(changedEntitiesCopy, { id });

                                index !== -1
                                    ? (changedEntitiesCopy[index] = updatedElement)
                                    : changedEntitiesCopy.push(updatedElement);
                                setChangedEntities(changedEntitiesCopy);

                                return isCurrentUser
                                    ? {
                                          ...item,
                                          permissions: [
                                              { show },
                                              { update },
                                              { execute: !execute },
                                          ],
                                      }
                                    : {
                                          ...item,
                                          permissions_for_worker: [
                                              { show },
                                              { update },
                                              { execute: !execute },
                                          ],
                                      };
                            }
                            return item;
                        })
                    );
                    break;
            }
        };

        return (
            <Modal
                open={isOpen}
                onCancel={() => onChangeVisibility(false)}
                className="product-category-picker"
                centered
                onOk={handleUpdatePermissions}
            >
                <div id="app-product-list" style={{ marginBottom: 0 }}>
                    {(isLoading || isListLoading) && <Loader />}

                    <Row className="breadcrumbs-controls">
                        <Col className="breadcrumbs">
                            <Breadcrumb>
                                <Breadcrumb.Item
                                    onClick={async () => await openCategoryCard(null, true)}
                                >
                                    <FontAwesomeIcon icon={faListCheck} />
                                    <span className="crumb-name">Категории</span>
                                </Breadcrumb.Item>
                                {categoryPath.map((el) => (
                                    <Breadcrumb.Item
                                        key={el.id}
                                        onClick={async () => await openCategoryCard(el.id, true)}
                                    >
                                        <span className="crumb-name">{el.name}</span>
                                    </Breadcrumb.Item>
                                ))}
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <Tabs
                        items={[
                            {
                                label: type === 'products' ? 'Товары' : 'Услуги',
                                key: '1',
                                children: (
                                    <ServiceProductList
                                        productCategoryList={itemCategoryList}
                                        serviceCategoryList={null}
                                        productList={type === 'products' ? itemsList : null}
                                        serviceList={type === 'services' ? itemsList : null}
                                        selectCatList={selectCatList}
                                        selectElemList={[]}
                                        currentCategoryPage={currentCategoryPage}
                                        currentCategoryMeta={currentCategoryMeta}
                                        handleShowMoreCategories={handleShowMoreCategories}
                                        setSelectCatList={setSelectCatList}
                                        setSelectElemList={() => {}}
                                        openCategoryCard={openCategoryCard}
                                        openCard={() => {}}
                                        infiniteScrollProps={{
                                            isActive: true,
                                            onNext: handleChangePage,
                                            totalItems: currentMeta?.total,
                                            itemsName: type === 'products' ? 'Товары' : 'Услуги',
                                            height:
                                                window.screen.height < 950
                                                    ? 'calc(90vh - 220px)'
                                                    : 'calc(75vh - 220px)',
                                        }}
                                        showPermisionButtonsInCard
                                        onPermissionButtonClick={handlePermissionButtonClick}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            </Modal>
        );
    }
);
