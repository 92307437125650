import { CRMAPIBase } from '../crmApiBase';
import { TProductCategorySearch } from '../models/productCategorySearch';
import { ProductCategoryListResp } from '../responseModels/product/productCategoryListResponse';
import { ProductCategoryResp } from '../responseModels/product/productCategoryResponse';
import { ProductListResp } from '../responseModels/product/productListResponse';
import { RequestResult } from '../responseModels/requestResult';
import { CategoryParams } from 'types/getParams';
class ProductCategoryGroup extends CRMAPIBase {
    /**
     * Добавление категории товаров
     * @param name Название категории
     * @param crmId ID CRM
     * @param picture Фото категории
     * @returns Результат запроса
     */
    async saveProductCategory(
        name: string,
        crmId: number,
        parentCatId?: number,
        picture?: File
    ): Promise<RequestResult<ProductCategoryResp>> {
        const result = await this.post<ProductCategoryResp>('/product-categories', {
            name,
            crm_id: crmId,
            parent_product_category_id: parentCatId,
            picture,
        });
        return result;
    }

    /**
     * Получение категории товаров
     * @param id ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getProductCategory(
        id: number,
        crmId: number
    ): Promise<RequestResult<ProductCategoryResp>> {
        const result = await this.get<ProductCategoryResp>(`/product-categories/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Получение списка категорий товаров
     * @param crmId ID CRM
     * @param categoryId Категория
     * @param page Страница
     * @param perPage На страницу
     * @returns Результат запроса
     */
    async getProductCategoryList(
       params: CategoryParams
    ): Promise<RequestResult<ProductCategoryListResp>> {
        const result = await this.get<ProductCategoryListResp>('/product-categories', {
           ...params
        });
        return result;
    }

    /**
     * Обновление категории товаров
     * @param id ID Категории
     * @param name Название категории
     * @param crmId ID CRM
     * @param picture Фото категории
     * @returns Результат запроса
     */
    async updateProductCategory(
        id: number,
        name: string,
        crmId: number,
        picture?: File
    ): Promise<RequestResult<ProductCategoryResp>> {
        const result = await this.put<ProductCategoryResp>(`/product-categories/${id}`, {
            name: name,
            crm_id: crmId,
            picture: picture,
        });
        return result;
    }

    /**
     * Удаление категории товаров
     * @param id ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeProductCategory(
        id: number,
        crmId: number
    ): Promise<RequestResult<ProductCategoryResp>> {
        const result = await this.delete<ProductCategoryResp>(`/product-categories/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Восстановление категории товаров
     * @param id ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreProductCategory(
        id: number,
        crmId: number
    ): Promise<RequestResult<ProductCategoryResp>> {
        const result = await this.post<ProductCategoryResp>(`/product-categories/${id}/restore`, {
            crm_id: crmId,
        });
        return result;
    }
    
    /**
     * Получение товаров категории товаров
     * @param id ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getProductCategoryProductList(
        id: number,
        crmId: number
    ): Promise<RequestResult<ProductListResp>> {
        const result = await this.get<ProductListResp>(`/product-categories/${id}/products`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * @deprecated
     * Поиск категорий товаров
     * @param crmId ID CRM
     * @param productCategorySearchModel Объект модели поиска категорий товаров
     * @returns Результат запроса
     */
    async searchProductCategory(
        crmId: number,
        productCategorySearchModel: TProductCategorySearch
    ): Promise<RequestResult<ProductCategoryListResp>> {
        let query = '';
        Object.keys(productCategorySearchModel).forEach((key) => {
            query +=
                key +
                '=' +
                (productCategorySearchModel[key] ? productCategorySearchModel[key] : '') +
                '&';
        });
        query = query.slice(0, -1);
        const result = await this.get<ProductCategoryListResp>('/productCategory/search', {
            crm_id: crmId,
            query: query,
        });
        return result;
    }

    /**
     * Обновление родительских категорий
     * @param crmId ID CRM
     * @param productCategories Карта перемещений
     * @returns Результат запроса
     */
    async updateProductParentCategories(
        crmId: number,
        productCategories: Array<{ id: number; parent_product_category_id: number }>
    ): Promise<RequestResult<any>> {
        const result = await this.post<any>('/product-categories/update/bulk', {
            crm_id: crmId,
            product_categories: productCategories,
        });
        return result;
    }

    /**
     * Получение id всех дочерних категорий, включая потомки потомков
     * @param crmId ID CRM
     * @param categoryId id категории
     * @returns Результат запроса
     */
    async getAllIncludedProductCategories(
        crmId: number,
        categoryId: number
    ): Promise<RequestResult<any>> {
        const result = await this.get<any>(`/product-categories/${categoryId}/categories`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Получение общего количества товаров и общей цены товаров, если передается параметр id, то поиск идет в этом разделе и всех подразделах
     * @param crmId ID CRM
     * @param categoryId id категории
     * @returns Результат запроса
     */
    async getProductCatalogInfo(crmId: number, categoryId?: number): Promise<RequestResult<any>> {
        const result = await this.get<any>(
            categoryId == null 
            ? '/product-categories/catalog' 
            : `/product-categories/catalog/${categoryId}`
        , {
            crm_id: crmId,
        });
        return result;
    }
}

export { ProductCategoryGroup };
