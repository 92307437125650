import { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';

import { useMount, useReactive } from 'ahooks';
import { Modal, Space, Table, message } from 'antd';
import { TPivot } from 'types/worksheets/worksheetFields';

import { OrderFieldsCreateResp } from '@api/responseModels/orderFields/orderFieldsCreateResponse';
import { WorksheetFieldsResp } from '@api/responseModels/worksheet/worksheetFieldsResponse';
import { Loader } from '@atoms/loader';
import { CRMAPIManager } from '@classes/crmApiManager';
import { SettingsManager } from '@classes/settingsManager';

import { getCreatingModalColumn } from '../../data';
import { getFilteredWorksheetFields } from '../../helpers/getFilteredWorksheetFields';
import { toggleValue } from '../../helpers/toggleValue';
import { setWorksheetFields } from '../../redux/settingsSlice';

const modalBodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

export const CreatingModal = ({
    visible,
    hideModal,
    initialName,
    clearSearch,
    isPhotoField,
    worksheetId,
}) => {
    const creds = SettingsManager.getConnectionCredentials();
    const state = useReactive({
        orderField: {
            name: '',
            pivot: {
                is_shown: 1,
                is_used: 1,
                is_required: 0,
                is_saved: 0,
            },
        },
        isLoading: false,
    });

    const dispatch = useDispatch();

    const [messageApi, contextHolder] = message.useMessage();

    const handleCancel = () => {
        hideModal();
    };

    const createOrderField = async () => {
        try {
            state.isLoading = true;
            const orderFieldsCreateResp = await CRMAPIManager.request<OrderFieldsCreateResp>(
                async (api) => {
                    return await api.createOrderField(
                        creds.crmID,
                        state.orderField.name,
                        isPhotoField ? 1 : 0
                    );
                }
            );
            if (orderFieldsCreateResp.errorMessages) throw orderFieldsCreateResp.errorMessages;

            const { id: order_field_id } = orderFieldsCreateResp.data.data;

            const dto: TPivot[] = [
                {
                    worksheet_id: worksheetId,
                    order_field_id,
                    ...state.orderField.pivot,
                },
            ];

            const worksheetFieldsResp = await CRMAPIManager.request<WorksheetFieldsResp>(
                async (api) => {
                    return await api.updateWorksheetFields(+worksheetId, creds.crmID, dto);
                }
            );

            if (worksheetFieldsResp.errorMessages) throw worksheetFieldsResp.errorMessages;

            const worksheetFieldsData = getFilteredWorksheetFields(worksheetFieldsResp.data.data);
            dispatch(setWorksheetFields(worksheetFieldsData));

            state.isLoading = false;
            clearSearch();
            hideModal();
        } catch (err) {
            console.error(err);
            messageApi.open({
                type: 'error',
                content: err?.[0],
            });
            state.isLoading = false;
        }
    };

    const handleChangePivotValue = (pivotProperty: string, value: number) => {
        const toggledValue = toggleValue(value);
        state.orderField.pivot[pivotProperty] = toggledValue;

        if (pivotProperty === 'is_used') {
            state.orderField.pivot.is_shown = toggledValue;
            if (toggledValue === 0) {
                state.orderField.pivot.is_required = 0;
                state.orderField.pivot.is_saved = 0;
            }
        }
    };

    const handleChangeFieldName = (value: string) => {
        state.orderField.name = value;
    };

    const column = getCreatingModalColumn(
        handleChangeFieldName,
        handleChangePivotValue,
        isPhotoField
    );

    useMount(() => {
        state.orderField.name = initialName;
    });

    return (
        <>
            <Modal
                centered
                title="Создание нового поля"
                open={visible}
                onOk={createOrderField}
                onCancel={handleCancel}
                okButtonProps={{ disabled: state.orderField.name === '' }}
                okText={'Создать'}
                bodyStyle={modalBodyStyle}
            >
                {state.isLoading && <Loader />}
                <Space direction="vertical" align={'center'} size={'small'}>
                    <Table
                        pagination={false}
                        bordered
                        sticky
                        columns={column}
                        dataSource={[state.orderField]}
                        size={'small'}
                    />
                </Space>
            </Modal>
            {contextHolder}
        </>
    );
};
