import { TRequisite } from '../../types/requisite';
import { CRMAPIBase } from '../crmApiBase';
import { TRequisiteSearch } from '../models/requisiteSearch';
import { RequestResult } from '../responseModels/requestResult';
import { RequisiteListResp } from '../responseModels/requisite/requisiteListResponse';
import { RequisiteResp } from '../responseModels/requisite/requisiteResponse';

class RequisiteGroup extends CRMAPIBase {
    /**
     * Добавление реквизита
     * @param requisite Реквизит
     * @returns Результат запроса
     */
    async saveRequisite(requisite: TRequisite): Promise<RequestResult<RequisiteResp>> {
        const result = await this.post<RequisiteResp>('/requisites', { ...requisite });
        return result;
    }

    /**
     * Получение реквизита
     * @param id ID реквизита
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getRequisite(id: number, crmId: number): Promise<RequestResult<RequisiteResp>> {
        const result = await this.get<RequisiteResp>(`/requisites/${id}`, { crm_id: crmId });
        return result;
    }

    /**
     * Получение списка реквизитов
     * @param crmId ID CRM
     * @param userId ID Пользователя
     * @returns Результат запроса
     */
    async getRequisiteList(
        crmId: number,
        userId: number
    ): Promise<RequestResult<RequisiteListResp>> {
        const result = await this.get<RequisiteListResp>('/requisites', {
            crm_id: crmId,
            user_id: userId,
        });
        return result;
    }

    /**
     * Обновление реквизита
     * @param requisite Реквизит
     * @returns Результат запроса
     */
    async updateRequisite(requisite: TRequisite): Promise<RequestResult<RequisiteResp>> {
        const result = await this.put<RequisiteResp>(`/requisites/${requisite.id}`, { ...requisite });
        return result;
    }

    /**
     * Удаление реквизита
     * @param id ID реквизита
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeRequisite(id: number, crmId: number): Promise<RequestResult<RequisiteResp>> {
        const result = await this.delete<RequisiteResp>(`/requisites/${id}`, {
            id: id,
            crm_id: crmId,
        });
        return result;
    }

    /**
     * @deprecated
     * Поиск реквизитов
     * @param crmId ID CRM
     * @param requisiteSearchModel Объект параметров поиска
     * @returns Результат запроса
     */
    async searchRequisite(
        crmId: number,
        requisiteSearchModel: TRequisiteSearch
    ): Promise<RequestResult<RequisiteListResp>> {
        let query = '';
        Object.keys(requisiteSearchModel).forEach((key) => {
            query += key + '=' + (requisiteSearchModel[key] ? requisiteSearchModel[key] : '') + '&';
        });
        query = query.slice(0, -1);
        const result = await this.get<RequisiteListResp>('/requisite/serch', {
            crm_id: crmId,
            query: query,
        });
        return result;
    }
}

export { RequisiteGroup };
