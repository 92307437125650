import { createSelector } from '@reduxjs/toolkit';

export const getSettings = (state) => state.settings;

export const getWorksheetFields = createSelector(
    getSettings,
    (settings) => settings.worksheetFields
);

export const getPivotsImmutableValue = createSelector(
    getSettings,
    (settings) => settings.pivotsImmutableValue
);

export const getPivotsChangeableValue = createSelector(
    getWorksheetFields,
    (fields) => fields?.order_fields?.map((field) => field.pivot)
);
