import { useDispatch, useSelector } from 'react-redux';

import { useToggle } from 'ahooks';
import { Button, Col, DatePicker, Dropdown, Input, MenuProps, Row, Space, Typography } from 'antd';
import moment from 'moment';

import { faComments } from '@fortawesome/free-regular-svg-icons';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FILTER_KEYS } from '../../common/constants';
import { setFilterDate, setSearchText } from '../../common/redux/commentSlice';
import { getComments } from '../../common/redux/selectors';
import { AppliedFilters } from './blocks/appliedFilters';
import { Files } from './blocks/files';
import { Members } from './blocks/members';
import { Topics } from './blocks/topics';

import './commentFilter.scss';

export const CommentsFilter = () => {
    const dispatch = useDispatch();
    const {
        filterData: { searchText },
    } = useSelector(getComments);

    const [isDropdownOpen, { toggle: toggleDropdown }] = useToggle(false);
    const [isDatePickerOpen, { toggle: toggleDatePicker }] = useToggle(false);
    const [isTopicsOpen, { toggle: toggleTopics }] = useToggle(false);
    const [isMembersOpen, { toggle: toggleMembers }] = useToggle(false);
    const [isFilesOpen, { toggle: toggleFiles }] = useToggle(false);

    const changeDropdownVisibility = () => {
        if (isDatePickerOpen) toggleDatePicker();
        toggleDropdown();
    };

    const handleSetDate = (date) => {
        const formattedDate = moment(date).format('DD.MM.YYYY');
        dispatch(setFilterDate(formattedDate));
        if (isDatePickerOpen) toggleDatePicker();
        toggleDropdown();
    };

    const items: MenuProps['items'] = [
        {
            key: FILTER_KEYS.DATE,
            label: (
                <div className="filter-comment-date-picker">
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleDatePicker();
                        }}
                        style={{ width: '100%' }}
                    >
                        Дата
                    </div>
                    <DatePicker
                        onChange={handleSetDate}
                        placement="bottomRight"
                        open={isDatePickerOpen && isDropdownOpen}
                    />
                </div>
            ),
        },
        {
            key: FILTER_KEYS.TOPIC,
            label: <>Темы</>,
            onClick: toggleTopics,
        },
        {
            key: FILTER_KEYS.MEMBER,
            label: <>Участники</>,
            onClick: toggleMembers,
        },
        {
            key: FILTER_KEYS.FILE,
            label: <>Файлы</>,
            onClick: toggleFiles,
        },
    ];

    return (
        <>
            <Row className="comments-filter-wrap" align={'middle'}>
                <Col className="comments-logo">
                    <Space align="baseline" size={'small'}>
                        <FontAwesomeIcon icon={faComments} size="xl" />
                        <Typography.Title level={5}>Чат</Typography.Title>
                    </Space>
                </Col>

                <Col span={4}></Col>
                <Col span={20}>
                    <div className="comments-filter-search-panel">
                        <Input
                            className="comments-filter-search-input"
                            placeholder="Поиск"
                            suffix={<FontAwesomeIcon icon={faSearch} color="#ff9100" />}
                            onChange={(e) => dispatch(setSearchText(e.target.value))}
                            value={searchText}
                            allowClear
                        />
                        <Dropdown
                            trigger={['click']}
                            menu={{ items }}
                            placement="bottomRight"
                            open={isDropdownOpen}
                            overlayClassName="comments-filter-dropdown"
                        >
                            <Button
                                shape="circle"
                                onClick={changeDropdownVisibility}
                                icon={
                                    <>
                                        +<FontAwesomeIcon icon={faFilter} color="#ff9100" />
                                    </>
                                }
                                className="comments-filter-btn"
                            />
                        </Dropdown>
                    </div>
                </Col>
            </Row>

            <Col span={4}></Col>
            <Col span={20}>
                <AppliedFilters />
            </Col>

            <Topics
                isOpen={isTopicsOpen}
                toggleVisibility={toggleTopics}
                toggleDropdown={toggleDropdown}
            />
            <Members
                isOpen={isMembersOpen}
                toggleVisibility={toggleMembers}
                toggleDropdown={toggleDropdown}
            />
            <Files
                isOpen={isFilesOpen}
                toggleVisibility={toggleFiles}
                toggleDropdown={toggleDropdown}
            />
        </>
    );
};
