import { TTask } from "@components/tasks/common/types";
import { observer } from "mobx-react";
import { SyntheticEvent, useRef } from "react";
import cn from 'classnames';
import useGetCardWidth from "@atoms/listsCard/hooks/useGetCardWidth";
import { columnViewStore } from "@molecules/listViewChanger/listViewStore";
import { GlobalConstants } from "@constants/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Common } from "@classes/commonMethods";
import { faCalendar, faCheck, faClock, faRubleSign } from "@fortawesome/free-solid-svg-icons";
import { Button, Typography } from "antd";
import AutoResizeText from "@atoms/listsCard/autoResizeText/autoResizeText";
import styles from './taskCard.module.scss';

const { Text } = Typography;

type TProps = {
    data: TTask;
    selectList: Array<{ id: number; }>;
    openCard: (id: number) => void | null;
    handleTaskSelect: (e: SyntheticEvent, taskId: number) => void | null;
};

const TaskCard = observer(({ data, selectList, openCard, handleTaskSelect }: TProps) => {
    const cardRef = useRef(null);
    const cardWidth = useGetCardWidth({ cardRef });

    return (
        <div
            ref={cardRef}
            className={cn(styles.card, {
                [styles['card_vertical']]: columnViewStore.col > 1,
            })}
            onClick={() => openCard(data.id)}
        >
            <div className={styles['card__image']}>
                <img src={typeof data?.picture == "string" ? `${GlobalConstants.BaseUrlForImg}${data?.picture as string}` : data?.picture?.["src"]} />
            </div>
            <div className={styles['card__info']}>
                <p className={cn(styles['card__name'], {
                    [styles['card__name-select-margin']]: columnViewStore.col == 1
                })}>{data.name}</p>
                <div className={styles['card__duration']}>
                    <FontAwesomeIcon icon={faClock} />
                    <Text>{Common.convertMinutes2Date(data.duration)}</Text>
                </div>
                <div className={styles['card__price-container']}>
                    <AutoResizeText maxWidth={cardWidth}>
                        {Common.formatCurrency(data.reward)}&nbsp;
                        <FontAwesomeIcon color="black" icon={faRubleSign} />
                    </AutoResizeText>
                </div>
            </div>
            <div className={styles['card__select-btn']}>
                <Button
                    icon={<FontAwesomeIcon icon={faCheck} />}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleTaskSelect(e, data.id);
                    }}
                    shape="circle"
                    type={selectList.find((sli) => sli.id == data.id) ? 'primary' : 'default'}
                />
            </div>
        </div>
    );
});

export { TaskCard };