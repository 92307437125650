import { RequestResult } from '@api/responseModels/requestResult';
import { CRMAPIBase } from '../crmApiBase';
import { OrderDetailsResp } from '@api/responseModels/order/orderDetailsResponse';
import { OrderDetailsSyncResp } from '@api/responseModels/order/orderDetailsSyncResponse';

class OrderDetailsGroup extends CRMAPIBase {
    /**
     * создание детали заказа
     * @param name название параметра
     * @param value описание параметра
     * @param profileId ID клиента
     * @param profileType Тип профиля клиент
     * @param crmId ID CRM
     * @returns Результат запроса
     */

    async saveDetail(
        name: string,
        value: string,
        profileId: number,
        profileType: string,
        crmId: number
    ): Promise<RequestResult<OrderDetailsResp>> {
        const result = this.post<OrderDetailsResp>('/customer-order-params', {
            name: name,
            value: value,
            profile_id: profileId,
            profile_type: profileType,
            crm_id: crmId,
        });
        return result;
    }

    /**
     * обновление детали заказа
     * @param id ID параметра
     * @param name название параметра
     * @param value описание параметра
     * @param crmId ID CRM
     * @returns Результат запроса
     */

    async updateDetail(
        id: number,
        name: string,
        value: string,
        crmId: number
    ): Promise<RequestResult<OrderDetailsResp>> {
        const result = this.put<OrderDetailsResp>(`/customer-order-params/${id}`, {
            name: name,
            value: value,
            crm_id: crmId,
        });
        return result;
    }
    /**
     * удаление детали заказа
     * @param crmId ID CRM
     * @param id ID параметра
     * @returns Результат запроса
     */

    async removeDetail(id: number, crmId: number): Promise<RequestResult<OrderDetailsResp>> {
        const result = this.delete<OrderDetailsResp>(`/customer-order-params/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Синхронизация делатей заказа в заказе и клиенте
     * @param crmId ID CRM
     * @param id ID заказа
     * @param customerOrderParams ID выбраных деталей заказа для привязки к заказу
     * @returns Результат запроса
     */

    async syncParamsOrderCustomer(crmId: number, id: number, customerOrderParams: Array<number>) {
        const result = this.post<OrderDetailsSyncResp>(`/orders/${id}/sync-params`, {
            crm_id: crmId,
            customer_order_params: customerOrderParams,
        });
        return result;
    }
}

export { OrderDetailsGroup };
