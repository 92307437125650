import { observer } from "mobx-react";
import { TaskFilterStore } from "./filterStore";
import { useState } from "react";
import { TaskFilter } from "types/filters";
import { Button, Col, Drawer, Radio, RadioChangeEvent, Row, Select, Space, Typography } from "antd";
import DatePicker, { RangePickerProps } from "antd/lib/date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownWideShort, faArrowUpWideShort, faFilter } from "@fortawesome/free-solid-svg-icons";

const sortOptions = [
    {
        label: 'Наименование',
        value: 'name',
    },
    {
        label: 'Продолжительность',
        value: 'duration',
    },
    {
        label: 'Дата создания',
        value: 'created_at',
    },
];

type TProps = {
    store: TaskFilterStore
};

const TaskFilterComponent = observer(({store}: TProps) => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleSortChange = (value: TaskFilter['sortBy']) => {
        store.changeBy(value);
    };
    const handleRadioChange = (e: RadioChangeEvent) => {
        store.changeDir(e.target.value);
    };

    const handleCreatedDates: RangePickerProps['onChange'] = (_, dateString) => {
        store.changeCreatedDates(dateString[0], dateString[1]);
    };

    return (
        <>
            <Button className="round-button" type="default" onClick={showDrawer}>
                <FontAwesomeIcon className='btn-icon' icon={faFilter} />
            </Button>
            <Drawer title="Фильтр" placement="right" onClose={onClose} open={open}>
                <Space direction="vertical" size="middle">
                    <Row>
                        <Col>
                            <Select
                                defaultValue={store.filter.sortBy}
                                style={{ width: '200px' }}
                                options={sortOptions}
                                onChange={handleSortChange}
                            />
                            <Radio.Group
                                defaultValue={store.filter.sortDirection}
                                onChange={handleRadioChange}
                            >
                                <Radio.Button value="asc">
                                    <FontAwesomeIcon icon={faArrowUpWideShort} />
                                </Radio.Button>
                                <Radio.Button value="desc">
                                    <FontAwesomeIcon icon={faArrowDownWideShort} />
                                </Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Typography.Title level={5}>Дата создания</Typography.Title>
                        <Col span={24}>
                            <Space>
                                <DatePicker.RangePicker onChange={handleCreatedDates} />
                            </Space>
                        </Col>
                    </Row>
                </Space>
            </Drawer>
        </>
    );
});

export { TaskFilterComponent };