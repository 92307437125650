import { EmptyRows } from '../constants';

export const getModifiedData = (data) => {
    return [
        ...data,
        {
            id: EmptyRows.EMPTY_ROW_1,
            name: null,
            quantity: null,
            price: null,
            total: null,
        },
        {
            id: EmptyRows.EMPTY_ROW_2,
            name: '',
            quantity: null,
            price: null,
            total: null,
        },
    ];
};
