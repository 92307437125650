import { TTask, TTaskCategory } from "@components/tasks/common/types"
import { List } from "antd";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { TaskCategoryCard } from "../taskCategoryCard/taskCategoryCard";

type TProps = {
    dataCategories: Array<TTaskCategory & { tasks: Array<TTask>; categories: Array<TTaskCategory> }>;
    selectList: Array<{ id: number; name: string; }>;
    route: string;
    routeCard: string;
    setLastCategoryId: React.Dispatch<React.SetStateAction<number>>;
    setSelectList: (sl: { id: number, name: string }[]) => void;
};

const RenderTaskCategoryList = observer(({ dataCategories, selectList, route, routeCard, setLastCategoryId, setSelectList}: TProps) => {
    const navigate = useNavigate();

    function openCard(id: number) {
        setLastCategoryId(id);
        navigate(`${routeCard}/${id}`);
    }

    function handleCategoryClick(select: boolean, categoryId: number, categoryName: string) {
        if (select) {
            !selectList.find((sli) => sli.id == categoryId)
                ? setSelectList([...selectList, { id: categoryId, name: categoryName }])
                : setSelectList(selectList.filter((sli) => sli.id != categoryId));
        } else {
            openCard(categoryId);
        }
    }

    return (
        <>
            {dataCategories.length > 0 && (
                <List
                    className="category-list-template task-category-card-list"
                    dataSource={dataCategories}
                    itemLayout="horizontal"
                    renderItem={(item) => (
                        <List.Item className="task-category-card">
                            <TaskCategoryCard
                                data={item}
                                selectList={selectList}
                                route={route}
                                handleCategoryClick={handleCategoryClick}
                            />
                        </List.Item>
                    )}
                />
            )}
        </>
    )
});

export { RenderTaskCategoryList };