import { useDispatch, useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';

import { faReply, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toggleReply } from '../../redux/commentSlice';
import { getComments } from '../../redux/selectors';

import './replyModePanel.scss';

export const ReplyModePanel = () => {
    const {
        replyCommentData: { comment },
    } = useSelector(getComments);

    const dispatch = useDispatch();

    return (
        <div className="reply-mode-panel-container">
            <Row justify={'space-between'}>
                <Col>
                    <FontAwesomeIcon icon={faReply} />
                </Col>
                <Col onClick={() => dispatch(toggleReply(false))}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        color="#C7C2BF"
                        className="reply-mode-panel-btn"
                    />
                </Col>
            </Row>
            <Row justify={'space-between'}>
                <Col>
                    <Typography.Text className="reply-mode-panel-text">
                        <span className="reply-mode-panel-text-blue">В ответ на:</span> {comment}
                    </Typography.Text>
                </Col>
            </Row>
        </div>
    );
};
