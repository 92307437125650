import { TTask, TTaskCategory } from "@components/tasks/common/types";
import { columnViewStore } from "@molecules/listViewChanger/listViewStore";
import { List } from "antd";
import { observer } from "mobx-react";
import { SyntheticEvent } from "react";
import { TaskCard } from "../taskCard/taskCard";
import { TMetadata } from "types/metadata";
import { ShowMoreButton } from "@molecules/paginationControls/showMoreButton";

type TProps = {
    taskList: Array<TTask>;
    taskCategoryList: Array<TTaskCategory & { tasks: Array<TTask>; categories: Array<TTaskCategory> }>;
    setSelectList: (newSelectList: Array<{ id: number; }>) => void;
    selectList: Array<{ id: number; }>;
    openCard: (id: number) => void | null;
    openCategoryCard: (id: number, back: boolean) => void | null;
    currentCategoryMeta: TMetadata;
    currentCategoryPage: number;
    handleShowMoreCategories: () => Promise<void>;
};

const RenderTaskList = observer(({ 
    taskList, 
    taskCategoryList, 
    setSelectList, 
    selectList, 
    openCard, 
    openCategoryCard, 
    currentCategoryMeta, 
    currentCategoryPage, 
    handleShowMoreCategories 
}: TProps) => {
    function handleTaskSelect(e: SyntheticEvent, taskId: number) {
        e.stopPropagation();
        !selectList.find((task) => task.id == taskId)
            ? setSelectList(selectList.concat({ id: taskId }))
            : setSelectList(selectList.filter((task) => task.id != taskId));
    }
    
    return (
        <>
            {taskCategoryList.length > 0 && (
                <List
                    className="task-category-card-list"
                    dataSource={taskCategoryList}
                    itemLayout="horizontal"
                    grid={{ column: 4 }}
                    renderItem={(item) => (
                        <List.Item className="task-category-card template-category-card">
                            <div onClick={() => openCategoryCard(item.id, false)} className="category-folder">
                                <p className="folder-count">{item?.task_count}</p>
                                <p className="folder-text">{item.name}</p>
                            </div>
                        </List.Item>
                    )}
                />
            )}
            {currentCategoryMeta && currentCategoryPage < currentCategoryMeta.last_page && (
                <ShowMoreButton onClick={handleShowMoreCategories} text="Показать ещё" />
            )}
            {taskList.length > 0 && (
                <List
                    className="card-list-template task-card-list"
                    dataSource={taskList}
                    itemLayout="horizontal"
                    bordered
                    grid={{ column: columnViewStore.col }}
                    renderItem={(item) => (
                        <List.Item className="product-card">
                            <TaskCard
                                data={item}
                                selectList={selectList}
                                openCard={openCard}
                                handleTaskSelect={handleTaskSelect}
                            />
                        </List.Item>
                    )}
                />
            )}
        </>
    );
});

export { RenderTaskList };