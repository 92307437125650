import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactive } from "ahooks";
import { Dropdown } from "antd";
import { useEffect, useRef } from "react";

export type TItem = { 
    key: string, 
    label: string, 
    icon: JSX.Element,
    onClick?: () => any 
}

type TState = {
    // Сколько показывать отдельных элементов
    itemsToShow: number;
    kbOpen: boolean;
};

type TProps = {
    // До 7 (с учётом бургер-меню)
    items: Array<TItem>,
    // Только во всплывающем меню
    dropdownItems: Array<TItem>,
    // Кол-во выбранных
    selected?: number
};

const FunctionalMenu = ({ items, dropdownItems, selected }: TProps): JSX.Element => {
    const state = useReactive<TState>({
        itemsToShow: 7,
        kbOpen: false
    });
    const navRef = useRef<HTMLDivElement>(null);

    function getOverflow() {
        if (items.length == 0 && dropdownItems.length == 0) return null;
        const overflowCount = items.length < state.itemsToShow
            ? dropdownItems.length
            : items.length - state.itemsToShow + dropdownItems.length;
        if (overflowCount > 0) {
            return (
                <div className="functional-item-more">
                    {`+${overflowCount}`} 
                </div>
            );
        }
        return null;
    }

    function handleResize() {
        state.kbOpen = window.screen.height - 200 > window.visualViewport.height;
        let width = navRef?.current?.clientWidth;
        if (width == undefined || width <= 0) return;
        let itemCount = Math.floor(width / 68);
        if (selected > 0 || dropdownItems.length > 0 || itemCount < items.length) { 
            itemCount -= 1;
        }
        if (itemCount > 7) {
            itemCount = 7;
        }
        state.itemsToShow = itemCount;
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
    }, [navRef]);

    useEffect(() => {
        handleResize();
    }, []);
    
    function getBgColor(key: string): string {
        switch (key) {
            case "share": //Переслать
                return "#FFE0B2";
            case "reply": //Ответить
                return "#FFE0B2";
            case "download": //Скачать
                return "#C8E6C9";
            case "attach": //Прикрепить к заказу
                return "#B2EBF2";
            case "completed": //Выполненио и использовано
                return "#F0F4C3";
            case "copy": //Копирование
                return "#B3E5FC";
            case "save": //Сохранение
                return "#C8E6C9";
            case "edit": //Редактирование
                return "#ebf4fd";
            case "move": //Перемещение
                return "#ebf4fd";
            case "abort": //Отмена
                return "#f7cbca";
            case "change": //Изменить
                return "#edf4fc";
            case "createCategory": //Добавить категорию
                return "#c0cafb";
            case "createElement": //Добавить сущность
                return "#eef7fe";
            default:
                return "#fff";
        }
    }

    return (
        <div className="functional-container" ref={navRef} style={{bottom: state.kbOpen ? 0 : "66px"}}>
            {
                items.slice(0, state.itemsToShow).map(i => (
                    <div className="functional-item" key={i.key} onClick={i.onClick}>
                        <div className={(selected > 0 || items.length > state.itemsToShow || dropdownItems.length > 0) ? "functional-bg" : "functional-bg functional-bg-sticky"} style={{backgroundColor: getBgColor(i.key)}}/>
                        {i.icon}
                        <div className="functional-item-text">
                            <span>{i.label}</span>
                        </div>
                    </div>
                ))
            }
            {
                (selected > 0 || items.length > state.itemsToShow || dropdownItems.length > 0) &&
                <Dropdown 
                    disabled={ items.length <= state.itemsToShow && dropdownItems.length == 0 } 
                    menu={{ items: items.slice(state.itemsToShow).concat(dropdownItems) }}
                    overlayClassName="functional-menu-overlay"
                    placement="topRight"
                >
                    <div className="functional-item functional-item-main">
                        <div className="functional-bg-main" />
                        <FontAwesomeIcon icon={faEllipsisV} />
                        {  getOverflow() }
                        { selected > 0 &&
                            <div className="functional-item-main-text">
                                {selected}
                            </div>
                        }
                    </div>
                </Dropdown>
            }
        </div>
    );
}

export { FunctionalMenu };