import { Button, Input, InputNumber, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TOrderProduct } from 'types/Orders/products';

import RenderIf from '@common/RenderIf';
import { SearchInputProfile } from '@components/orders/searchInputProfile';
import { faBarcode, faCheck, faPaperclip, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nameCellLarge } from '@organisms/orders/profile/common/constants';
import { getIsIncludesOther, getIsLastRow } from '@organisms/orders/profile/common/helpers';
import { EmptyRows, GetColumnsProps } from '@organisms/orders/profile/common/types';

export const getColumnsWithoutExecutor = ({
    handleSearchInput,
    handleChangeQuantity,
    handleChangePrice,
    handleSelectItem,
    selectedItems,
    handleSelectAllItems,
    itemsCount,
    nameCellWidth,
    isCommentSelected,
    inputRefs,
    setInputRef,
}: GetColumnsProps): ColumnsType<TOrderProduct> => {
    return [
        {
            title: '',
            dataIndex: 'id',
            width: '5%',
            key: 'id',
            render: (_text, record) => {
                return (
                    record.id !== EmptyRows.EMPTY_ROW_1 &&
                    record.id !== EmptyRows.EMPTY_ROW_2 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                            }}
                        >
                            <FontAwesomeIcon icon={faBarcode} />
                        </div>
                    )
                );
            },
        },
        {
            title: (
                <div className="column-name">
                    <span>Без исполнителя</span>
                    <RenderIf condition={!isCommentSelected}>
                        <div className="button-select">
                            <Button
                                icon={<FontAwesomeIcon icon={faCheck} />}
                                onClick={() => {
                                    handleSelectAllItems('product');
                                }}
                                shape="circle"
                                type={selectedItems.length === itemsCount ? 'primary' : 'default'}
                                disabled={itemsCount === 0}
                            />
                        </div>
                    </RenderIf>
                </div>
            ),
            dataIndex: 'name',
            className: 'td-service-cell',
            key: 'name',
            fixed: 'left',
            width: nameCellWidth,
            render: (_text, record, index) => {
                return (
                    <RenderIf condition={!getIsLastRow(record.id, record.name)}>
                        <div className="column-name">
                            <SearchInputProfile
                                placeholder={
                                    record.name === null ? 'Введите наименование товара' : ''
                                }
                                fetchType="without-executor"
                                setDataOrder={handleSearchInput}
                                rowIndex={index}
                                defaultValue={record.name}
                                isIncludesOther={getIsIncludesOther(record.id)}
                                otherItemQuantity={record.quantity}
                                customClassName={`${
                                    nameCellWidth === nameCellLarge
                                        ? 'search-input-large'
                                        : 'search-input-small'
                                }`}
                                inputRefs={inputRefs}
                            />
                            <RenderIf
                                condition={
                                    record.id !== EmptyRows.EMPTY_ROW_1 && !isCommentSelected
                                }
                            >
                                <div className="button-select">
                                    <Button
                                        icon={<FontAwesomeIcon icon={faCheck} />}
                                        onClick={() => handleSelectItem(record.id, 'product')}
                                        shape="circle"
                                        type={
                                            selectedItems.includes(record.id)
                                                ? 'primary'
                                                : 'default'
                                        }
                                    />
                                </div>
                            </RenderIf>
                        </div>
                    </RenderIf>
                );
            },
        },
        {
            title: 'Кол-во',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '10%',
            render: (__, record, index) => {
                return (
                    <RenderIf condition={!getIsLastRow(record.id, record.name)}>
                        <InputNumber
                            ref={(el) => setInputRef(index, el)}
                            onClick={(e) => e.currentTarget.select()}
                            onFocus={(e) => e.currentTarget.select()}
                            min={0}
                            max={1000}
                            value={record.quantity}
                            style={{ width: '100%' }}
                            controls={false}
                            onChange={(value) => handleChangeQuantity(index, value, 'product')}
                        />
                    </RenderIf>
                );
            },
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            width: '10%',
            render: (__, record, index) => {
                return (
                    <RenderIf condition={!getIsLastRow(record.id, record.name)}>
                        <InputNumber
                            min={0}
                            value={record.price}
                            style={{ width: '100%' }}
                            controls={false}
                            onChange={(value) => handleChangePrice(index, value, 'product')}
                            onClick={(e) => {
                                e.currentTarget.select();
                            }}
                        />
                    </RenderIf>
                );
            },
        },
        {
            title: 'Сумма',
            dataIndex: 'total',
            key: 'total',
            width: '10%',
            render: (_, record, _index) => {
                return (
                    <>
                        {record.quantity && record.price && (
                            <span>{(record.quantity * record.price).toLocaleString('ru-RU')}</span>
                        )}
                    </>
                );
            },
        },
        // Заглушка
        {
            title: 'Файлы',
            dataIndex: 'files',
            key: 'files',
            width: '43%',
            render: (_, record, _index) => {
                return (
                    <RenderIf
                        condition={typeof record.id === 'number' || record.id.includes('other')}
                    >
                        <Space className="files-container">
                            <Button className="comment-btn">
                                <FontAwesomeIcon icon={faPaperclip} className="icon-comment" />
                                <span>2</span>
                            </Button>
                            <label className="add-file-btn ant-btn">
                                <FontAwesomeIcon icon={faPlus} />
                                <Input
                                    className="file-upload-input"
                                    onChange={() => {}}
                                    type="file"
                                />
                            </label>
                        </Space>
                    </RenderIf>
                );
            },
        },
    ];
};
