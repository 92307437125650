import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { useReactive } from 'ahooks';
import { Avatar, Button, Col, Input, List, Modal, Row } from 'antd';
import { filter, includes, sortBy } from 'lodash';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { Search } = Input;

const highlightStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    background: 'none',
    padding: 0,
    color: 'blue',
};

export const SelectItemModal = ({
    isModalOpen,
    setIsModalOpen,
    data,
    selectedItems,
    onSelect,
    categoryType,
}) => {
    const state = useReactive({
        searchValue: '',
    });
    const [dataSource, setDataSource] = useState(data);
    const getIsItemSelected = (id) => includes(selectedItems, id);

    const handleSearch = ({ target: { value } }) => {
        state.searchValue = value;
        setDataSource(
            value
                ? filter(data, (item) => includes(item.name.toLowerCase(), value.toLowerCase()))
                : data
        );
    };

    useEffect(() => {
        const sortedObjects = sortBy(dataSource, (obj) => {
            return includes(selectedItems, obj.id) ? 0 : 1;
        });

        setDataSource(sortedObjects);
    }, [JSON.stringify(selectedItems)]);

    return (
        <Modal
            className="product-category-picker"
            title="Выберите категорию"
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            wrapClassName="sticky-modal"
            footer={[
                <Button key="submit" type="primary" onClick={() => setIsModalOpen(false)}>
                    OK
                </Button>,
            ]}
        >
            <Row>
                <Col span={24}>
                    <Search allowClear className="header-search-input" onChange={handleSearch} />
                </Col>
            </Row>

            <List
                className="customer-card-list"
                dataSource={dataSource}
                itemLayout="horizontal"
                renderItem={(item) => (
                    <List.Item
                        className="customer-card"
                        key={item.id}
                        onClick={() => onSelect(item.id, categoryType)}
                    >
                        <Row style={{ width: '100%' }}>
                            <Col span={3}>
                                <Avatar className="avatar" />
                            </Col>
                            <Col span={18}>
                                <Row>
                                    <Col>
                                        <h3 className="title">
                                            <Highlighter
                                                searchWords={state.searchValue.split(' ') || []}
                                                autoEscape={true}
                                                textToHighlight={item.name}
                                                highlightStyle={highlightStyle}
                                            />
                                        </h3>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={3} className="button-select">
                                <Button
                                    icon={<FontAwesomeIcon icon={faCheck} />}
                                    shape="circle"
                                    type={getIsItemSelected(item.id) ? 'primary' : 'default'}
                                />
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
        </Modal>
    );
};
