import { sumBy } from 'lodash';

import { Common } from '@classes/commonMethods';
import { styles } from '@components/orders/pdfFile/common/styles';
import { Text } from '@react-pdf/renderer';

export const Footer = ({ data }) => {
    return (
        <>
            <Text style={styles.mt_5}>
                Всего наименований на сумму{' '}
                {(sumBy(data?.products, 'total') + sumBy(data?.services, 'total')).toLocaleString('ru-RU')}
            </Text>
            <Text style={[styles.fwBold, styles.mt_5]}>
                {Common.numberToWords(
                    sumBy(data?.products, 'total') + sumBy(data?.services, 'total')
                )}
            </Text>
            <Text style={styles.mt_10}>
                Исполнитель:{' '}
                <Text style={{ textDecoration: 'underline' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Text>
            </Text>
            <Text style={[styles.mt_5, { paddingLeft: '3cm' }]}>М.П.</Text>
        </>
    );
};
