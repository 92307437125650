import { isNil } from 'lodash';

const formatTime = (value: number) => {
    return value < 10 ? `0${value}` : value.toString();
};

export const formatDuration = (value: number) => {
    if (isNil(value)) return null;
    const minutes = parseInt((value / 60).toString());
    const seconds = parseInt((value - minutes * 60).toString());
    return `${formatTime(minutes)}:${formatTime(seconds)}`;
};