import { BodyTable } from './blocks/bodyTable';
import { Footer } from './blocks/footer';
import { Header } from './blocks/header';

export const DiagnosticReport = ({ data }) => {
    return (
        <>
            <Header data={data} />
            <BodyTable data={data} />
            <Footer data={data} />
        </>
    );
};
