import { SettingsManager } from '@classes/settingsManager';
import { FunctionalMenuWrapper } from '@components/common/functionalMenuWrapper';
import { faCheck, faUserTie, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeaderBreadcrumbProfiles } from '@molecules/breadcrumbs/profilesBreadcrumbs/HeaderProfilesBreadcrumb';
import { FunctionalMenu } from '@molecules/functionalMenu';

import { TAB_ROUTES } from '../common/constants';
import { ProfileBalance } from '../components/profileBalance';
import { WorkplaceInfoCard } from './components';

export const WorkplaceProfile = () => {
    const creds = SettingsManager.getConnectionCredentials();

    const funcMenuItems = [
        {
            key: 'save',
            label: 'Сохранить',
            icon: <FontAwesomeIcon icon={faCheck} />,
            onClick: () => {},
        },
        {
            key: 'abort',
            label: 'Отменить',
            icon: <FontAwesomeIcon icon={faXmark} />,
            onClick: () => {},
        },
    ];

    return (
        <div id="app-worker-profile">
            <FunctionalMenuWrapper>
                <FunctionalMenu items={funcMenuItems} dropdownItems={[]} />
            </FunctionalMenuWrapper>

            <HeaderBreadcrumbProfiles
                dataIcon={faUserTie}
                dataId={'Рабочее место'}
                dataTitle={'Сотрудники'}
                title={'Рабочее место'}
                route={`/lk/worker/crm/${creds?.crmID}/workers/tabs/${TAB_ROUTES.WORKING_NOW}`}
                isForInvitation={false}
                dataName={'Новый пользователь'}
                isSpecialty
            />

            <div className="upper-info">
                <div />
                <ProfileBalance
                    balance={0}
                    prepareTransaction={() => {}}
                    userWorkerProfileId={null}
                />
            </div>

            <WorkplaceInfoCard />
        </div>
    );
};
