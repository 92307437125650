import { combineReducers } from '@reduxjs/toolkit';

import commentsReducer from '../../components/orderComment/common/redux/commentReducer';
import orderReducer from './orderSlice';

const ordersProfileReducer = combineReducers({
    comments: commentsReducer,

    order: orderReducer,
});

export default ordersProfileReducer;
