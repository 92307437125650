import { sumBy } from 'lodash';

import { styles } from '@components/orders/pdfFile/common/styles';
import { Text, View } from '@react-pdf/renderer';

export const Footer = ({ data }) => {
    return (
        <>
            <View style={{ width: 'auto', marginTop: '1cm' }}>
                <View style={{ width: '100%', flexDirection: 'row', alignItems: 'flex-end' }}>
                    <Text
                        style={{
                            width: '50%',
                            textAlign: 'left',
                            paddingRight: '5mm',
                            fontStyle: 'italic',
                        }}
                    >
                        Всего выполнено работ и использовано материалов на сумму:
                    </Text>
                    <Text style={{ width: '50%' }}>
                        <Text style={{ textDecoration: 'underline' }}>
                            {(sumBy(data?.products, 'total') + sumBy(data?.services, 'total')).toLocaleString('ru-RU')}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Text>
                        <Text style={{ fontStyle: 'italic' }}> рублей</Text>
                    </Text>
                </View>

                <View style={{ width: '100%', flexDirection: 'row', marginTop: '2mm' }}>
                    <Text style={{ width: '50%', fontStyle: 'italic' }}>в т.ч. НДС:</Text>
                    <Text style={{ width: '50%', fontStyle: 'italic' }}>
                        рублей{' '}
                        <Text style={{ textDecoration: 'underline' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;0
                        </Text>{' '}
                        коп.
                    </Text>
                </View>
            </View>

            <Text style={styles.mt_5}>
                Исполнитель:{' '}
                <Text style={{ textDecoration: 'underline' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Text>
            </Text>
            <Text style={[styles.mt_5, { paddingLeft: '3cm' }]}>М.П.</Text>
        </>
    );
};
