import { useContext, useMemo, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { useDispatch, useSelector } from 'react-redux';

import { useReactive, useUnmount } from 'ahooks';
import { isEmpty } from 'lodash';

import RenderIf from '@common/RenderIf';
import { FunctionalMenuWrapper } from '@components/common/functionalMenuWrapper';
import { FunctionalMenu } from '@molecules/functionalMenu';

import { Context } from '../../common/context';
import { getGalleryItems } from '../../common/getGalleryItems';
import { setFullScreen } from '../../common/redux/filesViewerSlice';
import { getIsFullScreenMode, getSelectedFiles } from '../../common/redux/selectors';

import './filesCarousel.scss';

export const FilesCarousel = ({ data, openFileId, funcMenuData }) => {
    const dispatch = useDispatch();
    const isFullScreenMode = useSelector(getIsFullScreenMode);
    const [currentFileId, setCurrentFileId] = useState(openFileId);

    const state = useReactive({
        selected: [],
    });

    const ref = useRef(null);
    const { setRef, toggleVisibility } = useContext(Context);
    setRef(ref);

    const selectedFiles = useSelector(getSelectedFiles);

    const galleryItems = useMemo(() => getGalleryItems({ data }), [JSON.stringify(data)]);

    const onFullScreenChange = (isFullScreen) => {
        dispatch(setFullScreen(isFullScreen));
    };

    const onImageClick = () => {
        if (isFullScreenMode) return;
        ref.current.toggleFullScreen();
    };

    const onImageSlide = (id) => {
        setCurrentFileId(id);
    };

    useUnmount(() => {
        state.selected = [];
    });

    return (
        <>
            <ImageGallery
                ref={ref}
                showPlayButton={false}
                showNav={false}
                items={galleryItems}
                additionalClass="additional-style"
                startIndex={currentFileId}
                showThumbnails={!isFullScreenMode}
                onScreenChange={onFullScreenChange}
                onSlide={onImageSlide}
                onClick={onImageClick}
            />
            <RenderIf condition={!isEmpty(selectedFiles)}>
                <FunctionalMenuWrapper className="carousel-functional-menu-wrapper">
                    <FunctionalMenu
                        items={funcMenuData}
                        dropdownItems={[]}
                        selected={selectedFiles.length}
                    />
                </FunctionalMenuWrapper>
            </RenderIf>
        </>
    );
};
