import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMount, useReactive, useUpdateEffect } from 'ahooks';
import { Button, Col, Input, Row, Table, message } from 'antd';
import { isEqual, map, omit, orderBy } from 'lodash';
import { observer } from 'mobx-react';
import { TOrderField, TPivot, TWorksheetFields } from 'types/worksheets/worksheetFields';

import { OrderFieldsResp } from '@api/responseModels/orderFields/orderFieldsResponse';
import { WorksheetFieldsResp } from '@api/responseModels/worksheet/worksheetFieldsResponse';
import { Loader } from '@atoms/loader';
import { CRMAPIManager } from '@classes/crmApiManager';
import { SettingsManager } from '@classes/settingsManager';
import RenderIf from '@common/RenderIf';
import { GlobalConstants } from '@constants/global';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionalMenu } from '@molecules/functionalMenu';

import { CreatingModal } from '../common/components';
import { FIELD_NAME, FIELD_PIVOT } from '../common/constants';
import { getColumns } from '../common/data';
import { getDefaultPivot } from '../common/helpers/getDefaultPivot';
import { getFilteredWorksheetFields } from '../common/helpers/getFilteredWorksheetFields';
import { toggleValue } from '../common/helpers/toggleValue';
import {
    getPivotsChangeableValue,
    getPivotsImmutableValue,
    getWorksheetFields,
} from '../common/redux/selectors';
import {
    setFieldName,
    setFieldPivotValue,
    setOrderFields,
    setWorksheetFields,
} from '../common/redux/settingsSlice';
import { State, TimerId } from '../common/types';

import './style.scss';

const WorksheetSetting = observer((): JSX.Element => {
    const state = useReactive<State>({
        isModalOpen: false,
        searchValue: '',
        isFieldAlreadyExists: false,
        saveTimeout: null,
        searchTimeout: null,
        isLoading: false,
        isFunctionalMenuVisible: false,
        isPhotoField: false,
        fieldNameDto: null,
        savingEntity: null,
        isSearching: false,
        isSearchModeActive: false,
        editableFieldId: null,
    });
    const creds = SettingsManager.getConnectionCredentials();

    const dispatch = useDispatch();
    const worksheetFields: TWorksheetFields = useSelector(getWorksheetFields);
    const pivotsImmutableValue: TPivot[] = useSelector(getPivotsImmutableValue);
    const pivotsChangeableValue: TPivot[] = useSelector(getPivotsChangeableValue);

    const [loading, setLoading] = useState<boolean>(false);

    const { worksheetId } = useParams();

    const [messageApi, contextHolder] = message.useMessage();

    const fetchWorksheetFields = async () => {
        try {
            setLoading(true);
            state.isSearchModeActive = false;
            const resp = await CRMAPIManager.request<WorksheetFieldsResp>(async (api) => {
                return await api.getWorksheetFields(+worksheetId, creds.crmID);
            });
            setLoading(false);
            if (resp.errorMessages) throw resp.errorMessages;

            const worksheetFieldsData = getFilteredWorksheetFields(resp.data.data);
            dispatch(setWorksheetFields(worksheetFieldsData));
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };

    const getOrderFields = async (query) => {
        try {
            state.isSearching = true;
            state.isSearchModeActive = true;
            const resp = await CRMAPIManager.request<OrderFieldsResp>(async (api) => {
                return await api.getOrderFields(creds.crmID, +worksheetId, query);
            });

            state.isSearching = false;
            if (resp.errorMessages) throw resp.errorMessages;

            const orderFieldData: TOrderField[] = orderBy(
                resp.data.data.map((field) => {
                    return {
                        ...omit(field, 'worksheets'),
                        pivot: {
                            ...(field.worksheets?.[0]
                                ? field.worksheets?.[0]?.pivot
                                : getDefaultPivot({
                                      order_field_id: field.id,
                                      worksheet_id: +worksheetId,
                                  })),
                        },
                    };
                }),
                [(field) => -field.pivot.is_used]
            );

            dispatch(setOrderFields(orderFieldData));
        } catch (err) {
            console.error(err);
            messageApi.open({
                type: 'error',
                content: err?.[0],
            });
            state.isSearchModeActive = false;
        }
    };

    const updateOrderFieldName = async () => {
        const { id, value } = state.fieldNameDto ?? {};
        try {
            setLoading(true);

            const orderFieldsResp = await CRMAPIManager.request<OrderFieldsResp>(async (api) => {
                return await api.updateOrderFieldName(id, creds.crmID, value);
            });

            const worksheetFieldsResp = await CRMAPIManager.request<WorksheetFieldsResp>(
                async (api) => {
                    return await api.getWorksheetFields(+worksheetId, creds.crmID);
                }
            );
            if (worksheetFieldsResp.errorMessages) throw worksheetFieldsResp.errorMessages;

            const worksheetFieldsData = getFilteredWorksheetFields(worksheetFieldsResp.data.data);
            dispatch(setWorksheetFields(worksheetFieldsData));

            setLoading(false);
            state.fieldNameDto = null;
            state.isFunctionalMenuVisible = false;

            if (orderFieldsResp.errorMessages) throw orderFieldsResp.errorMessages;
            fieldSaved();
        } catch (err) {
            console.error(err);
            messageApi.open({
                type: 'error',
                content: err?.[0],
            });
            setLoading(false);
        }
    };

    const updateWorksheetFieldsPivots = async () => {
        try {
            setLoading(true);

            const dto: TPivot[] = map(pivotsChangeableValue, (item) => omit(item, 'worksheet_id'));
            const resp = await CRMAPIManager.request<WorksheetFieldsResp>(async (api) => {
                return await api.updateWorksheetFields(+worksheetId, creds.crmID, dto);
            });

            if (resp.errorMessages) throw resp.errorMessages;

            const worksheetFieldsData = getFilteredWorksheetFields(resp.data.data);
            dispatch(setWorksheetFields(worksheetFieldsData));

            setLoading(false);
            state.isFunctionalMenuVisible = false;
            fieldSaved();
        } catch (err) {
            messageApi.open({
                type: 'error',
                content: err.message,
            });
            fetchWorksheetFields();
        }
    };

    const handleChangePivotValue = (
        pivotProperty: string,
        fieldIndex: number,
        value: number
    ): void => {
        dispatch(
            setFieldPivotValue({
                pivotProperty,
                fieldIndex,
                value: toggleValue(value),
            })
        );
    };

    const handleChangeFieldName = (index: number, id, value: string) => {
        state.savingEntity = FIELD_NAME;
        state.fieldNameDto = {
            id,
            value,
        };
        state.isFunctionalMenuVisible = true;
        dispatch(setFieldName({ index, value }));

        if (state.saveTimeout) clearTimeout(state.saveTimeout);

        const timerId: TimerId = setTimeout(() => {
            updateOrderFieldName();
        }, 5000);
        state.saveTimeout = timerId;
    };

    const handleSave = () => {
        if (state.savingEntity === FIELD_NAME) {
            updateOrderFieldName();
            state.isFunctionalMenuVisible = false;
            clearTimeout(state.saveTimeout);
        }

        if (state.savingEntity === FIELD_PIVOT) {
            updateWorksheetFieldsPivots();
            state.isFunctionalMenuVisible = false;
            clearTimeout(state.saveTimeout);
        }
    };

    const handleSaveOnBlur = () => {
        state.editableFieldId = null;
        if (state.fieldNameDto) {
            if (state.saveTimeout) clearTimeout(state.saveTimeout);
            updateOrderFieldName();
        }
    };

    const handleSavePivotsOnFocus = () => {
        if (
            !isEqual(pivotsChangeableValue, pivotsImmutableValue) &&
            state.savingEntity === FIELD_PIVOT
        ) {
            updateWorksheetFieldsPivots();
            clearTimeout(state.saveTimeout);
        }
    };

    const setEditFieldId = (id: number) => (state.editableFieldId = id);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        if (state.searchTimeout) {
            clearTimeout(state.searchTimeout);
            state.searchTimeout = null;
        }

        state.searchTimeout = setTimeout(() => {
            if (e.target.value !== '') {
                getOrderFields(e.target.value);
                return;
            }
            fetchWorksheetFields();
        }, GlobalConstants.SearchTimeout);
        state.searchValue = e.target.value;
    };

    const fieldSaved = () => {
        messageApi.open({
            type: 'success',
            content: 'Сохранено!',
        });
    };

    const showModal = (isPhotoField: boolean) => {
        state.isModalOpen = true;
        state.isPhotoField = isPhotoField;
    };

    const hideModal = () => {
        state.isModalOpen = false;
    };

    useUpdateEffect(() => {
        if (!isEqual(pivotsChangeableValue, pivotsImmutableValue)) {
            state.isFunctionalMenuVisible = true;
            state.savingEntity = FIELD_PIVOT;
            if (state.saveTimeout) clearTimeout(state.saveTimeout);

            const timerId: TimerId = setTimeout(() => {
                updateWorksheetFieldsPivots();
            }, 5000);
            state.saveTimeout = timerId;
            return;
        }
        if (state.saveTimeout) clearTimeout(state.saveTimeout);
        state.isFunctionalMenuVisible = false;
    }, [JSON.stringify(pivotsChangeableValue)]);

    useMount(() => {
        fetchWorksheetFields();
    });

    const columns = getColumns(
        handleChangeFieldName,
        handleChangePivotValue,
        handleSaveOnBlur,
        handleSavePivotsOnFocus,
        setEditFieldId,
        state.searchValue,
        state.editableFieldId,
        state.isSearchModeActive
    );

    console.log();

    return (
        <Row>
            {loading && <Loader />}
            <Col className="worksheet-setting-content">
                <p className="worksheet-title">{worksheetFields?.name} / Настройка полей</p>
                <Input.Search
                    placeholder={'Введите название для поиска или создания поля'}
                    onChange={handleSearch}
                    allowClear
                    value={state.searchValue}
                    className="worksheet-setting-input"
                    loading={state.isSearching}
                />
            </Col>

            <RenderIf condition={state.searchValue !== ''}>
                <Col span={24} className="worksheet-setting-btns">
                    <Row justify={'space-between'}>
                        <Col>
                            <Button onClick={() => showModal(false)} type="primary">
                                + Текстовое поле
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => showModal(true)} type="primary">
                                + Фото поле
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </RenderIf>

            <Col>
                <Table
                    style={{ marginBottom: '150px' }}
                    pagination={false}
                    bordered
                    sticky
                    columns={columns}
                    dataSource={worksheetFields?.order_fields ?? []}
                    scroll={{ x: 350 }}
                    size={'small'}
                />
            </Col>

            <RenderIf condition={state.isFunctionalMenuVisible}>
                <FunctionalMenu
                    items={[
                        {
                            key: 'save',
                            label: 'Сохранить',
                            icon: <FontAwesomeIcon icon={faCheck} />,
                            onClick: handleSave,
                        },
                    ]}
                    dropdownItems={[]}
                />
            </RenderIf>
            <RenderIf condition={state.isModalOpen}>
                <CreatingModal
                    worksheetId={worksheetId}
                    hideModal={hideModal}
                    visible={state.isModalOpen}
                    initialName={state.searchValue}
                    clearSearch={() => (state.searchValue = '')}
                    isPhotoField={state.isPhotoField}
                />
            </RenderIf>
            {contextHolder}
        </Row>
    );
});

export default WorksheetSetting;
