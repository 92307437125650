import React, { SyntheticEvent, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useCreation, useReactive, useUpdateEffect } from 'ahooks';
import { Avatar, Button, Col, Divider, Input, List, Modal, Row, message } from 'antd';
import { find, isEmpty, orderBy, unionWith } from 'lodash';
import { observer } from 'mobx-react';
import { WorkerParams } from 'types/getParams';
import { TUser } from 'types/user/user';

import { UserListResp } from '@api/responseModels/user/userListResponse';
import { CRMAPIManager } from '@classes/crmApiManager';
import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import { GlobalConstants } from '@constants/global';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store as pStore } from '@molecules/paginationControls/pageSizeStore';
import { FilterStore } from '@organisms/workersFilter/filterStore';

const { Search } = Input;
const filterStore = new FilterStore();
const filter = filterStore.filter;

function debounce(fn: (...args: unknown[]) => void, ms: number) {
    let timer: NodeJS.Timeout | null = null;

    return (...args: any[]) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn(...args);
        }, ms);
    };
}

const filterWorkerList = (workerList: TUser[]): TUser[] => {
    return workerList.filter((user) => user?.worker_profile);
};
export const CommentEmployeesPicker = observer(
    ({ isModalOpen, setIsModalOpen, currentItems, setCurrentItems }) => {
        const state = useReactive({
            isEmployeeListLoading: false,
            currentPage: 1,
            currentMeta: null,
        });

        const [employeeList, setEmployeeList] = useState([]);
        const [selectedEmployees, setSelectedEmployees] = useState([]);
        const [dataSource, setDataSource] = useState([]);

        const creds = useCreation(() => SettingsManager.getConnectionCredentials(), []);

        useUpdateEffect(() => {
            if (isEmpty(employeeList)) return;
            setDataSource(filterWorkerList(employeeList));
        }, [employeeList.length]);

        useEffect(() => {
            if (isEmpty(currentItems)) return;
            setSelectedEmployees(currentItems);
        }, [currentItems]);

        async function getWorkerList(page: number = state.currentPage) {
            if (state.isEmployeeListLoading) return;
            state.isEmployeeListLoading = true;
            try {
                const workerList = await CRMAPIManager.request<UserListResp>(async (api) => {
                    const params: WorkerParams = {
                        crm_id: creds.crmID,
                        sort_by: filter.sortBy,
                        sort_direction: filter.sortDirection,
                        filters: {
                            created_at: [...filter.createdDates],
                            updated_at: [...filter.updatedDates],
                            deleted: filter.deleted,
                            profile: 'worker',
                        },
                        query: filter.query,
                        page,
                        per_page: pStore.pS,
                    };
                    Object.keys(params.filters).filter(
                        (key) => params.filters[key] === null && delete params.filters[key]
                    );
                    return await api.getUserList(params);
                });
                if (workerList.errorMessages) {
                    setEmployeeList([]);
                    throw workerList.errorMessages;
                }
                setEmployeeList((prev) => {
                    return [...prev, ...workerList.data.data];
                });
                state.currentMeta = workerList.data.meta;
            } catch (err) {
                messageService.sendError(err.message);
            }
            state.isEmployeeListLoading = false;
        }

        async function handleChangePage() {
            getWorkerList(state.currentPage + 1).then(() => {
                state.currentPage += 1;
            });
        }

        const handlePickerOK = () => {
            setCurrentItems(selectedEmployees);
            setIsModalOpen(false);
        };

        const handlePickerCancel = () => {
            setIsModalOpen(false);
        };

        const handleSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
            setEmployeeList([]);
            state.currentPage = 1;
            filterStore.changeQuery(e.target.value);
        }, GlobalConstants.SearchTimeout);

        function handleSelectButtonClick(e: SyntheticEvent, worker: TUser) {
            e.stopPropagation();

            const isEmployeeAlreadyAdded = find(
                selectedEmployees,
                (selectedWorker) => selectedWorker?.id === worker.id
            );
            if (isEmployeeAlreadyAdded) {
                const filteredSelectedOrderWorker = selectedEmployees.filter(
                    (selectedWorker) => selectedWorker?.id !== worker.id
                );
                setSelectedEmployees(filteredSelectedOrderWorker);
                return;
            }
            setSelectedEmployees((prev) => {
                return [...prev, worker];
            });
            message.success(`${worker.name} ${worker.surname} | Менеджер выбран`);
        }

        function beforeMountWorkerList() {
            getWorkerList();
        }

        useEffect(() => {
            beforeMountWorkerList();
        }, [filter.query]);

        useEffect(() => {
            if (state.currentMeta?.total == null || state.currentMeta?.total == 0) return;
            const newPage = Math.ceil(state.currentMeta.from / pStore.pS);
            getWorkerList(newPage).then(() => {
                state.currentPage = newPage;
            });
        }, [pStore.pS]);

        return (
            <Modal
                className="product-category-picker"
                title={'Выберите сотрудника'}
                open={isModalOpen}
                onOk={handlePickerOK}
                onCancel={handlePickerCancel}
                cancelText="Отмена"
                wrapClassName="sticky-modal"
            >
                <Row>
                    <Col span={24}>
                        <Search
                            allowClear
                            className="header-search-input"
                            onChange={handleSearchChange}
                        />
                    </Col>
                </Row>
                {/* Выбор сотрудников */}

                <InfiniteScroll
                    dataLength={dataSource.length}
                    next={handleChangePage}
                    hasMore={dataSource.length < state.currentMeta?.total}
                    loader={<Divider plain>Загрузка...</Divider>}
                    endMessage={<Divider plain>Все сотрудники загружены :^)</Divider>}
                    height={
                        window.screen.height < 950 ? 'calc(90vh - 220px)' : 'calc(75vh - 220px)'
                    }
                >
                    <List
                        className="customer-card-list"
                        dataSource={unionWith(
                            orderBy(selectedEmployees, ['name'], ['asc']),
                            dataSource,
                            (a, b) => a.id == b.id
                        )}
                        itemLayout="horizontal"
                        renderItem={(item) => (
                            <List.Item
                                className="customer-card"
                                key={item.id}
                                onClick={(e) => handleSelectButtonClick(e, item)}
                            >
                                <Row style={{ width: '100%' }}>
                                    <Col span={3}>
                                        <Avatar
                                            className="avatar"
                                            src={`${GlobalConstants.BaseUrlForImg}${
                                                item?.picture as string
                                            }`}
                                        />
                                    </Col>
                                    <Col span={18}>
                                        <Row>
                                            <Col>
                                                <h3 className="title">
                                                    {item.name + ' ' + item.surname}
                                                </h3>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={3} className="button-select">
                                        <Button
                                            icon={<FontAwesomeIcon icon={faCheck} />}
                                            shape="circle"
                                            type={
                                                find(
                                                    selectedEmployees,
                                                    (selectedWorker) =>
                                                        item?.id === selectedWorker?.id
                                                )
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                        />
                                    </Col>
                                </Row>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </Modal>
        );
    }
);
