import { CategoryParams } from 'types/getParams';
import { CRMAPIBase } from '../crmApiBase';
import { TServiceCategorySearch } from '../models/serviceCategorySearch';
import { RequestResult } from '../responseModels/requestResult';
import { ServiceCategoryListResp } from '../responseModels/service/serviceCategoryListResponse';
import { ServiceCategoryResp } from '../responseModels/service/serviceCategoryResponse';
import { ServiceListResp } from '../responseModels/service/serviceListResponse';

class ServiceCategoryGroup extends CRMAPIBase {
    /**
     * Добавление категории услуг
     * @param name Название категории
     * @param crmId ID CRM
     * @param picture Фото категории
     * @returns Результат запроса
     */
    async saveServiceCategory(
        name: string,
        crmId: number,
        parentCatId?: number,
        picture?: File
    ): Promise<RequestResult<ServiceCategoryResp>> {
        const result = await this.post<ServiceCategoryResp>('/service-categories', {
            name,
            crm_id: crmId,
            parent_service_category_id: parentCatId,
            picture,
        });
        return result;
    }

    /**
     * Получение категории услуг
     * @param id ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getServiceCategory(
        id: number,
        crmId: number
    ): Promise<RequestResult<ServiceCategoryResp>> {
        const result = await this.get<ServiceCategoryResp>(`/service-categories/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Получение списка категорий услуг
     * @param params Параметры списка
     * @returns Результат запроса
     */
    async getServiceCategoryList(params: CategoryParams): Promise<RequestResult<ServiceCategoryListResp>> {
        const result = await this.get<ServiceCategoryListResp>('/service-categories', {
            ...params
        });
        return result;
    }

    /**
     * Обновление категории услуг
     * @param id ID Категории
     * @param name Название категории
     * @param crmId ID CRM
     * @param picture Фото категории
     * @returns Результат запроса
     */
    async updateServiceCategory(
        id: number,
        name: string,
        crmId: number,
        picture?: File
    ): Promise<RequestResult<ServiceCategoryResp>> {
        const result = await this.put<ServiceCategoryResp>(`/service-categories/${id}`, {
            name: name,
            crm_id: crmId,
            picture: picture,
        });
        return result;
    }

    /**
     * Удаление категории услуг
     * @param id ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeServiceCategory(
        id: number,
        crmId: number
    ): Promise<RequestResult<ServiceCategoryResp>> {
        const result = await this.delete<ServiceCategoryResp>(`/service-categories/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Восстановление категории услуг
     * @param id ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreServiceCategory(
        id: number,
        crmId: number
    ): Promise<RequestResult<ServiceCategoryResp>> {
        const result = await this.post<ServiceCategoryResp>(`/service-categories/${id}/restore`, {
            crm_id: crmId,
        });
        return result;
    }
    /**
     * Получение услуг категории услуг
     * @param id ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getServiceCategoryServiceList(
        id: number,
        crmId: number
    ): Promise<RequestResult<ServiceListResp>> {
        const result = await this.get<ServiceListResp>(`/service-categories/${id}/services`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * @deprecated
     * Поиск категорий товаров
     * @param crmId ID CRM
     * @param serviceCategorySearchModel Объект модели поиска категорий товаров
     * @returns Результат запроса
     */
    async searchServiceCategory(
        crmId: number,
        serviceCategorySearchModel: TServiceCategorySearch
    ): Promise<RequestResult<ServiceCategoryListResp>> {
        let query = '';
        Object.keys(serviceCategorySearchModel).forEach((key) => {
            if (serviceCategorySearchModel[key] == '') return;
            query +=
                key +
                '=' +
                (serviceCategorySearchModel[key] ? serviceCategorySearchModel[key] : '') +
                '&';
        });
        query = query.slice(0, -1);
        const result = await this.get<ServiceCategoryListResp>('/service-categories/search', {
            crm_id: crmId,
            query: query,
        });
        return result;
    }

    /**
     * Обновление родительских категорий
     * @param crmId ID CRM
     * @param serviceCategories Карта перемещений
     * @returns Результат запроса
     */
    async updateServiceParentCategories(
        crmId: number,
        serviceCategories: Array<{ id: number; parent_service_category_id: number }>
    ): Promise<RequestResult<any>> {
        const result = await this.post<any>('/service-categories/update/bulk', {
            crm_id: crmId,
            service_categories: serviceCategories,
        });
        return result;
    }

    /**
     * Получение id всех дочерних категорий, включая потомки потомков
     * @param crmId ID CRM
     * @param categoryId id категории
     * @returns Результат запроса
     */
    async getAllIncludedServiceCategories(
        crmId: number,
        categoryId: number
    ): Promise<RequestResult<any>> {
        const result = await this.get<any>(`/service-categories/${categoryId}/categories`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Получение общего количества сервисов, если передается параметр id, то поиск идет в этом разделе и всех подразделах
     * @param crmId ID CRM
     * @param categoryId id категории
     * @returns Результат запроса
     */
    async getServiceCatalogInfo(crmId: number, categoryId?: number): Promise<RequestResult<any>> {
        const result = await this.get<any>(
            categoryId == null 
            ? '/service-categories/catalog' 
            : `/service-categories/catalog/${categoryId}`
        , {
            crm_id: crmId,
        });
        return result;
    }
}

export { ServiceCategoryGroup };
