import { CRMAPIBase } from "../crmApiBase";
import { RequestResult } from "../responseModels/requestResult";
import { LoginResp } from "../responseModels/security/loginResponse";
import { RegistrationResp } from "../responseModels/security/registrationResponse";
import { ConnectionResp } from "../responseModels/security/connectionResponse";

class SecurityGroup extends CRMAPIBase{
    /**
     * Запрос на наличие пользователя в БД с таким номером телефона
     * @param phone Номер телефона
     * @returns Результат запроса
     */
    async connection(phone: string): Promise<RequestResult<ConnectionResp>> {
        const result = await this.post<ConnectionResp>("/security/connection", { phone: phone });
        return result;
    }

    /**
     * Запросить код
     * @param phone Номер телефона 
     * @returns Результат запроса
     */
    async sendCode(phone: string): Promise<RequestResult<any>> {
        const result = await this.post<any>("/security/sendCode", { phone: phone });
        return result;
    }

    /**
    * Логин
    * @param phone Номер телефона
    * @param passcode Код входа
    * @returns Результат запроса
    */
    async login(phone: string, passcode: string): Promise<RequestResult<LoginResp>> {
        this._token = null;
        delete this._httpClient.defaults.headers.common['Authorization'];
        const result = await this.post<LoginResp>("/security/login", { phone: phone, passcode: passcode });
        if (!result.errorMessages) {
            this._token = result.data.token;
            this._httpClient.defaults.headers.common['Authorization'] = "Bearer " + this._token;
        }
        return result;
    }

    /**
    * Регистрация
    * @param phone Номер телефона
    * @param passcode Код входа
    * @returns Результат запроса
    */
    async register(phone: string, passcode: string): Promise<RequestResult<RegistrationResp>> {
        this._token = null;
        delete this._httpClient.defaults.headers.common['Authorization'];
        const result = await this.post<RegistrationResp>("/security/register", { phone: phone, passcode: passcode });
        if (!result.errorMessages) {
            this._token = result.data.token;
            this._httpClient.defaults.headers.common['Authorization'] = "Bearer " + this._token;
        }
        return result;
    }

    /**
     * Закрытие сессии
     * @param CRMID Идентификатор текущей CRM
     * @returns Результат запроса
     */
    async logout(CRMID: number): Promise<RequestResult<any>> {
        this._token = null;
        const result = await this.get<any>("/security/logout", { crm_id: CRMID });
        return result;
    }
}

export { SecurityGroup }