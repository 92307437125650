import { memo } from 'react';

import { Col, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    commentValue: string;
    onCommentValueChange: (value: string) => void;
    toggleTextArea?: () => void;
    visible?: boolean;
}

export const CommentTextArea: React.FC<Props> = memo(
    ({ commentValue, onCommentValueChange, toggleTextArea, visible = true }) => {
        const handleDelete = () => {
            onCommentValueChange('');
            toggleTextArea();
        };

        return (
            visible && (
                <div className="comment-text-area-container">
                    <Row
                        className="comment-block relative-container comment-block-height"
                        align={'middle'}
                        justify={'start'}
                    >
                        <Col span={24}>
                            <TextArea
                                value={commentValue}
                                onChange={(e) => onCommentValueChange(e.target.value)}
                                className="comment-input"
                                placeholder="Коментарии"
                                autoSize={true}
                                autoFocus
                            />
                            <div
                                className="item-delete-btn item-delete-btn-input "
                                onClick={handleDelete}
                            >
                                <FontAwesomeIcon color="white" icon={faXmark} />
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        );
    }
);
