import { UserResp } from "@api/responseModels/user/userResponse";
import { BruhTimePicker } from "@atoms/bruhTimePicker";
import { Loader } from "@atoms/loader";
import { Common } from "@classes/commonMethods";
import { CRMAPIManager } from "@classes/crmApiManager";
import { messageService } from "@classes/messageService";
import { SettingsManager } from "@classes/settingsManager";
import { GlobalConstants } from "@constants/global";
import { faAngleDown, faAngleLeft, faCalendar, faCaretDown, faCaretRight, faCheck, faEdit, faHouse, faPlus, faUserTie, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DurationPicker } from "@organisms/pickers/durationPicker";
import { LastIdStore } from "@pages/lastIdStore";
import { Breadcrumb, Button, Col, Collapse, DatePicker, Input, InputNumber, List, Modal, Row, Select, Switch, Table, message } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useReactive } from "ahooks";
import { FunctionalMenu } from "@molecules/functionalMenu";
import { baseScheduleException, emptySchedule, scheduleTypes, startExceptions, templateExamples, workers } from "./common/data";
import { ScheduleExceptionModal } from "@components/workers/schedule/partials/exceptionModal/exceptionModal";
import { SimilarScheduleModal } from "@components/workers/schedule/partials/similarModal/similarModal";
import { weekDays } from "./common/constants";
import { TSchedule, TScheduleException, TScheduleTemplate } from "./common/types";
var _ = require('lodash');

const { Panel } = Collapse;

export type TimePeriod = {
    from?: moment.Moment,
    /**
     * Разница в минутах
     */
    duration?: number,
    to?: moment.Moment
}

type State = {
    isLoading: boolean,
    schedule: TSchedule,
    templates: Array<TScheduleTemplate>,
    activeTemplateId: number,
    activeTemplateNameMirror: string,
    templatesFolded: boolean,
    templatesEdit: boolean,
    overtimePickerVisible: boolean,
    similarScheduleModal: boolean,
    similarScheduleList: Array<{ value: number, name: string }>,
    selectedScheduleAtSave: number,
    exceptions: Array<TScheduleException>,
    exceptionModal: boolean,
    curException: TScheduleException,
    durationPickerVisible: boolean,
    exceptionEditMode: boolean,
    exceptionEnd: moment.Moment,
    availablePeriods: TimePeriod[],
    templateConfirm: boolean,
    curUser?: { id: number; name: string; surname: string; specialty: string; },
    tempBreaks: {
        main: { start_time?: Date, end_time?: Date },
        days: { start_time?: Date, end_time?: Date }[]
    },
    suggestedName: string
};

const WorkerSchedule = observer((): JSX.Element => {
    const state = useReactive<State>({
        isLoading: false,
        schedule: emptySchedule,
        templates: templateExamples,
        activeTemplateId: -1,
        activeTemplateNameMirror: '',
        templatesFolded: true,
        templatesEdit: false,
        overtimePickerVisible: false,
        similarScheduleModal: false,
        similarScheduleList: [],
        selectedScheduleAtSave: -1,
        exceptions: startExceptions,
        exceptionModal: false,
        curException: baseScheduleException([]),
        durationPickerVisible: false,
        exceptionEditMode: false,
        exceptionEnd: moment().hours(8).minutes(0).add(2, "day"),
        availablePeriods: [],
        templateConfirm: false,
        curUser: null,
        tempBreaks: {
            main: { start_time: null, end_time: null },
            days: []
        },
        suggestedName: "Ежедневно 9-18"
    });
    /// Константы
    const navigate = useNavigate();
    const creds = SettingsManager.getConnectionCredentials();
    const [messageApi, contextHolder] = message.useMessage();

    function genDays(
        days: number,
        start_time: string,
        end_time: string,
        breaks: TScheduleTemplate['breaks']
    ): TScheduleTemplate['days'] {
        let arr = [];
        for (let i = 0; i < days; i++) {
            arr.push({
                start_time: start_time,
                end_time: end_time,
                breaks: [...breaks],
                index: i,
                type: 'default',
            });
        }
        return arr;
    }

    function setTemplate(id: number) {
        const template = state.templates.find((t) => t.template_id == id);
        if (!template) return;
        if (template.template_id == state.activeTemplateId) {
            state.activeTemplateId = -1;
            state.activeTemplateNameMirror = '';
            state.schedule = emptySchedule;
            return;
        }
        state.activeTemplateId = id;
        state.activeTemplateNameMirror = template.template_name;
        state.tempBreaks.days = template.days.map(_ => { return { start_time: state.tempBreaks.main.start_time, end_time: state.tempBreaks.main.end_time } })
        state.schedule = {
            start_schedule_at: state.schedule.start_schedule_at,
            ...template,
        };
    }

    async function checkWorkerBelongToCrm() {
        try {
            const tuser = await CRMAPIManager.request<UserResp>(async (api) => {
                return await api.getUser(LastIdStore.lastUserId, creds.crmID);
            });
            if (!isNaN(LastIdStore.lastUserId) && tuser.statusCode == 404) {
                navigate(`/lk/worker/crm/${creds.crmID}/workers`);
            }
            state.curUser = {
                id: tuser.data?.data.worker_profile.id,
                name: tuser.data?.data.name,
                surname: tuser.data?.data.surname,
                specialty: tuser.data?.data.worker_profile.role?.name,
            };
            state.exceptions.map((e) => {
                return { ...e, workers: [state.curUser] };
            });
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
    }

    async function beforeMountSchedule() {
        state.isLoading = true;
        const urlArr = window.location.pathname.split('/');
        LastIdStore.setLastUserId(Number(urlArr[urlArr.length - 2]));
        const res = await Common.checkUserBelongToCrm(GlobalConstants.CrmOrderinUrl);
        if (!res) navigate(`/lk/worker/crm/${creds.crmID}`);
        if (!isNaN(LastIdStore.lastUserId)) await checkWorkerBelongToCrm();
        state.isLoading = false;
    }

    useEffect(() => {
        beforeMountSchedule();
    }, []);

    function toDate(dateString: string): Date {
        if (dateString.includes(':')) {
            const parts = dateString.split(':');
            let date = new Date();
            date.setHours(Number(parts[0]));
            date.setMinutes(Number(parts[1]));
            return date;
        } else {
            const parts = dateString.split('-');
            return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
        }
    }

    function handleDateChange(dateString: string, param: string, index?: number) {
        if (param == "start_schedule_at") {
            state.schedule = { ...state.schedule, start_schedule_at: dateString };
        }
        else {
            let mainFrom = moment(index == null ? state.schedule.start_time : state.schedule.days[index].start_time, "HH:mm");
            let mainTo = moment(index == null ? state.schedule.end_time : state.schedule.days[index].end_time, "HH:mm");
            if (mainTo.isSameOrBefore(mainFrom)) {
                mainTo.add(1, "day");
            }

            if (param == "start_time") {

                if (index != null) {
                    state.schedule.days[index].start_time = dateString;
                    const newBreaks = state.schedule.days[index].breaks.filter(v => moment(v.start_time).isSameOrAfter(mainFrom));
                    state.schedule.days[index].breaks = newBreaks;
                }
                else {
                    state.schedule.start_time = dateString;
                    const newBreaks = state.schedule.breaks.filter(v => moment(v.start_time).isSameOrAfter(mainFrom));
                    state.schedule.breaks = newBreaks;
                }
            }
            else {
                if (index != null) {
                    state.schedule.days[index].end_time = dateString;
                    const newBreaks = state.schedule.days[index].breaks.filter(v => moment(v.end_time).isSameOrBefore(mainTo));
                    state.schedule.days[index].breaks = newBreaks;
                }
                else {
                    state.schedule.end_time = dateString;
                    const newBreaks = state.schedule.breaks.filter(v => moment(v.end_time).isSameOrBefore(mainTo));
                    state.schedule.breaks = newBreaks;
                }
            }
        }
    }

    function suggestScheduleName() {
        let suggestion = "";
        const sType = state.schedule.template_type.type;
        switch (sType) {
            case "daily": { suggestion = "Ежедневно"; break; }
            case "interval": { suggestion = `${state.schedule.template_type.on}/${state.schedule.template_type.off}`; break; }
            case "week": { suggestion = "5/2"; break; }
        }
        suggestion += ` ${Number(state.schedule.start_time.split(":")[0])}-${Number(state.schedule.end_time.split(":")[0])}`;
        let sNum = 1;
        while (state.templates.find(t => t.template_name == `${suggestion} #${sNum}`) != undefined) {
            sNum++;
        }
        suggestion += ` #${sNum}`;
        state.suggestedName = suggestion;
    }

    useEffect(() => {
        if (state.activeTemplateNameMirror === "" || state.activeTemplateNameMirror.match(/^ *$/) !== null) {
            suggestScheduleName();
        }
    }, [
        state.schedule.template_type.type,
        state.schedule.template_type.on,
        state.schedule.template_type.off,
        state.schedule.start_time,
        state.schedule.end_time,
        state.activeTemplateNameMirror
    ]);

    function fillNameIfEmpty() {
        if (state.activeTemplateNameMirror === "" || state.activeTemplateNameMirror.match(/^ *$/) !== null) {
            if (state.activeTemplateId == -1) {
                suggestScheduleName();
                state.activeTemplateNameMirror = state.suggestedName;
            }
            else {
                const tempName = state.templates.find(t => t.template_id == state.activeTemplateId)?.template_name
                state.activeTemplateNameMirror = tempName;
            }
        }
    }

    async function trySaveTemplate() {
        if (state.similarScheduleModal && state.selectedScheduleAtSave != -1) {
            const temp = state.templates.find(t => t.template_id == state.selectedScheduleAtSave);
            setTemplate(state.selectedScheduleAtSave);
            state.similarScheduleModal = false;
            messageApi.success(`Выбран шаблон \"${temp.template_name}\"`);
        }
        else {
            state.templates = state.templates.map(t => t.template_id == state.activeTemplateId
                ? {
                    start_time: state.schedule.start_time,
                    end_time: state.schedule.end_time,
                    breaks: state.schedule.breaks,
                    days: state.schedule.days,
                    template_type: state.schedule.template_type,
                    workers: state.schedule.workers,
                    template_name: state.activeTemplateNameMirror,
                    template_id: state.activeTemplateId
                }
                : t
            );
            state.similarScheduleModal = false;
            messageApi.success("Изменения сохранены");
        }
    }

    async function trySaveSchedule() {
        if (state.similarScheduleModal && state.selectedScheduleAtSave != -1) {
            const temp = state.templates.find(t => t.template_id == state.selectedScheduleAtSave);
            setTemplate(state.selectedScheduleAtSave);
            state.similarScheduleModal = false;
            messageApi.success(`Выбран шаблон \"${temp.template_name}\"`);
        }
        else {
            const newId = moment().milliseconds();
            state.templates = state.templates.concat({
                start_time: state.schedule.start_time,
                end_time: state.schedule.end_time,
                breaks: state.schedule.breaks,
                days: state.schedule.days,
                template_type: state.schedule.template_type,
                workers: state.schedule.workers,
                template_name: state.activeTemplateNameMirror,
                template_id: newId
            });
            state.activeTemplateId = newId;
            state.similarScheduleModal = false;
            messageApi.success("Вывод в консоли.");
            console.log(state.schedule, state.exceptions);
        }
    }

    function checkForSimilar() {
        let curSchedule = {
            template_id: null,
            template_name: null,
            start_time: state.schedule.start_time,
            end_time: state.schedule.end_time,
            breaks: [...state.schedule.breaks],
            days: [...state.schedule.days.map(d => { return { ...d, breaks: [...d.breaks] } })],
            template_type: {
                ...state.schedule.template_type,
            },
            workers: [],
        };

        for (let i = 0; i < state.templates.length; i++) {
            let temp = { ...state.templates[i], template_name: null, template_id: null, workers: [] };
            //console.log(temp, _.isEqual(temp, curSchedule));
            if (_.isEqual(temp, curSchedule) && state.templates[i].template_id != state.activeTemplateId) {
                state.similarScheduleList = state.similarScheduleList.concat({ value: state.templates[i].template_id, name: state.templates[i].template_name });
            }
        }
    }

    async function prepareForSave() {
        state.isLoading = true;
        fillNameIfEmpty();
        if (state.templates.find(t => t.template_name == state.activeTemplateNameMirror && t.template_id != state.activeTemplateId)) {
            messageApi.error("Уже есть шаблон с таким именем.");
            return;
        }
        if (!state.similarScheduleModal) {
            state.similarScheduleList = [];
            checkForSimilar();
            if (state.similarScheduleList.length != 0) {
                state.selectedScheduleAtSave = -1;
                state.similarScheduleModal = true;
                state.isLoading = false;
                return;
            }
        }
        if (state.activeTemplateId != -1) {
            if (state.schedule.workers.length > 0) {
                state.templateConfirm = true;
            }
            else await trySaveTemplate();
        }
        else {
            await trySaveSchedule();
        }
        state.isLoading = false;
    }

    function onChangeDuration(duration: number, _) {
        state.durationPickerVisible = false;
        state.curException = { ...state.curException, duration: duration };
        const startTimeSplit = state.curException.start_time.split(':').map((v) => Number(v));
        state.exceptionEnd = moment(toDate(state.curException.start_date))
            .hours(startTimeSplit[0])
            .minutes(startTimeSplit[1])
            .add(duration, 'minutes');
    }

    function onCancelDuration() {
        state.durationPickerVisible = false;
    }

    function onChangeOvertime(duration: number, _) {
        state.overtimePickerVisible = false;
        state.schedule = {
            ...state.schedule,
            template_type: {
                ...state.schedule.template_type,
                allowed_overtime: duration,
            },
        };
    }

    function onCancelOvertime() {
        state.overtimePickerVisible = false;
    }

    function tryAddBreak(index: number, dates: moment.Moment[]) {
        let mainFrom = moment(
            index == null ? state.schedule.start_time : state.schedule.days[index].start_time,
            'HH:mm'
        );
        let mainTo = moment(
            index == null ? state.schedule.end_time : state.schedule.days[index].end_time,
            'HH:mm'
        );
        let targetFrom = moment(dates[0].clone());
        let targetTo = moment(dates[1].clone());
        // Нормализация
        if (mainTo.isSameOrBefore(mainFrom)) {
            mainTo.add(1, 'day');
            if (targetFrom.hours() < mainFrom.hours()) {
                targetFrom.add(1, 'day');
                targetTo.add(1, 'day');
            } else if (targetTo.isBefore(targetFrom)) {
                targetTo.add(1, 'day');
            }
        }
        if (!targetFrom.isBefore(targetTo)) {
            messageApi.error('Начало должно быть раньше конца');
            return;
        }
        if (targetTo.diff(targetFrom, 'seconds') < 599) {
            messageApi.info('Перерыв должен быть не менее 10 минут');
            return;
        }
        //console.log(`mainFrom: ${mainFrom.toLocaleString()}, mainTo: ${mainTo.toLocaleString()}, targetFrom: ${targetFrom.toLocaleString()}, targetTo: ${targetTo.toLocaleString()}`)
        // Проверка, что в границах раб. дня
        if (
            targetFrom.isBetween(mainFrom, mainTo, null, '[]') &&
            targetTo.isBetween(mainFrom, mainTo, null, '[]')
        ) {
            // Проверка, что не пересекается с другими перерывами
            const compareTo =
                index == null ? state.schedule.breaks : state.schedule.days[index].breaks;
            for (let i = 0; i < compareTo.length; i++) {
                let brFrom = moment(compareTo[i].start_time);
                let brTo = moment(compareTo[i].end_time);
                if (
                    targetFrom.isBetween(brFrom, brTo, null, '[]') ||
                    targetTo.isBetween(brFrom, brTo, null, '[]') ||
                    brFrom.isBetween(targetFrom, targetTo, null, '[]') ||
                    brTo.isBetween(targetFrom, targetTo, null, '[]')
                ) {
                    messageApi.error('Перерыв не может пересекаться с другими');
                    return;
                }
            }
            compareTo.push({ start_time: targetFrom.toDate(), end_time: targetTo.toDate() });
            let toSave = _.orderBy(compareTo, ['start_time'], ['asc']);
            if (index == null) state.schedule.breaks = toSave;
            else {
                state.schedule.days[index].type = 'changed';
                state.schedule.days[index].breaks = toSave;
            }
            messageApi.success("Перерыв добавлен");
            return;
        }
        else {
            messageApi.error("Перерыв должен быть внутри рабочего дня");
            return;
        }
    }

    function tryAddException(exc: TScheduleException) {
        const timeParts = exc.start_time.split(":").map(v => Number(v));
        let excStart = moment(exc.start_date, "DD-MM-YYYY").hours(timeParts[0]).minutes(timeParts[1]);
        let excEnd = excStart.clone().add(exc.duration, "minutes");
        let isNew = true;

        for (let i = 0; i < state.exceptions.length; i++) {
            if (state.exceptions[i].id != exc.id) {
                const targetTimeParts = state.exceptions[i].start_time.split(":").map(v => Number(v));
                let targetStart = moment(state.exceptions[i].start_date, "DD-MM-YYYY").hours(targetTimeParts[0]).minutes(targetTimeParts[1]);
                let targetEnd = targetStart.clone().add(state.exceptions[i].duration, "minutes");
                if (targetStart.isBetween(excStart, excEnd, null, "[]") || targetEnd.isBetween(excStart, excEnd, null, "[]") || excStart.isBetween(targetStart, targetEnd, null, "[]") || excEnd.isBetween(targetStart, targetEnd, null, "[]")) {
                    messageApi.error("Исключение пересекается с другими");
                    return;
                }
            }
            else isNew = false;
        }

        let exc_status = null;
        if (moment().isBetween(excStart, excEnd, null, "[]")) {
            exc_status = "Активно";
        }
        else if (moment().isBefore(excStart)) {
            exc_status = "Ожидает";
        }
        else {
            exc_status = "Завершено";
        }

        if (isNew) {
            const newExceptions = state.exceptions.concat({ ...exc, status: exc_status }).sort((a, b) => moment(a.start_date, "DD-MM-YYYY").diff(moment(b.start_date, "DD-MM-YYYY")));
            state.exceptions = newExceptions;
        }
        else {
            const newExceptions = state.exceptions.map(e => e.id == exc.id ? { ...exc, status: exc_status } : e).sort((a, b) => moment(a.start_date, "DD-MM-YYYY").diff(moment(b.start_date, "DD-MM-YYYY")));
            state.exceptions = newExceptions;
        }
        state.exceptionModal = false;
    }

    useEffect(() => {
        state.availablePeriods = getAvailablePeriods();
    }, [state.exceptions]);

    function getAvailablePeriods(): TimePeriod[] {
        const periods: TimePeriod[] = [];
        let tExc: TScheduleException, start: moment.Moment, end: moment.Moment, firstAfterToday = false, addedInLast = false;
        const today = moment();
        for (let i = 0; i < state.exceptions.length; i++) {
            tExc = state.exceptions[i];
            start = moment(tExc.start_date, "DD-MM-YYYY").hours(Number(tExc.start_time.split(":")[0])).minutes(Number(tExc.start_time.split(":")[1]));
            end = start.clone().add(tExc.duration, "minute");
            if (end.isBefore(today)) continue;
            if (addedInLast) {
                periods[periods.length - 1].to = start;
                periods[periods.length - 1].duration = start.diff(periods[periods.length - 1].from, "minutes");
                addedInLast = false;
            }
            if (start.isAfter(today)) {
                if (!firstAfterToday) {
                    periods.push({ from: today, duration: start.diff(today, "minutes"), to: start });
                    firstAfterToday = true;
                }
                periods.push({ from: end, duration: null, to: null });
                addedInLast = true;
            }
        }
        return periods;
    }

    return (
        <div id="app-worker-schedule">
            {contextHolder}
            {state.isLoading && <Loader />}
            <DurationPicker
                isModalOpen={state.durationPickerVisible}
                onOk={onChangeDuration}
                onCancel={onCancelDuration}
                defaultValue={state.curException?.duration}
                showTimeCountMethod={false}
                totalTime={false}
                showMinutes={!state.curException?.exception_type.dailyBoundaries}
                showHours={!state.curException?.exception_type.dailyBoundaries}
                showDays={state.curException?.exception_type.dailyBoundaries}
                showMonths={false}
            />
            <DurationPicker
                isModalOpen={state.overtimePickerVisible}
                onOk={onChangeOvertime}
                onCancel={onCancelOvertime}
                defaultValue={state.schedule?.template_type.allowed_overtime}
                showTimeCountMethod={false}
                totalTime={false}
                showDays={false}
                showMonths={false}
            />
            <FunctionalMenu
                items={[
                    {
                        key: 'save',
                        label: 'Сохранить',
                        icon: <FontAwesomeIcon icon={faCheck} />,
                        onClick: prepareForSave,
                    },
                    /*{
                        disabled: true,
                        key: 'save-template',
                        label: 'Сохранить как шаблон',
                        icon: <FontAwesomeIcon icon={faCheck} />,
                        onClick: null,
                    }*/
                ]}
                dropdownItems={[]}
            />
            <Modal
                className="template-confirm-modal"
                title="Изменение шаблона"
                open={state.templateConfirm}
                onCancel={() => state.templateConfirm = false}
                onOk={async () => {
                    await trySaveTemplate();
                    state.templateConfirm = false;
                }}
                okText="Сохранить"
            >
                <div className="exception-profile">
                    <Row className="vcentered vpadded">
                        <Col className="hpadded half-width">
                            <label>Используют график</label>
                        </Col>
                        <Col className="hpadded half-width">
                            {state.schedule.workers.map((w) => {
                                return (
                                    <Row className="vpadded">
                                        <label>{`${w.specialty} | ${w.name} ${w.surname}`}</label>
                                    </Row>
                                );
                            })}
                        </Col>
                    </Row>
                </div>
            </Modal>
            <ScheduleExceptionModal
                availablePeriods={state.availablePeriods}
                curException={state.curException}
                exceptionEnd={state.exceptionEnd}
                curUser={state.curUser}
                exceptionEditMode={state.exceptionEditMode}
                exceptionModal={state.exceptionModal}
                onClose={() => (state.exceptionModal = false)}
                onDelete={() => {
                    state.exceptions = state.exceptions.filter(
                        (exc) => exc.id != state.curException.id
                    );
                    state.exceptionModal = false;
                }}
                onSave={() => {
                    if (state.exceptionEditMode) {
                        if (state.curException.duration <= 0) {
                            messageApi.error("Не задана продолжительность исключения")
                        }
                        else {
                            tryAddException(state.curException);
                        }
                    }
                    else {
                        if (state.curException.duration <= 0) {
                            messageApi.error("Не задана продолжительность исключения")
                        }
                        else {
                            tryAddException({ ...state.curException, id: moment().millisecond() });
                        }
                    }
                }}
                setCurException={(exc) => {
                    state.curException = exc;
                    return state.curException;
                }}
                setExceptionEnd={(value) => {
                    state.exceptionEnd = value;
                }}
                setDurationPickerVisible={(val) => {
                    state.durationPickerVisible = val;
                }}
                messageApi={messageApi}
            />
            <SimilarScheduleModal
                isOpen={state.similarScheduleModal}
                setIsOpen={(value) => state.similarScheduleModal = value}
                onOk={async () => await prepareForSave()}
                selectedSchedule={state.selectedScheduleAtSave}
                setSelectedSchedule={(value) => state.selectedScheduleAtSave = value}
                activeTemplateName={state.activeTemplateNameMirror}
                changeActiveTemplateName={(value) => {
                    state.activeTemplateNameMirror = value;
                }}
                placeholder={
                    state.activeTemplateId != -1
                        ? state.templates.find(t => t.template_id == state.activeTemplateId)?.template_name
                        : state.suggestedName
                }
                similarScheduleList={state.similarScheduleList}
            />
            <Row className="breadcrumb-header">
                <Col className="header-location">
                    <span>График работы</span>
                </Col>
            </Row>
            <Row className="breadcrumbs-controls">
                <Col className="breadcrumbs">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate(`/lk/worker/crm/${creds?.crmID}`)}>
                            <FontAwesomeIcon icon={faHouse} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClick={() => navigate(`/lk/worker/crm/${creds?.crmID}/workers`)}
                        >
                            <FontAwesomeIcon icon={faUserTie} />
                            <span className="crumb-name">Сотрудники</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClick={() =>
                                navigate(
                                    `/lk/worker/crm/${creds?.crmID}/workers/${LastIdStore.lastUserId}`
                                )
                            }
                        >
                            <span>ID: {LastIdStore.lastUserId}</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faCalendar} />
                            <span className="crumb-name">График</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <div className="schedule-profile">
                <Collapse
                    bordered={false}
                    defaultActiveKey={[]}
                    expandIcon={(_props) => {
                        return (
                            <Button
                                className="green-btn btn-icon-separated"
                                icon={
                                    <FontAwesomeIcon
                                        icon={state.templatesFolded ? faAngleLeft : faAngleDown}
                                    />
                                }
                                onClick={() => (state.templatesFolded = !state.templatesFolded)}
                            >
                                Шаблоны
                            </Button>
                        );
                    }}
                    expandIconPosition="end"
                >
                    <Panel
                        className="template-panel"
                        collapsible="icon"
                        header={
                            <Input
                                className="template-header"
                                onChange={(e) => {
                                    state.activeTemplateNameMirror = e.target.value;
                                }}
                                placeholder={state.activeTemplateId != -1
                                    ? state.templates.find(t => t.template_id == state.activeTemplateId)?.template_name == ""
                                        ? state.suggestedName
                                        : state.templates.find(t => t.template_id == state.activeTemplateId)?.template_name
                                    : state.suggestedName
                                }
                                value={state.activeTemplateNameMirror}
                            />
                        }
                        key="template-list"
                    >
                        <Row align="top" className="template-stack">
                            {state.templates.length > 0 && state.templates.map((t, i) => {
                                return (
                                    <Col
                                        className="half-width"
                                        style={state.templatesEdit ? { paddingRight: '10px' } : {}}
                                    >
                                        <Button
                                            className={
                                                t.template_id == state.activeTemplateId
                                                    ? 'set-focus btn-center-text'
                                                    : 'btn-center-text'
                                            }
                                            onClick={(e) => {
                                                if (state.templatesEdit) return;
                                                setTemplate(
                                                    (t as TScheduleTemplate).template_id
                                                );
                                                e.currentTarget.blur();
                                            }}
                                            style={state.templatesEdit ? { marginTop: "10px" } : {}}
                                        >
                                            {`${i + 1}. ${(t as TScheduleTemplate).template_name}`}
                                            {state.templatesEdit &&
                                                <div
                                                    className="delete-btn"
                                                    onClick={() => {
                                                        if (t.workers.length == 0) {
                                                            state.templates = state.templates.filter(te => te.template_id != t.template_id);
                                                            if (t.template_id == state.activeTemplateId) {
                                                                state.activeTemplateId = -1;
                                                                state.activeTemplateNameMirror = "";
                                                                state.schedule = emptySchedule;
                                                                return;
                                                            }
                                                        }
                                                        else {
                                                            messageApi.info(`По этому графику работают ${t.workers.length} чел.`);
                                                        }
                                                    }}
                                                    style={{
                                                        backgroundColor: t.workers.length == 0 ? "red" : "#808080"
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faXmark} />
                                                </div>
                                            }
                                        </Button>
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row align="middle" className="template-stack">
                            <Col className="half-width">
                                <Button
                                    className="btn-icon-separated"
                                    danger={state.templatesEdit}
                                    icon={<FontAwesomeIcon icon={faEdit} />}
                                    onClick={() => {
                                        state.templatesEdit = !state.templatesEdit;
                                    }}
                                    type="default"
                                >
                                    Редактировать список
                                </Button>
                            </Col>
                            <Col className="half-width">
                                <Button
                                    className="green-btn btn-icon-separated"
                                    icon={<FontAwesomeIcon icon={faPlus} />}
                                    disabled
                                >
                                    Добавить
                                </Button>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
                <Collapse
                    bordered={false}
                    defaultActiveKey={["schedule", "days", "exceptions"]}
                    expandIcon={({ isActive }) => <FontAwesomeIcon className="bigger-caret" icon={isActive ? faCaretDown : faCaretRight} />}
                    expandIconPosition="end"
                >
                    <Panel
                        className="hide-header"
                        collapsible="disabled"
                        forceRender={true}
                        header="Рабочий график:"
                        key="schedule"
                        showArrow={false}
                    >
                        <Row>
                            <Col className="two-thirds-width">
                                <Row>
                                    <label className="required">Режим работы</label>
                                </Row>
                                <Row>
                                    <Select
                                        className="set-font-size"
                                        defaultValue={scheduleTypes[0].value}
                                        onChange={(value: 'week' | 'daily' | 'interval') => {
                                            state.schedule = {
                                                ...state.schedule,
                                                template_type: {
                                                    ...state.schedule.template_type,
                                                    type: value,
                                                    on: value == 'week' ? 7 : 1,
                                                    off: value == 'interval' ? 1 : 0,
                                                },
                                            };
                                            switch (value) {
                                                case "week":
                                                    state.schedule.days = genDays(7, state.schedule.start_time, state.schedule.end_time, state.schedule.breaks);
                                                    state.tempBreaks.days = state.schedule.days.map(() => { return { start_time: null, end_time: null } });
                                                case "interval":
                                                    if (state.schedule.template_type.on) {
                                                        state.schedule.days = genDays(state.schedule.template_type.on, state.schedule.start_time, state.schedule.end_time, state.schedule.breaks);
                                                        state.tempBreaks.days = state.schedule.days.map(() => { return { start_time: null, end_time: null } });
                                                    }
                                                    else {
                                                        state.schedule.days = [];
                                                        state.tempBreaks.days = [];
                                                    }
                                            }
                                        }}
                                        options={scheduleTypes}
                                        style={{ width: '90%' }}
                                        value={state.schedule.template_type.type}
                                    />
                                </Row>
                            </Col>
                            {state.schedule.template_type.type === 'interval' && (
                                <Col className="one-third-width">
                                    <Row>
                                        <label className="required">Начать график с</label>
                                    </Row>
                                    <Row>
                                        <DatePicker
                                            allowClear={false}
                                            className="wide-datepicker"
                                            disabledDate={(date) => {
                                                return date && date < moment().endOf('day');
                                            }}
                                            format="DD-MM-YYYY"
                                            inputReadOnly
                                            onChange={(_, dateString) =>
                                                handleDateChange(dateString, 'start_schedule_at')
                                            }
                                            picker="date"
                                            value={moment(toDate(state.schedule.start_schedule_at))}
                                        />
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        {state.schedule.template_type.type == "interval" && <Row className="vcentered">
                            <Col className="full-width">
                                <Row>
                                    <label className="required">
                                        Периодичность
                                    </label>
                                </Row>
                                <Row className="vcentered">
                                    <Col>
                                        <InputNumber
                                            min={1}
                                            max={14}
                                            step={1}
                                            value={state.schedule.template_type.on}
                                            onChange={(v) => {
                                                state.schedule = {
                                                    ...state.schedule, template_type: {
                                                        ...state.schedule.template_type,
                                                        on: v
                                                    }
                                                };
                                                if (v) {
                                                    state.schedule.days = genDays(v, state.schedule.start_time, state.schedule.end_time, state.schedule.breaks);
                                                    state.tempBreaks.days = state.schedule.days.map(() => { return { start_time: null, end_time: null } });
                                                }
                                                else {
                                                    state.schedule.days = [];
                                                    state.tempBreaks.days = [];
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col className="hpadded">
                                        <span>д. через</span>
                                    </Col>
                                    <Col>
                                        <InputNumber
                                            min={0}
                                            max={14}
                                            step={1}
                                            value={state.schedule.template_type.off}
                                            onChange={(v) => {
                                                state.schedule = {
                                                    ...state.schedule, template_type: {
                                                        ...state.schedule.template_type,
                                                        off: v
                                                    }
                                                };
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                        <Row className="vcentered">
                            <Col style={{ minWidth: '120px' }}>
                                <label className="required">Время работы</label>
                            </Col>
                            <Col>
                                <label className="hpadded">с</label>
                            </Col>
                            <Col className="bigger-time">
                                <BruhTimePicker
                                    closeOnSelect={true}
                                    format="HH:mm"
                                    onChange={(val, _) => {
                                        handleDateChange(val.format('HH:mm'), 'start_time');
                                    }}
                                    value={moment(toDate(state.schedule.start_time))}
                                />
                            </Col>
                            <Col>
                                <label className="hpadded">до</label>
                            </Col>
                            <Col className="bigger-time">
                                <BruhTimePicker
                                    closeOnSelect={true}
                                    format="HH:mm"
                                    onChange={(val, _) => {
                                        handleDateChange(val.format('HH:mm'), 'end_time');
                                    }}
                                    value={moment(toDate(state.schedule.end_time))}
                                />
                            </Col>
                        </Row>
                        <Row className="vcentered">
                            <Col style={{ minWidth: '120px' }}>
                                <label>Перерыв</label>
                            </Col>
                            {state.schedule.breaks.length > 0 ? (
                                <Col>
                                    {state.schedule.breaks
                                        .map((value, index) => (
                                            <Row className="vpadded vcentered">
                                                <Col>
                                                    <label className="hpadded">с</label>
                                                </Col>
                                                <Col>
                                                    <label className="hpadded">
                                                        {moment(value.start_time).format('HH:mm')}
                                                    </label>
                                                </Col>
                                                <Col>
                                                    <label className="hpadded">до</label>
                                                </Col>
                                                <Col>
                                                    <label className="hpadded">
                                                        {moment(value.end_time).format('HH:mm')}
                                                    </label>
                                                </Col>
                                                <Col>
                                                    <Button
                                                        danger
                                                        className="hmargined delete-btn"
                                                        icon={<FontAwesomeIcon icon={faXmark} />}
                                                        onClick={() => {
                                                            const tBreaks = state.schedule.breaks;
                                                            tBreaks.splice(index, 1);
                                                            state.schedule = {
                                                                ...state.schedule,
                                                                breaks: tBreaks,
                                                            };
                                                        }}
                                                        title="Удалить перерыв"
                                                    />
                                                </Col>
                                            </Row>
                                        )).concat(<Row className="vpadded vcentered">
                                            <Col>
                                                <label className="hpadded">
                                                    с
                                                </label>
                                            </Col>
                                            <Col>
                                                <BruhTimePicker
                                                    closeOnSelect={true}
                                                    format="HH:mm"
                                                    onChange={(val, _) => {
                                                        state.tempBreaks.main.start_time = val;
                                                    }}
                                                    value={moment(state.tempBreaks.main.start_time)}
                                                />
                                            </Col>
                                            <Col>
                                                <label className="hpadded">
                                                    до
                                                </label>
                                            </Col>
                                            <Col>
                                                <BruhTimePicker
                                                    closeOnSelect={true}
                                                    format="HH:mm"
                                                    onChange={(val, _) => {
                                                        state.tempBreaks.main.end_time = val;
                                                    }}
                                                    value={moment(state.tempBreaks.main.end_time)}
                                                />
                                            </Col>
                                            <Col>
                                                <Button
                                                    disabled={state.tempBreaks.main.start_time == null || state.tempBreaks.main.end_time == null}
                                                    className="hmargined green-btn"
                                                    icon={<FontAwesomeIcon icon={faPlus} />}
                                                    onClick={() => {
                                                        tryAddBreak(null, [moment(state.tempBreaks.main.start_time), moment(state.tempBreaks.main.end_time)])
                                                    }}
                                                    title="Добавить перерыв"
                                                />
                                            </Col>
                                        </Row>)
                                    }
                                </Col>)
                                : (<Col>
                                    <i>Без перерывов</i>
                                    <Row className="vpadded vcentered">
                                        <Col>
                                            <label className="hpadded">
                                                с
                                            </label>
                                        </Col>
                                        <Col>
                                            <BruhTimePicker
                                                closeOnSelect={true}
                                                format="HH:mm"
                                                onChange={(val, _) => {
                                                    state.tempBreaks.main.start_time = val;
                                                }}
                                                value={moment(state.tempBreaks.main.start_time)}
                                            />
                                        </Col>
                                        <Col>
                                            <label className="hpadded">
                                                до
                                            </label>
                                        </Col>
                                        <Col>
                                            <BruhTimePicker
                                                closeOnSelect={true}
                                                format="HH:mm"
                                                onChange={(val, _) => {
                                                    state.tempBreaks.main.end_time = val;
                                                }}
                                                value={moment(state.tempBreaks.main.end_time)}
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                disabled={state.tempBreaks.main.start_time == null || state.tempBreaks.main.end_time == null}
                                                className="hmargined green-btn"
                                                icon={<FontAwesomeIcon icon={faPlus} />}
                                                onClick={() => {
                                                    tryAddBreak(null, [moment(state.tempBreaks.main.start_time), moment(state.tempBreaks.main.end_time)])
                                                }}
                                                title="Добавить перерыв"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                )}
                        </Row>
                        <Row>
                            <Col className="half-width">
                                <Row>
                                    <label>По этому графику работают</label>
                                </Row>
                                <Row>
                                    <Select
                                        className="worker-select"
                                        maxTagCount="responsive"
                                        mode="multiple"
                                        onChange={(value) => {
                                            state.schedule = {
                                                ...state.schedule,
                                                workers: workers.filter(
                                                    (w) =>
                                                        value.includes(w.id) ||
                                                        w.id == state.curUser?.id
                                                ),
                                            };
                                        }}
                                        options={
                                            state.curUser
                                                ? [state.curUser].concat(workers).map((w) => {
                                                    return {
                                                        value: w.id,
                                                        label: `${w.specialty} | ${w.name
                                                            } ${w.surname.charAt(0)}.`,
                                                    };
                                                })
                                                : workers.map((w) => {
                                                    return {
                                                        value: w.id,
                                                        label: `${w.specialty} | ${w.name
                                                            } ${w.surname.charAt(0)}.`,
                                                    };
                                                })
                                        }
                                        placeholder="Выберите сотрудник(-а/-ов)"
                                        style={{ width: '90%' }}
                                        value={_.uniq(
                                            [state.curUser?.id].concat(
                                                state.schedule.workers.map((w) => w.id)
                                            )
                                        )}
                                    />
                                </Row>
                            </Col>
                            <Col className="half-width">
                                <Row>
                                    <label>Разрешить продлевать раб. день на</label>
                                </Row>
                                <Row>
                                    <a onClick={() => (state.overtimePickerVisible = true)}>
                                        {state.schedule?.template_type.allowed_overtime > 0
                                            ? Common.convertMinutes2Date(
                                                state.schedule?.template_type.allowed_overtime
                                            )
                                            : 'Не разрешено'}
                                    </a>
                                </Row>
                            </Col>
                        </Row>
                    </Panel>
                    {state.schedule.template_type.type != "daily" && <Panel header="Настроить каждый день отдельно:" key="days">
                        <List
                            className="by-day-list"
                            dataSource={state.schedule.days}
                            itemLayout="horizontal"
                            renderItem={(item, index) => (
                                <List.Item key={"li" + index}>
                                    <Col className="full-width">
                                        <Row className="space-between">
                                            <Col>
                                                <b>{state.schedule.template_type.type == "week"
                                                    ? weekDays[item.index]
                                                    : `${item.index + 1} день`
                                                }</b>
                                            </Col>
                                            <Col>
                                                <label>
                                                    Выходной
                                                </label>
                                                <Switch
                                                    className="hmargined"
                                                    checked={item.type == "day_off"}
                                                    onChange={(checked) => {
                                                        item.type = checked ? "day_off" : "changed";
                                                        if (checked) {
                                                            state.schedule.days = state.schedule.days.map((v, i) => i == index ? { ...v, type: "day_off" } : v);
                                                        }
                                                        else {
                                                            state.schedule.days = state.schedule.days.map((v, i) => i == index ? { ...v, type: "changed" } : v);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        {item.type != "day_off" && (<>
                                            <Row className="vcentered">
                                                <Col style={{ minWidth: "120px" }}>
                                                    <label className="required">
                                                        Время работы
                                                    </label>
                                                </Col>
                                                <Col>
                                                    <label className="hpadded">
                                                        с
                                                    </label>
                                                </Col>
                                                <Col className="bigger-time">
                                                    <BruhTimePicker
                                                        format="HH:mm"
                                                        onChange={(val, _) => {
                                                            handleDateChange(val.format("HH:mm"), "start_time", index)
                                                        }}
                                                        value={moment(toDate(item.start_time))}
                                                    />
                                                </Col>
                                                <Col>
                                                    <label className="hpadded">
                                                        до
                                                    </label>
                                                </Col>
                                                <Col className="bigger-time">
                                                    <BruhTimePicker
                                                        format="HH:mm"
                                                        onChange={(val, _) => {
                                                            handleDateChange(val.format("HH:mm"), "end_time", index)
                                                        }}
                                                        value={moment(toDate(item.end_time))}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="vcentered">
                                                <Col style={{ minWidth: "120px" }}>
                                                    <label>
                                                        Перерыв
                                                    </label>
                                                </Col>
                                                {item.breaks.length > 0
                                                    ? (<Col>
                                                        {
                                                            item.breaks.map((value, bIndex) => (
                                                                <Row className="vpadded" key={bIndex + "_" + index}>
                                                                    <Col>
                                                                        <label className="hpadded">
                                                                            с
                                                                        </label>
                                                                    </Col>
                                                                    <Col>
                                                                        <label className="hpadded">
                                                                            {moment(value.start_time).format("HH:mm")}
                                                                        </label>
                                                                    </Col>
                                                                    <Col>
                                                                        <label className="hpadded">
                                                                            до
                                                                        </label>
                                                                    </Col>
                                                                    <Col>
                                                                        <label className="hpadded">
                                                                            {moment(value.end_time).format("HH:mm")}
                                                                        </label>
                                                                    </Col>
                                                                    <Col>
                                                                        <Button
                                                                            danger
                                                                            className="hmargined delete-btn"
                                                                            icon={<FontAwesomeIcon icon={faXmark} />}
                                                                            onClick={() => {
                                                                                state.schedule.days = state.schedule.days.map((v, i) => i == index ? { ...v, breaks: v.breaks.filter((_, j) => j != bIndex), type: "changed" } : v);
                                                                            }}
                                                                            title="Удалить перерыв"
                                                                            type="text"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            )).concat(<Row className="vpadded vcentered">
                                                                <Col>
                                                                    <label className="hpadded">
                                                                        с
                                                                    </label>
                                                                </Col>
                                                                <Col>
                                                                    <BruhTimePicker
                                                                        closeOnSelect={true}
                                                                        format="HH:mm"
                                                                        onChange={(val, _) => {
                                                                            state.tempBreaks.days[index].start_time = val;
                                                                        }}
                                                                        value={moment(state.tempBreaks.days[index].start_time)}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <label className="hpadded">
                                                                        до
                                                                    </label>
                                                                </Col>
                                                                <Col>
                                                                    <BruhTimePicker
                                                                        closeOnSelect={true}
                                                                        format="HH:mm"
                                                                        onChange={(val, _) => {
                                                                            state.tempBreaks.days[index].end_time = val;
                                                                        }}
                                                                        value={moment(state.tempBreaks.days[index].end_time)}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Button
                                                                        disabled={state.tempBreaks.days[index].start_time == null || state.tempBreaks.days[index].end_time == null}
                                                                        className="hmargined green-btn"
                                                                        icon={<FontAwesomeIcon icon={faPlus} />}
                                                                        onClick={() => {
                                                                            tryAddBreak(null, [moment(state.tempBreaks.days[index].start_time), moment(state.tempBreaks.days[index].end_time)])
                                                                        }}
                                                                        title="Добавить перерыв"
                                                                    />
                                                                </Col>
                                                            </Row>)
                                                        }
                                                    </Col>)
                                                    : (<Col>
                                                        <i>Без перерывов</i>
                                                        <Row className="vpadded vcentered">
                                                            <Col>
                                                                <label className="hpadded">
                                                                    с
                                                                </label>
                                                            </Col>
                                                            <Col>
                                                                <BruhTimePicker
                                                                    closeOnSelect={true}
                                                                    format="HH:mm"
                                                                    onChange={(val, _) => {
                                                                        state.tempBreaks.days[index].start_time = val;
                                                                    }}
                                                                    value={moment(state.tempBreaks.days[index].start_time)}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <label className="hpadded">
                                                                    до
                                                                </label>
                                                            </Col>
                                                            <Col>
                                                                <BruhTimePicker
                                                                    closeOnSelect={true}
                                                                    format="HH:mm"
                                                                    onChange={(val, _) => {
                                                                        state.tempBreaks.days[index].end_time = val;
                                                                    }}
                                                                    value={moment(state.tempBreaks.days[index].end_time)}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    disabled={state.tempBreaks.days[index].start_time == null || state.tempBreaks.days[index].end_time == null}
                                                                    className="hmargined green-btn"
                                                                    icon={<FontAwesomeIcon icon={faPlus} />}
                                                                    onClick={() => {
                                                                        tryAddBreak(null, [moment(state.tempBreaks.days[index].start_time), moment(state.tempBreaks.days[index].end_time)])
                                                                    }}
                                                                    title="Добавить перерыв"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>)
                                                }
                                            </Row>
                                        </>)}
                                    </Col>
                                </List.Item>
                            )}
                        />
                    </Panel>}
                    <Panel
                        header="Изменения в графике:"
                        key="exceptions"
                        extra={
                            <Button
                                className="green-btn btn-icon-separated"
                                icon={<FontAwesomeIcon icon={faPlus} />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    state.exceptionEditMode = false;
                                    state.curException = baseScheduleException([state.curUser]);
                                    state.exceptionEnd = moment()
                                        .hours(8)
                                        .minutes(0)
                                        .add(2, 'days');
                                    state.exceptionModal = true;
                                }}
                                title="Добавить изменение"
                                type="default"
                            >
                                Добавить
                            </Button>
                        }
                    >
                        <Row className="table-row hcentered">
                            {state.exceptions.length > 0
                                ? <Table
                                    bordered={true}
                                    columns={[
                                        {
                                            title: 'Тип',
                                            dataIndex: 'exception_type',
                                            width: "70px",
                                            key: 'exception_type',
                                            render: (val) => val.name
                                        },
                                        {
                                            title: 'Прод.',
                                            dataIndex: 'duration',
                                            key: 'duration',
                                            width: "80px",
                                            render: (val) => Common.convertMinutes2Date(val)
                                        },
                                        {
                                            title: 'Начало //\nЗавершение',
                                            dataIndex: 'start_date',
                                            key: 'start_at',
                                            render: (_, record) => (
                                                <Col style={{ minWidth: "120px" }}>
                                                    <Row>
                                                        {`${record.start_time} / ${moment(record.start_date, "DD-MM-YYYY").format("DD.MM.YY")} //`}
                                                    </Row>
                                                    <Row>
                                                        {`${moment(record.start_date, "DD-MM-YYYY").set("hours", Number(record.start_time.split(":")[0]))
                                                            .set("minutes", Number(record.start_time.split(":")[1])).add(record.duration, "minutes").format("HH:mm / DD.MM.YY")}`}
                                                    </Row>
                                                </Col>)
                                        },
                                        {
                                            title: 'Статус',
                                            dataIndex: 'status',
                                            width: "75px",
                                            key: 'status'
                                        }
                                    ]}
                                    className="exception-table"
                                    dataSource={state.exceptions}
                                    rowClassName={(record) => {
                                        switch (record.status) {
                                            case 'Завершено':
                                                return 'exception-finished';
                                            case 'Активно':
                                                return 'exception-active';
                                            default:
                                                return 'exception-regular';
                                        }
                                    }
                                    }
                                    onRow={(record, _) => {
                                        return {
                                            onClick: (_) => {
                                                if (record.status == "Завершено") return;
                                                state.exceptionEditMode = true;
                                                state.curException = record;
                                                const tParts = record.start_time.split(":").map(v => Number(v));
                                                state.exceptionEnd = moment(toDate(record.start_date)).hours(tParts[0]).minutes(tParts[1]).add(record.duration, "minutes");
                                                state.exceptionModal = true;
                                            }
                                        };
                                    }}
                                    pagination={{
                                        pageSize: 5
                                    }}
                                    rowKey="id"
                                />
                                : <p className="vpadded">Вы ещё не добавили изменений :)</p>}
                        </Row>
                    </Panel>
                </Collapse>
            </div>
        </div>
    );
});

export { WorkerSchedule };
