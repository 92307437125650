import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TakeToWork = ({ onClick }) => {
    return (
        <div className="unsupport-screen-btn" onClick={onClick}>
            <p className="unsupport-screen-btn-text">Взять в работу</p>
            <p className="unsupport-screen-btn-text">
                Файл будет доступен только Вам, пока не обновите его
            </p>

            <div className="unsupport-screen-btn-icon">
                <FontAwesomeIcon icon={faLock} color="orange" size="lg" />
            </div>
        </div>
    );
};
