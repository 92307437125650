import { MESSENGERS_BASE_URL } from '../constants';

export const shareOnWhatsApp = (message) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const url =
        (isMobile ? MESSENGERS_BASE_URL.WHATSAPP_MOBILE : MESSENGERS_BASE_URL.WHATSAPP_WEB) +
        encodeURIComponent(message);

    window.open(url, '_blank');
};
