export const WordIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96">
        <path
            fill="#fff"
            d="M27 88h58a1 1 0 0 0 1-1V28H69c-1.654 0-3-1.346-3-3V8H27a1 1 0 0 0-1 1v78a1 1 0 0 0 1 1z"
        />
        <path fill="#fff" d="M85.586 26 68 8.414V25a1 1 0 0 0 1 1h16.586z" />
        <path
            fill="#605E5C"
            fill-rule="evenodd"
            d="M87.121 24.707 69.293 6.879A2.978 2.978 0 0 0 67.172 6H27c-1.654 0-3 1.346-3 3v78c0 1.654 1.346 3 3 3h58c1.654 0 3-1.346 3-3V26.828c0-.8-.313-1.554-.879-2.121zM68 8.414 85.586 26H69a1 1 0 0 1-1-1V8.414zM27 88h58a1 1 0 0 0 1-1V28H69c-1.654 0-3-1.346-3-3V8H27a1 1 0 0 0-1 1v78a1 1 0 0 0 1 1z"
            clip-rule="evenodd"
            opacity=".64"
        />
        <path fill="#103F91" d="M79 62H51v2h28a1 1 0 1 0 0-2z" />
        <path fill="#185ABD" d="M79 56H51v2h28a1 1 0 1 0 0-2z" />
        <path fill="#2B7CD3" d="M51 52h28a1 1 0 1 0 0-2H51v2z" />
        <path fill="#41A5EE" d="M79 44H51v2h28a1 1 0 1 0 0-2z" />
        <path
            fill="#185ABD"
            d="M12 74h32a4 4 0 0 0 4-4V38a4 4 0 0 0-4-4H12a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4z"
        />
        <path
            fill="#F9F7F7"
            d="M21.625 60.645c.066.522.109.977.13 1.366h.075a23.574 23.574 0 0 1 .427-2.654L25.791 44h4.57l3.656 15.127c.183.747.336 1.697.457 2.853h.061c.051-.797.178-1.718.381-2.761L37.841 44H42l-5.118 22h-4.86l-3.489-14.574c-.101-.42-.216-.967-.343-1.642a19.158 19.158 0 0 1-.236-1.472h-.06c-.041.358-.12.89-.237 1.595a40.66 40.66 0 0 1-.282 1.565L24.1 66h-4.936L14 44h4.235l3.184 15.388c.071.317.14.736.206 1.258v-.001z"
        />
    </svg>
);
