import React, { useState } from 'react';

import { Modal } from 'antd';

import RenderIf from '@common/RenderIf';

import { Context } from './common/context';
import { FilesPreviewProps } from './common/types';
import { FilesCarousel } from './partials';

import './filesViewer.scss';

export const FilesViewer: React.FC<FilesPreviewProps> = ({
    open,
    onClose,
    data,
    openFileId,
    funcMenuData,
    sender,
    topic,
}) => {
    const [ref, setRef] = useState(null);

    return (
        <Context.Provider value={{ ref, setRef, toggleVisibility: onClose }}>
            <RenderIf condition={open}>
                <Modal
                    open={open}
                    onCancel={onClose}
                    footer={null}
                    closable={false}
                    zIndex={900}
                    wrapClassName={'modal-wrap-custom'}
                >
                    {sender}
                    {topic}
                    <FilesCarousel
                        data={data}
                        openFileId={openFileId}
                        funcMenuData={funcMenuData}
                    />
                </Modal>
            </RenderIf>
        </Context.Provider>
    );
};
