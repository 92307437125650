import { TCustomer } from 'types/customer';
import { CRMAPIBase } from '../crmApiBase';
import { RequestResult } from '../responseModels/requestResult';
import { CustomerProfileListResp } from '../responseModels/customer/customerProfileListResponse';
import { CustomerProfileResp } from '../responseModels/customer/customerProfileResponse';

class CustomerProfileGroup extends CRMAPIBase {
    /**
     * Добавление профиля клиента
     * @param crmId ID CRM
     * @param customerProfile Профиль клиента
     * @returns Результат запроса
     */
    async saveCustomerProfile(
        crmId: number,
        customerProfile: TCustomer
    ): Promise<RequestResult<CustomerProfileResp>> {
        const result = await this.post<CustomerProfileResp>('/customer-profiles', {
            crm_id: crmId,
            ...customerProfile,
        });
        return result;
    }

    /**
     * Получение профиля клиента
     * @param id ID клиента
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getCustomerProfile(
        id: number,
        crmId: number
    ): Promise<RequestResult<CustomerProfileResp>> {
        const result = await this.get<CustomerProfileResp>(`/customer-profiles/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Обновление профиля клиента
     * @param crmId ID CRM
     * @param customerProfile Профиль клиента
     * @returns Результат запроса
     */
    async updateCustomerProfile(
        crmId: number,
        customerProfile: TCustomer
    ): Promise<RequestResult<CustomerProfileResp>> {
        const result = await this.put<CustomerProfileResp>(
            `/customer-profiles/${customerProfile.id}`,
            { crm_id: crmId, ...customerProfile }
        );
        return result;
    }

    /**
     * Удаление профиля клиента
     * @param id ID профиля клиента
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeCustomerProfile(
        id: number,
        crmId: number
    ): Promise<RequestResult<CustomerProfileResp>> {
        const result = await this.delete<CustomerProfileResp>(`/customer-profiles/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Восстановление профиля клиента
     * @param id ID профиля клиента
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreCustomerProfile(
        id: number,
        crmId: number
    ): Promise<RequestResult<CustomerProfileResp>> {
        const result = await this.post<CustomerProfileResp>(`/customer-profiles/${id}/restore`, {
            crm_id: crmId,
        });
        return result;
    }
}

export { CustomerProfileGroup };
