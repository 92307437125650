export const PdfIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96">
        <path
            fill="#fff"
            d="M19 88h58a1 1 0 0 0 1-1V28H61c-1.654 0-3-1.346-3-3V8H19a1 1 0 0 0-1 1v78a1 1 0 0 0 1 1z"
        />
        <path fill="#fff" d="M77.586 26 60 8.414V25a1 1 0 0 0 1 1h16.586z" />
        <path
            fill="#605E5C"
            fill-rule="evenodd"
            d="M79.121 24.707 61.293 6.879A2.978 2.978 0 0 0 59.172 6H19c-1.654 0-3 1.346-3 3v78c0 1.654 1.346 3 3 3h58c1.654 0 3-1.346 3-3V26.828c0-.8-.313-1.554-.879-2.121zM60 8.414 77.586 26H61a1 1 0 0 1-1-1V8.414zM19 88h58a1 1 0 0 0 1-1V28H61c-1.654 0-3-1.346-3-3V8H19a1 1 0 0 0-1 1v78a1 1 0 0 0 1 1z"
            clip-rule="evenodd"
            opacity=".64"
        />
        <path
            fill="#C8C6C4"
            fill-rule="evenodd"
            d="M25 37h46a1 1 0 1 0 0-2H25a1 1 0 1 0 0 2zm0 6h46a1 1 0 1 0 0-2H25a1 1 0 1 0 0 2zm46 6H25a1 1 0 1 1 0-2h46a1 1 0 1 1 0 2zm-46 6h46a1 1 0 1 0 0-2H25a1 1 0 1 0 0 2z"
            clip-rule="evenodd"
        />
        <path
            fill="#D65532"
            fill-rule="evenodd"
            d="M30.706 79.705a1 1 0 0 0 .294-.708V64.038a1 1 0 0 0-.997-1L16.01 63A2.003 2.003 0 0 0 14 64.994v12.959c-.003 1.105.856 2.003 1.963 2.006l14.034.038a1 1 0 0 0 .71-.292zm49.3-16.664-13.959-.038a1 1 0 0 0-1.004.997l-.04 14.959A1 1 0 0 0 66 79.962L79.96 80a2 2 0 0 0 2.004-1.994L82 65.047a2 2 0 0 0-1.994-2.006z"
            clip-rule="evenodd"
        />
        <path
            stroke="#D65532"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M58.5 80.5h-21a2 2 0 0 1-2-2v-14a2 2 0 0 1 2-2h21a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2z"
            clip-rule="evenodd"
        />
    </svg>
);
