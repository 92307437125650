import { TOrderFieldQuery } from 'types/orderObjects/orderObject';

import { OrderObjectResp } from '@api/responseModels/orderObjects/orderObjectResponse';
import { OrderObjectRestoreResp } from '@api/responseModels/orderObjects/orderObjectRestoreResponse';
import { OrderObjectsCreateResp } from '@api/responseModels/orderObjects/orderObjectsCreateResponse';
import { OrderObjectsResp } from '@api/responseModels/orderObjects/orderObjectsResponse';
import { RequestResult } from '@api/responseModels/requestResult';

import { CRMAPIBase } from '../crmApiBase';

class OrderObjectsGroup extends CRMAPIBase {
    async createOrderObject(
        crm_id: number,
        name: string,
        customer_profile_id: string | number
    ): Promise<RequestResult<OrderObjectsCreateResp>> {
        const result = await this.post<OrderObjectsCreateResp>(`order-objects`, {
            crm_id,
            name,
            customer_profile_id,
        });
        return result;
    }

    async getOrderObject(order_object_id: number, crm_id): Promise<RequestResult<OrderObjectResp>> {
        const result = await this.get<OrderObjectResp>(`order-objects/${order_object_id}`, {
            crm_id,
        });
        return result;
    }

    async updateOrderObject(
        crm_id: number,
        name: string,
        customer_profile_id: string | number,
        order_object_id: number
    ): Promise<RequestResult<OrderObjectResp>> {
        const result = await this.put<OrderObjectResp>(`order-objects/${order_object_id}`, {
            crm_id,
            name,
            customer_profile_id,
        });
        return result;
    }

    async getOrderObjects(
        crm_id: number,
        customer_profile_id: number
    ): Promise<RequestResult<OrderObjectsResp>> {
        const result = await this.get<OrderObjectsResp>(`order-objects`, {
            crm_id,
            customer_profile_id,
        });
        return result;
    }

    async deleteOrderObject(
        order_object_id: number,
        crm_id: number
    ): Promise<RequestResult<OrderObjectsResp>> {
        const result = await this.delete<OrderObjectsResp>(`order-objects/${order_object_id}`, {
            crm_id,
        });
        return result;
    }

    async restoreOrderObject(
        order_object_id: number
    ): Promise<RequestResult<OrderObjectRestoreResp>> {
        const result = await this.post<OrderObjectRestoreResp>(
            `order-objects/${order_object_id}/restore`
        );
        return result;
    }

    async syncOrderFieldsOrderObject(
        crm_id: number,
        order_object_id: number,
        order_fields: TOrderFieldQuery[]
    ): Promise<RequestResult<OrderObjectResp>> {
        const result = await this.post<OrderObjectResp>(
            `order-objects/${order_object_id}/sync-fields`,
            {
                crm_id,
                order_fields,
            }
        );
        return result;
    }
}

export { OrderObjectsGroup };
