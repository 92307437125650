import {
    faCancel,
    faCopy,
    faDownload,
    faEdit,
    faReply,
    faSave,
    faShare,
    faShareNodes,
    faThumbsUp,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FORMATS, authorName, authorPosition } from './constants';
import { ExcelIcon } from './svg/ExcelIcon';
import { PdfIcon } from './svg/PdfIcon';
import { WordIcon } from './svg/WordIcon';
import { Buttons, GetCommentsInitialItemsProps, UpdatedComment } from './types';

export const comments: UpdatedComment[] = [
    {
        id: 1,
        author: {
            avatarUrl:
                'https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
            name: authorName,
            position: authorPosition,
        },
        repliedTo: [],
        date: 'Вчера',
        time: '10:31',
        body: {
            text: 'замена шаровой опоры Лексус RX 300 замена шаровой опоры Лексус RX 300 замена шаровой опоры Лексус RX 300 замена шаровой опоры Лексус RX 300',
            files: [],
        },
        isSelected: false,
        topic: null,
        repliedComment: null,
    },
];

const stub = () => {};

export const underTextButtons: Buttons[] = [
    {
        id: 1,
        icon: faCopy,
        onClick: stub,
    },
    {
        id: 2,
        icon: faEdit,
        onClick: stub,
    },
    {
        id: 3,
        icon: faShareNodes,
        onClick: stub,
    },
    {
        id: 4,
        icon: faReply,
        onClick: stub,
    },
    {
        id: 5,
        icon: faThumbsUp,
        onClick: stub,
    },
];

export const underImageButtons: Buttons[] = [
    {
        id: 1,
        icon: faTrash,
        onClick: stub,
    },
    {
        id: 2,
        icon: faDownload,
        onClick: stub,
    },
    {
        id: 3,
        icon: faCopy,
        onClick: stub,
    },
    {
        id: 4,
        icon: faShareNodes,
        onClick: stub,
    },
    {
        id: 5,
        icon: faReply,
        onClick: stub,
    },
];

export const getInitialMenuItemsForComments = ({
    handleDeselectAllItems,
    handleDeleteSelectedItems,
    handleCopyComment,
    toggleEmployeesModal,
    toggleContentShareModal,
    toggleEditMode,
    handleSaveEditedComment,
    handleCancelEditedComment,
    handleToggleReplyMode,
}: GetCommentsInitialItemsProps) => {
    return [
        {
            key: 'download',
            label: 'Скачать',
            icon: <FontAwesomeIcon icon={faDownload} />,
        },
        {
            key: 'reply',
            label: 'Ответить',
            icon: <FontAwesomeIcon icon={faReply} />,
            onClick: handleToggleReplyMode,
        },
        {
            key: 'copy',
            label: 'Копировать',
            icon: <FontAwesomeIcon icon={faCopy} />,
            onClick: handleCopyComment,
        },
        {
            key: 'forward',
            label: 'Переслать',
            icon: <FontAwesomeIcon icon={faShare} />,
            onClick: toggleEmployeesModal,
        },
        {
            key: 'share',
            label: 'Поделиться',
            icon: <FontAwesomeIcon icon={faShareNodes} />,
            onClick: toggleContentShareModal,
        },
        {
            key: 'edit',
            label: 'Изменить',
            icon: <FontAwesomeIcon icon={faEdit} />,
            onClick: toggleEditMode,
        },
        {
            key: 'delete',
            label: 'Удалить',
            icon: <FontAwesomeIcon icon={faTrash} />,
            onClick: handleDeleteSelectedItems,
        },
        {
            key: 'cancel-comment',
            label: 'Отмена',
            icon: <FontAwesomeIcon icon={faCancel} />,
            onClick: handleDeselectAllItems,
        },
        {
            key: 'save',
            label: 'Сохранить',
            icon: <FontAwesomeIcon icon={faSave} />,
            onClick: handleSaveEditedComment,
        },
        {
            key: 'cancel-edit',
            label: 'Отмена',
            icon: <FontAwesomeIcon icon={faCancel} />,
            onClick: handleCancelEditedComment,
        },
    ];
};

export const FORMAT_ICONS = {
    [FORMATS.PDF]: <PdfIcon />,
    [FORMATS.WORD]: <WordIcon />,
    [FORMATS.EXCEL]: <ExcelIcon />,
    [FORMATS.UNSUPPORTED]: null,
};
