import { memo, useEffect, useRef } from 'react';

import { useReactive, useToggle, useUpdateEffect } from 'ahooks';
import { Button, Col, Row } from 'antd';

import RenderIf from '@common/RenderIf';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getFileFormatIcon } from '../../helpers/getFileFormatIcon';
import { PauseIcon } from '../../svg/PauseIcon';
import { PlayIcon } from '../../svg/PlayIcon';
import { FileType } from '../../types';
import { UnsupportThumbnail } from '../unsupportThumbnail/unsupportThumbnail';
import { formatDuration } from './common/helpers/formatDuration';
import { getAudioDuration } from './common/helpers/getAudioDuration';

import './fileThumbnail.scss';

interface Props {
    removeFile?: (id: number) => void;
    selectFile?: (id: number) => void;
    index: number;
    files: FileType[];
    file: FileType;
    handleClick?: () => void;
    avoidPlayingAudio?: boolean;
    selected?: boolean;
    isSmall?: boolean;
}

export const FileThumbnail: React.FC<Props> = memo(
    ({
        removeFile,
        selectFile,
        file,
        handleClick = () => {},
        avoidPlayingAudio,
        selected,
        isSmall,
    }) => {
        const state = useReactive({
            duration: null,
            isPlayFirstTime: true,
            interval: null,
            currentTime: 0,
        });

        const { blob } = file;
        const extension = file.name.split('.').at(-1);
        const isAudio = file.extension === 'audio';
        const isImage = blob.type.startsWith('image/');
        const isOtherFile = !isAudio && !isImage;
        const audioRef = useRef(null);
        const audioElement = audioRef.current;
        const [isPlaying, { toggle: toggleIsPlaying }] = useToggle(false);

        const playOrPause = () => {
            toggleIsPlaying();
            setAudioDuration();
            if (audioElement.paused) {
                audioElement.play();
                return;
            }
            audioElement.pause();
        };

        const setAudioDuration = () => {
            if (state.interval) {
                clearInterval(state.interval);
                state.interval = null;
                return;
            }
            if (state.isPlayFirstTime) state.duration = 0;
            state.isPlayFirstTime = false;

            state.interval = setInterval(() => {
                state.duration += 0.05;
            }, 50);
        };

        const handleAudioEnded = () => {
            toggleIsPlaying();
            clearInterval(state.interval);
            state.interval = null;
            state.isPlayFirstTime = true;
        };

        useEffect(() => {
            if (!isAudio) return;
            async function fetchAudioDurations() {
                state.duration = await getAudioDuration(blob);
            }
            fetchAudioDurations();
        }, [JSON.stringify(blob)]);

        useUpdateEffect(() => {
            if (!isAudio) return;
            audioElement.src = URL.createObjectURL(blob);
        }, [audioElement, JSON.stringify(blob)]);

        const TopButton: React.FC = () => (
            <>
                <RenderIf condition={removeFile}>
                    <Button
                        className="item-delete-btn"
                        danger
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        onClick={() => removeFile(file.id)}
                        size="small"
                        shape="circle"
                        type="primary"
                    />
                </RenderIf>
                <RenderIf condition={selectFile}>
                    <Button
                        className={`item-select-btn`}
                        icon={<FontAwesomeIcon icon={faCheck} />}
                        onClick={() => selectFile(file.id)}
                        size="small"
                        shape="circle"
                        type={selected ? 'primary' : 'default'}
                    />
                </RenderIf>
            </>
        );

        return (
            <Row
                className="file-thumbnail-container relative-container"
                style={{ marginTop: '15px' }}
                justify={'space-between'}
            >
                <Col>
                    <RenderIf condition={isAudio}>
                        <div
                            onClick={handleClick}
                            className={`file-wrapper ${isSmall ? 'file-wrapper-small' : ''}`}
                        >
                            <div
                                onClick={!avoidPlayingAudio ? playOrPause : () => {}}
                                className="audio-preview"
                            >
                                {isPlaying ? <PauseIcon /> : <PlayIcon />}
                                {formatDuration(state.duration)}
                            </div>
                            <span className="file-name">{file.name}</span>
                        </div>
                        <TopButton />
                    </RenderIf>

                    <RenderIf condition={isImage}>
                        <div
                            onClick={handleClick}
                            className={`file-wrapper ${isSmall ? 'file-wrapper-small' : ''}`}
                        >
                            <img src={URL.createObjectURL(blob)} alt="Preview" />
                            <span className="file-name">{file.name}</span>
                        </div>
                        <TopButton />
                    </RenderIf>

                    <RenderIf condition={isOtherFile}>
                        <div
                            onClick={handleClick}
                            className={`file-wrapper ${isSmall ? 'file-wrapper-small' : ''}`}
                        >
                            <div className={`other-file ${isSmall ? 'other-file-small' : ''}`}>
                                {getFileFormatIcon(extension) || (
                                    <UnsupportThumbnail extension={file.extension} />
                                )}
                            </div>
                            <span className="file-name">{file.name}</span>
                        </div>
                        <TopButton />
                    </RenderIf>
                </Col>
                <audio ref={audioRef} onEnded={handleAudioEnded} style={{ display: 'none' }} />
            </Row>
        );
    }
);
