import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useReactive, useToggle, useUpdateEffect } from 'ahooks';
import { Col, Row } from 'antd';
import { isEmpty, map } from 'lodash';
import { TUser } from 'types/user/user';

import RenderIf from '@common/RenderIf';
import { ShowWithTransition } from '@components/common/showWithTransition';
import { rootStore } from '@store/rootStore/instanse';

import { CommentTextArea, ReplyModePanel } from '../../common/components';
import { FileThumbnail } from '../../common/components/fileThumbnail';
import { authorPosition } from '../../common/constants';
import { addComment, toggleReply } from '../../common/redux/commentSlice';
import { getComments } from '../../common/redux/selectors';
import { FileType, Topic, UpdatedComment } from '../../common/types';
import { CommentAttributes } from './blocks/commentAttributes';
import { CommentBottomPanel } from './blocks/commentBottomPanel';

export const CommentCreating = ({ visible }) => {
    const dispatch = useDispatch();
    const { isReplyMode, replyCommentData } = useSelector(getComments);

    const state = useReactive({
        commentValue: '',
        repliedComment: null,
    });

    const [files, setFiles] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [topic, setTopic] = useState(null);

    const author = rootStore.currentUserStore.getUser;
    const isCommentReadyToSend = !isEmpty(state.commentValue) || !isEmpty(files);

    const [isCommentTextAreaPanelVisible, { toggle: toggleCommentTextAreaPanelVisible }] =
        useToggle(false);

    const sendComment = () => {
        if (!isCommentReadyToSend) return;
        const payload: UpdatedComment = {
            id: Math.floor(10000 + Math.random() * 90000),
            author: {
                ...author,
                position: authorPosition,
            },
            repliedTo: recipients,
            date: 'Сегодня',
            time: '10:31',
            body: {
                files,
                text: state.commentValue,
            },
            repliedComment: replyCommentData.comment,
            isSelected: false,
            topic,
        };
        dispatch(addComment(payload));

        state.commentValue = '';
        setFiles([]);
        setRecipients([]);
        setTopic(null);
        dispatch(toggleReply(false));

        if (isCommentTextAreaPanelVisible) toggleCommentTextAreaPanelVisible();
    };

    const handleSelectRecipients = (recipients: TUser[]) => {
        setRecipients(recipients);
    };

    const selectTopic = (topic: Topic) => {
        setTopic(topic);
    };

    const removeFile = (id: number) => {
        const restFiles = files.filter((f) => f.id != id);
        setFiles(restFiles);
    };

    const onCommentValueChange = (value: string) => {
        state.commentValue = value;
    };

    const setFile = (file: FileType) => {
        setFiles((prev) => {
            return [...prev, file];
        });
    };

    useUpdateEffect(() => {
        if (!isCommentTextAreaPanelVisible && isReplyMode) toggleCommentTextAreaPanelVisible();

        if (!isReplyMode) {
            setRecipients([]);
            state.repliedComment = null;
            setTopic(null);
            return;
        }

        setRecipients([replyCommentData.recipient]);
        setTopic(replyCommentData.topic);
        state.repliedComment = replyCommentData.comment;
    }, [isReplyMode]);

    return (
        visible && (
            <>
                <ShowWithTransition visible={isCommentReadyToSend || isCommentTextAreaPanelVisible}>
                    <RenderIf condition={isReplyMode}>
                        <ReplyModePanel />
                    </RenderIf>
                    <CommentAttributes
                        recipients={recipients}
                        topic={topic}
                        selectRecipients={handleSelectRecipients}
                        selectTopic={selectTopic}
                    />
                    <Row>
                        {map(files, (file, index) => (
                            <Col span={8}>
                                <FileThumbnail
                                    files={files}
                                    key={file.id}
                                    removeFile={removeFile}
                                    index={index}
                                    file={file}
                                />
                            </Col>
                        ))}
                    </Row>
                </ShowWithTransition>

                <ShowWithTransition visible={isCommentTextAreaPanelVisible}>
                    <CommentTextArea
                        commentValue={state.commentValue}
                        onCommentValueChange={onCommentValueChange}
                        toggleTextArea={toggleCommentTextAreaPanelVisible}
                        visible={isCommentTextAreaPanelVisible}
                    />
                    <div style={{ margin: '0 auto' }}></div>
                </ShowWithTransition>

                <CommentBottomPanel
                    isCommentReadyToSend={isCommentReadyToSend}
                    sendComment={sendComment}
                    toggleTextArea={toggleCommentTextAreaPanelVisible}
                    setFile={setFile}
                    files={files}
                />
            </>
        )
    );
};
