export const TAB_ROUTES = {
    AVAILABLE: 'available',
    DELETED: 'deleted'
};

export const TAB_LIST = [
    {
        label: 'Задачи',
        key: TAB_ROUTES.AVAILABLE,
    },
    {
        label: 'Корзина',
        key: TAB_ROUTES.DELETED,
    },
];

export const STATUS_LIST = [
    { label: 'Задачи', value: 'Задачи' }, 
    { label: 'Удалена', value: 'Удалена' }
];