import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Download = ({ onClick }) => {
    return (
        <div className="unsupport-screen-btn" onClick={onClick}>
            <p className="unsupport-screen-btn-text">Скачать</p>
            <p className="unsupport-screen-btn-text">Для просмотра</p>

            <div className="unsupport-screen-btn-icon">
                <FontAwesomeIcon icon={faDownload} color="green" size="lg" />
            </div>
        </div>
    );
};
