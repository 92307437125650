import { styles } from '@components/orders/pdfFile/common/styles';
import { Text, View } from '@react-pdf/renderer';

export const Footer = ({ data }) => {
    return (
        <>
            <Text style={{ width: 'auto', marginTop: '1cm' }}>
                Гарантия на выполненные работы и проданные товары 15 дней
            </Text>

            <View
                style={[
                    styles.mt_5,
                    {
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    },
                ]}
            >
                <View>
                    <Text style={{ textDecoration: 'underline' }}>
                        Исполнитель: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Text>
                    <Text style={[styles.mt_5, { paddingLeft: '3cm' }]}>М.П.</Text>
                </View>
                <View>
                    <Text style={{ textDecoration: 'underline' }}>
                        Заказчик: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Text>
                    <Text style={[styles.mt_5, { paddingLeft: '3cm' }]}>М.П.</Text>
                </View>
            </View>
        </>
    );
};
