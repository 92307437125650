import { BruhTimePicker } from "@atoms/bruhTimePicker";
import { Common } from "@classes/commonMethods";
import { workers } from "@components/workers/schedule/common/data";
import { Modal, Popconfirm, Button, Space, Row, Col, Select, DatePicker, Input, Dropdown } from "antd";
import type { MenuProps } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import moment, { Moment } from "moment";
import { store as ServiceStore } from "@organisms/service/serviceStore";
import _ from "lodash";
import { useReactive } from "ahooks";
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { TimePeriod } from "@components/workers/schedule/schedule";
import { exceptionTypes } from "../../common/constants";
import { TExceptionType, TScheduleException } from "../../common/types";
import { MessageInstance } from "antd/lib/message";

type TProps = {
    exceptionEditMode: boolean;
    exceptionModal: boolean;
    curException: TScheduleException;
    exceptionEnd: Moment;
    curUser: { id: number; name: string; surname: string; specialty: string; };
    availablePeriods: TimePeriod[];
    onClose: () => void;
    onDelete: () => void;
    onSave: () => void;
    setCurException: (exc: TScheduleException) => TScheduleException;
    setExceptionEnd: (value: Moment) => void;
    setDurationPickerVisible: (val: boolean) => void;
    messageApi: MessageInstance;
};

const ScheduleExceptionModal = ({
    exceptionEditMode, 
    exceptionModal, 
    curException, 
    exceptionEnd, 
    curUser, 
    availablePeriods, 
    onClose, 
    onDelete, 
    onSave, 
    setCurException, 
    setExceptionEnd, 
    setDurationPickerVisible,
    messageApi
}: TProps): JSX.Element => {
    const state = useReactive({
        exceptionChanged: false,
        reasonOpen: false,
        periodOpen: false,
        startTimeError: false,
        startDateError: false,
        durationError: false,
        endTimeError: false,
        endDateError: false
    });
    const reasonRef = useRef(null);

    useEffect(() => {
        if (!exceptionModal) {
            state.startDateError = false;
            state.startTimeError = false;
            state.durationError = false;
            state.endDateError = false;
            state.endTimeError = false;
            state.exceptionChanged = false;
        }

        if(exceptionEditMode) return;
        if(exceptionModal) {
            handlePeriodSelection({key:"0"});
            setTimeout(() => {
                reasonRef.current.focus();
                state.reasonOpen = true;
            }, 1000);
        }
        else {
            state.reasonOpen = false;
        }
    }, [exceptionModal]);

    useEffect(() => {
        state.exceptionChanged = true;
    }, [curException.duration]);
    
    // Проверки на соответствие доступным периодам и ограничениям типа
    useEffect(() => {
        if (curException.duration > curException.exception_type.maxDuration || curException.duration < curException.exception_type.minDuration) state.durationError = true;
        else state.durationError = false;
    }, [curException.duration, curException.exception_type, exceptionModal]);

    useEffect(() => {
        if (exceptionEditMode || !exceptionModal) return;
        // Промежуточный список периодов (на случай, если придётся добавить текущий для существующего изменения графика)
        const tAP = filteredPeriods(availablePeriods, curException.exception_type);
        const start = toMoment(curException.start_date, curException.start_time);
        let badStart = true;
        let badEnd = true;
        
        // Смотрим ОК ли начало
        if (start.isBefore(exceptionEnd)) {
            for (let i = 0; i < tAP.length; i++) {
                if (start.isSameOrAfter(tAP[i].from, 'minute') && (tAP[i].to == null || start.isBefore(tAP[i].to, 'minute'))) {
                    badStart = false;
                    if (exceptionEnd.isAfter(tAP[i].from, 'minute') && (tAP[i].to == null || exceptionEnd.isSameOrBefore(tAP[i].to, 'minute'))) {
                        badEnd = false;
                    }
                    break;
                }
            } 
        }
        if (badStart) {
            if (!curException.exception_type.dailyBoundaries) state.startTimeError = true;
            state.startDateError = true;
        }
        else {
            state.startTimeError = false;
            state.startDateError = false;
        }
        if (badEnd) {
            if (!curException.exception_type.dailyBoundaries) state.endTimeError = true;
            state.endDateError = true;
        }
        else {
            state.endTimeError = false;
            state.endDateError = false;
        }

    }, [curException.start_date, curException.start_time, exceptionEnd]);

    function toMoment(date: string, time: string): moment.Moment {
        return moment(`${date} ${time}:00`, "DD-MM-YYYY HH:mm:ss");
    }

    function toDate(dateString: string): Date {
        if (dateString.includes(":")) {
            const parts = dateString.split(":");
            let date = new Date();
            date.setHours(Number(parts[0]));
            date.setMinutes(Number(parts[1]));
            return date;
        }
        else {
            const parts = dateString.split("-");
            return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
        }
    }

    function handleExceptionTypeChange(value: TScheduleException["exception_type"]) {
        let tDuration = curException.duration < value.minDuration || curException.duration > value.maxDuration ? value.minDuration : curException.duration;
        if (value.dailyBoundaries && tDuration % (24 * 60) != 0) tDuration = value.minDuration;
        let tStartDate = curException.start_date;
        let tStartTime = curException.start_time;
        let tStart = toMoment(tStartDate, tStartTime);
        if (value.dailyBoundaries && tStart != tStart.clone().startOf("day")) {
            tStart.add(1, "day").startOf("day");
            tStartDate = tStart.format("DD-MM-YYYY");
            tStartTime = tStart.format("HH:mm");
        }
        setExceptionEnd(tStart.clone().add(tDuration, "minute"));
        setCurException({ 
            ...curException, 
            exception_type: value, 
            duration: tDuration,
            start_date: tStartDate,
            start_time: tStartTime,
        });
    }

    function filteredPeriods(periods: TimePeriod[], exceptionType: TExceptionType): TimePeriod[] {
        return periods.filter(p => { 
            if (exceptionType.dailyBoundaries) {
                const startDay = p.from == p.from.clone().startOf("day") ? p.from.clone() : p.from.clone().add(1, "day").startOf("day");
                if(p.to != null && p.to.diff(startDay, 'minutes') < exceptionType.minDuration) return false;
            }
            if (p.duration != null && p.duration < exceptionType.minDuration) return false;
            return true;
        });
    }

    function makeItems(periods: TimePeriod[], exceptionType: TExceptionType): MenuProps["items"] {
        const items: MenuProps["items"] = [];
        const tPeriods = filteredPeriods(periods, exceptionType);
        for (let i = 0; i < tPeriods.length; i++) {
            items.push({ 
                key: i, 
                label: (tPeriods[i].from.format("HH:mm DD-MM-YYYY"))
                    + (tPeriods[i].duration ? " (" + Common.convertMinutes2Date(tPeriods[i].duration) + ")" : " и позже"),
                disabled: tPeriods[i].duration && tPeriods[i].duration <= curException.duration
            });
        }
        return items;
    }

    function handlePeriodSelection(info) {
        const periods = filteredPeriods(availablePeriods, curException.exception_type);
        if (periods.length == 0) { //вряд ли, но на всякий случай надо
            messageApi.error("Нет подходящих периодов.");
            return;
        }

        const period = periods[Number(info.key)];
        if (curException.exception_type.dailyBoundaries) {
            const startDay = period.from == period.from.clone().startOf("day") ? period.from.clone() : period.from.clone().add(1, "day").startOf("day");
            setCurException({ 
                ...curException, 
                start_date: startDay.format("DD-MM-YYYY"),
                start_time: startDay.format("HH:mm"),
                duration: curException.exception_type.minDuration
            });
            setExceptionEnd(startDay.clone().add(curException.exception_type.minDuration, "minutes"));
        }
        else {
            setCurException({ 
                ...curException, 
                start_date: period.from.clone().format("DD-MM-YYYY"),
                start_time: period.from.clone().format("HH:mm"),
                duration: curException.exception_type.minDuration
            });
            setExceptionEnd(period.from.clone().add(curException.exception_type.minDuration, "minutes"));
        }
        
        state.periodOpen = false;
    }

    return (
        <Modal
            className="schedule-exception-modal"
            title={`Изменение в графике ${exceptionEditMode ? "- редактирование" : "- добавление"}`}
            open={exceptionModal}
            onCancel={onClose}
            footer={
                exceptionEditMode
                    ? <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Popconfirm
                        title="Исключение будет удалено"
                        onConfirm={onDelete}
                        okText="Подтвердить"
                        cancelText="Отмена"
                        placement="topLeft"
                    >
                        <Button danger disabled={curException.status != "Ожидает"}>
                            Удалить
                        </Button>
                    </Popconfirm>
                    <Space>
                        <Button onClick={onClose}>
                            Отмена
                        </Button>
                        <Button 
                            disabled={curException.status == "Завершено" || !state.exceptionChanged || state.durationError || state.endDateError || state.endTimeError || state.startDateError || state.startTimeError}
                            onClick={onSave} 
                            type="primary"
                        >
                            Сохранить
                        </Button>
                    </Space></div>
                    : <Space>
                        <Button onClick={onClose}>
                            Отмена
                        </Button>
                        <Button 
                            disabled={state.durationError || state.endDateError || state.endTimeError || state.startDateError || state.startTimeError}
                            onClick={onSave} 
                            type="primary"
                        >
                            Сохранить
                        </Button>
                    </Space>
                    
            }
        >
            <div className="exception-profile">
                <Row className="vcentered vpadded">
                    <Col className="hpadded one-third-width">
                        <label>
                            Причина
                        </label>
                    </Col>
                    <Col className="hpadded one-third-width">
                        <Select
                            disabled={curException.status != "Ожидает"}
                            onBlur={() => {
                                state.reasonOpen = false;
                            }}
                            onChange={(option) => {
                                handleExceptionTypeChange(exceptionTypes[option]);
                                state.exceptionChanged = true;
                            }}
                            onFocus={() => {
                                state.reasonOpen = true;
                                state.periodOpen = false;
                            }}
                            onSelect={() => {
                                state.reasonOpen = false;
                                reasonRef.current.blur();
                                if (!exceptionEditMode) state.periodOpen = true;
                            }}
                            open={state.reasonOpen}
                            options={Object.values(exceptionTypes).map(et => {return {value: et.name, label: et.name}; })}
                            ref={reasonRef}
                            size="large"
                            style={{width: "100%"}}
                            value={curException.exception_type.name}
                        />
                    </Col>
                    <Col className="hpadded one-third-width">
                        <Dropdown 
                            arrow={true}
                            disabled={availablePeriods.length == 0 || exceptionEditMode} 
                            menu={{ items: makeItems(availablePeriods, curException.exception_type), onClick: handlePeriodSelection }}
                            onOpenChange={(open) => {
                                if (open) state.reasonOpen = false;
                                state.periodOpen = open
                            }}
                            open={state.periodOpen}
                            placement="bottomRight"
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    Период
                                    <FontAwesomeIcon icon={faCaretDown} />
                                </Space>
                            </a>
                        </Dropdown>
                    </Col>
                </Row>
                <Row className="vcentered vpadded">
                    <Col className="hpadded one-third-width">
                        <label>
                            Начало
                        </label>
                    </Col>
                    <Col className="hpadded" style={{maxWidth: "80px"}}>
                        <BruhTimePicker
                            closeOnSelect={true}
                            disabled={curException.status != "Ожидает" || curException.exception_type.dailyBoundaries}
                            format="HH:mm"
                            hasError={state.startTimeError}
                            onChange={(val, _) => {
                                let start = moment(toDate(curException.start_date)).hours(val.hours()).minutes(val.minutes());
                                const newDuration = Math.trunc(moment.duration(exceptionEnd.diff(start)).as("minutes"));
                                ServiceStore.setDuration(newDuration);
                                setCurException({...curException, start_time: val.format("HH:mm"), duration: newDuration});
                                state.exceptionChanged = true;
                            }}
                            value={moment(toDate(curException.start_time))}
                            shouldDisableTime={(val: Moment) => {
                                return moment(toDate(curException.start_date)).hours(val.hours()).minutes(val.minutes()).isSameOrAfter(exceptionEnd);
                            }}
                        />
                    </Col>
                    <Col className="hpadded" style={{maxWidth: "130px"}}>
                        <DatePicker 
                            allowClear={false}
                            disabled={curException.status != "Ожидает"}
                            disabledDate={date => {
                                const tSplit = curException.start_time.split(":").map(v => Number(v));
                                return Math.trunc(moment.duration(exceptionEnd.diff(date.hours(tSplit[0]).minutes(tSplit[1]))).as("minutes")) <= 0;
                            }}
                            format="DD-MM-YYYY"
                            inputReadOnly
                            onChange={(_, dateString) => {
                                const tSplit = curException.start_time.split(":").map(v => Number(v));
                                let start = _.hours(tSplit[0]).minutes(tSplit[1]);
                                const newDuration = Math.trunc(moment.duration(exceptionEnd.diff(start)).as("minutes"));
                                ServiceStore.setDuration(newDuration);
                                setCurException({...curException, start_date: dateString, duration: newDuration})
                                state.exceptionChanged = true;
                            }}
                            picker="date"
                            status={state.startDateError ? "error" : ""}
                            value={moment(toDate(curException.start_date))}
                        />
                    </Col>
                </Row>
                { (state.startTimeError || state.startDateError) && 
                    <Row className="vcentered vpadded">
                        <Col className="hpadded one-third-width">Пояснение</Col>
                        <Col className="hpadded two-thirds-width">
                            <i>Пересекается с другим изменением</i>
                        </Col>
                    </Row>
                }
                <Row className="vcentered vpadded">
                    <Col className="hpadded one-third-width">
                        <label>
                            Продолж-сть
                        </label>
                    </Col>
                    <Col className="hpadded two-thirds-width" onClick={() => {
                        if (curException.status == "Завершено") return;
                        setDurationPickerVisible(true);
                    }}>
                        <Input 
                            disabled={curException.status == "Завершено"} 
                            status={state.durationError ? "error" : ""}
                            value={Common.convertMinutes2Date(curException?.duration)} 
                        />
                    </Col>
                </Row>
                { state.durationError && 
                    <Row className="vcentered vpadded">
                        <Col className="hpadded one-third-width">Пояснение</Col>
                        <Col className="hpadded two-thirds-width">
                            <i>{`${curException.exception_type.name} занимает от ${Common.convertMinutes2Date(curException.exception_type.minDuration)} `
                            + `до ${Common.convertMinutes2Date(curException.exception_type.maxDuration)}`}</i>
                        </Col>
                    </Row>
                }
                <Row className="vcentered vpadded">
                    <Col className="hpadded one-third-width">
                        <label>
                            Завершение
                        </label>
                    </Col>
                    <Col className="hpadded" style={{maxWidth: "80px"}}>
                        <BruhTimePicker
                            closeOnSelect={true}
                            disabled={curException.status == "Завершено" || curException.exception_type.dailyBoundaries}
                            format="HH:mm"
                            hasError={state.endTimeError}
                            onChange={(value, _) => {
                                setExceptionEnd(value);
                                const startSplit = curException.start_time.split(":").map(v => Number(v));
                                const start = moment(toDate(curException.start_date)).hours(startSplit[0]).minutes(startSplit[1]);
                                const newDuration = Math.trunc(moment.duration(value.diff(start)).as("minutes"));
                                ServiceStore.setDuration(newDuration);
                                setCurException({...curException, duration: newDuration});
                                state.exceptionChanged = true;
                            }}
                            shouldDisableTime={(val) => {
                                const startSplit = curException.start_time.split(":").map(v => Number(v));
                                const start = moment(toDate(curException.start_date)).hours(startSplit[0]).minutes(startSplit[1]);
                                return val.isSameOrBefore(start);
                            }}
                            value={exceptionEnd}
                        />
                    </Col>
                    <Col className="hpadded" style={{maxWidth: "130px"}}>
                        <DatePicker 
                            allowClear={false}
                            disabled={curException.status == "Завершено"}
                            disabledDate={date => {
                                const startSplit = curException.start_time.split(":").map(v => Number(v));
                                const start = moment(toDate(curException.start_date)).hours(startSplit[0]).minutes(startSplit[1]);
                                return Math.trunc(moment.duration(date.diff(start)).as("minutes")) <= 0;
                            }}
                            format="DD-MM-YYYY"
                            inputReadOnly
                            onChange={(value, _) => {
                                setExceptionEnd(value);
                                const startSplit = curException.start_time.split(":").map(v => Number(v));
                                const start = moment(toDate(curException.start_date)).hours(startSplit[0]).minutes(startSplit[1]);
                                const newDuration = Math.trunc(moment.duration(value.diff(start)).as("minutes"));
                                ServiceStore.setDuration(newDuration);
                                setCurException({...curException, duration: newDuration});
                                state.exceptionChanged = true;
                            }}
                            picker="date"
                            status={state.endDateError ? "error" : ""}
                            value={exceptionEnd}
                        />
                    </Col>
                </Row>
                { (state.endTimeError || state.endDateError) && 
                    <Row className="vcentered vpadded">
                        <Col className="hpadded one-third-width">Пояснение</Col>
                        <Col className="hpadded two-thirds-width">
                            <i>Пересекается с другим изменением</i>
                        </Col>
                    </Row>
                }
                <Row className="vcentered vpadded">
                    <Col className="hpadded one-third-width">
                        <label>
                            Сотрудники
                        </label>
                    </Col>
                    <Col className="hpadded two-thirds-width">
                        <Select
                            disabled={curException.status == "Завершено"}
                            maxTagCount='responsive'
                            mode="multiple" 
                            onChange={(value)=>{
                                setCurException({...curException, workers: workers.filter(w => value.includes(w.id) || w.id == curUser?.id)});
                                state.exceptionChanged = true;
                            }}
                            options={curUser 
                                ? [curUser].concat(workers).map(w => { return { value: w.id, label: `${w.specialty} | ${w.name} ${w.surname.charAt(0)}.`}})
                                : workers.map(w => { return { value: w.id, label: `${w.specialty} | ${w.name} ${w.surname.charAt(0)}.`}})
                            }
                            placeholder="Выберите сотрудник(-а/-ов)"
                            style={{width: "100%"}}
                            value={_.uniq([curUser?.id].concat(curException.workers.map(w => w.id)))}
                        />
                    </Col>
                </Row>
                <Row className="vcentered vpadded">
                    <Col className="hpadded one-third-width">
                        <label>
                            Комментарий
                        </label>
                    </Col>
                    <Col className="hpadded two-thirds-width">
                        <TextArea 
                            disabled={curException.status == "Завершено"}
                            value={curException.description}
                            maxLength={512}
                            onChange={(e) => {
                                setCurException({ ...curException, description: e.target.value });
                                state.exceptionChanged = true;
                            }}
                            placeholder="Отсутствует"
                            rows={4}
                            showCount
                        />
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export { ScheduleExceptionModal };