import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
    name: 'loadings',
    initialState: {
        data: {},
    },
    reducers: {
        toggleLoading: (state, action: PayloadAction<{ isLoading: boolean; ids: string[] }>) => {
            const { isLoading, ids } = action.payload;
            const override = ids.reduce((res, id) => ({ ...res, [id]: isLoading }), {});

            state.data = {
                ...state.data,
                ...override,
            };
        },
    },
});

export const { toggleLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
