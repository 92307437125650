import { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { viewItems } from '@molecules/listViewChanger/listViewChanger';

const items: MenuProps['items'] = [
    {
        key: 'viewChanger',
        label: <span>Вид</span>,
        children: [...viewItems],
    },
];

const OptionsMenu = () => {
    return (
        <Dropdown menu={{ items: items }}>
            <Button>
                <FontAwesomeIcon className="btn-icon" icon={faEllipsisV} />
            </Button>
        </Dropdown>
    );
};

export { OptionsMenu };
