import { Col, Row } from 'antd';
import React, { memo } from 'react';
import './style.scss';

export const TotalSum: React.FC<{ totalSum: number }> = memo(({ totalSum }) => {

    return (
        <Row className="order-total-sum" justify={'space-between'} align="middle">
            <Col className="text-name" xs={{ span: 7 }} sm={{ span: 5 }}>
                <p>Общий итог</p>
            </Col>
            <Col className="text-sum" xs={{ span: 8 }} sm={{ span: 6 }}>
                <p>{totalSum.toLocaleString('ru-RU')} ₽</p>
            </Col>
        </Row>
    );
});
