import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useCreation, useReactive, useUpdateEffect } from 'ahooks';
import { Button, Col, Input, List, Modal, Row, message } from 'antd';
import { filter, includes, isEmpty, map } from 'lodash';
import { observer } from 'mobx-react';
import { TUser } from 'types/user/user';

import { OrderResp } from '@api/responseModels/order/orderResponse';
import { CRMAPIManager } from '@classes/crmApiManager';
import { SettingsManager } from '@classes/settingsManager';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TOPIC_CATEGORIES, TOPIC_ICONS } from './common/constants';
import { addTopic, resetTopics, setTopics } from './common/redux/commentTopicsPickerSlice';
import { getTopics } from './common/redux/selectors';

import './commentTopicsPicker.scss';

type tProps = {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    currentItems: TUser[] | any;
    setCurrentItems: (selectedEmployees: TUser[] | any) => void;
};

export const CommentTopicsPicker = observer(
    ({ isModalOpen, setIsModalOpen, currentItems, setCurrentItems }: tProps) => {
        const dispatch = useDispatch();
        const { data: topicsData } = useSelector(getTopics);

        const state = useReactive({
            searchValue: '',
        });

        const [selectedTopic, setSelectedTopic] = useState(null);
        const [dataSource, setDataSource] = useState([]);

        const creds = useCreation(() => SettingsManager.getConnectionCredentials(), []);
        const params = useParams();

        const getOrderInfo = async () => {
            try {
                const torder = await CRMAPIManager.request<OrderResp>(async (api) => {
                    return await api.getOrder(+params?.orderID, creds.crmID);
                });
                const modifiedProductList = map(torder?.data?.data?.products, (prod) => ({
                    ...prod,
                    category: TOPIC_CATEGORIES.PRODUCT,
                }));
                const modifiedServiceList = map(torder?.data?.data?.services, (serv) => ({
                    ...serv,
                    category: TOPIC_CATEGORIES.SERVICE,
                }));

                setDataSource([...modifiedProductList, ...modifiedServiceList]);
                dispatch(setTopics([...modifiedProductList, ...modifiedServiceList]));

                if (torder.errorMessages) throw torder.errorMessages;
            } catch (err) {
                console.log(err);
            }
        };

        const handlePickerOK = () => {
            setCurrentItems(selectedTopic);
            setIsModalOpen(false);
        };

        const handlePickerCancel = () => {
            setIsModalOpen(false);
        };

        const handleSelectTopic = (e: SyntheticEvent, topic: any) => {
            e.stopPropagation();
            const isTopicAlreadyAdded = selectedTopic?.id === topic.id;
            if (isTopicAlreadyAdded) {
                setSelectedTopic(null);
                return;
            }
            setSelectedTopic(topic);
        };

        const handleSearch = ({ target: { value } }) => {
            state.searchValue = value;
            const foundTopics = filter(topicsData, (topic) =>
                includes(topic.name.toLowerCase(), value.toLowerCase())
            );

            setDataSource(foundTopics);
        };

        const createNewTopic = () => {
            const topic = {
                id: Math.floor(10000 + Math.random() * 90000),
                category: TOPIC_CATEGORIES.CUSTOM,
                name: state.searchValue,
            };

            dispatch(addTopic(topic));

            state.searchValue = '';
            message.success('Добавлено');
        };

        useUpdateEffect(() => {
            setDataSource(topicsData);
        }, [topicsData]);

        useEffect(() => {
            if (isEmpty(currentItems)) return;
            setSelectedTopic(currentItems);
        }, [currentItems]);

        useEffect(() => {
            getOrderInfo();

            return () => {
                dispatch(resetTopics());
            };
        }, []);

        return (
            <Modal
                className="product-category-picker"
                title={'Выберите тему'}
                open={isModalOpen}
                onOk={handlePickerOK}
                onCancel={handlePickerCancel}
                cancelText="Отмена"
                centered
            >
                <div className="category-picker-search-box">
                    <Input
                        allowClear
                        className="header-search-input"
                        value={state.searchValue}
                        onChange={handleSearch}
                    />
                    <Button
                        disabled={!isEmpty(dataSource) || !state.searchValue.trim()}
                        type="primary"
                        onClick={createNewTopic}
                    >
                        Новая тема
                    </Button>
                </div>

                <List
                    className="customer-card-list"
                    dataSource={dataSource}
                    itemLayout="horizontal"
                    renderItem={(item) => (
                        <List.Item
                            className="customer-card"
                            key={item.category + item.id}
                            onClick={(e) => handleSelectTopic(e, item)}
                        >
                            <Row style={{ width: '100%' }}>
                                <Col span={3}>
                                    <FontAwesomeIcon
                                        color="orange"
                                        icon={TOPIC_ICONS[item?.category]}
                                    />
                                </Col>
                                <Col span={18}>
                                    <Row>
                                        <Col>
                                            <h3 className="title">{item.name}</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={3} className="button-select">
                                    <Button
                                        icon={<FontAwesomeIcon icon={faCheck} />}
                                        shape="circle"
                                        type={selectedTopic?.id === item.id ? 'primary' : 'default'}
                                    />
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Modal>
        );
    }
);
