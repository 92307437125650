import moment from "moment";
import { exceptionTypes } from "./constants";
import { TSchedule, TScheduleException, TScheduleTemplate } from "./types";

export const workers: TSchedule["workers"] = [
    {
        id: 1120,
        name: "Иван",
        surname: "Иванов",
        specialty: "Токарь"
    },
    {
        id: 1121,
        name: "Сергей",
        surname: "Сергеев",
        specialty: "Механик"
    },
    {
        id: 1122,
        name: "Роман",
        surname: "Романов",
        specialty: "Электрик"
    },
    {
        id: 1123,
        name: "Алексей",
        surname: "Алексеев",
        specialty: "Менеджер"
    }
];

export const emptySchedule: TSchedule = {
    template_id: -1,
    template_name: "Мой график",
    start_time: "09:00",
    end_time: "18:00",
    breaks: [],
    days: [],
    template_type: {
        type: "daily",
        on: 1,
        off: 1,
        allowed_overtime: 0
    },
    start_schedule_at: moment().add(1, "day").format("DD-MM-YYYY"),
    workers: []
};

export const startExceptions: TScheduleException[] = [
    {
        id: 0,
        exception_type: exceptionTypes['Отгул'],
        duration: 1860,
        start_date: moment().subtract(7, 'days').format("DD-MM-YYYY"),
        start_time: "11:00",
        status: 'Завершено',
        workers: [],
        description: null
    },
    {
        id: 2,
        exception_type: exceptionTypes['Переработка'],
        duration: 420,
        start_date: moment().add(7, 'days').format("DD-MM-YYYY"),
        start_time: "11:00",
        status: 'Ожидает',
        workers: [],
        description: null
    },
    {
        id: 1,
        exception_type: exceptionTypes['Отпуск'],
        duration: 14400,
        start_date: moment().add(15, 'days').startOf("day").format("DD-MM-YYYY"),
        start_time: "00:00",
        status: 'Ожидает',
        workers: [],
        description: null
    },
];

export const baseScheduleException = (workerArr: TSchedule["workers"]): TScheduleException => { 
    return {
        id: null,
        exception_type: exceptionTypes["Отгул"], 
        duration: 24*60, 
        start_date: moment().hours(0).minutes(0).add(1, "day").format("DD-MM-YYYY"),
        start_time: "08:00",
        status: "Ожидает",
        workers: [...workerArr],
        description: ""
    }
};

export const scheduleTypes = [{ label: "Каждый день", value: "daily" }, 
    { label: "По дням недели", value: "week" }, 
    { label: "Интервал", value: "interval" }];

export const templateExamples: TScheduleTemplate[] = [
    {
        template_id: 0,
        template_name: "5/2 8-17",
        start_time: "08:00",
        end_time: "17:00",
        breaks: [],
        days: [
            { index: 0, type: "default", start_time: "08:00", end_time: "17:00", breaks: [] },
            { index: 1, type: "default", start_time: "08:00", end_time: "17:00", breaks: [] },
            { index: 2, type: "default", start_time: "08:00", end_time: "17:00", breaks: [] },
            { index: 3, type: "default", start_time: "08:00", end_time: "17:00", breaks: [] },
            { index: 4, type: "default", start_time: "08:00", end_time: "17:00", breaks: [] },
            { index: 5, type: "day_off", start_time: "00:00", end_time: "23:59", breaks: [] },
            { index: 6, type: "day_off", start_time: "00:00", end_time: "23:59", breaks: [] },
        ],
        template_type: {
            type: "week",
            on: 5,
            off: 2,
            allowed_overtime: 60
        },
        workers: [...workers]
    },
    {
        template_id: 1,
        template_name: "2/2 8-20",
        start_time: "08:00",
        end_time: "20:00",
        breaks: [],
        days: [
            { index: 0, type: "default", start_time: "08:00", end_time: "20:00", breaks: [] },
            { index: 1, type: "default", start_time: "08:00", end_time: "20:00", breaks: [] },
        ],
        template_type: {
            type: "interval",
            on: 2,
            off: 2,
            allowed_overtime: 60
        },
        workers: [...workers]
    },
    {
        template_id: 2,
        template_name: "Ежедневно 8-17",
        start_time: "08:00",
        end_time: "17:00",
        breaks: [],
        days: [],
        template_type: {
            type: "daily",
            on: 1,
            off: 1,
            allowed_overtime: 60
        },
        workers: []
    },
];