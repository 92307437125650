import { CRMAPIBase } from "@api/crmApiBase";
import { RequestResult } from "@api/responseModels/requestResult";
import { TransactionResp } from "@api/responseModels/transactions/transactionResponse";
import { TCreateTransaction } from "types/transactions/create";
import { TransactionListResp } from "@api/responseModels/transactions/transactionListResponse";
import { TransactionParams } from "types/getParams";

class TransactionsGroup extends CRMAPIBase {
    /**
     * Добавление транзакции
     * @param params Параметры создания транзакции
     * @returns Результат запроса
     */
    async createTransaction(params: TCreateTransaction): Promise<RequestResult<TransactionResp>> {
        const result = await this.post<TransactionResp>('/transactions', {...params});
        return result;
    }

    /**
     * Создание обратной транзакции (i.e. удаление)
     * @param params Параметры как при изначальной транзакции
     * @returns Результат запроса
     */
    async revertTransaction(params: TCreateTransaction): Promise<RequestResult<TransactionResp>> {
        const result = await this.post<TransactionResp>('/transactions', {
            crm_id: params.crm_id,
            receiver_type: params.sender_type,
            receiver_id: params.sender_id,
            sender_type: params.receiver_type,
            sender_id: params.receiver_id,
            sum: params.sum,
            payment_method_id: params.payment_method_id,
            transaction_type_id: params.transaction_type_id,
        });
        return result;
    }

    /**
     * Получить список транзакций
     * @param crm_id ID CRM
     * @param page Страница
     * @param per_page Элементов на страницу
     * @param profile_type Фильтр по типу профиля
     * @param profile_id Фильтр по отправителю
     * @returns Результат запроса
     */
    async getTransactionList(params: TransactionParams): Promise<RequestResult<TransactionListResp>> {
        const result = await this.get<TransactionListResp>('/transactions', {...params});
        return result;
    }

    /**
     * Получить отдельную транзакцию
     * @param crmId ID CRM,
     * @param id ID транзакции
     * @returns Результат запроса
     */
    async getTransaction(crmId: number, id: number): Promise<RequestResult<TransactionResp>> {
        const result = await this.get<TransactionResp>(`/transactions/${id}`, { crm_id: crmId });
        return result;
    }
};

export { TransactionsGroup };