import Typography from 'antd/lib/typography/Typography';
import { observer } from 'mobx-react';

// import { columnViewStore } from '@molecules/listViewChanger/listViewStore';
import './brigadeCard.scss';

export const BrigadeCard = observer(({ number, name, onClick }) => {
    // Добавить изменение класса при изменении columnViewStore.col

    return (
        <div className="brigade-card-container" onClick={onClick}>
            <Typography className="brigade-card-count">{number}</Typography>
            <Typography className="brigade-card-name">{name}</Typography>
        </div>
    );
});
