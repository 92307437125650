import { createSlice } from '@reduxjs/toolkit';

import { initialWorkplaceList } from '../constants';

const workplaceListSlice = createSlice({
    name: 'workplace',
    initialState: {
        data: initialWorkplaceList,
    },
    reducers: {},
});

export default workplaceListSlice.reducer;
