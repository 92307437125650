import { EyeSlashIcon } from '@organisms/orders/profile/components/orderComment/common/svg/EyeSlashIcon';

import { Download } from './blocks/download';
import { TakeToWork } from './blocks/takeToWork';

import './unsupportScreen.scss';

export const UnsupportScreen = ({ label }) => {
    return (
        <div className="unsupport-screen-wrapper">
            <div className="unsupport-screen-icon">
                <EyeSlashIcon />
            </div>

            <div className="unsupport-screen-message">
                Файл <b>{label}</b> временно не поддерживается для предпросмотра
            </div>

            <div className="unsupport-screen-buttons">
                <TakeToWork onClick={() => {}} />
                <Download onClick={() => {}} />
            </div>
        </div>
    );
};
