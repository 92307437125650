import { useDispatch, useSelector } from 'react-redux';

import { Col, Grid, Row } from 'antd';
import { map } from 'lodash';

import RenderIf from '@common/RenderIf';
import { FileThumbnail } from '@organisms/orders/profile/components/orderComment/common/components/fileThumbnail';
import { selectFile } from '@organisms/orders/profile/components/orderComment/common/redux/commentSlice';
import { getComments } from '@organisms/orders/profile/components/orderComment/common/redux/selectors';

import './fileContent.scss';

const { useBreakpoint } = Grid;

export const FileContent = ({ comment, onOpenFile }) => {
    const dispatch = useDispatch();
    const { xs: isScreenSmall } = useBreakpoint();

    const { selectedFiles } = useSelector(getComments);

    return (
        <RenderIf condition={comment.body.files}>
            <Row className="comment-files-container" gutter={[0, 5]}>
                {map(comment.body.files, (file, index: number) => {
                    return (
                        <Col key={file.id} span={6}>
                            <FileThumbnail
                                files={comment.body.files}
                                file={file}
                                index={index}
                                selectFile={(id) => dispatch(selectFile(id))}
                                selected={selectedFiles.includes(file.id)}
                                handleClick={
                                    file.extension !== 'audio' ? () => onOpenFile(index) : () => {}
                                }
                                isSmall={isScreenSmall}
                            />
                        </Col>
                    );
                })}
            </Row>
        </RenderIf>
    );
};
