import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Input, List, Modal, Row } from 'antd';
import { filter, includes, map, uniqBy } from 'lodash';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBarcode, faCheck, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FILTER_KEYS } from '@organisms/orders/profile/components/orderComment/common/constants';
import {
    resetFilterArrayItem,
    setFilterArrayItem,
} from '@organisms/orders/profile/components/orderComment/common/redux/commentSlice';
import { getComments } from '@organisms/orders/profile/components/orderComment/common/redux/selectors';

const { Search } = Input;

export const Topics = ({ isOpen, toggleVisibility, toggleDropdown }) => {
    const dispatch = useDispatch();

    const { data: comments, filterData } = useSelector(getComments);

    const topics = useMemo(() => {
        const topicList = map(
            filter(comments, (com) => com.topic),
            (com) => com.topic
        );

        return uniqBy(topicList, 'id');
    }, [comments]);

    const getCategoryIcon = (category: string): IconProp => {
        if (category === 'product') return faBarcode;
        return faScrewdriverWrench;
    };

    const handleCancel = () => {
        dispatch(resetFilterArrayItem(FILTER_KEYS.TOPIC));
        toggleVisibility();
        toggleDropdown();
    };

    const isTopicSelected = (id) =>
        includes(
            map(filterData.topics, (topic) => topic.id),
            id
        );

    const handleOk = () => {
        toggleVisibility();
        toggleDropdown();
    };

    const handleSelect = (topic) => {
        dispatch(
            setFilterArrayItem({
                item: FILTER_KEYS.TOPIC,
                value: topic,
            })
        );
    };

    return (
        <Modal
            title={'Выберите тему'}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelText="Отмена"
            centered
        >
            <Row>
                <Col span={24}>
                    <Search allowClear onChange={() => {}} />
                </Col>
            </Row>
            <List
                dataSource={topics}
                itemLayout="horizontal"
                renderItem={(topic) => (
                    <List.Item key={topic.category + topic.id} onClick={() => {}}>
                        <Row style={{ width: '100%' }}>
                            <Col span={3}>
                                <FontAwesomeIcon
                                    color="orange"
                                    icon={getCategoryIcon(topic.category)}
                                />
                            </Col>
                            <Col span={18}>
                                <Row>
                                    <Col>
                                        <h3>{topic.name}</h3>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={3}>
                                <Button
                                    icon={<FontAwesomeIcon icon={faCheck} />}
                                    shape="circle"
                                    type={isTopicSelected(topic.id) ? 'primary' : 'default'}
                                    onClick={() => handleSelect(topic)}
                                />
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
        </Modal>
    );
};
