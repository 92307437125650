import filesViewerReducer from '@components/common/filesPreview/common/redux/filesViewerSlice';
import administratorReducer from '@organisms/administrator/common/redux/administratorSlice';
import rootOrdersReducer from '@organisms/orders/common/reducer';
import settingsReducer from '@organisms/settings/common/redux/settingsSlice';
import workersRootReducer from '@organisms/workers/common/redux/reducer';
import { combineReducers } from '@reduxjs/toolkit';

import errorReducer from './Errors/errorSlice';
import loadingReducer from './Loadings/loadingSlice';

const rootReducer = combineReducers({
    orders: rootOrdersReducer,
    workers: workersRootReducer,
    loadings: loadingReducer,
    errors: errorReducer,
    filesViewer: filesViewerReducer,
    settings: settingsReducer,
    administrator: administratorReducer,
});

export default rootReducer;
