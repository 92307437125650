import { makeAutoObservable } from 'mobx';

class ServiceStore {
    duration: number;
    constructor() {
        makeAutoObservable(this);
    }
    setDuration = (value: number) => {
        this.duration = value;
    };
}
const store = new ServiceStore();

export { store };
