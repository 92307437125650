import React, { memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCreation } from 'ahooks';
import { Col, Row, Table } from 'antd';
import { isEmpty } from 'lodash';
import { TOrderProduct } from 'types/Orders/products';

import {
    clearSelectedProducts,
    clearSelectedServices,
    selectAllItemsWithoutExecutor,
    selectItemWithoutExecutor,
} from '../../common/redux/orderSlice';
import {
    getOrder,
    getSelectedItemsWithoutExecutor,
    getSelectedProducts,
    getSelectedServices,
    getTotalItemsWithoutExecutor,
} from '../../common/redux/selectors';
import { clearSelectedComments } from '../orderComment/common/redux/commentSlice';
import { getComments } from '../orderComment/common/redux/selectors';
import { getColumnsWithoutExecutor } from './common/data';
import { TableProps } from './common/types';

import '../style.scss';

export const WithoutExecutorTable: React.FC<TableProps> = memo(({ nameCellWidth }) => {
    const dispatch = useDispatch();

    const { withoutExecutorFields = [] } = useSelector(getOrder) || {};
    const selectedItemsWithoutExecutor = useSelector(getSelectedItemsWithoutExecutor);
    const totalItemsWithoutExecutor = useSelector(getTotalItemsWithoutExecutor);
    const selectedServices = useSelector(getSelectedServices);
    const selectedProducts = useSelector(getSelectedProducts);
    const { selectedComments } = useSelector(getComments);
    const inputRefs = useRef([]);

    const setInputRef = (index, el) => {
        inputRefs.current[index] = el;
    };

    const handleSearchInput = (_item: TOrderProduct, _, _rowIndex: number) => {};

    const handleChangeQuantity = (_rowIndex: number, _newQuantity: number) => {};

    const handleChangePrice = (_rowIndex: number, _newPrice: number) => {};

    const handleSelectItem = (id: number) => {
        dispatch(clearSelectedComments());
        dispatch(clearSelectedProducts());
        dispatch(clearSelectedServices());
        dispatch(selectItemWithoutExecutor(id));
    };

    const handleSelectAllItems = () => {
        dispatch(selectAllItemsWithoutExecutor());
    };

    const columnsWithoutExecutor = useCreation(() => {
        return getColumnsWithoutExecutor({
            handleSearchInput,
            handleChangeQuantity,
            handleChangePrice,
            handleSelectItem,
            selectedItems: selectedItemsWithoutExecutor,
            handleSelectAllItems,
            itemsCount: withoutExecutorFields.length - 2,
            nameCellWidth: nameCellWidth,
            isCommentSelected:
                !isEmpty(selectedComments) ||
                !isEmpty(selectedProducts) ||
                !isEmpty(selectedServices),
            inputRefs,
            setInputRef,
        });
    }, [
        selectedItemsWithoutExecutor.length,
        nameCellWidth,
        !isEmpty(selectedComments),
        withoutExecutorFields,
        selectedProducts,
        selectedServices,
    ]);

    return (
        <Row className="table-product">
            <Table
                columns={columnsWithoutExecutor}
                bordered
                dataSource={withoutExecutorFields}
                pagination={false}
                className="table-size"
                size={'small'}
                rowKey={(record) => record.id}
                footer={() => {
                    return (
                        <Row className="t-footer" justify={'space-between'} align="middle">
                            <Col span={4}>
                                <h3>Итого</h3>
                            </Col>
                            <Col span={6}>
                                <h3>{totalItemsWithoutExecutor.toLocaleString('ru-RU')} ₽</h3>
                            </Col>
                        </Row>
                    );
                }}
            />
        </Row>
    );
});
