import { map, sumBy } from 'lodash';

import { Table as PDFTable, TD, TH, TR } from '@ag-media/react-pdf-table';
import { Text } from '@react-pdf/renderer';

import { styles } from '../../../common/styles';

export const BodyTable = ({ data }) => {
    return (
        <PDFTable style={styles.mt_10}>
            <TH>
                <TD weighting={1} style={[styles.tableHead]}>
                    №
                </TD>
                <TD weighting={7} style={[styles.tableHead, styles.fwBold]}>
                    Работы:
                </TD>
                <TD weighting={2} style={[styles.tableHead]}>
                    Количество
                </TD>
                <TD weighting={1} style={[styles.tableHead]}>
                    Ед. изм
                </TD>
                <TD weighting={2} style={[styles.tableHead]}>
                    Цена
                </TD>
                <TD weighting={3} style={[styles.tableHead]}>
                    Сумма
                </TD>
            </TH>

            {/* Услуги */}
            {map(data?.services, (service, index) => {
                return (
                    <TR>
                        <TD weighting={1} style={[styles.p_1, styles.justifyCenter]}>
                            {index + 1}
                        </TD>
                        <TD weighting={7} style={[styles.p_1, styles.tAlignLeft]}>
                            {service.name}
                        </TD>
                        <TD weighting={2} style={[styles.p_1, styles.justifyCenter]}>
                            {service.quantity}
                        </TD>
                        <TD weighting={1} style={[styles.p_1, styles.tAlignLeft]}>
                            шт.
                        </TD>
                        <TD weighting={2} style={[styles.p_1, styles.justifyCenter]}>
                            {Number(service.price).toLocaleString('ru-RU')}
                        </TD>
                        <TD weighting={3} style={[styles.p_1, styles.justifyCenter]}>
                            {service.total.toLocaleString('ru-RU')}
                        </TD>
                    </TR>
                );
            })}
            <TR>
                <TD weighting={1} style={[styles.p_1]} />
                <TD weighting={7} style={[styles.p_1, styles.tAlignLeft]}>
                    ИТОГО
                </TD>
                <TD weighting={2} style={[styles.p_1]} />
                <TD weighting={1} style={[styles.p_1]} />
                <TD weighting={2} style={[styles.p_1]} />
                <TD weighting={3} style={[styles.p_1, styles.justifyCenter, styles.fwBold]}>
                    {sumBy(data?.services, 'total').toLocaleString('ru-RU')}
                </TD>
            </TR>

            {/* Товары */}
            <TR>
                <TD weighting={1} style={[styles.p_1]} />
                <TD weighting={7} style={[styles.p_1, styles.justifyCenter, styles.fwBold]}>
                    Товары:
                </TD>
                <TD weighting={2} style={[styles.p_1]} />
                <TD weighting={1} style={[styles.p_1]} />
                <TD weighting={2} style={[styles.p_1]} />
                <TD weighting={3} style={[styles.p_1]} />
            </TR>
            {map(data?.products, (service, index) => {
                return (
                    <TR>
                        <TD weighting={1} style={[styles.p_1, styles.justifyCenter]}>
                            {index + 1}
                        </TD>
                        <TD weighting={7} style={[styles.p_1, styles.tAlignLeft]}>
                            {service.name}
                        </TD>
                        <TD weighting={2} style={[styles.p_1, styles.justifyCenter]}>
                            {service.quantity}
                        </TD>
                        <TD weighting={1} style={[styles.p_1, styles.tAlignLeft]}>
                            шт.
                        </TD>
                        <TD weighting={2} style={[styles.p_1, styles.justifyCenter]}>
                            {Number(service.price).toLocaleString('ru-RU')}
                        </TD>
                        <TD weighting={3} style={[styles.p_1, styles.justifyCenter]}>
                            {service.total.toLocaleString('ru-RU')}
                        </TD>
                    </TR>
                );
            })}
            <TR>
                <TD weighting={1} style={[styles.p_1]} />
                <TD weighting={7} style={[styles.p_1, styles.tAlignLeft]}>
                    ИТОГО
                </TD>
                <TD weighting={2} style={[styles.p_1]} />
                <TD weighting={1} style={[styles.p_1]} />
                <TD weighting={2} style={[styles.p_1]} />
                <TD weighting={3} style={[styles.p_1, styles.justifyCenter, styles.fwBold]}>
                    {sumBy(data?.products, 'total').toLocaleString('ru-RU')}
                </TD>
            </TR>
            <TR _lastRow>
                <Text style={[styles.p_1, styles.fwBold, styles.tAlignRight, { flex: 14 }]}>
                    Всего {'('}с учетом НДС{')'}
                </Text>

                <TD weighting={3} style={[styles.p_1, styles.justifyCenter, styles.fwBold]}>
                    {(sumBy(data?.products, 'total') + sumBy(data?.services, 'total')).toLocaleString('ru-RU')}
                </TD>
            </TR>
        </PDFTable>
    );
};
