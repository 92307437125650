import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import RenderIf from '@common/RenderIf';
import { getComments } from '@organisms/orders/profile/components/orderComment/common/redux/selectors';
import { UpdatedComment } from '@organisms/orders/profile/components/orderComment/common/types';

import './textContent.scss';

interface Props {
    comment: UpdatedComment;
}

export const TextContent: React.FC<Props> = ({ comment }) => {
    const {
        filterData: { searchText },
    } = useSelector(getComments);

    return (
        <div className="comment-content-box">
            <Row>
                <Col span={24}>
                    <RenderIf condition={comment.body.text}>
                        <Highlighter
                            searchWords={searchText.split(' ')}
                            autoEscape={true}
                            textToHighlight={comment.body.text}
                            className="comment-body-text"
                        />
                    </RenderIf>
                </Col>
            </Row>
        </div>
    );
};
