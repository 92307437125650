import { filter, map } from 'lodash';

import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import {
    AUDIO,
    EXTENSIONS_DATA,
    FORMATS,
} from '@organisms/orders/profile/components/orderComment/common/constants';
import { ExcelIcon } from '@organisms/orders/profile/components/orderComment/common/svg/ExcelIcon';
import { EyeSlashIcon } from '@organisms/orders/profile/components/orderComment/common/svg/EyeSlashIcon';
import { PdfIcon } from '@organisms/orders/profile/components/orderComment/common/svg/PdfIcon';
import { WordIcon } from '@organisms/orders/profile/components/orderComment/common/svg/WordIcon';
import { FileType } from '@organisms/orders/profile/components/orderComment/common/types';

import { OFFICE_FILES } from './constants';
import { PdfViewer, Thumbnail, UnsupportScreen } from './shared';

export const getGalleryItems = ({ data }) => {
    const dataWithoutAudio = filter(data, (file: FileType) => file.extension !== AUDIO);

    const images = map(dataWithoutAudio, (file: FileType, index) => {
        const src = URL.createObjectURL(file.blob);

        const extensionData = EXTENSIONS_DATA[file.extension] || EXTENSIONS_DATA.UNSUPPORTED;

        switch (extensionData) {
            // PDF
            case FORMATS.PDF: {
                return {
                    thumbnail: 'thumbnail',
                    thumbnailTitle: file?.name,
                    thumbnailLabel: file?.name,
                    thumbnailClass: 'custom-thumbnail',
                    originalClass: 'custom-original',
                    renderItem: () => <PdfViewer src={src} />,
                    renderThumbInner: () => (
                        <Thumbnail
                            key={file?.name + index}
                            alt={file?.name}
                            icon={<PdfIcon />}
                            label={file?.name}
                            id={index}
                        />
                    ),
                };
            }

            // WORD
            case FORMATS.WORD: {
                return {
                    thumbnail: 'thumbnail',
                    thumbnailTitle: file?.name,
                    thumbnailLabel: file?.name,
                    thumbnailClass: 'custom-thumbnail',
                    originalClass: 'custom-original',
                    renderItem: () => (
                        <DocViewer
                            documents={OFFICE_FILES.WORD}
                            pluginRenderers={DocViewerRenderers}
                        />
                    ),
                    renderThumbInner: () => (
                        <Thumbnail
                            key={file?.name + index}
                            alt={file?.name}
                            icon={<WordIcon />}
                            label={file?.name}
                            id={index}
                        />
                    ),
                };
            }

            // EXCELL
            case FORMATS.EXCEL: {
                return {
                    thumbnail: 'thumbnail',
                    thumbnailTitle: file?.name,
                    thumbnailLabel: file?.name,
                    thumbnailClass: 'custom-thumbnail',
                    originalClass: 'custom-original',
                    renderItem: () => (
                        <DocViewer
                            documents={OFFICE_FILES.EXCEL}
                            pluginRenderers={DocViewerRenderers}
                        />
                    ),
                    renderThumbInner: () => (
                        <Thumbnail
                            key={file?.name + index}
                            alt={file?.name}
                            label={file?.name}
                            icon={<ExcelIcon />}
                            id={index}
                        />
                    ),
                };
            }

            // ИЗОБРАЖЕНИЯ
            case FORMATS.IMAGE: {
                return {
                    original: src,
                    thumbnail: 'thumbnail',
                    thumbnailClass: 'custom-thumbnail',
                    originalClass: 'custom-original',
                    renderThumbInner: () => (
                        <Thumbnail
                            key={file?.name + index}
                            alt={file?.name}
                            imageSource={src}
                            label={file?.name}
                            id={index}
                        />
                    ),
                };
            }

            // БЕЗ ПОДДЕРЖКИ
            default:
                return {
                    thumbnail: 'thumbnail',
                    thumbnailClass: 'custom-thumbnail',
                    originalClass: 'custom-original',
                    renderItem: () => <UnsupportScreen label={file?.name} />,
                    renderThumbInner: () => (
                        <Thumbnail
                            key={file?.name + index}
                            alt={file?.name}
                            icon={<EyeSlashIcon style={{ margin: '7.525px 0 7.525px 0' }} />}
                            label={file?.name}
                            id={index}
                        />
                    ),
                };
        }
    });

    return images;
};
