import React, { useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch, useSelector } from 'react-redux';

import { useReactive, useUpdateEffect } from 'ahooks';
import { Select, Spin, message } from 'antd';
import axios from 'axios';
import { find, some } from 'lodash';
import qs from 'qs';

import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import { GlobalConstants } from '@constants/global';
import { getOrder } from '@organisms/orders/profile/common/redux/selectors';
import { rootStore } from '@store/rootStore/instanse';
import { LOADING_IDS } from '@utils/redux/Loadings/loadingIds';
import { toggleLoading } from '@utils/redux/Loadings/loadingSlice';

import { SearchInputProps } from './common/types';

import './searchInputProfile.scss';

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;
const { Option } = Select;

const SearchInputProfile: React.FC<SearchInputProps> = ({
    placeholder,
    style,
    fetchType,
    setDataOrder,
    rowIndex,
    defaultValue,
    isIncludesOther,
    otherItemQuantity,
    customClassName,
    inputRefs,
}) => {
    const state = useReactive({
        otherItem: null,
        fetchType: null,
        rowIndex: null,
        data: [],
        isError: false,
        value: '',
        isFocused: false,
        isFetching: false,
        kbOpen: false,
    });

    const ref = useRef(null);

    const dispatch = useDispatch();
    const { [fetchType]: entities = [] } = useSelector(getOrder) || {};

    const pseudoDelay = async (message: string) => {
        dispatch(toggleLoading({ isLoading: true, ids: [LOADING_IDS.ORDER] }));
        setTimeout(() => {
            dispatch(toggleLoading({ isLoading: false, ids: [LOADING_IDS.ORDER] }));
            messageService.sendSuccess(message);
        }, 1500);
    };

    const fetch = (query: string) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        currentValue = query;
        const creds = SettingsManager.getConnectionCredentials();
        if (fetchType === 'without-executor') return;
        if (fetchType == 'tasks') {
            state.isError = false;
            state.data = rootStore.taskStore
                .getTaskList(
                    {
                        crm_id: SettingsManager.getConnectionCredentials().crmID,
                        category_id: null,
                        filters: {
                            created_at: [null, null],
                            deleted: 'all',
                        },
                        query: query,
                        sort_direction: 'asc',
                        sort_by: 'name',
                        per_page: 8,
                        page: 1,
                    },
                    'available'
                )
                .data.data.map((item: any) => ({
                    value: item.name,
                    text: item.name,
                    id: item.id,
                    price: item.reward,
                }));
            state.isFetching = false;
            return;
        }
        const getData = async () => {
            const str = qs.stringify({
                crm_id: creds.crmID,
                query: query,
                sort_by: 'name',
                sortDirection: 'asc',
                createdDates: ['', ''],
                updatedDates: ['', ''],
                price: ['0', '99999'],
                deleted: 'null',
            });
            try {
                state.isError = false;
                state.isFetching = true;
                const response = await axios.get(`${GlobalConstants.BaseUrl}/${fetchType}?${str}`, {
                    headers: {
                        Authorization: 'Bearer ' + creds.token,
                    },
                });
                state.isFetching = false;

                const result = response.data.data;
                const tempData = result.map((item: any) => ({
                    value: item.name,
                    text: item.name,
                    id: item.id,
                    price: item.price,
                }));
                state.data = tempData;
            } catch (err) {
                console.log(err);
                state.isError = true;
                state.isFetching = false;
            }
        };
        timeout = setTimeout(getData, GlobalConstants.SearchTimeout);
    };

    const handleSearch = (value: string) => {
        if (state.isFocused) state.value = value;

        if (value) fetch(value);
    };

    const handleSelect = (label) => {
        const dataItem = state.data.find((item) => item.value == label);
        setDataOrder(
            {
                id: +dataItem.id,
                name: dataItem.text,
                price: dataItem.price,
                quantity: 1,
                total: 1 * dataItem.price,
            },
            fetchType,
            rowIndex
        );
        state.value = '';

        setTimeout(() => {
            inputRefs.current[rowIndex].focus();
        }, 0);
    };

    const canBeAddedToOther =
        !state.isFocused &&
        state.value !== '' &&
        state.value !== defaultValue &&
        !some(entities, { name: state.value });

    const isFieldAlreadyExist =
        some(entities, { name: state.value }) &&
        !state.isFocused &&
        state.value !== '' &&
        state.value !== defaultValue &&
        !state.isFetching;

    const isFieldExistInData =
        !state.isFocused &&
        some(state.data, { text: state.value }) &&
        !state.isFetching &&
        state.value !== defaultValue;

    const isFieldWasCleared = !state.isFocused && state.value === '';

    useUpdateEffect(() => {
        if (isFieldExistInData) {
            const dataItem = find(state.data, { text: state.value });
            setDataOrder(
                {
                    id: +dataItem.id,
                    name: dataItem.text,
                    price: dataItem.price,
                    quantity: 1,
                    total: 1 * dataItem.price,
                },
                fetchType,
                rowIndex
            );
            state.value = '';
            return;
        }

        if (isFieldWasCleared) {
            state.value = defaultValue;
            return;
        }

        if (isFieldAlreadyExist) {
            message.error(`Поле ${state.value} уже существует`);
            return;
        }

        if (canBeAddedToOther && fetchType !== 'without-executor') {
            state.otherItem = {
                id: 'other' + Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000,
                name: state.value,
                price: 0,
                quantity: otherItemQuantity ?? 1,
                total: 0,
            };
            state.fetchType = fetchType;
            state.rowIndex = rowIndex;

            setDataOrder(state.otherItem, state.fetchType, state.rowIndex);
            pseudoDelay('Добавлено в прочее');
            state.value = '';

            setTimeout(() => {
                inputRefs.current[rowIndex].focus();
            }, 0);
        }
    }, [canBeAddedToOther, isFieldExistInData, isFieldAlreadyExist, isFieldWasCleared]);

    const scrollToItem = (itemId, offset = 20) => {
        const element = document.getElementById(itemId);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

        setTimeout(() => {
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        });
    };

    const handleResize = () => {
        state.kbOpen = window.screen.height - 200 > window.visualViewport.height;
    };

    useUpdateEffect(() => {
        if (state.isFocused) {
            state.data = [];
            handleSearch(state.value);
        }

        if (state.isFocused && state.kbOpen) scrollToItem(`select-${fetchType}-${rowIndex}`);
    }, [state.isFocused, state.kbOpen]);

    useEffect(() => {
        state.value = defaultValue;

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Select
            id={`select-${fetchType}-${rowIndex}`}
            ref={ref}
            className={`${isIncludesOther ? 'other' : ''} ${customClassName || ''}`}
            showSearch
            onDropdownVisibleChange={(val) => {
                state.isFocused = val;
            }}
            autoClearSearchValue={false}
            status={state.isError ? 'error' : ''}
            value={state.value}
            searchValue={state.value}
            placeholder={placeholder}
            style={style}
            defaultActiveFirstOption={false}
            showArrow={false}
            onSearch={handleSearch}
            notFoundContent={state.isFetching ? <Spin size="small" /> : <div>Нет данных</div>}
            onSelect={handleSelect}
        >
            {state.data?.map((elem) => (
                <Option key={elem.id} value={elem.value}>
                    <Highlighter
                        searchWords={state.value?.split(' ') || []}
                        autoEscape={true}
                        textToHighlight={elem.text}
                    />
                </Option>
            ))}
        </Select>
    );
};

export { SearchInputProfile };
