import { useCreation, useReactive, useToggle } from 'ahooks';
import { Button, Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import { TUser } from 'types/user/user';

import RenderIf from '@common/RenderIf';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommentEmployeesPicker } from '@organisms/orders/profile/components/orderComment/common/components/commentEmployeesPicker';

import './sender.scss';

export const Sender = () => {
    const state = useReactive({
        senders: [],
    });

    const [isEmployeesModalVisible, { toggle: toggleEmployeesModal }] = useToggle(false);

    const firstSelectedRecipient = useCreation(() => {
        if (isEmpty(state.senders)) return undefined;
        return `${state.senders[0]?.name} ${state.senders[0]?.surname}`;
    }, [state.senders[0]]);

    const selectSenders = (senders: TUser[]) => {
        state.senders = senders;
    };

    return (
        <Row className="sender-block" align={'middle'}>
            <Col span={4}>
                <span className="comment-replied-property">От кого:</span>
            </Col>
            <Col span={20}>
                <Button onClick={toggleEmployeesModal} className="attribute-btn">
                    <RenderIf condition={state.senders.length > 1}>
                        {`${firstSelectedRecipient} +${state.senders.length - 1}`}
                    </RenderIf>
                    <RenderIf condition={state.senders.length < 2}>
                        <p className="comment-topic">{firstSelectedRecipient ?? 'Общий чат'}</p>
                    </RenderIf>
                    <FontAwesomeIcon className="attribute-btn-arrow" icon={faChevronDown} />
                </Button>
            </Col>
            <RenderIf condition={isEmployeesModalVisible}>
                <CommentEmployeesPicker
                    isModalOpen={isEmployeesModalVisible}
                    setIsModalOpen={toggleEmployeesModal}
                    currentItems={state.senders}
                    setCurrentItems={selectSenders}
                />
            </RenderIf>
        </Row>
    );
};
