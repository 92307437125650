import { useReactive } from "ahooks";
import { observer } from "mobx-react";
import { TTask, TTaskCategory } from "../common/types";
import { TMetadata } from "types/metadata";
import { useNavigate } from "react-router-dom";
import { SettingsManager } from "@classes/settingsManager";
import { FilterStore } from "@organisms/productServicesFilter/filterStore";
import { useEffect, useRef } from "react";
import { CRMAPIManager } from "@classes/crmApiManager";
import { TaskCategoryListResp } from "@api/responseModels/task/taskCategoryListResponse";
import { LastIdStore } from "@pages/lastIdStore";
import { CategoryParams } from "types/getParams";
import { store as pStore } from '@molecules/paginationControls/pageSizeStore';
import { messageService } from "@classes/messageService";
import { TaskCategoryResp } from "@api/responseModels/task/taskCategoryResponse";
import { Common } from "@classes/commonMethods";
import { GlobalConstants } from "@constants/global";
import { Loader } from "@atoms/loader";
import { FunctionalMenu } from "@molecules/functionalMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonSnowboarding, faPlus, faTrashCan, faTrashCanArrowUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import { HeaderListBreadcrumbs } from "@molecules/breadcrumbs/listsBreadcrumbs/HeaderListBreadcrumbs";
import { PageSizeChanger } from "@molecules/paginationControls/pageSizeChanger";
import { ListViewChanger } from "@molecules/listViewChanger/listViewChanger";
import { Pagination, Tabs } from "antd";
import { ShowMoreButton } from "@molecules/paginationControls/showMoreButton";
import { RenderTaskCategoryList } from "../partials/renderTaskCategoryList/renderTaskCategoryList";
import "./style.scss";
const filterStore = new FilterStore();
const filter = filterStore.filter;

type TState = {
    isLoading: boolean,
    taskCategoryList: Array<TTaskCategory & { tasks: Array<TTask>; categories: Array<TTaskCategory>}>,
    selectList: Array<{ id: number; name: string; }>,
    currentPage: number,
    currentMeta: TMetadata,
};

const TaskCategoryList = observer((): JSX.Element => {
    const state = useReactive<TState>({
        isLoading: true,
        taskCategoryList: [],
        selectList: [],
        currentPage: 1,
        currentMeta: null,
    });
    const navigate = useNavigate();
    const creds = SettingsManager.getConnectionCredentials();
    const tabNumber = useRef<string>('');

    function onChangeTab(key: string) {
        if (key == '3') filterStore.changeDeleted('only');
        else {
            filterStore.changeDeleted('null');
        }
        state.selectList = [];
        tabNumber.current = key;
    }

    async function getTaskCategoryList(page: number = state.currentPage, addition: boolean = false) {
        state.isLoading = true;
        try {
            const taskCatList = await CRMAPIManager.request<TaskCategoryListResp>(
                async (api) => {
                    const params: CategoryParams = {
                        crm_id: creds.crmID,
                        category_id: LastIdStore.lastServiceCategoryId ?? '',
                        sort_by: filter.sortBy,
                        sort_direction: filter.sortDirection,
                        filters: {
                            created_at: [...filter.createdDates],
                            deleted: "all",
                        },
                        query: filter.query,
                        page,
                        per_page: pStore.pS,
                    };
                    Object.keys(params.filters).filter(
                        (key) => params.filters[key] === null && delete params.filters[key]
                    );
                    return await api.getTaskCategoryList(params);
                }
            );
            if (taskCatList.errorMessages) throw taskCatList.errorMessages;
            if (addition) {
                state.taskCategoryList = [...state.taskCategoryList, ...taskCatList.data.data];
            } else {
                state.taskCategoryList = taskCatList.data.data;
            }
            state.currentMeta = taskCatList.data.meta;
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    async function handleShowMore() {
        await getTaskCategoryList(state.currentPage + 1, true);
        state.currentPage += 1;
    }

    async function handleChangePage(newPage: number) {
        await getTaskCategoryList(newPage);
        state.currentPage = newPage;
    }

    async function deleteSelected() {
        state.isLoading = true;
        while (state.selectList.length != 0) {
            const target = state.selectList.pop();
            try {
                const del = await CRMAPIManager.request<TaskCategoryResp>(async (api) => {
                    return await api.removeTaskCategory(creds.crmID, target.id);
                });
                if (del.errorMessages) throw del.errorMessages;
            } catch (errors) {
                messageService.sendErrorList(errors);
            }
        }
        await getTaskCategoryList(1);
        state.currentPage = 1;
        state.isLoading = false;
    }

    async function restoreSelected() {
        state.isLoading = true;
        while (state.selectList.length != 0) {
            const target = state.selectList.pop();
            try {
                const del = await CRMAPIManager.request<TaskCategoryResp>(async (api) => {
                    return await api.restoreTaskCategory(creds.crmID, target.id);
                });
                if (del.errorMessages) throw del.errorMessages;
            } catch (errors) {
                messageService.sendErrorList(errors);
            }
        }
        await getTaskCategoryList(1);
        state.currentPage = 1;
        state.isLoading = false;
    }

    function handleCreateTaskCategory() {
        LastIdStore.setLastTaskCategoryId(-1);
        navigate(`/lk/worker/crm/${creds.crmID}/task-categories/create`);
    }

    function beforeMountTaskCategoryList() {
        Common.checkUserBelongToCrm(GlobalConstants.CrmOrderinUrl).then((res) => {
            if (!res) navigate(`/lk/worker/crm/${creds.crmID}`);
        })
        getTaskCategoryList();
    }

    useEffect(() => {
        beforeMountTaskCategoryList();
    }, [filter.deleted]);

    useEffect(() => {
        if (state.currentMeta?.total == null || state.currentMeta?.total == 0) return;
        let newPage = Math.ceil(state.currentMeta.from / pStore.pS);
        getTaskCategoryList(newPage).then(() => {
            state.currentPage = newPage;
        })
    }, [pStore.pS]);

    return (
        <div className="list-template task-category-list">
            {state.isLoading && <Loader/>}
            <FunctionalMenu
                items={state.selectList.length > 0
                    ? [
                        {
                            key: 'createCategory',
                            label: 'Создать категорию',
                            icon: <FontAwesomeIcon icon={faPlus} />,
                            onClick: handleCreateTaskCategory,
                        },
                        {
                            key: 'abort',
                            label: 'Отмена',
                            icon: <FontAwesomeIcon icon={faXmark} />,
                            onClick: () => {
                                state.selectList = [];
                            },
                        },
                    ] : [
                        {
                            key: 'createCategory',
                            label: 'Создать категорию',
                            icon: <FontAwesomeIcon icon={faPlus} />,
                            onClick: handleCreateTaskCategory,
                        }
                    ]}
                dropdownItems={state.selectList.length > 0 
                    ? [{
                        key: tabNumber.current == '3' ? 'restore' : 'delete',
                        label: tabNumber.current == '3' ? 'Восстановить' : 'Удалить',
                        icon: tabNumber.current == '3' ? <FontAwesomeIcon icon={faTrashCanArrowUp} /> : <FontAwesomeIcon icon={faTrashCan} />,
                        onClick: tabNumber.current == '3' ? restoreSelected : deleteSelected,
                    }] : []
                }
                selected={state.selectList.length}
            />
            <HeaderListBreadcrumbs
                dataTotal={state.currentMeta?.total}
                title={'Категории задач'}
                dataTitle={'Категории задач'}
                dataIcon={faPersonSnowboarding}
                dataPrice={null}
                flag={false}
                isProduct={false}
                categoryPath={null}
                openCategoryCard={null}
                searchPlaceHolder='По категориям'
            >
                <>
                    <PageSizeChanger />
                    <ListViewChanger />
                </>
            </HeaderListBreadcrumbs>
            <Tabs
                items={[
                    {
                        label: 'Категории задач',
                        key: '1',
                        children: (
                            <RenderTaskCategoryList
                                dataCategories={state.taskCategoryList}
                                selectList={state.selectList}
                                route={`/lk/worker/crm/${creds?.crmID}/tasks`}
                                routeCard={`/lk/worker/crm/${creds?.crmID}/task-categories`}
                                setLastCategoryId={LastIdStore.setLastTaskCategoryId}
                                setSelectList={(sl: { id: number, name: string }[]) => state.selectList = sl}
                            />
                        ),
                    },
                    {
                        label: 'Корзина',
                        key: '3',
                        children: (
                            <RenderTaskCategoryList
                                dataCategories={state.taskCategoryList}
                                selectList={state.selectList}
                                route={`/lk/worker/crm/${creds?.crmID}/tasks`}
                                routeCard={`/lk/worker/crm/${creds?.crmID}/task-categories`}
                                setLastCategoryId={LastIdStore.setLastTaskCategoryId}
                                setSelectList={(sl: { id: number, name: string }[]) => state.selectList = sl}
                            />
                        ),
                    }
                ]}
                onChange={(key) => {
                    onChangeTab(key);
                }}
            />
            {state.currentMeta && state.currentPage < state.currentMeta.last_page && (
                <ShowMoreButton onClick={handleShowMore} text="Показать ещё" />
            )}
            <Pagination
                current={state.currentPage}
                defaultCurrent={1}
                onChange={handleChangePage}
                pageSize={pStore.pS}
                showSizeChanger={false}
                total={state.currentMeta?.total ?? 1}
            />
        </div>
    );
});

export { TaskCategoryList };