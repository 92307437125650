import { filter, includes, isEmpty, without } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

import { CATEGORY_TYPES } from '../constants';
import { AdminData } from '../types';
import { mockData } from './mockData';

const initialState = {
    data: {
        list: mockData,
        resultList: mockData,
        regions: [],
        categoryOfActivity: [],
        subcategoryOfActivity: [],
        companies: [],
        searchValue: '',
    },
};

const administratorSlice = createSlice({
    name: 'administrator',
    initialState,
    reducers: {
        filterElementsByCriteria: (state, { payload }) => {
            const { itemId, categoryType } = payload;

            const fiterList = () => {
                const { categoryOfActivity, companies, regions, subcategoryOfActivity } =
                    state.data;

                const byRegions = filter(
                    mockData,
                    (item) => isEmpty(regions) || includes(regions, item.region.id)
                );
                const byCategory = filter(
                    byRegions,
                    (item) =>
                        isEmpty(categoryOfActivity) ||
                        includes(categoryOfActivity, item.categoryOfActivity.id)
                );
                const bySubcategory = filter(
                    byCategory,
                    (item) =>
                        isEmpty(subcategoryOfActivity) ||
                        includes(subcategoryOfActivity, item.subcategoryOfActivity.id)
                );
                const byCompany = filter(
                    bySubcategory,
                    (item) => isEmpty(companies) || includes(companies, item.company.id)
                );

                state.data.list = byCompany;
                state.data.resultList = byCompany;
            };

            if (!includes(state.data[categoryType], itemId)) {
                state.data[categoryType].push(itemId);
                fiterList();
                return;
            }

            state.data[categoryType] = without(state.data[categoryType], itemId);
            if (categoryType === CATEGORY_TYPES.CATEGORY_OF_ACTIVITY)
                state.data.subcategoryOfActivity = [];
            fiterList();
        },

        filterElementsBySearchValue: (state, { payload }) => {
            state.data.searchValue = payload;
            if (!payload) {
                state.data.resultList = state.data.list;
                return;
            }

            state.data.resultList = filter(state.data.list, (item: AdminData) =>
                includes(item.name.toLowerCase(), payload)
            );
        },
    },
});

export default administratorSlice.reducer;

export const { filterElementsByCriteria, filterElementsBySearchValue } = administratorSlice.actions;
