import { useCreation, useToggle } from 'ahooks';
import { Button, Col, Row, Space } from 'antd';
import { isEmpty } from 'lodash';
import { TUser } from 'types/user/user';

import RenderIf from '@common/RenderIf';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommentEmployeesPicker } from '@organisms/orders/profile/components/orderComment/common/components/commentEmployeesPicker';
import { CommentTopicsPicker } from '@organisms/orders/profile/components/orderComment/common/components/commentTopicsPicker';
import { TOPIC_CATEGORIES } from '@organisms/orders/profile/components/orderComment/common/components/commentTopicsPicker/common/constants';

interface Props {
    recipients: TUser[];
    topic: any;
    selectTopic: any;
    selectRecipients: any;
}

export const CommentAttributes: React.FC<Props> = ({
    recipients,
    topic,
    selectRecipients,
    selectTopic,
}) => {
    const [isEmployeesModalVisible, { toggle: toggleEmployeesModal }] = useToggle(false);
    const [isTopicsModalVisible, { toggle: toggleTopicsModal }] = useToggle(false);

    const topicCategory = {
        [TOPIC_CATEGORIES.PRODUCT]: 'Продукт: ',
        [TOPIC_CATEGORIES.SERVICE]: 'Услуга: ',
        [TOPIC_CATEGORIES.CUSTOM]: '',
    };

    const topicTitle = useCreation(() => {
        if (!topic) return null;
        return `${topicCategory[topic.category]}${topic.name}`;
    }, [topic]);

    const firstSelectedRecipient = useCreation(() => {
        if (isEmpty(recipients)) return undefined;
        return `${recipients[0]?.name} ${recipients[0]?.surname}`;
    }, [recipients[0]]);

    return (
        <div className="comment-replied">
            <Space direction="vertical">
                <Row align={'middle'}>
                    <Col xs={3} sm={2}>
                        <span className="comment-replied-property">Кому:</span>
                    </Col>
                    <Col xs={21} sm={22}>
                        <Button onClick={toggleEmployeesModal} className="attribute-btn">
                            <RenderIf condition={recipients.length > 1}>
                                {`${firstSelectedRecipient} +${recipients.length - 1}`}
                            </RenderIf>
                            <RenderIf condition={recipients.length < 2}>
                                <p className="comment-topic">
                                    {firstSelectedRecipient ?? 'Общий чат'}
                                </p>
                            </RenderIf>
                            <FontAwesomeIcon className="attribute-btn-arrow" icon={faChevronDown} />
                        </Button>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col xs={3} sm={2}>
                        <span className="comment-replied-property">Тема:</span>
                    </Col>
                    <Col xs={21} sm={22}>
                        <Button onClick={toggleTopicsModal} className="attribute-btn">
                            <p className="comment-topic">{topicTitle ?? 'Без темы'}</p>
                            <FontAwesomeIcon className="attribute-btn-arrow" icon={faChevronDown} />
                        </Button>
                    </Col>
                </Row>
            </Space>
            <RenderIf condition={isEmployeesModalVisible}>
                <CommentEmployeesPicker
                    currentItems={recipients}
                    isModalOpen={isEmployeesModalVisible}
                    setCurrentItems={selectRecipients}
                    setIsModalOpen={toggleEmployeesModal}
                />
            </RenderIf>
            <RenderIf condition={isTopicsModalVisible}>
                <CommentTopicsPicker
                    currentItems={topic}
                    setCurrentItems={selectTopic}
                    setIsModalOpen={toggleTopicsModal}
                    isModalOpen={isTopicsModalVisible}
                />
            </RenderIf>
        </div>
    );
};
