import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react';

import { columnViewStore } from '@molecules/listViewChanger/listViewStore';
import { RenderUserList } from '@molecules/renderList/userList';

export const WorkplaceList = observer(() => {
    const { groupId } = useParams();

    return (
        <RenderUserList
            setSelectList={() => {}}
            inviteStatus={null}
            userList={[]}
            selectList={[]}
            isWorker={true}
            col={columnViewStore.col}
        />
    );
});
