import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Typography } from 'antd';
import { countBy, flatMap, includes, isEmpty, keys, map, sum, values } from 'lodash';

import {
    FILTER_KEYS,
    GROUPED_FILE_FORMATS,
} from '@organisms/orders/profile/components/orderComment/common/constants';
import {
    resetFilterArrayItem,
    setFilterAllFiles,
    setFilterFiles,
} from '@organisms/orders/profile/components/orderComment/common/redux/commentSlice';
import { getComments } from '@organisms/orders/profile/components/orderComment/common/redux/selectors';
import { UpdatedComment } from '@organisms/orders/profile/components/orderComment/common/types';

import '../commentFilter.scss';

const { Text } = Typography;

export const Files = ({ isOpen, toggleVisibility, toggleDropdown }) => {
    const dispatch = useDispatch();
    const {
        data: comments,
        filterData: { files: selectedFiles },
    } = useSelector(getComments);

    const allFiles = useMemo(
        () =>
            flatMap(comments, (com: UpdatedComment) =>
                map(com.body.files, (file) => GROUPED_FILE_FORMATS[file.extension])
            ),
        [comments]
    );

    const isSelected = (fileType) => {
        return includes(selectedFiles, fileType);
    };

    const isAllFilesSelected = () => {
        const allFilesKeysArray = keys(countBy(allFiles));

        if (isEmpty(selectedFiles) || isEmpty(allFilesKeysArray)) return false;

        return selectedFiles.length === allFilesKeysArray.length;
    };

    const handleOk = () => {
        toggleVisibility();
        toggleDropdown();
    };

    const handleCancel = () => {
        dispatch(resetFilterArrayItem(FILTER_KEYS.FILE));
        toggleVisibility();
        toggleDropdown();
    };

    const handleSelect = (fileType) => {
        dispatch(setFilterFiles(fileType));
    };

    const handleSelectAll = (allFiles) => {
        dispatch(setFilterAllFiles(allFiles));
    };

    return (
        <Modal
            title={'Только с файлами'}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelText="Отмена"
            centered
        >
            <div className="filter-file-wrapper">
                <div
                    className={`filter-file-container ${
                        isAllFilesSelected() ? 'filter-file-selected' : ''
                    }`}
                    onClick={() => handleSelectAll(keys(countBy(allFiles)))}
                >
                    <Text>
                        Все{' '}
                        <Text className="filter-file-number">{sum(values(countBy(allFiles)))}</Text>
                    </Text>
                </div>

                {map(countBy(allFiles), (number, fileType) => {
                    if (!number) return null;

                    return (
                        <div
                            key={fileType}
                            className={`filter-file-container ${
                                isSelected(fileType) ? 'filter-file-selected' : ''
                            }`}
                            onClick={() => handleSelect(fileType)}
                        >
                            <Text>
                                {fileType !== 'undefined' ? fileType : 'Другие'}{' '}
                                <Text className="filter-file-number">{number}</Text>
                            </Text>
                        </div>
                    );
                })}
            </div>
        </Modal>
    );
};
