import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import { TaskCategoryList } from '@components/tasks/category/taskCategoryList';
import { TaskCategoryProfile } from '@components/tasks/category/taskCategoryProfile';
import { TaskProfile, TaskUseCases } from '@components/tasks/profile/taskProfile';
import { TaskList } from '@components/tasks/taskList';
import { WorkerSchedule } from '@components/workers/schedule';
import { Administrator } from '@organisms/administrator/administrator';
import { Section } from '@organisms/administrator/partials/section/section';
import { ApelsinFooter } from '@organisms/common/apelsinFooter';
import { ApelsinHeader } from '@organisms/common/apelsinHeader';
import { CRMProfile } from '@organisms/crmProfile';
import { CustomerList } from '@organisms/customers/customerList';
import { CustomerProfile } from '@organisms/customers/customerProfile';
import { InvitationList } from '@organisms/invitations/invitationList';
import { OrderEdit } from '@organisms/orders/edit/orderEdit';
import { OrderList } from '@organisms/orders/orderList';
import { OrderCreate } from '@organisms/orders/profile/orderCreate';
import { OrderProfile } from '@organisms/orders/profile/orderProfile';
import { PermissionSettings } from '@organisms/permissionSettings/permissionSettings';
import { ProductList } from '@organisms/product/productList';
import { ProductProfile } from '@organisms/product/productProfile';
import { ProductCategoryList } from '@organisms/productCategory/productCategoryList';
import { ProductCategoryProfile } from '@organisms/productCategory/productCategoryProfile';
import { AfterLoginForm } from '@organisms/security/afterLoginForm';
import { ConnectionForm } from '@organisms/security/connectionForm';
import { CrmFormCreation } from '@organisms/security/crmFormCreation';
import { LogList } from '@organisms/security/logList';
import { Logout } from '@organisms/security/logout';
import { TransactionList } from '@organisms/security/transactionList';
import { ServiceList } from '@organisms/service/serviceList';
import { ServiceProfile } from '@organisms/service/serviceProfile';
import { ServiceCategoryList } from '@organisms/serviceCategory/serviceCategoryList';
import { ServiceCategoryProfile } from '@organisms/serviceCategory/serviceCategoryProfile';
import { CustomizableSection } from '@organisms/settings/customizableSection/customizableSection';
import { Settings } from '@organisms/settings/settings';
import WorksheetSetting from '@organisms/settings/worksheetSetting/worksheetSetting';
import { Worksheets } from '@organisms/settings/worksheets/worksheets';
import { SpecialtyList } from '@organisms/specialty/specialtyList';
import { SpecialtyProfile } from '@organisms/specialty/specialtyProfile';
import { WorkerList } from '@organisms/workers/workerList';
import { WorkerProfile } from '@organisms/workers/workerProfile';
import { WorkplaceProfile } from '@organisms/workers/workplaceProfile/workplaceProfile';

const App = (): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();

    function checkConnection() {
        if (SettingsManager.getConnectionCredentials()?.token != null) {
            if (SettingsManager.getConnectionCredentials().crmID && location?.pathname == '/') {
                navigate(`/lk/worker/crm/${SettingsManager.getConnectionCredentials().crmID}`);
            } else if (location?.pathname == '/') navigate('/lk');
        } else {
            if (location?.pathname != 'login') navigate('/login');
        }
    }

    useEffect(() => {
        checkConnection();
        messageService.clearMessages();
    }, [location.pathname]);

    function getBody(): JSX.Element {
        return (
            <Routes>
                <Route path="/login" element={<ConnectionForm />} />
                <Route path="/lk" element={<AfterLoginForm />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/lk/worker" element={<CrmFormCreation />} />
                <Route path="/lk/worker/crm/:id" element={<CRMProfile />} />
                <Route path="/lk/worker/crm/:id/edit" element={<CRMProfile />} />
                <Route path="/lk/worker/crm/:id/invitations" element={<InvitationList />} />
                <Route
                    path="/lk/worker/crm/:id/workers/tabs/:currentTab"
                    element={<WorkerList />}
                />
                <Route
                    path="/lk/worker/crm/:id/workers/tabs/:currentTab/:groupId/workplaces"
                    element={<WorkerList />}
                />
                {/* Создание рабочего места */}
                <Route
                    path="/lk/worker/crm/:id/workers/tabs/:currentTab/:groupId/workplaces/create"
                    element={<WorkplaceProfile />}
                />
                {/* Редактирование рабочего места */}
                <Route
                    path="/lk/worker/crm/:id/workers/tabs/:currentTab/:groupId/workplaces/:workplaceId"
                    element={<WorkplaceProfile />}
                />
                <Route path="/lk/worker/profile" element={<WorkerProfile />} />
                <Route path="/lk/worker/profile/edit" element={<WorkerProfile />} />
                <Route path="/lk/worker/crm/:crmID/workers/:workerID" element={<WorkerProfile />} />
                <Route path="/lk/worker/crm/:crmID/workers/create" element={<WorkerProfile />} />
                <Route
                    path="/lk/worker/crm/:crmID/workers/:workerID/edit"
                    element={<WorkerProfile />}
                />
                <Route
                    path="/lk/worker/crm/:crmID/workers/:workerID/schedule"
                    element={<WorkerSchedule />}
                />
                <Route
                    path="/lk/worker/crm/:crmID/workers/:workerID/permissions"
                    element={<PermissionSettings />}
                />
                <Route path="/lk/worker/crm/:crmID/customers" element={<CustomerList />} />
                <Route
                    path="/lk/worker/crm/:crmID/customers/:customerID"
                    element={<CustomerProfile />}
                />
                <Route
                    path="/lk/worker/crm/:crmID/customers/create"
                    element={<CustomerProfile />}
                />
                <Route
                    path="/lk/worker/crm/:crmID/customers/:customerID/edit"
                    element={<CustomerProfile />}
                />
                <Route path="/lk/worker/crm/:id/specialties" element={<SpecialtyList />} />
                <Route
                    path="/lk/worker/crm/:id/specialties/:specialtyID"
                    element={<SpecialtyProfile />}
                />
                <Route
                    path="/lk/worker/crm/:id/specialties/:specialtyID/edit"
                    element={<SpecialtyProfile />}
                />
                <Route
                    path="/lk/worker/crm/:id/product-category"
                    element={<ProductCategoryList />}
                />
                <Route
                    path="/lk/worker/crm/:id/product-category/:productCategoryID"
                    element={<ProductCategoryProfile />}
                />
                <Route
                    path="/lk/worker/crm/:id/product-category/create"
                    element={<ProductCategoryProfile />}
                />
                <Route
                    path="/lk/worker/crm/:id/product-category/:productCategoryID/edit"
                    element={<ProductCategoryProfile />}
                />
                <Route path="/lk/worker/crm/:id/products" element={<ProductList />} />
                <Route
                    path="/lk/worker/crm/:id/products/nav/:productCategoryId"
                    element={<ProductList />}
                />
                <Route path="/lk/worker/crm/:id/products/:productID" element={<ProductProfile />} />
                <Route path="/lk/worker/crm/:id/products/create" element={<ProductProfile />} />
                <Route
                    path="/lk/worker/crm/:id/products/:productID/edit"
                    element={<ProductProfile />}
                />
                <Route
                    path="/lk/worker/crm/:id/service-categories"
                    element={<ServiceCategoryList />}
                />
                <Route
                    path="/lk/worker/crm/:id/service-categories/:serviceCategoryID"
                    element={<ServiceCategoryProfile />}
                />
                <Route
                    path="/lk/worker/crm/:id/service-categories/create"
                    element={<ServiceCategoryProfile />}
                />
                <Route
                    path="/lk/worker/crm/:id/service-categories/:serviceCategoryID/edit"
                    element={<ServiceCategoryProfile />}
                />
                <Route path="/lk/worker/crm/:id/services" element={<ServiceList />} />
                <Route
                    path="/lk/worker/crm/:id/services/nav/:serviceCategoryId"
                    element={<ServiceList />}
                />
                <Route path="/lk/worker/crm/:id/services/:serviceID" element={<ServiceProfile />} />
                <Route path="/lk/worker/crm/:id/services/create" element={<ServiceProfile />} />
                <Route
                    path="/lk/worker/crm/:id/services/:serviceID/edit"
                    element={<ServiceProfile />}
                />
                <Route path="/lk/worker/crm/:id/task-categories" element={<TaskCategoryList />} />
                <Route
                    path="/lk/worker/crm/:id/task-categories/:taskCategoryID"
                    element={<TaskCategoryProfile useCase={TaskUseCases.Profile} />}
                />
                <Route
                    path="/lk/worker/crm/:id/task-categories/create"
                    element={<TaskCategoryProfile useCase={TaskUseCases.Create} />}
                />
                <Route
                    path="/lk/worker/crm/:id/task-categories/:taskCategoryID/edit"
                    element={<TaskCategoryProfile useCase={TaskUseCases.Edit} />}
                />
                <Route path="/lk/worker/crm/:id/tasks" element={<TaskList />} />
                <Route path="/lk/worker/crm/:id/tasks/nav/:taskCategoryId" element={<TaskList />} />
                <Route
                    path="/lk/worker/crm/:id/tasks/create"
                    element={<TaskProfile useCase={TaskUseCases.Create} />}
                />
                <Route
                    path="/lk/worker/crm/:id/tasks/:taskID"
                    element={<TaskProfile useCase={TaskUseCases.Profile} />}
                />
                <Route
                    path="/lk/worker/crm/:id/tasks/:taskID/edit"
                    element={<TaskProfile useCase={TaskUseCases.Edit} />}
                />
                <Route path="/lk/worker/crm/:id/orders" element={<OrderList />} />
                <Route path="/lk/worker/crm/:id/orders/create" element={<OrderCreate />} />
                <Route path="/lk/worker/crm/:id/orders/:orderID" element={<OrderProfile />} />
                {/** Временное решение для демонстрации изменений URI */}
                <Route
                    path="/lk/worker/crm/:id/orders/:orderID/:worksheetName"
                    element={<OrderProfile />}
                />
                {/** */}
                <Route path="/lk/worker/crm/:id/orders/:orderID/edit" element={<OrderEdit />} />
                <Route path="/lk/worker/crm/:id/logs" element={<LogList />} />
                <Route path="/lk/worker/crm/:id/transactions" element={<TransactionList />} />
                <Route path="/lk/worker/crm/:id/settings" element={<Settings />} />
                <Route
                    path="/lk/worker/crm/:id/settings/:sectionName"
                    element={<CustomizableSection />}
                />
                <Route
                    path="/lk/worker/crm/:id/settings/:sectionName/worksheets"
                    element={<Worksheets />}
                />
                <Route
                    path="/lk/worker/crm/:id/settings/:sectionName/worksheets/:worksheetId"
                    element={<WorksheetSetting />}
                />

                <Route path="/lk/admin" element={<Administrator />} />
                <Route path="/lk/admin/:sectionCode" element={<Section />} />
            </Routes>
        );
    }

    return (
        <div>
            <ApelsinHeader />
            <div id="apelsin-app-content">{getBody()}</div>
            <ApelsinFooter />
        </div>
    );
};

export { App };
