import { ChangeEvent, memo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useReactive, useToggle } from 'ahooks';
import { Button, Col, Row, Space } from 'antd';
import { map } from 'lodash';

import RenderIf from '@common/RenderIf';
import { faMicrophone, faPaperPlane, faPause, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStopwatch from '@organisms/orders/profile/common/hooks/useStopwatch';
import { acceptedFormats } from '@organisms/orders/profile/components/orderComment/common/constants';
import { getNumberOfSentAudioFiles } from '@organisms/orders/profile/components/orderComment/common/helpers/getNumberOfSentAudioFiles';
import { getNumberOfUnsentAudioFiles } from '@organisms/orders/profile/components/orderComment/common/helpers/getNumberOfUnsentAudioFiles';
import { getComments } from '@organisms/orders/profile/components/orderComment/common/redux/selectors';
import { FileType } from '@organisms/orders/profile/components/orderComment/common/types';

const microUsingStartedMp3Link = 'https://free-sound-effects.net/mp3/03/free-sound-1674743345.mp3';

interface Props {
    isCommentReadyToSend: boolean;
    sendComment: () => void;
    toggleTextArea: () => void;
    setFile: (file: FileType) => void;
    files: any[];
}

export const CommentBottomPanel: React.FC<Props> = memo(
    ({ isCommentReadyToSend, sendComment, toggleTextArea, setFile, files }) => {
        const state = useReactive({
            mediaStream: null,
            isPaused: false,
        });

        const { data: comments } = useSelector(getComments);

        const [isRecording, { toggle: toggleIsRecording }] = useToggle(false);
        const { minutes, seconds } = useStopwatch({
            isRunning: isRecording,
            isPaused: state.isPaused,
        });

        const mediaRecorder = useRef(null);
        const vibrate = navigator.vibrate;

        const generateId = () => Math.floor(Math.random() * 9000) + 1000;

        const vibrateDevice = () => {
            if (!vibrate) return;
            vibrate.call(navigator, [100]);
        };

        const getAudioFileName = (sentFilesNumber, unsentFilesNumber) =>
            `Аудио ${(unsentFilesNumber + sentFilesNumber + 1).toString().padStart(3, '0')}`;

        const startRecording = (e) => {
            e.preventDefault();
            vibrateDevice();
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    const audio = new Audio(microUsingStartedMp3Link);
                    audio.volume = 0.5;
                    audio.play();
                    state.mediaStream = stream;
                    const mediaRecorderInstance = new MediaRecorder(stream);
                    mediaRecorderInstance.ondataavailable = (e) => {
                        if (e.data.size > 0) {
                            const sentFilesNumber = getNumberOfSentAudioFiles(comments);
                            const unsentFilesNumber = getNumberOfUnsentAudioFiles(files);
                            const name = getAudioFileName(sentFilesNumber, unsentFilesNumber);

                            const blob = new Blob([e.data], { type: 'audio/wav' });
                            const id = generateId();
                            setFile({
                                id,
                                name,
                                extension: 'audio',
                                blob,
                            });
                        }
                    };
                    mediaRecorderInstance.start();
                    mediaRecorder.current = mediaRecorderInstance;
                    toggleIsRecording();
                })
                .catch(console.error);
        };

        const pauseRecording = (e) => {
            e.preventDefault();
            if (!mediaRecorder.current && state.isPaused) return;
            mediaRecorder.current.pause();
            state.isPaused = true;
        };

        const resumeRecording = (e) => {
            e.preventDefault();
            if (!mediaRecorder.current && !state.isPaused) {
                return;
            }
            mediaRecorder.current.resume();
            state.isPaused = false;
        };

        const stopRecording = (e) => {
            e.preventDefault();
            if (!mediaRecorder.current && !isRecording) return;

            mediaRecorder.current.stop();
            toggleIsRecording();

            if (state.mediaStream) {
                state.mediaStream.getTracks().forEach((track) => {
                    track.stop();
                });
                state.mediaStream = null;
            }
            vibrateDevice();
            state.isPaused = false;
        };

        const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files;
            if (!files) return;
            const filesArray = Array.from(files);
            map(filesArray, (file) => {
                const blob = new Blob([file], { type: file.type });
                const id = generateId();

                setFile({
                    id,
                    name: file.name,
                    extension: file.name.match(/\.([^.]+)$/)[1],
                    blob,
                });
            });
            e.target.value = null;
        };

        return (
            <>
                <RenderIf condition={!isRecording}>
                    <Row
                        className="comment-block"
                        justify={'space-between'}
                        align={'middle'}
                        style={{ marginTop: '15px' }}
                    >
                        <Col>
                            <label
                                htmlFor="fileInput"
                                className="custom-file-upload comment-type-select"
                            >
                                +Файл
                                <input
                                    id="fileInput"
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    accept={acceptedFormats}
                                />
                            </label>
                        </Col>
                        <Col className="types-divider"></Col>
                        <Col onClick={toggleTextArea}>
                            <div className="comment-type-select">+Комментарий</div>
                        </Col>
                        <Col className="types-divider"></Col>
                        <Col className="voice-btn">
                            <Button
                                onClick={startRecording}
                                shape="circle"
                                className="comment-type-select"
                            >
                                <span className="comment-type-select">+</span>
                                {<FontAwesomeIcon icon={faMicrophone} color="orange" size="lg" />}
                            </Button>
                            <RenderIf condition={isCommentReadyToSend}>
                                <div style={{ width: '70px' }}></div>
                                <Button onClick={sendComment} className="send-btn">
                                    <FontAwesomeIcon icon={faPaperPlane} color="orange" size="2x" />
                                </Button>
                            </RenderIf>
                        </Col>
                    </Row>
                </RenderIf>
                <RenderIf condition={isRecording}>
                    <Row
                        className="comment-block"
                        justify={'space-between'}
                        align={'middle'}
                        style={{ marginTop: '10px' }}
                    >
                        <Col>
                            <div className="stopwatch-display">
                                <h3>Запись аудио</h3>
                                <h2>
                                    {minutes}:{seconds}
                                </h2>
                            </div>
                        </Col>
                        <Col className="voice-btn">
                            <Space>
                                <RenderIf condition={!state.isPaused}>
                                    <Button
                                        onClick={pauseRecording}
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faPause}
                                                color="orange"
                                                size="lg"
                                            />
                                        }
                                    ></Button>
                                </RenderIf>
                                <RenderIf condition={state.isPaused}>
                                    <Button
                                        onClick={resumeRecording}
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faMicrophone}
                                                color="orange"
                                                size="lg"
                                            />
                                        }
                                    ></Button>
                                </RenderIf>
                                <Button
                                    onClick={stopRecording}
                                    icon={
                                        <FontAwesomeIcon icon={faStop} color="orange" size="lg" />
                                    }
                                ></Button>
                            </Space>
                        </Col>
                    </Row>
                </RenderIf>
            </>
        );
    }
);
