import { initialTaskCategories, initialTasks } from "@components/tasks/common/data";
import { TTask, TTaskCategory } from "@components/tasks/common/types";
import { makeAutoObservable } from "mobx";
import moment from "moment";
import { CategoryParams, TaskParams } from "types/getParams";

class TaskStore {
    private tasks: TTask[] = [];
    private categories: TTaskCategory[] = [];

    constructor() {
        makeAutoObservable(this);
        this.tasks = [...initialTasks];
        this.categories = [...initialTaskCategories];
    }

    // Технические
    getCat(crm_id: number, id?: number) {
        return this.categories.find(cat => cat.crm_id == crm_id && cat.id == id);
    }

    mapIncludedCategories(crmId: number, categoryId?: number): number[] {
        let iCat = this.categories.filter(c => c.crm_id == crmId && c.parent_task_category_id == categoryId).map(c => c.id);
        for (let i = 0; i < iCat.length; i++) {
            let nextCat = this.categories.filter(c => c.crm_id == crmId && c.parent_task_category_id == iCat[i]).map(c => c.id);
            iCat.push(...nextCat);
        }
        return iCat;
    }

    recountCategories(crmId: number) {
        let cats = this.mapIncludedCategories(crmId, null);
        this.categories = this.categories.map(c => {
            if (cats.includes(c.id)) {
                return { 
                    ...c, 
                    task_count: this.tasks.filter(t => t.task_category_id == c.id).length 
                };
            } else {
                return c;
            }
        })
    }

    // Сектор задач
    totalTasks() {
        return this.tasks.length;
    }

    saveTask(task: TTask) {
        console.log(task);
        let cat = this.getCat(task.crm_id, task.task_category_id);
        if (task == undefined) {
            return {
                errorCode: "NotFound",
                errorMessages: ["Не найдена категория"],
                statusCode: 404
            };
        }
        let newId = 0;
        if (this.tasks.length > 0) {
            for (let i = 0; i < this.tasks.length; i++) {
                if (this.tasks[i].id >= newId) newId = this.tasks[i].id + 1;
            }
        }
        const newTask: TTask = { ...task, id: newId };
        this.tasks.push(newTask);
        this.recountCategories(task.crm_id);
        return {
            data: {
                data: {...newTask, category: cat},
            },
            statusCode: 200,
        };
    }

    getTask(crm_id: number, id: number) {
        let task = this.tasks.find(t => t.id == id && t.crm_id == crm_id);
        if (task == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Задача не найдена"],
                statusCode: 404
            };
        }
        else {
            return {
                data: {
                    data: {...task, category: this.getCat(task.crm_id, task.task_category_id)}
                },
                statusCode: 200
            };
        }
    }

    getTaskList(params: TaskParams, tab: string) {
        let targetList = [];
        // filter
        for (let i = 0; i < this.tasks.length; i++) {
            let target = this.tasks[i];
            if (params.crm_id != target.crm_id) continue;
            // deleted = 'all' выполняет роль flatMap
            if (params.filters.deleted != 'all') {
                if ((params.category_id == '' && target.task_category_id != null) || (params.category_id != '' && params.category_id != target.task_category_id)) continue;
            }
            if (params.filters.created_at) {
                const createdFrom = params.filters.created_at[0];
                const createdFromDate = createdFrom == "" || createdFrom == null ? undefined : moment(createdFrom);
                const createdTo = params.filters.created_at[1];
                const createdToDate = createdTo == "" || createdTo == null ? undefined : moment(createdTo);
                const taskCreated = moment(target.created_at);
                if (!(createdFromDate == undefined && createdToDate == undefined) && !taskCreated.isBetween(createdFromDate, createdToDate, undefined, '[]')) continue;
            }
            if (params.query != null && params.query != "" && !target.name.toLowerCase().includes(params.query.toLowerCase())) continue;
            if (tab == 'available' && target.deleted != null) continue;
            if (tab == 'deleted' && target.deleted == null) continue;
            targetList.push({ ...this.tasks[i] });
        }
        // sorter
        const sortMod = params.sort_direction == "asc" ? 1 : -1;
        switch (params.sort_by) {
            case "created_at":
                targetList.sort((a, b) => {
                    const momentA = moment(a.created_at);
                    const momentB = moment(b.created_at);
                    if (momentA.isBefore(momentB)) {
                        return -1 * sortMod;
                    }
                    if (momentA.isAfter(momentB)) {
                        return 1 * sortMod;
                    }
                    return 0;
                });
                break;
            case "duration":
                targetList.sort((a, b) => {
                    return (a.duration - b.duration) * sortMod;
                });
                break;
            case "name":
                targetList.sort((a, b) => {
                    const nameA = a.name.toUpperCase(); 
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1 * sortMod;
                    }
                    if (nameA > nameB) {
                        return 1 * sortMod;
                    }
                    return 0;
                });
                break;
        }
        // пагинация
        const from = (params.page - 1) * params.per_page;
        return {
            data: {
                data: [...targetList
                    .slice(from, from + params.per_page)
                    .map(t => { return {
                        ...t, category: this.getCat(t.crm_id, t.task_category_id)
                    }})]
            },
            metadata: {
                current_page: params.page,
                from: from + 1,
                last_page: Math.ceil(targetList.length / params.per_page),
                per_page: params.per_page,
                to: from + params.per_page,
                total: targetList.length
            },
            statusCode: 200
        };
    }

    updateTask(task: TTask) {
        let cat = this.getCat(task.crm_id, task.task_category_id);
        if (task == undefined) {
            return {
                errorCode: "NotFound",
                errorMessages: ["Не найдена категория"],
                statusCode: 404
            };
        }
        let toUpdate = this.tasks.find(t => t.id == task.id && t.crm_id == task.crm_id);
        if (toUpdate == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Задача не найдена"],
                statusCode: 404
            };
        }
        this.tasks = this.tasks.map(t => {
            if (t.id == task.id && t.crm_id == t.crm_id) {
                let tempTask = { 
                    ...t, 
                    ...task,
                };
                toUpdate = tempTask;
                return tempTask;
            }
            return t;
        });
        this.recountCategories(task.crm_id);
        return {
            data: {
                data: {...toUpdate, category: cat},
            },
            statusCode: 200
        };
    }

    removeTask(crm_id: number, id: number) {
        let task = this.tasks.find(t => t.id == id && t.crm_id == crm_id);
        if (task == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Задача не найдена"],
                statusCode: 404
            };
        }
        let deleteMark = new Date().toISOString();
        this.tasks = this.tasks.map(t => {
            if (t.id == task.id && t.crm_id == t.crm_id) {
                return { ...t, deleted: deleteMark };
            }
            return t;
        });
        return {
            data: {
                data: { ...task, category: this.getCat(task.crm_id, task.task_category_id), deleted: deleteMark },
            },
            statusCode: 200
        };
    }

    restoreTask(crm_id: number, id: number) {
        let task = this.tasks.find(t => t.id == id && t.crm_id == crm_id);
        if (task == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Задача не найдена"],
                statusCode: 404
            };
        }
        this.tasks = this.tasks.map(t => {
            if (t.id == task.id && t.crm_id == t.crm_id) {
                return { ...t, deleted: null };
            }
            return t;
        });
        return {
            data: {
                data: { ...task, category: this.getCat(task.crm_id, task.task_category_id), deleted: null },
            },
            statusCode: 200
        };
    }

    // Сектор категорий
    totalTaskCategories() {
        return this.categories.length;
    }

    saveTaskCategory(
        name: string,
        crmId: number,
        parentCatId?: number,
        picture?: File
    ) {
        let newId = 0;
        if (this.categories.length > 0) {
            for (let i = 0; i < this.categories.length; i++) {
                if (this.categories[i].id >= newId) newId = this.categories[i].id + 1;
            }
        }
        let newCat = {
            id: newId,
            parent_task_category_id: parentCatId,
            name: name,
            picture: picture,
            crm_id: crmId,
            task_count: 0,
            quantity_total: 0,
            created_at: new Date().toISOString(),
            deleted: null,
        };
        this.categories.push(newCat);
        return {
            data: {
                data: {...newCat, tasks: [], categories: []},
            },
            statusCode: 200,
        };
    }

    getTaskCategory(crmId: number, id?: number) {
        let taskCat = this.categories.find(t => t.id == id && t.crm_id == crmId);
        if (taskCat == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Категория не найдена"],
                statusCode: 404
            };
        }
        else {
            let innerTasks = this.tasks.filter(t => t.task_category_id == id && t.crm_id == crmId);
            let innerCats = this.categories.filter(c => c.parent_task_category_id == id && c.crm_id == crmId);
            return {
                data: {
                    data: {
                        ...taskCat,
                        tasks: innerTasks,
                        categories: innerCats,
                    }
                },
                statusCode: 200
            };
        }
    }

    getTaskCategoryList(params: CategoryParams) {
        let targetList = [];
        // filter
        for (let i = 0; i < this.categories.length; i++) {
            let target = this.categories[i];
            if (params.crm_id != target.crm_id) continue;
            // deleted == 'all' используется как аналог flatMap, но с фильтрами
            if (params.filters.deleted != "all") {
                if ((params.category_id == '' && target.parent_task_category_id != null) || (params.category_id != '' && params.category_id != target.parent_task_category_id)) continue;
            }
            if (params.filters.created_at) {
                const createdFrom = params.filters.created_at[0];
                const createdFromDate = createdFrom == "" || createdFrom == null ? undefined : moment(createdFrom);
                const createdTo = params.filters.created_at[1];
                const createdToDate = createdTo == "" || createdTo == null ? undefined : moment(createdTo);
                const taskCreated = moment(target.created_at);
                if (!(createdFromDate == undefined && createdToDate == undefined) && !taskCreated.isBetween(createdFromDate, createdToDate, undefined, '[]')) continue;
            }
            if (params.query != null && params.query != "" && !target.name.toLowerCase().includes(params.query.toLowerCase())) continue;
            if ((params.filters.deleted == 'null' || params.filters.deleted == 'all')&& target.deleted != null) continue;
            if (params.filters.deleted == 'only' && target.deleted == null) continue;
            targetList.push({ ...this.categories[i] });
        }
        // sorter
        const sortMod = params.sort_direction == "asc" ? 1 : -1;
        switch (params.sort_by) {
            case "created_at":
                targetList.sort((a, b) => {
                    const momentA = moment(a.created_at);
                    const momentB = moment(b.created_at);
                    if (momentA.isBefore(momentB)) {
                        return -1 * sortMod;
                    }
                    if (momentA.isAfter(momentB)) {
                        return 1 * sortMod;
                    }
                    return 0;
                });
                break;
            case "name":
                targetList.sort((a, b) => {
                    const nameA = a.name.toUpperCase(); 
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1 * sortMod;
                    }
                    if (nameA > nameB) {
                        return 1 * sortMod;
                    }
                    return 0;
                });
                break;
        }
        // пагинация
        const from = (params.page - 1) * params.per_page;
        return {
            data: {
                data: [...targetList
                    .slice(from, from + params.per_page)
                    .map(cat => { return {
                        ...cat, 
                        tasks: this.tasks.filter(t => t.task_category_id == cat.id && t.crm_id == params.crm_id),
                        categories: this.categories.filter(c => c.parent_task_category_id == cat.id && c.crm_id == params.crm_id),
                    }})
                ]
            },
            metadata: {
                current_page: params.page,
                from: from + 1,
                last_page: Math.ceil(targetList.length / params.per_page),
                per_page: params.per_page,
                to: from + params.per_page,
                total: targetList.length
            },
            statusCode: 200
        };
    }

    updateTaskCategory(
        name: string,
        crmId: number,
        id?: number,
        parentId?: number,
        picture?: File
    ) {
        let toUpdate = this.categories.find(cat => cat.id == id && cat.crm_id == crmId);
        if (toUpdate == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Категория не найдена"],
                statusCode: 404
            };
        }
        let innerTasks = this.tasks.filter(t => t.task_category_id == id && t.crm_id == crmId);
        let innerCats = this.categories.filter(c => c.parent_task_category_id == id && c.crm_id == crmId);
        if (innerTasks.length > 0 || innerCats.length > 0) {
            return {
                errorCode: "NotEmpty",
                errorMessages: ["В категории остались задачи или другие категории"],
                statusCode: 500,
            }
        }
        this.categories = this.categories.map(c => {
            if (c.id == id && c.crm_id == crmId) {
                let tempCat = { 
                    ...c, 
                    name: name,
                    crm_id: crmId,
                    picture: picture,
                    parent_task_category_id: parentId,
                };
                toUpdate = tempCat;
                return tempCat;
            }
            return c;
        });
        innerTasks = this.tasks.filter(t => t.task_category_id == id && t.crm_id == crmId);
        innerCats = this.categories.filter(c => c.parent_task_category_id == id && c.crm_id == crmId);
        return {
            data: {
                data: {
                    ...toUpdate,
                    tasks: innerTasks,
                    categories: innerCats,
                },
            },
            statusCode: 200
        };
    }

    removeTaskCategory(crmId: number, id?: number) {
        let cat = this.categories.find(c => c.id == id && c.crm_id == crmId);
        if (cat == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Категория не найдена"],
                statusCode: 404
            };
        }
        let innerTasks = this.tasks.filter(t => t.task_category_id == id && t.crm_id == crmId);
        let innerCats = this.categories.filter(c => c.parent_task_category_id == id && c.crm_id == crmId);
        if (innerCats.length > 0 || innerTasks.length > 0) {
            return {
                errorCode: "NotEmpty",
                errorMessages: ["В категории остались задачи или другие категории"],
                statusCode: 500
            };
        }
        let deleteMark = new Date().toISOString();
        this.categories = this.categories.map(c => {
            if (c.id == id && c.crm_id == crmId) {
                return { ...c, deleted: deleteMark };
            }
            return c;
        });
        return {
            data: {
                data: { ...cat, tasks: [], categories: [], deleted: deleteMark },
            },
            statusCode: 200
        };
    }

    restoreTaskCategory(crmId: number, id?: number) {
        let cat = this.categories.find(c => c.id == id && c.crm_id == crmId);
        if (cat == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Категория не найдена"],
                statusCode: 404
            };
        }
        this.categories = this.categories.map(c => {
            if (c.id == id && c.crm_id == crmId) {
                return { ...c, deleted: null };
            }
            return c;
        });
        return {
            data: {
                data: { ...cat, tasks: [], categories: [], deleted: null },
            },
            statusCode: 200
        };
    }

    getTaskCategoryTaskList(crmId: number, categoryId?: number) {
        let cat = this.categories.find(c => c.id == categoryId && c.crm_id == crmId);
        if (cat == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Категория не найдена"],
                statusCode: 404
            };
        }
        let innerTasks = this.tasks
            .filter(t => t.task_category_id == categoryId && t.crm_id == crmId)
            .map(t => { return { ...t, category: this.getCat(t.crm_id, t.task_category_id) }});
        return {
            data: {
                data: [...innerTasks],
            },
            statusCode: 200
        };
    }

    getAllIncludedTaskCategories(crmId: number, categoryId?: number) {
        let cat = this.categories.find(c => c.id == categoryId && c.crm_id == crmId);
        if (cat == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Категория не найдена"],
                statusCode: 404
            };
        }
        let includedCategories = this.mapIncludedCategories(crmId, categoryId);
        return {
            data: {
                data: [...includedCategories]
            },
            statusCode: 200
        };
    }

    getTaskCatalogInfo(crmId: number, categoryId?: number) {
        let cat = this.categories.find(c => c.id == categoryId && c.crm_id == crmId);
        if (cat == undefined) {
            return {
                errorCode: "NotFound",
                errorMesssages: ["Категория не найдена"],
                statusCode: 404
            };
        }
        let includedCategories = this.mapIncludedCategories(crmId, categoryId);
        let totalPrice = 0;
        let tasks = this.tasks.filter(t => t.crm_id == crmId && t.task_category_id == categoryId);
        for (let i = 0; i < includedCategories.length; i++) {
            let nextCatTasks = this.tasks.filter(t => t.crm_id == crmId && t.task_category_id == categoryId);
            tasks.push(...nextCatTasks);
        }
        for (let j = 0; j < tasks.length; j++) {
            totalPrice += tasks[j].reward;
        }
        return {
            data: {
                price: totalPrice,
                quantity: tasks.length
            },
            statusCode: 200
        }
    }

    getTaskCategoryFlatMap(crmId: number) {
        return this.categories
            .filter(c => c.crm_id == crmId)
            .map(c => {
                return { 
                    ...c, 
                    tasks: this.tasks.filter(t => t.crm_id == crmId && t.task_category_id == c.id),
                    categories: this.categories.filter(c => c.crm_id == crmId && c.parent_task_category_id == c.id)
                };
            });
    }
}

export { TaskStore };