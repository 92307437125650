import { ChangeEvent } from 'react';
import Highlighter from 'react-highlight-words';

import { Checkbox, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TOrderField } from 'types/worksheets/worksheetFields';

import { PhotoCheckbox } from './components';
import {
    HandleChangeModalFieldName,
    OnChangeField,
    OnFieldNameChange,
    TOrderFieldState,
    handleChangePivotValue,
} from './types';

import './style.scss';

const { TextArea } = Input;

export const getColumns = (
    onFieldNameChange: OnFieldNameChange,
    onChangeField: OnChangeField,
    handleSaveOnBlur: () => void,
    handleSavePivotsOnFocus: () => void,
    setEditFieldId: (id: number) => void,
    searchValue: string,
    editableFieldId: number,
    isSearchModeActive: boolean
): ColumnsType<TOrderField> => {
    return [
        {
            title: <div className="column-title">Название поля</div>,
            dataIndex: 'name',
            align: 'center',
            width: '45%',
            render: (value, record, index) => {
                return !isSearchModeActive || record.id === editableFieldId ? (
                    <TextArea
                        key={record.id}
                        rows={Math.ceil(value / 33)}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            onFieldNameChange(index, record.id, e.target.value);
                        }}
                        value={value}
                        onBlur={handleSaveOnBlur}
                        onFocus={handleSavePivotsOnFocus}
                        autoFocus={record.id === editableFieldId}
                        autoSize={true}
                    />
                ) : (
                    <div
                        className="searchable-text-container"
                        onClick={() => setEditFieldId(record.id)}
                    >
                        <Highlighter
                            searchWords={searchValue.split(' ')}
                            autoEscape={true}
                            textToHighlight={value}
                        />
                    </div>
                );
            },
        },
        {
            title: <div className="column-title">Использовать поле</div>,
            dataIndex: ['pivot', 'is_used'],
            align: 'center',
            render: (value, record, index) => {
                return !record.is_photo ? (
                    <Checkbox
                        value={value}
                        checked={Boolean(value)}
                        onChange={() => onChangeField('is_used', index, value)}
                    />
                ) : (
                    <PhotoCheckbox
                        onChange={() => onChangeField('is_used', index, value)}
                        checked={Boolean(value)}
                    />
                );
            },
        },
        {
            title: <div className="column-title">Обязательно для заполнения</div>,
            dataIndex: ['pivot', 'is_required'],
            align: 'center',
            render: (value, record, index) => {
                return (
                    <Checkbox
                        value={value}
                        disabled={!record.pivot.is_used}
                        checked={Boolean(value)}
                        onChange={() => onChangeField('is_required', index, value)}
                    />
                );
            },
        },
        {
            title: <div className="column-title">{'Сохранять в шаблон "объекта заказа"'}</div>,
            dataIndex: ['pivot', 'is_saved'],
            align: 'center',
            render: (value, record, index) => {
                return (
                    <Checkbox
                        value={value}
                        disabled={!record.pivot.is_used}
                        checked={Boolean(value)}
                        onChange={() => {
                            onChangeField('is_saved', index, value);
                        }}
                    />
                );
            },
        },
    ];
};

export const getCreatingModalColumn = (
    handleChangeFieldName: HandleChangeModalFieldName,
    handleChangePivotValue: handleChangePivotValue,
    isPhotoField: boolean
): ColumnsType<TOrderFieldState> => {
    return [
        {
            title: <div className="column-title">Название поля</div>,
            dataIndex: 'name',
            align: 'center',
            width: '45%',
            render: (value) => {
                return (
                    <TextArea
                        rows={Math.ceil(value.length / 33)}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            handleChangeFieldName(e.target.value);
                        }}
                        value={value}
                    />
                );
            },
        },
        {
            title: <div className="column-title">Использовать поле</div>,
            dataIndex: ['pivot', 'is_used'],
            align: 'center',
            render: (value) => {
                return !isPhotoField ? (
                    <Checkbox
                        checked={Boolean(value)}
                        onChange={() => handleChangePivotValue('is_used', value)}
                    />
                ) : (
                    <PhotoCheckbox
                        onChange={() => handleChangePivotValue('is_used', value)}
                        checked={Boolean(value)}
                    />
                );
            },
        },
        {
            title: <div className="column-title">Обязательно для заполнения</div>,
            dataIndex: ['pivot', 'is_required'],
            align: 'center',
            render: (value, record) => {
                return (
                    <Checkbox
                        disabled={!record.pivot.is_used}
                        checked={value}
                        onChange={() => handleChangePivotValue('is_required', value)}
                    />
                );
            },
        },
        {
            title: <div className="column-title">{'Сохранять в шаблон "объекта заказа"'}</div>,
            dataIndex: ['pivot', 'is_saved'],
            align: 'center',
            render: (value, record) => {
                return (
                    <Checkbox
                        disabled={!record.pivot.is_used}
                        checked={value}
                        onChange={() => handleChangePivotValue('is_saved', value)}
                    />
                );
            },
        },
    ];
};
