import { Button, Col, Modal, Row, Slider, Radio } from 'antd';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, MouseEventHandler, useRef } from 'react';
import { useReactive } from 'ahooks';
import { observer } from 'mobx-react';
import type { SliderMarks } from 'antd/es/slider';
import type { RadioChangeEvent } from 'antd';

type DurationProps = {
    isModalOpen: boolean;
    onCancel: () => void;
    defaultValue: number;
    totalTime: boolean;
    onOk: (duration: number, totalTime: boolean) => void;
    showTimeCountMethod?: boolean;
    showMonths?: boolean;
    showDays?: boolean;
    showHours?: boolean;
    showMinutes?: boolean;
};

type TState = {
    duration: number;
    totalTime: boolean;
};

export const DurationPicker = observer(
    ({
        isModalOpen,
        onCancel,
        defaultValue,
        totalTime,
        onOk,
        showTimeCountMethod = true,
        showMonths = true,
        showDays = true,
        showHours = true,
        showMinutes = true,
    }: DurationProps) => {
        const plusRef = useRef(null);
        const minusRef = useRef(null);
        const [minutes, setMinutes] = useState<number>(0);
        const [hours, setHours] = useState<number>(0);
        const [days, setDays] = useState<number>(0);
        const [months, setMonths] = useState<number>(0);
        const state = useReactive<TState>({
            duration: defaultValue,
            totalTime: totalTime,
        });

        const onRadioChange = (e: RadioChangeEvent) => {
            state.totalTime = e.target.value;
        };
        type TProps = {
            title: string;
            key: string;
            defaultValue: number;
            maxValue: number;
            step: number;
            marks: SliderMarks;
            onMinus: MouseEventHandler<HTMLElement>;
            onPlus: MouseEventHandler<HTMLElement>;
            onAfterChange: (value: number) => void;
        };

        function durationToMinutes() {
            const convertedMonths = +months * (30 * 24 * 60);
            const convertedDays = +days * (24 * 60);
            const convertedHours = +hours * 60;

            state.duration = convertedMonths + convertedDays + convertedHours + +minutes;
        }

        useEffect(() => {
            durationToMinutes();
        }, [minutes, hours, days, months]);

        function durationToDate() {
            state.duration = defaultValue;
            // Calculate the number of months
            setMonths(Math.floor(state.duration / 43200));

            // Calculate the number of days
            setDays(Math.floor((state.duration % 43200) / 1440));

            // Calculate the number of hours
            setHours(Math.floor((state.duration % 1440) / 60));

            // Calculate the number of minutes
            setMinutes(state.duration % 60);
        }

        function setMinus(setM, val) {
            if (+val >= 1) setM((prevValue) => --prevValue);
        }
        function setPlus(setP, val, maxVal) {
            if (+val < maxVal) setP((prevValue) => ++prevValue);
        }

        const durationInputs = [
            {
                title: 'минуты',
                key: 'minutes',
                value: minutes,
                maxValue: 60,
                step: 1,
                marks: {
                    0: '0',
                    5: ' ',
                    10: '10',
                    15: ' ',
                    20: '20',
                    25: ' ',
                    30: '30',
                    35: ' ',
                    40: '40',
                    45: ' ',
                    50: '50',
                    55: ' ',
                    60: '60',
                },
                onAfterChange: setMinutes,
                onMinus: () => {
                    setMinus(setMinutes, minutes);
                },
                onPlus: () => setPlus(setMinutes, minutes, 60),
            },
            {
                title: 'часы',
                key: 'hours',
                value: hours,
                maxValue: 24,
                step: 1,
                marks: {
                    0: '0',
                    3: '3',
                    6: '6',
                    9: '9',
                    12: '12',
                    15: '15',
                    18: '18',
                    21: '21',
                    24: '24',
                },
                onAfterChange: setHours,
                onMinus: () => {
                    setMinus(setHours, hours);
                },
                onPlus: () => setPlus(setHours, hours, 24),
            },
            {
                title: 'дни',
                key: 'days',
                value: days,
                maxValue: 30,
                step: 1,
                marks: {
                    0: '0',
                    5: '5',
                    10: '10',
                    15: '15',
                    20: '20',
                    25: '25',
                    30: '30',
                },
                onAfterChange: setDays,
                onMinus: () => {
                    setMinus(setDays, days);
                },
                onPlus: () => setPlus(setDays, days, 30),
            },
            {
                title: 'месяцы',
                key: 'months',
                value: months,
                maxValue: 12,
                step: 1,
                marks: {
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                    11: '11',
                    12: '12',
                },
                onAfterChange: setMonths,
                onMinus: () => {
                    setMinus(setMonths, months);
                },
                onPlus: () => setPlus(setMonths, months, 12),
            },
        ];

        useEffect(() => {
            durationToDate();
        }, [defaultValue]);

        const DurationInput = ({
            title,
            key,
            defaultValue,
            maxValue,
            step,
            onAfterChange,
            onMinus,
            onPlus,
            marks,
        }: TProps) => {
            return (
                <Col key={key} className="input-section">
                    <Row className="input-block">
                        <Button
                            className="btn-dutarion"
                            type="primary"
                            ref={minusRef}
                            disabled={defaultValue == 0 ? true : false}
                            icon={<FontAwesomeIcon icon={faMinus} />}
                            onClick={onMinus}
                        />
                        <p className="time-header">
                            {title}: {defaultValue}{' '}
                        </p>
                        <Button
                            className="btn-dutarion"
                            type="primary"
                            icon={<FontAwesomeIcon icon={faPlus} />}
                            ref={plusRef}
                            onClick={onPlus}
                            disabled={defaultValue == maxValue ? true : false}
                        />
                        <Slider
                            min={0}
                            max={maxValue}
                            className="inputRange"
                            step={step}
                            defaultValue={defaultValue}
                            onAfterChange={onAfterChange}
                            marks={marks}
                            range={false}
                        />
                    </Row>
                </Col>
            );
        };
        return (
            <Modal
                className="duration-picker"
                title=""
                open={isModalOpen}
                cancelText="Отмена"
                onOk={() => {
                    onOk(state.duration, state.totalTime);
                }}
                onCancel={onCancel}
                wrapClassName="duration-modal"
            >
                <Col className="input-container">
                    <Row className="duration-title-section">
                        <h2 className="duration-title">Продолжительность: </h2>
                        <Row
                            className="duration-display"
                            style={!(months || days || hours || minutes) && { display: 'none' }}
                        >
                            {months ? months + ` м.` : ''}
                            {days ? (months ? ` / ` : '') + days + ` д.` : ''}
                            {hours ? (days || months ? ` / ` : '') + hours + ` ч.` : ''}
                            {minutes
                                ? (hours || days || months ? ` / ` : '') + minutes + ` мин.`
                                : ''}
                        </Row>
                    </Row>
                    {durationInputs.map((obj) => {
                        if (
                            (obj.key == 'minutes' && showMinutes) ||
                            (obj.key == 'hours' && showHours) ||
                            (obj.key == 'days' && showDays) ||
                            (obj.key == 'months' && showMonths)
                        ) {
                            return (
                                <DurationInput
                                    key={obj.key}
                                    defaultValue={obj?.value}
                                    title={obj.title}
                                    marks={obj.marks}
                                    maxValue={obj.maxValue}
                                    step={obj.step}
                                    onAfterChange={obj.onAfterChange}
                                    onMinus={obj.onMinus}
                                    onPlus={obj.onPlus}
                                />
                            );
                        } else return null;
                    })}
                    {showTimeCountMethod && (
                        <Radio.Group
                            onChange={onRadioChange}
                            value={state?.totalTime ? true : false}
                        >
                            <Col>
                                <Radio className="duration-radio" value={false}>
                                    Учитывать только рабочее время
                                </Radio>
                            </Col>
                            <Col>
                                <Radio className="duration-radio" value={true}>
                                    Учитывать общее время
                                </Radio>
                            </Col>
                        </Radio.Group>
                    )}
                </Col>
            </Modal>
        );
    }
);
