import { useState } from 'react';

import { useToggle } from 'ahooks';
import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';

import RenderIf from '@common/RenderIf';
import { FilesViewer } from '@components/common/filesPreview';
import { faCancel, faDownload, faEdit, faReply, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Avatar, FileContent, HeaderInfo, Sender, TextContent, Topic } from './blocks';

import './commentItem.scss';

export const CommentItem = ({ com }) => {
    const [isModalOpen, { toggle: toggleIsModalOpen }] = useToggle(false);
    const [openFileId, setOpenFileId] = useState(null);

    const handleOpenFile = (id) => {
        setOpenFileId(id);
        toggleIsModalOpen();
    };

    const funcMenuData = [
        {
            key: 'download',
            label: 'Скачать',
            icon: <FontAwesomeIcon icon={faDownload} color="" />,
            onClick: () => {},
        },
        {
            key: 'reply',
            label: 'Ответить',
            icon: <FontAwesomeIcon icon={faReply} />,
            onClick: () => {},
        },
        {
            key: 'share',
            label: 'Переслать',
            icon: <FontAwesomeIcon icon={faShare} />,
            onClick: () => {},
        },
        {
            key: 'change',
            label: 'Изменить',
            icon: <FontAwesomeIcon icon={faEdit} />,
            onClick: () => {},
        },
        {
            key: 'abort',
            label: 'Отмена',
            icon: <FontAwesomeIcon icon={faCancel} />,
            onClick: () => {},
        },
    ];

    return (
        <Row className="comment-row" justify={'space-between'}>
            <Col className="comment-img" span={4}>
                <Avatar comment={com} />
            </Col>

            <Col span={20}>
                <HeaderInfo comment={com} />
                <hr className="header-separator" />
                <RenderIf condition={!isEmpty(com.body.files)}>
                    <FileContent comment={com} onOpenFile={handleOpenFile} />
                    <hr className="header-separator" />
                </RenderIf>
                <RenderIf condition={com.body.text}>
                    <TextContent comment={com} />
                </RenderIf>
            </Col>

            <FilesViewer
                data={com.body.files}
                onClose={toggleIsModalOpen}
                open={isModalOpen}
                openFileId={openFileId}
                funcMenuData={funcMenuData}
                sender={<Sender />}
                topic={<Topic />}
            />
        </Row>
    );
};
