import { Filter } from 'types/filters';

import { OrderListResp } from '@api/responseModels/order/orderListResponse';
import { UserListResp } from '@api/responseModels/user/userListResponse';
import { CRMAPIManager } from '@classes/crmApiManager';
import { SettingsManager } from '@classes/settingsManager';
import { GlobalConstants } from '@constants/global';
import { useMutation } from '@tanstack/react-query';

interface IProps {
    filter: Filter;
    profile?: 'customer' | 'worker';
    setDataList: (data: any) => void;
    setCurrentMeta: (meta: any) => void;
    getFnKey: GetFnKey;
    category_id?: number | string;
    worker_id?: number;
}

type Params = {
    crm_id: number;
    sort_by: Filter['sortBy'];
    sort_direction: Filter['sortDirection'];
    filters?: {
        created_at?: Filter['createdDates'];
        updated_at?: Filter['updatedDates'];
        deleted?: Filter['deleted'];
        profile?: Filter['profile'];
    };
    query?: string;
    page?: number;
    per_page?: number;
    category_id?: number | string;
    worker_id?: number;
};

type GetFnKey = 'getUserList' | 'getOrderList' | 'getProductList' | 'getServiceList';

export const useFetchDataList = ({
    filter,
    profile,
    setCurrentMeta,
    setDataList,
    getFnKey,
    worker_id,
}: IProps) => {
    const creds = SettingsManager.getConnectionCredentials();

    const fetchDataList = async (page, category_id) => {
        const resp = await CRMAPIManager.request<UserListResp | OrderListResp>(async (api) => {
            const params: Params = {
                crm_id: creds.crmID,
                sort_by: filter.sortBy,
                category_id,
                sort_direction: filter.sortDirection,
                filters: {
                    created_at: [...filter.createdDates],
                    updated_at: [...filter.updatedDates],
                    deleted: filter.deleted,
                    profile,
                },
                query: filter.query,
                page,
                per_page: GlobalConstants.ElementsPerPage,
                worker_id,
            };
            Object.keys(params.filters).filter(
                (key) => params.filters[key] === null && delete params.filters[key]
            );
            return await api[getFnKey](params);
        });
        return resp;
    };

    const mutation = useMutation({
        mutationFn: ({ page, categoryId }: { page?: number; categoryId?: number | string }) => {
            return fetchDataList(page, categoryId);
        },
        mutationKey: ['getFnKey'],
        onSuccess: (resp, { page }) => {
            if (resp.errorMessages) {
                setDataList([]);
                throw resp.errorMessages;
            }
            setDataList((wl) => (page === 1 ? resp.data.data : [...wl, ...resp.data.data]));
            setCurrentMeta(resp.data.meta);
        },
    });

    return {
        isLoading: mutation.isPending,
        getDataList: mutation.mutate,
    };
};
