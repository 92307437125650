import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';
import { isEmpty, map } from 'lodash';

import RenderIf from '@common/RenderIf';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FILTER_KEYS } from '@organisms/orders/profile/components/orderComment/common/constants';
import {
    resetFilterArrayItem,
    setFilterDate,
} from '@organisms/orders/profile/components/orderComment/common/redux/commentSlice';
import { getComments } from '@organisms/orders/profile/components/orderComment/common/redux/selectors';

import '../commentFilter.scss';

export const AppliedFilters = () => {
    const dispatch = useDispatch();
    const {
        filterData: { members, topics, date, files },
    } = useSelector(getComments);

    const membersList = useMemo(
        () => map(members, (mem) => `${mem.name} ${mem.surname}`).join(', '),
        [members]
    );

    const topicsList = useMemo(() => map(topics, (top) => top.name).join(', '), [topics]);

    const filesList = useMemo(() => files.join(', '), [files]);

    const deleteFilterArrayItem = (type) => {
        dispatch(resetFilterArrayItem(type));
    };

    return (
        <div className="applied-filers-wrapper">
            <RenderIf condition={!isEmpty(topics)}>
                <Row justify={'space-between'} align={'middle'}>
                    <Col>
                        <Typography.Text className="comment-filter-name">Тема: </Typography.Text>
                        <Typography.Text>{topicsList}</Typography.Text>
                    </Col>
                    <Col
                        className="comment-filter-delete"
                        onClick={() => deleteFilterArrayItem(FILTER_KEYS.TOPIC)}
                    >
                        <FontAwesomeIcon icon={faXmark} color="brown" />
                    </Col>
                </Row>
                <div className="comment-filter-divider" />
            </RenderIf>

            <RenderIf condition={!isEmpty(members)}>
                <Row justify={'space-between'} align={'middle'}>
                    <Col>
                        <Typography.Text className="comment-filter-name">
                            Исполнитель:{' '}
                        </Typography.Text>
                        <Typography.Text>{membersList}</Typography.Text>
                    </Col>
                    <Col
                        className="comment-filter-delete"
                        onClick={() => deleteFilterArrayItem(FILTER_KEYS.MEMBER)}
                    >
                        <FontAwesomeIcon icon={faXmark} color="brown" />
                    </Col>
                </Row>
                <div className="comment-filter-divider" />
            </RenderIf>

            <RenderIf condition={!isEmpty(files)}>
                <Row justify={'space-between'} align={'middle'}>
                    <Col>
                        <Typography.Text className="comment-filter-name">Файлы: </Typography.Text>
                        <Typography.Text>{filesList}</Typography.Text>
                    </Col>
                    <Col
                        className="comment-filter-delete"
                        onClick={() => deleteFilterArrayItem(FILTER_KEYS.FILE)}
                    >
                        <FontAwesomeIcon icon={faXmark} color="brown" />
                    </Col>
                </Row>
                <div className="comment-filter-divider" />
            </RenderIf>

            <RenderIf condition={!!date}>
                <Row justify={'space-between'} align={'middle'}>
                    <Col>
                        <Typography.Text className="comment-filter-name">Дата: </Typography.Text>
                        <Typography.Text>С {date} по 11.11.1111</Typography.Text>
                    </Col>
                    <Col
                        className="comment-filter-delete"
                        onClick={() => dispatch(setFilterDate(null))}
                    >
                        <FontAwesomeIcon icon={faXmark} color="brown" />
                    </Col>
                </Row>
                <div className="comment-filter-divider" />
            </RenderIf>
        </div>
    );
};
