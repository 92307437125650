import { TService } from 'types/service';

type TOrderService = {
    id: number | string | null;
    name: string;
    quantity: number | null;
    price: number | null;
    total: number | null;
};

const normalizeOrderService = (service: TService, quantity: number): TOrderService => {
    return {
        id: service.id,
        name: service.name,
        quantity: quantity,
        price: service.price,
        total: service.price * quantity,
    };
};
export { TOrderService, normalizeOrderService };
