import { MESSENGERS_BASE_URL } from '../constants';

export const shareOnTelegram = (message) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const url =
        (isMobile ? MESSENGERS_BASE_URL.TELEGRAM_MOBILE : MESSENGERS_BASE_URL.TELEGRAM_WEB) +
        encodeURIComponent(message);

    window.open(url, '_blank');
};
