import React, { SyntheticEvent } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Divider, List } from 'antd';
import { observer } from 'mobx-react-lite';
import { TMetadata } from 'types/metadata';
import { TProduct } from 'types/product';
import { TProductCategory } from 'types/productCategory';
import { TService } from 'types/service';
import { TServiceCategory } from 'types/serviceCategory';

import { ProductCard } from '@atoms/listsCard/serviceProductCards/cards/productCard';
import { ServiceCard } from '@atoms/listsCard/serviceProductCards/cards/serviceCard';
import { CategoryCard } from '@atoms/listsCard/serviceProductCards/categoryCards/categoryCard';
import { columnViewStore } from '@molecules/listViewChanger/listViewStore';
import { ShowMoreButton } from '@molecules/paginationControls/showMoreButton';

type Props = {
    avoidRootStore?: boolean;
    noQuantitySelector?: boolean;
    noNavigation?: boolean;
    productCategoryList: Array<
        TProductCategory & { products: Array<TProduct>; categories: Array<TProductCategory> }
    > | null;
    serviceCategoryList: Array<
        TServiceCategory & { services: Array<TService>; categories: Array<TServiceCategory> }
    > | null;
    productList: Array<TProduct & { category: TProductCategory }> | null;
    serviceList: Array<TService & { category: TServiceCategory }> | null;
    selectCatList: Array<{ id: number; name: string; parentId: number | null }>;
    selectElemList: Array<{ id: number; name: string; parentId: number | null }>;
    currentCategoryMeta: TMetadata;
    currentCategoryPage: number;
    handleShowMoreCategories: () => Promise<void>;
    setSelectCatList: React.Dispatch<
        React.SetStateAction<Array<{ id: number; name: string; parentId: number | null }>>
    >;
    setSelectElemList: (value: { id: number; name: string; parentId: number | null }[]) => void;
    openCategoryCard: (id: number | null, back: boolean) => void | null;
    openCard: (id: number) => void | null;
    hideQuantityInProductCard?: boolean;
    searchValue?: string;
    infiniteScrollProps?: {
        isActive: boolean;
        totalItems: number;
        itemsName?: string;
        onNext: () => void;
        height?: string;
    };
    showPermisionButtonsInCard?: boolean;
    onPermissionButtonClick?: (id: number, type: 'show' | 'update' | 'execute') => void;
};

const ServiceProductList = observer(
    ({
        avoidRootStore = false,
        noQuantitySelector = false,
        noNavigation = false,
        productCategoryList,
        serviceCategoryList,
        productList,
        serviceList,
        selectCatList,
        selectElemList,
        currentCategoryMeta,
        currentCategoryPage,
        handleShowMoreCategories,
        setSelectCatList,
        setSelectElemList,
        openCategoryCard,
        openCard,
        hideQuantityInProductCard,
        searchValue = '',
        infiniteScrollProps,
        showPermisionButtonsInCard,
        onPermissionButtonClick,
    }: Props) => {
        function handleSelect(category) {
            !selectCatList.find((sli) => sli.id == category.id)
                ? setSelectCatList((oldList) => [...oldList, category])
                : setSelectCatList((oldList) => oldList.filter((sli) => sli.id != category.id));
        }

        function handleProductSelectButtonClick(
            e: SyntheticEvent,
            productId: number,
            productName: string,
            parentId: number | null
        ) {
            e.stopPropagation();
            !selectElemList.find((sli) => sli.id == productId)
                ? setSelectElemList([
                      ...selectElemList,
                      { id: productId, name: productName, parentId },
                  ])
                : setSelectElemList(selectElemList.filter((sli) => sli.id != productId));
        }

        const dataCategoryList = productCategoryList ? productCategoryList : serviceCategoryList;
        const dataList = productList ? productList : serviceList;

        return (
            <>
                {dataCategoryList.length > 0 && (
                    <List
                        className="product-category-card-list"
                        dataSource={dataCategoryList}
                        itemLayout="horizontal"
                        grid={{ column: 4 }}
                        renderItem={(item) => (
                            <List.Item className="product-category-card product-card">
                                {productCategoryList ? (
                                    <CategoryCard
                                        data={item}
                                        selectedCatList={selectCatList}
                                        openCategoryCard={openCategoryCard}
                                        onSelect={handleSelect}
                                    />
                                ) : (
                                    <CategoryCard
                                        data={item}
                                        selectedCatList={selectCatList}
                                        openCategoryCard={openCategoryCard}
                                        onSelect={handleSelect}
                                    />
                                )}
                            </List.Item>
                        )}
                    />
                )}
                {currentCategoryMeta && currentCategoryPage < currentCategoryMeta.last_page && (
                    <ShowMoreButton onClick={handleShowMoreCategories} text="Показать ещё" />
                )}
                {dataList.length > 0 && !infiniteScrollProps?.isActive && (
                    <List
                        className="product-card-list"
                        /// Не требует исправления
                        dataSource={dataList}
                        itemLayout="horizontal"
                        bordered
                        grid={{ column: columnViewStore.col }}
                        renderItem={(item) => (
                            <List.Item className="product-card">
                                {productList ? (
                                    <ProductCard
                                        data={item}
                                        selectElemList={selectElemList}
                                        openCard={openCard}
                                        handleProductSelectButtonClick={
                                            handleProductSelectButtonClick
                                        }
                                        avoidRootStore={avoidRootStore}
                                        noQuantitySelector={noQuantitySelector}
                                        noNavigation={noNavigation}
                                        hideQuantity={hideQuantityInProductCard}
                                        searchValue={searchValue}
                                        // showPermisionButtons={showPermisionButtonsInCard}
                                    />
                                ) : (
                                    <ServiceCard
                                        /// Не требует исправления
                                        data={item}
                                        selectElemList={selectElemList}
                                        openCard={openCard}
                                        handleProductSelectButtonClick={
                                            handleProductSelectButtonClick
                                        }
                                        avoidRootStore={avoidRootStore}
                                        noQuantitySelector={noQuantitySelector}
                                        noNavigation={noNavigation}
                                    />
                                )}
                            </List.Item>
                        )}
                    />
                )}
                {/* Если выбрана бесконечная подгрузка */}
                {dataList.length > 0 && infiniteScrollProps?.isActive && (
                    <InfiniteScroll
                        dataLength={dataList.length}
                        next={infiniteScrollProps.onNext}
                        hasMore={dataList.length < infiniteScrollProps.totalItems}
                        loader={<Divider plain>Загрузка...</Divider>}
                        endMessage={
                            <Divider plain>{`Все ${
                                infiniteScrollProps.itemsName
                                    ? infiniteScrollProps.itemsName.toLowerCase()
                                    : 'клиенты'
                            } загружены :^)`}</Divider>
                        }
                        height={infiniteScrollProps.height ? infiniteScrollProps.height : ''}
                    >
                        <List
                            className="product-card-list"
                            dataSource={dataList}
                            itemLayout="horizontal"
                            bordered
                            grid={{ column: columnViewStore.col }}
                            renderItem={(item) => (
                                <List.Item className="product-card">
                                    {productList ? (
                                        <ProductCard
                                            data={item}
                                            selectElemList={selectElemList}
                                            openCard={openCard}
                                            handleProductSelectButtonClick={
                                                handleProductSelectButtonClick
                                            }
                                            avoidRootStore={avoidRootStore}
                                            noQuantitySelector={noQuantitySelector}
                                            noNavigation={noNavigation}
                                            hideQuantity={hideQuantityInProductCard}
                                            searchValue={searchValue}
                                            showPermisionButtons={showPermisionButtonsInCard}
                                            onPermissionButtonClick={onPermissionButtonClick}
                                        />
                                    ) : (
                                        <ServiceCard
                                            data={item}
                                            selectElemList={selectElemList}
                                            openCard={openCard}
                                            handleProductSelectButtonClick={
                                                handleProductSelectButtonClick
                                            }
                                            avoidRootStore={avoidRootStore}
                                            noQuantitySelector={noQuantitySelector}
                                            noNavigation={noNavigation}
                                            showPermisionButtons={showPermisionButtonsInCard}
                                            onPermissionButtonClick={onPermissionButtonClick}
                                        />
                                    )}
                                </List.Item>
                            )}
                        />
                    </InfiniteScroll>
                )}
            </>
        );
    }
);

export { ServiceProductList };
