import { LastIdStore } from "@pages/lastIdStore";
import { useReactive } from "ahooks";
import { SyntheticEvent, useEffect } from "react";
import { TMetadata } from "types/metadata";
import { TProduct } from "types/product";
import { TProductCategory } from "types/productCategory";
import { store as pStore } from '@molecules/paginationControls/pageSizeStore';
import { SettingsManager } from "@classes/settingsManager";
import { CategoryParams, ProductParams } from "types/getParams";
import { CRMAPIManager } from "@classes/crmApiManager";
import { ProductListResp } from "@api/responseModels/product/productListResponse";
import { messageService } from "@classes/messageService";
import { ProductCategoryListResp } from "@api/responseModels/product/productCategoryListResponse";
import { ProductCategoryResp } from "@api/responseModels/product/productCategoryResponse";
import { Button, Col, List, Modal, Pagination, Row, Tabs } from "antd";
import { Loader } from "@atoms/loader";
import { HeaderListBreadcrumbs } from "@molecules/breadcrumbs/listsBreadcrumbs/HeaderListBreadcrumbs";
import { faBarcode, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ServiceProductList } from "@molecules/renderList/serviceProductList";
import { ShowMoreButton } from "@molecules/paginationControls/showMoreButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TProps = {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    multiple: boolean;
    selectedByDefault?: { id: number; name: string; parentId: number; }[];
    setSelected: (selected?: { id: number; name: string; parentId: number; }[]) => void;
};

const ProductPicker = ({
    isModalOpen,
    setIsModalOpen,
    multiple,
    selectedByDefault,
    setSelected,
}: TProps): JSX.Element => {
    type TState = {
        isLoading: boolean;
        selected: { id: number; name: string; parentId: number; }[];
        productList: Array<TProduct & { category: TProductCategory }>;
        productCategoryList: Array<
            TProductCategory & {
                products: Array<TProduct>;
                categories: Array<TProductCategory>;
            }
        >;
        currentTab: 'list' | 'selected';
        currentCategoryMeta?: TMetadata;
        categoryPath: Array<{ id: number; name: string }>;
        currentMeta?: TMetadata;
        currentCategoryPage: number;
        currentPage: number;
    };

    const state = useReactive<TState>({
        isLoading: true,
        selected: selectedByDefault,
        productList: [],
        productCategoryList: [],
        currentTab: selectedByDefault.length > 0 ? 'selected' : 'list',
        currentCategoryMeta: null,
        categoryPath: [],
        currentMeta: null,
        currentCategoryPage: 1,
        currentPage: 1,
    });

    async function getProductList(page: number = state.currentPage, addition: boolean = false) {
        state.isLoading = true;
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const params: ProductParams = {
                crm_id: creds.crmID,
                category_id: LastIdStore.lastProductCategoryId ?? '',
                sort_by: 'name',
                sort_direction: 'asc',
                filters: {
                    created_at: null,
                    updated_at: null,
                    price: null,
                    deleted: 'null',
                },
                query: null,
                page,
                per_page: pStore.pS,
            };
            Object.keys(params.filters).filter(key => params.filters[key] === null && delete params.filters[key])
            const prodList = await CRMAPIManager.request<ProductListResp>(async (api) => {
                return await api.getProductList(params);
            });
            if (prodList.errorMessages) {
                state.productList = [];
                throw prodList.errorMessages;
            }
            if (addition) {
                state.productList = [...state.productList, ...prodList.data.data];
            }
            else {
                state.productList = prodList.data.data;
            }
            state.currentMeta = prodList.data.meta;
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    async function getProductCategoryList(page: number = state.currentCategoryPage, addition: boolean = false) {
        state.isLoading = true;
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const prodCatList = await CRMAPIManager.request<ProductCategoryListResp>(
                async (api) => {
                    const params: CategoryParams = {
                        crm_id: creds.crmID,
                        category_id: LastIdStore.lastProductCategoryId ?? '',
                        sort_by: 'name',
                        sort_direction: 'asc',
                        filters: {
                            created_at: null,
                            deleted: 'null',
                        },
                        query: null,
                        page,
                        per_page: pStore.pS,
                    };
                    Object.keys(params.filters).filter(key => params.filters[key] === null && delete params.filters[key])
                    return await api.getProductCategoryList(params);

                }
            );
            if (prodCatList.errorMessages) throw prodCatList.errorMessages;
            if (addition){
                state.productCategoryList = [...state.productCategoryList, ...prodCatList.data.data];
            }
            else {
                state.productCategoryList = prodCatList.data.data;
            }
            state.currentCategoryMeta = prodCatList.data.meta;
            if (state.categoryPath.length == 0 && LastIdStore.lastProductCategoryId != null) {
                let tProdCat = null;
                let tCurFolderId = LastIdStore.lastProductCategoryId;
                let tPath = [];
                while (tCurFolderId != null) {
                    tProdCat = await CRMAPIManager.request<ProductCategoryResp>(async (api) => {
                        return await api.getProductCategory(tCurFolderId, creds.crmID);
                    });
                    tPath = [{ id: tCurFolderId, name: tProdCat.data.data.name }, ...tPath];
                    tCurFolderId = tProdCat.data.data.parent_product_category_id;
                }
                state.categoryPath = tPath;
            }
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    function handlePickerOk() {
        setSelected(state.selected);
        setIsModalOpen(false);
    }

    function handlePickerCancel() {
        setIsModalOpen(false);
    }

    async function handleShowMoreProducts() {
        await getProductList(state.currentPage + 1, true);
        state.currentPage += 1;
    }

    useEffect(() => {
        state.selected = selectedByDefault;
        LastIdStore.setLastProductCategoryId(null);
        getProductList().then(() => {
            getProductCategoryList();
        });
    }, [isModalOpen]);

    function setSelectElemList(value: { id: number; name: string; parentId: number; }[]): void {
        if (!multiple) state.selected = value.length > 0 ? [value[value.length-1]] : [];
        state.selected = value;
        console.log(value);
    }

    useEffect(() => {
        if(state.currentMeta?.total) {
            let newPage = Math.ceil(state.currentMeta.from / pStore.pS);
            getProductList(newPage).then(() => {
                state.currentPage = newPage;
            });
        }
        if(state.currentCategoryMeta?.total) {
            let newPage = Math.ceil(state.currentCategoryMeta.from / pStore.pS);
            getProductCategoryList(newPage).then(() => {
                state.currentCategoryPage = newPage;
            });
        }
    }, [pStore.pS]);

    async function handleChangePage(newPage: number) {
        await getProductList(newPage);
        state.currentPage = newPage;
    }

    async function handleShowMoreCategories() {
        await getProductCategoryList(state.currentCategoryPage + 1, true);
        state.currentCategoryPage += 1;
    }

    async function openCategoryCard(id: number | null, back = false) {
        LastIdStore.setLastProductCategoryId(id);
        const tPath = [...state.categoryPath];
        if (back) {
            for (let i = tPath.length - 1; i >= 0; i--) {
                if (tPath[i].id !== id) {
                    tPath.pop();
                } else {
                    break;
                }
            }
        } else {
            const category = state.productCategoryList.find((sc) => sc.id == id);
            tPath.push({ id: id, name: category?.name });
        }
        state.categoryPath = tPath;

        await getProductList(1);
        await getProductCategoryList(1);
        state.currentPage = 1;
        state.currentCategoryPage = 1;
    }

    function deselect(e: SyntheticEvent, item: { id: number; name: string; parentId: number; }) {
        e.stopPropagation();
        state.selected = state.selected.filter(sel => sel.id != item.id);
    }

    return (
        <div>
            <Modal
                className='picker-template product-picker'
                title={`Выбор товар${multiple ? 'ов' : 'а'}`}
                open={isModalOpen}
                onOk={handlePickerOk}
                onCancel={handlePickerCancel}
                cancelText='Отмена'
                wrapClassName='sticky-modal'
            >
                {state.isLoading && <Loader />}
                <Tabs
                    items={[
                        {
                            label: 'Список',
                            key: 'list',
                            children: (
                                <div>
                                    <HeaderListBreadcrumbs
                                        dataTotal={null}
                                        title={'Товары'}
                                        dataTitle={'Товары'}
                                        dataIcon={faBarcode}
                                        dataPrice={null}
                                        flag={true}
                                        isProduct={true}
                                        categoryPath={state.categoryPath}
                                        openCategoryCard={openCategoryCard}
                                        onSearch={() => null}
                                        showSearchInput={false}
                                        searchPlaceHolder='По товарам'
                                        headless={true}
                                    ><></></HeaderListBreadcrumbs>
                                    <ServiceProductList
                                        avoidRootStore={true}
                                        noQuantitySelector={true}
                                        noNavigation={true}
                                        productCategoryList={state.productCategoryList}
                                        serviceCategoryList={null}
                                        productList={state.productList}
                                        serviceList={null}
                                        selectCatList={[]}
                                        selectElemList={state.selected}
                                        setSelectCatList={() => null}
                                        setSelectElemList={setSelectElemList}
                                        openCategoryCard={openCategoryCard}
                                        openCard={() => null}
                                        currentCategoryMeta={state.currentCategoryMeta}
                                        currentCategoryPage={state.currentCategoryPage}
                                        handleShowMoreCategories={handleShowMoreCategories}
                                    />
                                    { state.currentMeta && state.currentPage < state.currentMeta.last_page && <ShowMoreButton onClick={handleShowMoreProducts} text='Показать ещё' /> }
                                    <Pagination
                                        current={state.currentPage}
                                        defaultCurrent={1}
                                        onChange={handleChangePage}
                                        pageSize={pStore.pS}
                                        showSizeChanger={false}
                                        total={state.currentMeta?.total ?? 1}
                                    />
                                </div>
                            )
                        },
                        {
                            label: 'Выбранные',
                            key: 'selected',
                            children: (
                                <List
                                    className='picker-selected-list'
                                    dataSource={state.selected}
                                    itemLayout='vertical'
                                    bordered
                                    renderItem={(item) => (
                                        <List.Item className='picker-selected-card'>
                                            <Row>
                                                <Col>
                                                    <h3>{item.name}</h3>
                                                    &nbsp;
                                                    <i>{`Категория ${item.parentId == undefined ? 'базовая' : '№'+item.parentId}/#${item.id}`}</i>    
                                                </Col>
                                                <Col className="button-select">
                                                    <Button
                                                        danger
                                                        icon={<FontAwesomeIcon icon={faXmark} />}
                                                        onClick={(e) => deselect(e, item)}
                                                        shape="circle"
                                                        type='primary'
                                                    />
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            )
                        }
                    ]}
                    onChange={() => null}
                    defaultActiveKey={selectedByDefault.length > 0 ? 'selected' : 'list'}
                />
            </Modal>
        </div>
    );
};

export { ProductPicker };