import { action, computed, makeObservable, observable, values } from 'mobx';
import { TOrderProduct } from 'types/Orders/products';
import { TOrderService } from 'types/Orders/services';

type PrivateType = '_orderList';

type OrderItemType = TOrderProduct | TOrderService;
type OrdersType = {
    products: Record<number, TOrderProduct>;
    services: Record<number, TOrderService>;
};

class OrderStore {
    private _orderList: OrdersType = { products: {}, services: {} };

    constructor() {
        makeObservable<OrderStore, PrivateType>(this, {
            _orderList: observable,
            updateProductList: action,
            updateServicesList: action,
            reset: action,
            deleteItem: action,
            orderList: computed,
            products: computed,
            services: computed,
            allEntitiesCount: computed
        });
    }

    get orderList() {
        return {
            products: values(this._orderList.products),
            services: values(this._orderList.services),
        };
    }

    get allEntitiesCount() {
        return values(this._orderList.products)
            .concat(...values(this._orderList.services))
            .reduce((acc, cur) => cur.quantity + acc, 0);
    }

    get products() {
        return this._orderList.products;
    }

    get services() {
        return this._orderList.services;
    }

    updateProductList = (item: OrderItemType) => {
        if (item.quantity === 0) {
            delete this._orderList.products[item.id]
            return;
        }
        this._orderList.products[item.id] = item;
    };

    updateServicesList = (item: OrderItemType) => {
        if (item.quantity === 0) {
            delete this._orderList.services[item.id]
            return;
        }
        this._orderList.services[item.id] = item;
    };

    deleteItem(id: number, type: 'product' | 'services') {
        if (type === 'product') {
            delete this._orderList.products[id]
        }
        if (type === 'services') {
            delete this._orderList.services[id];
        }
    }

    reset = () => {
        this._orderList = { products: {}, services: {} };
    }
}

export { OrderStore };
