import { CRMAPIBase } from './crmApiBase';
import { CRMGroup } from './methodGroups/crmGroup';
import { CustomerProfileGroup } from './methodGroups/customerProfileGroup';
import { FileGroup } from './methodGroups/fileGroup';
import { InvitationGroup } from './methodGroups/invitationGroup';
import { OrderDetailsGroup } from './methodGroups/orderDetailsGroup';
import { OrderFieldsGroup } from './methodGroups/orderFieldsGroup';
import { OrderGroup } from './methodGroups/orderGroup';
import { OrderObjectsGroup } from './methodGroups/orderObjectsGroup';
import { PermissionGroup } from './methodGroups/permissionGroup';
import { ProductCategoryGroup } from './methodGroups/productCategoryGroup';
import { ProductGroup } from './methodGroups/productGroup';
import { ProfileGroup } from './methodGroups/profileGroup';
import { RequisiteGroup } from './methodGroups/requisiteGroup';
import { SecurityGroup } from './methodGroups/securityGroup';
import { ServiceCategoryGroup } from './methodGroups/serviceCategoryGroup';
import { ServiceGroup } from './methodGroups/serviceGroup';
import { TaskCategoryGroup } from './methodGroups/taskCategoryGroup';
import { TaskGroup } from './methodGroups/taskGroup';
import { TransactionsGroup } from './methodGroups/transactionsGroup';
import { UserGroup } from './methodGroups/userGroup';
import { WorkerProfileGroup } from './methodGroups/workerProfileGroup';
import { WorksheetGroup } from './methodGroups/worksheetGroup';

class CRMAPI extends CRMAPIBase {}

interface CRMAPI
    extends SecurityGroup,
        UserGroup,
        RequisiteGroup,
        CustomerProfileGroup,
        WorkerProfileGroup,
        OrderGroup,
        OrderDetailsGroup,
        ProfileGroup,
        InvitationGroup,
        ProductCategoryGroup,
        ProductGroup,
        FileGroup,
        ServiceCategoryGroup,
        ServiceGroup,
        TransactionsGroup,
        PermissionGroup,
        WorksheetGroup,
        OrderFieldsGroup,
        TaskGroup,
        TaskCategoryGroup,
        OrderObjectsGroup,
        CRMGroup {}

function applyMixins(derivedCtor: any, baseCtors: any[]) {
    baseCtors.forEach((baseCtor) => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
            if (name !== 'constructor') {
                derivedCtor.prototype[name] = baseCtor.prototype[name];
            }
        });
    });
}

applyMixins(CRMAPI, [
    SecurityGroup,
    UserGroup,
    RequisiteGroup,
    CustomerProfileGroup,
    WorkerProfileGroup,
    OrderGroup,
    OrderDetailsGroup,
    ProfileGroup,
    InvitationGroup,
    ProductCategoryGroup,
    ProductGroup,
    FileGroup,
    ServiceCategoryGroup,
    ServiceGroup,
    TransactionsGroup,
    PermissionGroup,
    WorksheetGroup,
    OrderFieldsGroup,
    TaskGroup,
    TaskCategoryGroup,
    OrderObjectsGroup,
    CRMGroup,
]);

export { CRMAPI };
