import React from 'react';
import { Row, Table } from 'antd';
import { observer } from 'mobx-react';
import { TableOrderDetailsProps } from '../models';
import './style.scss';

export const TableOrderDetails: React.FC<TableOrderDetailsProps> = observer(
    ({
        dataSource,
        getColumnsDetails,
        onValueChange,
        handleSelectTemplate,
        hasTemplateSelected,
    }) => {
        const isContainRequiredField = Boolean(dataSource.find((item) => item.isRequired));

        return (
            <Row className={'order-details'}>
                <Table
                    className='order-details-table'
                    columns={getColumnsDetails(
                        onValueChange,
                        isContainRequiredField,
                        handleSelectTemplate,
                        hasTemplateSelected
                    )}
                    bordered
                    dataSource={dataSource}
                    pagination={false}
                    size={'small'}
                    rowKey={(rec) => rec.key}
                />
            </Row>
        );
    }
);
